import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Modal, Input, Button } from 'antd';
import styles from './AddCircleModal.styles';
import TextSearch from '../../components/Filter/TextSearch';
import { colors } from '../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { selectFleets } from '../../redux/store/appData/fleets';
import { selectAreas } from '../../redux/store/appData/areas';
import SelectorLayout from '../../components/shared/SelectorLayout';
import { createCircleIfNeeded } from '../../redux/store/userData/circles';
import { typeLabelForCircle } from '../../utils';
import { useIntl } from 'react-intl';
import { CircleInputData } from '../../@types';
import { PermissionSwitch } from '../CircleEditDrawer/PermissionSwitch';

const circleTypes = ['standard', 'area', 'service'];

const AddCircleModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [type, setType] = useState('standard');
  const [name, setName] = useState('');
  const [positiveBalanceRequired, setPositiveBalanceRequired] = useState(true);
  const [addressRequired, setAddressRequired] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(true);
  const [paymentRequired, setPaymentRequired] = useState(true);
  const [licenseRequired, setLicenseRequired] = useState(true);
  const [identityRequired, setIdentityRequired] = useState(true);
  const [sepaCreditCheckRequired, setSepaCreditCheckRequired] = useState(true);
  const [selectedFleets, setSelectedFleets] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const fleets = useSelector(selectFleets) as any;
  const areas = useSelector(selectAreas);
  const areasOptions = areas
    ? areas.map((area) => ({
        label: area.name,
        value: area.id,
      }))
    : [];
  const fleetOptions = fleets
    ? fleets.map((fleet: { name: any; id: any }) => ({
        label: fleet.name,
        value: fleet.id,
      }))
    : [];
  const visible = useSelector(getIsVisibleModal('createCircleModal'));
  const isDisabled = !(
    type &&
    name &&
    (type === 'area' ? selectedAreas.length > 0 : selectedFleets.length > 0)
  );

  const handleCancel = () => {
    dispatch(closeModal('createCircleModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    batch(() => {
      const circleInputData = {
        type,
        name,
        addressRequired,
        positiveBalanceRequired,
        phoneVerificationRequired: phoneRequired,
        paymentMethodRequired: paymentRequired,
        driverLicenseRequired: licenseRequired,
        identityApprovalRequired: identityRequired,
        creditCheckForSepaRequired: sepaCreditCheckRequired,
      } as CircleInputData;
      if (type === 'area') {
        circleInputData['areasIds'] = selectedAreas;
      } else {
        circleInputData['fleetsIds'] = selectedFleets;
      }
      dispatch(createCircleIfNeeded(token, circleInputData));
    });
    handleCancel();
  };

  const onSelectArea = (value: any) => {
    setSelectedAreas(value);
  };

  const onSelectFleet = (value: any) => {
    setSelectedFleets(value);
  };

  const onChangeAddressRequired = (checked: boolean) =>
    setAddressRequired(checked);

  const onChangePositiveBalanceRequired = (checked: boolean) =>
    setPositiveBalanceRequired(checked);
  const onChangePhoneRequired = (checked: boolean) => setPhoneRequired(checked);

  const onChangePaymentRequired = (checked: boolean) => {
    setPaymentRequired(checked);
    if (!checked) {
      setSepaCreditCheckRequired(checked);
    }
  };

  const onChangeSepaCheckRequired = (checked: boolean) =>
    setSepaCreditCheckRequired(checked);

  const onChangeLicenseRequired = (checked: boolean) =>
    setLicenseRequired(checked);

  const onChangeIdentityRequired = (checked: boolean) =>
    setIdentityRequired(checked);

  useEffect(() => {
    if (visible) {
      setType('');
      setName('');
      setSelectedFleets([]);
      setSelectedAreas([]);
      setPositiveBalanceRequired(true);
      setAddressRequired(true);
      setPaymentRequired(true);
      setLicenseRequired(true);
      setIdentityRequired(true);
      setSepaCreditCheckRequired(true);
    }
  }, [visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-circle-wrapper"
          title={intl.formatMessage({
            id: 'circle.create.title',
          })}
          visible={visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-circle-contents" tabIndex={0}>
            <TextSearch
              placeholder={intl.formatMessage({
                id: 'circle.create.type',
              })}
              dataSource={circleTypes?.map((type) => {
                return {
                  value: typeLabelForCircle(intl, type),
                  text: typeLabelForCircle(intl, type),
                  type: type,
                };
              })}
              setValue={(value, option) => {
                setType(option?.type);
              }}
              defaultOpen={false}
            />
            <div className="inputs-container">
              <Input
                value={name}
                placeholder={intl.formatMessage({
                  id: 'circle.create.name',
                })}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {type !== 'area' && (
              <div className="inputs-container">
                <SelectorLayout
                  mode="multiple"
                  keyPrefix="fleet-select"
                  value={selectedFleets}
                  placeholder={intl.formatMessage({
                    id: 'circle.create.fleets',
                  })}
                  onSelect={onSelectFleet}
                  options={fleetOptions}
                />
              </div>
            )}
            {type === 'area' && (
              <div className="inputs-container">
                <SelectorLayout
                  keyPrefix="area-select"
                  value={selectedAreas}
                  placeholder={intl.formatMessage({
                    id: 'circle.create.areas',
                  })}
                  onSelect={onSelectArea}
                  options={areasOptions}
                />
              </div>
            )}
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={positiveBalanceRequired}
                onChange={onChangePositiveBalanceRequired}
                label={intl.formatMessage({
                  id: 'circle.positiveBalanceRequired',
                })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={addressRequired}
                onChange={onChangeAddressRequired}
                label={intl.formatMessage({
                  id: 'circle.addressRequired',
                })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={phoneRequired}
                onChange={onChangePhoneRequired}
                label={intl.formatMessage({
                  id: 'circle.phoneRequired',
                })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={paymentRequired}
                onChange={onChangePaymentRequired}
                label={intl.formatMessage({
                  id: 'circle.paymentRequired',
                })}
              />
            </div>
            <div className={cn('add-circle-checkbox-container', 'sub')}>
              <PermissionSwitch
                isSelected={sepaCreditCheckRequired}
                onChange={onChangeSepaCheckRequired}
                disabled={!paymentRequired}
                label={intl.formatMessage({
                  id: 'circle.creditCheckRequiredForSepa',
                })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={licenseRequired}
                onChange={onChangeLicenseRequired}
                label={intl.formatMessage({ id: 'circle.licenseRequired' })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <PermissionSwitch
                isSelected={identityRequired}
                onChange={onChangeIdentityRequired}
                label={intl.formatMessage({ id: 'circle.identityRequired' })}
              />
            </div>
            <div className="add-circle-checkbox-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default AddCircleModal;
