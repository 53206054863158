import React from 'react';
import { useSelector } from 'react-redux';
import styles from './ProfileInfo.styles';
import ListItem from '../../components/shared/ListItem';
import { selectSelectedProfile } from '../../redux/store/userData/profiles';
import { renderNumber, renderText } from '../shared/DrawerLayout/renderText';
import { renderBoolean } from '../shared/DrawerLayout/renderBoolean';
import { getBrandNameById } from '../ProfilesTable/getColumns';
import { selectBrands } from '../../redux/store/appData/fleets';
import ProfileGallery from './ProfileGallery';

const ProfileInfo: React.FC = () => {
  const selectedProfile = useSelector(selectSelectedProfile);
  const brands = useSelector(selectBrands);

  return (
    <>
      <div className="profile-info">
        <ListItem
          label="Brand"
          value={renderText(
            getBrandNameById(brands!, selectedProfile?.brandId!)
          )}
        />
        <ListItem
          label="Vehicle Brand"
          value={renderText(selectedProfile?.brandName!)}
        />
        <ListItem
          label="Vehicle Model"
          value={renderText(selectedProfile?.model!)}
        />
        <ListItem
          label="Vehicle Model Year"
          value={renderNumber(selectedProfile?.modelYear!)}
        />
        <ListItem
          label="Vehicle Type"
          value={renderText(selectedProfile?.vehicleType!)}
        />
        <ListItem label="Gear" value={renderText(selectedProfile?.gear!)} />
        <ListItem label="Fuel" value={renderText(selectedProfile?.fuel!)} />
        {selectedProfile?.fuel === 'electric' &&
        selectedProfile?.batteryCapacityKwh ? (
          <ListItem
            label="Battery Capacity (Kwh)"
            value={renderNumber(selectedProfile?.batteryCapacityKwh!)}
          />
        ) : (
          <>
            <ListItem
              label="Fuel Consumption (l/100km)"
              value={renderNumber(selectedProfile?.fuelConsumption!)}
            />
            <ListItem
              label="Fuel Tank Volume (l)"
              value={renderNumber(selectedProfile?.fuelTankVolume!)}
            />
          </>
        )}
        <ListItem
          label="Engine Power (HPS)"
          value={renderNumber(selectedProfile?.enginePower!)}
        />
        <ListItem
          label="Pollution Badge"
          value={renderText(selectedProfile?.pollutionBadge!)}
        />
        <ListItem
          label="Fuel Level Supported"
          value={renderBoolean(selectedProfile?.fuelLevelSupported!)}
        />
        <ListItem
          label="Central Lock"
          value={renderBoolean(selectedProfile?.centralLock!)}
        />
        <ListItem
          label="Seats No."
          value={renderNumber(selectedProfile?.seats!)}
        />
        {selectedProfile?.vehicleType === 'transporter' && (
          <>
            <ListItem
              label="Load Capacity (kg)"
              value={renderNumber(selectedProfile?.loadCapacity!)}
            />
            <ListItem
              label="Width (mm)"
              value={renderNumber(selectedProfile?.width!)}
            />
            <ListItem
              label="Height (mm)"
              value={renderNumber(selectedProfile?.height!)}
            />
            <ListItem
              label="Length (mm)"
              value={renderNumber(selectedProfile?.length!)}
            />
          </>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ProfileInfo;
