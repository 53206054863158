import React from 'react';
import { useSelector } from 'react-redux';
import styles from './PricingInfo.styles';
import { selectSelectedPricing } from '../../redux/store/appData/pricings';
import { renderDateTime } from '../shared/TableLayout';
import ListEditItem from '../../components/shared/ListEditItem';
import ListItem from '../../components/shared/ListItem';
import { Tag } from 'antd';
import formatDateTime from '../../utils/formatDateTime';
import { getStatusColor } from '../../utils';
import { useIntl } from 'react-intl';
import SelectorLayout from '../shared/SelectorLayout';

const PricingInfo: React.FC = () => {
  const intl = useIntl();
  const selectedPricing = useSelector(selectSelectedPricing);

  return (
    <div style={{ paddingTop: 24 }}>
      <div className="pricing-info">
        <ListEditItem
          label={intl.formatMessage({ id: 'fleetadmin.pricing.status' })}
          leftItem={
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tag color={getStatusColor(selectedPricing?.status)}>
                  {selectedPricing?.status}
                </Tag>
              </div>
            </>
          }
        />
        <ListEditItem
          label={intl.formatMessage({ id: 'fleetadmin.pricing.version' })}
          leftItem={
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {`${selectedPricing.version} (${formatDateTime(
                  selectedPricing.lastModifiedAt
                )})`}
              </div>
            </>
          }
        />
        <ListItem
          label={intl.formatMessage({ id: 'fleetadmin.pricing.validfrom' })}
          value={renderDateTime(selectedPricing?.validFrom) || '-'}
        />
        <div className="pricing-label">
          {intl.formatMessage({ id: 'fleetadmin.pricing.fleets' })}
        </div>
        <SelectorLayout
          keyPrefix="brand-select"
          mode="multiple"
          value={selectedPricing.fleets?.map((item) => item.id)}
          placeholder="Brands"
          options={
            selectedPricing.fleets?.map((brand) => ({
              value: brand.id,
              label: brand.name,
            })) || []
          }
          disabled={true}
          style={{ padding: '0 24px' }}
        />
        <div className="pricing-label">
          {intl.formatMessage({ id: 'fleetadmin.pricing.circles' })}
        </div>
        <SelectorLayout
          keyPrefix="brand-select"
          mode="multiple"
          value={selectedPricing.circles?.map((item) => item.id)}
          placeholder="Circles"
          options={
            selectedPricing.circles?.map((brand) => ({
              value: brand.id,
              label: brand.name,
            })) || []
          }
          disabled={true}
          style={{ padding: '0 24px' }}
        />
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default PricingInfo;
