import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Select, Checkbox } from 'antd';
import styles from './UserOrgModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { zIndex } from '../../../theme/theme';
import { selectAuth } from '../../../redux/store/userData/user';

const { Option } = Select;

interface UserInput {
  companyName?: string;
  legalType?: string;
  dateOfBirth?: string;
  otherLegalType?: string;
  registerNr?: string;
  registerLocation?: string;
  address?: {
    country?: string;
    city?: string;
    streetName?: string;
    streetNumber?: string;
    postalCode?: string;
  };
  addressApproved?: boolean;
}

enum LEGAL_TYPES {
  AG = 'AG',
  EV = 'e.V.',
  GmbH = 'GmbH',
  CoKG = 'GmbH & Co KG',
  KG = 'KG',
  OHG = 'OHG',
  Sonstige = 'Sonstige',
}

const DefaultSupportedCountries = [
  { code: 'CH', name: 'Switzerland' },
  { code: 'MC', name: 'Monaco' },
  { code: 'NO', name: 'Norway' },
  { code: 'BE', name: 'Belgium' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'AT', name: 'Austria' },
  { code: 'FI', name: 'Finland' },
];

const UserOrgModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const user = useSelector(selectAuth);

  const selectedUser = useSelector(selectSelectedUser);
  const selectedBrand = user.brands.find(
    (it) => it.id === selectedUser?.brandId
  );
  const [companyName, setCompanyName] = useState(
    selectedUser?.companyName || ''
  );
  const [legalType, setLegalType] = useState(selectedUser?.legalType || '');
  const [otherLegalType, setOtherLegalType] = useState(
    selectedUser?.otherLegalType || ''
  );
  const [registerNr, setRegisterNr] = useState(selectedUser?.registerNr || '');
  const [registerLocation, setRegisterLocation] = useState(
    selectedUser?.registerLocation || ''
  );

  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('DE');
  const [addressApproved, setAddressApproved] = useState(
    selectedUser?.addressVerified || false
  );
  const address = selectedUser?.address;

  const visible = useSelector(getIsVisibleModal('UserOrgModal'));
  const isAddressValid =
    streetName && streetNumber && city && postalCode && country;

  const supportedCountries =
    selectedBrand?.allowedUserAddressCountries?.map((item) => ({
      code: item.toUpperCase(),
      name: intl.formatMessage({
        id: item.toUpperCase(),
      }),
    })) || DefaultSupportedCountries;

  const handleCancel = () => {
    dispatch(closeModal('UserOrgModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    let userInput: UserInput = {};

    if (companyName !== selectedUser?.companyName) {
      userInput.companyName = companyName;
    }
    if (legalType !== selectedUser.legalType) {
      userInput.legalType = legalType;
    }
    if (otherLegalType !== selectedUser.otherLegalType) {
      userInput.otherLegalType = otherLegalType;
    }
    if (registerNr !== selectedUser.registerNr) {
      userInput.registerNr = registerNr;
    }
    if (registerLocation !== selectedUser.registerLocation) {
      userInput.registerLocation = registerLocation;
    }
    if (city != selectedUser.address.city) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.city = city;
    }
    if (streetName != selectedUser.address.streetName) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.streetName = streetName;
    }
    if (streetNumber != selectedUser.address.streetNumber) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.streetNumber = streetNumber;
    }
    if (postalCode != selectedUser.address.postalCode) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.postalCode = postalCode;
    }
    if (country != selectedUser.address.country) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.country = country;
    }
    if (addressApproved && selectedUser?.addressVerified == false) {
      userInput.addressApproved = addressApproved;
    }

    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser.id, userInput));
      handleCancel();
    }
  };

  const onClickAddressApproved = (e: any) => {
    setAddressApproved(e.target.checked);
  };

  useEffect(() => {
    if (visible) {
      setCompanyName(selectedUser?.companyName || '');
      setLegalType(selectedUser?.legalType || '');
      setOtherLegalType(selectedUser?.otherLegalType || '');
      setRegisterNr(selectedUser?.registerNr || '');
      setRegisterLocation(selectedUser?.registerLocation || '');
      setStreetName(address?.streetName || '');
      setStreetNumber(address?.streetNumber || '');
      setPostalCode(address?.postalCode || '');
      setCity(address?.city || '');
      setCountry(address?.country || 'DE');
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-org-contents">
          <div className="user-org-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.org`,
            })}
          </div>
          <div className="user-org-input-container">
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.companyname`,
              })}
            </div>
            <Input
              value={companyName}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.companyname`,
              })}
              onChange={(e) => setCompanyName(e.target.value)}
              style={{ marginRight: 12 }}
            />

            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.legaltype`,
              })}
            </div>
            <Select
              defaultValue="DE"
              value={legalType}
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.legaltype`,
              })}
              onChange={(code: string) => setLegalType(code)}
              dropdownStyle={{ zIndex: zIndex.dropDown }}
            >
              {Object.values(LEGAL_TYPES)?.map((typ) => (
                <Option key={`area-option-${typ}`} value={typ}>
                  {typ}
                </Option>
              ))}
            </Select>

            {legalType === 'Sonstige' && (
              <>
                <div className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.otherlegaltype`,
                  })}
                </div>
                <Input
                  value={otherLegalType}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.otherlegaltype`,
                  })}
                  onChange={(e) => setOtherLegalType(e.target.value)}
                />
              </>
            )}

            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.registernr`,
              })}
            </div>
            <Input
              value={registerNr}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.registernr`,
              })}
              onChange={(e) => setRegisterNr(e.target.value)}
            />

            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.registerlocation`,
              })}
            </div>
            <Input
              value={registerLocation}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.registerlocation`,
              })}
              onChange={(e) => setRegisterLocation(e.target.value)}
            />

            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.address`,
              })}
            </div>
            <div className="user-org-contact-modal-row">
              <Input
                value={streetName}
                placeholder="Street name"
                onChange={(e: any) => {
                  setStreetName(e.target.value);
                }}
                disabled={selectedUser?.addressVerified}
              />
              <div className="street-number-input">
                <Input
                  value={streetNumber}
                  placeholder="Number"
                  onChange={(e: any) => {
                    setStreetNumber(e.target.value);
                  }}
                  disabled={selectedUser?.addressVerified}
                />
              </div>
            </div>
            <div className="user-org-contact-modal-row">
              <div className="postal-code-input">
                <Input
                  value={postalCode}
                  placeholder="Postal code"
                  onChange={(e: any) => {
                    setPostalCode(e.target.value);
                  }}
                  disabled={selectedUser?.addressVerified}
                />
              </div>
              <Input
                value={city}
                placeholder="City"
                onChange={(e: any) => {
                  setCity(e.target.value);
                }}
                disabled={selectedUser?.addressVerified}
              />
            </div>
            <Select
              defaultValue="DE"
              value={country}
              style={{ width: '100%' }}
              placeholder="Country"
              onChange={(code: string) => setCountry(code)}
              dropdownStyle={{ zIndex: zIndex.dropDown }}
              disabled={selectedUser?.addressVerified}
            >
              {supportedCountries.map((country) => (
                <Option
                  key={`area-option-${country.code}`}
                  value={country.code}
                >
                  {country.name}
                </Option>
              ))}
            </Select>
            <Checkbox
              disabled={selectedUser?.addressVerified || !isAddressValid}
              style={{ marginBottom: 8, marginTop: 8 }}
              value={addressApproved}
              onChange={onClickAddressApproved}
            >
              {intl.formatMessage({
                id: 'fleetadmin.users.info.addressApproved',
              })}
            </Checkbox>
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};
export default UserOrgModal;
