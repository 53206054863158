import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { VehicleEventData } from '../../@types';
import {
  AreaChanged,
  BookingAssigned,
  DamageRecordUpdated,
  IgnitionChanged,
  ServiceNoteAdded,
  ServiceReported,
  ShockDetected,
  StatusChanged,
  TripReserved,
  Trip,
} from './TimelineItemBody';
import TripFrameTop from './TripFrameTop';
import TripFrameBottom from './TripFrameBottom';
import styles from './ClaimTimelineItem.styles';
import formatDateTime from '../../utils/formatDateTime';
import ImageAdded from './ImageAdded';

const IMAGE_PATH = '/images/timeline';

const iconMapper = {
  areaChanged: `${IMAGE_PATH}/dot.svg`,
  bookingAssigned: `${IMAGE_PATH}/clock.svg`,
  damageRecordUpdated: `${IMAGE_PATH}/redDot.svg`,
  ignitionChanged: `${IMAGE_PATH}/thunderbolt.svg`,
  lockChanged: `${IMAGE_PATH}/lock.svg`,
  serviceNoteAdded: `${IMAGE_PATH}/message.svg`,
  serviceReported: `${IMAGE_PATH}/list.svg`,
  shockDetected: `${IMAGE_PATH}/warning.svg`,
  statusChanged: `${IMAGE_PATH}/dot.svg`,
  tripFinished: `${IMAGE_PATH}/lock.svg`,
  tripReserved: `${IMAGE_PATH}/clock.svg`,
  tripStarted: `${IMAGE_PATH}/unlock.svg`,
  lock: `${IMAGE_PATH}/lock.svg`,
  unlock: `${IMAGE_PATH}/unlock.svg`,
  imageAdded: `${IMAGE_PATH}/camera.svg`,
};

const bodyMapper = {
  areaChanged: AreaChanged,
  bookingAssigned: BookingAssigned,
  damageRecordUpdated: DamageRecordUpdated,
  ignitionChanged: IgnitionChanged,
  lockChanged: Trip,
  serviceNoteAdded: ServiceNoteAdded,
  serviceReported: ServiceReported,
  shockDetected: ShockDetected,
  statusChanged: StatusChanged,
  tripFinished: Trip,
  tripReserved: TripReserved,
  tripStarted: Trip,
  imageAdded: ImageAdded,
};

const NotMatched = () => <p>type is not matched</p>;

type TimelineItemProps = {
  vehicleEvent: VehicleEventData;
  hideSecondBar?: boolean;
  hideFirstBar?: boolean;
  isInsideTrip: boolean;
  isNewTrip: boolean;
  onClickBody: () => void;
};

const ClaimTimelineItem: React.FC<TimelineItemProps> = ({
  vehicleEvent,
  hideSecondBar,
  hideFirstBar,
  isInsideTrip,
  isNewTrip,
  onClickBody,
}) => {
  const intl = useIntl();
  const { type, addedAt, imageType, ignitionOff } = vehicleEvent;
  // @ts-ignore
  const iconSrc = iconMapper[type] || `${IMAGE_PATH}/dot.svg`;
  // @ts-ignore
  const Body = bodyMapper[type] || NotMatched;
  const dateTime = formatDateTime(addedAt);

  let title;
  if (type === 'tripStarted') {
    title = intl.formatMessage({
      id: 'fleetadmin.event.unlocked',
    });
  } else if (type === 'tripFinished') {
    title = intl.formatMessage({
      id: 'fleetadmin.event.tripFinished',
    });
  } else if (type === 'ignitionChanged') {
    title = intl.formatMessage({
      id: `fleetadmin.event.${ignitionOff ? 'ignitionOff' : 'ignitionOn'}`,
    });
  } else if (type === 'imageAdded') {
    title = intl.formatMessage({
      id: `fleetadmin.event.${imageType}`,
    });
  } else {
    title = intl.formatMessage({
      id: `fleetadmin.event.${type}`,
    });
  }

  return (
    <>
      {isNewTrip && <TripFrameTop vehicleEvent={vehicleEvent} />}
      {isNewTrip && type !== 'tripFinished' && type !== 'imageAdded' && (
        <div className={cn('timeline-item-wrapper', { isInsideTrip })}>
          <div className="left">
            <div
              className={cn('first', 'bar', {
                hidden:
                  hideFirstBar ||
                  (type === 'imageAdded' && imageType === 'rentalEndImage'),
              })}
            />
            <div className="icon-wrapper">
              <img src={`${IMAGE_PATH}/loading.svg`} alt={`${type} icon`} />
            </div>
            <div className={cn('second', ' bar')} />
          </div>
          <div className="right">
            <p className="title">Driving..</p>
          </div>
        </div>
      )}
      <div className={cn('timeline-item-wrapper', { isInsideTrip })}>
        <div className="left">
          <div
            className={cn('first', 'bar', {
              hidden:
                hideFirstBar ||
                (type === 'imageAdded' && imageType === 'rentalEndImage'),
            })}
          />
          <div className="icon-wrapper">
            <img src={iconSrc} alt={`${type} icon`} />
          </div>
          <div
            className={cn('second', ' bar', {
              hidden: hideSecondBar || type === 'tripStarted',
            })}
          />
        </div>
        <div className="right">
          <p className="title">
            {title}
            <span className="date-time">{dateTime}</span>
          </p>
          <div className="body">
            <Body vehicleEvent={vehicleEvent} onClick={onClickBody} />
          </div>
        </div>
      </div>
      {type === 'tripStarted' && <TripFrameBottom />}
      <style jsx>{styles}</style>
    </>
  );
};

export default ClaimTimelineItem;
