import { RootState } from '../../../config';
import { ViewMode } from '../../../../hooks/useViewMode';
import { APIFilterParams } from './initialState';

export const selectFilter = (state: RootState) => state.ui.filter;

export const selectIsFilterPanelOpen = (state: RootState) =>
  state.ui.filter.isPanelOpen;

export const selectFilterTarget = (state: RootState) => state.ui.filter.target;

export const selectFilterTargetType = (state: RootState) =>
  state.ui.filter.targetType;

export const selectFilterFor = (viewMode: ViewMode) => (state: RootState) =>
  // @ts-ignore
  state.ui.filter[viewMode];

export const selectAPIFilterParams = (state: RootState): APIFilterParams =>
  state.ui.filter.apiParams;

export const selectFilters = (state: RootState) => state.ui.filter;
