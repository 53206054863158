import moment from 'moment';

const formatDateWithDay = (dateTime: any) => {
  if (dateTime === '') {
    return '';
  }
  return moment(dateTime).format('dd DD MMM. YYYY');
};

export default formatDateWithDay;
