const bookingColumns = [
  { label: 'Booking ID', value: 'presentableId', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Type', value: 'type', disabled: false },
  { label: 'Car Plate', value: 'confirmedCarId', disabled: false },
  { label: 'From', value: 'from', disabled: false },
  { label: 'To', value: 'to', disabled: false },
  { label: 'Created at', value: 'created', disabled: false },
  { label: 'Category', value: 'categoryId', disabled: false },
  { label: 'Area', value: 'areaId', disabled: false },
];

export const defaultSelectedBookingColumns = bookingColumns.map(
  (bookingColumn) => bookingColumn.value
);

export default bookingColumns;
