import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse } from '../../../../@types';
import { ReceiptsTypes } from '../../../../@types/enums';

export const receiptsRequest = () =>
  createAction(ReceiptsTypes.RECEIPTS_REQUEST);

export const receiptsSuccess = (payload: any[]) =>
  createAction(ReceiptsTypes.RECEIPTS_SUCCESS, payload);

export const receiptsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ReceiptsTypes.RECEIPTS_FAILURE, error);

export const setDisplayReceipts = (payload: any[]) =>
  createAction(ReceiptsTypes.SET_DISPLAY_RECEIPT, payload);

export const setTotalReceipts = (payload: any[]) =>
  createAction(ReceiptsTypes.SET_TOTAL_RECEIPTS, payload);

export const selectReceipt = (payload: any | null) =>
  createAction(ReceiptsTypes.SELECT_RECEIPT, payload);

export const receiptRequest = () => createAction(ReceiptsTypes.RECEIPT_REQUEST);

export const receiptSuccess = (payload: any[]) =>
  createAction(ReceiptsTypes.RECEIPT_SUCCESS, payload);

export const receiptFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ReceiptsTypes.RECEIPT_FAILURE, error);

export const receiptsCSVRequest = () =>
  createAction(ReceiptsTypes.RECEIPTS_CSV_REQUEST);

export const receiptsCSVSuccess = () =>
  createAction(ReceiptsTypes.RECEIPTS_CSV_SUCCESS);

export const receiptsCSVFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ReceiptsTypes.RECEIPTS_CSV_FAILURE, error);
