import { RootState } from '../../../config';
import { AreaData } from '../../../../@types';

export const selectAreas = (state: RootState): AreaData[] | null =>
  state.appData.areas.entities;

export const selectDisplayAreas = (state: RootState): AreaData[] | null =>
  state.appData.areas.display;

export const selectSelectedArea = (state: RootState): AreaData | null =>
  state?.appData?.areas?.selected;

export const selectHideOnMapArea = (state: RootState) =>
  state?.appData?.areas?.hideOnMap;

export const selectAreasLoading = (state: RootState): boolean =>
  state.appData.areas.loading;
