import React from 'react';
import { Tabs } from 'antd';
import styles from './TabLayout.styles';

const { TabPane } = Tabs;

type Tab = {
  title: React.ReactNode;
  content: React.ReactNode;
};

type TabLayoutProps = {
  tabs: Tab[];
  keyName: string;
  activeTab?: number;
  onChangeTab?: (activeKey: string) => void;
};

const TabLayout: React.FC<TabLayoutProps> = ({
  tabs,
  keyName,
  activeTab,
  onChangeTab,
}) => {
  return (
    <>
      <div className="tab-wrapper">
        <Tabs
          activeKey={
            activeTab !== undefined ? `${keyName}-tab-${activeTab}` : undefined
          }
          type="card"
          onChange={onChangeTab}
        >
          {tabs.map((tab, id) => (
            <TabPane
              tab={tab.title}
              key={`${keyName}-tab-${id}`}
              className="tab-panel hide-scrollbar"
            >
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

TabLayout.defaultProps = {
  activeTab: undefined,
  onChangeTab: null,
};

export default TabLayout;
