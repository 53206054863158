import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { VehicleEventData } from '../../@types';
import {
  AreaChanged,
  BookingAssigned,
  DamageRecordUpdated,
  IgnitionChanged,
  ServiceNoteAdded,
  ServiceReported,
  ShockDetected,
  StatusChanged,
  TripReserved,
  Trip,
  NoteAdded,
  RecordAdded,
  TripChecked,
  BorderCrossed,
  DocumentAdded,
} from './TimelineItemBody';
import styles from './TimelineItem.styles';
import formatDateTime from '../../utils/formatDateTime';
import ImageAdded from './ImageAdded';
import { selectSelectedTrip } from '../../redux/store/userData/trips';
import { useSelector } from 'react-redux';

const IMAGE_PATH = '/images/timeline';

type TimelineItemType =
  | 'areaChanged'
  | 'bookingAssigned'
  | 'damageRecordUpdated'
  | 'ignitionChanged'
  | 'lockChanged'
  | 'serviceNoteAdded'
  | 'serviceReported'
  | 'shockDetected'
  | 'statusChanged'
  | 'tripFinished'
  | 'tripReserved'
  | 'tripStarted';

const iconMapper = {
  areaChanged: `${IMAGE_PATH}/dot.svg`,
  bookingAssigned: `${IMAGE_PATH}/clock.svg`,
  damageRecordUpdated: `${IMAGE_PATH}/redDot.svg`,
  ignitionChanged: `${IMAGE_PATH}/thunderbolt.svg`,
  lockChanged: `${IMAGE_PATH}/lock.svg`,
  serviceNoteAdded: `${IMAGE_PATH}/message.svg`,
  serviceReported: `${IMAGE_PATH}/list.svg`,
  shockDetected: `${IMAGE_PATH}/warning.svg`,
  borderCrossing: `${IMAGE_PATH}/dot.svg`,
  statusChanged: `${IMAGE_PATH}/dot.svg`,
  tripFinished: `${IMAGE_PATH}/lock.svg`,
  tripReserved: `${IMAGE_PATH}/clock.svg`,
  tripStarted: `${IMAGE_PATH}/unlock.svg`,
  lock: `${IMAGE_PATH}/lock.svg`,
  unlock: `${IMAGE_PATH}/unlock.svg`,
  imageAdded: `${IMAGE_PATH}/camera.svg`,
  noteAdded: `${IMAGE_PATH}/message.svg`,
  documentAdded: `${IMAGE_PATH}/clip.svg`,
  recordAdded: `${IMAGE_PATH}/message.svg`,
  checkedIn: `${IMAGE_PATH}/login.svg`,
  checkedOut: `${IMAGE_PATH}/logout.svg`,
};

const bodyMapper = {
  areaChanged: AreaChanged,
  bookingAssigned: BookingAssigned,
  damageRecordUpdated: DamageRecordUpdated,
  ignitionChanged: IgnitionChanged,
  lockChanged: Trip,
  borderCrossing: BorderCrossed,
  serviceNoteAdded: ServiceNoteAdded,
  serviceReported: ServiceReported,
  shockDetected: ShockDetected,
  statusChanged: StatusChanged,
  tripFinished: Trip,
  tripReserved: TripReserved,
  tripStarted: Trip,
  checkedOut: TripChecked,
  checkedIn: TripChecked,
  imageAdded: ImageAdded,
  noteAdded: NoteAdded,
  documentAdded: DocumentAdded,
  recordAdded: RecordAdded,
};

const NotMatched = () => <p>type is not matched</p>;

type TimelineItemProps = {
  vehicleEvent: any;
  hideSecondBar?: boolean;
  hideFirstBar?: boolean;
  onClick?: () => void;
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  vehicleEvent,
  hideSecondBar,
  hideFirstBar,
  onClick = () => {},
}) => {
  const intl = useIntl();
  const {
    type,
    carLocked,
    ignitionOff,
    initialTripIgnition,
    lastTripIgnition,
    addedAt,
    connectionType,
  } = vehicleEvent;
  const selectedTrip = useSelector(selectSelectedTrip);

  let updatedType = type;
  if (connectionType === 'geoTab') {
    if (type === 'tripStarted') {
      updatedType = 'checkedOut';
    } else if (type === 'tripFinished') {
      updatedType = 'checkedIn';
    }
  }
  // @ts-ignore
  const iconSrc = iconMapper[updatedType] || `${IMAGE_PATH}/dot.svg`;
  // @ts-ignore
  const Body = bodyMapper[updatedType] || NotMatched;
  const dateTime = formatDateTime(addedAt);

  let title = intl.formatMessage({
    id: `fleetadmin.event.${updatedType}`,
  });
  if (updatedType === 'lockChanged') {
    title = intl.formatMessage({
      id: `fleetadmin.event.${carLocked ? 'unlocked' : 'locked'}`,
    });
  } else if (updatedType === 'ignitionChanged') {
    title = intl.formatMessage({
      id: `fleetadmin.event.${
        ignitionOff
          ? lastTripIgnition && selectedTrip?.status === 'DONE'
            ? 'ignitionOffFinally'
            : 'ignitionOff'
          : initialTripIgnition
          ? 'ignitionOnInitially'
          : 'ignitionOn'
      }`,
    });
  } else if (updatedType === 'imageAdded') {
    title = intl.formatMessage({
      id: `fleetadmin.event.${vehicleEvent.imageType}`,
    });
  }

  return (
    <>
      <div className={'timeline-item-wrapper'}>
        <div className="left">
          <div
            className={cn('first', 'bar', {
              hidden: hideFirstBar,
            })}
          />
          <div className="icon-wrapper">
            <img src={iconSrc} alt={`${updatedType} icon`} />
          </div>
          <div
            className={cn('second', ' bar', {
              hidden: hideSecondBar,
            })}
          />
        </div>
        <div className="right">
          <p className="title">
            {title}
            <span className="date-time">{dateTime}</span>
          </p>
          <div className="body">
            <Body vehicleEvent={vehicleEvent} intl={intl} onClick={onClick} />
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TimelineItem;
