import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LinkToVehicle.styles';
import {
  selectCarWithoutMapEffect,
  selectSelectedDrawersCount,
} from '../../redux/store/ui/common';
import { FleetAdminCarData } from '../../@types';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface Props {
  text?: string;
  vehicle?: FleetAdminCarData;
  activeTab?: number;
}

const LinkToVehicle: React.FC<Props> = ({ text, vehicle, activeTab }) => {
  const dispatch = useDispatch();
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  const onClickHandler = (e: any) => {
    e.preventDefault();
    if (vehicle != null && openedDrawersCount < 2) {
      dispatch(selectCarWithoutMapEffect(vehicle, false, activeTab));
    }
  };

  return (
    <>
      {openedDrawersCount < 2 ? (
        <span className="link" onClick={onClickHandler}>
          {text || ''}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=VEHICLE&type=TABLE&pageSize=50&pageNumber=1&carId=${vehicle.id}`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link">{text || ''}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToVehicle;
