import { CircleData } from '../@types';

const getCirclesNameById = (circles: CircleData[], circleId: any) => {
  if (circles == null) {
    return circleId;
  }

  const theCircle = circles.find((circle) => circle.id === circleId);
  return theCircle ? theCircle.name : circleId;
};

export default getCirclesNameById;
