import React from 'react';
import { Select } from 'antd';
import styles from './CarEditorCategorySelector.styles';
import { zIndex } from '../../theme/theme';

const { Option } = Select;

type CategorySelectorProps = {
  categories: any;
  style?: any;
  className?: string;
  defaultValue?: string;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  onSelect?: (value: string) => void;
};

const CategorySelector: React.FC<CategorySelectorProps> = ({
  categories,
  style,
  className,
  defaultValue,
  value,
  disabled,
  loading,
  placeholder,
  onSelect,
}) => {
  return (
    <div style={style} className={className}>
      <Select
        defaultValue={defaultValue}
        value={value}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
        style={{ width: '100%' }}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder}
        onChange={onSelect}
      >
        {categories &&
          [...categories].map((category) => (
            <Option key={`area-option-${category}`} value={category.id}>
              {category.name}
            </Option>
          ))}
      </Select>
      <style jsx>{styles}</style>
    </div>
  );
};

export default CategorySelector;
