import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .update-car-category-modal {
    width: 380px;
    padding: 16px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .connection-selector-wrapper,
  .reason-selector-wrapper {
    margin-bottom: 8px;
  }
  .update-car-category-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .update-car-category-modal :global(.submit-button:hover) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .update-car-category-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .update-car-category-modal :global(.cancel-button:hover) {
    ${common.button}
    ${common.cancelButton}
  }
`;

export default style;
