import css from 'styled-jsx/css';

const style = css`
  .wrapper {
    position: relative;
    font-weight: 500;
    cursor: pointer;
    margin: -16px;
    padding: 16px;
  }
  .wrapper.new {
    border-left: 4px solid #0ab735;
    padding-left: 12px;
  }
  .pop {
    color: #0ab735;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    top: 2px;
    margin: 0;
  }
  .VIN {
    font-size: 12px;
  }

  @media only screen and (min-width: 768px) {
    .VIN {
      font-size: 14px;
    }
  }
`;

export default style;
