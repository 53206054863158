import { RootState } from '../../../config';

export const selectVisibleCarMarkers = (state: RootState) =>
  state.ui.map.visibleCarMarkers;

export const selectVisibleAreaPolygons = (state: RootState) =>
  state.ui.map.visibleAreaPolygons;

export const selectVisibleParkingAreasPolygons = (state: RootState) =>
  state.ui.map.visibleParkingAreasPolygons;

export const selectMapCenter = (state: RootState) => state.ui.map.center;

export const selectMapZoom = (state: RootState) => state.ui.map.zoom;
