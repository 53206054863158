export enum ProfilesTypes {
  PROFILES_REQUEST = 'userData/Profiles/PROFILES_REQUEST',
  PROFILES_SUCCESS = 'userData/Profiles/PROFILES_SUCCESS',
  PROFILES_FAILURE = 'userData/Profiles/PROFILES_FAILURE',
  PROFILE_REQUEST = 'userData/Profiles/PROFILE_REQUEST',
  PROFILE_SUCCESS = 'userData/Profiles/PROFILE_SUCCESS',
  PROFILE_FAILURE = 'userData/Profiles/PROFILE_FAILURE',
  UPDATE_PROFILE_REQUEST = 'userData/Profiles/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'userData/Profiles/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = 'userData/Profiles/UPDATE_PROFILE_FAILURE',
  SELECT_PROFILE = 'userData/Profiles/SELECT_PROFILE',
  SET_DISPLAY_PROFILES = 'userData/Profiles/SET_DISPLAY_PROFILES',
  CREATE_PROFILE_REQUEST = 'userData/Profiles/CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS = 'userData/Profiles/CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE = 'userData/Profiles/CREATE_PROFILE_FAILURE',
  UPLOAD_IMAGE_REQUEST = 'userData/Profiles/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS = 'userData/Profiles/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE = 'userData/Profiles/UPLOAD_IMAGE_FAILURE',
  DELETE_IMAGE_REQUEST = 'userData/Profiles/DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS = 'userData/Profiles/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE = 'userData/Profiles/DELETE_IMAGE_FAILURE',
}
