import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
// @ts-ignore
import cookie from 'js-cookie';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './UpdatePricingModal.styles';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import {
  fetchBrandPricingsIfNeeded,
  selectBrandPricings,
} from '../../redux/store/appData/pricings';
import {
  selectSelectedCircle,
  updateCircleIfNeeded,
} from '../../redux/store/userData/circles';
import PricingSelector from './CirclePricingSelector';
import { useIntl } from 'react-intl';

const UpdatePricingModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCircle = useSelector(selectSelectedCircle);
  const brandsPricings = useSelector(selectBrandPricings);
  const visibleModal = useSelector(
    getIsVisibleModal('updateCirclePricingModal')
  );

  const brandPricings = selectedCircle?.brandId
    ? brandsPricings[selectedCircle?.brandId]
    : null;
  const currentPricing =
    selectedCircle?.pricing && brandPricings
      ? brandPricings.find(
          (pricing: { name: string }) => pricing.name === selectedCircle.pricing
        )
      : null;
  const [selectedPricing, setSelectedPricing] = useState(currentPricing?.id);

  const submitDisabled = useMemo(() => {
    const selectedPricingEntity = brandPricings?.find(
      (pricing: { id: any }) => pricing.id === selectedPricing
    );
    return selectedPricingEntity?.name === selectedCircle?.pricing;
  }, [selectedCircle, selectedPricing]);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateCircleIfNeeded(token, selectedCircle?.id!, {
        pricingId: selectedPricing,
      })
    );
    dispatch(closeModal('updateCirclePricingModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateCirclePricingModal'));
  };

  const handleOnChange = (value: any) => {
    setSelectedPricing(value);
  };

  const loadPricings = useCallback(async () => {
    const cookieToken = await cookie.get('token');
    if (selectedCircle?.brandId) {
      dispatch(
        fetchBrandPricingsIfNeeded(cookieToken!, selectedCircle.brandId)
      );
    }
  }, []);

  useEffect(() => {
    if (selectedCircle?.brandId && !brandPricings) {
      loadPricings();
    }
    setSelectedPricing(null);
  }, [selectedCircle]);

  useEffect(() => {
    setSelectedPricing(currentPricing?.id || null);
  }, [currentPricing]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateCirclePricingModal'));
        }}
      >
        <div className="update-pricing-modal">
          <div className="pricing-selector-wrapper">
            <p className="label">Group Pricing</p>
            <PricingSelector
              pricings={brandPricings}
              defaultValue={selectedPricing}
              value={selectedPricing}
              onSelect={handleOnChange}
              loading={false}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={submitDisabled}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdatePricingModal;
