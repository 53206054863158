import React from 'react';

export const getRenderWithPlaceholder =
  (placeholder = '-') =>
  // eslint-disable-next-line react/display-name
  (
    value:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined
  ) =>
    (
      <span style={{ color: '#0077be', cursor: 'pointer' }}>
        {value ? value : placeholder}
      </span>
    );
