import React from 'react';
import { useSelector } from 'react-redux';
import TargetSelector from './TargetSelector';
import ValueSelector from './ValueSelector';
import styles from './FilterPanel.styles';
import { selectFilterTarget } from '../../redux/store/ui/filters';

type FilterPanelProps = {};

const FilterPanel: React.FC<FilterPanelProps> = () => {
  const target = useSelector(selectFilterTarget);

  return (
    <>
      <div className="filter-panel">
        {!target && <TargetSelector />}
        {target && <ValueSelector />}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default FilterPanel;
