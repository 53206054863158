import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css.global`
  .upload-wrapper {
    padding: 24px 24px 24px 0;
  }
  .upload-users-button-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 12px 24px 0 0;
  }

  .upload-users-button-container :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .upload-users-button-container :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
`;

export default style;
