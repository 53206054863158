import { FleetAdminCircleData } from '../../../../@types';
import { RootState } from '../../../config';

export const selectCircles = (
  state: RootState
): FleetAdminCircleData[] | null => state.userData?.circles?.entities;

export const selectDisplayCircles = (
  state: RootState
): FleetAdminCircleData[] | null => state.userData?.circles?.display;

export const selectSelectedCircle = (
  state: RootState
): FleetAdminCircleData | null => state.userData?.circles?.selected;

export const selectFetchedCircles = (state: RootState) =>
  state.userData?.circles?.fetchedCircles;

export const selectLoadingCircle = (state: RootState) =>
  state.userData?.circles?.loadingCircle;

export const selectCirclesLoading = (state: RootState): boolean =>
  state.userData.circles.loading;
