import { request, requestAPI } from '../../../../utils/request';
import {
  categoriesFailure,
  categoriesSuccess,
  categoriesRequest,
  setDisplayCategories,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { CategoriesState } from './initialState';

export const fetchCategories = () => (dispatch: Dispatch) => {
  dispatch(categoriesRequest());
  requestAPI('/fleets/cars/categories')
    .then((data) => {
      dispatch(categoriesSuccess(data));
      dispatch(setDisplayCategories(data));
    })
    .catch((err) => {
      message.error(err.message);
      dispatch(categoriesFailure(err));
    });
};

const shouldFetchCategories = (categories: CategoriesState) =>
  !categories?.entities && !categories?.loading;

export const fetchCategoriesIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCategories(getState().appData.categories)) {
      return dispatch(fetchCategories());
    } else {
      return Promise.resolve();
    }
  };
};

export const createCategory =
  (token: string, category: any, callback: () => void) => () => {
    requestAPI(
      `/fleets/cars/categories`,
      {},
      {
        method: 'POST',
        body: JSON.stringify(category),
      }
    )
      .then(() => {
        message.success('Category created successfully');
        callback();
      })
      .catch((err) => {
        message.error(err?.message);
      });
  };
