import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './FeeItemModal.styles';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';

type Props = {
  selectedFee: any;
  handleCancel: () => void;
  onUpdate: (feeCode: string, feePrice: string, comment: string) => void;
};

const FeeItemModal = ({ selectedFee, handleCancel, onUpdate }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [feePrice, setFeePrice] = useState('');
  const [comment, setComment] = useState('');

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onUpdate(selectedFee?.code, feePrice, comment);
    handleCancel();
  };

  const visible = !!selectedFee?.code;

  useEffect(() => {
    if (visible) {
      setFeePrice('');
      setComment(selectedFee?.priceComment || '');
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="fee-item-contents">
          <div className="fee-item-input-container">
            <div style={{ marginRight: 8 }}>
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.price.label`,
                })}
              </div>
              <Input
                value={feePrice}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.pricing.price.label`,
                })}
                onChange={(e) => setFeePrice(e.target.value)}
                style={{ marginRight: 12 }}
              />
            </div>
            <div className="fee-item-input-container">
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.fee.comment.label`,
                })}
              </div>
              <textarea
                className="comment-area"
                placeholder="Type here ..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                draggable={false}
              >
                Type here ...
              </textarea>
            </div>
          </div>
          <div className="button-container">
            <Button
              className="submit-button"
              onClick={handleOnSubmit}
              disabled={!feePrice}
            >
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};
export default FeeItemModal;
