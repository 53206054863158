import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './UpdateCategoryModal.styles';
import {
  selectSelectedCar,
  updateCarIfNeeded,
} from '../../redux/store/userData/cars';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { fetchCarConditionsIfNeeded } from '../../redux/helpers';
import { selectCategories } from '../../redux/store/userData/categories/selectors';
import CategorySelector from '../../components/CarEditDrawer/CarEditorCategorySelector';
import { selectFleets } from '../../redux/store/appData/fleets/selectors';
import { useIntl } from 'react-intl';

const UpdateCategoryModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const fleets = useSelector(selectFleets);
  const categories = useSelector(selectCategories);
  const visibleModal = useSelector(getIsVisibleModal('updateCategoryModal'));

  const currentCategory = selectedCar?.categoryId;
  const [selectedCategory, setSelectedCategory] = useState(currentCategory);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateCarIfNeeded(
        token,
        selectedCar?.id!,
        {
          categoryId: selectedCategory,
        },
        () => {
          dispatch(
            fetchCarConditionsIfNeeded(token, selectedCar?.id!, false, true)
          );
        }
      )
    );
    dispatch(closeModal('updateCategoryModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateCategoryModal'));
  };

  const handleOnChange = (value: React.SetStateAction<string | undefined>) => {
    setSelectedCategory(value);
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateCategoryModal'));
        }}
      >
        <div className="update-car-category-modal">
          <div className="connection-selector-wrapper">
            <p className="label">Vehicle Category</p>
            <CategorySelector
              categories={categories?.filter(
                (category) =>
                  category?.brandId ===
                  fleets?.find((fleet) => fleet.id === selectedCar?.fleetId)
                    ?.brandId
              )}
              className="car-editor-area-selector"
              defaultValue={selectedCategory}
              value={selectedCategory}
              onSelect={handleOnChange}
              loading={false}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={currentCategory === selectedCategory}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateCategoryModal;
