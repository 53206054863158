import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import BookingInfo from './BookingInfo';
import styles from './BookingDrawerBody.styles';
import BookingStatus from '../../components/shared/BookingStatus';
import { selectSelectedBooking } from '../../redux/store/userData/trips';

const BookingDrawerBody: React.FC = () => {
  const selectedBooking = useSelector(selectSelectedBooking);
  const tabs = [{ title: 'Info', content: <BookingInfo /> }];

  return (
    <div className="booking-drawer-body">
      <div className="booking-drawer-top">
        <p className="booking-code">{selectedBooking?.presentableId}</p>
        <BookingStatus
          status={selectedBooking?.status}
          style={{ marginLeft: '12px' }}
        />
      </div>
      <TabLayout tabs={tabs} keyName="booking-drawer" />
      <style jsx>{styles}</style>
    </div>
  );
};

export default BookingDrawerBody;
