import css from 'styled-jsx/css';

const style = css`
  .text-input-wrapper {
  }
  .text-input-label {
    font-size: 12px;
    margin-bottom: 2px;
    padding-left: 4px;
  }
`;

export default style;
