import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './EditAdminInfoModal.styles';
import { colors } from '../../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import {
  updateAdmin,
  selectSelectedAdmin,
} from '../../../redux/store/userData/admins';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import SelectorLayout from '../../shared/SelectorLayout';
import { parseLocale } from '../../../utils';

export const LANGUAGES = [
  { label: 'DE', value: 'DE' },
  { label: 'EN', value: 'EN' },
];

const EditAdminInfoModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [locale, setLocale] = useState('');

  const selectedAdmin = useSelector(selectSelectedAdmin);

  const visible = useSelector(getIsVisibleModal('EditAdminInfoModal'));
  const isDisabled = !(firstName && lastName && email);

  const handleCancel = () => {
    dispatch(closeModal('EditAdminInfoModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (selectedAdmin) {
      dispatch(
        updateAdmin({
          firstName,
          lastName,
          email,
          locale: locale.toLowerCase(),
        })
      );
    }
    handleCancel();
  };

  const onSelectLocale = (value: any) => {
    setLocale(value);
  };

  useEffect(() => {
    if (visible) {
      setFirstName(selectedAdmin?.firstName || '');
      setLastName(selectedAdmin?.lastName || '');
      setEmail(selectedAdmin?.email || '');
      setLocale(
        selectedAdmin?.locale
          ? parseLocale(selectedAdmin?.locale)?.toUpperCase()
          : 'DE'
      );
    }
  }, [visible]);

  return (
    <>
      <div>
        <DrawerModal visible={visible} onClose={handleCancel}>
          <div className="update-admin-contents" tabIndex={0}>
            <div className="update-admin-title">
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update.title`,
              })}
            </div>
            <div className="input-container">
              <div className="update-admin-input-row-container">
                <Input
                  value={firstName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.admins.info.firstName`,
                  })}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{ marginRight: 12 }}
                />
                <Input
                  value={lastName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.admins.info.lastName`,
                  })}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <Input
                value={email}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.admins.info.email`,
                })}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: 16 }}
              />
              <SelectorLayout
                keyPrefix="brand-select"
                value={locale}
                onSelect={onSelectLocale}
                options={LANGUAGES}
                style={{ marginTop: 16 }}
              />
            </div>
            <div className="button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `fleetadmin.admins.info.update`,
                })}
              </Button>
              <Button className="cancel-button" onClick={handleCancel}>
                {intl.formatMessage({
                  id: `fleetadmin.admins.info.cancel`,
                })}
              </Button>
            </div>
          </div>
        </DrawerModal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default EditAdminInfoModal;
