import { OutputCarData } from '../../@types';

const defaultCarEntity = {
  id: '',
  status: '',
  brand: '',
  model: '',
  plate: '',
  areaName: '',
  areaId: '',
  position: '',
  fuelLevel: null,
  chassisNumber: '',
  images: [],
  fuelCardNumber: '',
  gear: '',
  enginePower: null,
  pollutionBadge: '',
  initialRegistration: '',
  rentalCount: null,
  fuel: '',
  type: '',
  notes: null,
  equippedSince: null,
  categoryId: '',
  backBy: '',
};

export const seasonToCar = (car: OutputCarData): any => {
  try {
    return { ...defaultCarEntity, ...car };
  } catch (e) {
    console.log('exception while processing car ', e);
    return null;
  }
};

const seasonToCars = (cars: OutputCarData[]) => cars?.map(seasonToCar);

export default seasonToCars;
