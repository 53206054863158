import { FleetServiceTripData } from '../../../@types';
import serviceTypes from '../../../assets/serviceTypes';

export const optionsForServiceStatus: Array<FleetServiceTripData['status']> = [
  'PLANNED',
  'OPEN',
  'IN_PROGRESS',
  'CLOSED',
];

export const optionsForServiceType: FleetServiceTripData['serviceTypes'] =
  serviceTypes;
