import React from 'react';
import { useSelector } from 'react-redux';
import { DamageEntityData } from '../../@types';
import RecordTable from './RecordTable';
import { selectSelectedClaim } from '../../redux/store/userData/claims/selectors';
import { useIntl } from 'react-intl';

type RecordProps = {
  carRecords: DamageEntityData[];
};

const Record: React.FC<RecordProps> = ({ carRecords }) => {
  const intl = useIntl();
  const selectedClaim = useSelector(selectSelectedClaim);
  return (
    <div className="record-wrapper">
      <RecordTable
        intl={intl}
        rowKey="record"
        dataSource={carRecords}
        selectedId={selectedClaim?.id}
        onClickRow={() => {}}
      />
    </div>
  );
};

export default Record;
