import { GAdminUserDataOutput } from '../redux/store/userData/users';

export const getPaymentIcon = (payment) =>
  `/images/list-action-item/${
    payment.methodType == 'payIn'
      ? 'paymentPayIn.png'
      : payment.methodType == 'paypal'
      ? 'paymentPaypal.svg'
      : payment.methodType == 'sepa'
      ? 'paymentSepa.svg'
      : payment?.card?.variant === 'MasterCard'
      ? 'paymentMastercard.svg'
      : 'paymentVisa.svg'
  }`;

export const getStatusColor = (status) => {
  switch (status) {
    case 'open':
    case 'unknown':
    case 'EXPIRED':
      return 'grey';
    case 'listed':
    case 'submitted':
    case 'paused':
    case 'pending':
    case 'EXPIRING':
      return 'orange';
    case 'verified':
    case 'approved':
    case 'active':
    case 'success':
    case 'done':
    case 'true':
      return 'green';
    case 'primary':
    case 'PUBLISHED':
    case 'imported':
      return 'blue';
    case 'failed':
    case 'deleted':
    case 'blocked':
    case 'rejected':
    case 'archived':
    case 'error':
    case 'false':
      return 'red';
    default:
      return 'grey';
  }
};

export const isVerifiedUser = (user: GAdminUserDataOutput) =>
  user?.emailVerified &&
  user?.verified?.phoneNumber &&
  user?.addressVerified &&
  user?.verified?.identityDocument &&
  user?.verified?.drivingLicense &&
  user?.creditworthinessApproved;

export const findDuplicationReason = (user1, user2) => {
  if (
    user1?.identityDocument?.number &&
    user1?.identityDocument?.number === user2?.identityDocument?.number
  ) {
    return 'fleetadmin.users.same.id';
  }

  if (
    user1?.license?.number &&
    user1?.license?.number === user2?.license?.number
  ) {
    return 'fleetadmin.users.same.dl';
  }

  if (
    user1?.firstName &&
    user1?.lastName &&
    user1?.dateOfBirth &&
    user1?.firstName === user2?.firstName &&
    user1?.lastName === user2?.lastName &&
    user1?.dateOfBirth === user2?.dateOfBirth
  ) {
    return 'fleetadmin.users.same.identity';
  }

  return null;
};

export const getFilteredColumns = (columns, displayColumns) =>
  columns.filter((column) =>
    // @ts-ignore
    displayColumns?.includes(
      typeof column?.dataIndex === 'object'
        ? `${column?.dataIndex?.[0]}.${column?.dataIndex?.[1]}`
        : column?.dataIndex
    )
  );
