import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  :global(.area-file-upload) {
    width: 100%;
    display: inline-block;
  }
  :global(.ant-upload.ant-upload-select) {
    width: 100%;
  }
  :global(.upload-file.ant-btn) {
    color: ${colors.blue};
    padding-left: 0;
    border: 0;
  }
`;

export default style;
