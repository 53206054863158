import { ViewMode } from '../../../hooks/useViewMode';
import {
  optionsForVehicleStatus,
  optionsForVehicleGear,
  optionsForVehicleFuelType,
  optionsForVehicleBlockReasons,
  optionsForSupportedVehicleConnections,
  optionsForVehicleConnection,
} from '../assets/optionsForVehicle';
import {
  optionsForClaimStatus,
  optionsForClaimTripIgnition,
  optionsForClaimCloseReason,
} from '../assets/optionsForClaim';
import {
  optionsForServiceStatus,
  optionsForServiceType,
} from '../assets/optionsForCare';
import {
  optionsForUserStatus,
  optionsForCWStatus,
  optionsForCardStatus,
} from '../assets/optionsForUsers';
import { optionsForTripStatus } from '../assets/optionsForTrips';

const optionMapper = {
  [ViewMode.VEHICLE]: {
    status: optionsForVehicleStatus,
    gear: optionsForVehicleGear,
    fuel: optionsForVehicleFuelType,
    blockReasons: optionsForVehicleBlockReasons,
    supportedVehicleConnections: optionsForSupportedVehicleConnections,
    vehicleConnection: optionsForVehicleConnection,
  },
  [ViewMode.CLAIM]: {
    status: optionsForClaimStatus,
    tripIgnition: optionsForClaimTripIgnition,
    closeReason: optionsForClaimCloseReason,
  },
  [ViewMode.CARE]: {
    status: optionsForServiceStatus,
    serviceTypes: optionsForServiceType,
  },
  [ViewMode.SHARE]: {
    status: optionsForTripStatus,
  },
  [ViewMode.USERS]: {
    accountStatus: optionsForUserStatus,
    'creditworthinessData.creditWorthinessStatus': optionsForCWStatus,
    'identityDocuments.[0].status': optionsForCardStatus,
    'licenses.[0].licenseStatus': optionsForCardStatus,
  },
};

const getOptionValues = (vm: any, target: any) => {
  // @ts-ignore
  return optionMapper[vm][target] || [];
};

export default getOptionValues;
