import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css`
  .archive-area-modal {
    width: 380px;
    padding: 16px;
  }
  .archive-area-modal-inputs {
    width: 100%;
    padding-bottom: 16px;
  }
  .archive-area-modal-wrapper,
  .reason-selector-wrapper {
    margin-bottom: 8px;
  }
  .archive-area-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
    background-color: red;
  }
  .archive-area-modal :global(.submit-button:hover) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
    background-color: red;
  }
  .archive-area-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .archive-area-modal :global(.cancel-button:hover) {
    ${common.button}
    ${common.cancelButton}
  }
  .archive-area-title {
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 600;
  }
  .archive-area-desc {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export default style;
