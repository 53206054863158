import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingInsuranceEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  updateBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import { useIntl } from 'react-intl';
import { FleetBookingInputData } from '../../../@types';
import SelectorLayout from '../../shared/SelectorLayout';
import { selectPricings } from '../../../redux/store/appData/pricings/selectors';

const BookingInsuranceEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedBooking = useSelector(selectSelectedBooking) as any;
  const visibleModal = useSelector(
    getIsVisibleModal('BookingInsuranceEditModal')
  );
  const pricings = useSelector(selectPricings) as any;
  const availableInsurances = pricings?.find(
    (item) => item.id === selectedBooking?.pricingId
  )?.insurances;

  const [insuranceId, setInsuranceId] = useState('');

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const updateBookingInput: FleetBookingInputData = {
      ...(insuranceId && { insuranceId }),
    };
    dispatch(updateBooking(selectedBooking?.id, updateBookingInput));
    dispatch(closeModal('BookingInsuranceEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    dispatch(closeModal('BookingInsuranceEditModal'));
  };

  useEffect(() => {
    if (visibleModal) {
      setInsuranceId('');
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingInsuranceEditModal'));
        }}
      >
        <div className="booking-user-edit-modal">
          <div className="booking-user-edit-wrapper">
            <div className="booking-user-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.booking.update.insurance.title',
                })}
              </p>
            </div>
          </div>
          <>
            <SelectorLayout
              keyPrefix="circle-select"
              value={insuranceId}
              placeholder={intl.formatMessage({
                id: 'fleetadmin.booking.insurance.label',
              })}
              options={[
                ...(availableInsurances?.map((insurance) => ({
                  value: insurance?.id,
                  label: insurance?.name,
                })) || []),
              ]}
              onSelect={(value) => setInsuranceId(value)}
            />
          </>

          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.assign',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingInsuranceEditModal;
