import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import styles from './TextSearch.styles';

type TextSearchProps = {
  dataSource: any[];
  setValue: (arg: any, arg2?: any) => void;
  placeholder?: string;
  defaultOpen?: boolean;
  defaultValue?: string;
  style?: any;
};

const TextSearch: React.FC<TextSearchProps> = ({
  dataSource,
  setValue,
  placeholder,
  defaultOpen,
  defaultValue,
  style,
}) => {
  const [inputVal, setInputVal] = useState(null);

  const onSelect = (value: any) => {
    if (value)
      setValue(
        value,
        dataSource.find((item) => item.value === value)
      );
  };

  const onBlur = () => {
    if (inputVal)
      setValue(
        inputVal,
        dataSource.find((item) => item.value === inputVal)
      );
  };

  const onChange = (value: any) => {
    if (value) {
      setInputVal(value);
      setValue(
        value,
        dataSource.find((item) => item.value === value)
      );
    }
  };

  return (
    <>
      <div className="text-search" style={style}>
        <AutoComplete
          className="text-input"
          popupClassName="auto-complete-popup"
          defaultValue={defaultValue || ''}
          placeholder={placeholder || 'Type here...'}
          dataSource={dataSource}
          onSelect={onSelect}
          onBlur={onBlur}
          onChange={onChange}
          defaultOpen={defaultOpen || false}
          // @ts-ignore
          filterOption={(inputValue, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              );
            }
          }}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TextSearch;
