import { AreaData, ErrorResponse } from '../../../../@types';

export interface AreaState {
  entities: AreaData[] | null;
  display: AreaData[] | null;
  selected: AreaData | null;
  hideOnMap: string[];
  error: ErrorResponse | null;
  loading: boolean;
  disabling: boolean;
  uploadingFile: boolean;
  creatingArea: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  hideOnMap: [],
  selected: null,
  error: null,
  loading: false,
  disabling: false,
  uploadingFile: false,
  creatingArea: false,
};
