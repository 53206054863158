import css from 'styled-jsx/css';

export const noVehicleStyles = css`
  .message {
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-align: center;
  }
`;

export const assignedVehiclesStyles = css`
  .assigned-vehicles {
    margin: 16px 24px 0;
    padding-left: 0;
    list-style: none;
  }
  .assigned-vehicle {
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    color: #0077be;
    border-bottom: 1px solid #979797;
    cursor: pointer;
  }
`;
