import React, { MouseEvent } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector, batch } from 'react-redux';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import getTheDayBefore from '../../utils/datetime/getTheDayBefore';
import getOneHourAfter from '../../utils/datetime/getOneHourAfter';
import { textStyles, userCodeStyles } from './TimelineItemBody.styles';
import { selectClaims, selectClaim } from '../../redux/store/userData/claims';
import { fetchVehicleEventsIfNeeded } from '../../redux/store/userData/vehicleEvents';
import { carDamages } from '../../redux/store/userData/cars';
import ImageAdded from './/ImageAdded';

type TextProps = {
  link?: boolean;
  onClick?: (e: MouseEvent) => void;
};

export const Text: React.FC<TextProps> = ({ children, link, onClick }) => (
  <>
    <p className={cn('text', { link })} onClick={onClick}>
      {children}
    </p>
    <style jsx>{textStyles}</style>
  </>
);

type UserCodeProps = {
  userCode: any;
};

export const UserCode: React.FC<UserCodeProps> = ({ userCode }) => (
  <>
    <p>by {userCode}</p>
    <style jsx>{userCodeStyles}</style>
  </>
);

type Props = {
  vehicleEvent: any;
  intl: any;
  onClick?: () => void;
};

export const AreaChanged: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { oldArea, newArea, userReferralCode, principalEmail, comment } = ve;
  return (
    <>
      {newArea && (
        <Text>
          {oldArea} → {newArea}
        </Text>
      )}
      {comment && <p>{`"${comment}"`}</p>}
      {userReferralCode || principalEmail ? (
        <UserCode userCode={userReferralCode || principalEmail} />
      ) : (
        <p>by system</p>
      )}
      <style jsx>{userCodeStyles}</style>
    </>
  );
};

export const BookingAssigned: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const bookingId = ve?.presentableBookingId;
  const { userReferralCode, principalEmail } = ve;

  return (
    <>
      {bookingId && <Text>{bookingId}</Text>}
      {principalEmail || userReferralCode ? (
        <UserCode userCode={principalEmail || userReferralCode} />
      ) : (
        <p>by system</p>
      )}
    </>
  );
};

export const DamageRecordUpdated: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const dispatch = useDispatch();
  const claims = useSelector(selectClaims);
  const selectedDamages = useSelector(carDamages);
  const thisClaim: any =
    selectedDamages?.find((claim) => claim?.id === ve?.damageId) ||
    claims?.find((claim) => claim?.damageId === ve?.damageId);
  const claimTitle = thisClaim?.damageTitle;
  const claimId = thisClaim?.damageId;

  const onClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const carId = thisClaim?.carId;
    const from: any = getTheDayBefore(
      thisClaim?.tripStartedAt || thisClaim?.addedAt
    ).toISOString();
    const to: any = getOneHourAfter(
      thisClaim?.tripFinishedAt || thisClaim?.addedAt
    ).toISOString();
    dispatch(selectClaim(thisClaim));
    batch(() => {
      dispatch(
        fetchVehicleEventsIfNeeded(
          carId,
          from,
          to,
          ['damageClaim'],
          'CLAIM',
          false
        )
      );
    });
  };

  return (
    <>
      <Text>{claimTitle}</Text>
      <Text link={true} onClick={onClick}>
        {claimId}
      </Text>
    </>
  );
};

export const IgnitionChanged: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { geoLocation, fuelLevel, mileage, connectionType } = ve;
  return connectionType === 'geoTab' ? (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LinkToGoogleMap position={geoLocation} />
        <span style={{ marginLeft: 4 }}>
          | F {fuelLevel ? `${fuelLevel}%` : '-'}
        </span>
        <span style={{ marginLeft: 4 }}>
          | {mileage ? `${mileage}km` : '-'}
        </span>
      </div>
    </>
  ) : null;
};

export const LockChanged: React.FC<Props> = ({ vehicleEvent: ve }) => {
  return (
    <>
      <UserCode userCode={ve.userReferralCode} />
    </>
  );
};

export const ServiceNoteAdded: React.FC<Props> = ({ vehicleEvent: ve }) => {
  return (
    <>
      {ve?.note && <Text>{`"${ve?.note}"`}</Text>}
      <UserCode userCode={ve.userReferralCode} />
    </>
  );
};

export const ServiceReported: React.FC<Props> = ({ vehicleEvent: ve }) => {
  return (
    <>
      <UserCode userCode={ve.userReferralCode} />
    </>
  );
};

export const ShockDetected: React.FC<Props> = ({ vehicleEvent: ve }) => {
  return (
    <>
      <Text>
        <LinkToGoogleMap position={ve.geoLocation} />
      </Text>
    </>
  );
};

export const StatusChanged: React.FC<Props> = ({ vehicleEvent: ve, intl }) => {
  const { oldStatus, newStatus, userReferralCode, principalEmail, comment } =
    ve;

  return (
    <Text>
      {newStatus && (
        <Text>
          {oldStatus} → {newStatus}
        </Text>
      )}
      {comment && <p>{`"${comment}"`}</p>}
      {newStatus === 'BLOCKED' &&
        ve?.blockReasons?.length > 0 &&
        ve.blockReasons.map((reason: string) => (
          <div title={reason} className="tooltip" key={reason}>
            <img
              src={`/images/blocked-reasons/${reason}.svg`}
              alt={reason}
              style={{ marginRight: '12px', width: '14px', height: '14px' }}
            />
          </div>
        ))}
      {principalEmail || userReferralCode ? (
        <UserCode userCode={principalEmail || userReferralCode} />
      ) : (
        <p>by system</p>
      )}
      <style jsx>{userCodeStyles}</style>
    </Text>
  );
};

export const NoteAdded: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { text, author } = ve;

  return (
    <Text>
      {text && <Text>{`"${text}"`}</Text>}
      {author && <UserCode userCode={author} />}
    </Text>
  );
};

export const DocumentAdded: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { text, author, file } = ve;

  return (
    <Text>
      {text && <Text>{`"${text}"`}</Text>}
      {author && <UserCode userCode={author} />}
      {file && (
        <ImageAdded
          vehicleEvent={{
            images: [file],
          }}
        />
      )}
    </Text>
  );
};

export const RecordAdded: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { text } = ve;

  return (
    <>
      <ImageAdded vehicleEvent={ve} />
      <div style={{ marginTop: 8 }} />
      <Text>
        {text && <Text>{`"${text}"`}</Text>}
        <UserCode userCode="GETAWAY Support" />
      </Text>
    </>
  );
};

export const TripReserved: React.FC<Props> = ({ vehicleEvent: ve }) => {
  return (
    <>
      <UserCode userCode={ve.userReferralCode} />
    </>
  );
};

export const Trip: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { geoLocation, fuelLevel, mileage } = ve;
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LinkToGoogleMap position={geoLocation} />
        <span style={{ marginLeft: 4 }}>
          | F {fuelLevel ? `${fuelLevel}%` : '-'}
        </span>
        <span style={{ marginLeft: 4 }}>
          | {mileage ? `${mileage}km` : '-'}
        </span>
      </div>
    </>
  );
};

export const BorderCrossed: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { fromCountry, toCountry, allowed } = ve;
  return (
    <>
      <p>
        <span>
          {` ${fromCountry ? fromCountry : '-'} -> ${
            toCountry ? toCountry : '-'
          } `}
        </span>
        <span>{`${allowed ? '' : '(violation)'}`}</span>
      </p>
    </>
  );
};

export const ImageAddedLink: React.FC<Props> = ({ onClick }) => {
  return (
    <>
      <Text link={true} onClick={onClick}>
        4 photos added
      </Text>
    </>
  );
};

export const TripChecked: React.FC<Props> = ({ vehicleEvent: ve }) => {
  const { ownerArea } = ve;
  return (
    <>
      <p>
        <span> {ownerArea ? `${ownerArea}` : '-'}</span>
      </p>
    </>
  );
};
