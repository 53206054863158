import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectCircleWithUIEffect,
  selectDisplayColumnsForCircle,
} from '../../redux/store/ui/common';
import {
  fetchCircles,
  selectCircles,
  selectDisplayCircles,
  selectSelectedCircle,
} from '../../redux/store/userData/circles';
import { FleetAdminCircleData } from '../../@types';
import { useIntl } from 'react-intl';
import { getFilteredColumns } from '../../utils';

const CircleTable = () => {
  const dispatch = useDispatch();
  const displayCircles = useSelector(selectDisplayCircles);
  const circlesEntities = useSelector(selectCircles);
  const selectedCircle = useSelector(selectSelectedCircle);
  const displayColumns = useSelector(selectDisplayColumnsForCircle);
  const intl = useIntl();

  const selectedId = selectedCircle ? selectedCircle.id : null;
  const columns = getColumns(intl);
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: FleetAdminCircleData) => {
    dispatch(selectCircleWithUIEffect(record));
  };

  useEffect(() => {
    if (!circlesEntities) {
      dispatch(fetchCircles());
    }
  }, []);

  return (
    <TableLayout
      rowKey="circle"
      columns={filteredColumns}
      dataSource={displayCircles!}
      selectedId={selectedId!}
      totalNumber={displayCircles?.length}
      onClickRow={onClickRow}
    />
  );
};

export default CircleTable;
