import * as React from 'react';
import moment from 'moment';
import {
  getRenderLink,
  renderDateTime,
  alphabeticalSorterFor,
  chronologicalSorterFor,
} from '../shared/TableLayout';
import TripStatus from '../../components/shared/TripStatus';
import { formatDistance, formatDuration } from '../../utils';
import isDesktop from '../../utils/sizeHelpers';

const getColumns = (): any[] => {
  const renderStatus = (status: string) => <TripStatus status={status} />;
  const renderDuration = (duration: string, trip: { status: string }) => {
    if (!duration || trip.status !== 'DONE') {
      return <span>-</span>;
    }
    return <span>{formatDuration(duration)}</span>;
  };

  const renderDistance = (distance: number, trip: { status: string }) => {
    return (
      <span>{trip.status === 'DONE' ? formatDistance(distance) : '-'}</span>
    );
  };

  const renderViolation = (
    violation: string,
    trip: { status: string; endViolations: string | any[] }
  ) => (
    <span>
      {trip.status === 'DONE'
        ? trip.endViolations.includes(violation)
          ? 'True'
          : 'False'
        : '-'}
    </span>
  );

  return [
    {
      title: 'Trip ID',
      dataIndex: 'code',
      render: getRenderLink(),
      ellipsis: true,
      width: isDesktop() ? 120 : 100,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('code'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      width: 150,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: 'Plate No.',
      dataIndex: 'plate',
      width: 150,
      sorter: alphabeticalSorterFor('plate'),
    },
    {
      title: 'Contract ID',
      dataIndex: 'merchantRef',
      width: 150,
      sorter: alphabeticalSorterFor('merchantRef'),
    },
    {
      title: 'Fleet',
      dataIndex: 'fleetName',
      ellipsis: true,
      width: 200,
      sorter: alphabeticalSorterFor('fleetName'),
    },
    {
      title: 'User',
      dataIndex: 'userReferralCode',
      width: 120,
      sorter: alphabeticalSorterFor('userReferralCode'),
    },
    {
      title: 'Started at',
      dataIndex: 'tripStart',
      width: 150,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('tripStart'),
    },
    {
      title: 'Finished at',
      dataIndex: 'tripEnd',
      width: 150,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('tripEnd'),
    },
    {
      title: 'Insurance',
      dataIndex: 'insurance',
      width: 120,
      sorter: alphabeticalSorterFor('insurance'),
    },
    {
      title: 'Duration',
      width: 200,
      dataIndex: 'duration',
      ellipsis: true,
      render: renderDuration,
      sorter: (
        tripA: { duration: moment.DurationInputArg1 },
        tripB: { duration: moment.DurationInputArg1 }
      ) =>
        moment.duration(tripA.duration).asDays() -
        moment.duration(tripB.duration).asDays(),
    },
    {
      title: 'Mileage',
      dataIndex: 'distance',
      width: 120,
      render: renderDistance,
      sorter: (tripA: { distance: number }, tripB: { distance: number }) => {
        return tripA.distance - tripB.distance;
      },
    },
    {
      title: 'Violation: Missing key',
      dataIndex: 'violationMissionKey',
      render: (
        _: any,
        trip: { status: string; endViolations: string | any[] }
      ) => renderViolation('keyMissing', trip),
      width: 200,
      ellipsis: true,
      sorter: (
        tripA: { endViolations: { includes: (arg0: string) => number } },
        tripB: { endViolations: { includes: (arg0: string) => number } }
      ) => {
        return (
          tripA.endViolations.includes('keyMissing') -
          tripB.endViolations.includes('keyMissing')
        );
      },
    },
    {
      title: 'Violation: Missing fuel card',
      dataIndex: 'violationMissingFuelCard',
      render: (
        _: any,
        trip: { status: string; endViolations: string | any[] }
      ) => renderViolation('fuelCardMissing', trip),
      width: 230,
      ellipsis: true,
      sorter: (
        tripA: { endViolations: { includes: (arg0: string) => number } },
        tripB: { endViolations: { includes: (arg0: string) => number } }
      ) => {
        return (
          tripA.endViolations.includes('fuelCardMissing') -
          tripB.endViolations.includes('fuelCardMissing')
        );
      },
    },
    {
      title: 'Violation: Return area',
      dataIndex: 'violationReturnArea',
      render: (
        _: any,
        trip: { status: string; endViolations: string | any[] }
      ) => renderViolation('returnedOutsideArea', trip),
      width: 200,
      ellipsis: true,
      sorter: (
        tripA: { endViolations: { includes: (arg0: string) => number } },
        tripB: { endViolations: { includes: (arg0: string) => number } }
      ) => {
        return (
          tripA.endViolations.includes('returnedOutsideArea') -
          tripB.endViolations.includes('returnedOutsideArea')
        );
      },
    },
    {
      title: 'Violation: Low fuel',
      dataIndex: 'violationLowFuel',
      render: (
        _: any,
        trip: { status: string; endViolations: string | any[] }
      ) => renderViolation('lowFuelLevel', trip),
      width: 200,
      ellipsis: true,
      sorter: (
        tripA: { endViolations: { includes: (arg0: string) => number } },
        tripB: { endViolations: { includes: (arg0: string) => number } }
      ) => {
        return (
          tripA.endViolations.includes('lowFuelLevel') -
          tripB.endViolations.includes('lowFuelLevel')
        );
      },
    },
  ];
};

export default getColumns;
