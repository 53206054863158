import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingStartEndEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  updateBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import { isBefore } from 'date-fns';
import { DatePicker, TimePicker } from 'antd/es';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { ANT_PICKERS_FORMAT } from '../../../utils';

const BookingStartEndEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedBooking = useSelector(selectSelectedBooking) as any;
  const [startDate, setStartDate] = useState(
    dayjs(selectedBooking?.from)
  ) as any;
  const [startTime, setStartTime] = useState(
    dayjs(selectedBooking?.from)
  ) as any;
  const [endDate, setEndDate] = useState(dayjs(selectedBooking?.to)) as any;
  const [endTime, setEndTime] = useState(dayjs(selectedBooking?.to)) as any;
  const visibleModal = useSelector(
    getIsVisibleModal('BookingStartEndEditModal')
  );

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const selectedStartTime = new Date(startTime);
    const selectedStartDate = new Date(startDate);
    const selectedEndTime = new Date(endTime);
    const selectedEndDate = new Date(endDate);
    dispatch(
      updateBooking(selectedBooking?.id, {
        from: new Date(
          selectedStartDate.setHours(
            selectedStartTime.getHours(),
            selectedStartTime.getMinutes()
          )
        ).toISOString(),
        to: new Date(
          selectedEndDate.setHours(
            selectedEndTime.getHours(),
            selectedEndTime.getMinutes()
          )
        ).toISOString(),
      })
    );
    dispatch(closeModal('BookingStartEndEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('BookingStartEndEditModal'));
  };

  useEffect(() => {
    if (visibleModal) {
      setStartDate(dayjs(selectedBooking?.from));
      setStartTime(dayjs(selectedBooking?.from));
      setEndDate(dayjs(selectedBooking?.to));
      setEndTime(dayjs(selectedBooking?.to));
    }
  }, [visibleModal]);
  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingStartEndEditModal'));
        }}
      >
        <div className="booking-vehicle-edit-modal">
          <div className="booking-vehicle-edit-wrapper">
            <div className="booking-vehicle-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.booking.update.from.title',
                })}
              </p>
            </div>
          </div>
          <div>
            {intl.formatMessage({
              id: 'fleetadmin.booking.update.from.label',
            })}
            <br />
            <DatePicker
              format={ANT_PICKERS_FORMAT}
              disabledDate={(date) =>
                // @ts-ignore
                isBefore(new Date(date), new Date())
              }
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              style={{ width: '140px', marginTop: 2, marginBottom: 16 }}
              defaultValue={dayjs(selectedBooking?.from)}
              onChange={(value) => setStartDate(value)}
            />
            <TimePicker
              // @ts-ignore
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              format="HH:mm"
              style={{ width: '90px', marginLeft: 12 }}
              defaultValue={dayjs(selectedBooking?.from)}
              onChange={(value) => setStartTime(value)}
              minuteStep={15}
            />
          </div>
          <div>
            {intl.formatMessage({
              id: 'fleetadmin.booking.update.to.label',
            })}
            <br />
            <DatePicker
              format={ANT_PICKERS_FORMAT}
              disabledDate={(date) =>
                isBefore(
                  // @ts-ignore
                  new Date(date),
                  new Date(startDate || selectedBooking?.from)
                )
              }
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              style={{ width: '140px', marginTop: 2, marginBottom: 16 }}
              defaultValue={dayjs(selectedBooking?.to)}
              onChange={(value) => setEndDate(value)}
            />
            <TimePicker
              // @ts-ignore
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              format="HH:mm"
              style={{ width: '90px', marginLeft: 12 }}
              defaultValue={dayjs(selectedBooking?.to)}
              onChange={(value) => setEndTime(value)}
              minuteStep={15}
            />
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.update',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.cancel',
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingStartEndEditModal;
