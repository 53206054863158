const usersColumns = [
  { label: 'User code', value: 'referralCode', disabled: true },
  { label: 'Status', value: 'accountStatus', disabled: true },
  { label: 'Brand', value: 'brandName', disabled: false },
  { label: 'Title', value: 'title', disabled: false },
  { label: 'Email', value: 'email', disabled: false },
  { label: 'User Id', value: 'id', disabled: false },
  {
    label: 'Merchant reference',
    value: 'merchantUserReference',
    disabled: false,
  },
  { label: 'Balance', value: 'balance', disabled: false },
  { label: 'Phone number', value: 'phoneNumber', disabled: false },
  { label: 'Driving license', value: 'license.number', disabled: false },
  { label: 'First name', value: 'firstName', disabled: false },
  { label: 'Last name', value: 'lastName', disabled: false },
  { label: 'Signup at', value: 'created', disabled: false },
  { label: 'Phone verified', value: 'verified.phoneNumber', disabled: false },
  {
    label: 'Address exist',
    value: 'verified.address',
    disabled: false,
  },
  {
    label: 'Payment method',
    value: 'paymentMethods',
    disabled: false,
  },
  {
    label: 'Credit worthiness',
    value: 'creditworthinessData.creditWorthinessStatus',
    disabled: false,
  },
  {
    label: 'Identify card',
    value: 'identityDocuments.[0].status',
    disabled: false,
  },
  {
    label: 'Driving License',
    value: 'licenses.[0].licenseStatus',
    disabled: false,
  },
  {
    label: 'Tariff',
    value: 'tariff.name',
    disabled: false,
  },
  {
    label: 'User type',
    value: 'userType',
    disabled: false,
  },
];

export const defaultSelectedUsersColumns = usersColumns.map(
  (userColumn) => userColumn.value
);

export default usersColumns;
