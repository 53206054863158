import { RootState } from '../../../config';

export const selectReceipts = (state: RootState): any =>
  state.userData.receipts.entities;

export const selectTotalReceipts = (state: RootState): any =>
  state.userData.receipts.total;

export const selectDisplayReceipts = (state: RootState): any =>
  state.userData.receipts.display;

export const selectSelectedReceipt = (state: RootState): any =>
  state.userData.receipts.selected;

export const selectReceiptsLoading = (state: RootState): boolean =>
  state.userData.receipts.loading;

export const selectReceiptLoading = (state: RootState): boolean =>
  state.userData.receipts.receiptLoading;
