import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import ImageSlide from './ImageSlide';
import NoImagePlaceholder from './NoImagePlaceholder';
import styles from './DamageModal.styles';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';

type DamageModalProps = {
  visible?: boolean;
  damage?: {
    images: string[];
    addedAt: string;
    title: string;
    description: string;
  };
  carPlate?: string;
  onClose: () => void;
};

const DamageModal = ({
  damage,
  carPlate,
  visible,
  onClose,
}: DamageModalProps) => {
  const dispatch = useDispatch();
  const _visible = useSelector(getIsVisibleModal('damageModal'));

  const handleCancel = () => {
    onClose();
    dispatch(closeModal('damageModal'));
  };

  return (
    damage != null && (
      <>
        <div>
          <Modal
            wrapClassName="damage-modal-wrapper"
            title={`Damage #${carPlate}: ${damage.title}`}
            visible={visible || _visible}
            width={1520}
            onCancel={handleCancel}
            footer={null}
          >
            {damage.images.length === 0 ? (
              <NoImagePlaceholder />
            ) : (
              <ImageSlide images={damage.images} />
            )}
          </Modal>
        </div>
        <style jsx global>
          {styles}
        </style>
      </>
    )
  );
};

export default DamageModal;
