import css from 'styled-jsx/css';
import { colors } from '../../../theme/theme';

const style = css`
  .action-cell {
    color: ${colors.blue};
    cursor: pointer;
    margin: -16px;
    padding: 16px;
    white-space: nowrap;
  }
`;

export default style;
