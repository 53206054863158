import css from 'styled-jsx/css';

const style = css`
  .container {
    box-sizing: border-box;
    display: inline-flex;
  }
  .applied-filter {
    box-sizing: border-box;
    background: #fff;
    color: #313131;
    height: 18px;
    padding: 0 4px 0 8px;
    font-size: 10px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #c9c9c9;
    border-radius: 12px;
    margin: 4px;
  }
  .remove {
    margin-left: 4px;
    cursor: pointer;
  }
  .badge {
    position: relative;
    top: -5px;
    left: 12px;
  }
  @media only screen and (min-width: 768px) {
    .container {
      box-sizing: border-box;
      display: inline-flex;
    }
    .applied-filter {
      box-sizing: border-box;
      background: #fff;
      color: #313131;
      height: 24px;
      padding: 0 4px 0 8px;
      font-size: 14px;
      line-height: 14px;
      display: inline-flex;
      align-items: center;
      border: 1px solid #c9c9c9;
      border-radius: 12px;
      margin: 4px;
    }
    .remove {
      margin-left: 4px;
      cursor: pointer;
    }
    .badge {
      position: relative;
      top: -5px;
      left: 12px;
    }
  }
`;

export default style;
