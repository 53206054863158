const categoryColumns = [
  { label: 'Name', value: 'name', disabled: true },
  { label: 'Acriss Code', value: 'acrissCode', disabled: false },
  { label: 'Brand', value: 'brandId', disabled: false },
  { label: 'Description', value: 'description', disabled: false },
  { label: 'No.Vehicles', value: 'id', disabled: false },
];

export const defaultSelectedCategoryColumns = categoryColumns.map(
  (categoryColumn) => categoryColumn.value
);

export default categoryColumns;
