import React, { useEffect } from 'react';
import cn from 'classnames';
import useViewType, { ViewType } from '../../hooks/useViewType';
import styles from './ViewTypeSwitch.styles';
import isDesktop from '../../utils/sizeHelpers';
import { selectClaim } from '../../redux/store/userData/claims';
import { selectCar } from '../../redux/store/userData/cars';
import { selectArea } from '../../redux/store/appData/areas';
import { useDispatch, useSelector } from 'react-redux';
import { selectViewMode } from '../../redux/store/ui/common/selectors';
import { ViewMode } from '../../hooks/useViewMode';
import { useIntl } from 'react-intl';

// @ts-ignore
const ViewTypeSwitch = ({ viewType }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const { setViewType, initViewType } = useViewType();

  useEffect(() => {
    initViewType();
  }, []);

  const handleOnClickTable = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!isDesktop()) {
      dispatch(selectArea(null));
      dispatch(selectCar(null));
      dispatch(selectClaim(null));
    }
    setViewType(ViewType.TABLE);
  };

  const handleOnClickMap = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setViewType(ViewType.MAP);
  };

  const handleOnClickDisposition = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setViewType(ViewType.TIME_TABLE);
  };

  return (
    <>
      <div className="table-map-switcher">
        <span
          className={cn('item', {
            active: viewType === ViewType.TABLE,
          })}
          onClick={handleOnClickTable}
        >
          Table
        </span>
        <span
          className={cn('item', { active: viewType === ViewType.MAP })}
          onClick={handleOnClickMap}
        >
          Map
        </span>
        {false && isDesktop() && viewMode === ViewMode.VEHICLE && (
          <>
            <span
              className={cn('item', {
                active: viewType === ViewType.TIME_TABLE,
              })}
              onClick={handleOnClickDisposition}
            >
              Timetable
            </span>
            <span className="beta">beta</span>
          </>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ViewTypeSwitch;
