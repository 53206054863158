import { RootState } from '../../../config';

export const selectPricings = (state: RootState): any =>
  state.appData.pricings.entities;

export const selectBrandPricings = (state: RootState): any =>
  state.appData.pricings.brandPricings;

export const selectDisplayPricings = (state: RootState): any =>
  state.appData.pricings.display;

export const selectSelectedPricing = (state: RootState): any =>
  state.appData.pricings.selected;

export const selectPricignsLoading = (state: RootState): boolean =>
  state.appData.pricings.loading;
