import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectDisplayColumnsForAdmins,
  selectAdminWithUIEffect,
} from '../../redux/store/ui/common';
import {
  fetchAdmins,
  selectDisplayAdmins,
  selectSelectedAdmin,
  selectAdmins,
} from '../../redux/store/userData/admins';
import { useIntl } from 'react-intl';
import { selectFleets } from '../../redux/store/appData/fleets/selectors';
import { getFilteredColumns } from '../../utils';

const AdminsTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayAdmins = useSelector(selectDisplayAdmins);
  const adminsEntities = useSelector(selectAdmins);
  const selectedAdmin = useSelector(selectSelectedAdmin);
  const displayColumns = useSelector(selectDisplayColumnsForAdmins);
  const availableFleets = useSelector(selectFleets);

  const selectedId = selectedAdmin ? selectedAdmin.id : null;
  const columns = getColumns({ intl, availableFleets });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: any) => {
    dispatch(selectAdminWithUIEffect(record));
  };

  useEffect(() => {
    if (!adminsEntities) {
      dispatch(fetchAdmins());
    }
  }, []);

  return (
    <TableLayout
      rowKey="admin"
      columns={filteredColumns}
      dataSource={displayAdmins!}
      selectedId={selectedId!}
      totalNumber={displayAdmins?.length}
      onClickRow={onClickRow}
    />
  );
};

export default AdminsTable;
