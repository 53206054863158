import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css`
  .booking-cancel-modal {
    width: 380px;
    padding: 16px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .booking-cancel-modal-inputs {
    width: 100%;
    padding-bottom: 16px;
  }
  .booking-cancel-wrapper,
  .reason-selector-wrapper {
    margin-bottom: 8px;
  }
  .booking-cancel-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .booking-cancel-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .booking-cancel-modal :global(.submit-button:hover) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .booking-cancel-modal :global(.cancel-button:hover) {
    ${common.button}
    ${common.cancelButton}
  }
`;

export default style;
