import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import PricingEditDrawerBody from './PricingEditDrawerBody';
import { closePricingDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedPricing } from '../../redux/store/appData/pricings';

const PricingEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedPricing = useSelector(selectSelectedPricing);
  const visible = selectedPricing != null;

  const handleOnClose = () => {
    dispatch(closePricingDrawerAndSetQueries());
  };

  return (
    selectedPricing && (
      <DrawerLayout
        visible={visible}
        title={selectedPricing.id}
        body={<PricingEditDrawerBody />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default PricingEditDrawer;
