import React, { useRef } from 'react';
import {
  addDays,
  format,
  isAfter,
  addHours,
  isSameHour,
  isBefore,
  subMinutes,
} from 'date-fns';
import Slider from 'react-slick';
import { PlusOutlined } from '@ant-design/icons';
import 'slick-carousel/slick/slick.css';
import deLocale from 'date-fns/locale/de';
import styles from './BookingTable.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedCar,
  selectCarActiveBookings,
} from '../../redux/store/userData/cars/selectors';
import isDesktop from '../../utils/sizeHelpers';
import { DAY_HRS, DAY_PER_PAGE, NUMBER_OF_CALENDER_DAYS } from '../../utils';
import { openModal } from '../../redux/store/ui/common';
import { selectAuth } from '../../redux/store/userData/user';
import { selectFleets } from '../../redux/store/appData/fleets';
import { FleetBookingData } from '../../@types';
import { getBookingById } from '../../redux/store/userData/cars/thunk';

const BookingTable: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const fleets = useSelector(selectFleets);
  const selectedCar = useSelector(selectSelectedCar);
  const carBookings = useSelector(selectCarActiveBookings);
  const car = useSelector(selectSelectedCar);
  const sliderRef = useRef();

  const daysList = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < NUMBER_OF_CALENDER_DAYS; index++) {
    daysList.push(index);
  }

  const pagesDays = daysList.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / DAY_PER_PAGE);
    if (!resultArray[chunkIndex]) {
      // @ts-ignore
      resultArray[chunkIndex] = []; // start a new chunk
    }
    // @ts-ignore
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    arrows: false,
  };

  const onClickArrowRight = () => {
    if (sliderRef) {
      // @ts-ignore
      sliderRef.current.slickNext();
    }
  };
  const onClickArrowLeft = () => {
    if (sliderRef) {
      // @ts-ignore
      sliderRef.current.slickPrev();
    }
  };

  const onAddOfflineBooking = () => {
    dispatch(openModal('BookingCreateModal'));
  };

  const onClickBooking = (booking: FleetBookingData) => {
    if (booking?.id) {
      dispatch(getBookingById(booking.id));
    }
  };

  return (
    <>
      <div className="booking-table-container">
        {user?.permissions?.tripsManager &&
          fleets?.find((fleet) => fleet?.id === selectedCar?.fleetId)
            ?.offlineBookingsEnabled && (
            <div className="add-booking-button-wrapper">
              <div className="add-booking-button" onClick={onAddOfflineBooking}>
                <PlusOutlined />
              </div>
            </div>
          )}
        <div className="booking-table-colsWrapper">
          <div className="booking-table-timeColWrapper">
            <div className="booking-table-timeBoxHeader">
              <div className="booking-table-arrows">
                <img
                  className="booking-table-arrow-left"
                  src={'/images/timeline/arrowRight.svg'}
                  alt="edit"
                  onClick={onClickArrowLeft}
                />
                <img
                  className="booking-table-arrow-right"
                  src={'/images/timeline/arrowRight.svg'}
                  alt="edit"
                  onClick={onClickArrowRight}
                />
              </div>
            </div>
            {DAY_HRS.map((time, timeIndex) => (
              <div key={time} className="booking-table-timeBox">
                {timeIndex !== 0 && (
                  <div className="booking-table-timeText">
                    <pre className="booking-table-timeText-pre">{`${time}   `}</pre>
                    <div className="booking-table-timeTextDashWrapper">
                      <div className="booking-table-timeTextDash">{`  |`}</div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <Slider
            // @ts-ignore
            ref={sliderRef}
            {...settings}
            // @ts-ignore
            style={{
              display: 'flex',
              width: isDesktop() ? 435 - 70 : window.innerWidth - 70,
            }}
          >
            {pagesDays.map((days: any, daysIndex) => (
              <div
                key={addDays(new Date(), daysIndex).toString()}
                className="booking-table-swiperPage"
                style={{
                  width: isDesktop() ? 435 - 70 : window.innerWidth - 70,
                }}
              >
                {days.map((day: any, index: any) => (
                  <div key={day} className="booking-table-dayColWrapper">
                    <div className="booking-table-dayBoxHeader">
                      <div className="booking-table-dayTextHeaderMonth">
                        {format(
                          new Date(
                            addDays(
                              new Date(),
                              daysIndex * DAY_PER_PAGE + index
                            )
                          ),
                          'EE',
                          {
                            locale: deLocale,
                          }
                        )}
                      </div>
                      <div
                        className={
                          day === 0
                            ? 'booking-table-dayTextHeaderHighlighted'
                            : 'booking-table-dayTextHeader'
                        }
                      >
                        {format(
                          new Date(
                            addDays(
                              new Date(),
                              daysIndex * DAY_PER_PAGE + index
                            )
                          ),
                          'dd.LL'
                        )}
                      </div>
                    </div>
                    {DAY_HRS.map((time, i) => {
                      const now = new Date();
                      const currentDay = new Date(
                        addDays(new Date(), daysIndex * DAY_PER_PAGE + index)
                      );
                      const currentHr = new Date(
                        addHours(currentDay.setHours(0), i)
                      );
                      const bookings: any[] = carBookings?.filter(
                        (item: any) => item.status !== 'started'
                      );
                      if (car?.status === 'RENTED') {
                        const currentRental = {
                          from: new Date(),
                          to: car?.backBy,
                        };
                        bookings.push(currentRental);
                      }
                      return (
                        <div key={time} className="booking-table-slotBox">
                          {bookings?.map(
                            (booking: any) =>
                              (isSameHour(currentHr, new Date(booking.from)) ||
                                isAfter(currentHr, new Date(booking.from))) &&
                              isBefore(currentHr, new Date(booking.to)) && (
                                <div
                                  key={booking.id}
                                  onClick={() => onClickBooking(booking)}
                                  className={
                                    isSameHour(
                                      currentHr,
                                      new Date(booking.from)
                                    )
                                      ? 'booking-table-fromActiveSlot'
                                      : isSameHour(
                                          currentHr,
                                          new Date(booking.to)
                                        ) ||
                                        isSameHour(
                                          currentHr,
                                          subMinutes(new Date(booking.to), 1)
                                        )
                                      ? 'booking-table-toActiveSlot'
                                      : 'booking-table-betweenActiveSlot'
                                  }
                                />
                              )
                          )}
                          {(isSameHour(currentHr, now) ||
                            isBefore(currentHr, now)) && (
                            <div className="booking-table-greySlot" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingTable;
