import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Marker } from 'react-map-gl';
import cn from 'classnames';
import locationFromString from '../../utils/locationFromString';
import getTheDayBefore from '../../utils/datetime/getTheDayBefore';
import getOneHourAfter from '../../utils/datetime/getOneHourAfter';
import { colors, zIndex } from '../../theme/theme';
import {
  selectSelectedClaim,
  selectDisplayClaims,
  selectClaim,
} from '../../redux/store/userData/claims';
import { fetchVehicleEventsIfNeeded } from '../../redux/store/userData/vehicleEvents';

const ClaimMarkers: React.FC = () => {
  const dispatch = useDispatch();
  const claims = useSelector(selectDisplayClaims);
  const selectedClaim = useSelector(selectSelectedClaim);

  const sortedByLatClaims = claims?.sort((carA, carB) => {
    const latClaimA = !carA?.geoLocation ? 90 : carA.geoLocation.split(',')[0];
    const latClaimB = !carB?.geoLocation ? 90 : carB.geoLocation.split(',')[0];

    return Number(latClaimB) - Number(latClaimA);
  });

  return (
    <>
      {sortedByLatClaims?.map((claim) => {
        if (!claim.geoLocation) {
          return null;
        }

        const position = locationFromString(claim.geoLocation);
        const isSelected = selectedClaim && claim.id === selectedClaim.id;
        const markerSize = isSelected ? 23 : 15;
        const color = colors.claim.open;
        // claim.status === 'OPEN' ? colors.claim.open : colors.claim.closed;

        const handleOnClick = (e: { preventDefault: () => void }) => {
          e.preventDefault();
          const carId = claim.carId;
          const from: any = getTheDayBefore(claim.addedAt!).toISOString();
          const to: any = getOneHourAfter(claim.addedAt).toISOString();

          batch(() => {
            dispatch(selectClaim(claim));
            dispatch(
              fetchVehicleEventsIfNeeded(
                carId,
                from,
                to,
                ['damageClaim'],
                'CLAIM',
                false
              )
            );
          });
        };

        return (
          <Marker
            key={`claims-${claim.damageId}`}
            latitude={position?.lat!}
            longitude={position?.lng!}
            offsetTop={-markerSize / 2}
            offsetLeft={-markerSize / 2}
            className={cn({ selected: isSelected })}
          >
            <div className="oval" onClick={handleOnClick} />
            <style jsx>
              {`
                :global(.mapboxgl-marker) {
                  z-index: ${zIndex.carMarker};
                }
                :global(.mapboxgl-marker.selected) {
                  z-index: ${zIndex.carMarkerSelected};
                }
                .oval {
                  width: ${markerSize}px;
                  height: ${markerSize}px;
                  border-radius: 50%;
                  cursor: pointer;
                  background: ${color};
                }
              `}
            </style>
          </Marker>
        );
      })}
    </>
  );
};

export default ClaimMarkers;
