import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .bar {
    width: 18px;
    height: 16px;
    border-right: 1px solid ${colors.border};
  }
  .loading-icon-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
  }
  .load-more {
    display: inline-flex;
    align-items: center;
    height: 26px;
    margin: 0;
    padding: 3px 10px;
    color: ${colors.black};
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    border: solid 2px ${colors.black};
    cursor: pointer;
  }
`;

export default style;
