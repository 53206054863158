export enum CommonTypes {
  SET_APPLIED_FILTERS_HEIGHT = 'ui/common/SET_APPLIED_FILTERS_HEIGHT',
  SET_DISPLAY_COLUMNS = 'ui/common/SET_DISPLAY_COLUMNS',
  OPEN_MODAL = 'ui/common/OPEN_MODAL',
  CLOSE_MODAL = 'ui/common/CLOSE_MODAL',
  SET_VIEW_MODE = 'ui/common/SET_VIEW_MODE',
  SET_VIEW_TYPE = 'ui/common/SET_VIEW_TYPE',
  OPEN_CONFIRM_MODAL = 'ui/common/OPEN_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL = 'ui/common/CLOSE_CONFIRM_MODAL',
  OPEN_STATUS_MODAL = 'ui/common/OPEN_STATUS_MODAL',
  CLOSE_STATUS_MODAL = 'ui/common/CLOSE_STATUS_MODAL',
}
