import React from 'react';
import styles from './TripFrameBottom.styles';

const TripFrameBottom: React.FC = () => {
  return (
    <>
      <div className="wrapper" />
      <style jsx>{styles}</style>
    </>
  );
};

export default TripFrameBottom;
