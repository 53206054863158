import { Reducer } from '../../../../@types';
import { TextsTypes } from '../../../../@types/enums';
import { initialState, TextsState } from './initialState';

// @ts-ignore
export const textsReducer: Reducer<TextsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case TextsTypes.TEXTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TextsTypes.TEXTS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.locale]: {
            ...state.entities[action.payload.locale],
            ...action.payload.texts,
          },
        },
        loading: false,
      };
    case TextsTypes.TEXTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
