import css from 'styled-jsx/css';
import { colors } from '../../../theme/theme';

const style = css`
  p {
    margin-left: 8px;
    margin-bottom: 0;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 14px;
    // width: 0px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
  }
  p.active {
    background: ${colors.device.online};
  }
  p.deleted {
    background: ${colors.device.offline};
  }
`;

export default style;
