import { MomentInput } from 'moment';
import formatDate from '../../../utils/formatDate';
import formatDateTime from '../../../utils/formatDateTime';

export const renderDate = (dateTime: MomentInput, dashForEmpty = true) =>
  dateTime ? (
    <span>{formatDate(dateTime)}</span>
  ) : dashForEmpty ? (
    <span>-</span>
  ) : null;

export const renderDateTime = (dateTime: MomentInput, dashForEmpty = true) =>
  dateTime ? (
    <span>{formatDateTime(dateTime)}</span>
  ) : dashForEmpty ? (
    <span>-</span>
  ) : null;
