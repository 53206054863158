import { ErrorResponse, FleetAdminCircleData } from '../../../../@types';

export interface CirclesState {
  entities: FleetAdminCircleData[] | null;
  display: FleetAdminCircleData[] | null;
  selected: FleetAdminCircleData | null;
  error: ErrorResponse | null;
  fetchedCircles: string[] | null;
  loading: boolean;
  loadingCircle: boolean;
  updatingCircle: boolean;
  addingUser: boolean;
  removingUser: boolean;
  addingFleet: boolean;
  removingFleet: boolean;
  creatingCircle: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  fetchedCircles: null,
  loading: false,
  loadingCircle: false,
  updatingCircle: false,
  addingUser: false,
  removingUser: false,
  addingFleet: false,
  removingFleet: false,
  creatingCircle: false,
};
