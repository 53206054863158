import styles from './getRenderLink.styles';
import abbreviateText from '../../../utils/abbreviateText';
import React from 'react';

// eslint-disable-next-line react/display-name
export const getRenderLink = () => (label: any) => {
  return (
    <>
      <span>
        {typeof label === 'string' ? abbreviateText(label, 20) : label}
      </span>
      <style jsx>{styles}</style>
    </>
  );
};
