import { ErrorResponse, VehicleEventData } from '../../../../@types';

export type EventTarget = 'CLAIM' | 'TRIP' | 'CARE' | 'CAR';
export interface VehicleEventsState {
  claimEvents: VehicleEventData[] | null;
  tripEvents: VehicleEventData[] | null;
  careEvents: VehicleEventData[] | null;
  carEvents: VehicleEventData[] | null;
  carEventsFilters: string[] | null;
  error: ErrorResponse | null;
  claimLastFrom: string | null;
  carLastFrom: string | null;
  to: string | null;
  loading: boolean;
  isAllClaimEventsLoaded: boolean;
  isAllCarEventsLoaded: boolean;
}

export const initialState = {
  claimEvents: null,
  tripEvents: null,
  careEvents: null,
  carEvents: null,
  carEventsFilters: ['BaseData'],
  error: null,
  claimLastFrom: null,
  carLastFrom: null,
  to: null,
  loading: false,
  isAllClaimEventsLoaded: false,
  isAllCarEventsLoaded: false,
};
