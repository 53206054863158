import NoImage from './NoImage';
import styles from './ServiceImageGallery.styles';

type Props = {
  images: any;
};

const ServiceImageGallery = ({ images }: Props) => {
  return (
    <>
      <div className="service-image-gallery">
        {images.length === 0 && <NoImage />}
        {images.map((imageUrl: any) => (
          <div key={imageUrl} className="img-wrapper">
            <img className="img" src={imageUrl} />
          </div>
        ))}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ServiceImageGallery;
