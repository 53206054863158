export const uniqueArrayOfObjects = (items: Array<any>) => {
  const uniqueIds: any[] = [];
  const uniqueItems: any[] = [];
  items?.forEach((item) => {
    const itemId = item?.id || item?.value;
    if (!uniqueIds.includes(itemId)) {
      uniqueIds.push(itemId);
      uniqueItems.push(item);
    }
  });
  return uniqueItems;
};
