import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, VehicleProfileInfoData } from '../../../../@types';
import { ProfilesTypes } from '../../../../@types/enums';

export const profilesRequest = () =>
  createAction(ProfilesTypes.PROFILES_REQUEST);

export const profilesSuccess = (payload: VehicleProfileInfoData[]) =>
  createAction(ProfilesTypes.PROFILES_SUCCESS, payload);

export const profilesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.PROFILES_FAILURE, error);

export const profileRequest = () => createAction(ProfilesTypes.PROFILE_REQUEST);

export const profileSuccess = (payload: VehicleProfileInfoData) =>
  createAction(ProfilesTypes.PROFILE_SUCCESS, payload);

export const profileFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.PROFILE_FAILURE, error);

export const updateProfileRequest = () =>
  createAction(ProfilesTypes.UPDATE_PROFILE_REQUEST);

export const updateProfileSuccess = (payload: VehicleProfileInfoData) =>
  createAction(ProfilesTypes.UPDATE_PROFILE_SUCCESS, payload);

export const updateProfileFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.UPDATE_PROFILE_FAILURE, error);

export const createProfileRequest = () =>
  createAction(ProfilesTypes.CREATE_PROFILE_REQUEST);

export const createProfileSuccess = (payload: VehicleProfileInfoData) =>
  createAction(ProfilesTypes.CREATE_PROFILE_SUCCESS, payload);

export const createProfileFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.CREATE_PROFILE_FAILURE, error);

export const selectProfile = (payload: VehicleProfileInfoData | null) =>
  createAction(ProfilesTypes.SELECT_PROFILE, payload);

export const setDisplayProfiles = (payload: VehicleProfileInfoData[]) =>
  createAction(ProfilesTypes.SET_DISPLAY_PROFILES, payload);

export const uploadImageRequest = () =>
  createAction(ProfilesTypes.UPLOAD_IMAGE_REQUEST);

export const uploadImageSuccess = (payload: VehicleProfileInfoData) =>
  createAction(ProfilesTypes.UPLOAD_IMAGE_SUCCESS, payload);

export const deleteImageFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.UPLOAD_IMAGE_FAILURE, error);

export const deleteImageRequest = () =>
  createAction(ProfilesTypes.DELETE_IMAGE_REQUEST);

export const deleteImageSuccess = (payload: VehicleProfileInfoData) =>
  createAction(ProfilesTypes.DELETE_IMAGE_SUCCESS, payload);

export const uploadImageFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(ProfilesTypes.DELETE_IMAGE_FAILURE, error);
