import moment from 'moment';

export const formatDuration = (duration: string): string => {
  const durationObj = moment.duration(duration);
  const days = Math.floor(durationObj.days());
  const hours = Math.floor(durationObj.hours());
  const minutes = Math.floor(durationObj.minutes());

  return `${days > 0 ? (days > 1 ? days + ' Days' : days + ' Day') : ''} ${
    hours > 0 ? (hours > 1 ? hours + ' Hours' : hours + ' Hour') : ''
  } ${minutes + ' Min'}`;
};
