import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { fetchCarConditionsIfNeeded } from '../../redux/helpers';
import styles from './UnlockLockButton.styles';
import {
  lockCarIfNeeded,
  unlockCarIfNeeded,
  selectCarConditions,
  selectUnlockingCar,
  selectSelectedCar,
} from '../../redux/store/userData/cars';
import { selectToken } from '../../redux/store/userData/user';

const UnlockLockButton: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const unlocking = useSelector(selectUnlockingCar);
  const conditions = useSelector(selectCarConditions);
  const [isCarLocked, setIsCarLocked] = React.useState(
    conditions === null ? false : conditions.immobilizerLocked
  );

  React.useEffect(() => {
    if (conditions) {
      setIsCarLocked(conditions.immobilizerLocked);
    }
  }, [conditions]);

  const handleLockCar = (e: any) => {
    e.preventDefault();
    dispatch(lockCarIfNeeded(token, selectedCar?.id!));
    setTimeout(
      () =>
        dispatch(
          fetchCarConditionsIfNeeded(token, selectedCar?.id!, false, true)
        ),
      400
    );
  };

  const handleUnlockCar = (e: any) => {
    e.preventDefault();
    dispatch(unlockCarIfNeeded(token, selectedCar?.id!));
    setTimeout(
      () =>
        dispatch(
          fetchCarConditionsIfNeeded(token, selectedCar?.id!, false, true)
        ),
      400
    );
  };

  return (
    <div className="buttons-wrapper">
      <Button
        className="lock button"
        block={true}
        onClick={handleLockCar}
        loading={isCarLocked ? false : unlocking}
        style={!isCarLocked ? { color: '#ee4e4e', borderColor: '#ee4e4e' } : {}}
        disabled={isCarLocked}
      >
        <FormattedMessage id="fleetadmin.carEditor.lock" />
      </Button>
      <Button
        className="unlock button"
        block={true}
        onClick={handleUnlockCar}
        loading={isCarLocked ? unlocking : false}
        style={isCarLocked ? { color: '#ee4e4e', borderColor: '#ee4e4e' } : {}}
        disabled={!isCarLocked}
      >
        <FormattedMessage id="fleetadmin.carEditor.unlock" />
      </Button>
      <style jsx>{styles}</style>
    </div>
  );
};

export default UnlockLockButton;
