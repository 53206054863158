import React from 'react';
import { Collapse, Tag } from 'antd';
import ListEditItem from '../../../shared/ListEditItem';
import ImageAdded from '../../../TimeLine/ImageAdded';
import { getStatusColor } from '../../../../utils';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import { renderDate } from '../../../shared/TableLayout';
import styles from '../UserInfo.styles';

const { Panel } = Collapse;

interface UserIDInfoProps {
  selectedUser: any;
  intl: any;
  openAddIDModal: () => void;
  onEditIDSettings: () => void;
  onIDStatusUpdate: () => void;
}

const UserIDInfo: React.FC<UserIDInfoProps> = ({
  selectedUser,
  intl,
  openAddIDModal,
  onEditIDSettings,
  onIDStatusUpdate,
}) => (
  <>
    <div className="user-section-title">
      {intl.formatMessage({ id: `fleetadmin.users.info.document` })}
      <img
        className="add-button"
        src="/images/drawer/add.svg"
        alt="edit"
        onClick={openAddIDModal}
      />
    </div>
    <ListEditItem
      label={intl.formatMessage({ id: `fleetadmin.users.info.id.submissions` })}
      leftItem={<span>{selectedUser?.remainingIDSubmissionAttempts || 0}</span>}
      onClickEdit={onEditIDSettings}
    />
    {selectedUser?.identityDocuments?.map((ID: any, index: number) => (
      <div key={ID.number} className="collapse-container">
        <Collapse bordered={false}>
          <Panel
            header={
              <div className="dl-header">
                <span>
                  {`${intl.formatMessage({
                    id: `fleetadmin.users.info.dl.submitted`,
                  })} ${ID.submissionDate}`}
                </span>
                {ID?.status && (
                  <Tag color={getStatusColor(ID.status)}>
                    {intl.formatMessage({
                      id: `fleetadmin.status.${ID.status}`,
                    })}
                  </Tag>
                )}
              </div>
            }
            key="user-id"
          >
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.status`,
              })}
              leftItem={renderText(ID.status, true)}
              onClickEdit={onIDStatusUpdate}
            />
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.document.type`,
              })}
              leftItem={renderText(ID?.documentType, true)}
            />
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.document.number`,
              })}
              leftItem={renderText(ID?.number, true)}
            />
            {ID?.issuePlace && (
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.document.issue`,
                })}
                leftItem={renderText(ID?.issuePlace, true)}
              />
            )}
            {ID?.issueCountry && (
              <ListEditItem
                label={intl.formatMessage({
                  id: `fleetadmin.users.info.document.issue`,
                })}
                leftItem={renderText(
                  intl.formatMessage({ id: ID?.issueCountry }),
                  true
                )}
              />
            )}
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.address`,
              })}
              leftItem={renderText(
                ID?.address?.streetName
                  ? `${ID?.address?.streetName}, ${
                      ID?.address?.streetNumber
                    }, ${ID?.address?.postalCode}, ${ID?.address?.city}, ${
                      ID?.address?.country || 'DE'
                    }`
                  : ID?.formattedAddress || null,
                true
              )}
            />
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.payment.lastchangeat`,
              })}
              leftItem={renderDate(ID?.lastChangedAt, true)}
            />
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.document.issuedate`,
              })}
              leftItem={renderDate(ID?.issueDate, true)}
            />
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.payment.expirydate`,
              })}
              leftItem={renderDate(ID?.expiryDate, true)}
            />
            {ID?.frontImageUrl && (
              <div className="dl-image-container">
                <ImageAdded
                  vehicleEvent={{
                    images: [
                      ID?.frontImageUrl,
                      ID?.backImageUrl,
                      ID?.selfieUrl,
                    ],
                  }}
                />
              </div>
            )}
          </Panel>
        </Collapse>
      </div>
    ))}
    <style jsx>{styles}</style>
  </>
);

export default UserIDInfo;
