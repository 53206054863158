import React from 'react';
import { useIntl } from 'react-intl';
import BlockedReasonsSelectorLayout from '../shared/BlockedReasonsSelectorLayout';

const BlockedReasons = [
  'needsFuel',
  'needsRepair',
  'needsRelocation',
  'needsBattery',
  'needsCleaning',
  'needsAccessKitCheck',
  'needsKeyFob',
  'needsFuelCard',
  'needsOther',
];

type BlockedReasonSelectorProps = {
  style?: any;
  className?: string;
  value?: any;
  loading?: boolean;
  onSelect?: (value: string) => void;
};

const BlockedReasonSelector: React.FC<BlockedReasonSelectorProps> = (props) => {
  const intl = useIntl();

  const placeholder = intl.formatMessage({
    id: 'fleetadmin.updateStatusModal.reasonPlaceholder',
  });

  const options = BlockedReasons.map((BlockedReason) => ({
    value: BlockedReason,
    label: (
      <>
        {intl.formatMessage({
          id: `fleetadmin.blockReasons.${BlockedReason}`,
        })}
      </>
    ),
  }));

  return (
    <BlockedReasonsSelectorLayout
      keyPrefix="care-search"
      placeholder={placeholder}
      options={options}
      {...props}
    />
  );
};

export default BlockedReasonSelector;
