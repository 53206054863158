import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import CarEditDrawerBody from './CarEditDrawerBody';
import { ViewMode } from '../../hooks/useViewMode';
import AreaEditDrawer from '../../components/AreaEditDrawer';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import ClaimDrawer from '../../components/ClaimDrawer';
import {
  closeCarDrawerAndSetQueries,
  selectViewMode,
} from '../../redux/store/ui/common';
import CategoryEditDrawer from '../../components/CategoryEditDrawer';
import ProfileEditDrawer from '../ProfileEditDrawer';
import BookingDrawer from '../BookingDrawer';
import { selectViewType } from '../../redux/store/ui/common/selectors';
import { ViewType } from '../../hooks/useViewType';
import TripDrawer from '../TripDrawer';

const CarEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const selectedCar = useSelector(selectSelectedCar);
  const visible = selectedCar != null;

  const handleOnClose = () => {
    dispatch(closeCarDrawerAndSetQueries());
  };

  return (
    selectedCar && (
      <>
        <DrawerLayout
          isCarEditDrawer
          visible={visible}
          title={selectedCar.id}
          body={<CarEditDrawerBody />}
          nestedDrawers={
            <>
              {viewMode !== ViewMode.AREA && <AreaEditDrawer />}
              {viewMode !== ViewMode.CLAIM && <ClaimDrawer />}
              {viewMode !== ViewMode.CATEGORIES && <CategoryEditDrawer />}
              {viewMode !== ViewMode.PROFILE && <ProfileEditDrawer />}
              {viewMode !== ViewMode.SHARE && <TripDrawer />}
              {viewMode !== ViewMode.BOOKING &&
                viewType !== ViewType.TIME_TABLE && <BookingDrawer />}
            </>
          }
          onReload={undefined}
          onClose={handleOnClose}
        />
      </>
    )
  );
};

export default CarEditDrawer;
