import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './ConfirmModal.styles';
import { selectConfirmModal } from '../../../redux/store/ui/common/index';
import { closeConfirmModal } from '../../../redux/store/ui/common/actions';
import { useIntl } from 'react-intl';

const ConfirmModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const confirmData = useSelector(selectConfirmModal);
  const visibleModal = useSelector(selectConfirmModal) != null;

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    confirmData?.onConfirm();
    dispatch(closeConfirmModal());
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeConfirmModal());
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeConfirmModal);
        }}
      >
        <div className="confirm-modal">
          <div className="confirm-modal-wrapper">
            <div className="confirm-modal-inputs">
              <p className="label">{confirmData?.title}</p>
            </div>
          </div>
          <Button
            className="submit-button"
            style={confirmData?.destructive ? { backgroundColor: 'red' } : {}}
            onClick={handleOnSubmit}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default ConfirmModal;
