import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserStatus.styles';
import { renderText } from '../../shared/DrawerLayout/renderText';
import {
  archiveUser,
  resetUserPassword,
  generateSignupCertificate,
  selectCirtificateLoading,
  syncEBUS,
  syncHAL,
  getGDPR,
  selectLoadingGDPR,
} from '../../../redux/store/userData/users';
import { renderDate, renderDateTime } from '../../shared/TableLayout';
import ListEditItem from '../../shared/ListEditItem';
import {
  openConfirmModal,
  openModal,
} from '../../../redux/store/ui/common/actions';
import { useIntl } from 'react-intl';
import {
  isVerifiedUser,
  getStatusColor,
  findDuplicationReason,
} from '../../../utils';
import { Tag, Collapse, Popover } from 'antd';
import LoadingIcon from '../../shared/LoadingIcon';
import { selectAuth } from '../../../redux/store/userData/user';
import LinkToUser from '../../shared/LinkToUser';

const { Panel } = Collapse;

const UserStatus = ({ selectedUser }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const loadingGDPR = useSelector(selectLoadingGDPR);
  const isSignupCertificateLoading = useSelector(selectCirtificateLoading);
  const latestNote = [...(selectedUser?.notes || [])]
    ?.filter(
      (note) =>
        note?.text !== undefined &&
        !note?.text?.includes('Document type: OTHER')
    )
    ?.pop()?.text;
  const selectedBrand = user?.brands?.find(
    (it) => it.id === selectedUser.brandId
  );
  const eBUSMerchant = selectedUser?.merchantSystemData?.find(
    (merchant) => merchant?.merchantSystem === 'eBUS'
  );

  const HALMerchant = selectedUser?.merchantSystemData?.find(
    (merchant) => merchant?.merchantSystem === 'HAL'
  );

  const verifiedUser = isVerifiedUser(selectedUser);

  const onUpdateAccountStatus = () => {
    dispatch(openModal('UserStatusModal'));
  };

  const onArchive = () => {
    dispatch(
      openConfirmModal({
        destructive: true,
        title: intl.formatMessage({
          id: `fleetadmin.users.status.archive.msg`,
        }),
        onConfirm: () => {
          dispatch(archiveUser(selectedUser.id));
        },
      })
    );
  };

  const onResetPassword = () => {
    dispatch(
      openConfirmModal({
        title: intl.formatMessage({
          id: `fleetadmin.users.status.resetpassword.msg`,
        }),
        onConfirm: () => {
          dispatch(resetUserPassword(selectedUser.id));
        },
      })
    );
  };

  const onDownloadGDPR = () => {
    dispatch(getGDPR(selectedUser.id));
  };

  const onClickGenerateSignupCertificate = () => {
    dispatch(
      generateSignupCertificate((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open(url, '_blank');
          if (!newWindow) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
    );
  };

  const onSyncEBUS = () => {
    dispatch(syncEBUS());
  };

  const onSyncHAL = () => {
    dispatch(syncHAL());
  };

  const onEditEbusOptions = () => {
    dispatch(openModal('EbusOptionsModal'));
  };

  const getSyncHeader = (merchant) => {
    const syncStatus =
      merchant?.status.toLowerCase() === 'no_data'
        ? 'error'
        : merchant?.status.toLowerCase();
    return (
      <div className="panel-header">
        <span>{merchant?.merchantSystem || ''}</span>
        <Tag color={getStatusColor(syncStatus)}>
          {intl.formatMessage({
            id: `fleetadmin.sync.status.${syncStatus}`,
          })}
        </Tag>
        <style jsx>{styles}</style>
      </div>
    );
  };

  return (
    <>
      <div className="user-info">
        <div className="user-section-title" style={{ marginTop: 10 }}>
          {intl.formatMessage({
            id: `fleetadmin.users.status.account`,
          })}
        </div>
        <ListEditItem
          style={
            selectedUser?.userDuplicates?.length > 0 ? { marginBottom: 0 } : {}
          }
          label={intl.formatMessage({
            id: `fleetadmin.users.status.status`,
          })}
          leftItem={
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tag color={getStatusColor(selectedUser?.accountStatus)}>
                  {selectedUser.accountStatus}
                </Tag>
                {selectedUser?.accountStatus === 'blocked' && (
                  <Popover
                    content={
                      <div className="popover">
                        {intl.formatMessage({
                          id: `fleetadmin.user.blocked.${
                            selectedUser?.blockReasonDetail ||
                            selectedUser?.blockReason ||
                            'other'
                          }`,
                        })}
                      </div>
                    }
                    placement="top"
                  >
                    <img
                      style={
                        !selectedUser?.blockReason ||
                        selectedUser?.blockReason === 'other'
                          ? { marginLeft: 10, width: '14px', height: '14px' }
                          : { marginLeft: 10, width: '16px', height: '16px' }
                      }
                      src={`/images/user-blocked-reasons/${
                        selectedUser?.blockReasonDetail === 'creditCheck'
                          ? 'creditCheck'
                          : selectedUser?.blockReason || 'other'
                      }.svg`}
                      alt="block reason"
                    />
                  </Popover>
                )}
              </div>
              {latestNote && (
                <p className="status-comment">{`"${latestNote}"`}</p>
              )}
            </>
          }
          onClickEdit={onUpdateAccountStatus}
        />
        {selectedUser?.userDuplicates?.length > 0 && (
          <div className="collapse-duplicates-container">
            <Collapse bordered={false}>
              <Panel
                header={`${
                  selectedUser?.userDuplicates?.length
                } ${intl.formatMessage({
                  id: `fleetadmin.users.duplicates`,
                })}`}
                key="user-id"
              >
                {selectedUser?.userDuplicates.map((duplicate) => (
                  <ListEditItem
                    key={duplicate.id}
                    label=""
                    leftItem={
                      <LinkToUser
                        userId={duplicate.id}
                        userCode={duplicate.referralCode}
                        isDuplicate
                      />
                    }
                    rightItem={
                      <div>
                        {findDuplicationReason(selectedUser, duplicate) && (
                          <Popover
                            content={
                              <div className="popover">
                                {intl.formatMessage({
                                  id: findDuplicationReason(
                                    selectedUser,
                                    duplicate
                                  ),
                                })}
                              </div>
                            }
                            placement="top"
                          >
                            <img
                              className="duplicate-info"
                              src="/images/drawer/info.svg"
                              alt="info"
                            />
                          </Popover>
                        )}
                        <Tag color={getStatusColor(duplicate?.accountStatus)}>
                          {duplicate.accountStatus}
                        </Tag>
                        {duplicate?.accountStatus === 'blocked' && (
                          <Popover
                            content={
                              <div className="popover">
                                {intl.formatMessage({
                                  id: `fleetadmin.user.blocked.${
                                    duplicate?.blockReasonDetail ||
                                    duplicate?.blockReason ||
                                    'other'
                                  }`,
                                })}
                              </div>
                            }
                            placement="left"
                          >
                            <img
                              style={
                                !duplicate?.blockReason ||
                                duplicate?.blockReason === 'other'
                                  ? {
                                      marginLeft: 10,
                                      width: '14px',
                                      height: '14px',
                                    }
                                  : {
                                      marginLeft: 10,
                                      width: '16px',
                                      height: '16px',
                                    }
                              }
                              src={`/images/user-blocked-reasons/${
                                duplicate?.blockReasonDetail === 'creditCheck'
                                  ? 'creditCheck'
                                  : duplicate?.blockReason || 'other'
                              }.svg`}
                              alt="block reason"
                            />
                          </Popover>
                        )}
                      </div>
                    }
                  />
                ))}
              </Panel>
            </Collapse>
          </div>
        )}
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.user.table.usertype`,
          })}
          leftItem={renderText(
            intl.formatMessage({
              id: `fleetadmin.user.${selectedUser?.userType}`,
            }),
            true
          )}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.users.status.usercode`,
          })}
          leftItem={renderText(selectedUser.referralCode, true)}
        />
        {selectedUser?.lastLoginAt && (
          <ListEditItem
            label={intl.formatMessage({
              id: `fleetadmin.users.status.login`,
            })}
            leftItem={renderDate(selectedUser.lastLoginAt, false)}
          />
        )}

        {selectedUser?.signupCertificateReady && (
          <ListEditItem
            label={intl.formatMessage({
              id: 'fleetadmin.users.registrationcertificate',
            })}
            leftItem={
              <div
                style={
                  isSignupCertificateLoading
                    ? { color: 'grey' }
                    : { color: '#1890ff', cursor: 'pointer' }
                }
                onClick={
                  isSignupCertificateLoading
                    ? null
                    : onClickGenerateSignupCertificate
                }
              >
                Link
              </div>
            }
            rightItem={isSignupCertificateLoading ? <LoadingIcon /> : null}
          />
        )}
        {/* Sync Status */}
        {(eBUSMerchant || HALMerchant) && (
          <>
            <div className="user-section-title">
              {intl.formatMessage({
                id: `fleetadmin.users.info.sync`,
              })}
            </div>
            {eBUSMerchant && (
              <div className="collapse-container">
                <Collapse bordered={false}>
                  <Panel header={getSyncHeader(eBUSMerchant)} key="user-id">
                    {eBUSMerchant?.status !== 'DONE' &&
                      user?.merchant?.permissions?.developer && (
                        <>
                          <ListEditItem
                            label={intl.formatMessage({
                              id: `fleetadmin.users.ebus.id`,
                            })}
                            leftItem={renderText(
                              selectedUser?.forcedProductId,
                              true
                            )}
                            onClickEdit={onEditEbusOptions}
                          />
                          <ListEditItem
                            label={intl.formatMessage({
                              id: `fleetadmin.users.ebus.options`,
                            })}
                            leftItem={renderText(
                              selectedUser?.forcedProductOptions || '',
                              true
                            )}
                          />
                        </>
                      )}
                    <ListEditItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.sync.lastSyncedAt`,
                      })}
                      leftItem={renderDateTime(eBUSMerchant?.syncedAt, true)}
                    />
                    <ListEditItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.sync.lastSyncedResult`,
                      })}
                      leftItem={renderText(
                        ['DONE', 'IMPORTED'].includes(eBUSMerchant?.status)
                          ? eBUSMerchant?.merchantReference
                          : eBUSMerchant?.error,
                        true
                      )}
                    />

                    {['ERROR', 'PENDING', 'NO_DATA'].includes(
                      eBUSMerchant?.status
                    ) && (
                      <div
                        className={
                          eBUSMerchant?.status === 'PENDING' && !verifiedUser
                            ? 'collapse-blue-action-disabled'
                            : 'collapse-blue-action'
                        }
                        onClick={
                          eBUSMerchant?.status === 'PENDING' && !verifiedUser
                            ? null
                            : onSyncEBUS
                        }
                      >
                        {intl.formatMessage({
                          id: `fleetadmin.users.sync`,
                        })}
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </div>
            )}
            {HALMerchant && (
              <div className="collapse-container">
                <Collapse bordered={false}>
                  <Panel header={getSyncHeader(HALMerchant)} key="user-id">
                    <ListEditItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.sync.lastSyncedAt`,
                      })}
                      leftItem={renderDateTime(HALMerchant?.syncedAt, true)}
                    />
                    <ListEditItem
                      label={intl.formatMessage({
                        id: `fleetadmin.users.sync.lastSyncedResult`,
                      })}
                      leftItem={renderText(
                        ['DONE', 'IMPORTED'].includes(HALMerchant?.status)
                          ? HALMerchant?.merchantReference
                          : HALMerchant?.error,
                        true
                      )}
                    />
                    {['ERROR', 'PENDING', 'NO_DATA'].includes(
                      HALMerchant?.status
                    ) && (
                      <div
                        className={
                          eBUSMerchant?.status === 'PENDING' && !verifiedUser
                            ? 'collapse-blue-action-disabled'
                            : 'collapse-blue-action'
                        }
                        onClick={
                          eBUSMerchant?.status === 'PENDING' && !verifiedUser
                            ? null
                            : onSyncHAL
                        }
                      >
                        {intl.formatMessage({
                          id: `fleetadmin.users.sync`,
                        })}
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </div>
            )}
          </>
        )}
        <div className="user-section-title">
          {intl.formatMessage({
            id: `fleetadmin.users.status.actions`,
          })}
        </div>
        <div
          style={{ display: 'flex', alignContent: 'center' }}
          className="blue-action"
        >
          <div
            style={{ marginRight: 10 }}
            onClick={loadingGDPR ? null : onDownloadGDPR}
          >
            {intl.formatMessage({
              id: `fleetadmin.users.status.gdpr`,
            })}
          </div>
          {loadingGDPR && <LoadingIcon font={14} />}
        </div>
        <div className="blue-action" onClick={onResetPassword}>
          {intl.formatMessage({
            id: `fleetadmin.users.status.resetpassword`,
          })}
        </div>
        <div className="red-action" onClick={onArchive}>
          {intl.formatMessage({
            id: `fleetadmin.users.status.archive`,
          })}
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserStatus;
