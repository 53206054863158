import css from 'styled-jsx/css';

export const loadingStyles = css`
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 24px;
  }
`;

export const timelineStyles = css`
  .timeline-wrapper {
    margin: 0 24px 24px 8px;
  }
`;
