import { batch } from 'react-redux';
import { ViewType } from '../../../../hooks/useViewType';
import { setQueries } from '../../../../libs/setQueries';
import { selectArea } from '../../appData/areas';
import {
  fetchCarByIdIfNeeded,
  selectCar,
  fetchCarActiveBookings,
  setCarDrawerActiveTab,
} from '../../userData/cars';
import { selectCare } from '../../userData/cares';
import { selectClaim, fetchClaimsByCarId } from '../../userData/claims';
import { selectBooking, selectTrip } from '../../userData/trips';
import {
  fetchVehicleEvents,
  fetchVehicleEventsForCar,
} from '../../userData/vehicleEvents';
import { fetchCarConditionsIfNeeded } from '../../../helpers';
import { selectCategory } from '../../userData/categories';
import getTheDayBefore from '../../../../utils/datetime/getTheDayBefore';
import getOneHourAfter from '../../../../utils/datetime/getOneHourAfter';
import { fetchCircleById, selectCircle } from '../../userData/circles';
import { selectPricing } from '../../appData/pricings';
import { paramsToObject } from '../../../../hooks/useQuery';
import { selectProfile } from '../../userData/profiles';
import {
  Dispatch,
  FleetAdminCarData,
  GetState,
  FleetAdminCircleData,
  AreaData,
  VehicleCategoryData,
  PricingTableData,
  VehicleProfileInfoData,
} from '../../../../@types';
import {
  fetchUserById,
  fetchUserDuplicates,
  fetchUserTransactions,
  selectUser,
  userDuplicateSuccess,
} from '../../userData/users';
import { selectAdmin } from '../../userData/admins';
import { selectReceipt } from '../../userData/receipts';
import { fetchReceiptById } from '../../userData/receipts/thunk';

export const selectCarWithoutMapEffect = (
  car: FleetAdminCarData,
  shouldUpdateQuery: boolean = true,
  activeTab: number = 0
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().userData.user;
    const selectedClaim = getState().userData.claims.selected;

    batch(() => {
      dispatch(setCarDrawerActiveTab(activeTab));
      dispatch(selectCar(car));
      dispatch(selectArea(null));
      setTimeout(() => {
        dispatch(fetchCarByIdIfNeeded(token, car.id!));
        dispatch(fetchCarConditionsIfNeeded(token, car.id!, false));
        dispatch(fetchVehicleEventsForCar(car.id));
        dispatch(fetchClaimsByCarId(car.id));
        dispatch(fetchCarActiveBookings(car.id!));
        if (selectedClaim) {
          const { addedAt, tripStartedAt, tripFinishedAt } = selectedClaim;
          const from = getTheDayBefore(
            tripStartedAt || addedAt
          ).toISOString() as any;
          const to = getOneHourAfter(
            tripFinishedAt || addedAt
          ).toISOString() as any;
          dispatch(
            fetchVehicleEvents(
              token,
              car.id,
              from,
              to,
              ['damageClaim'],
              'CLAIM',
              false
            )
          );
        }
      }, 300);
    });
    if (shouldUpdateQuery) {
      const query = {
        vm: 'VEHICLE',
        carId: car.id,
      };
      setQueries(query);
    }
  };
};

export const selectCarAndSwitchToMapView =
  (car: FleetAdminCarData) => (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().userData.user;
    batch(() => {
      dispatch(selectCar(car));
      dispatch(selectArea(null));
      dispatch(fetchCarByIdIfNeeded(token, car.id!));
      dispatch(fetchCarConditionsIfNeeded(token, car.id!, false));
    });

    const query = {
      vm: 'VEHICLE',
      type: ViewType.MAP,
      carId: car.id,
    };

    setQueries(query);
  };

export const closeCarDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCar(null));
  dispatch(setCarDrawerActiveTab(0));
  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.carId;
  setQueries(existingParams, true);
};

export const closeCareDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCare(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.careId;
  setQueries(existingParams, true);
};

export const closeTripDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectTrip(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.tripId;
  setQueries(existingParams, true);
};

export const closeBookingDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectBooking(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.bookingId;
  setQueries(existingParams, true);
};

export const closeClaimDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectClaim(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.claimId;
  setQueries(existingParams, true);
};

export const selectAreaWithUIEffect =
  (area: AreaData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectArea(area));
      dispatch(selectCar(null));
    });

    const query = {
      vm: 'AREA',
      areaId: area.id,
    };

    setQueries(query);
  };

export const selectCategoryWithUIEffect =
  (category: VehicleCategoryData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectCategory(category));
      dispatch(selectCar(null));
    });

    const query = {
      vm: 'CATEGORIES',
      categoryId: category.id,
    };

    setQueries(query);
  };

export const selectPricingWithUIEffect =
  (pricing: PricingTableData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectPricing(pricing));
    });

    const query = {
      vm: 'PRICING',
      pricingId: pricing.id,
    };

    setQueries(query);
  };

export const selectReceiptWithUIEffect =
  (receipt: any) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectReceipt(receipt));
      dispatch(selectTrip(null));
      dispatch(selectUser(null));
      dispatch(fetchReceiptById(receipt.id));
    });

    const query = {
      vm: 'RECEIPTS',
      receiptId: receipt.id,
    };

    setQueries(query);
  };

export const selectProfileWithUIEffect =
  (profile: VehicleProfileInfoData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectProfile(profile));
    });

    const query = {
      vm: 'PROFILE',
      profileId: profile.id,
    };

    setQueries(query);
  };

export const selectCircleWithUIEffect =
  (circle: FleetAdminCircleData) => (dispatch: any) => {
    batch(() => {
      dispatch(selectCircle(circle));
      dispatch(fetchCircleById(circle.id!));
    });

    const query = {
      vm: 'CIRCLE',
      circleId: circle.id,
    };

    setQueries(query);
  };

export const selectUserWithUIEffect = (user: any) => (dispatch: any) => {
  batch(() => {
    dispatch(selectReceipt(null));
    dispatch(selectUser(user));
    dispatch(
      fetchUserById(user.id!, () => {
        batch(() => {
          dispatch(fetchUserDuplicates(user.id!));
          dispatch(
            fetchUserTransactions('account', user.id!, 0, 20, false, () => {
              dispatch(fetchUserTransactions('deposit', user.id!, 0, 20, true));
            })
          );
        });
      })
    );
  });

  const query = {
    vm: 'USERS',
    userId: user.id,
  };

  setQueries(query);
};

export const selectAdminWithUIEffect = (admin: any) => (dispatch: any) => {
  batch(() => {
    dispatch(selectAdmin(admin));
    // dispatch(fetchAdminById(user.id!));
  });

  const query = {
    vm: 'ADMINS',
    adminId: admin.id,
  };

  setQueries(query);
};

export const selectAreaAndSwitchToMapView =
  (area: AreaData) => (dispatch: Dispatch) => {
    batch(() => {
      dispatch(selectArea(area));
      dispatch(selectCar(null));
    });

    const query = {
      vm: 'AREA',
      type: ViewType.MAP,
      areaId: area.id,
    };

    setQueries(query);
  };

export const closeAreaDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectArea(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.areaId;
  setQueries(existingParams, true);
};

export const closecategoryDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCategory(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.categoryId;
  setQueries(existingParams, true);
};

export const closePricingDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectPricing(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.pricingId;
  setQueries(existingParams, true);
};

export const closeProfileDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectProfile(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.profileId;
  setQueries(existingParams, true);
};

export const closeReceiptDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectReceipt(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.receiptNo;
  setQueries(existingParams, true);
};

export const closeUsersDrawerAndSetQueries =
  (isDuplicate = false) =>
  (dispatch: Dispatch) => {
    if (isDuplicate) {
      dispatch(userDuplicateSuccess(null));
    } else {
      dispatch(selectUser(null));
    }

    const query = new URLSearchParams(window.location.search);
    const entries = query.entries();
    const existingParams = paramsToObject(entries);
    // @ts-ignore
    delete existingParams.userId;
    setQueries(existingParams, true);
  };

export const closeAdminsDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectAdmin(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.adminId;
  setQueries(existingParams, true);
};

export const closeCircleDrawerAndSetQueries = () => (dispatch: Dispatch) => {
  dispatch(selectCircle(null));

  const query = new URLSearchParams(window.location.search);
  const entries = query.entries();
  const existingParams = paramsToObject(entries);
  // @ts-ignore
  delete existingParams.circleId;
  setQueries(existingParams, true);
};
