import { request, requestAPI } from '../../../../utils/request';
import {
  receiptsRequest,
  receiptsSuccess,
  receiptsFailure,
  setDisplayReceipts,
  receiptRequest,
  receiptSuccess,
  receiptFailure,
  receiptsCSVRequest,
  receiptsCSVSuccess,
  receiptsCSVFailure,
  setTotalReceipts,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { ReceiptsState } from './initialState';
import { APIFilterParams } from '../../ui/filters';

export const fetchReceipts =
  (
    offset: number,
    limit: number,
    successCallback?: Function,
    filterParams?: APIFilterParams
  ) =>
  (dispatch: Dispatch) => {
    dispatch(receiptsRequest());
    requestAPI('/fleets/receipts', {
      limit: limit.toString(),
      offset: offset.toString(),
      ...filterParams,
    })
      .then((data) => {
        dispatch(receiptsSuccess(data.receipts));
        dispatch(setDisplayReceipts(data.receipts));
        dispatch(setTotalReceipts(data.totalCount));
        if (successCallback && typeof successCallback === 'function') {
          successCallback();
        }
      })
      .catch((err) => {
        dispatch(receiptsFailure(err));
        message.error(err.message);
      });
  };

const shouldFetchReceipts = (state: ReceiptsState) => !state.loading;

export const fetchReceiptsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchReceipts(getState().userData.receipts)) {
      return dispatch(fetchReceipts(0, 1000));
    } else {
      return Promise.resolve();
    }
  };
};

export const fetchReceiptById = (id: string) => (dispatch: Dispatch) => {
  dispatch(receiptRequest());

  requestAPI('/fleets/receipts/single', { receiptId: id })
    .then((res) => {
      if (res?.status !== 404) dispatch(receiptSuccess(res));
    })
    .catch((err) => {
      dispatch(receiptFailure(err));
      message.error(err.message);
    });
};

export const generateReceiptsCSV =
  (receiptIds: any, successCallback?: Function) => (dispatch: Dispatch) => {
    dispatch(receiptsCSVRequest());
    requestAPI(
      '/fleets/receipts/csv',
      {},
      {
        method: 'POST',
        headers: {
          accept: 'application/octet-stream',
          'content-type': 'application/json',
        },
        body: JSON.stringify(receiptIds),
      }
    )
      .then((data) => {
        dispatch(receiptsCSVSuccess());
        if (successCallback && typeof successCallback === 'function') {
          successCallback(data);
        }
      })
      .catch((err) => {
        dispatch(receiptsCSVFailure(err));
        message.error(err.message);
      });
  };
