import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper {
    border: 2px solid ${colors.black};
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    padding: 4px 8px;
  }
  .title {
    margin: 0;
    font-size: 14px;
    color: ${colors.black};
  }
`;

export default style;
