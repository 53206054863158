import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { DatePicker, Modal, Input, Button, Select } from 'antd';
import styles from './AddServiceModal.styles';
import TextSearch from '../../components/Filter/TextSearch';
import getDataSourceValues from '../../components/Filter/helpers/getDataSourceValues';
import { colors, zIndex } from '../../theme/theme';
import { addHours } from 'date-fns';
import SERVICE_TYPES from './servicesList';
import { selectCars } from '../../redux/store/userData/cars';
import {
  fetchCaresIfNeeded,
  createService,
} from '../../redux/store/userData/cares';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

const { Option } = Select;
const validate = require('validate.js');
type AddServiceModalProps = {
  visible?: boolean;
};

const constraints = {
  location: {
    format: {
      pattern:
        // eslint-disable-next-line no-useless-escape
        /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?[,]\s?)+(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$/,
      message:
        'Wrong format. latitude,longitude with at least 6 decimal digits',
    },
  },
};

const validateLocation = (location: any) => {
  let result = validate({ location }, constraints);
  if (result && result.location && result.location.length)
    return result.location[0];
  else return '';
};

const AddServiceModal: React.FC<AddServiceModalProps> = ({ visible }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const token = useSelector(selectToken);
  const [plate, setPlate] = useState(null);
  const [serviceTypes, setServiceTypes] = useState(['washOutside']);
  const [finishBy, setFinishBy] = useState(dayjs(addHours(new Date(), 2)));
  const [location, setLocation] = useState({ value: '', error: '' });
  const _visible = useSelector(getIsVisibleModal('addServiceModal'));
  const plateDataSourceValues = getDataSourceValues(cars, 'plate');
  const plateDataSource = plateDataSourceValues.map((v: any) => {
    return {
      value: v,
      text: v,
    };
  });

  const isDisabled = !(plate && serviceTypes && finishBy && location.value);

  const handleCancel = () => {
    dispatch(closeModal('addServiceModal'));
  };

  const validateInputs = () => {
    const locationErr = validateLocation(location.value);
    setLocation({ ...location, error: locationErr });
    return plate && serviceTypes && finishBy && location.value && !locationErr;
  };

  const handleOnSubmit = (e: any) => {
    if (validateInputs()) {
      e.preventDefault();
      batch(() => {
        dispatch(
          createService(
            token,
            {
              plate,
              location: location.value,
              serviceTypes,
              finishBy: finishBy.format('YYYY-MM-DDTHH:mm'),
            },
            () => dispatch(fetchCaresIfNeeded(token))
          )
        );
      });
      handleCancel();
    }
  };
  useEffect(() => {
    if (_visible) {
      setFinishBy(dayjs(addHours(new Date(), 2)));
    }
  }, [_visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-service-wrapper"
          title={`Create a new service`}
          visible={visible || _visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-service-contents" tabIndex={0}>
            <TextSearch
              placeholder="Plate Number"
              dataSource={plateDataSource}
              setValue={setPlate}
              defaultOpen={false}
            />
            <div className="services-container">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear={true}
                value={serviceTypes}
                optionFilterProp="children"
                dropdownStyle={{ zIndex: zIndex.dropDown }}
                onChange={setServiceTypes}
              >
                {SERVICE_TYPES.map((option) => (
                  <Option key={option.text} value={option.value}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            </div>
            <DatePicker
              className="date-input"
              onChange={(datetime) => {
                // @ts-ignore
                setFinishBy(datetime);
              }}
              getPopupContainer={(triggerNode: HTMLElement) => {
                return triggerNode.parentNode as HTMLElement;
              }}
              format="YYYY-MM-DD HH:mm"
              placeholder="Finish By"
              value={finishBy}
              // @ts-ignore
              showTime={{
                format: 'HH:mm',
                minuteStep: 30,
              }}
            />
            <div className="location-container">
              <Input
                placeholder="Location (latitude, longitude)"
                onChange={(e) =>
                  setLocation({ value: e.target.value, error: '' })
                }
              />
              {location.error && <p className="error">{location.error}</p>}
            </div>
            <div className="submit-button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default AddServiceModal;
