import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import TripDrawerBody from './TripDrawerBody';
import CarEditDrawer from '../../components/CarEditDrawer';
import AreaEditDrawer from '../../components/AreaEditDrawer';
import ClaimDrawer from '../../components/ClaimDrawer';
import { ViewMode } from '../../hooks/useViewMode';
import { selectSelectedTrip } from '../../redux/store/userData/trips';
import {
  closeTripDrawerAndSetQueries,
  selectViewMode,
} from '../../redux/store/ui/common';
import CircleEditDrawer from '../CircleEditDrawer';
import { ViewType } from '../../hooks/useViewType';
import { selectViewType } from '../../redux/store/ui/common/selectors';
import ReceiptDrawer from '../ReceiptDrawer';
import UsersDrawer from '../UsersDrawer';

const TripDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const selectedTrip = useSelector(selectSelectedTrip);
  const visible = selectedTrip != null;

  const handleOnClose = () => {
    dispatch(closeTripDrawerAndSetQueries());
  };

  return (
    selectedTrip && (
      <DrawerLayout
        isTripDrawer
        visible={visible}
        title={selectedTrip.id}
        body={<TripDrawerBody />}
        nestedDrawers={
          <>
            {(viewMode !== ViewMode.VEHICLE ||
              viewType === ViewType.TIME_TABLE) && <CarEditDrawer />}
            {viewMode !== ViewMode.AREA && <AreaEditDrawer />}
            {viewMode !== ViewMode.CLAIM && <ClaimDrawer />}
            {viewMode !== ViewMode.CIRCLE && <CircleEditDrawer />}
            {viewMode !== ViewMode.RECEIPTS && <ReceiptDrawer />}
            {viewMode !== ViewMode.USERS && <UsersDrawer />}
          </>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default TripDrawer;
