import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './EbusOptionsModal.styles';
import {
  getIsVisibleModal,
  closeModal,
} from '../../../../redux/store/ui/common';
import DrawerModal from '../../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../../redux/store/userData/users';

interface UserInput {
  forcedProductId?: string;
  forcedProductOptions?: string;
}

const EbusOptionsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser) as any;
  const [forcedProductId, setTestingProductId] = useState(
    selectedUser?.forcedProductId || ''
  );
  const [forcedProductOptions, setTestingProductOptions] = useState(
    selectedUser?.forcedProductOptions || ''
  );

  const visible = useSelector(getIsVisibleModal('EbusOptionsModal'));

  const handleCancel = () => {
    dispatch(closeModal('EbusOptionsModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    let userInput: UserInput = {};

    if (forcedProductId !== selectedUser?.forcedProductId) {
      userInput.forcedProductId = forcedProductId;
    }
    if (forcedProductOptions !== selectedUser.forcedProductOptions) {
      userInput.forcedProductOptions = forcedProductOptions;
    }

    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser?.id, userInput));
      handleCancel();
    }
  };

  useEffect(() => {
    if (visible) {
      setTestingProductId(selectedUser?.forcedProductId || '');
      setTestingProductOptions(selectedUser?.forcedProductOptions || '');
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="ebus-options-contents">
          <div className="ebus-options-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.ebus`,
            })}
          </div>
          <div className="ebus-options-input-container">
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.product`,
              })}
            </div>
            <Input
              value={forcedProductId}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.ebus.product`,
              })}
              onChange={(e) => setTestingProductId(e.target.value)}
              style={{ marginBottom: 12 }}
            />
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.ebus.options`,
              })}
            </div>
            <Input
              value={forcedProductOptions}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.options`,
              })}
              onChange={(e) => setTestingProductOptions(e.target.value)}
            />
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};
export default EbusOptionsModal;
