import css from 'styled-jsx/css';

const style = css`
  .buttons-wrapper {
    display: flex;
  }
  :global(.ant-btn.lock.button) {
    width: 100%;
    margin: 8px 0;
    color: black;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border: 2px solid black;
    border-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  :global(.ant-btn.lock.button:disabled) {
    width: 100%;
    margin: 8px 0;
    color: black;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border: 2px solid black;
    border-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  :global(.ant-btn.unlock.button) {
    width: 100%;
    margin: 8px 0;
    color: black;
    border-top-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  :global(.ant-btn.unlock.button:disabled) {
    width: 100%;
    margin: 8px 0;
    color: black;
    border-top-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .icon {
    display: inline-block;
    margin-right: 8px;
  }
`;

export default style;
