import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import AssignedVehicles from './AssignedVehicles';
import Badge from '../../components/shared/Badge';
import styles from './CategoryEditDrawerBody.styles';
import { selectCars } from '../../redux/store/userData/cars';
import getNumOfCarsByCategoryId from '../../utils/getNumOfCarsByCategoryId';
import { selectSelectedCategory } from '../../redux/store/userData/categories';
import CategoryInfo from './CategoryInfo';

const CategoryEditDrawerBody = () => {
  const cars = useSelector(selectCars);
  const selectedCategory = useSelector(selectSelectedCategory);
  const numOfVehicles = getNumOfCarsByCategoryId(cars, selectedCategory.id);

  const tabs = [
    {
      title: <span>Vehicles {<Badge num={numOfVehicles} />}</span>,
      content: <AssignedVehicles />,
    },
    { title: 'Category Info', content: <CategoryInfo /> },
  ];

  return (
    <>
      <div className="area-edit-drawer-body">
        <div className="area-name-wrapper">
          <p className="name" title={selectedCategory?.name}>
            {selectedCategory?.name?.length > 20
              ? `${selectedCategory?.name.slice(0, 20)}...`
              : selectedCategory?.name}
          </p>
        </div>
        <TabLayout tabs={tabs} keyName="area-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default CategoryEditDrawerBody;
