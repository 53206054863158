import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const userDetailsStyles = css`
  .user-details p {
    margin: 0;
  }
  .email {
    color: ${colors.blue};
    cursor: pointer;
  }
`;

export const tripInfoStyles = css`
  .trip-info-block-button {
    margin: 24px 24px 24px;
    color: red;
    cursor: pointer;
  }
  .trip-info-unblock-button {
    margin: 24px 24px 24px;
    color: #0077be;
    cursor: pointer;
  }
  .trip-info-finish-button {
    margin: 24px 24px 60px;
    color: red;
    cursor: pointer;
  }
  .edit-button {
    cursor: pointer;
  }
`;
