import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper {
    width: 100%;
    height: 586px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #d8d8d8;
  }
  img {
    width: 80px;
    height: 75px;
  }
  p {
    color: ${colors.released};
    font-size: 18px;
    text-align: center;
  }
`;

export default style;
