export async function downloadZip(response, callback) {
  const contentDisposition = response?.headers?.get('content-disposition');
  let filename = 'GDPR.zip';
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="([^"]+)/);
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1];
    }
  }

  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  callback();
}
