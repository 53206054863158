import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper {
    padding: 8px 24px 24px;
  }
  .head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }
  .title {
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .car-status-wrapper {
    margin-left: 8px;
  }
  .plate {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-right: auto;
    margin-bottom: 0;
    display: inline-block;
  }
  .label {
    color: #000;
    margin-bottom: 4px;
  }
  .edit-button {
    cursor: pointer;
  }
  .block-edit-button {
    cursor: pointer;
    align-self: flex-start;
  }
  .car-status {
    width: 130px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
  }
  .block-reasons-section {
    display: flex;
    flex-direction: column;
  }
  .block-reason-wrapper {
    display: inline-block;
    padding: 0px;
    margin: 6px 0px 6px 6px;
  }
  .block-reason-label {
    display: inline-block;
    font-family: 'Avenir Next';
    color: rgb(49, 49, 49);
    font-size: 16px;
    font-weight: normal;
    margin: 0px;
  }
  .block-reason-icon {
    margin-left: 12px;
    width: 14px;
    height: 14px;
  }
  .js-copyTextArea {
    position: absolute;
    top: 1000px;
    right: 1000px;
  }
  .status-comment {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .car-position-right-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .car-position-last-update {
    color: grey;
    font-size: 14px;
    font-family: 'Avenir Next', serif;
    margin-left: 10px;
    margin-right: 5px;
  }
  .kit-wrapper {
    display: flex;
    margin: 5px 0;
  }
  .online-wrapper {
    margin-left: 10px;
  }
  .reset-button {
    cursor: pointer;
    color: ${colors.blue};
    margin: 5px 0;
  }
  .car-terminate-button {
    margin: 70px 24px 60px;
    color: red;
    cursor: pointer;
  }
`;

export default style;
