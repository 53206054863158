import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingVehicleEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  updateBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import { selectCars } from '../../../redux/store/userData/cars';
import TextSearch from '../../Filter/TextSearch';
import { useIntl } from 'react-intl';

const BookingVehicleEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const selectedBooking = useSelector(selectSelectedBooking) as any;
  const visibleModal = useSelector(
    getIsVisibleModal('BookingVehicleEditModal')
  );

  const [confirmedCarId, setConfirmedCarId] = useState(null);
  const plateDataSource = cars
    ?.filter((car) => car?.fleetId === selectedBooking?.fleetId)
    .map((v: any) => {
      return {
        value: v?.plate,
        text: v?.plate,
        id: v?.id,
      };
    });

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(updateBooking(selectedBooking?.id, { confirmedCarId }));
    dispatch(closeModal('BookingVehicleEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('BookingVehicleEditModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingVehicleEditModal'));
        }}
      >
        <div className="booking-vehicle-edit-modal">
          <div className="booking-vehicle-edit-wrapper">
            <div className="booking-vehicle-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.booking.update.vehicle.title',
                })}
              </p>
            </div>
          </div>
          <TextSearch
            placeholder={intl.formatMessage({
              id: 'fleetadmin.booking.update.vehicle.label',
            })}
            dataSource={plateDataSource || []}
            setValue={(value, option) => setConfirmedCarId(option?.id)}
            defaultOpen={false}
            style={{ paddingRight: 0, paddingLeft: 0 }}
          />
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={!confirmedCarId}
          >
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.assign',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingVehicleEditModal;
