import { RootState } from '../../../config';
import { VehicleEventData } from '../../../../@types';

export const selectClaimEvents = (state: RootState): VehicleEventData | null =>
  state.userData.vehicleEvents.claimEvents;

export const selectTripEvents = (state: RootState): VehicleEventData[] | null =>
  state.userData.vehicleEvents.tripEvents;

export const selectCareEvents = (state: RootState): VehicleEventData | null =>
  state.userData.vehicleEvents.careEvents;

export const selectCarEvents = (
  state: RootState
): Array<VehicleEventData> | null => state.userData.vehicleEvents.carEvents;

export const selectCarEventsFilters = (state: RootState): string[] =>
  state.userData.vehicleEvents.carEventsFilters;

export const selectLoadingVehicleEvents = (state: RootState): Boolean =>
  state.userData.vehicleEvents.loading;

export const selectClaimLastFrom = (state: RootState): string | null =>
  state.userData.vehicleEvents.claimLastFrom;

export const selectVehicleEventsTo = (state: RootState): string | null =>
  state.userData.vehicleEvents.to;

export const selectIsAllClaimEventsLoaded = (state: RootState): boolean =>
  state.userData.vehicleEvents.isAllClaimEventsLoaded;

export const selectIsAllCarEventsLoaded = (state: RootState): boolean =>
  state.userData.vehicleEvents.isAllCarEventsLoaded;
