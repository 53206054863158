import React from 'react';
import { DatePicker } from 'antd';
import styles from './DateSearch.styles';
import { ANT_PICKERS_FORMAT } from '../../utils';

type DateSearchProps = {
  setFromDate: (value: any) => void;
  setUntilDate: (value: any) => void;
};

const DateSearch: React.FC<DateSearchProps> = ({
  setFromDate,
  setUntilDate,
}) => {
  const onChangeFromDate: any = (_, dateString) => {
    setFromDate(dateString);
  };
  const onChangeUntilDate: any = (_, dateString) => {
    setUntilDate(dateString);
  };

  return (
    <>
      <div className="date-search">
        <DatePicker
          format={ANT_PICKERS_FORMAT}
          className="date-filter-input"
          onChange={onChangeFromDate}
          placeholder="From"
          getPopupContainer={(triggerNode: HTMLElement) => {
            return triggerNode.parentNode as HTMLElement;
          }}
        />
        <DatePicker
          format={ANT_PICKERS_FORMAT}
          className="date-filter-input"
          onChange={onChangeUntilDate}
          getPopupContainer={(triggerNode: HTMLElement) => {
            return triggerNode.parentNode as HTMLElement;
          }}
          placeholder="Until"
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default DateSearch;
