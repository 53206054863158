import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, FleetData } from '../../../../@types';
import { FleetsTypes } from '../../../../@types/enums';

export const fleetsRequest = () => createAction(FleetsTypes.FLEETS_REQUEST);

export const fleetsSuccess = (payload: FleetData[]) =>
  createAction(FleetsTypes.FLEETS_SUCCESS, payload);

export const fleetsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(FleetsTypes.FLEETS_FAILURE, error);
