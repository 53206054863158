import React from 'react';
import { useIntl } from 'react-intl';
import { FleetData } from '../../@types';
import SelectorLayout from '../../components/shared/SelectorLayout';

type FleetSelectorProps = {
  fleets: Array<FleetData>;
  value: string;
  className?: string;
  onSelect?: (value: string) => void;
};

const FleetSelector: React.FC<FleetSelectorProps> = (props) => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: 'fleetadmin.searchBox.fleet.placeholder',
  });

  const options = props.fleets?.map((fleet) => ({
    value: fleet.id,
    label: fleet.name,
  }));

  return (
    <SelectorLayout
      keyPrefix="fleet-select"
      placeholder={placeholder}
      options={options}
      {...props}
    />
  );
};

export default FleetSelector;
