import inside from 'point-in-polygon';
import { AreaData } from '../@types';
import locationFromString from './locationFromString';

type Location = { lat: number; lng: number };

export const getAreaCoordinates = (area: any) => {
  return area.geoJSONData.features[0].geometry.coordinates.map(
    (coordinate: string) =>
      locationFromString(coordinate.split(' ').reverse().join(','))
  );
};

export const isInsideArea = (target: any, area: AreaData | null): boolean => {
  if (target && area) {
    const areaLocations = getAreaCoordinates(area);
    const targetCoordinate = [target.lat, target.lng];
    const areaCoordinates = areaLocations.map((location: Location) => [
      location.lat,
      location.lng,
    ]);
    return inside(targetCoordinate, areaCoordinates);
  }
  return true;
};
