import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import UsersDrawerBody from './UsersDrawerBody';
import {
  closeUsersDrawerAndSetQueries,
  selectViewMode,
} from '../../redux/store/ui/common';
import { selectSelectedUser } from '../../redux/store/userData/users';
import { ViewMode } from '../../hooks/useViewMode';
import ReceiptDrawer from '../ReceiptDrawer';
import UserDuplicateDrawer from './UserDuplicateDrawer';
import TripDrawer from '../TripDrawer';

const UsersDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const visible = selectedUser != null;
  const viewMode = useSelector(selectViewMode);

  const handleOnClose = () => {
    dispatch(closeUsersDrawerAndSetQueries());
  };

  return (
    selectedUser && (
      <DrawerLayout
        visible={visible}
        title={selectedUser?.id!!}
        body={<UsersDrawerBody selectedUser={selectedUser} />}
        nestedDrawers={
          <>
            {viewMode !== ViewMode.RECEIPTS && <ReceiptDrawer />}
            {viewMode !== ViewMode.SHARE && <TripDrawer />}
            <UserDuplicateDrawer />
          </>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default UsersDrawer;
