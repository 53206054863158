import React, { useEffect } from 'react';
import { InputNumber } from 'antd';
import styles from './Number.styles';

type NumberProps = {
  unit?: string;
  onChangeValue: (arg: any) => void;
};

const Number: React.FC<NumberProps> = ({ unit = '', onChangeValue }) => {
  const formatter = (value: any) => `${value}${unit}`;
  const parser: any = (value: string) => value.replace(unit, '');

  useEffect(() => {
    onChangeValue(0);
  }, []);

  return (
    <>
      <div className="number-search">
        <InputNumber
          className="input"
          defaultValue={0}
          min={0}
          max={3}
          formatter={formatter}
          parser={parser}
          onChange={onChangeValue}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Number;
