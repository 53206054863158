const recordColumns = [
  { label: 'index', value: 'index', disabled: true },
  { label: 'where', value: 'damageWhere', disabled: true },
  { label: 'side', value: 'damageSide', disabled: true },
  { label: 'location', value: 'damageLocation', disabled: true },
  { label: 'component', value: 'damageComponent', disabled: true },
];

export const defaultSelectedRecordColumns = recordColumns.map(
  (recordColumn) => recordColumn.value
);

export default recordColumns;
