import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const userDetailsStyles = css`
  .user-details p {
    margin: 0;
  }
  .email {
    color: ${colors.blue};
    cursor: pointer;
  }
`;

export const claimInfoStyles = css`
  .row {
    display: flex;
    width: 100%;
  }
  .images-wrapper {
    margin-top: 15px;
    margin-bottom: 0;
  }
  .reservedAtBody {
    display: flex;
    margin-bottom: 0;
    font-weight: normal !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .no-img-message {
    text-align: left;
    font-size: 14px !important;
  }
  .image-wrapper {
    margin-bottom: 8px;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .link {
    margin: 0;
    color: ${colors.interactiveLink};
    cursor: pointer;
  }
`;
