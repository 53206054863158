import { Target } from '../@types';

const targetsForClaim: Target[] = [
  {
    value: 'damageId',
    type: 'text',
    isAPI: true,
    queryName: 'presentableId',
  },
  {
    value: 'status',
    type: 'selection',
    isAPI: true,
    queryName: 'status',
    queryType: 'array',
  },
  {
    value: 'closeReason',
    type: 'selection',
    isAPI: true,
    queryName: 'closeReason',
    queryType: 'array',
  },
  {
    value: 'fleetId',
    type: 'selection',
    isAPI: true,
    queryName: 'fleet',
    queryType: 'array',
  },
  { value: 'roadworthiness', type: 'boolean', isAPI: true },
  { value: 'thirdParty', type: 'boolean', isAPI: true },
  {
    value: 'addedAt',
    type: 'date',
    isAPI: true,
    queryNameFrom: 'addedAtFrom',
    queryNameTo: 'addedAtTo',
  },
  { value: 'userId', type: 'text', isAPI: true, queryName: 'user' },
  { value: 'carId', type: 'text', isAPI: true, queryName: 'car' },
  { value: 'tripId', type: 'text', isAPI: true, queryName: 'trip' },
  { value: 'tripIgnition', type: 'selection', isAPI: false },
  { value: 'userReferralCode', type: 'text', isAPI: false },
  { value: 'brand', type: 'text', isAPI: false },
  { value: 'plate', type: 'text', isAPI: true, queryName: 'car' },
  { value: 'tripPresentableId', type: 'text', isAPI: true },
];

export default targetsForClaim;
