import css from 'styled-jsx/css';

const style = css`
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
  }
  li {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`;

export default style;
