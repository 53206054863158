import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';
import getFleetNameById from '../../utils/getFleetNameById';
import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDateTime,
  getRenderLink,
  getRenderAction,
} from '../shared/TableLayout';
import message from '../../utils/message';
import ClaimStatusLabel from '../../components/shared/ClaimStatusLabel';
import { setQueries } from '../../libs/setQueries';
import { ViewType } from '../../hooks/useViewType';
import { selectClaim } from '../../redux/store/userData/claims';
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import { DamageEntityData } from '../../@types';

type getColumnsArgs = {
  fleets: any;
  dispatch: Dispatch;
  intl: IntlShape;
};

const getColumns = ({ fleets, dispatch, intl }: getColumnsArgs): any[] => {
  const renderTripIgnition = (
    tripIgnition:
      | boolean
      | ReactChild
      | ReactFragment
      | ReactPortal
      | null
      | undefined
  ) => (
    <span>
      {intl.formatMessage({
        id: `fleetadmin.claim.${tripIgnition}`,
      })}{' '}
    </span>
  );

  const renderStatus = (status: any) => (
    <ClaimStatusLabel status={status || 'open'} />
  ); //TODO: replace "OPEN" to status

  const renderCloseReason = (closeReason: any) => (
    <span>{closeReason || '-'}</span>
  );

  const renderFleetName = (fleetId: any) => (
    <span>{getFleetNameById(fleets, fleetId) || '-'}</span>
  );

  const renderBoolean = (value: boolean) => (
    <span>{value ? 'True' : !value ? 'False' : 'Unknown'}</span>
  );

  const renderAction = getRenderAction((claim: DamageEntityData | null) => {
    if (claim?.geoLocation) {
      dispatch(selectClaim(claim));
      setQueries({ type: ViewType.MAP });
    } else {
      message.error(
        intl.formatMessage({
          id: 'fleetadmin.claim.noPositionData',
        })
      );
    }
  });

  return [
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.damage',
      }),
      dataIndex: 'damageId',
      render: getRenderLink(),
      ellipsis: true,
      width: 200,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('damageId'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.status',
      }),
      dataIndex: 'status',
      render: renderStatus,
      width: 150,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.closereason',
      }),
      dataIndex: 'closeReason',
      render: renderCloseReason,
      width: 180,
      sorter: alphabeticalSorterFor('closeReason'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.ignition',
      }),
      dataIndex: 'tripIgnition',
      render: renderTripIgnition,
      width: 130,
      sorter: alphabeticalSorterFor('tripIgnition'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.addedat',
      }),
      dataIndex: 'addedAt',
      render: renderDateTime,
      width: 180,
      sorter: chronologicalSorterFor('addedAt'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.brand',
      }),
      dataIndex: 'brand',
      width: 150,
      sorter: alphabeticalSorterFor('brand'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.fleet',
      }),
      dataIndex: 'fleetId',
      render: renderFleetName,
      width: 150,
      ellipsis: true,
      sorter: (careA: { fleetId: any }, careB: { fleetId: any }) => {
        const A = getFleetNameById(fleets, careA.fleetId) || '-';
        const B = getFleetNameById(fleets, careB.fleetId) || '-';
        return alphabeticalSorter(A, B);
      },
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.plate',
      }),
      dataIndex: 'plate',
      width: 150,
      ellipsis: true,
      sorter: alphabeticalSorterFor('plate'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.user',
      }),
      dataIndex: 'userReferralCode',
      width: 150,
      ellipsis: true,
      sorter: alphabeticalSorterFor('userCode'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.tripid',
      }),
      dataIndex: 'tripPresentableId',
      width: 150,
      ellipsis: true,
      sorter: alphabeticalSorterFor('tripId'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.thirdparty',
      }),
      dataIndex: 'thirdParty',
      render: renderBoolean,
      width: 150,
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.roadworthiness',
      }),
      dataIndex: 'roadworthiness',
      render: renderBoolean,
      width: 150,
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.table.action',
      }),
      dataIndex: 'operation',
      width: 170,
      render: renderAction,
    },
  ];
};

export default getColumns;
