import moment from 'moment';

const formatDateTime = (
  dateTime: moment.MomentInput,
  format = 'YYYY-MM-DD HH:mm'
) => {
  if (dateTime === '') {
    return '';
  }
  return moment(dateTime).format(format);
};

export default formatDateTime;

export const metaStringToDate = (dateTimeStr, timeZoneOffsetStr) => {
  if (!dateTimeStr || typeof dateTimeStr !== 'string') {
    return null;
  }

  const [date, time] = dateTimeStr.split(' ');

  if (!date || !time) {
    return null;
  }

  const [year, month, day] = date.split(':');
  const [hour, minute, second] = time.split(':');

  if (
    [year, month, day, hour, minute, second].some(
      (value) => value === undefined
    )
  ) {
    return null;
  }

  const newDate = new Date(
    // @ts-ignore
    Date.UTC(year, month - 1, day, hour, minute, second)
  );

  if (timeZoneOffsetStr) {
    if (typeof timeZoneOffsetStr !== 'string' || timeZoneOffsetStr.length < 6) {
      return null;
    }

    const sign = timeZoneOffsetStr[0];
    const hoursOffset = parseInt(timeZoneOffsetStr.substring(1, 3), 10);
    const minutesOffset = parseInt(timeZoneOffsetStr.substring(4, 6), 10);

    if (isNaN(hoursOffset) || isNaN(minutesOffset)) {
      return null;
    }

    const totalOffsetMinutes =
      (sign === '+' ? 1 : -1) * (hoursOffset * 60 + minutesOffset);
    newDate.setMinutes(newDate.getMinutes() - totalOffsetMinutes);
  }

  return newDate;
};
