import React from 'react';
import { useSelector } from 'react-redux';
import styles from './ReceiptDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import { selectSelectedReceipt } from '../../redux/store/userData/receipts';
import ReceiptInfo from './ReceiptInfo';

const ReceiptDrawerBody = () => {
  const selectedReceipt = useSelector(selectSelectedReceipt);

  const tabs = [{ title: 'Info', content: <ReceiptInfo /> }];

  return (
    <>
      <div className="receipt-drawer-body">
        <div className="receipt-name-wrapper">
          <p className="name" title={selectedReceipt?.receiptNo}>
            {selectedReceipt!.receiptNo!.length > 20
              ? `${selectedReceipt!.receiptNo!.slice(0, 20)}...`
              : selectedReceipt!.receiptNo}
          </p>
        </div>
        <TabLayout tabs={tabs} keyName="receipt-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ReceiptDrawerBody;
