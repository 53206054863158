import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  p {
    margin-bottom: 0;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 14px;
    width: 130px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
    margin-right: 8px;
  }
  p.open {
    background: ${colors.claim.open};
  }
  p.in_progress {
    background: ${colors.claim.inProgress};
  }
  p.confirmed {
    background: ${colors.claim.inProgress};
  }
  p.completed {
    background: ${colors.claim.inProgress};
  }
  p.closed {
    background: ${colors.claim.closed};
  }
  p.close_settled {
    background: ${colors.claim.closedSettled};
  }
`;

export default style;
