import css from 'styled-jsx/css';
import { colors, common } from '../../theme/theme';

const style = css`
  .filter-vehicle-events-modal {
    width: 230px;
    padding: 21px 26px 11px 26px;
  }
  .filter-vehicle-events-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.cancelButton}
  }
  .filter-vehicle-events-modal :global(.submit-button:hover) {
    margin-top: 24px;
    ${common.button}
    ${common.cancelButton}
  }
  .filter-vehicle-events-modal
    :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: ${colors.black};
    border-color: ${colors.black};
    border-radius: 2px;
  }
  .filter-vehicle-events-modal :global(.ant-checkbox-group-item) {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
  .filter-vehicle-events-modal :global(.ant-checkbox + span) {
    padding-left: 20px;
    font-family: Avenir Next;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black};
  }
  .filter-vehicle-events-modal :global(.ant-checkbox-inner) {
    width: 18px;
    height: 18px;
  }
`;

export default style;
