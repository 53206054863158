import { Reducer } from '../../../../@types';
import { TripsTypes } from '../../../../@types/enums';
import { initialState, TripsState } from './initialState';
// @ts-ignore
export const tripsReducer: Reducer<TripsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case TripsTypes.TRIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TripsTypes.TRIPS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case TripsTypes.TRIPS_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
      };
    case TripsTypes.SET_DISPLAY_TRIPS:
      return {
        ...state,
        display: action.payload,
      };
    case TripsTypes.SET_TOTAL_TRIPS:
      return {
        ...state,
        totalTrips: action.payload,
      };
    case TripsTypes.SELECT_TRIP:
      return {
        ...state,
        selected: action.payload,
      };
    case TripsTypes.BOOKINGS_REQUEST:
      return {
        ...state,
        loadingBookings: true,
      };
    case TripsTypes.BOOKINGS_SUCCESS:
      return {
        ...state,
        bookingEntities: action.payload,
        loadingBookings: false,
      };
    case TripsTypes.UPDATE_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookingEntities: state?.bookingEntities
          ? [
              // @ts-ignore
              ...state?.bookingEntities?.map((entity) =>
                entity.id === action?.payload?.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        bookingDisplay: state?.bookingDisplay
          ? [
              // @ts-ignore
              ...state?.bookingDisplay?.map((entity) =>
                entity.id === action?.payload?.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        selectedBooking: { ...state.selectedBooking, ...action?.payload },
      };
    case TripsTypes.UPDATE_TRIP_SUCCESS:
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        display: [
          // @ts-ignore
          ...state.display.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        selected: { ...state.selected, ...action.payload },
        loading: false,
      };
    case TripsTypes.BOOKINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingBookings: false,
      };
    case TripsTypes.SET_BOOKINGS_DISPLAY:
      return {
        ...state,
        bookingDisplay: action.payload,
      };
    case TripsTypes.SET_TOTAL_BOOKINGS:
      return {
        ...state,
        totalBookings: action.payload,
      };
    case TripsTypes.SELECT_BOOKING:
      return {
        ...state,
        selectedBooking: action.payload,
      };
    case TripsTypes.TRIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TripsTypes.TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TripsTypes.TRIP_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
      };
    case TripsTypes.TRIP_INVOICE_SUCCESS:
      return {
        ...state,
        selectedTripInvoiceData: action.payload,
      };
    case TripsTypes.FINISH_TRIP_REQUEST:
      return {
        ...state,
        finishingTrip: true,
        loading: true,
      };
    case TripsTypes.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        display: [
          // @ts-ignore
          ...state.display.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        selected: { ...state.selected, ...action.payload },
        finishingTrip: false,
      };
    case TripsTypes.FINISH_TRIP_FAILURE:
      return {
        ...state,
        error: action.error,
        finishingTrip: false,
      };

    case TripsTypes.START_TRIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TripsTypes.START_TRIP_SUCCESS:
      return {
        ...state,
        entities: [
          // @ts-ignore
          ...state.entities.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        display: [
          // @ts-ignore
          ...state.display.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        selected: { ...state.selected, ...action.payload },
        loading: false,
      };
    case TripsTypes.START_TRIP_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TripsTypes.FINISH_TRIP_VIOLATIONS:
      return {
        ...state,
        finishingTrip: false,
        finishTripViolations: action.payload,
      };
    default:
      return state;
  }
};
