import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CarTimeline.styles';
import TimelineItem from '../TimeLine/TimelineItem';
import NowItem from '../../components/CarEditDrawer/NowItem';
import LoadMoreCarEvents from './LoadMoreCarEvents';
import { isBefore } from 'date-fns';
import { FILTER_VEHICLE_EVENTS_MODAL } from './FilterVehicleEventsModal';
import TimeTag from '../TimeLine/TimeTag';
import moment from 'moment';
import {
  selectSelectedCar,
  setCarDrawerActiveTab,
} from '../../redux/store/userData/cars';
import {
  selectCarEventsFilters,
  selectCarEvents,
  setCarEventsFilters,
  fetchVehicleEvents,
} from '../../redux/store/userData/vehicleEvents';
import { openModal } from '../../redux/store/ui/common';
import { Checkbox } from 'antd';
import ClaimTimeline from '../../components/ClaimDrawer/ClaimTimeline';
import { selectSelectedClaim } from '../../redux/store/userData/claims';
import { selectToken } from '../../redux/store/userData/user';

const CarTimeline: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedClaim = useSelector(selectSelectedClaim);
  const [isGroupedByTrip, setIsGroupedByTrip] = useState(!!selectedClaim);
  const vehicleEvents = useSelector(selectCarEvents) || [];
  const selectedCar = useSelector(selectSelectedCar);
  const carEventsFilters = useSelector(selectCarEventsFilters);

  const noteAddedEvents =
    selectedCar && vehicleEvents && carEventsFilters?.includes('BaseData')
      ? selectedCar?.notes
          ?.filter((note) =>
            isBefore(
              // @ts-ignore
              new Date(vehicleEvents[vehicleEvents?.length - 1]?.addedAt),
              // @ts-ignore
              new Date(note.timestamp)
            )
          )
          .map((note) => ({
            type: 'noteAdded',
            addedAt: note.timestamp,
            text: note.text,
            // @ts-ignore
            author: note?.author,
          }))
      : [];
  // @ts-ignore
  const mergedVehicleEvents = vehicleEvents.concat(noteAddedEvents);
  mergedVehicleEvents?.sort(function (a, b) {
    const dateA = new Date(a.addedAt!),
      dateB = new Date(b.addedAt!);
    // @ts-ignore
    return dateB - dateA;
  });

  const handleFilterButton = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(openModal(FILTER_VEHICLE_EVENTS_MODAL));
  };

  const onClickGroupBy = (e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setIsGroupedByTrip(e?.target?.checked);
    if (e?.target?.checked) {
      const from = moment().subtract(7, 'days').toISOString();
      const to = moment().toISOString();
      dispatch(setCarEventsFilters(['Claims']));
      dispatch(setCarDrawerActiveTab(1));
      dispatch(
        fetchVehicleEvents(
          token,
          selectedCar?.id!,
          from,
          to,
          ['damageClaim'],
          'CLAIM',
          false
        )
      );
    }
  };

  return (
    <>
      <div className="timeline-wrapper">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '4px',
            backgroundColor: 'white',
          }}
        >
          <Checkbox checked={isGroupedByTrip} onChange={onClickGroupBy}>
            Group by trip
          </Checkbox>
          <div className="timeline-filter-button" onClick={handleFilterButton}>
            Filters
          </div>
        </div>
        {isGroupedByTrip ? (
          <ClaimTimeline />
        ) : (
          <>
            {mergedVehicleEvents && <NowItem key={`vehicleEvents-now`} />}
            {mergedVehicleEvents?.map((ve, _i) => {
              return (
                <>
                  {(_i === 0 ||
                    (ve?.addedAt &&
                      !moment(ve?.addedAt)?.isSame(
                        mergedVehicleEvents[_i - 1].addedAt,
                        'day'
                      ))) && <TimeTag date={ve?.addedAt!} />}
                  {ve && (
                    <TimelineItem
                      key={`vehicleEvents-${_i}`}
                      vehicleEvent={ve}
                      hideSecondBar={_i === mergedVehicleEvents.length - 1}
                    />
                  )}
                </>
              );
            })}
          </>
        )}
        {!isGroupedByTrip && <LoadMoreCarEvents />}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default CarTimeline;
