import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getTheDayBefore from '../../utils/datetime/getTheDayBefore';
import { Spin } from 'antd';
import styles from './LoadMoreClaimEvents.styles';
import { selectSelectedClaim } from '../../redux/store/userData/claims';
import {
  fetchVehicleEventsIfNeeded,
  selectIsAllClaimEventsLoaded,
  selectClaimLastFrom,
  selectLoadingVehicleEvents,
} from '../../redux/store/userData/vehicleEvents';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const LoadingIcon = (props: any) => <Spin indicator={antIcon} {...props} />;

type LoadMoreProps = {};

const LoadMoreClaimEvents: React.FC<LoadMoreProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingVehicleEvents);
  const selectedCar = useSelector(selectSelectedCar);
  const selectedClaim = useSelector(selectSelectedClaim);
  const previousFrom = useSelector(selectClaimLastFrom) as any;
  const isAllEventsLoaded = useSelector(selectIsAllClaimEventsLoaded);

  const theDayBeforePreviousFrom = getTheDayBefore(
    previousFrom!
  ).toISOString() as any;
  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(
      fetchVehicleEventsIfNeeded(
        selectedClaim?.carId || selectedCar?.id,
        theDayBeforePreviousFrom,
        previousFrom,
        ['damageClaim'],
        'CLAIM',
        true
      )
    );
  };

  if (isAllEventsLoaded) {
    return null;
  }

  return (
    <>
      <div className="wrapper">
        <div className="first bar" />
        {isLoading ? (
          <div className="loading-icon-wrapper">
            <LoadingIcon />
          </div>
        ) : (
          <div className="load-more" onClick={onClick}>
            Load more...
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default LoadMoreClaimEvents;
