import React from 'react';
import {
  alphabeticalSorterFor,
  chronologicalSorterFor,
  getRenderWithPlaceholder,
  renderDateTime,
} from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import { typeLabelForCircle } from '../../utils';
import RenderBoolean from '../../components/shared/RenderBoolean';
import { IntlShape } from 'react-intl';

const getColumns = (intl: IntlShape) => {
  const renderType = (circleType: string) => (
    <span>{typeLabelForCircle(intl, circleType)}</span>
  );
  const renderCode = (
    circleCode:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined
  ) => <span>{circleCode}</span>;
  const renderTrueOrFalse = (value: boolean) => <RenderBoolean value={value} />;
  const renderModifiedBy = (
    lastModifiedBy:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined
  ) => <span>{lastModifiedBy}</span>;

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: isDesktop() ? 250 : 150,
      fixed: 'left' as 'left',
      render: getRenderWithPlaceholder(),
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: 'Type',
      dataIndex: 'circleType',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      render: renderType,
      sorter: alphabeticalSorterFor('circleType'),
    },
    {
      title: 'Code',
      dataIndex: 'circleCode',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      render: renderCode,
      sorter: alphabeticalSorterFor('circleCode'),
    },
    {
      title: 'Created at',
      dataIndex: 'created',
      render: renderDateTime,
      ellipsis: true,
      width: 200,
      sorter: chronologicalSorterFor('created'),
    },
    {
      title: 'Last modified at',
      dataIndex: 'lastModifiedAt',
      render: renderDateTime,
      ellipsis: true,
      width: 200,
      sorter: chronologicalSorterFor('lastModifiedAt'),
    },
    {
      title: 'Last modified by',
      dataIndex: 'lastModifiedBy',
      render: renderModifiedBy,
      ellipsis: true,
      width: 200,
      sorter: alphabeticalSorterFor('lastModifiedBy'),
    },
  ];
};

export default getColumns;
