export enum EventsTypes {
  SET_VEHICLE_EVENTS_FROM = 'userData/VehicleEvents/SET_VEHICLE_EVENTS_FROM',
  VEHICLE_EVENTS_REQUEST = 'userData/VehicleEvents/VEHICLE_EVENTS_REQUEST',
  VEHICLE_EVENTS_SUCCESS = 'userData/VehicleEvents/VEHICLE_EVENTS_SUCCESS',
  VEHICLE_EVENTS_FAILURE = 'userData/VehicleEvents/VEHICLE_EVENTS_FAILURE',
  ADD_VEHICLE_EVENTS = 'userData/VehicleEvents/ADD_VEHICLE_EVENTS',
  LOAD_MORE_VEHICLE_EVENTS_REQUEST = 'userData/VehicleEvents/LOAD_MORE_VEHICLE_EVENTS_REQUEST',
  LOAD_MORE_VEHICLE_EVENTS_SUCCESS = 'userData/VehicleEvents/LOAD_MORE_VEHICLE_EVENTS_SUCCESS',
  VEHICLE_EVENTS_EMPTY_RESPONSE = 'userData/VehicleEvents/VEHICLE_EVENTS_EMPTY_RESPONSE',
  VEHICLE_EVENTS_RESET = 'userData/VehicleEvents/VEHICLE_EVENTS_RESET',
  SET_VEHICLE_EVENTS_FILTERS = 'userData/VehicleEvents/SET_VEHICLE_EVENTS_FILTERS',
}
