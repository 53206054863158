import { FleetData, Reducer } from '../../../../@types';
import { FleetsTypes } from '../../../../@types/enums';
import { initialState, FleetsState } from './initialState';
import { uniqueArrayOfObjects } from '../../../../utils/brandHelpers';

// @ts-ignore
export const fleetsReducer: Reducer<FleetsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case FleetsTypes.FLEETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FleetsTypes.FLEETS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        brands: uniqueArrayOfObjects(
          action.payload.map((fleet: FleetData) => ({
            id: fleet.brandId,
            name: fleet.brandName,
          }))
        ),
        loading: false,
      };
    case FleetsTypes.FLEETS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
