import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectDisplayColumnsForProfile,
  selectProfileWithUIEffect,
} from '../../redux/store/ui/common';
import {
  fetchProfiles,
  selectDisplayProfiles,
  selectProfiles,
  selectSelectedProfile,
} from '../../redux/store/userData/profiles';
import { selectBrands } from '../../redux/store/appData/fleets';
import { getFilteredColumns } from '../../utils';

const ProfilesTable = () => {
  const dispatch = useDispatch();
  const displayProfiles = useSelector(selectDisplayProfiles);
  const profileEntities = useSelector(selectProfiles);
  const selectedProfile = useSelector(selectSelectedProfile);
  const displayColumns = useSelector(selectDisplayColumnsForProfile);
  const selectedId = selectedProfile ? selectedProfile.id : null;
  const brands = useSelector(selectBrands);
  const columns = getColumns(brands!);
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: any) => {
    dispatch(selectProfileWithUIEffect(record));
  };

  const loadProfiles = useCallback(async () => {
    dispatch(fetchProfiles());
  }, []);

  useEffect(() => {
    if (!profileEntities) {
      loadProfiles();
    }
  }, []);

  return (
    <TableLayout
      rowKey="profile"
      columns={filteredColumns}
      dataSource={displayProfiles!!}
      selectedId={selectedId!!}
      totalNumber={displayProfiles?.length}
      onClickRow={onClickRow}
    />
  );
};

export default ProfilesTable;
