import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import AdminsDrawerBody from './AdminsDrawerBody';
import { closeAdminsDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedAdmin } from '../../redux/store/userData/admins';

const AdminsDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedAdmin = useSelector(selectSelectedAdmin);
  const visible = selectedAdmin != null;

  const handleOnClose = () => {
    dispatch(closeAdminsDrawerAndSetQueries());
  };

  return (
    selectedAdmin && (
      <DrawerLayout
        visible={visible}
        title={selectedAdmin?.id!!}
        body={<AdminsDrawerBody />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default AdminsDrawer;
