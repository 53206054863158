import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './RootReducer';
import { persistStore } from 'redux-persist';

export const configureStore = (initialState) => {
  const middlewares = [thunk];
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept(() => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./RootReducer');
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
};
