const getFleetNameById = (fleets: any, fleetId: any) => {
  if (!Array.isArray(fleets)) {
    return fleetId;
  }

  const theFleet = fleets.find((fleet) => fleet.id === fleetId);
  return theFleet ? theFleet.name : fleetId;
};

export default getFleetNameById;
