import React, { useState } from 'react';
import { isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import styles from './TripTimeline.styles';
import TimelineItem from '../TimeLine/TimelineItem';
import TripIncompleteItem from './TripIncompleteItem';
import AllAroundModal from '../../components/AllAroundModal';
import { useIntl } from 'react-intl';
import { selectSelectedCare } from '../../redux/store/userData/cares';
import {
  selectTripEvents,
  selectCareEvents,
} from '../../redux/store/userData/vehicleEvents';
import { selectSelectedTrip } from '../../redux/store/userData/trips';

const TripTimeline: React.FC = () => {
  const intl = useIntl();
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedCare = useSelector(selectSelectedCare);
  const vehicleEvents: any = useSelector(
    // @ts-ignore
    selectedCare ? selectCareEvents : selectTripEvents
  );
  const lastIgnitionChange =
    vehicleEvents &&
    vehicleEvents?.filter((e: { type: string }) => e.type === 'ignitionChanged')
      .length > 0
      ? vehicleEvents
          ?.filter((e: { type: string }) => e.type === 'ignitionChanged')
          ?.reduce(function (
            prev: { addedAt: string | number | Date },
            current: { addedAt: string | number | Date }
          ) {
            return isAfter(new Date(prev.addedAt), new Date(current.addedAt))
              ? prev
              : current;
          })
      : null;
  const filteredVehicleEvents = vehicleEvents
    ? vehicleEvents
        ?.filter(
          (event: any) =>
            !(
              event?.type === 'ignitionChanged' &&
              !(
                event?.initialTripIgnition ||
                (event?.addedAt === lastIgnitionChange?.addedAt &&
                  event.ignitionOff === true)
              )
            )
        )
        .map((event: any) => {
          if (
            event.type === 'ignitionChanged' &&
            event.ignitionOff === true &&
            event?.addedAt === lastIgnitionChange?.addedAt
          ) {
            return { ...event, lastTripIgnition: true };
          }
          return event;
        })
    : [];

  const targetTrip = selectedTrip || selectedCare;
  let mergedVehicleEvents = filteredVehicleEvents;
  let records = targetTrip?.records ?? [];

  if (records.length > 0) {
    mergedVehicleEvents = [
      ...filteredVehicleEvents,
      ...records.map((record) => ({
        ...record,
        type: 'recordAdded',
        addedAt: record.timestamp,
        author: record.operator,
        images: [record.file],
      })),
    ];
  }

  const sortedEvents = [...mergedVehicleEvents].sort(function (a, b) {
    const dateA = new Date(a.addedAt),
      dateB = new Date(b.addedAt);
    // @ts-ignore
    return dateB - dateA;
  });

  const [isVisibleCarCheckModal, setIsVisibleCarCheckModal] = useState(false);
  const [images, setImages] = useState([]);
  const [imageType, setImageType] = useState('');
  const allAroundTitle = intl.formatMessage({
    id: 'fleetadmin.trip.allAround.title',
  });

  const serviceImageTitle = intl.formatMessage({
    id: 'fleetadmin.trip.serviceImage.modal.title',
  });

  const openCarCheckModal = (ve: any) => {
    setImages(ve.images);
    setImageType(ve.imageType);
    setIsVisibleCarCheckModal(true);
  };

  const closeCarCheckModal = () => {
    setIsVisibleCarCheckModal(false);
  };

  return (
    <>
      <div className="timeline-wrapper">
        {!['DONE', 'CLOSED'].includes(targetTrip?.status!) && (
          <TripIncompleteItem
            key={`vehicleEvents-incomplete`}
            status={targetTrip?.status!}
          />
        )}
        {sortedEvents?.map((ve, _i) => {
          return (
            <>
              {ve && (
                <TimelineItem
                  key={`vehicleEvents-${_i}`}
                  vehicleEvent={ve}
                  hideFirstBar={
                    _i === 0 && ['DONE', 'CLOSED'].includes(targetTrip?.status!)
                  }
                  hideSecondBar={_i === sortedEvents.length - 1}
                  onClick={() => openCarCheckModal(ve)}
                />
              )}
            </>
          );
        })}
      </div>
      {images && images.length > 0 && (
        <AllAroundModal
          allAroundCheck={{
            images: images,
            title: imageType?.includes('service')
              ? serviceImageTitle
              : allAroundTitle,
            chassisNumber: selectedTrip?.chassisNumber || '',
          }}
          visible={isVisibleCarCheckModal}
          onClose={closeCarCheckModal}
        />
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default TripTimeline;
