export enum AdminsTypes {
  ADMINS_REQUEST = 'userData/Admins/ADMINS_REQUEST',
  ADMINS_SUCCESS = 'userData/Admins/ADMINS_SUCCESS',
  ADMINS_FAILURE = 'userData/Admins/ADMINS_FAILURE',
  SET_DISPLAY_ADMINS = 'userData/Admins/SET_DISPLAY_ADMINS',
  SELECT_ADMIN = 'userData/Admins/SELECT_ADMIN',
  ADMINS_UPDATE_REQUEST = 'userData/Admins/ADMINS_UPDATE_REQUEST',
  ADMINS_UPDATE_SUCCESS = 'userData/Admins/ADMINS_UPDATE_SUCCESS',
  ADMINS_UPDATE_FAILURE = 'userData/Admins/ADMINS_UPDATE_FAILURE',
  ADMINS_CREATE_REQUEST = 'userData/Admins/ADMINS_CREATE_REQUEST',
  ADMINS_CREATE_SUCCESS = 'userData/Admins/ADMINS_CREATE_SUCCESS',
  ADMINS_CREATE_FAILURE = 'userData/Admins/ADMINS_CREATE_FAILURE',
  ADMINS_DELETE_REQUEST = 'userData/Admins/ADMINS_DELETE_REQUEST',
  ADMINS_DELETE_SUCCESS = 'userData/Admins/ADMINS_DELETE_SUCCESS',
  ADMINS_DELETE_FAILURE = 'userData/Admins/ADMINS_DELETE_FAILURE',
}
