import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .pricing-edit-drawer-body {
    background: ${colors.white};
    min-height: 100%;
  }
  .pricing-name-wrapper {
    padding: 8px 24px 24px;
    background: ${colors.backgroundContentMain};
  }
  .name {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0;
  }
`;

export default style;
