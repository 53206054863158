import css from 'styled-jsx/css';
import { common, colors } from '../../theme/theme';

const style = css`
  .filter-panel {
    max-height: calc(100vh - 232px);
    background: ${colors.white};
    ${common.secondaryShadow};
  }
  :global(.filter-panel label) {
    display: flex;
  }
`;

export default style;
