import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import Badge from '../../components/shared/Badge';
import styles from './CircleEditDrawerBody.styles';
import { selectSelectedCircle } from '../../redux/store/userData/circles';
import CircleInfo from '../../components/CircleEditDrawer/CircleInfo';
import JoinedUsers from '../../components/CircleEditDrawer/JoinedUsers';
import AssignedAreas from '../../components/CircleEditDrawer/AssignedAreas';
import AssignedFleets from '../../components/CircleEditDrawer/AssignedFleets';
import CircleAdmins from './CircleAdmins/CircleAdmins';

const CircleEditDrawerBody = () => {
  const selectedCircle = useSelector(selectSelectedCircle);

  const tabs = [
    {
      title: <span>Users {<Badge num={selectedCircle?.users?.length} />}</span>,
      content: <JoinedUsers />,
    },
    {
      title: (
        <span>Admins {<Badge num={selectedCircle?.admins?.length} />}</span>
      ),
      content: <CircleAdmins />,
    },
    selectedCircle?.circleType === 'area'
      ? {
          title: (
            <span>Areas {<Badge num={selectedCircle?.areas?.length} />}</span>
          ),
          content: <AssignedAreas />,
        }
      : {
          title: (
            <span>Fleets {<Badge num={selectedCircle?.fleets?.length} />}</span>
          ),
          content: <AssignedFleets />,
        },
    { title: 'Info', content: <CircleInfo /> },
  ];

  return (
    <>
      <div className="circle-edit-drawer-body">
        <div className="circle-name-wrapper">
          <p className="name" title={selectedCircle?.name}>
            {selectedCircle!.name!.length > 20
              ? `${selectedCircle!.name!.slice(0, 20)}...`
              : selectedCircle?.name}
          </p>
        </div>
        <TabLayout tabs={tabs} keyName="circle-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default CircleEditDrawerBody;
