import React from 'react';
import { useIntl } from 'react-intl';
import SelectorLayout from '../../components/shared/SelectorLayout';
import styles from './CarStatusSelector.styles';

const getIconUrl = (carStatus: string) => `/images/drawer/car-${carStatus}.svg`;

const carStatuses = ['BLOCKED', 'RELEASED', 'RELEASE_READY'];
const blockedCarStatuses = ['BLOCKED', 'RELEASE_READY'];

type CarStatusSelectorProps = {
  style?: any;
  className?: string;
  value?: any;
  loading?: boolean;
  currentStatus?: string;
  onSelect?: (value: string) => void;
};

const CarStatusSelector: React.FC<CarStatusSelectorProps> = (props) => {
  const intl = useIntl();

  const placeholder = intl.formatMessage({
    id: 'fleetadmin.updateStatusModal.statusPlaceholder',
  });
  const availableStatuses =
    props?.currentStatus === 'BLOCKED' ? blockedCarStatuses : carStatuses;
  const options = availableStatuses.map((carStatus) => ({
    value: carStatus,
    label: (
      <div className="status-wrapper">
        <span className="icon-wrapper">
          <img src={getIconUrl(carStatus)} alt="icon" />
        </span>
        {intl.formatMessage({
          id: `fleetadmin.updateStatusModal.${carStatus}`,
        })}
        <style jsx>{styles}</style>
      </div>
    ),
  }));

  return (
    <SelectorLayout
      keyPrefix="care-search"
      placeholder={placeholder}
      options={options}
      {...props}
    />
  );
};

export default CarStatusSelector;
