import React from 'react';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import ListEditItem from '../shared/ListEditItem';
type Props = {
  isSelected: boolean;
  label: string;
  onChange?: SwitchChangeEventHandler;
  disabled?: boolean;
};
export const PermissionSwitch = ({
  isSelected,
  onChange,
  label,
  disabled,
}: Props) => {
  return (
    <ListEditItem
      leftItem={<div className="permission-label">{label}</div>}
      rightItem={
        <Switch checked={isSelected} onChange={onChange} disabled={disabled} />
      }
    />
  );
};
