import { RootState } from '../../../config';

export const selectAdmins = (state: RootState): any[] | null =>
  state.userData?.admins?.entities;

export const selectDisplayAdmins = (state: RootState): any[] | null =>
  state.userData?.admins?.display;

export const selectSelectedAdmin = (state: RootState): any | null =>
  state.userData?.admins?.selected;

export const selectLoadingAdmins = (state: RootState) =>
  state.userData?.admins?.loading;
