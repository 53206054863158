import React from 'react';
import styles from './Badge.styles';

type BadgeProps = {
  num?: number;
};

const Badge: React.FC<BadgeProps> = ({ num }) => {
  if (num === 0 || !num) {
    return null;
  }

  return (
    <>
      <span className="badge">{num}</span>
      <style jsx>{styles}</style>
    </>
  );
};

export default Badge;
