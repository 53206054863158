import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import BookingDrawerBody from './BookingDrawerBody';
import CarEditDrawer from '../../components/CarEditDrawer';
import AreaEditDrawer from '../../components/AreaEditDrawer';
import { ViewMode } from '../../hooks/useViewMode';
import { selectSelectedBooking } from '../../redux/store/userData/trips';
import {
  selectViewMode,
  closeBookingDrawerAndSetQueries,
} from '../../redux/store/ui/common';
import CategoryEditDrawer from '../CategoryEditDrawer';
import { ViewType } from '../../hooks/useViewType';
import { selectViewType } from '../../redux/store/ui/common/selectors';
import PricingEditDrawer from '../PricingEditDrawer';
import TripDrawer from '../TripDrawer';
import UsersDrawer from '../UsersDrawer';

const BookingDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const selectedBooking = useSelector(selectSelectedBooking);

  const handleOnClose = () => {
    dispatch(closeBookingDrawerAndSetQueries());
  };

  return (
    selectedBooking && (
      <DrawerLayout
        visible={selectedBooking != null}
        title={selectedBooking.id}
        body={<BookingDrawerBody />}
        nestedDrawers={
          <>
            {(viewMode !== ViewMode.VEHICLE ||
              viewType === ViewType.TIME_TABLE) && <CarEditDrawer />}
            {viewMode !== ViewMode.AREA && <AreaEditDrawer />}
            {viewMode !== ViewMode.CATEGORIES && <CategoryEditDrawer />}
            {viewMode !== ViewMode.PRICING && <PricingEditDrawer />}
            {viewMode !== ViewMode.SHARE && <TripDrawer />}
            {viewMode !== ViewMode.USERS && <UsersDrawer />}
          </>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default BookingDrawer;
