import { RootState } from '../../../config';
import { VehicleCategoryData } from '../../../../@types';
import { createSelector } from '@reduxjs/toolkit';
import { selectCars } from '../cars';

export const selectCategories = (
  state: RootState
): VehicleCategoryData[] | null => state.userData.categories.entities;

export const selectDisplayCategories = (
  state: RootState
): VehicleCategoryData[] | null => state.userData.categories.display;

export const selectSelectedCategory = (state: RootState): any =>
  state?.userData?.categories?.selected;

export const selectCategoryAssignedVehicles = createSelector(
  [selectCars, selectSelectedCategory],
  (cars, selectedCategory: any) => {
    return cars?.filter((car) => car.categoryId === selectedCategory?.id);
  }
);

export const selectCategoryLoading = (state: RootState): boolean =>
  state.userData.categories.loading;
