import React from 'react';
import { Card } from 'antd';
import styles from './AuthLayout.styles';

const GETAWAY_LOGO = `/images/logo${
  process.env.REACT_APP_STAGE === 'staging' ? '-staging' : ''
}.png`;

type AuthLayoutProps = {
  meta: React.ReactNode;
  title: React.ReactNode;
  body: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ meta, title, body }) => (
  <div className="wrapper">
    {meta}
    <div className="card-wrapper">
      <Card className="card">
        <div className="logo-wrapper">
          <img className="logo-image" src={GETAWAY_LOGO} alt="getaway logo" />
        </div>
        <h1 className="title">{title}</h1>
        {body}
      </Card>
    </div>
    <style jsx>{styles}</style>
  </div>
);

// @ts-ignore
export default AuthLayout;
