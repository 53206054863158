import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../components/shared/TableLayout/TableLayout';
import { setQueryPagination } from '../libs/setQueries';
import { selectAPIFilterParams } from '../redux/store/ui/filters/selectors';
import { selectViewMode } from '../redux/store/ui/common';
import { ViewMode } from '../hooks/useViewMode';
import { selectCars } from '../redux/store/userData/cars';
import { selectTariffs } from '../redux/store/userData/users';

const withPaginatedQueryFetch = (WrappedComponent, fetchDataAction) => {
  const Wrapper = (props) => {
    const dispatch = useDispatch();
    const cars = useSelector(selectCars);
    const tariffs = useSelector(selectTariffs);
    const viewMode = useSelector(selectViewMode);
    const apiFilter = useSelector(selectAPIFilterParams);
    const urlParams = new URLSearchParams(window.location.search);
    const initialPageSize = parseInt(
      urlParams.get('pageSize') || String(DEFAULT_PAGE_SIZE)
    );
    const initialPageNumber = parseInt(urlParams.get('pageNumber') || '1');

    const [pageSize, setPageSize] = useState(initialPageSize);
    const [pageNumber, setPageNumber] = useState(initialPageNumber);

    /* Replacing plate filter with carId, for claims filter, since damage obj has carId but not plate */
    const apiFilterKeys = apiFilter ? Object.keys(apiFilter) : [];
    const modifiedApiFilter = apiFilter;
    if (viewMode === ViewMode.CLAIM && apiFilterKeys?.includes('car')) {
      modifiedApiFilter['car'] =
        cars?.find((car) => car.plate == apiFilter?.['car']?.[0])?.id ||
        apiFilter['car'];
    }
    /* Replacing tariff name with id */
    if (viewMode === ViewMode.USERS && apiFilterKeys?.includes('tariffs')) {
      modifiedApiFilter['tariffs'] =
        tariffs?.find((item) => item.name == apiFilter?.['tariffs']?.[0])?.id ||
        apiFilter['tariffs'];
    }
    /* ------ */

    const fetchData = async (pageNumber, pageSize) => {
      dispatch(
        fetchDataAction(
          pageNumber * pageSize,
          pageSize,
          () => {},
          modifiedApiFilter
        )
      );
    };

    const onPageChanged = (newPageNumber, newPageSize) => {
      setPageSize(newPageSize);
      setPageNumber(newPageNumber);
      fetchData(newPageNumber - 1, newPageSize);

      setQueryPagination(newPageSize, newPageNumber);
    };

    useEffect(() => {
      const resetPageNumber = modifiedApiFilter ? 1 : pageNumber;
      fetchData(resetPageNumber - 1, pageSize);
      setQueryPagination(pageSize, resetPageNumber);
    }, [JSON.stringify(apiFilter)]);

    return (
      <WrappedComponent
        {...props}
        pageNumber={pageNumber}
        onPageChanged={onPageChanged}
      />
    );
  };

  Wrapper.displayName = `withPaginatedQueryFetch(${getDisplayName(
    WrappedComponent
  )})`;

  return Wrapper;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPaginatedQueryFetch;
