import React from 'react';
import ReactDOM from 'react-dom/client';
import packageJson from '../package.json';
import './index.less';
import { Provider } from 'react-redux';
// @ts-ignore
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { store, persistor } from './redux/config';
import AppRoute from './routes/AppRoute';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingIcon from './components/shared/LoadingIcon';
import { ConfigProvider } from 'antd';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: packageJson.version,
  environment:
    process.env.REACT_APP_STAGE === 'production' ? 'production' : 'staging',
});

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<LoadingIcon />} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#0077be',
              fontSize: 15,
            },
            components: {
              Button: {},
              Table: {
                rowHoverBg: 'rgba(216, 245, 35, 0.1)',
                rowSelectedBg: 'unset',
                rowSelectedHoverBg: 'unset',
              },
              Select: {
                optionSelectedColor: '#000',
              },
            },
          }}
        >
          <AppRoute />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
