import React from 'react';
import { useSelector } from 'react-redux';
import styles from './ReceiptInfo.styles';
import ListItem from '../../components/shared/ListItem';
import { selectSelectedReceipt } from '../../redux/store/userData/receipts';
import { renderText } from '../shared/DrawerLayout/renderText';
import { useIntl } from 'react-intl';
import { renderDate } from '../shared/TableLayout';
import InvoiceItem from '../TripDrawer/InvoiceItem';
import LinkToTrip from '../shared/LinkToTrip';
import LinkToUser from '../shared/LinkToUser';
import { selectTripLoading } from '../../redux/store/userData/trips/selectors';
import ListEditItem from '../shared/ListEditItem';
import LoadingIcon from '../shared/LoadingIcon';
import { selectUserLoading } from '../../redux/store/userData/users';
import { formatPrice } from '../../utils';

const ReceiptInfo: React.FC = () => {
  const selectedReceipt = useSelector(selectSelectedReceipt);
  const isTripLoading = useSelector(selectTripLoading);
  const isUserLoading = useSelector(selectUserLoading);

  const intl = useIntl();

  return (
    <>
      <div className="receipt-info">
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.receiptno',
          })}
          value={
            selectedReceipt?.receiptNo ? (
              <InvoiceItem
                invoiceName={selectedReceipt?.receiptNo}
                invoiceUri={selectedReceipt?.receiptUrl}
                showDownloadButton
                showCopyInvoiceUrl
              />
            ) : (
              '-'
            )
          }
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.receiptmerchantreference',
          })}
          value={renderText(selectedReceipt?.receiptMerchantReference!)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.receipttype',
          })}
          value={renderText(
            intl.formatMessage({
              id: `fleetadmin.receipts.type.${selectedReceipt?.receiptType!}`,
            })
          )}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.receiptdate',
          })}
          value={renderDate(selectedReceipt?.receiptDate!)}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.usercode',
          })}
          leftItem={
            <LinkToUser
              userCode={selectedReceipt?.userCode!}
              userId={selectedReceipt?.userId!}
            />
          }
          rightItem={isUserLoading ? <LoadingIcon /> : null}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.tripcode',
          })}
          leftItem={
            <LinkToTrip tripCode={selectedReceipt?.tripCode!} service={null} />
          }
        />

        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.tripreference',
          })}
          value={renderText(selectedReceipt?.tripReference!)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.grossamount',
          })}
          value={formatPrice(selectedReceipt?.grossAmount!)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.currency',
          })}
          value={renderText(selectedReceipt?.currency!)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.collection',
          })}
          value={renderText(selectedReceipt?.collection!)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'fleetadmin.receipts.table.createdby',
          })}
          value={renderText(selectedReceipt?.createdBy!)}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ReceiptInfo;
