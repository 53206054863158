import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LinkToReceipt.styles';
import { selectAuth } from '../../redux/store/userData/user';
import {
  fetchReceiptById,
  selectReceiptLoading,
  selectSelectedReceipt,
} from '../../redux/store/userData/receipts';
import moment from 'moment';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingIcon from './LoadingIcon';

interface Props {
  receiptNo: string;
  date: string;
}

const LinkToReceipt: React.FC<Props> = ({ receiptNo, date }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const selectedReceipt = useSelector(selectSelectedReceipt);
  const isPaymentManager = user?.permissions?.paymentManager;
  const isReceiptLoading = useSelector(selectReceiptLoading);
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  const onClickHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (
      isPaymentManager &&
      !isReceiptLoading &&
      !selectedReceipt &&
      openedDrawersCount < 2
    ) {
      dispatch(fetchReceiptById(receiptNo));
    }
  };

  return (
    <>
      {openedDrawersCount < 2 ? (
        <span
          className={isPaymentManager ? 'link' : 'value-wrapper'}
          onClick={onClickHandler}
        >
          {receiptNo}{' '}
          <span style={{ color: 'black' }}>
            vom {moment(date).format('DD.MM.YYYY')}
          </span>
          {isReceiptLoading && <LoadingIcon />}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=RECEIPTS&type=TABLE&pageSize=50&pageNumber=1&receiptId=${receiptNo}`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link">{receiptNo}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToReceipt;
