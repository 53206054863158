import css from 'styled-jsx/css';

const style = css`
  .range-switcher {
    height: 30px;
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 25px;
    background: transparent;
  }
  .item {
    color: black;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    background: #fff;
    padding: 0 16px;
    box-shadow: var(--ds-shadow-overlay, 0 4px 4px rgba(0, 0, 0, 0.25));
  }
  .item:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .item.active {
    background: #2b2e42;
    color: #fff;
  }
  .item.today {
    margin-right: 16px;
  }
  @media only screen and (min-width: 768px) {
  }
`;

export default style;
