import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getAreaNameById from '../../utils/getAreaNameById';
import getFleetNameById from '../../utils/getFleetNameById';
import VINAndPlate from '../../components/shared/VINAndPlate';
import ListItem from '../../components/shared/ListItem';
import ServiceImageGallery from './ServiceImageGallery';
import ServiceList from './ServiceList';
import LinkToArea from '../../components/shared/LinkToArea';
import styles from './CareInfo.styles';
import { getAreaById } from '../../utils';
import { selectAreas } from '../../redux/store/appData/areas';
import { selectSelectedCare } from '../../redux/store/userData/cares';
import { selectFleets } from '../../redux/store/appData/fleets';
import { selectAuth } from '../../redux/store/userData/user/selectors';
import { openModal } from '../../redux/store/ui/common/actions';

const CareInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const areas = useSelector(selectAreas);
  const fleets = useSelector(selectFleets);
  const selectedCare = useSelector(selectSelectedCare);

  const areaName = getAreaNameById(areas, selectedCare?.areaId);
  const fleetName = getFleetNameById(fleets, selectedCare?.fleetId);
  const area = getAreaById(areas, selectedCare?.areaId!);
  const isTripInProgress = ['OPEN', 'IN_PROGRESS'].includes(
    selectedCare?.status!
  );

  const onFinishTripClicked = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(openModal('CareCancelModal'));
  };

  return (
    <>
      <div className="care-info">
        <VINAndPlate
          chassisNumber={selectedCare?.chassisNumber}
          plate={selectedCare?.plate}
          carId={selectedCare?.carId}
        />
        {area && (
          <ListItem
            label={'Area'}
            value={<LinkToArea area={area} text={areaName} />}
          />
        )}
        {selectedCare?.orderExtras?.phone && (
          <ListItem
            label={'Phone number'}
            value={selectedCare?.orderExtras.phone}
          />
        )}
        {selectedCare?.orderExtras?.frequency && (
          <ListItem
            label={'Frequency'}
            value={selectedCare?.orderExtras.frequency}
          />
        )}
        {selectedCare?.orderExtras?.referral && (
          <ListItem
            label={'Referral'}
            value={selectedCare?.orderExtras.referral}
          />
        )}
        {selectedCare?.pickupLocation && (
          <ListItem
            label={'Location'}
            value={selectedCare?.pickupLocation}
            locationType
          />
        )}
        <ListItem label={'Fleet'} value={fleetName} />
        {selectedCare?.userReferralCode && (
          <ListItem
            label={'User code'}
            value={selectedCare?.userReferralCode}
          />
        )}
        <ListItem
          label={'Services reported'}
          value={<ServiceList serviceTypes={selectedCare?.serviceTypes} />}
        />
        {selectedCare?.note && (
          <ListItem label={'Comments'} value={selectedCare?.note} />
        )}
        {user?.permissions?.tripsManager && isTripInProgress && (
          <div
            className="care-info-finish-button"
            onClick={onFinishTripClicked}
          >
            Finish trip
          </div>
        )}
        <ServiceImageGallery images={selectedCare?.images} />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default CareInfo;
