import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css`
  .user-info {
    margin: 0;
    padding-bottom: 100px;
    position: relative;
  }
  .edit-button {
    cursor: pointer;
  }
  .add-button {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .admin-info-label {
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .admin-delete-button {
    margin: 70px 24px 60px;
    color: red;
    cursor: pointer;
  }
  .subtitle-permission {
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding-top: 8px;
    padding-bottom: 4px;
    margin-bottom: 0;
  }
  .permission-section {
    display: flex;
    flex-direction: column;
  }
  .permission-wrapper {
    display: inline-block;
    padding: 0;
    margin: 6px 0 6px 6px;
  }
  .permission-label {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 1px;
  }
  .permission-icon {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
  .admin-section-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px;
    margin-top: 40px;
  }
  .admin-selector {
    padding: 8px 24px 16px 24px;
  }
  .button-container {
    padding: 50px 16px 30px 16px;
    position: sticky;
    bottom: 0;
    background-image: url('/images/drawer/cta-background.svg');
  }
  .user-info :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .user-info :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
  .sub-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .separator-slash {
    margin: 0 3px 2px 3px;
  }
  .action {
    color: #1890ff;
    cursor: pointer;
  }
`;

export default style;
