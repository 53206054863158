import getKeysWithTypeOf from '../components/Filter/helpers/getKeysWithTypeOf';
import { isBefore, isAfter, addDays } from 'date-fns';
import { getDeepProperty } from './getDeepProperty';

// Function to check if a key is of a specific type
const isKeyType = (type) => {
  const keys = getKeysWithTypeOf(type);
  return (key) => keys.includes(key);
};

// Function to handle filtering of array values
const filterArrayValues = (value, wishList, key, entity) => {
  if (key === 'blockReasons') {
    return (
      value.some((v) => wishList.includes(v)) && entity.status === 'BLOCKED'
    );
  }
  return value.some((v) => wishList.includes(v));
};

// Function to handle filtering by date
const filterByDate = (value, wishList) => {
  const theDateTime = new Date(value);
  return wishList.every((wishItem) => {
    const split = wishItem.split('_');
    if (wishItem.includes('after')) {
      // Subtract 1 day from the 'after' date for inclusivity
      const adjustedAfterDate = new Date(addDays(new Date(split[1]), -1));
      return isAfter(theDateTime, adjustedAfterDate);
    } else if (wishItem.includes('before')) {
      // Add 1 day to the 'before' date for inclusivity
      const adjustedBeforeDate = new Date(addDays(new Date(split[1]), 1));
      return isBefore(theDateTime, adjustedBeforeDate);
    } else {
      // For a range, subtract 1 day from start and add 1 day to end for inclusivity
      const adjustedStartDate = new Date(addDays(new Date(split[0]), -1));
      const adjustedEndDate = new Date(addDays(new Date(split[1]), 1));
      return (
        isAfter(theDateTime, adjustedStartDate) &&
        isBefore(theDateTime, adjustedEndDate)
      );
    }
  });
};

// Function to handle filtering by number
const filterByNumber = (value, wishList) => {
  const split = wishList[0].split('=');
  return split[0] === '>'
    ? value >= parseInt(split[1])
    : value <= parseInt(split[1]);
};

// Function to handle filtering by boolean
const filterByBoolean = (value, wishList) => {
  return (
    (value === true && wishList.includes('true')) ||
    (value === false && wishList.includes('false')) ||
    (value == null && wishList.includes('unknown'))
  );
};

const filterByInt = (value, wishList) => {
  return wishList?.includes(value?.toString());
};

export const filterBy = (key, wishList) => (entities) => {
  if (!Array.isArray(wishList) || wishList.length === 0) {
    return entities;
  }

  return entities?.filter((entity) => {
    let value = getDeepProperty(entity, key);

    if (isKeyType('text')(key) || isKeyType('selection')(key)) {
      return Array.isArray(value)
        ? wishList.some((wishItem) =>
            filterArrayValues(value, [wishItem], key, entity)
          )
        : wishList.includes(value);
    }

    if (isKeyType('date')(key)) {
      return wishList.some((wishItem) => filterByDate(value, [wishItem]));
    }

    if (isKeyType('number')(key)) {
      return wishList.some((wishItem) => filterByNumber(value, [wishItem]));
    }

    if (isKeyType('boolean')(key)) {
      return wishList.some((wishItem) => filterByBoolean(value, [wishItem]));
    }

    if (isKeyType('int')(key)) {
      return wishList.some((wishItem) => filterByInt(value, [wishItem]));
    }
  });
};
