import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import CareDrawerBody from './CareDrawerBody';
import CarEditDrawer from '../../components/CarEditDrawer';
import AreaEditDrawer from '../../components/AreaEditDrawer';
import ClaimDrawer from '../../components/ClaimDrawer';
import { ViewMode } from '../../hooks/useViewMode';
import { selectSelectedCare } from '../../redux/store/userData/cares';
import { closeCareDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectViewMode } from '../../redux/store/ui/common/selectors';

const CareDrawer: React.FC = () => {
  const viewMode = useSelector(selectViewMode);
  const selectedCare = useSelector(selectSelectedCare);
  const dispatch = useDispatch();
  const visible = selectedCare != null;

  const handleOnClose = () => {
    dispatch(closeCareDrawerAndSetQueries());
  };

  return (
    selectedCare && (
      <DrawerLayout
        isCareDrawer
        visible={visible}
        title={selectedCare.id}
        body={<CareDrawerBody />}
        nestedDrawers={
          <>
            {viewMode !== ViewMode.VEHICLE && <CarEditDrawer />}
            {viewMode !== ViewMode.AREA && <AreaEditDrawer />}
            {viewMode !== ViewMode.CLAIM && <ClaimDrawer />}
          </>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default CareDrawer;
