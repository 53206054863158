import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import styles from './CarEditorProfileSelector.styles';
import { selectProfiles } from '../../redux/store/userData/profiles';
import { zIndex } from '../../theme/theme';

const { Option } = Select;

type Props = {
  style?: any;
  className?: string;
  defaultValue?: string;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  onSelect?: (value: string) => void;
};

const ProfileSelector: React.FC<Props> = ({
  style,
  className,
  defaultValue,
  value,
  disabled,
  loading,
  placeholder,
  onSelect,
}) => {
  const profiles = useSelector(selectProfiles) as any;

  return (
    <div style={style} className={className}>
      <Select
        defaultValue={defaultValue}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder}
        onChange={onSelect}
      >
        <Option value={''}>
          <span>Not assigned</span>
        </Option>
        {[...profiles]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((profile) => (
            <Option key={`profile-option-${profile.id}`} value={profile.id}>
              {profile.name}
            </Option>
          ))}
      </Select>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProfileSelector;
