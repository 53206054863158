import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './TripStatus.styles';

type TripStatusProps = {
  status: string;
  style?: any;
};

const TripStatus: React.FC<TripStatusProps> = ({ status, style }) => {
  return status ? (
    <p style={style} className={status?.toLowerCase()}>
      <FormattedMessage id={`fleetadmin.tripStatus.${status?.toLowerCase()}`} />
      <style jsx>{styles}</style>
    </p>
  ) : null;
};

export default TripStatus;
