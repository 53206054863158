const checkIsNew = (timestamp: string | null): boolean => {
  if (timestamp == null) {
    return false;
  }
  const now = new Date();
  const equippedAt = new Date(timestamp);
  return now.getTime() - equippedAt.getTime() < 7 * 24 * 60 * 60 * 1000;
};

export default checkIsNew;
