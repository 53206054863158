import { createAction, createErrorAction } from '../../../helpers';
import {
  ErrorResponse,
  FleetRentalData,
  FleetServiceTripData,
} from '../../../../@types';
import { CaresTypes } from '../../../../@types/enums';

export const caresRequest = () => createAction(CaresTypes.CARES_REQUEST);

export const caresSuccess = (payload: FleetServiceTripData[]) =>
  createAction(CaresTypes.CARES_SUCCESS, payload);

export const caresFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CaresTypes.CARES_FAILURE, error);

export const setDisplayCares = (payload: FleetServiceTripData[]) =>
  createAction(CaresTypes.SET_DISPLAY_CARES, payload);

export const selectCare = (payload: FleetServiceTripData | null) =>
  createAction(CaresTypes.SELECT_CARE, payload);

export const finishServiceTripRequest = () =>
  createAction(CaresTypes.FINISH_SERVICE_TRIP_REQUEST);

export const finishServiceTripSuccess = (payload: FleetRentalData) =>
  createAction(CaresTypes.FINISH_SERVICE_TRIP_SUCCESS, payload);

export const finishServiceTripFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CaresTypes.FINISH_SERVICE_TRIP_FAILURE, error);

export const finishServiceTripViolations = (payload: string[]) =>
  createAction(CaresTypes.FINISH_SERVICE_TRIP_VIOLATIONS, payload);
