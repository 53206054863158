import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import AreaEditDrawerBody from './AreaEditDrawerBody';
import { selectSelectedArea } from '../../redux/store/appData/areas';
import { closeAreaDrawerAndSetQueries } from '../../redux/store/ui/common';

const AreaEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedArea = useSelector(selectSelectedArea);
  const visible = selectedArea != null;

  const handleOnClose = () => {
    dispatch(closeAreaDrawerAndSetQueries());
  };

  return (
    selectedArea && (
      <DrawerLayout
        visible={visible}
        title={selectedArea.id}
        body={<AreaEditDrawerBody />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default AreaEditDrawer;
