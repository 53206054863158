import { Spin } from 'antd';
import * as React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (font = 24, color = '#0077be') => (
  <LoadingOutlined style={{ fontSize: font, color: color }} spin />
);

const LoadingIcon = (props: any) => (
  <Spin indicator={antIcon(props.font, props.color)} {...props} />
);

export default LoadingIcon;
