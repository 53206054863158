import { Reducer } from '../../../../@types';
import { UserTypes } from '../../../../@types/enums';
import { initialState, UserState } from './initialState';

export const authReducer: Reducer<UserState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        ...action.payload?.permissions,
        loading: false,
      };
    case UserTypes.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
