import css from 'styled-jsx/css';

const style = css`
  :global(.gstc) {
    overflow: visible;
    bottom: 20px;
    position: fixed;
  }
  :global(._loading_overlay_wrapper) {
    z-index: 900;
    position: inherit;
  }
  :global(._loading_overlay_overlay) {
    z-index: 900;
    top: unset;
    bottom: 0;
    left: 16px;
    height: inherit;
    opacity: 0.2;
  }
  :global(.gstc *) {
    overflow: visible;
  }
  :global(.gstc__list-column-row-content) {
    padding: 2% 20% !important;
    color: rgb(0, 119, 190);
  }
  :global(.gstc__chart-timeline-items-row-item) {
    border-radius: 4px;
  }
  :global(.gstc__list-column-row) {
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
  :global(.gstc__list-column-header-resizer) {
    padding: 0 20% !important;
  }
  :global(.gstc__chart-timeline-grid-row-cell) {
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
  :global(.gstc__chart-timeline-items-row-item-label) {
    margin: 0 !important;
    mask-image: unset !important;
    -webkit-mask-image: unset !important;
  }
  :global(.gstc__chart-timeline-grid-row-cell.current) {
    background-color: #fdfdfd;
  }
  :global(.gstc__chart-timeline-grid-row-cell--weekend) {
    // background-color: #9498b0;
  }
  :global(.gstc__chart-time-bookmarks) {
    z-index: 1;
  }
  :global(.gstc__scroll-bar--horizontal) {
    background-color: transparent;
  }
`;

export default style;
