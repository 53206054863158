import React, { MouseEvent } from 'react';
import { Modal } from 'antd';
import styles from './ClaimAllAroundGallery.styles';
import formatDateTime from '../../utils/formatDateTime';
import { useDispatch, useSelector } from 'react-redux';
import getTheDayBefore from '../../utils/datetime/getTheDayBefore';
import { selectSelectedClaim } from '../../redux/store/userData/claims';
import {
  selectClaimLastFrom,
  fetchVehicleEventsIfNeeded,
} from '../../redux/store/userData/vehicleEvents';
import { Image } from 'antd';
import { ImageStyles } from '../TimeLine/ImageAdded.styles';

type AllAroundModalProps = {
  visible?: boolean;
  vin: any;
  galleryData?: Array<{
    type: string;
    tripId: string;
    addedAt: string;
    images: Array<string>;
  }>;
  onClose: () => void;
};

const ClaimAllAroundGallery = ({
  vin,
  galleryData,
  visible,
  onClose,
}: AllAroundModalProps) => {
  const dispatch = useDispatch();
  const previousFrom = useSelector(selectClaimLastFrom) as any;
  const theDayBeforePreviousFrom = getTheDayBefore(
    previousFrom!
  ).toISOString() as any;
  const selectedClaim = useSelector(selectSelectedClaim);
  const handleCancel = () => {
    onClose();
  };
  const onClickLoadMore = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(
      fetchVehicleEventsIfNeeded(
        selectedClaim?.carId,
        theDayBeforePreviousFrom,
        previousFrom,
        ['damageClaim'],
        // @ts-ignore
        'CLAIM',
        true
      )
    );
  };

  return galleryData != [] ? (
    <>
      <div>
        <Modal
          wrapClassName="allAround-modal-wrapper"
          title={`"All-around check" record / ${vin}`}
          visible={visible}
          width={500}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="trip-list-wrapper">
            {galleryData?.map((data, i) => (
              <div className="trip-wrapper" key={`${data.tripId}_${i}`}>
                <div
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="trip-title">
                    {data.tripId}
                    <div>
                      <p className="trip-desc">
                        {data.type.includes('Start') ? '/Start' : '/End'}
                      </p>
                    </div>
                  </p>
                  <p className="trip-date">{formatDateTime(data.addedAt)}</p>
                </div>
                <Image.PreviewGroup>
                  <div style={{ display: 'flex' }}>
                    {/*// @ts-ignore*/}
                    {galleryData[i].images.map((image) => (
                      <Image
                        key={image}
                        width={50}
                        height={50}
                        src={image}
                        wrapperStyle={{
                          marginLeft: '4px',
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </div>
                </Image.PreviewGroup>
              </div>
            ))}
            <p className="load-more" onClick={onClickLoadMore}>
              Load more ...
            </p>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
      <style jsx>{ImageStyles}</style>
    </>
  ) : null;
};

export default ClaimAllAroundGallery;
