import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './UserIDSettingsModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';

const UserIDSettingsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const [remainingIDSubmissionAttempts, setRemainingIDSubmissionAttempts] =
    useState(selectedUser?.remainingIDSubmissionAttempts);

  const visible = useSelector(getIsVisibleModal('IDSettingsModal'));

  const handleCancel = () => {
    dispatch(closeModal('IDSettingsModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (selectedUser) {
      dispatch(
        updateUser(selectedUser.id, {
          remainingIDSubmissionAttempts,
        })
      );
    }
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setRemainingIDSubmissionAttempts(
        selectedUser?.remainingIDSubmissionAttempts || ''
      );
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-ID-settings-contents">
          <div className="user-ID-settings-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.document`,
            })}
          </div>
          <div className="user-ID-settings-input-container">
            <Input
              value={remainingIDSubmissionAttempts}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.id.submissions`,
              })}
              onChange={(e) => setRemainingIDSubmissionAttempts(e.target.value)}
              style={{ marginRight: 12 }}
            />
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserIDSettingsModal;
