import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .link {
    color: ${colors.blue};
    cursor: pointer;
  }
  .receipt-actions {
    display: flex;
  }
`;

export default style;
