import css from 'styled-jsx/css';
import { colors } from '../../../theme/theme';

const style = css`
  span {
    color: ${colors.interactiveLink};
    font-weight: 500;
    cursor: pointer;
  }
`;

export default style;
