import React from 'react';
import { UserCode } from './TimelineItemBody';
import { VehicleEventData } from '../../@types';
import styles from './TripFrameTop.styles';
import { useSelector } from 'react-redux';
import LinkToTrip from '../../components/shared/LinkToTrip';
import { selectCares } from '../../redux/store/userData/cares';
import {
  selectTripLoading,
  selectTrips,
} from '../../redux/store/userData/trips';
import LoadingIcon from '../shared/LoadingIcon';

type TripFrameTopProps = {
  vehicleEvent: VehicleEventData;
};

const TripFrameTop: React.FC<TripFrameTopProps> = ({ vehicleEvent }) => {
  const trips = useSelector(selectTrips);
  const services = useSelector(selectCares);
  const isTripLoading = useSelector(selectTripLoading);
  const { userReferralCode, tripId, merchantRef, presentableTripId } =
    vehicleEvent;
  const trip = trips?.find((trip) => tripId === trip.id);
  const service = services?.find((service) => tripId === service.id);
  const tripContractId = merchantRef
    ? ` (${merchantRef})`
    : !!trip && trip?.merchantRef
    ? ` (${trip?.merchantRef})`
    : !!service && service?.merchantRef
    ? ` (${trip?.merchantRef})`
    : '';

  return (
    <>
      <div className="wrapper">
        <p className="title">
          Trip <LinkToTrip tripCode={presentableTripId} service={service} />
          {tripContractId}
          {isTripLoading && <LoadingIcon font={14} />}
        </p>
        <UserCode userCode={userReferralCode} />
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default TripFrameTop;
