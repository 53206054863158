import css from 'styled-jsx/css';

const style = css`
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #c7c3c3;
  }
  .card-wrapper {
    width: 444px;
    max-width: calc(100% - 32px);
    min-height: 444px;
    background: white;
    box-shadow: 0 12px 44px 0 rgba(0, 0, 0, 0.5);
  }
  :global(.ant-card.card) {
    width: 320px;
    max-width: 100%;
    min-height: 320px;
    margin: 0 auto;
    border: 0;
  }
  .logo-wrapper {
    padding: 16px 0 32px;
    width: 100%;
    text-align: center;
  }
  .logo-image {
    width: inherit;
  }
  .title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
  }
`;

export default style;
