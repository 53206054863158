import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css.global`
  .allAround-modal-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .allAround-modal-wrapper .ant-modal-header {
    padding: 15px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  :global(.ant-modal) {
    top: 0;
    height: 95vh;
  }
  :global(.ant-modal-content) {
    top: 0;
    height: 95vh;
  }
  .allAround-modal-wrapper .ant-modal-title {
    font-size: 16px;
  }
  .allAround-modal-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
    display: flex;
  }
  .allAround-modal-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .trip-list-wrapper {
    width: 270px;
    height: 587px;
    overflow-y: scroll;
  }
  .trip-wrapper {
    display: flex;
    flex-direction: column;
    width: 260px;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #9f9f9f;
    margin: 0px 10px 0px 10px;
    cursor: pointer;
    padding: 10px;
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .trip-title {
    display: flex;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #0077be;
    margin: 0;
    margin-bottom: 0px;
    padding-left: 5px;
  }
  .trip-date {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #9f9f9f;
    margin: 0;
    margin-bottom: 0px;
    padding-right: 5px;
  }
  .load-more {
    width: 121px;
    height: 22px;
    font-family: AvenirNext;
    font-size: 16px;
    line-height: 19px;
    color: #0077be;
    padding-left: 10px;
    padding-top: 20px;
    cursor: pointer;
  }
  .trip-desc {
    font-family: Avenir Next;
    font-style: normal;
    font-size: 12px;
    color: black;
    margin: 0;
  }
  @media only screen and (min-width: 768px) {
    .allAround-modal-wrapper {
      z-index: ${zIndex.allAroundModal};
    }
    .allAround-modal-wrapper .ant-modal-header {
      padding: 15px;
      border-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    :global(.ant-modal) {
      top: 40px;
      height: 80vh;
    }
    :global(.ant-modal-content) {
      top: 0;
      height: 80vh;
    }
    .allAround-modal-wrapper .ant-modal-title {
      font-size: 16px;
    }
    .allAround-modal-wrapper .ant-modal-body {
      padding: 15px;
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-contents: center;
    }
    .allAround-modal-wrapper .ant-modal-close {
      width: 50px;
      height: 50px;
      color: black;
    }
    .trip-list-wrapper {
      width: 100%;
      height: 65vh;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .trip-panel {
      width: 350px;
    }
    .trip-wrapper {
      display: flex;
      flex-direction: column;
      width: 400px;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #9f9f9f;
      margin: 0px 10px 0px 10px;
      cursor: pointer;
      padding: 10px;
      border: solid 1px lightgray;
      border-radius: 4px;
      margin-bottom: 6px;
    }
    .trip-title {
      display: flex;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #0077be;
      margin: 0;
      margin-bottom: 0px;
      padding-left: 5px;
    }
    .trip-date {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #9f9f9f;
      margin: 0;
      margin-bottom: 0px;
      padding-right: 5px;
    }
    .load-more {
      width: 121px;
      height: 22px;
      font-family: AvenirNext;
      font-size: 16px;
      line-height: 19px;
      color: #0077be;
      padding-left: 10px;
      padding-top: 20px;
      cursor: pointer;
    }
    .trip-desc {
      font-family: Avenir Next;
      font-style: normal;
      font-size: 12px;
      color: black;
      margin: 0;
    }
  }
`;

export default style;
