import css from 'styled-jsx/css';

const style = css`
  .badge {
    font-size: 12px;
    border-radius: 11px;
    color: #fff;
    background: #000;
    padding: 0 5px;
    margin-left: 3px;
  }
`;

export default style;
