import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Input, Select } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './UserContactModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { useIntl } from 'react-intl';
import { selectAuth } from '../../../redux/store/userData/user';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { zIndex } from '../../../theme/theme';

const { Option } = Select;

const DefaultSupportedCountries = [
  { code: 'CH', name: 'Switzerland' },
  { code: 'MC', name: 'Monaco' },
  { code: 'NO', name: 'Norway' },
  { code: 'BE', name: 'Belgium' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'AT', name: 'Austria' },
  { code: 'FI', name: 'Finland' },
];

interface UserInput {
  phoneNumber?: string;
  email?: string;
  address?: {
    country?: string;
    city?: string;
    streetName?: string;
    streetNumber?: string;
    postalCode?: string;
  };
  addressApproved?: boolean;
  emailVerified?: boolean;
}

const UserContactModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const visibleModal = useSelector(getIsVisibleModal('UserContactModal'));
  const address = selectedUser?.address;
  const user = useSelector(selectAuth);

  const selectedBrand = user.brands.find(
    (it) => it.id === selectedUser?.brandId
  );
  const supportedCountries =
    selectedBrand?.allowedUserAddressCountries?.map((item) => ({
      code: item.toUpperCase(),
      name: intl.formatMessage({
        id: item.toUpperCase(),
      }),
    })) || DefaultSupportedCountries;
  const supportedPhoneCountries =
    selectedBrand?.allowedPhoneNumberCountries?.map((country) =>
      country.toLowerCase()
    );

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneApproved, setPhoneApproved] = useState(
    selectedUser?.verified?.phoneNumber || false
  );
  const [email, setEmail] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('DE');
  const [addressApproved, setAddressApproved] = useState(
    selectedUser?.addressVerified || false
  );

  const [emailApproved, setEmailApproved] = useState(
    selectedUser?.emailVerified || false
  );

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let userInput: UserInput = {};
    if (phoneNumber.value != selectedUser?.phoneNumber) {
      userInput.phoneNumber = phoneNumber.value;
    }
    if (email != selectedUser?.email) {
      userInput.email = email;
    }
    if (city != selectedUser?.address?.city) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.city = city;
    }
    if (streetName != selectedUser?.address?.streetName) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.streetName = streetName;
    }
    if (streetNumber != selectedUser?.address?.streetNumber) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.streetNumber = streetNumber;
    }
    if (postalCode != selectedUser?.address?.postalCode) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.postalCode = postalCode;
    }
    if (country != selectedUser?.address?.country) {
      if (!userInput.address) {
        userInput.address = {};
      }
      userInput.address.country = country;
    }

    if (emailApproved && !selectedUser?.emailVerified) {
      userInput.emailVerified = emailApproved;
    }

    if (addressApproved && selectedUser?.addressVerified == false) {
      userInput.addressApproved = addressApproved;
    }

    if (phoneApproved && selectedUser?.verified?.phoneNumber == false) {
      // @ts-ignore
      userInput.phoneNumberVerified = phoneApproved;
    }

    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser.id, userInput));
      dispatch(closeModal('UserContactModal'));
    }
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('UserContactModal'));
  };

  const onPhoneNumberChanged = (value: string, data: any) => {
    let newVal = value.startsWith(data?.dialCode)
      ? value.slice(data?.dialCode?.length)
      : value;
    const zeroRegex = new RegExp(`^[0]*`, 'gi');
    newVal = newVal.replace(zeroRegex, '');
    const dialCodeRegex = new RegExp(`^${data?.dialCode}`, 'gi');
    newVal = newVal.replace(dialCodeRegex, '');
    newVal = `+${data?.dialCode}${newVal}`;

    setPhoneNumber({
      dialCode: data?.dialCode,
      value: newVal,
      error: '',
    });
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onClickAddressApproved = (e: any) => {
    setAddressApproved(e.target.checked);
  };
  const onClickEmailApproved = (e: any) => {
    setEmailApproved(e.target.checked);
  };

  const onClickPhoneApproved = (e: any) => {
    setPhoneApproved(e.target.checked);
  };

  useEffect(() => {
    if (visibleModal) {
      setPhoneNumber({
        dialCode: '',
        value: selectedUser?.phoneNumber,
        error: '',
      });
      setEmail(selectedUser?.email);
      setStreetName(address?.streetName || '');
      setStreetNumber(address?.streetNumber || '');
      setPostalCode(address?.postalCode || '');
      setCity(address?.city || '');
      setCountry(address?.country || 'DE');
    }
  }, [visibleModal]);
  const isAddressValid =
    streetName && streetNumber && city && postalCode && country;

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('UserContactModal'));
        }}
      >
        <div className="user-contact-modal">
          <div className="contact-modal-wrapper">
            <div className="user-contact-modal-inputs">
              <p className="user-contact-title">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.contact`,
                })}
              </p>
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.phone`,
                })}
              </div>
              <PhoneInput
                country={selectedBrand?.defaultCountryCode || 'de'}
                onlyCountries={supportedPhoneCountries}
                value={phoneNumber?.value}
                onChange={onPhoneNumberChanged}
                inputClass="add-phone-input"
                inputProps={{
                  required: true,
                  autoComplete: 'tel',
                }}
              />
              <Checkbox
                disabled={selectedUser?.verified?.phoneNumber}
                style={{ marginBottom: 8, marginTop: 8 }}
                value={phoneApproved}
                onChange={onClickPhoneApproved}
              >
                {intl.formatMessage({
                  id: 'fleetadmin.users.phone.verify',
                })}
              </Checkbox>
            </div>
            <div className="user-contact-modal-inputs">
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.email`,
                })}
              </div>
              <Input
                value={email}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.email`,
                })}
                onChange={onChangeEmail}
                disabled={selectedUser?.emailVerified}
              />
            </div>
            <Checkbox
              disabled={selectedUser?.emailVerified}
              style={{ marginBottom: 8, marginTop: 8 }}
              value={emailApproved}
              onChange={onClickEmailApproved}
            >
              {intl.formatMessage({
                id: 'fleetadmin.users.info.emailVerified',
              })}
            </Checkbox>
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.info.address`,
              })}
            </div>
            <div className="user-contact-modal-row">
              <Input
                value={streetName}
                placeholder="Street name"
                onChange={(e: any) => {
                  setStreetName(e.target.value);
                }}
                disabled={selectedUser?.addressVerified}
              />
              <div className="street-number-input">
                <Input
                  value={streetNumber}
                  placeholder="Number"
                  onChange={(e: any) => {
                    setStreetNumber(e.target.value);
                  }}
                  disabled={selectedUser?.addressVerified}
                />
              </div>
            </div>
            <div className="user-contact-modal-row">
              <div className="postal-code-input">
                <Input
                  value={postalCode}
                  placeholder="Postal code"
                  onChange={(e: any) => {
                    setPostalCode(e.target.value);
                  }}
                  disabled={selectedUser?.addressVerified}
                />
              </div>
              <Input
                value={city}
                placeholder="City"
                onChange={(e: any) => {
                  setCity(e.target.value);
                }}
                disabled={selectedUser?.addressVerified}
              />
            </div>
            <Select
              defaultValue="DE"
              value={country}
              style={{ width: '100%' }}
              placeholder="Country"
              onChange={(code: string) => setCountry(code)}
              dropdownStyle={{ zIndex: zIndex.dropDown }}
              disabled={selectedUser?.addressVerified}
            >
              {supportedCountries.map((country) => (
                <Option
                  key={`area-option-${country.code}`}
                  value={country.code}
                >
                  {country.name}
                </Option>
              ))}
            </Select>
          </div>
          <Checkbox
            disabled={selectedUser?.addressVerified || !isAddressValid}
            style={{ marginBottom: 8, marginTop: 8 }}
            value={addressApproved}
            onChange={onClickAddressApproved}
          >
            {intl.formatMessage({
              id: 'fleetadmin.users.info.addressApproved',
            })}
          </Checkbox>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `fleetadmin.admins.info.update`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `fleetadmin.admins.info.cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserContactModal;
