import { Reducer } from '../../../../@types';
import { ReceiptsTypes } from '../../../../@types/enums';
import { initialState, ReceiptsState } from './initialState';

// @ts-ignore
export const receiptsReducer: Reducer<ReceiptsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case ReceiptsTypes.RECEIPTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ReceiptsTypes.RECEIPTS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case ReceiptsTypes.SET_TOTAL_RECEIPTS:
      return {
        ...state,
        total: action.payload,
      };
    case ReceiptsTypes.RECEIPTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ReceiptsTypes.RECEIPTS_CSV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ReceiptsTypes.RECEIPTS_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ReceiptsTypes.RECEIPTS_CSV_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ReceiptsTypes.SELECT_RECEIPT:
      return {
        ...state,
        selected: action.payload,
      };
    case ReceiptsTypes.SET_DISPLAY_RECEIPT:
      return {
        ...state,
        display: action.payload,
      };
    case ReceiptsTypes.RECEIPT_REQUEST:
      return {
        ...state,
        receiptLoading: true,
      };
    case ReceiptsTypes.RECEIPT_SUCCESS:
      return {
        ...state,
        entities: state?.entities
          ? [
              // @ts-ignore
              ...state.entities.map((entity) =>
                entity?.id === action.payload?.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        display: state.display
          ? [
              // @ts-ignore
              ...state.display.map((entity) =>
                entity?.id === action.payload?.id
                  ? { ...entity, ...action.payload }
                  : entity
              ),
            ]
          : null,
        selected: { ...state?.selected, ...action?.payload },
        receiptLoading: false,
      };
    case ReceiptsTypes.RECEIPT_FAILURE:
      return {
        ...state,
        error: action.error,
        receiptLoading: false,
      };
    default:
      return state;
  }
};
