const timeTableColumns = [
  { label: 'Plate', value: 'plate', disabled: true },
  { label: 'Area', value: 'area', disabled: false },
];

export const defaultSelectedTimeTableColumns = timeTableColumns.map(
  (column) => column.value
);

export default timeTableColumns;
