import React from 'react';
import { useSelector } from 'react-redux';
import getNumOfCarsByAreaId from '../../utils/getNumOfCarsByAreaId';
import TabLayout from '../../components/shared/TabLayout';
import AreaInfo from './AreaInfo';
import AssignedVehicles from './AssignedVehicles';
import Badge from '../../components/shared/Badge';
import styles from './AreaEditDrawerBody.styles';
import { selectSelectedArea } from '../../redux/store/appData/areas';
import { selectCars } from '../../redux/store/userData/cars';

const AreaEditDrawerBody = () => {
  const cars = useSelector(selectCars);
  const selectedArea = useSelector(selectSelectedArea);
  const numOfVehicles = getNumOfCarsByAreaId(cars, selectedArea?.id);

  const tabs = [
    {
      title: <span>Vehicles {<Badge num={numOfVehicles} />}</span>,
      content: <AssignedVehicles />,
    },
    { title: 'Area Info', content: <AreaInfo /> },
  ];

  return (
    <>
      <div className="area-edit-drawer-body">
        <div className="area-name-wrapper">
          <p className="name" title={selectedArea?.name}>
            {selectedArea!?.name!?.length > 20
              ? `${selectedArea!.name!.slice(0, 20)}...`
              : selectedArea?.name}
          </p>
        </div>
        <TabLayout tabs={tabs} keyName="area-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AreaEditDrawerBody;
