import css from 'styled-jsx/css';

const style = css`
  .wrapper {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 64px;
    padding: 20px 24px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
  }
  p {
    margin-bottom: 0;
  }
  .category-name {
    font-size: 18px;
    color: #7f7f7f;
  }
  .separator,
  .page-name {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .left {
    margin-left: 40px;
  }
  @media only screen and (min-width: 768px) {
    .left {
      margin-left: 0px;
    }
  }
`;

export default style;
