import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './DeviceStatus.styles';

type TripStatusProps = {
  status: string;
  style?: any;
};

const DeviceStatus: React.FC<TripStatusProps> = ({ status, style }) => {
  return (
    <p style={style} className={status?.toLowerCase()}>
      <FormattedMessage
        id={`fleetadmin.deviceStatus.${status?.toLowerCase()}`}
      />
      <style jsx>{styles}</style>
    </p>
  );
};

export default DeviceStatus;
