import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './NoImagePlaceholder.styles';

const DAMAGE_ICON = '/images/damage-modal/damage-icon.svg';

const NoImagePlaceholder = () => {
  return (
    <>
      <div className="wrapper">
        <img src={DAMAGE_ICON} alt="no image placeholder" />
        <br />
        <p>
          <FormattedMessage id="fleetadmin.damageModal.noImage" />
        </p>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default NoImagePlaceholder;
