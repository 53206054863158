import React from 'react';
import { ServiceTypesData } from '../../@types';
import { FormattedMessage } from 'react-intl';
import styles from './ServiceList.styles';

interface Props {
  serviceTypes: string[] | null | undefined;
}

const ServiceList: React.FC<Props> = ({ serviceTypes }) => {
  if (serviceTypes?.length === 0) {
    return <span>No service reported</span>;
  }

  return (
    <>
      <ul>
        {serviceTypes?.map((serviceType: any) => (
          <li key={`care-info-service-${serviceType}`}>
            - <FormattedMessage id={`fleetadmin.serviceType.${serviceType}`} />
          </li>
        ))}
      </ul>
      <style jsx>{styles}</style>
    </>
  );
};

export default ServiceList;
