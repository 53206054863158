import {
  BrandData,
  ErrorResponse,
  MerchantOutputData,
} from '../../../../@types';

export type UserData = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  fleets: string[] | null;
  brands: Array<BrandData>;
  locale: string;
  permissions?: PermissionsData;
  merchant?: MerchantOutputData;
};
export interface UserState extends UserData {
  error: ErrorResponse | null;
  loading: boolean;
  token: string;
}

export type PermissionsData = {
  userManager?: boolean;
  tripsManager?: boolean;
  fleetManager?: boolean;
  userGroupsManager?: boolean;
  merchantManager?: boolean;
  claimsManager?: boolean;
  paymentManager?: boolean;
  mechanic?: boolean;
};

export const initialState: UserState = {
  email: null,
  firstName: null,
  lastName: null,
  fleets: null,
  token: '',
  error: null,
  loading: false,
  brands: [],
  locale: 'de',
  merchant: null,
  permissions: null,
};
