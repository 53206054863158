import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .selected {
    background: ${colors.area_selected};
  }
  .unselected {
    background: ${colors.area};
  }
  .label-container {
    display: flex;
    align-items: center;
    padding: 2px 8px 2px 6px;
    border-radius: 6px;
    cursor: pointer;
  }
  .label-text {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
    line-height: 22px;
  }
`;

export default style;
