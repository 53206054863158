import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import styles from './PhoneReportModal.styles';
import { Upload, Button } from 'antd';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';

const totalRowsHeader = [
  'callId',
  'line',
  'date',
  'time',
  'numberTimezone',
  'datetime (UTC)',
  'direction',
  'from',
  'to',
  'anweredAircall',
  'anweredVanad',
  'missed_call_reason',
  'agent',
  'duration (total)',
  'duration (in call)',
  'waitingtimeVanad',
  'talkTimeVanad',
  'holdTimeVanad',
  'afterCallWorkVanad',
  'handlingTimeVanad',
  'via',
  'voicemail',
  'recording',
  'comments',
  'tags',
  'call quality',
  'teams',
];

type PhoneReportModalProps = {
  visible?: boolean;
};

const PhoneReportModal: React.FC<PhoneReportModalProps> = ({ visible }) => {
  const dispatch = useDispatch();
  const _visible = useSelector(getIsVisibleModal('phoneReportsModal'));
  const [key, setKey] = React.useState(new Date().toString());
  const [airCallRow, setAirCallRow] = React.useState(null) as any;
  const [vanadRow, setVanadRow] = React.useState(null) as any;
  const [totalRows, setTotalRows] = React.useState([totalRowsHeader]);

  const handleCancel = () => {
    dispatch(closeModal('phoneReportsModal'));
  };

  useEffect(() => {
    setKey(new Date().toString());
    setAirCallRow(null);
    setVanadRow(null);
    setTotalRows([totalRowsHeader]);
  }, [_visible]);

  const airCallProps: any = {
    name: 'file',
    accept: '.csv',
    defaultFileList: [],
    beforeUpload: (file) => {
      Papa.parse(file, {
        complete: (results) => setAirCallRow(results),
      });
      return false;
    },
  };

  const vanadProps: any = {
    name: 'file',
    accept: '.csv',
    defaultFileList: [],
    beforeUpload: (file) => {
      Papa.parse(file, {
        complete: (results) => setVanadRow(results),
      });
      return false;
    },
  };

  const getIndexOf = (
    item: { [x: string]: any },
    titles: string | any[],
    title: string
  ) => (titles?.indexOf(title) >= 0 ? item[titles?.indexOf(title)] : '');

  const onDownloadPress = (event: any, done: () => void) => {
    let air: any = [];
    let vanad: any = [];
    const airCallTitles = airCallRow?.data?.[0];
    const vanadTitles = vanadRow?.data?.[0];

    if (airCallRow?.data?.length > 0) {
      airCallRow?.data?.forEach((item: { [x: string]: any }, i: number) => {
        if (i > 0) {
          const row = [];
          row.push(''); // callId
          row.push(getIndexOf(item, airCallTitles, 'line')); // line
          row.push(getIndexOf(item, airCallTitles, 'date (TZ offset incl.)')); // date
          row.push(getIndexOf(item, airCallTitles, 'time (TZ offset incl.)')); // time
          row.push(getIndexOf(item, airCallTitles, 'number timezone')); // numberTimezone
          row.push(new Date(getIndexOf(item, airCallTitles, 'datetime (UTC)'))); // datetime (UTC)
          row.push(getIndexOf(item, airCallTitles, 'direction')); // direction
          row.push(getIndexOf(item, airCallTitles, 'from')); // from
          row.push(getIndexOf(item, airCallTitles, 'to')); // to
          row.push(getIndexOf(item, airCallTitles, 'answered')); // anweredAircall
          row.push(getIndexOf(item, airCallTitles, 'anweredVanad')); // anweredVanad
          row.push(getIndexOf(item, airCallTitles, 'missed_call_reason')); // missed_call_reason
          row.push(getIndexOf(item, airCallTitles, 'user')); // agent
          row.push(getIndexOf(item, airCallTitles, 'duration (total)')); // duration (total)
          row.push(getIndexOf(item, airCallTitles, 'duration (in call)')); // duration (in call)
          row.push(0); // waitingtimeVanad
          row.push(0); // talkTimeVanad
          row.push(0); // holdTimeVanad
          row.push(0); // afterCallWorkVanad
          row.push(0); // handlingTimeVanad
          row.push(getIndexOf(item, airCallTitles, 'via')); // via
          row.push(getIndexOf(item, airCallTitles, 'voicemail')); // voicemail
          row.push(getIndexOf(item, airCallTitles, 'recording')); // recording
          row.push(getIndexOf(item, airCallTitles, 'comments')); // comments
          row.push(getIndexOf(item, airCallTitles, 'tags')); // tags
          row.push(getIndexOf(item, airCallTitles, 'call quality')); // call quality
          row.push(getIndexOf(item, airCallTitles, 'teams')); // teams
          air.push(row);
        }
      });
    }
    if (vanadRow?.data?.length > 0) {
      vanadRow?.data?.forEach((item: { [x: string]: any }, i: number) => {
        if (i > 0) {
          const row = [];
          row.push(getIndexOf(item, vanadTitles, 'Call ID')); // callId
          row.push(getIndexOf(item, vanadTitles, 'Queue')); // line
          row.push(getIndexOf(item, vanadTitles, 'Date')); // date
          row.push(getIndexOf(item, vanadTitles, 'Time')); // time
          row.push(''); // numberTimezone
          const dateUTC = new Date(
            `${getIndexOf(item, vanadTitles, 'Date')} ${getIndexOf(
              item,
              vanadTitles,
              'Time'
            )}`
          );
          row.push(dateUTC); // datetime (UTC)
          row.push(''); // direction
          row.push(''); // from
          row.push(''); // to
          row.push(''); // anweredAircall
          row.push(getIndexOf(item, vanadTitles, 'Answered')); // anweredVanad
          row.push(''); // missed_call_reason
          row.push(getIndexOf(item, vanadTitles, 'Agent')); // agent
          row.push(0); // duration (total)
          row.push(0); // duration (in call)
          row.push(getIndexOf(item, vanadTitles, 'Waiting Time')); // waitingtimeVanad
          row.push(getIndexOf(item, vanadTitles, 'Talk Time')); // talkTimeVanad
          row.push(getIndexOf(item, vanadTitles, 'Hold Time')); // holdTimeVanad
          row.push(getIndexOf(item, vanadTitles, 'After Call Work')); // afterCallWorkVanad
          row.push(getIndexOf(item, vanadTitles, 'Handling Time')); // handlingTimeVanad
          row.push(''); // via
          row.push(''); // voicemail
          row.push(getIndexOf(item, vanadTitles, 'Link to voice recording')); // recording
          row.push(''); // comments
          row.push(''); // tags
          row.push(''); // call quality
          row.push(''); // teams
          vanad.push(row);
        }
      });
    }
    const toSort = [...air, ...vanad];
    toSort.sort(function (a, b) {
      // @ts-ignore
      return new Date(a[5]) - new Date(b[5]);
    });
    setTotalRows([...totalRows, ...toSort]);
    done();
  };

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-service-wrapper"
          title={`Phone reports`}
          visible={visible || _visible}
          width={720}
          onCancel={handleCancel}
          footer={null}
        >
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Upload key={`${key}-airCallRow`} {...airCallProps}>
              <Button icon={<UploadOutlined />}>Upload AirCall Report</Button>
            </Upload>
          </div>
          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Upload key={`${key}-vanadRow`} {...vanadProps}>
              <Button icon={<UploadOutlined />}>Upload Vanad Report</Button>
            </Upload>
          </div>
          <Button
            disabled={!airCallRow && !vanadRow}
            type="primary"
            icon={<DownloadOutlined />}
          >
            <CSVLink
              className={
                !airCallRow && !vanadRow
                  ? 'disabled-download-btn'
                  : 'download-btn'
              }
              filename="total-phone-report.csv"
              data={totalRows}
              asyncOnClick
              onClick={onDownloadPress}
            >
              Merge and download report
            </CSVLink>
          </Button>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default PhoneReportModal;
