import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, VehicleCategoryData } from '../../../../@types';
import { CategoriesTypes } from '../../../../@types/enums';

export const categoriesRequest = () =>
  createAction(CategoriesTypes.CATEGORIES_REQUEST);

export const categoriesSuccess = (payload: VehicleCategoryData[]) =>
  createAction(CategoriesTypes.CATEGORIES_SUCCESS, payload);

export const categoriesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CategoriesTypes.CATEGORIES_FAILURE, error);

export const selectCategory = (payload: VehicleCategoryData | null) =>
  createAction(CategoriesTypes.SELECT_CATEGORY, payload);

export const setDisplayCategories = (payload: VehicleCategoryData[]) =>
  createAction(CategoriesTypes.SET_DISPLAY_CATEGORIES, payload);
