import css from 'styled-jsx/css';

const style = css`
  .status-wrapper {
    display: flex;
  }
  .icon-wrapper {
    margin-right: 8px;
  }
`;

export default style;
