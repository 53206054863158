import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import TripInfo from './TripInfo';
import styles from './TripDrawerBody.styles';
import TripStatus from '../../components/shared/TripStatus';
import VINAndPlate from '../../components/shared/VINAndPlate';
import TripTimeline from '../../components/TripDrawer/TripTimeline';
import { selectSelectedTrip } from '../../redux/store/userData/trips/selectors';

const TripDrawerBody: React.FC = () => {
  // @ts-ignore
  const { chassisNumber, plate, code, status, carId } =
    useSelector(selectSelectedTrip);
  const tabs = [
    {
      title: 'Timeline',
      content: (
        <>
          <VINAndPlate
            chassisNumber={chassisNumber}
            plate={plate}
            carId={carId}
          />
          <TripTimeline />
        </>
      ),
    },
    { title: 'Info', content: <TripInfo /> },
  ];

  return (
    <div className="trip-drawer-body">
      <div className="trip-drawer-top">
        <p className="trip-code">{code}</p>
        <TripStatus status={status} style={{ marginLeft: '12px' }} />
      </div>
      <TabLayout tabs={tabs} keyName="trip-drawer" />
      <style jsx>{styles}</style>
    </div>
  );
};

export default TripDrawerBody;
