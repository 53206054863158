import { ErrorResponse, VehicleProfileInfoData } from '../../../../@types';

export interface State {
  entities: VehicleProfileInfoData[] | null;
  display: VehicleProfileInfoData[] | null;
  selected: VehicleProfileInfoData | null;
  error: ErrorResponse | null;
  loading: boolean;
  loadingProfile: boolean;
  updatingProfile: boolean;
  creatingProfile: boolean;
  uploadingImage: boolean;
  deletingImage: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
  loadingProfile: false,
  updatingProfile: false,
  creatingProfile: false,
  uploadingImage: false,
  deletingImage: false,
};
