import { Target } from '../@types';

const targetsForProfile: Target[] = [
  { value: 'name', type: 'text', isAPI: false },
  { value: 'brandId', type: 'selection', isAPI: false },
  { value: 'model', type: 'text', isAPI: false },
  { value: 'brandName', type: 'text', isAPI: false },
];

export default targetsForProfile;
