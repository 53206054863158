import {
  STATUS_MESSAGES,
  requestAPI,
  updateAPI,
  ViolationError,
} from '../../../../utils/request';
import {
  tripsRequest,
  tripsSuccess,
  tripsFailure,
  setTotalTrips,
  setDisplayTrips,
  tripRequest,
  tripFailure,
  tripInvoiceRequest,
  tripInvoiceFailure,
  tripInvoiceSuccess,
  finishTripRequest,
  finishTripSuccess,
  finishTripFailure,
  finishTripViolations,
  bookingsRequest,
  bookingsSuccess,
  bookingsFailure,
  setTotalBookings,
  setBookingsDisplay,
  createBookingRequest,
  createBookingFailure,
  createBookingSuccess,
  startBookingRequest,
  startOfflineBookingFailure,
  startOfflineBookingSuccess,
  selectBooking,
  cancelBookingRequest,
  cancelBookingSuccess,
  cancelBookingFailure,
  startTripRequest,
  startTripSuccess,
  startTripFailure,
  updateBookingRequest,
  updateBookingSuccess,
  updateBookingFailure,
  updateTripRequest,
  updateTripSuccess,
  updateTripFailure,
  bookingRequest,
  bookingFailure,
  selectTrip,
} from './actions';
import message from '../../../../utils/message';
import {
  RentalPagedDataList,
  BookingPagedDataList,
  Dispatch,
  GetState,
  FleetBookingData,
} from '../../../../@types';
import { openModal } from '../../ui/common';
import { fetchCarActiveBookings, getActiveBooking } from '../cars';
import { APIFilterParams } from '../../ui/filters';
import { localize } from '../../../helpers/localize';
import { finishServiceTripFailure, finishServiceTripSuccess } from '../cares';
import { batch } from 'react-redux';
import { fetchVehicleEventsForTrip } from '../vehicleEvents';

const API_PATH_TRIPS = '/fleets/trips';

export const fetchTrip = (tripCode: string, successCallback?: Function) => {
  return (dispatch: Dispatch) => {
    dispatch(tripRequest());
    requestAPI(API_PATH_TRIPS, { tripCode: tripCode.toString() })
      .then((rentalPaged: RentalPagedDataList) => {
        dispatch(tripsSuccess(rentalPaged.trips!));
        dispatch(setDisplayTrips(rentalPaged.trips!));
        dispatch(setTotalTrips(rentalPaged.totalCount!));
        if (successCallback && typeof successCallback === 'function') {
          successCallback(rentalPaged?.trips?.[0]);
        }
      })
      .catch((err) => {
        dispatch(tripFailure(err));
        message.error(err.message);
      });
  };
};

export const fetchTripById = (tripId: string, successCallback?: Function) => {
  return (dispatch: Dispatch) => {
    requestAPI(`${API_PATH_TRIPS}/${tripId}`, {})
      .then((trip) => {
        const { id, carId, reservedAt, tripEnd } = trip;
        batch(() => {
          dispatch(selectTrip(trip));
          dispatch(fetchTripInvoiceData(id));
          dispatch(fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd));
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
};

export const fetchTrips =
  (
    offset: number,
    limit: number,
    successCallback?: Function,
    filterParams?: APIFilterParams
  ) =>
  (dispatch: Dispatch) => {
    dispatch(tripsRequest());
    requestAPI(API_PATH_TRIPS, {
      offset: offset.toString(),
      limit: limit.toString(),
      ...filterParams,
    })
      .then((rentalPaged: RentalPagedDataList) => {
        if (rentalPaged) {
          dispatch(tripsSuccess(rentalPaged.trips!));
          dispatch(setDisplayTrips(rentalPaged.trips!));
          dispatch(setTotalTrips(rentalPaged.totalCount!));
          if (successCallback && typeof successCallback === 'function') {
            successCallback();
          }
        }
      })
      .catch((err) => {
        dispatch(tripsFailure(err));
        message.error(err.message);
      });
  };

export const fetchBookings =
  (offset: number, limit: number, successCallback?: Function) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { loadingBookings } = getState().userData.trips;
    if (!loadingBookings) {
      dispatch(bookingsRequest());
      requestAPI('/fleets/bookings', {
        limit: limit.toString(),
        offset: offset.toString(),
      })
        .then((bookingPaged: BookingPagedDataList) => {
          if (bookingPaged) {
            dispatch(bookingsSuccess(bookingPaged.bookings!));
            dispatch(setTotalBookings(bookingPaged.totalCount!));
            dispatch(setBookingsDisplay(bookingPaged.bookings!));
            if (successCallback && typeof successCallback === 'function') {
              successCallback(bookingPaged.bookings!);
            }
          }
        })
        .catch((err) => {
          dispatch(bookingsFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const fetchBookingById =
  (bookingId: string) => (dispatch: Dispatch, getState: GetState) => {
    const { loadingBookings } = getState().userData.trips;
    if (!loadingBookings) {
      dispatch(bookingRequest());
      requestAPI(`/fleets/bookings/${bookingId}`)
        .then((booking: FleetBookingData) => {
          if (booking) {
            dispatch(selectBooking(booking));
          }
        })
        .catch((err) => {
          dispatch(bookingFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const fetchTripInvoiceData =
  (tripId: string, successCallback?: Function) => (dispatch: Dispatch) => {
    dispatch(tripInvoiceRequest());
    requestAPI(`/fleets/trips/${tripId}/invoiceData`)
      .then((invoiceData: any) => {
        dispatch(tripInvoiceSuccess(invoiceData));
        if (successCallback && typeof successCallback === 'function') {
          successCallback(invoiceData);
        }
      })
      .catch((err) => {
        dispatch(tripInvoiceFailure(err));
        message.error(err.message);
      });
  };

export const finishTrip =
  (tripId: string, force: boolean = false) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { finishingTrip } = getState().userData.trips;
    const { tripsManager } = getState().userData.user;
    const isService = !!getState().userData.cares.selected;
    if (!finishingTrip) {
      dispatch(finishTripRequest());
      requestAPI(
        `/fleets/trips/${tripId}/finalize`,
        {
          force: force.toString(),
        },
        { method: 'PUT' }
      )
        .then(async (data) => {
          message.success(localize('trip.finished.success', getState));
          if (isService) {
            dispatch(finishServiceTripSuccess(data));
          } else {
            dispatch(finishTripSuccess(data));
          }
        })
        .catch((err) => {
          if (err instanceof ViolationError) {
            if (err.violations != null) {
              if (force || !tripsManager) {
                if (isService) {
                  dispatch(finishServiceTripFailure(err));
                } else {
                  dispatch(finishTripFailure(err));
                }
                message.error(err.message);
              } else {
                // handle finish violations by showing them in a dialog to confirm force finish
                dispatch(finishTripViolations(Object.keys(err.violations)));
                dispatch(openModal('finishTripModal'));
              }
            }
          }
        });
    } else {
      return Promise.resolve();
    }
  };

export const startTrip =
  (tripId: string) => (dispatch: Dispatch, getState: GetState) => {
    const { finishingTrip } = getState().userData.trips;
    if (!finishingTrip) {
      dispatch(startTripRequest());
      requestAPI(`/fleets/trips/${tripId}/unlock`, {}, { method: 'PUT' })
        .then(async (data) => {
          message.success(localize('trip.started.success', getState));
          dispatch(startTripSuccess(data));
        })
        .catch((err) => {
          dispatch(startTripFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const createBooking =
  (booking: any, successCallback?: Function) =>
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(createBookingRequest());
    dispatch(startTripRequest());
    requestAPI(
      '/fleets/bookings',
      {},
      { method: 'POST', body: JSON.stringify(booking) }
    )
      .then((data) => {
        if (data) {
          dispatch(createBookingSuccess());
          message.success(localize('booking.created.success', getState));
          if (getState()?.userData?.cars?.selected?.id) {
            dispatch(
              fetchCarActiveBookings(getState().userData.cars.selected.id)
            );
          }
          setTimeout(() => {
            dispatch(
              getActiveBooking(
                getState().userData.cars.display?.map((car: any) => car.id)
              )
            );
          }, 500);
          dispatch(fetchBookings(0, 1000));
          if (successCallback && typeof successCallback === 'function') {
            successCallback();
          }
        }
      })
      .catch((err) => {
        dispatch(createBookingFailure(err));
        message.error(err.message);
      });
  };

export const startBooking =
  (bookingId: string) => (dispatch: any, getState: any) => {
    dispatch(startBookingRequest());
    requestAPI(`/fleets/bookings/${bookingId}/start`, {}, { method: 'PUT' })
      .then((data) => {
        dispatch(
          bookingsSuccess(
            getState().userData.trips?.bookingEntities?.map((trip: any) =>
              trip?.id === data?.id ? data : trip
            )
          )
        );
        dispatch(
          setBookingsDisplay(
            getState().userData.trips?.bookingDisplay?.map((trip: any) =>
              trip?.id === data?.id ? data : trip
            )
          )
        );
        dispatch(selectBooking(data));
        dispatch(startOfflineBookingSuccess());
        message.success(localize('booking.started.success', getState));
      })
      .catch((err) => {
        dispatch(startOfflineBookingFailure(err));
        message.error(err.message);
      });
  };

export const cancelBooking =
  (bookingId: string, successCallback: () => void) =>
  (dispatch: any, getState: any) => {
    dispatch(cancelBookingRequest());
    requestAPI(`/fleets/bookings/${bookingId}`, {}, { method: 'DELETE' })
      .then((data) => {
        dispatch(cancelBookingSuccess());
        message.success(localize('booking.cancelled.success', getState));
        dispatch(
          setBookingsDisplay(
            getState().userData.trips?.bookingDisplay?.map((trip: any) =>
              trip?.id === data?.id ? data : trip
            )
          )
        );
        successCallback();
        dispatch(selectBooking(data));
      })
      .catch((err) => {
        dispatch(cancelBookingFailure(err));
        message.error(err.message);
      });
  };

export const updateBooking = updateAPI(
  updateBookingRequest,
  updateBookingSuccess,
  updateBookingFailure,
  '/fleets/bookings/'
);

export const updateTrip = updateAPI(
  updateTripRequest,
  updateTripSuccess,
  updateTripFailure,
  '/fleets/trips/'
);
