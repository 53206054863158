import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Upload } from 'antd';
import styles from './ProfileGallery.styles';
import {
  deleteProfileImage,
  selectSelectedProfile,
  uploadProfileImage,
} from '../../redux/store/userData/profiles';
import { selectAuth } from '../../redux/store/userData/user/selectors';

const ProfileGallery = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const selectedProfile = useSelector(selectSelectedProfile);

  const handleBeforeUpload = (file: any) => {
    dispatch(uploadProfileImage(selectedProfile?.id!, file));
    return false;
  };

  const handleDeleteImage = (imageKey: string) => {
    dispatch(deleteProfileImage(selectedProfile?.id!, imageKey));
  };

  return (
    <>
      <div className="profile-gallery-wrapper">
        {selectedProfile?.images?.map((imageObj, i) => (
          <div className="profile-image-wrapper" key={`image-${imageObj.key}`}>
            <img
              className="profile-image"
              src={imageObj.url}
              alt={`vehicle image ${i}`}
            />
            {user?.permissions?.fleetManager && (
              <div
                onClick={() => {
                  handleDeleteImage(imageObj.key!!);
                }}
                className="trashTouchable"
              >
                <img
                  src="/images/trash.png"
                  className="trash"
                  alt="trash-image"
                />
              </div>
            )}
          </div>
        ))}
        {user?.permissions?.fleetManager && (
          <Upload
            accept="image"
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
          >
            <Button icon="upload" className="upload-photo" type="link">
              Upload Photo
            </Button>
          </Upload>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ProfileGallery;
