import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import getTargets from './helpers/getTargets';
import styles from './TargetSelector.styles';
import { setTarget } from '../../redux/store/ui/filters';
import { selectViewMode } from '../../redux/store/ui/common';

type TargetSelectorProps = {};

const TargetSelector: React.FC<TargetSelectorProps> = () => {
  const [query, setQuery] = useState(null);
  const viewMode = useSelector(selectViewMode);
  const intl = useIntl();
  const dispatch = useDispatch();
  const targets = getTargets(viewMode);

  const filteredTargets =
    query === null
      ? targets
      : targets.filter((t: { value: string }) => {
          const label = intl.formatMessage({ id: String(t.value) });
          const lowerCaseLabel = label.toLowerCase();
          const lowerCaseValue =
            typeof t.value === 'string' ? t.value.toLowerCase() : '';

          return (
            lowerCaseLabel.includes(query) || lowerCaseValue.includes(query)
          );
        });

  const onClickOption =
    (target: any, targetType: any) => (e: { preventDefault: () => void }) => {
      e.preventDefault();
      dispatch(setTarget({ target, targetType }));
    };

  const onChangeInput = (e: {
    preventDefault: () => void;
    target: { value: string | React.SetStateAction<null> };
  }) => {
    e.preventDefault();
    if (typeof e.target.value === 'string') {
      const lowerCaseValue: any = e.target.value.toLowerCase();
      setQuery(lowerCaseValue);
    } else {
      setQuery(e.target.value);
    }
  };

  return (
    <>
      <div className="target-selector">
        <div className="input-wrapper">
          <Input placeholder="Search" onChange={onChangeInput} />
        </div>
        <ul className="target-options">
          {filteredTargets &&
            filteredTargets?.map((t: { value: any; type: any }) => (
              <li
                className="target-option"
                key={`target-option-${t.value}`}
                onClick={onClickOption(t.value, t.type)}
              >
                <FormattedMessage
                  id={`fleetadmin.filter.${viewMode}.${t.value}`}
                />
              </li>
            ))}
        </ul>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TargetSelector;
