import React from 'react';
import styles from './DrawerModal.styles';

type DrawerModalProps = {
  visible: Boolean;
  onClose: () => void;
};

const DrawerModal: React.FC<DrawerModalProps> = ({
  children,
  visible,
  onClose,
}) => {
  const handleOnClickOutSide = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onClose();
  };

  const handleOnClickModal = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  return (
    visible && (
      <>
        <div className="drawer-modal-wrapper" onClick={handleOnClickOutSide}>
          <div className="mask" onClick={handleOnClickOutSide}>
            <div className="drawer-modal" onClick={handleOnClickModal}>
              {children}
            </div>
          </div>
        </div>
        <style jsx>{styles}</style>
      </>
    )
  );
};

export default DrawerModal;
