import css from 'styled-jsx/css';

export const ImageStyles = css`
  .download {
    display: flex;
    border-radius: 50px;
    background-color: white;
    padding: 3px;
    box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 21%);
    margin-top: -45px;
    margin-left: -10px;
    z-index: 1;
    cursor: pointer;
    color: #0077be;
  }
  :global(.ant-image-img) {
    border-radius: 4px;
  }
  :global(.ant-image-mask-info) {
    font-size: 8px;
  }
  :global(.ant-image-img) {
    display: block;
  }
  :global(.ant-image) {
    position: relative;
    display: inline-block;
  }
  :global(.ant-image-img) {
    display: block;
    width: 100%;
    height: auto;
  }
  :global(.ant-image-img-placeholder) {
    background-color: #f5f5f5;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
  }
  :global(.ant-image-mask) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  :global(.ant-image-mask-info .anticon) {
    -webkit-margin-end: 4px;
    margin-inline-end: 4px;
  }
  :global(.ant-image-mask:hover) {
    opacity: 1;
  }
  :global(.ant-image-placeholder) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  :global(.ant-image-preview) {
    pointer-events: none;
    height: 100%;
    text-align: center;
  }
  :global(.ant-image-preview.zoom-enter, .ant-image-preview.zoom-appear) {
    -webkit-transform: none;
    transform: none;
    opacity: 0;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  :global(.ant-image-preview-mask) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  :global(.ant-image-preview-mask-hidden) {
    display: none;
  }
  :global(.ant-image-preview-wrap) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
  :global(.ant-image-preview-body) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  :global(.ant-image-preview-img) {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-transition: -webkit-transform 0.3s
      cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto;
  }
  :global(.ant-image-preview-img-wrapper) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: -webkit-transform 0.3s
      cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  :global(.ant-image-preview-img-wrapper::before) {
    display: inline-block;
    width: 1px;
    height: 50%;
    margin-right: -1px;
    content: '';
  }
  :global(.ant-image-preview-moving .ant-image-preview-img) {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  :global(.ant-image-preview-moving .ant-image-preview-img-wrapper) {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }
  :global(.ant-image-preview-wrap) {
    z-index: 1080;
  }
  :global(.ant-image-preview-operations) {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.85);
    list-style: none;
    background: rgba(0, 0, 0, 0.6);
    pointer-events: auto;
  }
  :global(.ant-image-preview-operations-operation) {
    margin-left: 12px;
    padding: 12px;
    cursor: pointer;
  }
  :global(.ant-image-preview-operations-operation-disabled) {
    color: rgba(255, 255, 255, 0.25);
    pointer-events: none;
  }
  :global(.ant-image-preview-operations-operation:last-of-type) {
    margin-left: 0;
  }
  :global(.ant-image-preview-operations-icon) {
    font-size: 18px;
  }
  :global(.ant-image-preview-switch-left, .ant-image-preview-switch-right) {
    position: fixed;
    top: 50%;
    right: 10px;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-top: -22px;
    color: rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
  }
  :global(.ant-image-preview-progress) {
    z-index: 3;
  }
  :global(
      .ant-image-preview-switch-left-disabled,
      .ant-image-preview-switch-right-disabled
    ) {
    color: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
  }
  :global(
      .ant-image-preview-switch-left-disabled > .anticon,
      .ant-image-preview-switch-right-disabled > .anticon
    ) {
    cursor: not-allowed;
  }
  :global(
      .ant-image-preview-switch-left > .anticon,
      .ant-image-preview-switch-right > .anticon
    ) {
    font-size: 18px;
  }
  :global(.ant-image-preview-switch-left) {
    left: 10px;
  }
  :global(.ant-image-preview-switch-right) {
    right: 10px;
  }
  :global(.ant-drawer.ant-drawer-open .ant-drawer-mask) {
    height: 100%;
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    pointer-events: auto;
  }
  @-webkit-keyframes antdDrawerFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antdDrawerFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  :global(
      .ant-notification-topLeft
        .ant-notification-fade-enter.ant-notification-fade-enter-active,
      .ant-notification-bottomLeft
        .ant-notification-fade-enter.ant-notification-fade-enter-active,
      .ant-notification-topLeft
        .ant-notification-fade-appear.ant-notification-fade-appear-active,
      .ant-notification-bottomLeft
        .ant-notification-fade-appear.ant-notification-fade-appear-active
    ) {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name: NotificationLeftFadeIn;
  }
  :global(.ant-notification .notification-fade-effect) {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  :global(.ant-notification-fade-enter, .ant-notification-fade-appear) {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    opacity: 0;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  :global(.ant-notification-fade-leave) {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  :global(
      .ant-notification-fade-enter.ant-notification-fade-enter-active,
      .ant-notification-fade-appear.ant-notification-fade-appear-active
    ) {
    -webkit-animation-name: NotificationFadeIn;
    animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  :global(.ant-notification-fade-leave.ant-notification-fade-leave-active) {
    -webkit-animation-name: NotificationFadeOut;
    animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  @-webkit-keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  :global(.ant-fade-enter, .ant-fade-appear) {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  :global(.ant-fade-leave) {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  :global(
      .ant-fade-enter.ant-fade-enter-active,
      .ant-fade-appear.ant-fade-appear-active
    ) {
    -webkit-animation-name: antFadeIn;
    animation-name: antFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  :global(.ant-fade-leave.ant-fade-leave-active) {
    -webkit-animation-name: antFadeOut;
    animation-name: antFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  :global(.ant-fade-enter, .ant-fade-appear) {
    opacity: 0;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  :global(.ant-fade-leave) {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  :global(.ant-zoom-leave) {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  @-webkit-keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  :global(
      .ant-zoom-enter.ant-zoom-enter-active,
      .ant-zoom-appear.ant-zoom-appear-active
    ) {
    -webkit-animation-name: antZoomIn;
    animation-name: antZoomIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  @-webkit-keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
      transform: scale(0.2);
      opacity: 0;
    }
  }
  :global(.ant-zoom-leave.ant-zoom-leave-active) {
    -webkit-animation-name: antZoomOut;
    animation-name: antZoomOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
  }
  :global(.ant-zoom-enter, .ant-zoom-appear) {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  :global(.ant-zoom-enter-prepare, .ant-zoom-appear-prepare) {
    -webkit-transform: none;
    transform: none;
  }
  :global(.image-preview-meta) {
    position: absolute;
    left: 16px;
    bottom: 3px;
    z-index: 3;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
  }
`;
