import css from 'styled-jsx/css';

const style = css`
  .table-map-switcher {
    background: #fff;
    border-radius: 12px;
    height: 22px;
    display: flex;
  }
  .item {
    color: black;
    padding: 0 8px;
    border-radius: 14px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
  }
  .item.active {
    background: #2b2e42;
    color: #fff;
  }
  .beta {
    position: fixed;
    top: 68px;
    right: 18px;
    background-color: rgb(226, 87, 71);
    border-radius: 2px;
    padding: 2px 4px;
    font-size: 10px;
    line-height: 11px;
    color: white;
  }
  @media only screen and (min-width: 768px) {
    .table-map-switcher {
      background: #fff;
      border-radius: 12px;
      height: 24px;
      display: flex;
    }
    .item {
      color: #979797;
      padding: 0 8px;
      border-radius: 14px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      padding: 0 12px;
    }
    .item.active {
      background: #2b2e42;
      color: #fff;
    }
  }
`;

export default style;
