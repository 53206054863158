import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .users-drawer-body {
    background: ${colors.white};
    min-height: 100%;
  }
  .users-name-wrapper {
    display: flex;
    flex-direction: row;
    padding: 8px 24px 24px;
    background: ${colors.backgroundContentMain};
  }
  .name {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
  }
  .users-name-edit-button {
    margin-left: 5px;
    cursor: pointer;
  }
`;

export default style;
