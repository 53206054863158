import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import ClaimDrawerBody from './ClaimDrawerBody';
import CarEditDrawer from '../../components/CarEditDrawer';
import TripDrawer from '../../components/TripDrawer';
import CareDrawer from '../../components/CareDrawer';
import { ViewMode } from '../../hooks/useViewMode';
import { selectSelectedClaim } from '../../redux/store/userData/claims';
import { ViewType } from '../../hooks/useViewType';
import isDesktop from '../../utils/sizeHelpers';
import { closeClaimDrawerAndSetQueries } from '../../redux/store/ui/common';
import {
  selectViewMode,
  selectViewType,
} from '../../redux/store/ui/common/selectors';

const ClaimDrawer: React.FC = () => {
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const selectedClaim = useSelector(selectSelectedClaim);
  const dispatch = useDispatch();

  const visible =
    (selectedClaim != null && isDesktop()) ||
    (selectedClaim != null && !isDesktop() && viewType !== ViewType.MAP);

  const handleOnClose = () => {
    dispatch(closeClaimDrawerAndSetQueries());
  };

  return (
    selectedClaim && (
      <DrawerLayout
        visible={visible}
        title={''}
        body={<ClaimDrawerBody />}
        nestedDrawers={
          <>
            {viewMode !== ViewMode.VEHICLE && <CarEditDrawer />}
            {viewMode !== ViewMode.SHARE && <TripDrawer />}
            {viewMode !== ViewMode.CARE && <CareDrawer />}
          </>
        }
        isClaimDrawer
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default ClaimDrawer;
