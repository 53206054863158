import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css`
  .drawer-modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${zIndex.drawer};
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .drawer-modal {
    background: #fff;
  }
  @media only screen and (min-width: 768px) {
    .drawer-modal-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${zIndex.drawerDesktop};
      height: 100%;
    }
    .mask {
      position: fixed;
      top: 0;
      right: 0;
      width: 435px;
      height: 100%;
      background: rgba(0, 0, 0, 0.26);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .drawer-modal {
      background: #fff;
    }
  }
`;

export default style;
