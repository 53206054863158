import css from 'styled-jsx/css';
import { colors, zIndex, common } from '../../theme/theme';

const style = css`
  .add-filter-wrapper {
    margin-right: 16px;
    position: relative;
  }
  .add-filter-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 4px;
    margin: 0;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .add-filter-button:hover {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 4px;
    margin: 0;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .filter-icon {
    margin-right: 0px;
  }
  .button-text {
    font-size: 14px;
    margin: 0;
  }
  .panel-wrapper {
    z-index: ${zIndex.addFilter};
    position: fixed;
    top: 20%;
    left: 13%;
    width: 275px;
    ${common.secondaryShadow}
  }
  .panel-close-button {
    z-index: ${zIndex.addFilterCloseButton};
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) {
    .add-filter-button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      min-width: 116px;
      padding: 0 8px;
      margin: 0;
      white-space: nowrap;
      color: ${colors.black};
      border: 2px solid ${colors.black};
      border-radius: 8px;
      cursor: pointer;
    }
    .add-filter-button:hover {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      min-width: 116px;
      padding: 0 8px;
      margin: 0;
      white-space: nowrap;
      color: ${colors.black};
      border: 2px solid ${colors.black};
      border-radius: 8px;
      cursor: pointer;
    }
    .filter-icon {
      margin-right: 4px;
    }
    .panel-wrapper {
      z-index: ${zIndex.addFilter};
      position: absolute;
      top: 34px;
      left: unset;
      width: 275px;
      ${common.secondaryShadow}
    }
  }
`;

export default style;
