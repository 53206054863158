import React from 'react';
import Intercom from 'react-intercom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/store/userData/user';

const ConfiguredIntercom = () => {
  const { email, firstName, lastName } = useSelector(selectAuth);
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  const intercomUser = {
    email,
    name: `${firstName} ${lastName}`,
  };
  // @ts-ignore
  return <Intercom appID={intercomAppId} {...intercomUser} />;
};

export default ConfiguredIntercom;
