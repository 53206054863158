import { requestAPI } from '../../../../utils/request';
import { fleetsRequest, fleetsSuccess, fleetsFailure } from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { FleetsState } from './initialState';

const fetchFleets = () => (dispatch: Dispatch) => {
  dispatch(fleetsRequest());
  requestAPI('/fleets')
    .then((fleets) => {
      dispatch(fleetsSuccess(fleets));
    })
    .catch((err) => {
      dispatch(fleetsFailure(err));
      message.error(err.message);
    });
};

const shouldFetchFleets = (state: FleetsState) => !state.loading;

export const fetchFleetsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchFleets(getState().appData.fleets)) {
      return dispatch(fetchFleets());
    } else {
      return Promise.resolve();
    }
  };
};
