import css from 'styled-jsx/css';

const style = css`
  .transaction-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .amount-date-container {
    width: 110px;
    text-align: end;
    margin-top: 12px;
  }

  .amount {
    font-size: 12px;
    font-weight: bold;
  }

  .amount.normal {
    color: black;
  }

  .amount.negative {
    color: darkred;
  }

  .amount.positive {
    color: green;
  }

  .date {
    font-size: 8px;
  }

  .dot-line-container {
    margin: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: #8c8c8c;
    border-radius: 50%;
    z-index: 1;
  }

  .line {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #d9d9d9;
  }

  .line.first-index {
    margin-top: 15%;
  }

  .collapse-container {
    margin: 10px 0;
    width: 100%;
  }

  .collapse {
    width: 100%;
  }

  .collapse-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .collapse-container-content {
    max-width: 230px;
  }

  .collapse-header-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .collapse-header-value {
    color: gray;
  }

  .wallet-collapse-content {
    padding: 12px 0;
  }

  @media only screen and (min-width: 768px) {
    .collapse-container-content {
      max-width: unset;
    }
  }
`;

export default style;
