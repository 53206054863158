import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Gallery.styles';
import { selectSelectedCar } from '../../redux/store/userData/cars';

const Gallery = () => {
  const selectedCar = useSelector(selectSelectedCar);
  const isNoImages = !selectedCar?.images || selectedCar.images.length === 0;

  return (
    <>
      <div className="gallery-wrapper">
        {isNoImages ? (
          <p className="no-img-message">There are no images</p>
        ) : (
          <>
            {selectedCar?.images?.map((imageUrl, i) => (
              <div className="image-wrapper" key={`image-${imageUrl}`}>
                <img
                  className="image"
                  src={imageUrl}
                  alt={`vehicle image ${i}`}
                />
              </div>
            ))}
          </>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Gallery;
