import css from 'styled-jsx/css';
import { zIndex } from '../../../theme/theme';

const style = css.global`
  .add-receipt-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .add-receipt-wrapper .ant-modal-header {
    padding: 15px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .add-receipt-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .add-receipt-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
  }
  .add-receipt-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .add-receipt-contents {
    width: 400px;
  }
  :global(.add-receipt-wrapper span) {
    display: flex;
    flex-direction: column !important;
  }
`;

export default style;
