import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Button, Input } from 'antd';
import DrawerModal from '../../../components/shared/DrawerModal';
import styles from './AddAdminToCircleModal.styles';
import {
  addAdminToCircle,
  selectSelectedCircle,
} from '../../../redux/store/userData/circles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import { useIntl } from 'react-intl';

const AddAdminToCircleModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedCircle = useSelector(selectSelectedCircle);

  const [adminEmail, setAdminEmail] = useState<string>('');
  const visibleModal = useSelector(getIsVisibleModal('addAdminToCircleModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      dispatch(addAdminToCircle(selectedCircle?.id!, adminEmail));
      dispatch(closeModal('addAdminToCircleModal'));
    });
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('addAdminToCircleModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('addAdminToCircleModal'));
        }}
      >
        <div className="add-user-modal">
          <div className="add-user-wrapper">
            <p className="label">Admin email</p>
            <Input
              placeholder="Type user email"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default AddAdminToCircleModal;
