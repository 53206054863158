import css from 'styled-jsx/css';

const style = css`
  .date-search {
    padding: 16px 16px 4px 16px;
  }
  .date-search :global(.date-filter-input) {
    width: calc(100%);
    margin-bottom: 10px;
  }
`;

export default style;
