import React from 'react';
import styles from './RenderBoolean.styles';

interface Props {
  value: boolean;
}

const CHECK_ICON = '/images/table/check.svg';
const CROSS_ICON = '/images/table/cross.svg';

const RenderBoolean: React.FC<Props> = ({ value }) => {
  return (
    <>
      <span className="true-or-false-cell">
        {value == null ? (
          '-'
        ) : value ? (
          <img src={CHECK_ICON} alt="true" />
        ) : (
          <img src={CROSS_ICON} alt="false" />
        )}
      </span>
      <style jsx>{styles}</style>
    </>
  );
};

export default RenderBoolean;
