export const optionsForUserStatus: Array<string> = ['active', 'blocked'];

export const optionsForCWStatus: Array<string> = ['open', 'verified', 'failed'];

export const optionsForCardStatus: Array<string> = [
  'open',
  'listed',
  'verified',
  'paused',
  'deleted',
];
