import css from 'styled-jsx/css';

const style = css`
  .service-image-gallery {
    margin: 24px;
  }
  .img-wrapper {
    width: 100%;
    margin-bottom: 16px;
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default style;
