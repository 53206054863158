import React from 'react';
import { Select } from 'antd';
import styles from './CarEditorConnectionSelector.styles';
import { zIndex } from '../../theme/theme';

const { Option } = Select;

type ConnectionSelectorProps = {
  connections: Array<'cloudboxx' | 'geoTab' | 'none'>;
  style?: any;
  className?: string;
  defaultValue?: string;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  onSelect?: (value: string) => void;
};

const ConnectionSelector: React.FC<ConnectionSelectorProps> = ({
  connections,
  style,
  className,
  defaultValue,
  value,
  disabled,
  loading,
  placeholder,
  onSelect,
}) => {
  return (
    <div style={style} className={className}>
      <Select
        defaultValue={defaultValue}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder}
        onChange={onSelect}
      >
        {[...connections].map((connection) => (
          <Option key={`area-option-${connection}`} value={connection}>
            {connection}
          </Option>
        ))}
      </Select>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ConnectionSelector;
