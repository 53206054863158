import React from 'react';
import { useSelector } from 'react-redux';
import {
  noAreasStyles,
  assignedAreasStyles,
  loadingStyles,
} from './AssignedAreas.styles';
import {
  selectLoadingCircle,
  selectSelectedCircle,
} from '../../redux/store/userData/circles';
import LoadingIcon from '../../components/shared/LoadingIcon';

const NoAreas = () => {
  return (
    <>
      <div>
        <p className="message">There are no areas assigned to this group</p>
      </div>
      <style jsx>{noAreasStyles}</style>
    </>
  );
};

const AssignedAreas = () => {
  const selectedCircle = useSelector(selectSelectedCircle);
  const loadingCircle = useSelector(selectLoadingCircle);

  if (loadingCircle) {
    return (
      <>
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  return (
    <>
      <ul
        className="assigned-areas"
        style={!selectedCircle?.areas?.length ? { borderBottom: 0 } : {}}
      >
        {selectedCircle?.areas?.length === 0 && <NoAreas />}
        {selectedCircle?.areas?.length! > 0 &&
          selectedCircle?.areas?.map((area, id) => {
            return (
              <li className="assigned-area" key={`assigned-area-${id}`}>
                <span>{area?.name}</span>
              </li>
            );
          })}
      </ul>
      <style jsx>{assignedAreasStyles}</style>
    </>
  );
};

export default AssignedAreas;
