import css from 'styled-jsx/css';

const style = css`
  .collapse-container {
    margin-right: 24px;
    margin-left: 24px;
  }
  .user-section-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px;
    margin-top: 36px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .consent-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export default style;
