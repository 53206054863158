import css from 'styled-jsx/css';

const style = css`
  .user-timeline {
    margin: 0;
    padding: 16px;
  }
  .empty-wrapper {
    margin-top: 50px;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  .empty {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

export default style;
