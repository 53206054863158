export type PricingState = any;

export enum PricingTypes {
  PRICING_REQUEST = 'appData/pricing/PRICING_REQUEST',
  PRICING_SUCCESS = 'appData/pricing/PRICING_SUCCESS',
  PRICING_FAILURE = 'appData/pricing/PRICING_FAILURE',
  BRAND_PRICING_REQUEST = 'appData/pricing/BRAND_PRICING_REQUEST',
  BRAND_PRICING_SUCCESS = 'appData/pricing/BRAND_PRICING_SUCCESS',
  BRAND_PRICING_FAILURE = 'appData/pricing/BRAND_PRICING_FAILURE',
  SET_DISPLAY_PRICING = 'appData/pricing/SET_DISPLAY_PRICING',
  SELECT_PRICING = 'appData/pricing/SELECT_PRICING',
  UPLOAD_PRICING_FILE_REQUEST = 'appData/pricing/UPLOAD_PRICING_FILE_REQUEST',
  UPLOAD_PRICING_FILE_SUCCESS = 'appData/pricing/UPLOAD_PRICING_FILE_SUCCESS',
  UPLOAD_PRICING_FILE_FAILURE = 'appData/pricing/UPLOAD_PRICING_FILE_FAILURE',
  PRICING_UPDATE_REQUEST = 'appData/pricing/PRICING_UPDATE_REQUEST',
  PRICING_UPDATE_SUCCESS = 'appData/pricing/PRICING_UPDATE_SUCCESS',
  PRICING_UPDATE_FAILURE = 'appData/pricing/PRICING_UPDATE_FAILURE',
  PRICING_DELETE_REQUEST = 'appData/pricing/PRICING_DELETE_REQUEST',
  PRICING_DELETE_SUCCESS = 'appData/pricing/PRICING_DELETE_SUCCESS',
  PRICING_DELETE_FAILURE = 'appData/pricing/PRICING_DELETE_FAILURE',
}
