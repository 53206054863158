import css from 'styled-jsx/css';

export const loadingStyles = css`
  .loading-wrapper {
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const carConditionStyles = css`
  .condition-item-dongle {
    box-sizing: border-box;
    width: 33.1%;
    border: 1px solid #f1f1f1;
    margin: 0 5px;
  }
  .condition-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 50%;
    border: 1px solid #f1f1f1;
    margin: 5px 5px;
    padding: 15px 10px;
  }
  .condition-item .condition-value {
    font-size: 17px;
    margin: 0;
  }
  .condition-left-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .condition-left-item .condition-label {
    margin: 0px 5px;
  }
  .invalid.condition-item {
    background: #ed4e4d;
  }
  .invalid.condition-item-dongle {
    background: #ed4e4d;
  }
  .condition-value {
    font-family: Avenir Next;
    color: #3f8e21;
    font-size: 18px;
    font-weight: 600;
    margin: 12px 0;
  }
  .condition-label {
    font-family: Avenir Next;
    font-size: 12px;
    margin-top: 7px;
    font-weight: 600;
    color: black;
  }
  .invalid .condition-value,
  .invalid .condition-label {
    color: #fff;
  }
  .no-data .condition-value,
  .no-data .condition-label {
    color: #979797;
  }
  .dongle-condition-image {
    height: 24px;
  }
  .condition-image {
    height: 20px;
  }
`;

export const conditionsWrapperStyles = css`
  .online-status-wrapper {
    margin: 10px 5px 15px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button-wrapper {
    margin-left: -15px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: auto;
  }

  :global(.refresh-button) {
    display: flex;
    align-items: center;
    margin: 0;
    text-align: left;
    width: auto !important;
    padding-right: 15px;
  }

  .conditions-wrapper {
    display: flex;
    background: white;
    text-align: center;
    margin: 0 0 4px;
  }

  .refresh-button-last-modified {
    display: flex !important;
    align-items: center !important;
    color: grey;
    margin-left: 15px;
    margin-right: 2px;
  }
`;
