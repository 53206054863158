import React from 'react';
import { Collapse } from 'antd';
import styles from './Transaction.styles';
import { formatPrice } from '../../../../utils';
import InvoiceItem from '../../../TripDrawer/InvoiceItem';
import ListItem from '../../../shared/ListItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import LinkToTrip from '../../../shared/LinkToTrip';

const { Panel } = Collapse;

const Transaction = ({
  intl,
  index,
  amount,
  date,
  headerTitle,
  headerValue,
  transaction,
}) => {
  return (
    <>
      <div className="transaction-container">
        <div className="amount-date-container">
          <div
            className={`amount ${
              amount < 0
                ? transaction.transactionType === 'chargeback'
                  ? 'negative'
                  : 'normal'
                : 'positive'
            }`}
          >
            {formatPrice(amount, false)}
          </div>
          <div className="date">{date}</div>
        </div>

        <div className="dot-line-container">
          <div className="dot" />
          <div className={`line ${index === 0 ? 'first-index' : ''}`} />
        </div>

        <div className="collapse-container">
          <Collapse bordered={false} className="collapse">
            <Panel
              header={
                <div className="collapse-header-container">
                  <div className="collapse-header-title">{headerTitle}</div>
                  <div className="collapse-header-value">{headerValue}</div>
                </div>
              }
              key={index}
            >
              <div className="collapse-container-content">
                {transaction.transactionType === 'credit' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.invoice',
                      })}
                      value={
                        transaction?.receiptNo ? (
                          <InvoiceItem
                            invoiceName={transaction?.receiptNo}
                            invoiceUri={transaction?.invoiceUrl}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.note',
                      })}
                      value={renderText(transaction?.note, true)}
                    />
                  </div>
                )}

                {transaction.transactionType === 'payment' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.method',
                      })}
                      value={renderText(transaction?.paymentMethod, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.reference',
                      })}
                      value={
                        transaction?.reference ? (
                          <InvoiceItem
                            invoiceName={transaction?.reference}
                            invoiceUri={transaction?.invoiceUrl}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.serviceref',
                      })}
                      value={renderText(transaction?.creditServiceRef, true)}
                    />
                  </div>
                )}

                {transaction.transactionType === 'payout' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.method',
                      })}
                      value={renderText(transaction?.paymentMethod, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.serviceref',
                      })}
                      value={renderText(transaction?.creditServiceRef, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.note',
                      })}
                      value={renderText(transaction?.note, true)}
                    />
                  </div>
                )}

                {transaction.transactionType === 'chargeback' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.serviceref',
                      })}
                      value={renderText(transaction?.creditServiceRef, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.method',
                      })}
                      value={renderText(transaction?.paymentMethod, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.reference',
                      })}
                      value={
                        transaction?.reference ? (
                          <InvoiceItem
                            invoiceName={transaction?.reference}
                            invoiceUri={transaction?.invoiceUrl}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  </div>
                )}

                {transaction.transactionType === 'bonus' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.note',
                      })}
                      value={renderText(transaction?.note, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.initiatedBy',
                      })}
                      value={renderText(transaction?.initiatedBy, true)}
                    />
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.reference',
                      })}
                      value={renderText(transaction?.reference, true)}
                    />
                  </div>
                )}

                {transaction.transactionType === 'invoice' && (
                  <div className="wallet-collapse-content">
                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.invoice',
                      })}
                      value={
                        transaction?.invoiceNo ? (
                          <InvoiceItem
                            invoiceName={transaction?.invoiceNo}
                            invoiceUri={transaction?.invoiceUrl}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />

                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.reference',
                      })}
                      value={
                        transaction?.reference &&
                        /^[A-Z]{3}-[A-Z]{3}$/?.test(transaction?.reference) ? (
                          <LinkToTrip
                            tripCode={transaction?.reference!}
                            service={null}
                          />
                        ) : (
                          renderText(transaction?.reference, true)
                        )
                      }
                    />

                    <ListItem
                      label={intl.formatMessage({
                        id: 'fleetadmin.wallet.serviceref',
                      })}
                      value={renderText(transaction?.creditServiceRef, true)}
                    />
                  </div>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Transaction;
