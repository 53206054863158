import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select, DatePicker } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './EditIDModal.styles';
import { closeModal, getIsVisibleModal } from '../../../redux/store/ui/common';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { useIntl } from 'react-intl';
import { zIndex } from '../../../theme/theme';
import { ANT_PICKERS_FORMAT } from '../../../utils';
import dayjs from 'dayjs';

const { Option } = Select;
const idStatuses = ['open', 'listed', 'verified', 'paused', 'deleted'];
const supportedLicenseCountries = [
  { code: 'CH', name: 'Switzerland' },
  { code: 'MC', name: 'Monaco' },
  { code: 'NO', name: 'Norway' },
  { code: 'BE', name: 'Belgium' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'AT', name: 'Austria' },
  { code: 'FI', name: 'Finland' },
];

export const MODAL_EDIT_USER_ID = 'EditIDModal';
const EditIDModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const visibleModal = useSelector(getIsVisibleModal(MODAL_EDIT_USER_ID));
  const dateFormat = 'YYYY-MM-DD';

  const identityDocument = selectedUser?.identityDocuments?.[0];
  const [type, setType] = useState(identityDocument?.documentType || '');
  const [number, setNumber] = useState(identityDocument?.number || '');
  const [firstName, setFirstName] = useState(identityDocument?.firstName || '');
  const [lastName, setLastName] = useState(identityDocument?.lastName || '');
  const [expiryDate, setExpiryDate] = useState(
    identityDocument?.expiryDate || ''
  ) as any;
  const [dateOfBirth, setDateOfBirth] = useState(
    identityDocument?.dateOfBirth || ''
  );
  const [issueDate, setIssueDate] = useState(identityDocument?.issueDate || '');
  const [issuePlace, setIssuePlace] = useState(
    identityDocument?.issuePlace || ''
  );
  const [birthPlace, setBirthPlace] = useState(
    identityDocument?.birthPlace || ''
  );

  const [issueCountry, setIssueCountry] = useState(
    identityDocument?.issueCountry || ''
  );
  const [status, setStatus] = useState(identityDocument?.status || 'listed');

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateUser(selectedUser.id, {
        identityDocument: {
          status,
          documentType: type,
          number,
          firstName,
          lastName,
          issuePlace,
          birthPlace,
          issueCountry,
          issueDate,
          expiryDate,
          dateOfBirth,
        },
      })
    );
    dispatch(closeModal(MODAL_EDIT_USER_ID));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal(MODAL_EDIT_USER_ID));
  };

  useEffect(() => {
    if (visibleModal) {
      const firstLicense = selectedUser?.identityDocuments[0];

      setNumber(firstLicense?.number || '');
      setFirstName(firstLicense?.firstName || '');
      setLastName(firstLicense?.lastName || '');
      setType(firstLicense?.documentType || '');
      setIssueDate(firstLicense?.issueDate || '');
      setExpiryDate(firstLicense?.expiryDate || '');
      setDateOfBirth(firstLicense?.dateOfBirth || '');
      setIssuePlace(firstLicense?.issuePlace || '');
      setBirthPlace(firstLicense?.birthPlace || '');
      setIssueCountry(firstLicense?.issueCountry || '');
      setStatus(firstLicense?.status || 'listed');
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('UserContactModal'));
        }}
      >
        <div className="update-phone-modal">
          <div className="phone-modal-wrapper">
            <div className="update-phone-modal-inputs">
              <p className="add-phone-title">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.id.manualForm.edit.title`,
                })}
              </p>
              <div className="id">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.dl.status`,
                  })}
                </p>
                <Select
                  dropdownStyle={{ zIndex: zIndex.dropDown }}
                  value={status}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.dl.status`,
                  })}
                  onChange={setStatus}
                >
                  {idStatuses.map((status) => (
                    <Option key={`id-option-${status}`} value={status}>
                      {intl.formatMessage({
                        id: `${'fleetadmin.status.'}${status}`,
                      })}
                    </Option>
                  ))}
                </Select>
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.type`,
                  })}
                </p>
                <Input
                  value={type}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.type`,
                  })}
                  onChange={(e: any) => {
                    setType(e.target.value);
                  }}
                />
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.number`,
                  })}
                </p>
                <Input
                  value={number}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.number`,
                  })}
                  onChange={(e: any) => {
                    setNumber(e.target.value);
                  }}
                />
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.firstName`,
                  })}
                </p>
                <Input
                  value={firstName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.firstName`,
                  })}
                  onChange={(e: any) => {
                    setFirstName(e.target.value);
                  }}
                />
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.lastName`,
                  })}
                </p>
                <Input
                  value={lastName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.lastName`,
                  })}
                  onChange={(e: any) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div className="id">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.birthPlace`,
                  })}
                </p>
                <Input
                  value={birthPlace}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.birthPlace`,
                  })}
                  onChange={(e: any) => {
                    setBirthPlace(e.target.value);
                  }}
                />
              </div>
              <div className="id-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.issuePlace`,
                  })}
                </p>
                <Input
                  value={issuePlace}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.issuePlace`,
                  })}
                  onChange={(e: any) => {
                    setIssuePlace(e.target.value);
                  }}
                />
              </div>
              <div className="id-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.issueDate`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  style={{ width: '100%' }}
                  value={issueDate ? dayjs(issueDate) : undefined}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.issueDate`,
                  })}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  onChange={(_, dateString) => setIssueDate(dateString)}
                />
              </div>
              <div className="id-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.expiryDate`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  style={{ width: '100%' }}
                  value={expiryDate ? dayjs(expiryDate) : undefined}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.expiryDate`,
                  })}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  onChange={(_, dateString) => setExpiryDate(dateString)}
                />
              </div>
              <div className="id-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.dateOfBirth`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  popupStyle={{ zIndex: zIndex.dropDown }}
                  style={{ width: '100%' }}
                  value={dateOfBirth ? dayjs(dateOfBirth) : undefined}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.id.manualForm.dateOfBirth`,
                  })}
                  onChange={(_, dateString) => setDateOfBirth(dateString)}
                />
              </div>
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.id.manualForm.issueCountry`,
                })}
              </p>
              <Select
                dropdownStyle={{ zIndex: zIndex.dropDown }}
                value={issueCountry}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.id.manualForm.issueCountry`,
                })}
                onChange={(code: string) => setIssueCountry(code)}
              >
                {supportedLicenseCountries.map((country) => (
                  <Option
                    key={`area-option-${country.code}`}
                    value={country.code}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Button
            disabled={!(number && issueCountry && issueDate && expiryDate)}
            className="submit-button"
            onClick={handleOnSubmit}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default EditIDModal;
