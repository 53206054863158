import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './StatusModal.styles';
import { selectStatusModal } from '../../../redux/store/ui/common/index';
import { closeStatusModal } from '../../../redux/store/ui/common/actions';
import { useIntl } from 'react-intl';
import { zIndex } from '../../../theme/theme';

const { Option } = Select;

const StatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const statusData = useSelector(selectStatusModal);
  const visibleModal = statusData != null;
  const [selectedStatus, setSelectedStatus] = useState(
    statusData?.defaultValue
  );

  const textkeyPath =
    statusData?.textkeyPath != undefined
      ? statusData.textkeyPath
      : 'fleetadmin.status.';
  const placeHolder = statusData?.placeHolder;

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    statusData?.onConfirm(selectedStatus);
    dispatch(closeStatusModal());
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeStatusModal());
  };

  useEffect(() => {
    setSelectedStatus(statusData?.defaultValue);
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeStatusModal());
        }}
      >
        <div className="confirm-modal">
          <div className="confirm-modal-wrapper">
            <Select
              dropdownStyle={{ zIndex: zIndex.dropDown }}
              value={selectedStatus || statusData?.defaultValue}
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({
                id: placeHolder || `fleetadmin.users.info.dl.status`,
              })}
              onChange={(status: string) => setSelectedStatus(status)}
            >
              {statusData?.statuses?.map((status) => (
                <Option key={`area-option-${status}`} value={status}>
                  {intl.formatMessage({
                    id: `${textkeyPath}${status}`,
                  })}
                </Option>
              ))}
            </Select>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          {statusData?.onReset !== undefined && (
            <Button
              className="cancel-button"
              onClick={() => {
                statusData?.onReset();
                dispatch(closeStatusModal());
              }}
            >
              {intl.formatMessage({
                id: `reset`,
              })}
            </Button>
          )}
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default StatusModal;
