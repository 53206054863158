import { FleetData } from '../../../../@types';
import { RootState } from '../../../config';
import { FleetAdminBrandData } from './initialState';

export const selectFleets = (state: RootState): Array<FleetData> | null =>
  state.appData.fleets.entities;

export const selectBrands = (
  state: RootState
): Array<FleetAdminBrandData> | null => state.appData.fleets.brands;

export const selectFleetsLoading = (state: RootState): boolean =>
  state.appData.fleets.loading;
