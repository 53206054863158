import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './BookingStatus.styles';

type Props = {
  status?:
    | 'waiting'
    | 'offered'
    | 'confirmed'
    | 'cancelled'
    | 'started'
    | 'finished';
  style?: any;
};

const BookingStatus: React.FC<Props> = ({ status, style }) => {
  return (
    <p style={style} className={status?.toLowerCase()}>
      <FormattedMessage
        id={`fleetadmin.bookingStatus.${status?.toLowerCase()}`}
      />
      <style jsx>{styles}</style>
    </p>
  );
};

BookingStatus.defaultProps = {
  status: 'finished',
  style: {},
};

export default BookingStatus;
