import { CategoryData } from '../@types';

export const getCategoryById = (
  categories: CategoryData[] | null,
  id: string
): CategoryData | undefined => {
  if (id) {
    return categories?.find((category) => category.id === id);
  }
  return undefined;
};
