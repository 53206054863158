import { createAction } from '../../../helpers';
import { TargetType } from '../../../../components/Filter/@types';
import { ViewMode } from '../../../../hooks/useViewMode';
import { FilterTypes } from '../../../../@types/enums';

export const openFilterPanel = () =>
  createAction(FilterTypes.OPEN_FILTER_PANEL);

export const closeFilterPanel = () =>
  createAction(FilterTypes.CLOSE_FILTER_PANEL);

export const setTarget = (payload: {
  target: 'string';
  targetType: TargetType;
}) => createAction(FilterTypes.SET_TARGET, payload);

export const addFilter = (payload: {
  viewMode: ViewMode;
  key: string;
  value: any;
}) => createAction(FilterTypes.ADD_FILTER, payload);

export const removeFilter = (payload: {
  viewMode: ViewMode;
  key: string;
  value: any;
}) => createAction(FilterTypes.REMOVE_FILTER, payload);
