import css from 'styled-jsx/css';

const style = css`
  .tab-wrapper :global(.ant-tabs-nav-wrap) {
    background: #f1f1f1;
  }
  .tab-wrapper :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #000 !important;
    font-family: Avenir Next;
    font-weight: 600;
  }
  .tab-wrapper :global(.ant-tabs-tab-btn:hover) {
    color: #000 !important;
  }
`;

export default style;
