import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import ReceiptDrawerBody from './ReceiptDrawerBody';
import {
  closeReceiptDrawerAndSetQueries,
  selectViewMode,
} from '../../redux/store/ui/common';
import { selectSelectedReceipt } from '../../redux/store/userData/receipts';
import { ViewMode } from '../../hooks/useViewMode';
import TripDrawer from '../TripDrawer';
import UsersDrawer from '../UsersDrawer';

const ReceiptDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const selectedReceipt = useSelector(selectSelectedReceipt);
  const visible = selectedReceipt != null;

  const handleOnClose = () => {
    dispatch(closeReceiptDrawerAndSetQueries());
  };

  return (
    selectedReceipt && (
      <DrawerLayout
        visible={visible}
        title={selectedReceipt?.id!}
        body={<ReceiptDrawerBody />}
        nestedDrawers={
          <>
            {viewMode !== ViewMode.SHARE && <TripDrawer />}
            {viewMode !== ViewMode.USERS && <UsersDrawer />}
          </>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default ReceiptDrawer;
