import React, { useState } from 'react';
import { Input, Checkbox } from 'antd';
import styles from './Selection.styles';

type SelectionProps = {
  options: any[];
  defaultValue?: any[];
  withInput?: boolean;
  setValue: (arg: any) => void;
};

const Selection: React.FC<SelectionProps> = ({
  options,
  defaultValue,
  withInput = true,
  setValue,
}) => {
  const [query, setQuery] = useState<string>('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((o) => {
          const lowerCaseLabel =
            typeof o.label === 'string' ? o.label.toLowerCase() : '';

          return lowerCaseLabel.includes(query);
        });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  return (
    <>
      <div className="selection">
        {withInput && (
          <div className="input-wrapper">
            <Input placeholder="Search" onChange={onChange} />
          </div>
        )}
        <div className="checkbox-wrapper">
          <Checkbox.Group
            options={filteredOptions}
            onChange={setValue}
            defaultValue={defaultValue}
          />
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Selection;
