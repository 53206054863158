import React from 'react';
import cn from 'classnames';
import styles from './VINCell.styles';

type VINCellProps = {
  vin: string;
  isNew: boolean;
};

const VINCell: React.FC<VINCellProps> = ({ vin, isNew }) => (
  <>
    <div className={cn('wrapper', { new: isNew })}>
      {isNew && <p className="pop">NEW</p>}
      <span className="VIN">{vin}</span>
    </div>
    <style jsx>{styles}</style>
  </>
);

export default VINCell;
