import getFleetNameById from '../../utils/getFleetNameById';
import getAreaNameById from '../../utils/getAreaNameById';
import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDateTime,
  getRenderLink,
} from '../shared/TableLayout';
import CareStatus from '../../components/shared/CareStatus';
import serviceTypes from '../../assets/serviceTypes';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import isDesktop from '../../utils/sizeHelpers';

type getColumnsArgs = {
  fleets: any;
  areas: any;
};

const paddingNum = (num: number) => String(num).padStart(2, '0');

const renderServiceType =
  (serviceType: string) =>
  // eslint-disable-next-line react/display-name
  (_: any, record: { serviceTypes: string | string[] }) => {
    return (
      <span
        style={{
          width: '100%',
          display: 'inline-block' as 'inline-block',
          textAlign: 'center' as 'center',
        }}
      >
        {record.serviceTypes.includes(serviceType) ? 1 : 0}
      </span>
    );
  };

const getServiceTypeColumn = (serviceType: string) => {
  return {
    title: <FormattedMessage id={`fleetadmin.serviceType.${serviceType}`} />,
    dataIndex: serviceType,
    render: renderServiceType(serviceType),
    width: 180,
    sorter: (careA: any, careB: any) => {
      const selector = (care: { serviceTypes: string | string[] }) =>
        care.serviceTypes.includes(serviceType) ? 1 : 0;

      return selector(careA) - selector(careB);
    },
  };
};

const getColumns = ({ fleets, areas }: getColumnsArgs): any[] => {
  const renderStatus = (status: string) => <CareStatus status={status} />;

  const renderFleetName = (fleetId: any) => (
    <span>{getFleetNameById(fleets, fleetId) || '-'}</span>
  );

  const renderAreaName = (areaId: unknown) => (
    <span>{getAreaNameById(areas, areaId) || '-'}</span>
  );

  const renderDuration = (
    _: any,
    service: { tripEnd: moment.MomentInput; tripStart: moment.MomentInput }
  ) => {
    if (!service.tripEnd || !service.tripStart) {
      return <span>-</span>;
    }

    const duration = moment.duration(
      moment(service.tripEnd).diff(service.tripStart)
    );

    const formattedDuration = `${paddingNum(
      Math.floor(duration.asHours())
    )}:${paddingNum(duration.minutes())}`;

    return <span>{formattedDuration}</span>;
  };

  const renderDistance = (distance: number | null) => {
    return <span>{distance != null ? `${distance.toFixed(1)} km` : '-'}</span>;
  };

  const renderServiceCount = (serviceTypes: string | any[]) => {
    return (
      <span
        style={{
          width: '100%',
          display: 'inline-block' as 'inline-block',
          textAlign: 'center' as 'center',
        }}
      >
        {serviceTypes.length}
      </span>
    );
  };

  const serviceTypeColumns = serviceTypes?.map((serviceType) =>
    getServiceTypeColumn(serviceType)
  );

  return [
    {
      title: 'Trip ID',
      dataIndex: 'code',
      render: getRenderLink(),
      ellipsis: true,
      width: isDesktop() ? 120 : 100,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('code'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      width: 150,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: 'Plate',
      dataIndex: 'plate',
      width: 150,
      sorter: alphabeticalSorterFor('plate'),
    },
    {
      title: 'Fleet',
      dataIndex: 'fleetId',
      render: renderFleetName,
      width: 150,
      ellipsis: true,
      sorter: (careA: { fleetId: any }, careB: { fleetId: any }) => {
        const A = getFleetNameById(fleets, careA.fleetId) || '-';
        const B = getFleetNameById(fleets, careB.fleetId) || '-';
        return alphabeticalSorter(A, B);
      },
    },
    {
      title: 'Area',
      dataIndex: 'areaId',
      render: renderAreaName,
      width: 150,
      ellipsis: true,
      sorter: (careA: { areaId: unknown }, careB: { areaId: unknown }) => {
        const A = getAreaNameById(areas, careA.areaId) || '-';
        const B = getAreaNameById(areas, careB.areaId) || '-';
        return alphabeticalSorter(A, B);
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      width: 200,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('createdAt'),
    },
    {
      title: 'Accepted at',
      dataIndex: 'reservedAt',
      width: 200,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('reservedAt'),
    },
    {
      title: 'Start',
      dataIndex: 'tripStart',
      width: 200,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('tripStart'),
    },
    {
      title: 'End',
      dataIndex: 'tripEnd',
      width: 200,
      ellipsis: true,
      render: renderDateTime,
      sorter: chronologicalSorterFor('tripEnd'),
    },
    {
      title: 'Duration',
      dataIndex: 'tripDuration',
      width: 150,
      ellipsis: true,
      render: renderDuration,
      sorter: (careA: any, careB: any) => {
        const selector = (care: {
          tripEnd: moment.MomentInput;
          tripStart: moment.MomentInput;
        }) => {
          if (!care.tripEnd || !care.tripStart) {
            return 0;
          }

          const duration = moment
            .duration(moment(care.tripEnd).diff(care.tripStart))
            .asMilliseconds();

          return duration;
        };
        return selector(careA) - selector(careB);
      },
    },
    {
      title: 'Distance',
      dataIndex: 'tripDistance',
      width: 150,
      ellipsis: true,
      render: renderDistance,
      sorter: (
        careA: { tripDistance: number },
        careB: { tripDistance: number }
      ) => {
        return careA.tripDistance - careB.tripDistance;
      },
    },
    {
      title: 'Service Hero',
      dataIndex: 'userReferralCode',
      width: 150,
      ellipsis: true,
      sorter: alphabeticalSorterFor('userReferralCode'),
    },
    {
      title: 'Brand',
      dataIndex: 'brandCode',
      width: 150,
      sorter: alphabeticalSorterFor('brandCode'),
    },
    {
      title: 'VIN',
      dataIndex: 'chassisNumber',
      width: 200,
      sorter: alphabeticalSorterFor('chassisNumber'),
    },
    {
      title: 'Service Count',
      dataIndex: 'serviceTypes',
      render: renderServiceCount,
      width: 150,
      sorter: (careA: any, careB: any) => {
        const selector = (care: { serviceTypes: any }) => {
          const serviceTypes = care.serviceTypes;
          return serviceTypes.length;
        };

        return selector(careA) - selector(careB);
      },
    },
    {
      title: 'Back by',
      dataIndex: 'backBy',
      width: 200,
      sorter: chronologicalSorterFor('backBy'),
    },

    {
      title: 'Pickup location',
      dataIndex: 'pickupLocation',
      width: 200,
    },
    // @ts-ignore
    ...serviceTypeColumns,
  ];
};

export default getColumns;
