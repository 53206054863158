import React, { ChangeEvent } from 'react';
import { Input } from 'antd';
import styles from './TextInput.styles';

type TextInputProps = {
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TextInput: React.FC<TextInputProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  return (
    <>
      <div className="text-input-wrapper">
        <p className="text-input-label">{placeholder}</p>
        <Input value={value} placeholder={placeholder} onChange={onChange} />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TextInput;
