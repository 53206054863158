import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import message from '../utils/message';

const ForgotPasswordForm = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    fetchForgot(values.email);
  };

  const fetchForgot = async (email: string | number | boolean) => {
    setLoading(true);

    try {
      const res = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/fleets/admin/forgot?email=${encodeURIComponent(email)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
          },
        }
      );
      if (!res.ok) {
        const text = await res.text();
        throw new Error(text);
      }
      message.success(
        intl.formatMessage({ id: 'fleetadmin.forgot.success' }, { email })
      );
    } catch (err) {
      message.error(intl.formatMessage({ id: 'fleetadmin.forgot.fail' }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="email"
        noStyle
        rules={[
          { required: true, message: 'email required' },
          {
            type: 'email',
            message: intl.formatMessage({
              id: 'fleetadmin.validation.invalidEmail',
            }),
          },
        ]}
      >
        <Input
          name="email"
          placeholder={intl.formatMessage({
            id: 'fleetadmin.forgot.form.placeholder',
          })}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: '100%', marginTop: 16 }}
        >
          {!loading && <FormattedMessage id="fleetadmin.forgot.button" />}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default ForgotPasswordForm;
