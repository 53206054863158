import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setQueries } from '../../libs/setQueries';
import TableLayout from '../../components/shared/TableLayout';
import getColumns from './getColumns';
import {
  selectTrip,
  selectDisplayTrips,
  selectTotalTrips,
  selectSelectedTrip,
  fetchTrips,
} from '../../redux/store/userData/trips';
import { fetchVehicleEventsForTrip } from '../../redux/store/userData/vehicleEvents';
import { selectDisplayColumnsForTrip } from '../../redux/store/ui/common';
import { fetchTripInvoiceData } from '../../redux/store/userData/trips/thunk';
import { useQuery } from '../../hooks/useQuery';
import withPaginatedQueryFetch from '../../hoc/withPaginatedQueryFetch';
import { getFilteredColumns } from '../../utils';

export const TRIP_DURATION_MARGIN = 60000;

const TripsTable = (props) => {
  const queries = useQuery();
  const dispatch = useDispatch();
  const displayTrips = useSelector(selectDisplayTrips);
  const tripsNo = useSelector(selectTotalTrips);
  const selectedTrip = useSelector(selectSelectedTrip);
  const displayColumns = useSelector(selectDisplayColumnsForTrip);
  const columns = getColumns();

  const selectedId = selectedTrip?.id || null;

  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record) => {
    const { id, carId, reservedAt, tripEnd } = record;

    batch(() => {
      dispatch(selectTrip(record));
      dispatch(fetchTripInvoiceData(id));
      dispatch(fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd));
    });

    const query = {
      ...queries,
      tripId: record.id,
    };
    setQueries(query);
  };

  return (
    <TableLayout
      rowKey="trip"
      columns={filteredColumns}
      dataSource={displayTrips}
      selectedId={selectedId}
      totalNumber={tripsNo}
      onClickRow={onClickRow}
      current={props.pageNumber}
      onPageChanged={props.onPageChanged}
    />
  );
};

export default withPaginatedQueryFetch(TripsTable, fetchTrips);
