import { Target } from '../@types';

const targetsForCircle: Target[] = [
  { value: 'name', type: 'text', isAPI: false },
  { value: 'circleType', type: 'text', isAPI: false },
  { value: 'phoneVerificationRequired', type: 'boolean', isAPI: false },
  { value: 'addressRequired', type: 'boolean', isAPI: false },
  { value: 'paymentMethodRequired', type: 'boolean', isAPI: false },
  { value: 'driverLicenseRequired', type: 'boolean', isAPI: false },
  { value: 'identityApprovalRequired', type: 'boolean', isAPI: false },
];

export default targetsForCircle;
