import { requestAPI } from '../../../../utils/request';
import {
  createProfileFailure,
  createProfileRequest,
  createProfileSuccess,
  deleteImageFailure,
  deleteImageRequest,
  deleteImageSuccess,
  profileFailure,
  profileRequest,
  profilesFailure,
  profilesRequest,
  profilesSuccess,
  profileSuccess,
  setDisplayProfiles,
  updateProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
  uploadImageFailure,
  uploadImageRequest,
  uploadImageSuccess,
} from './actions';
import message from '../../../../utils/message';
import { VehicleProfileInfoInputData } from '../../../../@types';

const profilesPath = 'fleets/cars/profiles';
export const fetchProfiles = () => (dispatch: any, getState: any) => {
  const shouldFetchProfiles = !getState()?.userData.profiles?.loading;

  if (shouldFetchProfiles) {
    dispatch(profilesRequest());
    return requestAPI(`/${profilesPath}`)
      .then((profiles) => {
        dispatch(profilesSuccess(profiles));
        dispatch(setDisplayProfiles(profiles));
      })
      .catch((err) => {
        dispatch(profilesFailure(err));
        message.error(err.message);
      });
  } else {
    return Promise.resolve();
  }
};

export const fetchProfileById =
  (profileId: string) => (dispatch: any, getState: any) => {
    const shouldFetchProfile = !getState().userData.profiles?.loadingProfile;

    if (shouldFetchProfile) {
      dispatch(profileRequest());
      requestAPI(`${profilesPath}/${profileId}`)
        .then((profile) => {
          if (profile) {
            dispatch(profileSuccess(profile));
          } else {
            dispatch(profilesFailure({ name: 'No Data', message: 'No Data' }));
          }
        })
        .catch((err) => {
          dispatch(profileFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const createProfile =
  (profileInputData: VehicleProfileInfoInputData) =>
  (dispatch: any, getState: any) => {
    const shouldCreateProfile = !getState().userData.profiles?.creatingProfile;

    if (shouldCreateProfile) {
      dispatch(createProfileRequest());
      requestAPI(
        `/${profilesPath}`,
        {},
        {
          method: 'POST',
          body: JSON.stringify(profileInputData),
        }
      )
        .then((profile) => {
          if (profile) {
            dispatch(createProfileSuccess(profile));
          }
          dispatch(fetchProfiles());
        })
        .catch((err) => {
          dispatch(createProfileFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const updateProfile =
  (profileId: string, profileInputData: VehicleProfileInfoInputData) =>
  (dispatch: any, getState: any) => {
    const shouldUpdateProfile = !getState().userData.profiles?.updatingProfile;

    if (shouldUpdateProfile) {
      dispatch(updateProfileRequest());
      requestAPI(
        `/${profilesPath}/${profileId}`,
        {},
        {
          method: 'PUT',
          body: JSON.stringify(profileInputData),
        }
      )
        .then((profile) => {
          if (profile) {
            dispatch(updateProfileSuccess(profile));
          }
        })
        .catch((err) => {
          dispatch(updateProfileFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const uploadProfileImage =
  (profileId: string, file: any) => (dispatch: any, getState: any) => {
    const { token } = getState().userData.user;
    const shouldUploadImage = !getState().userData.profiles?.uploadingImage;

    if (shouldUploadImage) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(uploadImageRequest());
      requestAPI(
        `/${profilesPath}/${profileId}/images`,
        {},
        {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
        }
      )
        .then((profile) => {
          message.success('Uploaded!');
          dispatch(uploadImageSuccess(profile));
        })
        .catch((err) => {
          dispatch(uploadImageFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };

export const deleteProfileImage =
  (profileId: string, imageKey: string) => (dispatch: any, getState: any) => {
    const shouldDeleteImage = !getState().userData.profiles?.deletingImage;

    if (shouldDeleteImage) {
      dispatch(deleteImageRequest());
      requestAPI(
        `/${profilesPath}/${profileId}/images/${imageKey}`,
        {},
        {
          method: 'DELETE',
        }
      )
        .then((profile) => {
          message.success('Image Removed!');
          dispatch(deleteImageSuccess(profile));
        })
        .catch((err) => {
          dispatch(deleteImageFailure(err));
          message.error(err.message);
        });
    } else {
      return Promise.resolve();
    }
  };
