import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css`
  .applied-filter-wrapper {
    margin-left: 20px;
    max-width: 1200px;
    padding: 0px 0;
    position: relative;
    z-index: ${zIndex.appliedFilterTag};
  }
  .applied-filter-wrapper.fixed {
    position: fixed;
    top: 116px;
    width: calc(100vw);
  }
  @media only screen and (min-width: 768px) {
    .applied-filter-wrapper {
      margin-left: 20px;
      max-width: 1200px;
      padding: 4px 0;
      position: relative;
      z-index: ${zIndex.appliedFilterTag};
    }
    .applied-filter-wrapper.fixed {
      position: fixed;
      top: 116px;
      width: calc(100vw - 200px);
    }
  }
`;

export default style;
