import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const styles = css`
  .wrapper {
    margin-top: 0px;
    padding: 0 8px;
    margin-bottom: 24px;
  }
  :global(.ant-table-expand-icon-th) {
    width: 35px !important;
    min-width: 20px !important;
    padding: 0 !important;
  }
  :global(.ant-table-row-expand-icon-cell) {
    width: 35px !important;
    min-width: 20px !important;
    padding: 0 !important;
  }
  :global(.table-layout),
  :global(.ant-table-thead > tr > th) {
    background: #f2f2f2;
  }
  :global(.ant-table-thead > tr > th) {
    white-space: break-spaces;
  }
  :global(.table-layout .ant-pagination.ant-table-pagination) {
    float: none;
    margin: 8px;
  }
  :global(.table-layout .selected-row) {
    background: ${colors.highlight.selected};
  }
  :global(.table-layout .ant-table-row.ant-table-row-hover > td) {
    background: ${colors.highlight.hovered} !important;
  }
  :global(.ant-skeleton) {
    background-color: white;
  }
  :global(.ant-table-column-has-sorters) {
    padding: 8px !important;
  }
  .loaderContainer {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  :global(.grecaptcha-badge) {
    visibility: hidden;
  }
  @media only screen and (min-width: 768px) {
    .wrapper {
      margin-top: 8px;
      padding: 16px 8px;
    }
    :global(.table-layout .ant-pagination.ant-table-pagination) {
      float: none;
      margin: 16px;
    }
    .loaderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    :global(.ant-table-column-has-sorters) {
      padding: 16px 8px !important;
    }
  }
`;

export default styles;
