import { FleetAdminCarData } from '../@types';

export const getCarsByCategoryId = (
  cars: FleetAdminCarData[] | null,
  categoryId: string
) => {
  return cars?.filter((car) => car.categoryId === categoryId);
};

const getNumOfCarsByCategoryId = (
  cars: FleetAdminCarData[] | null,
  categoryId: string
) => {
  const theCars = getCarsByCategoryId(cars, categoryId);
  return theCars?.length;
};

export default getNumOfCarsByCategoryId;
