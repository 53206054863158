import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import message from '../utils/message';
import { PASSWORD_PATTERN } from '../libs/constraints';
import { loginSuccess } from '../redux/store/userData/user';
import { useQuery } from '../hooks/useQuery';
import { navigateTo } from '../utils';

const Buffer = require('buffer/').Buffer;

const SetPassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const queries = useQuery();
  const { token } = queries;
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    fetchSetPassword({ password: values.password, token });
  };
  // @ts-ignore
  const fetchSetPassword = async ({ password, token }) => {
    setLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/fleets/admin/forgot`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
          },
          body: JSON.stringify({
            password,
            token,
          }),
        }
      );
      const data = await res.json();
      // eslint-disable-next-line no-prototype-builtins
      if (!res.ok && data?.hasOwnProperty('violations')) {
        Object.values(data.violations).forEach((value) => {
          message.error(intl.formatMessage({ id: String(value) }));
        });
      } else {
        message.success(
          intl.formatMessage({ id: 'fleetadmin.password.success' })
        );
        const key = `${data.email}:${password}`;
        const auth = `Basic ${Buffer.from(key).toString('base64')}`;
        window.location.href = '/dashboard';
        setTimeout(() => dispatch(loginSuccess({ ...data, token: auth })), 50);
      }
    } catch (err) {
      message.error(intl.formatMessage({ id: 'fleetadmin.password.fail' }));
    } finally {
      setLoading(false);
    }
  };

  const validateConfirmPassword = async (_, value) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(
        new Error(
          intl.formatMessage({ id: 'fleetadmin.validation.notIdentical' })
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'fleetadmin.validation.required',
            }),
          },
          {
            pattern: PASSWORD_PATTERN,
            message: intl.formatMessage({
              id: 'fleetadmin.validation.invalidPassword',
            }),
          },
        ]}
        hasFeedback
      >
        <Input.Password
          placeholder={intl.formatMessage({
            id: 'fleetadmin.password.form1.placeholder',
          })}
          style={{ marginBottom: 16 }}
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'fleetadmin.validation.required',
            }),
          },
          {
            validator: validateConfirmPassword,
            message: intl.formatMessage({
              id: 'fleetadmin.validation.notIdentical',
            }),
          },
        ]}
        hasFeedback
      >
        <Input.Password
          placeholder={intl.formatMessage({
            id: 'fleetadmin.password.form2.placeholder',
          })}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: '100%', marginTop: 16 }}
        >
          {!loading && <FormattedMessage id="fleetadmin.password.button" />}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default SetPassword;
