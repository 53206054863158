import css from 'styled-jsx/css';

const style = css`
  .detail-wrapper {
    margin: 0 24px;
  }
  .care-info-finish-button {
    margin: 24px 24px 60px;
    color: red;
    cursor: pointer;
  }
`;

export default style;
