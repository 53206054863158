const receiptsColumns = [
  { label: 'Receipt No,', value: 'receiptNo', disabled: true },
  { label: 'Type', value: 'receiptType', disabled: false },
  { label: 'Action', value: 'receiptUrl', disabled: false },
  {
    label: 'Merchant Reference',
    value: 'receiptMerchantReference',
    disabled: false,
  },
  { label: 'Receipt Date', value: 'receiptDate', disabled: false },
  { label: 'User code', value: 'userCode', disabled: false },
  {
    label: 'Merchant reference',
    value: 'merchantUserReference',
    disabled: false,
  },
  { label: 'Trip Code', value: 'tripCode', disabled: false },
  { label: 'Trip Reference', value: 'tripReference', disabled: false },
  { label: 'Vat Rate', value: 'vatRate', disabled: false },
  { label: 'Vat Amount', value: 'vatAmount', disabled: false },
  { label: 'Gross Amount', value: 'grossAmount', disabled: false },
  { label: 'Currency', value: 'currency', disabled: false },
  { label: 'Collection', value: 'collection', disabled: false },
  { label: 'Created By', value: 'createdBy', disabled: false },
];

export const defaultSelectedReceiptsColumns = receiptsColumns.map(
  (column) => column.value
);

export default receiptsColumns;
