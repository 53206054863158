import css from 'styled-jsx/css';

const style = css`
  .gallery-wrapper {
    min-height: 100%;
    padding: 24px;
  }
  .no-img-message {
    text-align: center;
  }
  .image-wrapper {
    margin-bottom: 8px;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default style;
