import css from 'styled-jsx/css';

const style = css`
  .table-search {
    padding: 8px;
  }
  .table-search :global(.input) {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }
  .table-search :global(.confirm-button) {
    width: 90px;
    margin-right: 8px;
  }
  .table-search :global(.reset-button) {
    width: 90px;
  }
`;

export default style;
