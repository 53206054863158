import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const textStyles = css`
  .text {
    margin: 0;
  }
  .text :global(a) {
    color: ${colors.blue};
  }
  .text.link {
    color: ${colors.blue};
    cursor: pointer;
  }
`;

export const userCodeStyles = css`
  p {
    font-size: 16px;
    margin: 0;
    font-style: italic;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
`;
