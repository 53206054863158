import { RootState } from '../../../config';
import { DamageEntityData, FleetRentalData } from '../../../../@types';

export const selectClaims = (state: RootState): DamageEntityData[] | null =>
  state.userData.claims.entities;

export const selectDisplayClaims = (
  state: RootState
): DamageEntityData[] | null => state.userData.claims.display;

export const selectTotalClaims = (state: RootState): number | null =>
  state.userData?.claims?.totalDamages;

export const selectSelectedClaim = (
  state: RootState
): DamageEntityData | null => state.userData.claims.selected;

export const selectAssignableClaimTrips = (
  state: RootState
): FleetRentalData[] | null => state.userData.claims.assignableClaimTrips;

export const selectIsClaimUpdating = (state: RootState): boolean =>
  state.userData.claims.isUpdating;

export const selectIsLocadingCarClaims = (state: RootState): boolean =>
  state.userData.claims.isLoadingCarClaim;

export const selectTotalDamages = (state: RootState): number =>
  state.userData.claims.totalDamages;

export const selectClaimsLoading = (state: RootState): boolean =>
  state.userData.claims.loading;
