const adminsColumns = [
  { label: 'Email', value: 'email', disabled: true },
  { label: 'First name', value: 'firstName', disabled: false },
  { label: 'Last name', value: 'lastName', disabled: false },
  { label: 'Status', value: 'blocked', disabled: false },
  { label: 'Created at', value: 'created', disabled: false },
  { label: 'Access', value: 'fullAccess', disabled: false },
  { label: 'Fleets', value: 'fleets', disabled: false },
];

export const defaultSelectedAdminsColumns = adminsColumns.map(
  (adminColumn) => adminColumn.value
);

export default adminsColumns;
