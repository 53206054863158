import css from 'styled-jsx/css';

const style = css`
  .floating-plus {
    position: absolute;
    bottom: 20px;
    right: 80px;
    z-index: 100;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06),
      0 2px 32px 0 rgba(0, 0, 0, 0.16);
    transform: rotate(0deg) scale(1);
    transition: transform 0.2s ease-in-out;
  }
  .floating-plus:hover {
    transform: scale(1.1);
  }
`;

export default style;
