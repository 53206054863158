import { Target } from '../@types';

const targetsForCare: Target[] = [
  { value: 'id', type: 'text', isAPI: false },
  { value: 'code', type: 'text', isAPI: false },
  { value: 'status', type: 'selection', isAPI: false },
  { value: 'plate', type: 'text', isAPI: false },
  { value: 'fleetId', type: 'selection', isAPI: false },
  { value: 'tripStart', type: 'date', isAPI: false },
  { value: 'tripEnd', type: 'date', isAPI: false },
  { value: 'brand', type: 'text', isAPI: false },
  { value: 'chassisNumber', type: 'text', isAPI: false },
  { value: 'areaId', type: 'text', isAPI: false },
  { value: 'userReferralCode', type: 'text', isAPI: false },
  { value: 'serviceTypes', type: 'selection', isAPI: false },
];

export default targetsForCare;
