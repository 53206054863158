import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .input-wrapper {
    padding: 16px 16px 0;
  }
  .checkbox-wrapper {
    padding: 16px 16px 0;
    height: 100%;
    overflow-y: scroll;
  }
  .selection :global(.ant-checkbox-group) {
    display: flex !important;
    flex-direction: column !important;
  }
  .selection :global(.ant-checkbox-group-item) {
    font-size: 15px !important;
    margin-bottom: 10px;
  }
  .selection :global(.ant-checkbox-checked .ant-checkbox-inner) {
    ${common.checkbox}
  }
  .selection
    :global(
      .ant-checkbox-group-item:hover .ant-checkbox-checked .ant-checkbox-inner
    ) {
    ${common.checkbox}
  }
  .selection
    :global(.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner) {
    ${common.checkboxDisabled}
  }
`;

export default style;
