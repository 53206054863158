import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper.isInsideTrip {
    border-right: 2px solid #000;
    border-left: 2px solid #000;
  }
  .bar {
    width: 18px;
    height: 16px;
  }
  .isInsideTrip .bar {
    width: 16px;
  }
  .first.bar {
    margin-bottom: 6px;
    border-right: 1px solid ${colors.border};
  }
  .first.bar.hidden {
    border-right: none;
  }
  .second.bar {
    margin-top: 6px;
    border-right: 1px solid ${colors.border};
  }
  .time-tag {
    display: inline-block;
    align-items: center;
    height: 20px;
    margin: 0;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: ${colors.white};
    background: ${colors.border};
    border-radius: 10px;
  }
`;

export default style;
