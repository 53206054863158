import { setQueries } from '../libs/setQueries';
import { useQuery } from './useQuery';

export enum ViewType {
  MAP = 'MAP',
  TABLE = 'TABLE',
  TIME_TABLE = 'TIME_TABLE',
}

const useViewType = () => {
  const query = useQuery();

  if (Array.isArray(query.type)) {
    throw new Error('vm must be string');
  }
  // @ts-ignore
  const viewType = ViewType[query.type];

  const setViewType = (viewType: any) => {
    setQueries({
      type: viewType,
    });
  };

  const initViewType = () => {
    if (
      query.type !== ViewType.TABLE &&
      query.type !== ViewType.MAP &&
      query.type !== ViewType.TIME_TABLE
    ) {
      return { type: ViewType.TABLE };
    } else {
      null;
    }
  };

  return {
    viewType,
    setViewType,
    initViewType,
  };
};

export default useViewType;
