import React, { useState } from 'react';
import styles from './UsersDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import UserInfo from './UserInfoTab/UserInfo';
import UserStatus from './UserStatusTab/UserStatus';
import { useIntl } from 'react-intl';
import UserTimeline from './UserTimeline/UserTimeline';
import { UserDataOutput } from '../../@types';
import UserWallet from './UserWalletTab/UserWallet';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/store/userData/user';
type Props = {
  selectedUser: UserDataOutput;
};
const UsersDrawerBody = ({ selectedUser }: Props) => {
  const intl = useIntl();
  const user = useSelector(selectAuth);
  const [activeTab, setActiveTab] = useState(0);

  const onClickTariffFile = () => {
    setActiveTab(2);
  };

  const tabs = [
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.status`,
          })}
        </span>
      ),
      content: <UserStatus selectedUser={selectedUser} />,
    },
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.info`,
          })}
        </span>
      ),
      content: (
        <UserInfo
          selectedUser={selectedUser}
          onClickTariffFile={onClickTariffFile}
        />
      ),
    },
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.wallet`,
          })}
        </span>
      ),
      content: <UserWallet selectedUser={selectedUser} />,
    },
    {
      title: (
        <span>
          {intl.formatMessage({
            id: `fleetadmin.users.tab.timeline`,
          })}
        </span>
      ),
      content: <UserTimeline selectedUser={selectedUser} />,
    },
  ];

  return (
    <>
      <div className="users-drawer-body">
        <div className="users-name-wrapper">
          <p className="name">
            {selectedUser?.userType === 'org'
              ? `${selectedUser?.companyName} ${
                  selectedUser?.legalType || selectedUser?.otherLegalType
                }`
              : `${selectedUser?.firstName} ${selectedUser?.lastName}`}
          </p>
        </div>
        <TabLayout
          tabs={tabs}
          keyName="users-drawer"
          activeTab={activeTab}
          onChangeTab={(activeKey) => {
            setActiveTab(parseInt(activeKey.split('-').pop() || '0', 10));
          }}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UsersDrawerBody;
