import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import AppliedFiltersTag from './AppliedFiltersTag';
import { ViewType } from '../../hooks/useViewType';
import styles from './AppliedFilters.styles';
import { setAppliedFiltersHeight } from '../../redux/store/ui/common';
import { selectFilters } from '../../redux/store/ui/filters';

const AppliedFilters: React.FC = ({ viewMode, viewType }: any) => {
  const dispatch = useDispatch();
  const AllFilters = useSelector(selectFilters) as any;
  const filters = AllFilters[viewMode] || null;

  useEffect(() => {
    if (filters && Object.values(filters).length > 5) {
      dispatch(setAppliedFiltersHeight(72));
    } else if (filters && Object.values(filters).length > 0) {
      dispatch(setAppliedFiltersHeight(40));
    } else {
      dispatch(setAppliedFiltersHeight(8));
    }
  }, [filters, dispatch]);

  return (
    <>
      <div
        className={cn('applied-filter-wrapper', {
          fixed: viewType === ViewType.MAP,
        })}
      >
        {filters &&
          Object.entries(filters).map(([key, value], id) => {
            if (Array.isArray(value)) {
              return value.map((v, i) => (
                <AppliedFiltersTag
                  key={`applied-filter-tag-${id}-${i}`}
                  viewMode={viewMode}
                  label={key}
                  value={v}
                />
              ));
            } else {
              return (
                <AppliedFiltersTag
                  key={`applied-filter-tag-${id}`}
                  viewMode={viewMode}
                  label={key}
                  value={value}
                />
              );
            }
          })}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AppliedFilters;
