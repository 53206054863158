import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .finish-trip-modal {
    width: 380px;
    padding: 16px;
  }
  .finish-trip-modal-body-wrapper {
    margin-bottom: 8px;
  }
  .finish-trip-label {
    font-family: AvenirNext, serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .finish-trip-description {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .finish-trip-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .finish-trip-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
`;

export default style;
