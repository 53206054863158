import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import { selectToken } from '../../../redux/store/userData/user/selectors';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import styles from './BulkCreateUsers.styles';
import { colors } from '../../../theme/theme';
import { fetchUsers } from '../../../redux/store/userData/users';

const { Dragger } = Upload;

type PhoneReportModalProps = {
  visible?: boolean;
};

const BulkCreateUsers: React.FC<PhoneReportModalProps> = ({ visible }) => {
  const intl = useIntl();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const _visible = useSelector(getIsVisibleModal('UsersCreateModal'));
  const [fileList, setFileList] = useState([]);
  const [erroredRows, setErroredRows] = useState(null);
  const isDisabled = fileList.length === 0;

  const handleCancel = () => {
    dispatch(closeModal('UsersCreateModal'));
  };

  const uploadProps = {
    name: 'file',
    accept: '.csv',
    fileList,
    multiple: false,
    customRequest: (options) => {
      const { onSuccess } = options;
      setErroredRows(null);
      onSuccess();
    },
    showUploadList: true,
    onChange(info) {
      setFileList(info.fileList.filter((file) => !!file.status));
    },
  };

  const handleOnSubmit = async () => {
    const file = fileList[0].originFileObj;
    let parsedCsvRows = null;
    await Papa.parse(file, {
      complete: (results) => (parsedCsvRows = results),
    });
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/fleets/users/csv`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: formData,
      }
    );
    const data = await response.json();
    if (response.ok) {
      if (data.addedTransactions > 0) {
        message.success(
          `${data.addedTransactions} users uploaded successfully.`
        );
      }
      if (data?.errors?.entry?.length === 0) {
        dispatch(fetchUsers(0, 50, () => {}));
      }
      setFileList([]);
      if (data?.errors?.entry?.length > 0 && parsedCsvRows) {
        // Start the erroredRows array with the column names
        const erroredRows = [parsedCsvRows.data[0].concat(['error'])];
        // Create a new array of errored rows based on the error entries in the response
        await data.errors.entry.forEach((errorEntry) => {
          let errorIndex = parseInt(errorEntry.key.split(':')[0], 10);
          // Set errorIndex to 0 if it is negative
          if (errorIndex < 0) {
            errorIndex = 0;
          }
          // Adjust for the column names at index 0
          const errorRow = parsedCsvRows.data[errorIndex + 1];
          if (errorRow) {
            // Add the error message to the row and push it
            erroredRows.push(errorRow.concat([errorEntry.value]));
          }
        });
        setErroredRows(erroredRows);
      } else {
        setTimeout(() => handleCancel(), 1000);
      }
    } else {
      message.error(`${file.name} file upload failed.`);
    }
  };

  const onDownloadPress = (event: any, done: () => void) => {
    done();
  };
  useEffect(() => {
    setFileList([]);
    setErroredRows(null);
  }, [_visible]);

  return (
    <>
      <div>
        <div className="upload-wrapper">
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {intl.formatMessage({
                id: 'fleetadmin.receipts.upload.title',
              })}
            </p>
            <p className="ant-upload-hint">
              {intl.formatMessage({
                id: 'fleetadmin.receipts.upload.description',
              })}
            </p>
          </Dragger>
        </div>

        {erroredRows?.length > 0 && (
          <CSVLink
            filename="failed-users.csv"
            // @ts-ignore
            data={erroredRows}
            asyncOnClick
            onClick={onDownloadPress}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'red',
              margin: '12px 24px 0 0',
            }}
          >
            <DownloadOutlined style={{ color: 'red', marginRight: 5 }} />
            {`${erroredRows?.length - 1} ${intl.formatMessage({
              id: 'fleetadmin.receipts.failed.msg',
            })}`}
          </CSVLink>
        )}

        <div className="upload-users-button-container">
          <Button
            className="submit-button"
            style={isDisabled ? { background: colors.buttonDisabled } : {}}
            onClick={handleOnSubmit}
            disabled={isDisabled}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
        </div>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default BulkCreateUsers;
