import { ErrorResponse } from '../../../../@types';
import initialTexts from './initialState.admin';

export interface TextsState {
  entities: any;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState = {
  entities: initialTexts,
  error: null,
  loading: false,
};
