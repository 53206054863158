import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { circleAdminsStyles, loadingStyles } from './CircleAdmins.styles';
import {
  removeAdminFromCircle,
  selectLoadingCircle,
  selectSelectedCircle,
} from '../../../redux/store/userData/circles';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { openModal } from '../../../redux/store/ui/common';
import CircleUsersTable from '../CircleUsersTable';

const CircleAdmins = () => {
  const dispatch = useDispatch();
  const selectedCircle = useSelector(selectSelectedCircle);
  const loadingCircle = useSelector(selectLoadingCircle);

  const onRemoveAdminClicked = (adminId: any) => {
    dispatch(removeAdminFromCircle(selectedCircle?.id!, adminId));
  };

  const onAddAdminClicked = () => {
    dispatch(openModal('addAdminToCircleModal'));
  };

  if (loadingCircle) {
    return (
      <>
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  return (
    <>
      <div className="add-user-button-wrapper">
        <div className="add-user-button" onClick={onAddAdminClicked}>
          <p className="button-text">Add admin</p>
        </div>
      </div>
      <div className="record-wrapper">
        <CircleUsersTable
          dataSource={selectedCircle?.admins!}
          onRemoveUserClicked={onRemoveAdminClicked}
          isAdminTable
        />
      </div>
      <style jsx>{circleAdminsStyles}</style>
    </>
  );
};

export default CircleAdmins;
