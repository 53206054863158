import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import ProfileEditDrawerBody from './ProfileEditDrawerBody';
import { closeProfileDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedProfile } from '../../redux/store/userData/profiles';

const ProfileEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedProfile = useSelector(selectSelectedProfile);
  const visible = selectedProfile != null;

  const handleOnClose = () => {
    dispatch(closeProfileDrawerAndSetQueries());
  };

  return (
    selectedProfile && (
      <DrawerLayout
        visible={visible}
        title={selectedProfile?.id!!}
        body={<ProfileEditDrawerBody />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default ProfileEditDrawer;
