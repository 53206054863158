import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const loadingStyles = css`
  .loading-wrapper {
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const noUsersStyles = css`
  .message {
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-align: center;
  }
`;

export const joinedUsersStyles = css`
  .add-user-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .add-user-button {
    display: flex;
    margin: 0 16px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .joined-users {
    padding-left: 0;
  }
  .button-text {
    font-size: 14px;
    margin-bottom: 0;
  }
  .popover {
    text-align: center;
  }
  .joined-user-leave {
    cursor: pointer;
  }
`;
