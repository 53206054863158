import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  noFleetsStyles,
  assignedFleetsStyles,
  loadingStyles,
} from './AssignedFleets.styles';
import {
  removeFleetFromCircle,
  selectLoadingCircle,
  selectSelectedCircle,
} from '../../redux/store/userData/circles';
import LoadingIcon from '../../components/shared/LoadingIcon';
import { openModal } from '../../redux/store/ui/common';
import ListEditItem from '../shared/ListEditItem';
import { Button, Popover } from 'antd';

const NoFleets = () => {
  return (
    <>
      <div>
        <p className="message">There are no fleets assigned to this group</p>
      </div>
      <style jsx>{noFleetsStyles}</style>
    </>
  );
};

const AssignedFleets = () => {
  const dispatch = useDispatch();
  const selectedCircle = useSelector(selectSelectedCircle);
  const loadingCircle = useSelector(selectLoadingCircle);

  if (loadingCircle) {
    return (
      <>
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  const onAddFleetClicked = () => {
    dispatch(openModal('addFleetToCircleModal'));
  };

  const onRemoveFleetClicked = (fleetId: string) => {
    dispatch(removeFleetFromCircle(selectedCircle?.id!, fleetId));
  };

  return (
    <>
      <div className="add-fleet-button-wrapper">
        <div className="add-fleet-button" onClick={onAddFleetClicked}>
          <p className="button-text">Add Fleet</p>
        </div>
      </div>
      <div
        className="assigned-fleets"
        style={!selectedCircle?.fleets?.length ? { borderBottom: 0 } : {}}
      >
        {selectedCircle?.fleets?.length === 0 ? (
          <NoFleets />
        ) : (
          selectedCircle?.fleets?.map((fleet) => (
            <ListEditItem
              key={fleet.id}
              leftItem={fleet.name || '-'}
              rightItem={
                <Popover
                  content={
                    <div className="popover">
                      <p>Are you sure?</p>
                      <Button onClick={() => onRemoveFleetClicked(fleet.id!)}>
                        Yes
                      </Button>
                    </div>
                  }
                  trigger="click"
                  placement="topRight"
                >
                  <img
                    className="fleet-remove-button"
                    src="/images/circleDrawer/delete.svg"
                    alt="delete"
                  />
                </Popover>
              }
            />
          ))
        )}
      </div>
      <style jsx>{assignedFleetsStyles}</style>
    </>
  );
};

export default AssignedFleets;
