import serviceTypes from '../../assets/serviceTypes';
import { FormattedMessage } from 'react-intl';

const serviceTypesColumns: any = serviceTypes?.map((serviceType) => ({
  label: <FormattedMessage id={`fleetadmin.serviceType.${serviceType}`} />,
  value: serviceType,
  disabled: false,
}));

const careColumns = [
  { label: 'Trip ID', value: 'code', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Plate', value: 'plate', disabled: false },
  { label: 'Fleet', value: 'fleetId', disabled: false },
  { label: 'Area', value: 'areaId', disabled: false },
  { label: 'Created at', value: 'createdAt', disabled: false },
  { label: 'Accepted at', value: 'reservedAt', disabled: false },
  { label: 'Start', value: 'tripStart', disabled: false },
  { label: 'End', value: 'tripEnd', disabled: false },
  { label: 'Duration', value: 'tripDuration', disabled: false },
  { label: 'Distance', value: 'tripDistance', disabled: false },
  { label: 'Service Hero', value: 'userReferralCode', disabled: false },
  { label: 'Brand', value: 'brandCode', disabled: false },
  { label: 'VIN', value: 'chassisNumber', disabled: false },
  { label: 'Service Count', value: 'serviceTypes', disabled: false },
  { label: 'Back by', value: 'backBy', disabled: false },
  { label: 'Pickup location', value: 'pickupLocation', disabled: false },
  ...serviceTypesColumns,
];

export const defaultSelectedCareColumns = careColumns.map(
  (careColumn) => careColumn.value
);

export default careColumns;
