import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .border {
    border-style: solid;
    border-width: 1px;
    border-color: #a3a3a3;
  }
  .image-wrapper {
    display: flex;
    width: 100%;
    height: 78vh;
    overflow: hidden;
    background-color: ${colors.grey};
    justify-content: center;
    align-items: center;
  }
  .damage-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: auto;
  }
  .indicator-wrapper {
    width: 100%;
    display: inline;
  }
  .inactive-indicator {
    background-color: #f2f2f2;
    width: 60px;
    height: 8px;
    border-radius: 8px;
    margin: 0 15px;
    border-width: 0;
    outline: none;
  }
  .active-indicator {
    background-color: #a3a3a3;
    width: 140px;
    height: 8px;
    border-radius: 8px;
    margin: 0 15px;
    border-width: 0;
    outline: none;
  }
  @media only screen and (min-width: 768px) {
    .border {
      border-style: solid;
      border-width: 1px;
      border-color: #a3a3a3;
    }
    .image-wrapper {
      display: flex;
      width: 100%;
      height: 800px;
      overflow: hidden;
      background-color: ${colors.grey};
      justify-content: center;
      align-items: center;
    }
    .damage-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: auto;
    }
    .indicator-wrapper {
      width: 100%;
      display: inline;
    }
    .inactive-indicator {
      background-color: #f2f2f2;
      width: 60px;
      height: 8px;
      border-radius: 8px;
      margin: 0 15px;
      border-width: 0;
      outline: none;
    }
    .active-indicator {
      background-color: #a3a3a3;
      width: 140px;
      height: 8px;
      border-radius: 8px;
      margin: 0 15px;
      border-width: 0;
      outline: none;
    }
  }
`;

export default style;
