export interface MapState {
  center: {
    lat: number | null;
    lng: number | null;
  };
  zoom: number;
  visibleCarMarkers: boolean;
  visibleAreaPolygons: boolean;
  visibleParkingAreasPolygons: boolean;
}

export const initialState = {
  center: {
    lat: 50.5,
    lng: 12.4,
  },
  zoom: 5,
  visibleCarMarkers: true,
  visibleAreaPolygons: true,
  visibleParkingAreasPolygons: false,
};
