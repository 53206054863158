import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { differenceInHours } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { fetchCarConditionsIfNeeded } from '../../redux/helpers';
import LoadingIcon from '../../components/shared/LoadingIcon';
import {
  loadingStyles,
  carConditionStyles,
  conditionsWrapperStyles,
} from './CarConditions.styles';
import formatDateTime from '../../utils/formatDateTime';
import {
  selectLoadingConditions,
  selectCarConditions,
  selectSelectedCar,
} from '../../redux/store/userData/cars';
import { selectAuth } from '../../redux/store/userData/user';
import DeviceStatus from '../shared/DeviceStatus';

const ALLOWABLE_FUEL_AMOUNT = 25;
const ALLOWABLE_VOLTAGE_LEVEL = 11.5;
const ALLOWABLE_MILEAGE = 0;

const CarConditionDongle = ({
  className,
  label,
  value,
  icon,
  isOutdatedStatus,
}: any) => {
  return (
    <>
      <div className={cn('condition-item-dongle', className)}>
        <p
          className="condition-value"
          style={isOutdatedStatus ? { color: 'grey' } : {}}
        >
          {value}
        </p>
        <img
          className="dongle-condition-image"
          src={`/images/car-editor/${icon}`}
          alt={label}
        />
        <p
          className="condition-label"
          style={isOutdatedStatus ? { color: 'grey' } : {}}
        >
          <FormattedMessage id={`fleetadmin.carEditor.${label}`} />
        </p>
      </div>
      <style jsx>{carConditionStyles}</style>
    </>
  );
};

const CarCondition = ({
  className,
  label,
  value,
  icon,
  isOutdatedStatus,
}: any) => {
  return (
    <>
      <div className={cn('condition-item', className)}>
        <div className="condition-left-item">
          <img
            className="condition-image"
            src={`/images/car-editor/${icon}`}
            alt={label}
          />
          <p
            className="condition-label"
            style={isOutdatedStatus ? { color: 'grey' } : {}}
          >
            <FormattedMessage id={`fleetadmin.carEditor.${label}`} />
          </p>
        </div>
        <p className="condition-value">{value}</p>
      </div>
      <style jsx>{carConditionStyles}</style>
    </>
  );
};

const CarConditions = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const { token } = useSelector(selectAuth);
  const selectedCar = useSelector(selectSelectedCar);
  const conditions = useSelector(selectCarConditions);
  const loading = useSelector(selectLoadingConditions);

  const isOutdatedStatus = conditions?.currentStatusTimestamp
    ? differenceInHours(
        new Date(),
        new Date(conditions?.currentStatusTimestamp)
      ) > 8
    : true;

  const handleRefreshCarConditions = (e: any) => {
    e.preventDefault();
    dispatch(fetchCarConditionsIfNeeded(token, selectedCar?.id!, true, true));
  };

  if (loading && selectedCar?.status !== 'EQUIPMENT_READY') {
    return (
      <>
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  const isValidFuelLevel =
    selectedCar?.status !== 'EQUIPMENT_READY' &&
    conditions != null &&
    conditions?.fuelLevel! > ALLOWABLE_FUEL_AMOUNT;

  const isValidVoltageLevel =
    selectedCar?.status !== 'EQUIPMENT_READY' &&
    conditions != null &&
    conditions?.batteryVoltage! >= ALLOWABLE_VOLTAGE_LEVEL;

  const isValidMileage =
    selectedCar?.status !== 'EQUIPMENT_READY' &&
    conditions != null &&
    conditions?.mileage! >= ALLOWABLE_MILEAGE;

  const conditionProps =
    selectedCar?.status === 'EQUIPMENT_READY' || conditions === null
      ? [
          {
            className: 'no-data',
            label: 'fuelLevel',
            value: '-',
            icon: 'fuel_no-data.svg',
          },
          {
            className: 'no-data',
            label: 'batteryVoltage',
            value: '-',
            icon: 'battery_no-data.svg',
          },
          {
            className: 'no-data',
            label: 'mileage',
            value: '-',
            icon: 'mileage_no-data.svg',
          },
          {
            className: 'no-data',
            label: 'carKey',
            value: '-',
            icon: 'key_no-data.svg',
          },
          {
            className: 'no-data',
            label: 'fuelCard',
            value: '-',
            icon: 'fuel-card_no-data.svg',
          },
        ]
      : [
          {
            className: !isValidFuelLevel && 'invalid',
            label: 'fuelLevel',
            value: conditions.fuelLevel ? `${conditions.fuelLevel}%` : '-',
            icon: isValidFuelLevel ? 'fuel.svg' : 'fuel_invalid.svg',
          },
          {
            className: !isValidVoltageLevel && 'invalid',
            label: 'batteryVoltage',
            value: conditions.batteryVoltage
              ? `${conditions.batteryVoltage}V`
              : '-',
            icon: isValidVoltageLevel ? 'battery.svg' : 'battery_invalid.svg',
          },
          {
            className: !isValidMileage && 'invalid',
            label: 'mileage',
            value: conditions.mileage ? `${conditions.mileage}km` : '-',
            icon: isValidMileage ? 'mileage.svg' : 'mileage_invalid.svg',
          },
          ...(selectedCar!?.keyFobRequired
            ? [
                {
                  className: !conditions.keyFobIn && 'invalid',
                  label: 'carKey',
                  value: conditions.keyFobIn ? (
                    <img src="/images/car-editor/check.svg" alt="check" />
                  ) : (
                    <FormattedMessage id="fleetadmin.carEditor.missing" />
                  ),
                  icon: conditions.keyFobIn ? 'key.svg' : 'key_invalid.svg',
                },
              ]
            : []),
          ...(selectedCar!?.fuelCardRequired
            ? [
                {
                  className: !conditions.fuelCardIn && 'invalid',
                  label: 'fuelCard',
                  value: conditions.fuelCardIn ? (
                    <img src="/images/car-editor/check.svg" alt="check" />
                  ) : (
                    <FormattedMessage id="fleetadmin.carEditor.missing" />
                  ),
                  icon: conditions.fuelCardIn
                    ? 'fuel-card.svg'
                    : 'fuel-card_invalid.svg',
                },
              ]
            : []),
        ];

  return (
    <>
      <div className="online-status-wrapper">
        <DeviceStatus status={conditions?.online ? 'online' : 'offline'} />
        <span className="refresh-button-last-modified">
          {conditions?.currentStatusTimestamp
            ? formatDateTime(conditions?.currentStatusTimestamp)
            : ''}
        </span>
      </div>
      <div className="conditions-wrapper">
        {conditionProps.slice(0, 3).map((props, i) => (
          <CarConditionDongle
            isOutdatedStatus={isOutdatedStatus}
            key={i}
            {...props}
          />
        ))}
      </div>
      {selectedCar?.vehicleConnection === 'cloudboxx' && (
        <div className="conditions-wrapper">
          {conditionProps.slice(3).map((props, i) => (
            <CarCondition
              key={i}
              isOutdatedStatus={isOutdatedStatus}
              {...props}
            />
          ))}
        </div>
      )}
      {user?.permissions?.fleetManager && (
        <div className="button-wrapper">
          <Button
            className="refresh-button"
            block={true}
            onClick={handleRefreshCarConditions}
            type="link"
          >
            <ReloadOutlined />
            <FormattedMessage id="fleetadmin.carEditor.update" />
          </Button>
          <span className="refresh-button-last-modified">
            {conditions?.lastModified
              ? formatDateTime(conditions?.lastModified)
              : ''}
          </span>
        </div>
      )}
      <style jsx>{conditionsWrapperStyles}</style>
    </>
  );
};

export default CarConditions;
