import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingCancelModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  cancelBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import {
  getActiveBooking,
  selectTimeTableCars,
} from '../../../redux/store/userData/cars';
import { useIntl } from 'react-intl';

const BookingCancelModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayCars = useSelector(selectTimeTableCars);
  const selectedBookig = useSelector(selectSelectedBooking) as any;
  const visibleModal = useSelector(getIsVisibleModal('BookingCancelModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      cancelBooking(selectedBookig?.id, () =>
        dispatch(getActiveBooking(displayCars!.map((car) => car.id)))
      )
    );
    dispatch(closeModal('BookingCancelModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('BookingCancelModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingCancelModal'));
        }}
      >
        <div className="booking-cancel-modal">
          <div className="booking-cancel-wrapper">
            <div className="booking-cancel-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.booking.cancel.title`,
                })}
              </p>
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingCancelModal;
