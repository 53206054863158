import React, { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setQueries } from '../../libs/setQueries';
import TableLayout from '../../components/shared/TableLayout';
import getColumns from './getColumns';
import { FleetBookingData } from '../../@types';
import {
  fetchBookings,
  selectDisplayBookings,
  selectBookings,
  selectSelectedBooking,
  selectBooking,
  selectTotalBookings,
  fetchBookingById,
} from '../../redux/store/userData/trips';
import { selectDisplayColumnsForBooking } from '../../redux/store/ui/common';
import { selectCars } from '../../redux/store/userData/cars';
import { selectAreas } from '../../redux/store/appData/areas';
import { useIntl } from 'react-intl';
import { getFilteredColumns } from '../../utils';

const BookingsTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const areas = useSelector(selectAreas);
  const selectedBooking = useSelector(selectSelectedBooking);
  const bookingsEntities = useSelector(selectBookings);
  const displayBookings = useSelector(selectDisplayBookings);
  const bookingsNo = useSelector(selectTotalBookings);
  const displayColumns = useSelector(selectDisplayColumnsForBooking);
  const columns = getColumns(intl, cars, areas);
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: FleetBookingData) => {
    batch(() => {
      dispatch(selectBooking(record));
      dispatch(fetchBookingById(record.id));
    });

    const query = {
      bookingId: record.id,
    };
    setQueries(query);
  };

  const loadBookings = () => {
    dispatch(fetchBookings(0, 1000));
  };

  useEffect(() => {
    if (!bookingsEntities || bookingsEntities?.length === 0) {
      loadBookings();
    }
  }, []);

  return (
    <TableLayout
      rowKey="booking"
      columns={filteredColumns}
      dataSource={displayBookings}
      selectedId={selectedBooking?.id}
      totalNumber={
        bookingsEntities &&
        displayBookings &&
        bookingsEntities?.length > displayBookings?.length
          ? displayBookings?.length
          : bookingsNo
      }
      onClickRow={onClickRow}
    />
  );
};

export default BookingsTable;
