import React from 'react';
import { useIntl } from 'react-intl';
import ListEditItem from '../../../shared/ListEditItem';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

type Props = {
  paymentType: string;
  isSelected: boolean;
  isDisabled?: boolean;
  onChange?: SwitchChangeEventHandler;
};
export const PaymentTypeSwitch = ({
  paymentType,
  isSelected,
  isDisabled,
  onChange,
}: Props) => {
  const intl = useIntl();
  const labelId = `fleetadmin.users.info.${paymentType}`;
  const formattedLabel = intl.formatMessage({ id: labelId });

  return (
    <ListEditItem
      leftItem={<div className="permission-label">{formattedLabel}</div>}
      rightItem={
        <Switch
          checked={isSelected}
          disabled={isDisabled}
          onChange={onChange}
        />
      }
    />
  );
};
