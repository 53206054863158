import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  p {
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 14px;
    width: 130px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
  }
  p.PLANNED {
    background: ${colors.cares.planned};
  }
  p.OPEN {
    background: ${colors.cares.open};
  }
  p.IN_PROGRESS {
    background: ${colors.cares.inProgress};
  }
  p.CLOSED {
    background: ${colors.cares.closed};
  }
`;

export default style;
