import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Button, Input } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import {
  selectSelectedCar,
  updateCarIfNeeded,
} from '../../redux/store/userData/cars';
import styles from './UpdateCarPlateModal.styles';
import { fetchVehicleEventsForCar } from '../../redux/store/userData/vehicleEvents';
import { useIntl } from 'react-intl';

const UpdateCarPlateModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);

  const [plate, setPlate] = useState(null) as any;
  const visibleModal = useSelector(getIsVisibleModal('updateCarPlateModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      if (plate) {
        dispatch(updateCarIfNeeded(token, selectedCar?.id!, { plate }));
        dispatch(closeModal('updateCarPlateModal'));
        setTimeout(
          () => dispatch(fetchVehicleEventsForCar(selectedCar?.id!)),
          300
        );
      }
    });
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateCarPlateModal'));
  };

  useEffect(() => {
    setPlate(null);
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateCarPlateModal'));
        }}
      >
        <div className="update-car-plate-modal">
          <div className="update-car-plate-wrapper">
            <p className="label">
              {intl.formatMessage({
                id: `fleetadmin.car.plate`,
              })}
            </p>
            <Input
              placeholder={intl.formatMessage({
                id: `fleetadmin.car.plate.placeholder`,
              })}
              value={plate}
              onChange={(e) => setPlate(e.target.value)}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={!plate}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateCarPlateModal;
