interface Location {
  lat: number;
  lng: number;
}

const locationFromString = (
  position: string,
  separator = ','
): Location | null => {
  if (position) {
    const numbers = position?.split(separator);
    return {
      lat: Number(numbers[0]),
      lng: Number(numbers[1]),
    };
  }
  return null;
};

export default locationFromString;
