import cn from 'classnames';
import getFleetNameById from '../../utils/getFleetNameById';
import checkIsNew from '../../utils/checkIsNew';
import { Popover } from 'antd';
import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDate,
  getRenderWithPlaceholder,
  getRenderAction,
} from '../shared/TableLayout';

import message from '../../utils/message';
import CarStatus from '../../components/shared/CarStatus';
import VINCell from './VINCell';
import CAR_STATUSES from '../../libs/carStatuses';
import React from 'react';
import styles from './getColumns.styles';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import formatDate from '../../utils/formatDate';
import isDesktop from '../../utils/sizeHelpers';
import { selectCarAndSwitchToMapView } from '../../redux/store/ui/common';
import getAreaNameById from '../../utils/getAreaNameById';
import RenderBoolean from '../../components/shared/RenderBoolean';
import { MomentInput } from 'moment';
import { FleetAdminCarData } from '../../@types';

const getColumns = ({ fleets, areas, lastCarsStatus, intl, dispatch }: any) => {
  const renderVIN = (vin: string, car: { equippedSince: string | null }) => {
    const isNew = checkIsNew(car.equippedSince);
    return <VINCell vin={vin} isNew={isNew} />;
  };

  const renderStatus = (status: any, record) => (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <CarStatus
        status={status}
        style={{
          width: '100px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
        }}
      />
      {record?.blockReasons?.length > 0 &&
        status === CAR_STATUSES.BLOCKED &&
        record?.blockReasons?.map((reason) => (
          <Popover
            key={reason}
            content={
              <div className="popover">
                {intl.formatMessage({
                  id: `fleetadmin.blockReasons.${reason}`,
                })}
              </div>
            }
            placement="top"
          >
            <img
              src={`/images/blocked-reasons/${reason}.svg`}
              alt={reason}
              style={{
                marginLeft: '12px',
                width: '14px',
                height: '14px',
                ...(record?.blockReasons?.length > 3 && { marginTop: 10 }),
              }}
            />
          </Popover>
        ))}
      <style jsx>{styles}</style>
    </div>
  );

  const renderFleet = (fleetId: any) => (
    <span>{getFleetNameById(fleets, fleetId)}</span>
  );

  const renderArea = (areaId: unknown) =>
    areaId ? (
      <span>{getAreaNameById(areas, areaId)}</span>
    ) : (
      getRenderWithPlaceholder('Not assigned')
    );

  const renderModel = (
    model:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    car: {
      brand:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    }
  ) => (
    <span>
      {car.brand} {model}
    </span>
  );

  const renderAccessKitType = (
    vehicleConnection:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined
  ) => <span>{vehicleConnection}</span>;

  const renderLastStatusChange = (statusChangedAt: MomentInput) => {
    return <span>{statusChangedAt ? formatDate(statusChangedAt) : '~'}</span>;
  };

  const renderBatteryVoltage = (battery: any) => {
    return <span>{battery != null ? `${battery} V` : '-'}</span>;
  };

  const renderFuelLevel = (fuelLevel: {} | null | undefined) => {
    if (fuelLevel == null) {
      return <span>-</span>;
    }

    const isTooLow = fuelLevel <= 10;

    return (
      <>
        <span className={cn({ highlight: isTooLow })}>{fuelLevel} %</span>
        <style jsx>{`
          .highlight {
            color: #ee4e4e;
          }
        `}</style>
      </>
    );
  };

  const renderTrueOrFalse = (value: boolean) => <RenderBoolean value={value} />;

  const renderAction = getRenderAction((car: FleetAdminCarData) => {
    if (car.position || lastCarsStatus[car.id!]?.position) {
      dispatch(selectCarAndSwitchToMapView(car));
    } else {
      message.error(
        intl.formatMessage({
          id: 'fleetadmin.car.noPositionData',
        })
      );
    }
  });

  const renderPosition = (
    position: any,
    car: { id: string | number; position: any }
  ) => (
    <LinkToGoogleMap
      position={lastCarsStatus[car.id]?.position || car?.position}
    />
  );

  return [
    {
      title: 'Plate',
      dataIndex: 'plate',
      render: getRenderWithPlaceholder(),
      ellipsis: true,
      width: 150,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('plate'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      width: 210,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: 'Vehicle VIN',
      dataIndex: 'chassisNumber',
      render: renderVIN,
      width: isDesktop() ? 200 : 150,
      sorter: alphabeticalSorterFor('chassisNumber'),
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: 170,
      render: renderAction,
    },
    {
      title: 'Location',
      dataIndex: 'position',
      ellipsis: false,
      width: 220,
      render: renderPosition,
    },
    {
      title: 'AccessKit type',
      dataIndex: 'vehicleConnection',
      ellipsis: false,
      width: 150,
      render: renderAccessKitType,
      sorter: alphabeticalSorterFor('vehicleConnection'),
    },
    {
      title: 'Last Status Change',
      dataIndex: 'statusChangedAt',
      render: renderLastStatusChange,
      width: 180,
      sorter: chronologicalSorterFor('statusChangedAt'),
    },
    {
      title: 'Equipped',
      dataIndex: 'equippedSince',
      width: 150,
      ellipsis: true,
      render: renderDate,
      sorter: chronologicalSorterFor('equippedSince'),
    },
    {
      title: 'First ReleasedReady',
      dataIndex: 'firstReleaseReadyAt',
      width: 150,
      ellipsis: true,
      render: renderDate,
      sorter: chronologicalSorterFor('firstReleaseReadyAt'),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      width: 170,
      ellipsis: true,
      render: renderModel,
      sorter: (
        carA: { brand: any; model: any },
        carB: { brand: any; model: any }
      ) => {
        const modelNameA = `${carA.brand}${carA.model}`;
        const modelNameB = `${carB.brand}${carB.model}`;
        return alphabeticalSorter(modelNameA, modelNameB);
      },
    },
    {
      title: 'Fleet',
      dataIndex: 'fleetId',
      render: renderFleet,
      width: 170,
      ellipsis: true,
      sorter: (carA: { fleetId: any }, carB: { fleetId: any }) => {
        const fleetNameA = getFleetNameById(fleets, carA.fleetId);
        const fleetNameB = getFleetNameById(fleets, carB.fleetId);
        return alphabeticalSorter(fleetNameA, fleetNameB);
      },
    },
    {
      title: 'Area',
      dataIndex: 'areaId',
      width: 170,
      ellipsis: true,
      render: renderArea,
      sorter: (carA: { areaId: unknown }, carB: { areaId: unknown }) => {
        const areaNameA =
          carA.areaId !== null ? getAreaNameById(areas, carA.areaId) : '';
        const areaNameB =
          carB.areaId !== null ? getAreaNameById(areas, carB.areaId) : '';
        return alphabeticalSorter(areaNameA, areaNameB);
      },
    },
    {
      title: 'Battery voltage',
      dataIndex: ['lastStatus', 'batteryVoltage'],
      width: 140,
      ellipsis: true,
      render: renderBatteryVoltage,
      sorter: (carA: any, carB: any) => {
        const getCarBattery = (car: { lastStatus: { batteryVoltage: null } }) =>
          car.lastStatus && car.lastStatus.batteryVoltage != null
            ? car.lastStatus.batteryVoltage
            : 0;

        const carABattery = getCarBattery(carA);
        const carBBattery = getCarBattery(carB);
        return carABattery - carBBattery;
      },
    },
    {
      title: 'Cloudbox status',
      dataIndex: ['lastStatus', 'online'],
      width: 140,
      ellipsis: true,
      render: renderTrueOrFalse,
      sorter: (carA: any, carB: any) => {
        const getScore = (car: any): number => {
          if (car.lastStatus == null || car.lastStatus.online == null) {
            return 0;
          } else if (car.lastStatus.online) {
            return 2;
          } else {
            return 1;
          }
        };
        const A = getScore(carA);
        const B = getScore(carB);
        return A - B;
      },
    },
    {
      title: 'Fuel status',
      dataIndex: ['lastStatus', 'fuelLevel'],
      width: 120,
      ellipsis: true,
      render: renderFuelLevel,
      sorter: (carA: any, carB: any) => {
        const getFuelLevel = (car: {
          lastStatus: { fuelLevel: null } | null;
        }) =>
          car.lastStatus != null && car.lastStatus.fuelLevel != null
            ? car.lastStatus.fuelLevel
            : 0;
        const carAFuelLevel = getFuelLevel(carA);
        const carBFuelLevel = getFuelLevel(carB);
        return carAFuelLevel - carBFuelLevel;
      },
    },
    {
      title: 'Key',
      dataIndex: ['lastStatus', 'keyFobIn'],
      width: 120,
      render: renderTrueOrFalse,
      sorter: (carA: any, carB: any) => {
        const getScore = (car: any): number => {
          if (car.lastStatus == null || car.lastStatus.keyFobIn == null) {
            return 0;
          } else if (car.lastStatus.keyFobIn) {
            return 2;
          } else {
            return 1;
          }
        };
        const A = getScore(carA);
        const B = getScore(carB);
        return A - B;
      },
    },
    {
      title: 'FuelCard',
      dataIndex: ['lastStatus', 'fuelCardIn'],
      width: 120,
      render: renderTrueOrFalse,
      sorter: (carA: any, carB: any) => {
        const getScore = (car: any): number => {
          if (car.lastStatus == null || car.lastStatus.fuelCardIn == null) {
            return 0;
          } else if (car.lastStatus.fuelCardIn) {
            return 2;
          } else {
            return 1;
          }
        };
        const A = getScore(carA);
        const B = getScore(carB);
        return A - B;
      },
    },
  ];
};

export default getColumns;
