import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css`
  .container {
    z-index: ${zIndex.widget};
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: pink;
    display: flex;
  }
  .switch {
    background: black;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.3;
  }
  .switchNames {
    background: black;
    width: 90px;
    height: 48px;
    margin-right: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.3;
  }
  img {
    width: 32px;
    height: 32px;
  }
  .switch.visible {
    opacity: 0.8;
  }
  .switchNames.visible {
    opacity: 0.8;
  }
  .area-names-label {
    color: white;
    margin-bottom: 0px;
  }
`;

export default style;
