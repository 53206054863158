import React from 'react';
import { useIntl } from 'react-intl';
import styles from './UserConsents.styles';
import { Collapse, Switch, Tag } from 'antd';
import ListEditItem from '../../../../shared/ListEditItem';
import { dateFromObjectId, getStatusColor } from '../../../../../utils';
import formatDateTime from '../../../../../utils/formatDateTime';
import { renderText } from '../../../../shared/DrawerLayout/renderText';
import ListItem from '../../../../shared/ListItem';
import { renderDate } from '../../../../shared/TableLayout';

const { Panel } = Collapse;

const UserConsents = ({ selectedUser }) => {
  const intl = useIntl();

  const schufaAgreed = selectedUser?.actions?.find(
    (action) => action?.name === 'creditWorthinessAgreed'
  );
  const newsLetterConsent = selectedUser?.actions?.find(
    (action) => action?.name === 'marketingConsentGiven'
  );
  const signupDate = formatDateTime(dateFromObjectId(selectedUser.id) as Date);

  const renderHeader = (name) => {
    const status =
      name === 'schufa'
        ? (!!schufaAgreed)?.toString()
        : name === 'newletter'
        ? (!!selectedUser?.marketingConsentGiven)?.toString()
        : 'true';
    return (
      <>
        <div className="consent-header">
          {intl.formatMessage({
            id: `fleetadmin.users.info.consents.${name}`,
          })}
          <Tag color={getStatusColor(status)}>
            {status === 'true' ? ' ✓ ' : ' ✗ '}
          </Tag>
        </div>
        <style jsx>{styles}</style>
      </>
    );
  };

  return (
    <>
      <div className="user-section-title">
        {intl.formatMessage({
          id: `fleetadmin.users.info.consents`,
        })}
      </div>
      <div className="collapse-container">
        <Collapse bordered={false}>
          {/* schufa */}
          <Panel
            header={renderHeader('schufa')}
            key="fleetadmin.users.info.consents.schufa"
          >
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.status`,
              })}
              leftItem={
                <div className="permission-label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.consent.${
                      schufaAgreed ? 'true' : 'false'
                    }`,
                  })}
                </div>
              }
              rightItem={<Switch checked={!!schufaAgreed} disabled />}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.consent.date`,
              })}
              value={renderDate(schufaAgreed?.created, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.consent.by`,
              })}
              value={renderText(schufaAgreed?.createdBy, true)}
            />
          </Panel>

          {/* newletter */}
          <Panel
            header={renderHeader('newletter')}
            key="fleetadmin.users.info.consents.newletter"
          >
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.status`,
              })}
              leftItem={
                <div className="permission-label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.consent.${
                      selectedUser?.marketingConsentGiven ? 'true' : 'false'
                    }`,
                  })}
                </div>
              }
              rightItem={
                <Switch
                  checked={selectedUser?.marketingConsentGiven}
                  disabled
                />
              }
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.consent.date`,
              })}
              value={renderDate(newsLetterConsent?.created, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.consent.by`,
              })}
              value={renderText(newsLetterConsent?.createdBy, true)}
            />
          </Panel>

          {/*privacy policy*/}
          <Panel
            header={renderHeader('privacypolicy')}
            key="fleetadmin.users.info.consents.privacypolicy"
          >
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.status`,
              })}
              leftItem={
                <div className="permission-label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.consent.true`,
                  })}
                </div>
              }
              rightItem={<Switch checked disabled />}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.consent.date`,
              })}
              value={renderDate(signupDate, false)}
            />
          </Panel>
        </Collapse>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserConsents;
