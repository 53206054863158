import {
  AddressData,
  ChallengeData,
  CircleData,
  CreditworthinessData,
  ErrorResponse,
  FleetUserTransactions,
  IdentityCardData,
  LicenseData,
  NoteData,
  NotificationsData,
  PaymentMethodDataOutput,
  TariffData,
  UserDeviceData,
  VerificationData,
} from '../../../../@types';

export type GAdminUserDataOutput = {
  email?: string;
  title?: 'MR' | 'MRS';
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  referralCode?: string;
  phoneNumber?: string;
  address?: AddressData;
  notifications?: NotificationsData;
  ownsACar?: boolean;
  license?: LicenseData;
  licenses?: Array<LicenseData>;
  identityDocument?: IdentityCardData;
  id?: string;
  balance?: number;
  withdrawableBalance?: number;
  currency?: string;
  verified?: VerificationData;
  paymentMethods?: Array<PaymentMethodDataOutput>;
  supportedPaymentTypes?: Array<
    'card' | 'sepa' | 'payIn' | 'manual' | 'paypal' | 'marketPayManualPayout'
  >;
  version?: number;
  challenges?: Array<ChallengeData>;
  serviceHero?: boolean;
  jumioCallbackURL?: string;
  schufaCheckRequired?: boolean;
  circles?: Array<CircleData>;
  useJumioForIdentityCheck?: boolean;
  addressOnboardingType?: 'document' | 'creditCheck' | 'noAddressCheck';
  remainingLicenseSubmissionAttempts?: number;
  identityApprovedAt?: string;
  created?: string;
  creditworthinessApproved?: boolean;
  addressVerified?: boolean;
  locale?: string;
  brandId?: string;
  brandName?: string;
  merchantUserReference?: string;
  birthPlace?: string;
  sanctionListCheckResult?: boolean;
  youngDriver?: boolean;
  pairedDevices?: Array<UserDeviceData>;
  creditworthinessData?: CreditworthinessData;
  youngDriverApproved?: boolean;
  blockedPaymentTypes?: Array<
    'card' | 'sepa' | 'payIn' | 'manual' | 'paypal' | 'marketPayManualPayout'
  >;
  firstNameVerified?: boolean;
  lastNameVerified?: boolean;
  dateOfBirthVerified?: boolean;
  birthPlaceVerified?: boolean;
  emailVerified?: boolean;
  markedForFraud?: boolean;
  accountStatus?: 'active' | 'blocked' | 'archived';
  lastLoginAt?: string;
  syncedWithMerchantSystemAt?: string;
  notes?: Array<NoteData>;
} & {
  [key: string]: any;
};

export interface UsersState {
  entities: any[] | null;
  display: any[] | null;
  total: null;
  selected: GAdminUserDataOutput | null;
  error: ErrorResponse | null;
  loading: boolean;
  selectedDuplicate: GAdminUserDataOutput | null;
  selectedUserTransactions: FleetUserTransactions | null;
  userTransactionsLoading: boolean;
  userPaymentId: string;
  uploadingImage: boolean;
  certificateLoading: boolean;
  loadingGDPR: boolean;
  tariffs: TariffData[] | null;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  total: null,
  error: null,
  loading: false,
  selectedDuplicate: null,
  selectedUserTransactions: null,
  userTransactionsLoading: false,
  userPaymentId: '',
  uploadingImage: false,
  certificateLoading: false,
  loadingGDPR: false,
  tariffs: null,
};
