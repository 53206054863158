import { AreaData } from '../@types';

export const getAreaById = (
  areas: AreaData[] | null,
  id: string
): AreaData | undefined => {
  if (id) {
    return areas?.find((area) => area.id === id);
  }
  return undefined;
};
