import { Reducer } from '../../../../@types';
import { CaresTypes } from '../../../../@types/enums';
import { CaresState, initialState } from './initialState';
// @ts-ignore
export const caresReducer: Reducer<CaresState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case CaresTypes.CARES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CaresTypes.CARES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case CaresTypes.CARES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CaresTypes.SET_DISPLAY_CARES:
      return {
        ...state,
        display: action.payload,
      };
    case CaresTypes.SELECT_CARE:
      return {
        ...state,
        selected: action.payload,
      };
    case CaresTypes.FINISH_SERVICE_TRIP_REQUEST:
      return {
        ...state,
        finishingTrip: true,
      };
    case CaresTypes.FINISH_SERVICE_TRIP_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        display: [
          ...state.display!.map((entity) =>
            entity.id === action.payload.id
              ? { ...entity, ...action.payload }
              : entity
          ),
        ],
        selected: { ...state.selected, ...action.payload },
        finishingTrip: false,
      };
    case CaresTypes.FINISH_SERVICE_TRIP_FAILURE:
      return {
        ...state,
        error: action.error,
        finishingTrip: false,
      };
    case CaresTypes.FINISH_SERVICE_TRIP_VIOLATIONS:
      return {
        ...state,
        finishingTrip: false,
        finishTripViolations: action.payload,
      };
    default:
      return state;
  }
};
