import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Marker } from 'react-map-gl';
import cn from 'classnames';
import locationFromString from '../../utils/locationFromString';
import styles from './CarMarkers.styles';
import CAR_STATUSES from '../../libs/carStatuses';
import { isInsideArea } from '../../utils/isInsideArea';
import {
  selectDisplayCars,
  selectSelectedCar,
  selectLastCarsStatus,
} from '../../redux/store/userData/cars';
import { selectAreas } from '../../redux/store/appData/areas';
import { selectCarWithoutMapEffect } from '../../redux/store/ui/common';

const getCarMarkerIcon = (carStatus: string, isSelected: boolean): string => {
  const lowerCaseCarStatus = carStatus.toLowerCase();
  return `/images/car-marker/car-marker-${lowerCaseCarStatus}${
    isSelected ? '-selected' : ''
  }.svg`;
};

const CarMarkers: React.FC = () => {
  const dispatch = useDispatch();
  const cars = useSelector(selectDisplayCars);
  const areas = useSelector(selectAreas);
  const selectedCar = useSelector(selectSelectedCar);
  const lastCarsStatus = useSelector(selectLastCarsStatus);

  // Sort cars for overlap cases. When vehicle is souther, the marker comes over.
  const sortedByLatCars = cars?.sort((carA, carB) => {
    // @ts-ignore
    const carAPosition = (lastCarsStatus[carA.id] || carA)?.position;
    // @ts-ignore
    const carBPosition = (lastCarsStatus[carB.id] || carB)?.position;
    const latCarA = !carAPosition ? 90 : carAPosition.split(',')[0];
    const latCarB = !carBPosition ? 90 : carBPosition.split(',')[0];

    return Number(latCarB) - Number(latCarA);
  });

  return (
    <>
      {sortedByLatCars?.map((car) => {
        // @ts-ignore
        const carPosition = lastCarsStatus[car.id]?.position || car.position;
        if (!carPosition) {
          return null;
        }

        const position = locationFromString(carPosition);
        const isSelected = selectedCar && car.id === selectedCar.id;
        const iconWidth = isSelected ? 65 : 42;
        const iconHeight = isSelected ? 75 : 50;

        const area =
          areas?.find((areaToFind) => areaToFind.id === car.areaId) || null;
        const insideArea = isInsideArea(position, area);

        const handleOnClick = (e: { preventDefault: () => void }) => {
          e.preventDefault();
          dispatch(selectCarWithoutMapEffect(car));
        };

        return (
          <Marker
            key={`car-marker-${car.id}`}
            latitude={position?.lat!}
            longitude={position?.lng!}
            offsetTop={-iconHeight}
            offsetLeft={-iconWidth / 2}
            className={cn({ selected: isSelected })}
          >
            <div className="car-marker-wrapper">
              <img
                className="car-marker-icon"
                src={getCarMarkerIcon(car?.status!, isSelected!)}
                alt="car maker"
                onClick={handleOnClick}
              />
              {!insideArea && car.status === CAR_STATUSES.RELEASED && (
                <img
                  className="car-marker-outsideArea"
                  src={`/images/map-blocked-reasons/needsRelocation.svg`}
                  style={
                    isSelected
                      ? { left: '35px', height: '30px', width: '30px' }
                      : {}
                  }
                  alt="car maker"
                />
              )}
              {car.status === CAR_STATUSES.BLOCKED &&
                // @ts-ignore
                car.blockReasons.length > 0 && (
                  <div
                    className="car-block-reason-wrapper"
                    style={isSelected ? { left: '35px' } : {}}
                  >
                    {car?.blockReasons?.map((reason, i) => (
                      <img
                        key={reason}
                        className="car-block-reason-icon"
                        style={
                          i > 1
                            ? {
                                marginLeft: `${-12 - 3 * i}px`,
                                zIndex: car?.blockReasons!.length - i,
                              }
                            : i === 1
                            ? {
                                marginLeft: '-11px',
                                zIndex: car.blockReasons!.length - i,
                              }
                            : { zIndex: car.blockReasons!.length - i }
                        }
                        src={`/images/map-blocked-reasons/${reason}.svg`}
                        alt="car block reason"
                      />
                    ))}
                  </div>
                )}
            </div>
            <style jsx>{styles}</style>
          </Marker>
        );
      })}
    </>
  );
};

export default CarMarkers;
