import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import NumOfResults from './NumOfResults';
import AddFilter from '../Filter/AddFilter';
import ViewTypeSwitch from './ViewTypeSwitch';
import ColumnsSelector from '../../components/ColumnSelector';
import { ViewMode } from '../../hooks/useViewMode';
import { ViewType } from '../../hooks/useViewType';
import styles from './Toolbar.styles';
import { selectViewMode, selectViewType } from '../../redux/store/ui/common';
import ExportCSV from './ExportCSV';

type FilterLayoutProps = {};

const Toolbar: React.FC<FilterLayoutProps> = () => {
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);

  return (
    <>
      <div
        className={cn('filter-wrapper', { fixed: viewType === ViewType.MAP })}
      >
        <div className="left">
          <AddFilter />
          <NumOfResults />
          <ExportCSV />
        </div>
        <div className="right">
          <ColumnsSelector />
          {![
            ViewMode.CARE,
            ViewMode.SHARE,
            ViewMode.BOOKING,
            ViewMode.CATEGORIES,
            ViewMode.PRICING,
            ViewMode.CIRCLE,
            ViewMode.PROFILE,
            ViewMode.USERS,
            ViewMode.ADMINS,
            ViewMode.RECEIPTS,
          ].includes(viewMode) && <ViewTypeSwitch viewType={viewType} />}
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Toolbar;
