import { MapTypes } from '../../../../@types/enums';
import { createAction } from '../../../helpers';

type Center = { lat: number; lng: number };

export const setMapCenter = (payload: Center) =>
  createAction(MapTypes.SET_MAP_CENTER, payload);

export const setMapCenterAndZoom = (payload: {
  center: Center;
  zoom: number;
}) => createAction(MapTypes.SET_MAP_CENTER_AND_ZOOM, payload);

export const toggleCarMarkers = () => createAction(MapTypes.TOGGLE_CAR_MARKERS);

export const toggleAreaPolygons = () =>
  createAction(MapTypes.TOGGLE_AREA_POLYGONS);

export const toggleParkingAreaPolygons = () =>
  createAction(MapTypes.TOGGLE_PARKING_AREA_POLYGONS);
