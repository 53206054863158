import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .pricing-table-section-title {
    margin: 20px 24px 10px 24px;
    font-family: AvenirNext, serif;
    font-size: 17px;
    font-weight: 600;
    color: black;
  }
  .pricing-table-section-items-wrapper {
    margin-left: 24px;
  }
  .pricing-collapse-container {
    margin: 0 24px;
  }
  .pricing-table-section-item {
    font-size: 14px;
    color: grey;
    padding: 3px 0;
  }
  .pricing-table-section-item.divider {
    border-bottom-color: grey;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .edit-button {
    cursor: pointer;
  }
  .button-container {
    padding: 50px 16px 30px 16px;
    position: sticky;
    bottom: 0;
    background-image: url('/images/drawer/cta-background.svg');
  }
  :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
  :global(.discard-button) {
    ${common.button}
    ${common.discardButton}
  }
  :global(.discard-button:hover) {
    ${common.discardButton}
  }
`;

export default style;
