import React from 'react';
import styles from './LinkToArea.styles';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CircleData } from '../../@types';
import {
  fetchCircleById,
  selectCircle,
} from '../../redux/store/userData/circles';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { Link } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';

interface Props {
  text?: string;
  circle?: CircleData;
  params?: any;
}

const LinkToCircle: React.FC<Props> = ({ text, circle }) => {
  const dispatch = useDispatch();
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  if (!circle) {
    return <span>{text}</span>;
  }

  const onClickHandler = (e: any) => {
    e.preventDefault();
    if (circle != null && openedDrawersCount < 2) {
      batch(() => {
        dispatch(selectCircle(circle));
        dispatch(fetchCircleById(circle.id!));
      });
    }
  };
  return (
    <>
      {openedDrawersCount < 2 ? (
        <span className="link" onClick={onClickHandler}>
          {text || '-'}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=CIRCLE&type=TABLE&pageSize=50&pageNumber=1&circleId=${circle.id}`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link">{text}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToCircle;
