import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './ArchiveAreaModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  archiveArea,
  selectSelectedArea,
} from '../../../redux/store/appData/areas';
import getNumOfCarsByAreaId from '../../../utils/getNumOfCarsByAreaId';
import { selectCars } from '../../../redux/store/userData/cars';
import { useIntl } from 'react-intl';

const ArchiveAreaModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const visibleModal = useSelector(getIsVisibleModal('ArchiveAreaModal'));
  const cars = useSelector(selectCars);
  const selectedArea = useSelector(selectSelectedArea);
  const numOfVehicles = getNumOfCarsByAreaId(cars, selectedArea?.id);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(archiveArea());
    dispatch(closeModal('ArchiveAreaModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('ArchiveAreaModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('ArchiveAreaModal'));
        }}
      >
        <div className="archive-area-modal">
          <div className="archive-area-modal-wrapper">
            <div className="archive-area--modal-inputs">
              <p className="archive-area-title">
                Are you sure you want to archive this area ?
              </p>

              {numOfVehicles > 0 && (
                <p className="archive-area-desc">
                  {`Note that by archiving this area, ${numOfVehicles} will move to release ready status`}
                </p>
              )}
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default ArchiveAreaModal;
