import { request, requestAPI, updateAPI } from '../../../../utils/request';
import {
  brandPricingsRequest,
  pricingsRequest,
  brandPricingSuccess,
  pricingsSuccess,
  brandPricingFailure,
  pricingsFailure,
  setDisplayPricings,
  uploadPricingFileSuccess,
  uploadPricingFileFailure,
  uploadPricingFileRequest,
  pricingUpdateRequest,
  pricingUpdateSuccess,
  pricingUpdateFailure,
  pricingDeleteRequest,
  pricingDeleteSuccess,
  pricingDeleteFailure,
} from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { PricingState } from '../../../../@types/enums';

const fetchPricings = () => (dispatch: Dispatch) => {
  dispatch(pricingsRequest());
  requestAPI('/fleets/pricings')
    .then((pricings) => {
      dispatch(pricingsSuccess(pricings));
      dispatch(setDisplayPricings(pricings));
    })
    .catch((err) => {
      dispatch(pricingsFailure(err));
      message.error(err.message);
    });
};

const shouldFetchPricings = (state: PricingState) => !state.loading;

export const fetchPricingsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchPricings(getState().appData.pricings)) {
      return dispatch(fetchPricings());
    } else {
      return Promise.resolve();
    }
  };
};

export const uploadPricingFile =
  (token: string, pricingId: string, file: any) => (dispatch: Dispatch) => {
    dispatch(uploadPricingFileRequest());
    const formData = new FormData();
    formData.append('file', file);

    request(`${process.env.REACT_APP_API_URL}/fleets/pricings/${pricingId}`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    })
      .then((data) => {
        message.success('Uploaded!');
        dispatch(uploadPricingFileSuccess(data));
      })
      .catch((err) => {
        dispatch(uploadPricingFileFailure(err));
        message.error(err.message);
      });
  };

const shouldUploadPricingFile = (state: PricingState) => !state.uploadingFile;

export const uploadPricingFileIfNeeded = (
  token: string,
  pricingId: string,
  file: any
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldUploadPricingFile(getState().appData.pricings)) {
      return dispatch(uploadPricingFile(token, pricingId, file));
    } else {
      return Promise.resolve();
    }
  };
};

const fetchBrandPricings =
  (token: string, brandId: any) => (dispatch: Dispatch, getState: GetState) => {
    dispatch(brandPricingsRequest());
    requestAPI(`/fleets/pricings/brand/${brandId}`)
      .then((pricings) => {
        dispatch(brandPricingSuccess({ brandId, pricings }));
      })
      .catch((err) => {
        dispatch(brandPricingFailure(err));
        message.error(err.message);
      });
  };

const shouldFetchBrandPricings = (state: PricingState) =>
  !state.loadingBrandPricings;

export const fetchBrandPricingsIfNeeded = (token: string, brandId: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchBrandPricings(getState().appData.pricings)) {
      return dispatch(fetchBrandPricings(token, brandId));
    } else {
      return Promise.resolve();
    }
  };
};

export const updatePricing = updateAPI(
  pricingUpdateRequest,
  pricingUpdateSuccess,
  pricingUpdateFailure,
  '/fleets/pricings/'
);

export const deletePricing =
  (pricingId: string, version) => (dispatch: any, getState: any) => {
    dispatch(pricingDeleteRequest());
    requestAPI(
      `/fleets/pricings/${pricingId?.split('-')?.[0]}/versions/${version}`,
      {},
      { method: 'DELETE' }
    )
      .then((pricing) => {
        dispatch(pricingDeleteSuccess({ pricingId }));
        message.success('Deleted');
      })
      .catch((err) => {
        dispatch(pricingDeleteFailure(err));
        message.error(err.message);
      });
  };

export const uploadPricingFileByVersion =
  (versionId: string, pricingId: string, file: any) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().userData.user;
    dispatch(uploadPricingFileRequest());
    const formData = new FormData();
    formData.append('file', file);

    request(
      `${process.env.REACT_APP_API_URL}/fleets/pricings/${pricingId}/${versionId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
      }
    )
      .then((data) => {
        message.success('Uploaded!');
        dispatch(uploadPricingFileSuccess(data));
      })
      .catch((err) => {
        dispatch(uploadPricingFileFailure(err));
        message.error(err.message);
      });
  };
