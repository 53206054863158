import React from 'react';
import cn from 'classnames';
import styles from '../TimeLine/TimelineItem.styles';

type Props = {
  status: string;
};

const TripIncompleteItem: React.FC<Props> = ({ status }) => {
  const titleFromStatus = () => {
    switch (status) {
      case 'CANCELLED':
        return 'Cancelled';
      case 'EXPIRED':
        return 'Expired';
      case 'RESERVED':
        return 'Reserved ..';
      case 'OPEN':
        return 'In Progress ..';
      case 'IN_PROGRESS':
        return 'Driving ..';
      default:
        return '-';
    }
  };

  return (
    <>
      <div className={'timeline-item-wrapper'}>
        <div className="left">
          <div
            className={cn('first', 'bar', {
              hidden: true,
            })}
          />
          <div className="icon-wrapper">
            <img
              src={`/images/timeline/${
                ['CANCELLED', 'EXPIRED'].includes(status)
                  ? 'grey_dot'
                  : 'loading'
              }.svg`}
              alt={`loading icon`}
            />
          </div>
          <div
            className={cn('second', ' bar', {
              hidden: true,
            })}
          />
        </div>
        <div className="right">
          <p className="title">{titleFromStatus()}</p>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TripIncompleteItem;
