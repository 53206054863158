import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapGL from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import { useIntl } from 'react-intl';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';
import getZoomFromBounds from '../../utils/getZoomFromBounds';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import locationFromString from '../../utils/locationFromString';
import isEmptyArray from '../../utils/isEmptyArray';
import message from '../../utils/message';
import Areas from './Areas';
import SelectedCarArea from './SelectedCarArea';
import CarMarkers from './CarMarkers';
import ClaimMarkers from './ClaimMarkers';
import Widget from '../../components/Mapbox/Widget';
import { ViewMode } from '../../hooks/useViewMode';
import AreaLabels from './AreaLabels';
import * as React from 'react';
import {
  selectArea,
  selectDisplayAreas,
  selectSelectedArea,
} from '../../redux/store/appData/areas';
import {
  selectSelectedCar,
  selectLastCarsStatus,
  selectDisplayCars,
} from '../../redux/store/userData/cars';
import { selectCar } from '../../redux/store/userData/cars';
import { selectClaim } from '../../redux/store/userData/claims';
import isDesktop from '../../utils/sizeHelpers';
import {
  closeCarDrawerAndSetQueries,
  closeAreaDrawerAndSetQueries,
} from '../../redux/store/ui/common';
import { selectFilterFor } from '../../redux/store/ui/filters';
import {
  selectVisibleAreaPolygons,
  selectVisibleCarMarkers,
  selectMapZoom,
  selectMapCenter,
  setMapCenterAndZoom,
} from '../../redux/store/ui/map';
import { useQuery } from '../../hooks/useQuery';
import { selectViewType } from '../../redux/store/ui/common/selectors';
import { selectVisibleParkingAreasPolygons } from '../../redux/store/ui/map/selectors';
import ParkingAreas from './ParkingAreas';

const SELECTED_CAR_ZOOM = 7;
const FILTER_BAR_OFFSET = 100;

const Map = () => {
  const intl = useIntl();
  const mapEl: any = useRef(null);
  const dispatch = useDispatch();
  const query = useQuery();
  const { vm } = query;
  const viewType = useSelector(selectViewType);

  const displayingCars = useSelector(selectDisplayCars);
  const lastCarsStatus = useSelector(selectLastCarsStatus);
  const selectedCar = useSelector(selectSelectedCar);
  const visibleCarDrawer = selectedCar != null;

  const selectedArea = useSelector(selectSelectedArea);
  const visibleAreaDrawer = selectedArea != null;
  const displayingAreas = useSelector(selectDisplayAreas);
  const filterForArea = useSelector(selectFilterFor(ViewMode.AREA));

  const center = useSelector(selectMapCenter);
  const zoom = useSelector(selectMapZoom);
  const visibleCarMarkers = useSelector(selectVisibleCarMarkers);
  const visibleAreaPolygons = useSelector(selectVisibleAreaPolygons);
  const visibleParkingAreaPolygons = useSelector(
    selectVisibleParkingAreasPolygons
  );

  const initialViewport = {
    width: '100%',
    height: 'calc(100vh - 64px)',
    // @ts-ignore
    latitude: center.lat,
    // @ts-ignore
    longitude: center.lng,
    zoom: zoom,
  };
  const [viewport, setViewport] = useState(initialViewport) as any;

  const fitBounds = (points: any[]) => {
    const bounds = getBoundsFromPoints(points);
    const center = {
      lat: (bounds.north + bounds.south) / 2,
      lng: (bounds.east + bounds.west) / 2,
    };
    const divSize = {
      width: mapEl?.current?._width,
      height: mapEl?.current?._height - FILTER_BAR_OFFSET,
    };
    const _zoom = getZoomFromBounds(bounds, divSize);

    dispatch(setMapCenterAndZoom({ center, zoom: _zoom }));
  };

  // Close selected car drawer if its no longer among displaying cars
  useEffect(() => {
    // @ts-ignore
    if (
      displayingCars === null ||
      isEmptyArray(displayingCars) ||
      (!isEmptyArray(displayingCars) &&
        selectedCar &&
        !(displayingCars.filter((car) => car.id === selectedCar.id).length > 0))
    ) {
      dispatch(selectCar(null));
    }
  }, [displayingCars]);

  // Close selected area drawer if its no longer among displaying areas
  useEffect(() => {
    // @ts-ignore
    if (
      displayingAreas === null ||
      isEmptyArray(displayingAreas) ||
      (!isEmptyArray(displayingAreas) &&
        selectedArea &&
        !(
          displayingAreas.filter((area) => area.id === selectedArea.id).length >
          0
        ))
    ) {
      dispatch(selectArea(null));
    }
  }, [displayingAreas]);

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        ...initialViewport,
        // @ts-ignore
        latitude: center.lat,
        // @ts-ignore
        longitude: center.lng,
        zoom: zoom,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setViewport({
      ...initialViewport,
      // @ts-ignore
      latitude: center.lat,
      // @ts-ignore
      longitude: center.lng,
      zoom: zoom,
    });
  }, [center, zoom]);

  // 'show on map' effect: center and zoom in to the selected car/area/displaying cars
  useEffect(() => {
    if (selectedCar != null) {
      // Zoom in to selected car
      const width = mapEl.current._width;
      const height = mapEl.current._height;
      const _viewport = new WebMercatorViewport({
        width,
        height,
        zoom: SELECTED_CAR_ZOOM,
        longitude: mapEl.current.props.longitude,
        latitude: mapEl.current.props.latitude,
      });

      const carPosition = locationFromString(
        // @ts-ignore
        selectedCar.position || lastCarsStatus[selectedCar.id]?.position
      );

      const [carX, carY] = _viewport.project([
        carPosition?.lng!,
        carPosition?.lat!,
      ]);
      const DRAWER_OFFSET = isDesktop() ? 400 : 0;

      const x = carX + DRAWER_OFFSET / 2;
      const y = carY;

      const [lng, lat] = _viewport.unproject([x, y]);
      setTimeout(
        () =>
          dispatch(
            setMapCenterAndZoom({
              center: { lng, lat },
              zoom: SELECTED_CAR_ZOOM,
            })
          ),
        700
      );
    } else if (selectedArea !== null) {
      // Zoom in to selected area
      const areaWithGeoJson = fixGeoJSONCoordinates(selectedArea.geoJSONData);
      // @ts-ignore
      const coordinates = areaWithGeoJson.features[0].geometry.coordinates[0];

      if (coordinates.length === 0) {
        message.warning(
          intl.formatMessage({
            id: 'fleetadmin.area.noCoordinations',
          })
        );
      } else {
        setTimeout(() => fitBounds(coordinates), 700);
      }
    } else {
      // Adjust viewport to displaying cars
      const isDisplayingCarsEmpty =
        displayingCars === null || isEmptyArray(displayingCars);

      if (!isDisplayingCarsEmpty) {
        const points = displayingCars
          ?.filter((car) => car.position !== '')
          .map((car) => locationFromString(car?.position!));

        if (!isEmptyArray(points) && points?.length > 1) {
          fitBounds(points);
        }
      }
    }
  }, [viewType]);

  // Search area effect / center and zoom in to area when filter changes
  useEffect(() => {
    const isNoFilter = filterForArea && Object.keys(filterForArea).length === 0;
    const isDisplayingAreasEmpty =
      displayingAreas === null || isEmptyArray(displayingAreas);

    if (!isDisplayingAreasEmpty && !isNoFilter) {
      const points = displayingAreas
        ?.filter(
          (area) =>
            // @ts-ignore
            !isEmptyArray(area.geoJSONData.features[0].geometry.coordinates)
        )
        .map((area) => fixGeoJSONCoordinates(area.geoJSONData))
        .reduce((acc: any[], areaWithGeoJson) => {
          const coordinates =
            // @ts-ignore
            areaWithGeoJson.features[0].geometry.coordinates[0];
          return [...acc, ...coordinates];
        }, []);
      if (!isEmptyArray(points)) {
        fitBounds(points);
      }
    }
  }, [displayingAreas, filterForArea]);

  const handleOnClick = (e: { target: { id: string | string[] } }) => {
    const isTargetArea = e.target.id.includes('area-polygon');

    if (!isTargetArea) {
      if (visibleCarDrawer) {
        dispatch(closeCarDrawerAndSetQueries());
      }
      if (visibleAreaDrawer) {
        dispatch(closeAreaDrawerAndSetQueries());
      }
      dispatch(selectClaim(null));
    }
  };

  return (
    <>
      <MapGL
        ref={mapEl}
        {...viewport}
        onViewportChange={(viewport) => setViewport(viewport)}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        dragRotate={false}
        touchRotate={false}
        attributionControl={false}
        onClick={handleOnClick}
      >
        {(vm === ViewMode.VEHICLE || vm === ViewMode.CLAIM) &&
          visibleCarMarkers && <CarMarkers />}
        {selectedCar && <SelectedCarArea />}
        {visibleAreaPolygons && <Areas />}
        {visibleParkingAreaPolygons && <ParkingAreas />}
        {(vm === ViewMode.AREA || viewport.zoom > 11) &&
          visibleAreaPolygons && <AreaLabels />}
        {vm === ViewMode.CLAIM && <ClaimMarkers />}
      </MapGL>
      <Widget />
    </>
  );
};

export default Map;
