import React from 'react';
import styles from './ListEditItem.styles';
import { useIntl } from 'react-intl';

interface Props {
  verified?: boolean;
}

const StatusBadge: React.FC<Props> = ({ verified }) => {
  const intl = useIntl();
  return (
    <>
      <img
        title={intl.formatMessage({
          id: `fleetadmin.users.info.${verified ? 'verified' : 'unverified'}`,
        })}
        alt={verified ? 'verified' : 'unverified'}
        src={`/images/drawer/${
          verified ? 'verified_green' : 'verified_grey'
        }.svg`}
        style={{
          width: 16,
          height: 16,
          marginRight: 4,
          marginBottom: 2,
        }}
      />

      <style jsx>{styles}</style>
    </>
  );
};

export default StatusBadge;
