import React, { ReactNode } from 'react';
import { timelineStyles } from './ClaimStatusItem.styles';
import cn from 'classnames';

type ClaimStatusItemProps = {
  title: string;
  date?: string;
  icon: string;
  iconSize: number;
  hiddenTopBar?: boolean;
  hiddenBottomBar?: boolean;
  body: ReactNode;
  editable: boolean;
  onPressEdit: () => void;
};

const ClaimStatusItem: React.FC<ClaimStatusItemProps> = ({
  title,
  date,
  icon,
  iconSize,
  hiddenTopBar,
  hiddenBottomBar,
  body,
  editable,
  onPressEdit,
}) => {
  return (
    <>
      <div className={cn('timeline-item-wrapper')}>
        <div className="left">
          <div
            className={cn('first', 'bar', {
              hidden: hiddenTopBar,
            })}
          />
          <img
            className="icon"
            style={{
              width: iconSize,
              height: iconSize,
              marginLeft: 16 - iconSize / 2,
            }}
            src={icon}
          />
          <div
            className={cn('second', ' bar', {
              hidden: hiddenBottomBar,
            })}
          />
        </div>
        <div className="right" style={{ paddingTop: 8 + iconSize / 2 }}>
          <div className="header">
            <p className="title">
              {title}
              <span className="date-time">{date}</span>
            </p>
            {editable && (
              <img
                className="icon"
                style={{
                  width: 20,
                  height: 20,
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
                onClick={onPressEdit}
                src="/images/timeline/pencil.svg"
              />
            )}
          </div>
          <div className="body">{body}</div>
        </div>
      </div>
      <style jsx>{timelineStyles}</style>
    </>
  );
};

export default ClaimStatusItem;
