import { requestAPI, updateAPI } from '../../../../utils/request';
import {
  usersRequest,
  usersSuccess,
  usersFailure,
  archiveSuccess,
  setDisplayUsers,
  usersUpdateRequest,
  userUpdateSuccess,
  usersUpdateFailure,
  paymentDeleteRequest,
  paymentDeleteSuccess,
  paymentDeleteFailure,
  generatePDFRequest,
  generatePDFSuccess,
  generatePDFFailure,
  setTotalUsers,
  syncRequest,
  syncSuccess,
  syncFailure,
  userDuplicatesRequest,
  userDuplicatesFailure,
  userDuplicatesSuccess,
  userDuplicateSuccess,
  downloadGDPRRequest,
  downloadGDPRSuccess,
  downloadGDPRFailure,
  tariffsRequest,
  tariffsSuccess,
  tariffsFailure,
  userTransactionsSuccess,
  userTransactionsRequest,
  userTransactionsFailure,
} from './actions';
import message from '../../../../utils/message';
import { localize } from '../../../helpers/localize';
import { APIFilterParams } from '../../ui/filters';
import { downloadZip } from '../../../../utils/downloadZip';

export const fetchUsers =
  (
    offset: number,
    limit: number,
    successCallback?: Function,
    filterParams?: APIFilterParams
  ) =>
  (dispatch: any) => {
    dispatch(usersRequest());
    requestAPI('/fleets/users', {
      offset: offset.toString(),
      limit: limit.toString(),
      ...filterParams,
    })
      .then((data) => {
        dispatch(usersSuccess(data.users));
        dispatch(setDisplayUsers(data.users));
        dispatch(setTotalUsers(data.totalCount));
      })
      .catch((err) => {
        dispatch(usersFailure(err));
        message.error(err.message);
      });
  };

export const updateUser = updateAPI(
  usersUpdateRequest,
  userUpdateSuccess,
  usersUpdateFailure,
  '/fleets/users/'
);

export const blockUser =
  (userId: string, params: any) => (dispatch: any, getState: any) => {
    dispatch(usersUpdateRequest());
    requestAPI(`/fleets/users/${userId}/block`, params, { method: 'PUT' })
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(localize('update.success', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const unblockUser =
  (userId: string, params: any) => (dispatch: any, getState: any) => {
    dispatch(usersUpdateRequest());
    requestAPI(`/fleets/users/${userId}/unblock`, params, { method: 'PUT' })
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(localize('update.success', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const resetUserPassword = (userId: string) => (dispatch: any) => {
  dispatch(usersUpdateRequest());
  requestAPI(`/fleets/users/${userId}/resetPassword`, {}, { method: 'PUT' })
    .then((user) => {
      dispatch(userUpdateSuccess(user));
      message.success('Reset password triggered');
    })
    .catch((err) => {
      dispatch(usersUpdateFailure(err));
      message.error(err.message);
    });
};

export const getGDPR = (userId: string) => (dispatch: any) => {
  dispatch(downloadGDPRRequest());
  requestAPI(
    `/fleets/users/${userId}/gdpr`,
    {},
    { method: 'GET', headers: { Accept: 'application/zip' } }
  )
    .then((res) => {
      downloadZip(res, () => {
        dispatch(downloadGDPRSuccess());
      });
    })
    .catch((err) => {
      message.error(err.message);
      dispatch(downloadGDPRFailure(err));
    });
};

export const archiveUser =
  (userId: string) => (dispatch: any, getState: any) => {
    dispatch(usersUpdateRequest());
    requestAPI(`/fleets/users/${userId}`, {}, { method: 'DELETE' })
      .then((user) => {
        dispatch(archiveSuccess(user));
        message.success(localize('notification.user.deleted', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const deletePayment = (id: string) => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.users;
  dispatch(paymentDeleteRequest());
  requestAPI(
    `/fleets/users/${selected.id}/paymentMethods/${id}`,
    {},
    { method: 'DELETE' }
  )
    .then((user) => {
      dispatch(paymentDeleteSuccess(user));
      message.success(localize('notification.payment.deleted', getState));
    })
    .catch((err) => {
      dispatch(paymentDeleteFailure(err));
      message.error(err.message);
    });
};

export const unpairDevice =
  (uuid: string) => (dispatch: any, getState: any) => {
    const { selected } = getState()?.userData.users;
    dispatch(usersUpdateRequest());
    requestAPI(
      `/fleets/users/${selected.id}/devices/${uuid}`,
      {},
      { method: 'DELETE' }
    )
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(localize('notification.device.unpaired', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const setDefaultPayment =
  (id: string) => (dispatch: any, getState: any) => {
    const { selected } = getState()?.userData.users;
    dispatch(paymentDeleteRequest());
    requestAPI(
      `/fleets/users/${selected.id}/paymentMethods/${id}/default`,
      {},
      { method: 'PUT' }
    )
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(localize('notification.payment.updated', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const syncHAL = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.users;
  dispatch(syncRequest());
  requestAPI(`/fleets/users/${selected.id}/sync/hal`, {}, { method: 'PUT' })
    .then((user) => {
      dispatch(syncSuccess(user));
      message.success(localize('notification.synced', getState));
    })
    .catch((err) => {
      dispatch(syncFailure(err));
      message.error(err.message);
    });
};

export const syncEBUS = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.users;
  dispatch(syncRequest());
  requestAPI(`/fleets/users/${selected.id}/sync/ebus`, {}, { method: 'PUT' })
    .then((user) => {
      dispatch(syncSuccess(user));
      message.success(localize('notification.synced', getState));
    })
    .catch((err) => {
      dispatch(syncFailure(err));
      message.error(err.message);
    });
};

export const cancelDeposit = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.users;
  dispatch(syncRequest());
  requestAPI(
    `/fleets/users/${selected?.id}/deposit/refund`,
    {},
    { method: 'POST' }
  )
    .then((user) => {
      dispatch(syncSuccess(user));
      message.success(
        localize('fleetadmin.notification.deposit.refunded', getState)
      );
    })
    .catch((err) => {
      dispatch(syncFailure(err));
      message.error(err.message);
    });
};

export const generateSignupCertificate =
  (callback: (user: any) => void) => (dispatch: any, getState: any) => {
    const { selected } = getState()?.userData.users;

    dispatch(generatePDFRequest());
    requestAPI(
      `/fleets/users/${selected.id}/signup-certificate`,
      {},
      {
        headers: {
          Accept: 'application/pdf',
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        dispatch(generatePDFSuccess());
        callback(blob);
      })
      .catch((err) => {
        dispatch(generatePDFFailure(err));
        message.error(err.message);
      });
  };

export const addUserDL =
  (userId: string, DL: any) => (dispatch: any, getState: any) => {
    dispatch(usersUpdateRequest());
    requestAPI(
      `/fleets/users/${userId}/license`,
      {},
      { method: 'POST', body: JSON.stringify(DL) }
    )
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(
          localize('notification.user.drivinglicense.added', getState)
        );
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const addUserID =
  (userId: string, DL: any) => (dispatch: any, getState: any) => {
    dispatch(usersUpdateRequest());
    requestAPI(
      `/fleets/users/${userId}/identityDocument`,
      {},
      { method: 'POST', body: JSON.stringify(DL) }
    )
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        message.success(localize('notification.user.id.added', getState));
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const deleteUserLicense = () => (dispatch: any, getState: any) => {
  const { selected } = getState()?.userData.users;
  dispatch(usersUpdateRequest());
  return requestAPI(
    `/fleets/users/${selected.id}/license`,
    {},
    { method: 'DELETED' }
  )
    .then((user) => {
      dispatch(userUpdateSuccess(user));
      message.success(
        localize('notification.user.drivinglicense.deleted', getState)
      );
    })
    .catch((err) => {
      dispatch(usersUpdateFailure(err));
      message.error(err.message);
    });
};

export const fetchUserById =
  (userId: string, callback: (arg: any) => void = () => {}) =>
  (dispatch: any) => {
    dispatch(usersUpdateRequest());
    return requestAPI(`/fleets/users/${userId}`, {})
      .then((user) => {
        dispatch(userUpdateSuccess(user));
        callback(user);
      })
      .catch((err) => {
        dispatch(usersUpdateFailure(err));
        message.error(err.message);
      });
  };

export const fetchDuplicateById = (userId: string) => (dispatch: any) => {
  dispatch(usersUpdateRequest());
  return requestAPI(`/fleets/users/${userId}`, {})
    .then((user) => {
      dispatch(userDuplicateSuccess(user));
    })
    .catch((err) => {
      dispatch(usersUpdateFailure(err));
      message.error(err.message);
    });
};

export const fetchUserDuplicates = (userId: string) => (dispatch: any) => {
  dispatch(userDuplicatesRequest());
  return requestAPI(`/fleets/users/${userId}/duplicates`, {})
    .then((duplicates) => {
      dispatch(
        userDuplicatesSuccess({ duplicates: duplicates?.users, id: userId })
      );
    })
    .catch((err) => {
      dispatch(userDuplicatesFailure(err));
      message.error(err.message);
    });
};

export const fetchTariffs = (brandId: string) => (dispatch: any) => {
  dispatch(tariffsRequest());
  return requestAPI(`/fleets/tariffs`, { brandId })
    .then((tariffs) => {
      dispatch(tariffsSuccess(tariffs));
    })
    .catch((err) => {
      dispatch(tariffsFailure(err));
      message.error(err.message);
    });
};

export const fetchUserTransactions =
  (
    walletType: string,
    userId: string,
    offset = 0,
    limit = 20,
    mergeTransactions = false,
    successCallback = () => {}
  ) =>
  (dispatch: any) => {
    dispatch(userTransactionsRequest());
    return requestAPI(`/fleets/users/${userId}/transactions`, {
      walletType,
      limit: limit.toString(),
      offset: offset.toString(),
    })
      .then((transactions) => {
        if (transactions) {
          dispatch(
            userTransactionsSuccess({
              transactions: { ...transactions, userId, mergeTransactions },
            })
          );
          successCallback();
        }
      })
      .catch((err) => {
        dispatch(userTransactionsFailure(err));
        message.error(err.message);
      });
  };
