import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { timelineStyles } from './ClaimStatus.styles';
import {
  selectSelectedClaim,
  selectAssignableClaimTrips,
  selectIsClaimUpdating,
} from '../../redux/store/userData/claims';
import ClaimStatusItem from '../TimeLine/ClaimStatusItem';
import moment from 'moment';
import ListEditItem from '../../components/shared/ListEditItem';
import ListItem from '../../components/shared/ListItem';
import ImageAdded from '../TimeLine/ImageAdded';
import { Button, Checkbox, Input, DatePicker } from 'antd';
import { colors } from '../../theme/theme';
import { selectCars, selectCar } from '../../redux/store/userData/cars';
import { setCarEventsFilters } from '../../redux/store/userData/vehicleEvents';
import TextSearch from '../../components/Filter/TextSearch';
import {
  updateClaimIfNeeded,
  fetchClaimsByCarId,
} from '../../redux/store/userData/claims';
import { selectToken } from '../../redux/store/userData/user';
import { isAfter } from 'date-fns';
import {
  selectTripInvoiceData,
  fetchTripInvoiceData,
} from '../../redux/store/userData/trips';
import { setCarDrawerActiveTab } from '../../redux/store/userData/cars';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import {
  fetchVehicleEventsForCar,
  fetchVehicleEvents,
} from '../../redux/store/userData/vehicleEvents';
import { Tag } from 'antd';
import { setDisplayColumns, openModal } from '../../redux/store/ui/common';
import { defaultSelectedRecordColumns } from '../ColumnSelector/recordColumns';
import getTheDayBefore from '../../utils/datetime/getTheDayBefore';
import getOneHourAfter from '../../utils/datetime/getOneHourAfter';
import { useIntl } from 'react-intl';
import DeclineReasonModal from '../../components/ClaimDrawer/DeclineReasonModal';
import { ANT_PICKERS_FORMAT } from '../../utils';
import dayjs from 'dayjs';

const IMAGE_PATH = '/images/timeline';

type ClaimStatusProps = {
  claimStatus: string;
  closeReason: string;
};

const ClaimStatus: React.FC<ClaimStatusProps> = ({
  claimStatus,
  closeReason,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const cars = useSelector(selectCars);
  const tripInvoiceData = useSelector(selectTripInvoiceData);
  const selectedClaim = useSelector(selectSelectedClaim);
  const assignableClaimTrips = useSelector(selectAssignableClaimTrips);
  const vehicle = cars?.find((car) => car?.plate === selectedClaim?.plate);
  const isClaimUpdating = useSelector(selectIsClaimUpdating);

  const [costCategory, setCostCategory] = useState(
    selectedClaim?.costCategory || 'lt150'
  );
  const [estimatedCost, setEstimatedCost] = useState(
    selectedClaim?.estimatedCost! / 100 || 0
  );
  const [assignedTripId, setAssignedTripId] = useState(
    selectedClaim?.assignedTripId || ''
  );
  const [invoiceDate, setInvoiceDate] = useState(
    selectedClaim?.invoiceDate || ''
  );
  const [invoiceAmount, setInvoiceAmount] = useState(
    selectedClaim?.invoiceAmount! / 100 || 0
  );
  const [invoiceReference, setInvoiceReference] = useState(
    selectedClaim?.invoiceRef || ''
  );

  const [editConfirmStep, setEditConfirmStep] = useState(false);
  const [editCompleteStep, setEditCompleteStep] = useState(false);
  const [editInvoiceStep, setEditInvoiceStep] = useState(false);

  const [confirmedNote, setConfirmedNote] = useState(
    selectedClaim?.confirmedNote || ''
  );
  const [completedNote, setCompletedNote] = useState(
    selectedClaim?.completedNote || ''
  );
  const [closedNote, setClosedNote] = useState(selectedClaim?.closedNote || '');

  const isAfterIgnition = isAfter(
    new Date(selectedClaim?.addedAt!),
    new Date(selectedClaim?.initialIgnitionOn!)
  );

  const tripDataSource = assignableClaimTrips?.length
    ? [
        ...(selectedClaim?.tripPresentableId
          ? [
              {
                value: selectedClaim?.tripPresentableId,
                text: selectedClaim?.tripPresentableId,
                id: selectedClaim?.tripId,
              },
            ]
          : []),

        ...(assignableClaimTrips || []).map((value) => {
          return {
            value: value.code,
            text: value.code,
            id: value.id,
          };
        }),
      ]
    : [];

  const onClickVehicleTimeline = () => {
    if (vehicle) {
      // @ts-ignore
      const { addedAt, tripStartedAt, tripFinishedAt } = selectedClaim;
      const from = getTheDayBefore(tripStartedAt || addedAt).toISOString();
      const to = getOneHourAfter(tripFinishedAt || addedAt).toISOString();
      dispatch(setCarEventsFilters(['Claims']));
      dispatch(setCarDrawerActiveTab(1));
      dispatch(selectCar(vehicle));
      dispatch(fetchVehicleEventsForCar(vehicle.id));
      dispatch(
        fetchVehicleEvents(
          token,
          vehicle.id,
          from,
          to,
          ['damageClaim'],
          'CLAIM',
          false
        )
      );

      dispatch(fetchClaimsByCarId(vehicle?.id));
      setDisplayColumns({ record: defaultSelectedRecordColumns });
    }
  };

  const onClickDamageRecord = () => {
    if (vehicle) {
      // @ts-ignore
      const { addedAt, tripStartedAt, tripFinishedAt } = selectedClaim;
      const from = getTheDayBefore(tripStartedAt || addedAt).toISOString();
      const to = getOneHourAfter(tripFinishedAt || addedAt).toISOString();
      dispatch(selectCar(vehicle));
      dispatch(setCarDrawerActiveTab(4));
      dispatch(fetchClaimsByCarId(vehicle?.id));
      setDisplayColumns({ record: defaultSelectedRecordColumns });

      dispatch(fetchVehicleEventsForCar(vehicle.id));
      dispatch(
        fetchVehicleEvents(
          token,
          vehicle.id,
          from,
          to,
          ['damageClaim'],
          'CLAIM',
          false
        )
      );
    }
  };
  const onClickConfirm = () => {
    setEditConfirmStep(false);
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'confirmed',
            confirmedNote,
            closeReason: '',
          },
          () =>
            setTimeout(() => {
              dispatch(fetchClaimsByCarId(vehicle?.id));
            }, 1000)
        )
      );
    });
  };

  const onClickComplete = () => {
    setEditCompleteStep(false);
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'completed',
            completedNote,
            closeReason: '',
            estimatedCost: estimatedCost,
            assignedTripId,
            costCategory,
          },
          (data) => {
            if (data?.assignedTripId) {
              dispatch(fetchTripInvoiceData(data.assignedTripId));
              setTimeout(() => {
                dispatch(fetchClaimsByCarId(vehicle?.id));
              }, 1000);
            }
          }
        )
      );
    });
  };

  const onClickCreateInvoice = () => {
    setEditInvoiceStep(false);
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'closed',
            closedNote,
            closeReason: 'invoiced',
            invoiceDate,
            invoiceAmount: invoiceAmount,
            invoiceRef: invoiceReference,
          },
          () =>
            setTimeout(() => {
              dispatch(fetchClaimsByCarId(vehicle?.id));
            }, 1000)
        )
      );
    });
  };

  const onClickCloseAsDeclined = () => {
    dispatch(openModal('DeclineReasonModal'));
  };
  const onClickCloseAsIncomplete = () => {
    setEditCompleteStep(false);
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'closed',
            closedNote: completedNote,
            closeReason: 'incomplete',
          },
          () =>
            setTimeout(() => {
              dispatch(fetchClaimsByCarId(vehicle?.id));
            }, 1000)
        )
      );
    });
  };
  const onClickCloseAsWontInvoice = () => {
    setEditInvoiceStep(false);
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'closed',
            closedNote,
            closeReason: 'wontInvoice',
          },
          () =>
            setTimeout(() => {
              dispatch(fetchClaimsByCarId(vehicle?.id));
            }, 1000)
        )
      );
    });
  };

  const onClickCancelOpenClaim = () => {
    batch(() => {
      dispatch(
        updateClaimIfNeeded(
          token,
          selectedClaim?.damageId!,
          selectedClaim?.carId!,
          {
            status: 'open',
          }
        )
      );
    });
  };

  useEffect(() => {
    setEditConfirmStep(false);
    setEditCompleteStep(false);
    setEditInvoiceStep(false);
    setCostCategory(selectedClaim?.costCategory || 'lt150');
    setEstimatedCost(selectedClaim?.estimatedCost! / 100 || 0);
    setAssignedTripId(selectedClaim?.assignedTripId || '');
    setInvoiceDate(selectedClaim?.invoiceDate || '');
    setInvoiceAmount(selectedClaim?.invoiceAmount! / 100 || 0);
    setInvoiceReference(selectedClaim?.invoiceRef || '');
    setConfirmedNote(selectedClaim?.confirmedNote || '');
    setCompletedNote(selectedClaim?.completedNote || '');
    setClosedNote(selectedClaim?.closedNote || '');
  }, [selectedClaim]);

  const damageConfirmBody =
    claimStatus === 'open' || editConfirmStep ? (
      <div>
        <ListEditItem
          label={intl.formatMessage({
            id: 'fleetadmin.claim.vehicle.label',
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={<div>{selectedClaim?.plate}</div>}
          rightItem={
            <div
              className="damage-record-wrapper"
              onClick={onClickDamageRecord}
            >
              <div className="damage-record-label">
                {intl.formatMessage({
                  id: `fleetadmin.claim.records`,
                })}
              </div>
              <img
                className="arrow-right"
                src={'/images/timeline/arrowRight.svg'}
                alt="edit"
              />
            </div>
          }
        />
        {(selectedClaim?.damageWhere ||
          selectedClaim?.damageLocation ||
          selectedClaim?.damageSide) && (
          <ListItem
            style={{ marginLeft: 0 }}
            label={intl.formatMessage({
              id: `fleetadmin.damage.location`,
            })}
            value={
              `${
                selectedClaim?.damageWhere
                  ? intl.formatMessage({
                      id: `fleetadmin.damage.where.${selectedClaim?.damageWhere}`,
                    })
                  : ''
              }` +
              `, ${
                selectedClaim?.damageSide
                  ? intl.formatMessage({
                      id: `fleetadmin.damage.side.${selectedClaim?.damageSide}`,
                    })
                  : ''
              }` +
              `, ${
                selectedClaim?.damageLocation
                  ? intl.formatMessage({
                      id: `fleetadmin.damage.location.${selectedClaim?.damageLocation}`,
                    })
                  : ''
              }`
            }
          />
        )}
        {selectedClaim?.damageComponent && (
          <ListItem
            style={{ marginLeft: 0 }}
            label={intl.formatMessage({
              id: `fleetadmin.damage.component`,
            })}
            value={intl.formatMessage({
              id: `fleetadmin.damage.component.${selectedClaim.damageComponent}`,
            })}
          />
        )}
        <ListItem
          style={{ marginLeft: 0 }}
          label={intl.formatMessage({
            id: `fleetadmin.claim.description`,
          })}
          value={selectedClaim?.damageDescription}
        />
        <ImageAdded
          vehicleEvent={{
            ...selectedClaim,
            images: selectedClaim?.damageImages,
          }}
        />

        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.note`,
          })}
          style={{ margin: '20px 24px 10px 0px' }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={confirmedNote}
              type="text"
              // @ts-ignore
              onChange={(event) => setConfirmedNote(event.target.value)}
            />
          }
          rightItem={null}
        />
        <Button
          className="submit-button"
          onClick={onClickConfirm}
          loading={isClaimUpdating}
        >
          {intl.formatMessage({
            id: `fleetadmin.claim.button.confirm`,
          })}
        </Button>
        <div className="close" onClick={onClickCloseAsDeclined}>
          {intl.formatMessage({
            id: `fleetadmin.claim.button.close.declined`,
          })}
        </div>
        <style jsx>{timelineStyles}</style>
      </div>
    ) : (
      <>
        <pre>{`by ${
          selectedClaim?.confirmedBy
            ? selectedClaim.confirmedBy
            : selectedClaim?.closedBy
        }`}</pre>
        {closeReason !== 'declined' && selectedClaim?.confirmedNote && (
          <p>{`${intl.formatMessage({
            id: `fleetadmin.claim.note.label`,
          })} ${selectedClaim?.confirmedNote}`}</p>
        )}
        {claimStatus === 'closed' &&
          closeReason === 'declined' &&
          selectedClaim?.closedNote && (
            <p>{`${intl.formatMessage({
              id: `fleetadmin.claim.note.label`,
            })} ${selectedClaim?.closedNote}`}</p>
          )}
        {claimStatus === 'closed' &&
          closeReason === 'declined' &&
          selectedClaim?.declineReason && (
            <p>{`${intl.formatMessage({
              id: `fleetadmin.claim.reason.label`,
            })} ${selectedClaim?.declineReason}`}</p>
          )}
      </>
    );

  const damageCompleteBody =
    claimStatus === 'confirmed' || editCompleteStep ? (
      <div>
        <div className="estimated-category">
          <div className="estimated-category-label">
            {intl.formatMessage({
              id: `fleetadmin.claim.estimation.category.label`,
            })}
          </div>
          <Checkbox
            checked={
              costCategory === 'gt150' ||
              costCategory === 'gt500' ||
              costCategory === 'gt1200'
            }
            onChange={(e) =>
              e?.target?.checked
                ? setCostCategory('gt150')
                : setCostCategory('lt150')
            }
          >
            {intl.formatMessage({
              id: `fleetadmin.claim.cost.150`,
            })}
          </Checkbox>
          <Checkbox
            checked={costCategory === 'gt500' || costCategory === 'gt1200'}
            onChange={(e) =>
              e?.target?.checked
                ? setCostCategory('gt500')
                : setCostCategory('gt150')
            }
          >
            {intl.formatMessage({
              id: `fleetadmin.claim.cost.500`,
            })}
          </Checkbox>
          <Checkbox
            checked={costCategory === 'gt1200'}
            onChange={(e) =>
              e?.target?.checked
                ? setCostCategory('gt1200')
                : setCostCategory('gt500')
            }
          >
            {intl.formatMessage({
              id: `fleetadmin.claim.cost.1200`,
            })}
          </Checkbox>
        </div>
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.estimation.value.label`,
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={estimatedCost}
              type="number"
              // @ts-ignore
              onChange={(event) => setEstimatedCost(event.target.value)}
            />
          }
          rightItem={null}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.reported.in`,
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={
            <div className="reported-trip">
              <div
                style={{ marginRight: 4 }}
              >{`${selectedClaim?.tripPresentableId} (${selectedClaim?.tripMerchantRef})  |`}</div>
              {selectedClaim?.initialIgnitionOn && (
                <Tag style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '2px',
                      marginTop: '2px',
                    }}
                  >
                    {isAfterIgnition
                      ? intl.formatMessage({
                          id: `fleetadmin.claim.after`,
                        })
                      : intl.formatMessage({
                          id: `fleetadmin.claim.before`,
                        })}
                  </span>
                  {intl.formatMessage({
                    id: `fleetadmin.claim.initial.ignition`,
                  })}
                </Tag>
              )}
            </div>
          }
          rightItem={null}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: 'fleetadmin.claim.vehicle.label',
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={<div>{selectedClaim?.plate}</div>}
          rightItem={
            <div
              className="damage-record-wrapper"
              onClick={onClickVehicleTimeline}
            >
              <div className="damage-record-label">
                {intl.formatMessage({
                  id: 'fleetadmin.claim.timeline.label',
                })}
              </div>
              <img
                className="arrow-right"
                src={'/images/timeline/arrowRight.svg'}
                alt="edit"
              />
            </div>
          }
        />
        {intl.formatMessage({
          id: 'fleetadmin.claim.trip.label',
        })}
        <TextSearch
          style={{ padding: 0, paddingTop: 4, width: '100%' }}
          dataSource={tripDataSource}
          defaultValue={assignedTripId}
          setValue={(value, option) => {
            setAssignedTripId(option?.id);
          }}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.note`,
          })}
          style={{ margin: '20px 24px 10px 0px' }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={completedNote}
              type="text"
              // @ts-ignore
              onChange={(event) => setCompletedNote(event.target.value)}
            />
          }
          rightItem={null}
        />
        <Button
          className="submit-button"
          style={
            assignedTripId === '' || !costCategory
              ? { background: colors.buttonDisabled }
              : {}
          }
          disabled={
            assignedTripId === '' ||
            !costCategory ||
            !estimatedCost ||
            estimatedCost === 0
          }
          loading={isClaimUpdating}
          onClick={onClickComplete}
        >
          {intl.formatMessage({
            id: `fleetadmin.claim.button.complete`,
          })}
        </Button>
        <div className="close" onClick={onClickCloseAsIncomplete}>
          {intl.formatMessage({
            id: `fleetadmin.claim.button.close.incomplete`,
          })}
        </div>
        <style jsx>{timelineStyles}</style>
      </div>
    ) : claimStatus === 'open' ? null : (
      <>
        <pre>{`by ${
          selectedClaim?.completedBy
            ? selectedClaim.completedBy
            : selectedClaim?.closedBy
        }`}</pre>
        {closeReason !== 'incomplete' && selectedClaim?.completedNote && (
          <p>{`${intl.formatMessage({
            id: `fleetadmin.claim.note.label`,
          })} ${selectedClaim?.completedNote}`}</p>
        )}
        {claimStatus === 'closed' &&
          closeReason === 'incomplete' &&
          selectedClaim?.closedNote && (
            <p>{`${intl.formatMessage({
              id: `fleetadmin.claim.note.label`,
            })} ${selectedClaim?.closedNote}`}</p>
          )}
        {selectedClaim?.estimatedCost !== 0 && (
          <div style={{ display: 'flex', marginTop: 10 }}>
            <img className="dot" src={'/images/timeline/dot.svg'} alt="edit" />
            <div>
              {`${intl.formatMessage({
                id: `fleetadmin.claim.estimation.value.label`,
              })} :`}
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '4px' }}>{`${
              selectedClaim?.estimatedCost! / 100
            }€`}</div>
          </div>
        )}
        {tripInvoiceData?.tripCode && (
          <div style={{ display: 'flex' }}>
            <img className="dot" src={'/images/timeline/dot.svg'} alt="edit" />
            <div>
              {`${intl.formatMessage({
                id: `fleetadmin.claim.trip.label`,
              })} :`}
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '4px' }}>
              {`${tripInvoiceData?.tripCode} (${tripInvoiceData?.tripMerchantRef})`}
            </div>
          </div>
        )}
        <style jsx>{timelineStyles}</style>
      </>
    );

  const damageInvoiceBody =
    claimStatus === 'completed' || editInvoiceStep ? (
      <div>
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.insurance.label`,
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={
            tripInvoiceData?.insurance ? (
              <div>{tripInvoiceData?.insurance}</div>
            ) : null
          }
          rightItem={
            tripInvoiceData?.invoiceUri ? (
              <a
                className="damage-record-wrapper"
                href={tripInvoiceData.invoiceUri}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="damage-record-label">
                  {intl.formatMessage({
                    id: `fleetadmin.claim.invoice.label`,
                  })}
                </div>
                <img
                  className="arrow-right"
                  src={'/images/timeline/download.svg'}
                  alt="edit"
                />
              </a>
            ) : null
          }
        />
        {tripInvoiceData?.userRef && tripInvoiceData?.userMerchantRef && (
          <ListEditItem
            label={intl.formatMessage({
              id: `fleetadmin.claim.applied.invoice.label`,
            })}
            style={{ marginLeft: 0, marginTop: 10 }}
            leftItem={
              <div>
                {`${tripInvoiceData?.userRef} (${tripInvoiceData?.userMerchantRef})`}
              </div>
            }
            rightItem={null}
          />
        )}
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.invoice.amount.label`,
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={invoiceAmount}
              type="number"
              // @ts-ignore
              onChange={(event) => setInvoiceAmount(event.target.value)}
            />
          }
          rightItem={null}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.invoice.ref.label`,
          })}
          style={{ marginLeft: 0, marginTop: 10 }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={invoiceReference}
              type="text"
              // @ts-ignore
              onChange={(event) => setInvoiceReference(event.target.value)}
            />
          }
          rightItem={null}
        />
        <div
          style={{
            marginTop: '16px',
            marginRight: '24px',
            borderBottom: '1px solid #979797',
            paddingBottom: '2px',
          }}
        >
          <p className="label">
            {intl.formatMessage({
              id: `fleetadmin.claim.invoice.date.label`,
            })}
          </p>
          <DatePicker
            format={ANT_PICKERS_FORMAT}
            getPopupContainer={(triggerNode: HTMLElement) => {
              return triggerNode.parentNode as HTMLElement;
            }}
            style={{ width: '100%' }}
            placeholder="select date"
            value={invoiceDate ? dayjs(invoiceDate) : undefined}
            // @ts-ignore
            onChange={(_, dateString) => setInvoiceDate(dateString)}
          />
        </div>
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.claim.note`,
          })}
          style={{ margin: '20px 24px 10px 0px' }}
          leftItem={
            <Input
              style={{ marginTop: 4 }}
              value={closedNote}
              type="text"
              // @ts-ignore
              onChange={(event) => setClosedNote(event.target.value)}
            />
          }
          rightItem={null}
        />
        <Button
          className="submit-button"
          style={
            invoiceReference === '' ||
            !invoiceReference ||
            invoiceAmount === 0 ||
            !invoiceAmount
              ? { background: colors.buttonDisabled }
              : {}
          }
          disabled={
            invoiceReference === '' ||
            !invoiceReference ||
            invoiceAmount === 0 ||
            !invoiceAmount ||
            !invoiceAmount ||
            invoiceAmount === 0
          }
          loading={isClaimUpdating}
          onClick={onClickCreateInvoice}
        >
          {intl.formatMessage({
            id: `fleetadmin.claim.button.invoice`,
          })}
        </Button>
        <div className="close" onClick={onClickCloseAsWontInvoice}>
          {intl.formatMessage({
            id: `fleetadmin.claim.button.close.wontinvoice`,
          })}
        </div>
        <style jsx>{timelineStyles}</style>
      </div>
    ) : claimStatus === 'open' ? null : (
      <>
        {claimStatus === 'closed' && (
          <pre>{`by ${selectedClaim?.closedBy}`}</pre>
        )}
        {selectedClaim?.closedNote && (
          <p>{`${intl.formatMessage({
            id: `fleetadmin.claim.note.label`,
          })} ${selectedClaim?.closedNote}`}</p>
        )}
        {claimStatus === 'closed' &&
          closeReason === 'invoiced' &&
          tripInvoiceData?.userRef && (
            <div style={{ display: 'flex', marginTop: 10 }}>
              <img
                className="dot"
                src={'/images/timeline/dot.svg'}
                alt="edit"
              />
              <div style={{ whiteSpace: 'nowrap' }}>
                {`${intl.formatMessage({
                  id: `fleetadmin.claim.applied.invoice.label`,
                })} :`}
              </div>
              <div
                style={{ fontWeight: 'bold', marginLeft: '4px' }}
              >{`${tripInvoiceData?.userRef} (${tripInvoiceData?.userMerchantRef})`}</div>
            </div>
          )}
        {claimStatus === 'closed' &&
          closeReason === 'invoiced' &&
          selectedClaim?.invoiceAmount && (
            <div style={{ display: 'flex' }}>
              <img
                className="dot"
                src={'/images/timeline/dot.svg'}
                alt="edit"
              />
              <div style={{ whiteSpace: 'nowrap' }}>
                {`${intl.formatMessage({
                  id: `fleetadmin.claim.invoice.amount.label`,
                })} :`}
              </div>
              <div style={{ fontWeight: 'bold', marginLeft: '4px' }}>{`${
                selectedClaim?.invoiceAmount / 100
              }€`}</div>
            </div>
          )}
        {claimStatus === 'closed' &&
          closeReason === 'invoiced' &&
          selectedClaim?.invoiceRef && (
            <div style={{ display: 'flex' }}>
              <img
                className="dot"
                src={'/images/timeline/dot.svg'}
                alt="edit"
              />
              <div style={{ whiteSpace: 'nowrap' }}>
                {`${intl.formatMessage({
                  id: `fleetadmin.claim.invoice.ref.label`,
                })} :`}
              </div>
              <div
                style={{ fontWeight: 'bold', marginLeft: '4px' }}
              >{`${selectedClaim?.invoiceRef}`}</div>
            </div>
          )}
        {claimStatus === 'closed' &&
          closeReason === 'invoiced' &&
          selectedClaim?.invoiceDate && (
            <div style={{ display: 'flex' }}>
              <img
                className="dot"
                src={'/images/timeline/dot.svg'}
                alt="edit"
              />
              <div style={{ whiteSpace: 'nowrap' }}>
                {`${intl.formatMessage({
                  id: `fleetadmin.claim.invoice.date.label`,
                })} :`}
              </div>
              <div style={{ fontWeight: 'bold', marginLeft: '4px' }}>{`${moment(
                selectedClaim?.invoiceDate
              ).format('DD MMM. YYYY')}`}</div>
            </div>
          )}
        {claimStatus === 'closed' && closeReason === 'invoiced' && (
          <div className="cancel-text" onClick={onClickCancelOpenClaim}>
            {intl.formatMessage({
              id: `fleetadmin.claim.button.invoice.cancel`,
            })}
          </div>
        )}
        <style jsx>{timelineStyles}</style>
      </>
    );
  return (
    <>
      <div className="timeline-wrapper">
        <ClaimStatusItem
          title={intl.formatMessage({
            id: 'fleetadmin.claim.section.reported',
          })}
          date={moment(selectedClaim?.addedAt!).format('YYYY-MM-DD HH:mm')}
          icon={`${IMAGE_PATH}/redDot.svg`}
          body={
            <pre className="reservedAtBody">
              {`by ${selectedClaim?.userReferralCode}|`}
              <LinkToGoogleMap position={selectedClaim?.geoLocation} />
            </pre>
          }
          iconSize={16}
          hiddenTopBar={true}
          editable={false}
          onPressEdit={() => {}}
        />
        <ClaimStatusItem
          title={
            claimStatus === 'open'
              ? intl.formatMessage({
                  id: 'fleetadmin.claim.section.confirm',
                })
              : claimStatus === 'closed' && closeReason === 'declined'
              ? intl.formatMessage({
                  id: 'fleetadmin.claim.section.declined',
                })
              : intl.formatMessage({
                  id: 'fleetadmin.claim.section.confirmed',
                })
          }
          date={
            claimStatus === 'open'
              ? ''
              : selectedClaim?.confirmedAt
              ? moment(selectedClaim?.confirmedAt).format('YYYY-MM-DD HH:mm')
              : moment(selectedClaim?.closedAt).format('YYYY-MM-DD HH:mm')
          }
          icon={`${IMAGE_PATH}/${
            claimStatus === 'open'
              ? 'stepOne.svg'
              : claimStatus === 'closed' && closeReason === 'declined'
              ? 'closeGreen.svg'
              : 'stepOneDone.svg'
          }`}
          iconSize={24}
          body={damageConfirmBody}
          hiddenBottomBar={closeReason === 'declined'}
          editable={claimStatus !== 'open'}
          onPressEdit={() => {
            setEditConfirmStep(!editConfirmStep);
          }}
        />
        {closeReason !== 'declined' && (
          <>
            <ClaimStatusItem
              title={
                claimStatus === 'open' || claimStatus === 'confirmed'
                  ? intl.formatMessage({
                      id: 'fleetadmin.claim.section.complete',
                    })
                  : claimStatus === 'closed' && closeReason === 'incomplete'
                  ? intl.formatMessage({
                      id: 'fleetadmin.claim.section.incomplete',
                    })
                  : intl.formatMessage({
                      id: 'fleetadmin.claim.section.completed',
                    })
              }
              date={
                claimStatus === 'open'
                  ? ''
                  : selectedClaim?.completedAt
                  ? moment(selectedClaim?.completedAt).format(
                      'YYYY-MM-DD HH:mm'
                    )
                  : moment(selectedClaim?.closedAt).format('YYYY-MM-DD HH:mm')
              }
              icon={`${IMAGE_PATH}/${
                claimStatus === 'open' || claimStatus === 'confirmed'
                  ? 'stepTwo.svg'
                  : claimStatus === 'closed' && closeReason === 'incomplete'
                  ? 'closeGreen.svg'
                  : 'stepTowDone.svg'
              }`}
              iconSize={24}
              hiddenBottomBar={closeReason === 'incomplete'}
              body={damageCompleteBody}
              editable={claimStatus === 'completed' || claimStatus === 'closed'}
              onPressEdit={() => {
                setEditCompleteStep(!editCompleteStep);
              }}
            />
            {closeReason !== 'incomplete' && (
              <ClaimStatusItem
                title={
                  claimStatus === 'closed' && closeReason === 'wontInvoice'
                    ? intl.formatMessage({
                        id: 'fleetadmin.claim.invoice.wontinvoice',
                      })
                    : claimStatus === 'closed'
                    ? intl.formatMessage({
                        id: 'fleetadmin.claim.invoice.created',
                      })
                    : intl.formatMessage({
                        id: 'fleetadmin.claim.invoice.create',
                      })
                }
                date={
                  claimStatus === 'closed'
                    ? moment(selectedClaim?.closedAt).format('YYYY-MM-DD HH:mm')
                    : claimStatus === 'open'
                    ? ''
                    : moment(selectedClaim?.closedAt).format('YYYY-MM-DD HH:mm')
                }
                icon={`${IMAGE_PATH}/${
                  claimStatus === 'closed' && closeReason === 'wontInvoice'
                    ? 'closeGreen.svg'
                    : claimStatus === 'closed'
                    ? 'stepThreeDone.svg'
                    : 'stepThree.svg'
                }`}
                iconSize={24}
                body={damageInvoiceBody}
                hiddenBottomBar
                editable={claimStatus === 'closed'}
                onPressEdit={() => {
                  setEditInvoiceStep(!editInvoiceStep);
                }}
              />
            )}
          </>
        )}
      </div>
      <DeclineReasonModal
        vehicle={vehicle}
        confirmedNote={confirmedNote}
        setEditConfirmStep={setEditConfirmStep}
      />
      <style jsx>{timelineStyles}</style>
    </>
  );
};

export default ClaimStatus;
