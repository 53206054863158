import { Brand } from '../@types';

type SupportedArray = string[] | number[] | Brand[];

const deDuplicate = (arr: any[]) => {
  if (typeof arr[0] === 'string' || typeof arr[0] === 'number') {
    return arr.filter((x: any, i: any) => arr.indexOf(x) === i);
  } else {
    return arr.reduce((acc: any[], x: Brand) => {
      const accIds = acc.map((y) => y.id);

      if (!accIds.includes(x.id)) {
        acc.push(x);
      }

      return acc;
    }, []);
  }
};

export const deDuplicateWith =
  (selector: { (x: any): any; (arg0: any): any }) => (arr: any[]) => {
    return arr.reduce((acc, x) => {
      const accTargets = acc.map(selector);

      if (!accTargets.includes(selector(x))) {
        acc.push(x);
      }

      return acc;
    }, []);
  };

export default deDuplicate;
