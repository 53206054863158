import { compose } from 'redux';
import { addFilter, removeFilter } from './actions';
import { filterBy } from '../../../../utils/filters';
import { removeQueries, setQueries } from '../../../../libs/setQueries';
import getNumOfCarsByAreaId from '../../../../utils/getNumOfCarsByAreaId';
import { ViewMode } from '../../../../hooks/useViewMode';
import { setDisplayAreas } from '../../appData/areas';
import { setDisplayCars } from '../../userData/cars';
import { setDisplayClaims } from '../../userData/claims';
import { setDisplayCares } from '../../userData/cares';
import {
  fetchTrip,
  setDisplayTrips,
  setBookingsDisplay,
} from '../../userData/trips';
import { setDisplayCategories } from '../../userData/categories';
import { setDisplayCircles } from '../../userData/circles';
import { setDisplayPricings } from '../../appData/pricings';
import { setDisplayProfiles } from '../../userData/profiles';
import { Dispatch, GetState } from '../../../../@types';
import { setDisplayUsers } from '../../userData/users';
import { setDisplayAdmins } from '../../userData/admins';
import { setDisplayReceipts } from '../../userData/receipts/actions';
import getTargets from '../../../../components/Filter/helpers/getTargets';

const entitiesMapper = {
  VEHICLE: 'cars',
  AREA: 'areas',
  CLAIM: 'claims',
  CARE: 'cares',
  SHARE: 'trips',
  BOOKING: 'trips',
  CATEGORIES: 'categories',
  PRICING: 'pricings',
  CIRCLE: 'circles',
  PROFILE: 'profiles',
  USERS: 'users',
  ADMINS: 'admins',
  RECEIPTS: 'receipts',
};

const setDisplayMapper = {
  VEHICLE: setDisplayCars,
  AREA: setDisplayAreas,
  CLAIM: setDisplayClaims,
  CARE: setDisplayCares,
  SHARE: setDisplayTrips,
  BOOKING: setBookingsDisplay,
  CATEGORIES: setDisplayCategories,
  PRICING: setDisplayPricings,
  CIRCLE: setDisplayCircles,
  PROFILE: setDisplayProfiles,
  USERS: setDisplayUsers,
  ADMINS: setDisplayAdmins,
  RECEIPTS: setDisplayReceipts,
} as any;

const getViewModeCategory = (viewMode: ViewMode) => {
  switch (viewMode) {
    case ViewMode.VEHICLE:
    case ViewMode.CARE:
    case ViewMode.CLAIM:
    case ViewMode.SHARE:
    case ViewMode.BOOKING:
    case ViewMode.CATEGORIES:
    case ViewMode.CIRCLE:
    case ViewMode.PROFILE:
    case ViewMode.USERS:
    case ViewMode.ADMINS:
    case ViewMode.RECEIPTS:
      return 'userData';
    case ViewMode.AREA:
    case ViewMode.PRICING:
      return 'appData';
    default:
      return 'userData';
  }
};

// the name of the entities in the store
const getEntitiesProp = (viewMode: ViewMode, submenu: string): string => {
  switch (viewMode) {
    case ViewMode.VEHICLE:
      return submenu === 'archived' ? 'archivedCars' : 'entities';
    case ViewMode.BOOKING:
      return 'bookingEntities';
    default:
      return 'entities';
  }
};

const getEntities = (state: any, viewMode: any, submenu: any) => {
  const entitiesProp = getEntitiesProp(viewMode, submenu);
  const category = getViewModeCategory(viewMode);
  // @ts-ignore
  const entities = state[category][entitiesMapper[viewMode]]
    ? // @ts-ignore
      state[category][entitiesMapper[viewMode]][entitiesProp]
    : null;

  // add numOfVehicle to area entity
  if (viewMode === ViewMode.AREA) {
    const cars = state.cars?.entities;

    return entities?.map((area: { id: any }) => {
      return { ...area, numOfVehicle: getNumOfCarsByAreaId(cars, area.id) };
    });
  }

  return entities;
};

const getFilteredEntities = (vm: any, entities: any, filters: any) => {
  const targets = getTargets(vm);
  const funnel = Object.entries(filters).map(([key, value]) => {
    // Check if the current key is an API filter
    const isAPI = targets.some(
      (target) => target.isAPI && target.value === key
    );

    // If it is an API filter, do not filter on ui/client side
    if (isAPI) {
      return (entities: any) => entities; // return entities unchanged as coming from backend
    }

    // Otherwise, add a filter function for this key
    return filterBy(key, value);
  });

  return compose(...funnel)(entities);
};

export const syncAndApplyFilter =
  ({ viewMode, value }: any) =>
  (dispatch: Dispatch, getState: GetState) => {
    Object.entries(value).forEach(([_key, _value]) => {
      dispatch(addFilter({ viewMode, key: _key, value: _value }));
    });

    const entities = getEntities(
      getState(),
      viewMode,
      getState().userData.cars.selectedSubmenu
    );
    const filters = getState().ui.filter[viewMode];

    const displayEntities = getFilteredEntities(viewMode, entities, filters);
    if (
      // @ts-ignore
      displayEntities?.length === 0 &&
      viewMode === 'SHARE' &&
      value?.code
    ) {
      dispatch(
        fetchTrip(value?.code, (trip: any) => {
          // @ts-ignore
          dispatch(setDisplayMapper[viewMode]([trip]));
        })
      );
    } else {
      // @ts-ignore
      dispatch(setDisplayMapper[viewMode](displayEntities));
    }
  };

export const addAndApplyFilter =
  ({ viewMode, value, key }: any) =>
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(addFilter({ viewMode, key, value }));

    const entities = getEntities(
      getState(),
      viewMode,
      getState().userData.cars.selectedSubmenu
    );
    const filters = getState().ui.filter[viewMode];

    const displayEntities = getFilteredEntities(
      viewMode,
      entities,
      filters
    ) as any;

    if (
      displayEntities?.length === 0 &&
      viewMode === 'SHARE' &&
      key === 'code'
    ) {
      dispatch(
        fetchTrip(value, (trip: any) => {
          dispatch(setDisplayMapper[viewMode]([trip]));
        })
      );
    } else {
      dispatch(setDisplayMapper[viewMode](displayEntities));
    }

    setQueries({
      ...filters,
    });
  };

export const removeAndApplyFilter =
  ({ viewMode, key, value }: any) =>
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(removeFilter({ viewMode, key, value }));

    const entities = getEntities(
      getState(),
      viewMode,
      getState().userData.cars.selectedSubmenu
    );
    const filters = getState().ui.filter[viewMode];

    const displayEntities = getFilteredEntities(viewMode, entities, filters);
    dispatch(setDisplayMapper[viewMode](displayEntities));

    removeQueries({
      [key]: value,
    });
  };
