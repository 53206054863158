import { createAction, createErrorAction } from '../../../helpers';
import {
  ErrorResponse,
  FleetAdminCarData,
  CarStatusData,
  Damage,
} from '../../../../@types';
import { CarsState } from './initialState';
import { CarsTypes } from '../../../../@types/enums';

export const carsRequest = () => createAction(CarsTypes.CARS_REQUEST);

export const activeBookingsRequest = () =>
  createAction(CarsTypes.ACTIVE_BOOKINGS_REQUEST);

export const archivedCarsRequest = () =>
  createAction(CarsTypes.ARCHIVED_CARS_REQUEST);

export const bookingByIdRequest = () =>
  createAction(CarsTypes.BOOKING_BY_ID_REQUEST);

export const timelineStatusChangesRequest = () =>
  createAction(CarsTypes.TIMELINE_STATUS_CHANGES_REQUEST);

export const bookingStatusChangeRequest = () =>
  createAction(CarsTypes.BOOKING_STATUS_CHANGE_REQUEST);

export const activeBookingsSuccess = (payload: FleetAdminCarData[]) =>
  createAction(CarsTypes.ACTIVE_BOOKINGS_SUCCESS, payload);

export const carsSuccess = (payload: FleetAdminCarData[]) =>
  createAction(CarsTypes.CARS_SUCCESS, payload);

export const archivedCarsSuccess = (payload: FleetAdminCarData[]) =>
  createAction(CarsTypes.ARCHIVED_CARS_SUCCESS, payload);

export const timelineStatusChangesSuccess = (payload: Array<any>) =>
  createAction(CarsTypes.TIMELINE_STATUS_CHANGES_SUCCESS, payload);

export const bookingStatusChangeSuccess = (payload: Array<any>) =>
  createAction(CarsTypes.BOOKING_STATUS_CHANGE_SUCCESS, payload);

export const carsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.CARS_FAILURE, error);

export const activeBookingsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.ACTIVE_BOOKINGS_FAILURE, error);

export const timelineStatusChangesFailure = <E extends ErrorResponse>(
  error: E
) => createAction(CarsTypes.TIMELINE_STATUS_CHANGES_FAILURE, error);

export const bookingStatusChangeFailure = <E extends ErrorResponse>(error: E) =>
  createAction(CarsTypes.BOOKING_STATUS_CHANGE_FAILURE, error);

export const bookingByIdFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.BOOKING_BY_ID_FAILURE, error);

export const carRequest = () => createAction(CarsTypes.CAR_REQUEST);

export const carSuccess = (payload: FleetAdminCarData | null) =>
  createAction(CarsTypes.CAR_SUCCESS, payload);

export const carFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.CAR_FAILURE, error);

export const selectCar = (payload: FleetAdminCarData | null) =>
  createAction(CarsTypes.SELECT_CAR, payload);

export const selectVehiclesSubmenu = (payload: CarsState['selectedSubmenu']) =>
  createAction(CarsTypes.SELECT_VEHICLES_SUBMENU, payload);

export const setCarDamages = (payload: Damage[] | null) =>
  createAction(CarsTypes.SET_CAR_DAMAGES, payload);

export const setCarActiveBookings = (payload: any) =>
  createAction(CarsTypes.SET_CAR_ACTIVE_BOOKINGS, payload);

export const setDisplayCars = (payload: FleetAdminCarData[] | null) =>
  createAction(CarsTypes.SET_DISPLAY_CARS, payload);

export const carConditionsRequest = () =>
  createAction(CarsTypes.CAR_CONDITIONS_REQUEST);

export const carConditionsSuccess = (payload: CarStatusData) =>
  createAction(CarsTypes.CAR_CONDITIONS_SUCCESS, payload);

export const updateLastCarsStatus = (payload: CarStatusData) =>
  createAction(CarsTypes.UPDATE_LAST_CARS_STATUS, payload);

export const carConditionsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.CAR_CONDITIONS_FAILURE, error);

export const updateCarStatusRequest = () =>
  createAction(CarsTypes.UPDATE_CAR_STATUS_REQUEST);

export const updateCarStatusSuccess = (payload: FleetAdminCarData | null) =>
  createAction(CarsTypes.UPDATE_CAR_STATUS_SUCCESS, payload);

export const updateCarStatusFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.UPDATE_CAR_STATUS_FAILURE, error);

export const unlockCarRequest = () =>
  createAction(CarsTypes.UNLOCK_CAR_REQUEST);

export const unlockCarSuccess = (payload: any) =>
  createAction(CarsTypes.UNLOCK_CAR_SUCCESS, payload);

export const unlockCarFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.UNLOCK_CAR_FAILURE, error);

export const resetBoardRequest = () =>
  createAction(CarsTypes.RESET_BOARD_REQUEST);

export const resetBoardSuccess = () =>
  createAction(CarsTypes.RESET_BOARD_SUCCESS);

export const resetBoardFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.RESET_BOARD_FAILURE, error);

export const resetModemRequest = () =>
  createAction(CarsTypes.RESET_MODEM_REQUEST);

export const resetModemSuccess = () =>
  createAction(CarsTypes.RESET_MODEM_SUCCESS);

export const resetModemFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.RESET_MODEM_FAILURE, error);

export const resetBluetoothRequest = () =>
  createAction(CarsTypes.RESET_BLUETOOTH_REQUEST);

export const resetBluetoothSuccess = () =>
  createAction(CarsTypes.RESET_BLUETOOTH_SUCCESS);

export const resetBluetoothFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.RESET_BLUETOOTH_FAILURE, error);

export const resetDataConnectionRequest = () =>
  createAction(CarsTypes.RESET_DATA_CONNECTION_REQUEST);

export const resetDataConnectionSuccess = () =>
  createAction(CarsTypes.RESET_DATA_CONNECTION_SUCCESS);

export const resetDataConnectionFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CarsTypes.RESET_DATA_CONNECTION_FAILURE, error);

export const setCarDrawerActiveTab = (payload: number) =>
  createAction(CarsTypes.SET_CAR_DRAWER_ACTIVE_TAB, payload);
