import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  getActiveBooking,
  selectCar,
  selectTimeTableCars,
} from '../../redux/store/userData/cars';
import RangeSwitch from './RangeSwitch/RangeSwitch';
import { addDays } from 'date-fns';
import GSTC from 'gantt-schedule-timeline-calendar';
import 'gantt-schedule-timeline-calendar/dist/style.css';
import styles from './VehicleTimeTable.styles';
import { Plugin as HighlightWeekends } from 'gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js';
import { Plugin as TimeBookmarks } from 'gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js';
import { selectCarWithoutMapEffect } from '../../redux/store/ui/common';
import {
  getBookingById,
  getTimelineStatusChanges,
  getTripById,
} from '../../redux/store/userData/cars/thunk';
import getAreaNameById from '../../utils/getAreaNameById';
import { selectAreas } from '../../redux/store/appData/areas/selectors';
import {
  selectAppliedFiltersHeight,
  selectDisplayColumnsForTimeTable,
} from '../../redux/store/ui/common/selectors';
import { selectArea } from '../../redux/store/appData/areas';
import { selectActiveBookings } from '../../redux/store/userData/cars/selectors';
import { activeBookingsSuccess } from '../../redux/store/userData/cars/actions';
import LoadingOverlay from 'react-loading-overlay';

const CALENDAR_PERIOD_START_DATE = addDays(new Date().setHours(0), -1 * 30); // current date minus 3 months
const CALENDAR_PERIOD_END_DATE = addDays(new Date().setHours(0), 8 * 30); // current date plus 9 months
const INITIAL_LEFT_DAYS_OFFSET = 1;
const INITIAL_LEFT_WEEK_OFFSET = 6;
const INITIAL_LEFT_HOURS_OFFSET = 2;
// const INITIAL_LEFT_WEEKS_OFFSET = 2;

const getStatusBG = (status: string) => {
  switch (status) {
    case 'BLOCKED':
      return '#ee4e4e60';
    case 'RELEASED':
      return '#fdfdfd';
    case 'RENTED':
      return '#fdfdfd';
    case 'RELEASE_READY':
      return 'repeating-linear-gradient(45deg,#A0A0A010,#A0A0A010 10px,#A0A0A040 10px, #A0A0A040 20px)';
    case 'EQUIPMENT_READY':
      return 'repeating-linear-gradient(45deg,#A0A0A010,#A0A0A010 10px,#A0A0A040 10px, #A0A0A040 20px)';
    case 'EQUIPPED':
      return 'repeating-linear-gradient(45deg,#A0A0A010,#A0A0A010 10px,#A0A0A040 10px, #A0A0A040 20px)';
    case 'TERMINATED':
      return 'repeating-linear-gradient(45deg,#A0A0A0,#A0A0A0 10px,#A0A0A040 10px, #A0A0A040 20px)';
    case 'REMOVED':
      return 'A0A0A080';
    default:
      return '';
  }
};

const getColumns = (displayColumns: string[]) => [
  {
    id: 'plate',
    data: ({ row }: any) =>
      `<span style="cursor: pointer" onclick="onClickPlate('${row.plate}')">${row.plate}</span>`,
    sortable: 'plate',
    isHTML: true,
    width: 130,
    header: {
      content: 'Plate',
    },
    expander: false,
    resizer: false,
    hidden: false,
  },
  {
    id: 'area',
    data: ({ row }: any) =>
      `<span style="cursor: pointer" onclick="onClickArea('${row.areaId}')">${
        row?.areaName?.length > 15
          ? `${row.areaName.slice(0, 15)}...`
          : row.areaName
      }</span>`,
    sortable: 'areaName',
    isHTML: true,
    width: 180,
    header: {
      content: 'Area',
    },
    expander: false,
    resizer: false,
    hidden: !displayColumns?.includes('area'),
  },
];

export enum CalendarRange {
  Hours = 'Hours',
  Days = 'Days',
  Week = 'Week',
}

let CalendarState: any = null;
let CalendarInstance: any = null;

const VehicleTimeTable = () => {
  const dispatch = useDispatch();
  const displayCars = useSelector(selectTimeTableCars);
  const areas = useSelector(selectAreas);
  const activeBookings = useSelector(selectActiveBookings);
  const appliedFiltersHeight = useSelector(selectAppliedFiltersHeight);
  const displayColumns = useSelector(selectDisplayColumnsForTimeTable);
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState(CalendarRange.Days);
  const onClickPlate = (plate: string) => {
    const car = displayCars?.find((car) => car.plate === plate);
    if (car) dispatch(selectCarWithoutMapEffect(car));
  };

  const onClickArea = (areaId: string) => {
    const clickedArea = areas?.find((area) => area.id === areaId);
    if (clickedArea) {
      batch(() => {
        dispatch(selectArea(clickedArea));
        dispatch(selectCar(null));
      });
    }
  };

  const bookmarks = {
    Now: {
      time: GSTC.api.date(new Date(), true).valueOf(),
      color: 'black',
      label: 'Now',
    },
  };

  const scrollToNow = (value?: any) => {
    switch (value || range) {
      case CalendarRange.Hours:
        CalendarInstance?.api?.setScrollLeft(
          30 * 24 + new Date().getHours() - INITIAL_LEFT_HOURS_OFFSET
        );
        break;
      case CalendarRange.Days:
        CalendarInstance?.api?.setScrollLeft(30 - INITIAL_LEFT_DAYS_OFFSET);
        break;
      case CalendarRange.Week:
        CalendarInstance?.api?.setScrollLeft(30 - INITIAL_LEFT_WEEK_OFFSET);
        break;
    }
    CalendarInstance?.api?.setScrollTop(0);
  };

  const onChangeRange = (value: any) => {
    setRange(value);
    if (CalendarState) {
      switch (value) {
        case CalendarRange.Hours:
          CalendarState.update('config.chart.time', (time: any) => {
            time.zoom = 15;
            time.period = 'hour';
            return time;
          });
          break;
        case CalendarRange.Days:
          CalendarState.update('config.chart.time', (time: any) => {
            time.zoom = 20;
            time.period = 'day';
            return time;
          });
          break;
        case CalendarRange.Week:
          CalendarState.update('config.chart.time', (time: any) => {
            time.zoom = 21;
            time.period = 'day';
            return time;
          });
          break;
      }
      scrollToNow(value);
    }
  };

  // @ts-ignore
  const onCellCreateStatusPeriods = ({ time, row, vido, content }) => {
    if (!row.statusPeriods) return content;
    for (const statusPeriod of row.statusPeriods) {
      if (time.rightGlobal <= statusPeriod.to) {
        return vido.html`<div class="tooltip" style="height:100%;width:100%;background: ${
          statusPeriod?.background || row.background
        };"><span class="tooltiptext">${
          statusPeriod?.tooltipText || row.status
        }</span></div>`;
      }
    }
    return content;
  };

  // @ts-ignore
  const slotContent = (vido, props) => {
    const { onChange } = vido;

    function onClick() {
      if (['confirmed', 'offered'].includes(props.item.status)) {
        if (props?.item?.bookingId) {
          dispatch(getBookingById(props.item.bookingId));
        }
      } else {
        dispatch(getTripById(props.item.bookingId));
      }
    }

    onChange((changedProps: any) => {
      // if current element is reused to display other item data just update your data so when you click you will display right alert
      props = changedProps;
    });
    // return render function
    return (content: any) => {
      return vido.html`<div class="tooltip" class="my-item-wrapper" @click=${onClick} style="width:100%;display:flex;overflow:hidden;cursor: pointer;"><span class="tooltiptext" style="color: ${
        props.item?.requestedStatus
          ? 'black'
          : ['confirmed', 'offered'].includes(props.item.status)
          ? 'white'
          : 'black'
      };background-color: ${
        props.item?.requestedStatus
          ? 'transparent'
          : ['confirmed', 'offered'].includes(props.item.status)
          ? '#a2ad00'
          : '#d8f52b'
      }; font-weight: 600;">${content}</span></div>`;
    };
  };

  const chartTimelineItemsRowItemTemplate = ({
    className,
    labelClassName,
    styleMap,
    cache,
    shouldDetach,
    cutterLeft,
    cutterRight,
    getContent,
    actions,
    slots,
    html,
    vido,
    props,
  }: any) => {
    const detach = shouldDetach || !props || !props.item;
    return cache(
      detach
        ? null
        : slots.html(
            'outer',
            html`
              <div
                class=${className}
                data-gstcid=${props.item.id}
                data-actions=${actions()}
                style=${styleMap.directive()}
                @click=${() => {}}
              >
                ${slots.html(
                  'inner',
                  html`
                    ${cutterLeft()}
                    <div class=${labelClassName}>
                      ${slots.html('content', getContent())}
                    </div>
                    ${cutterRight()}
                  `
                )}
              </div>
            `
          )
    );
  };

  // @ts-ignore
  const rowSlot = (vido, props) => {
    const { html, onChange, update, api } = vido;

    let img = '';
    onChange((newProps: any) => {
      props = newProps;
      if (!props || !props.row) return;
      img = props.row.img;
      update();
    });

    return (content: any) => {
      if (!props || !props.column) return content;
      return api.sourceID(props.column.id) === 'label'
        ? html`<div class="row-content-wrapper" style="display:flex">
            <div class="row-content" style="flex-grow:1;">${content}</div>
          </div>`
        : content;
    };
  };

  const initializeCalendar = () => {
    const config = {
      debug: false,
      innerHeight: window.innerHeight - 240 - appliedFiltersHeight,
      licenseKey: process.env.REACT_APP_DISPOSITION_LIB_KEY,
      plugins: [
        // GrabScroll({
        //   enabled: true,
        // }),
        HighlightWeekends(),
        TimeBookmarks({
          bookmarks,
        }),
      ],
      list: {
        toggle: {
          display: false,
        },
        columns: {
          data: GSTC.api.fromArray(getColumns(['plate', 'area'])),
        },
      },
      scroll: {
        vertical: { width: 20, minInnerSize: 0, multiplier: 1 },
        horizontal: { width: 20, minInnerSize: 40, multiplier: 1 },
      },
      slots: {
        'chart-timeline-items-row-item': {
          content: [slotContent],
        },
        'list-column-row': { content: [rowSlot] },
      },
      templates: {
        'chart-timeline-items-row-item': chartTimelineItemsRowItemTemplate,
      },
      locale: {
        name: 'german',
        months: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        weekdays: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        weekdaysShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
      },
      chart: {
        calculatedZoomMode: true,
        time: {
          zoom: 20,
          from: GSTC.api.date(CALENDAR_PERIOD_START_DATE).valueOf(),
          to: GSTC.api.date(CALENDAR_PERIOD_END_DATE).valueOf(),
        },
        // items: GSTC.api.fromArray(activeBookings),
        grid: {
          cell: {
            onCreate: [onCellCreateStatusPeriods],
          },
        },
      },
    };
    // @ts-ignore
    CalendarState = GSTC.api.stateFromConfig(config);
    GSTC.api.date(new Date());
    CalendarInstance = GSTC({
      element: document.getElementById('gstc')!,
      state: CalendarState,
    });
    scrollToNow();
  };

  // useEffect(() => {
  //   // initializeCalendar();
  // }, []);
  //
  // useEffect(() => {
  //   // @ts-ignore
  //   window.onClickPlate = onClickPlate;
  //   // @ts-ignore
  //   window.onClickArea = onClickArea;
  //   if (displayCars!?.length > 0) {
  //     setIsLoading(true);
  //     CalendarState.update('config.chart.items', () => GSTC.api.fromArray([]));
  //     dispatch(
  //       getTimelineStatusChanges(
  //         CALENDAR_PERIOD_START_DATE.toISOString(),
  //         new Date().toISOString(),
  //         displayCars!.map((car) => car.id),
  //         (statuses: any) => {
  //           const rows = displayCars?.map((car: any) => ({
  //             ...car,
  //             id: car.id,
  //             label: car.plate,
  //             plate: car.plate,
  //             areaName: getAreaNameById(areas, car.areaId),
  //             background: getStatusBG(car.status),
  //             status: car.status,
  //             statusPeriods: statuses
  //               ?.filter((status: any) => status.carId === car.id)
  //               .concat(['last'])
  //               ?.map((status: any, index: number, statuses: any) => {
  //                 return {
  //                   // @ts-ignore
  //                   from: GSTC.api
  //                     .date(
  //                       new Date(
  //                         index === 0
  //                           ? CALENDAR_PERIOD_START_DATE
  //                           : statuses[index - 1].timestamp
  //                       ),
  //                       true
  //                     )
  //                     .valueOf(),
  //                   // @ts-ignore
  //                   to: GSTC.api
  //                     .date(
  //                       new Date(
  //                         statuses[index] === 'last'
  //                           ? CALENDAR_PERIOD_END_DATE
  //                           : status.timestamp
  //                       ),
  //                       true
  //                     )
  //                     .valueOf(),
  //                   tooltipText:
  //                     statuses[index] === 'last'
  //                       ? statuses[index - 1]?.newStatus
  //                       : status.oldStatus,
  //                   background: getStatusBG(
  //                     statuses[index] === 'last'
  //                       ? statuses[index - 1]?.newStatus
  //                       : status.oldStatus
  //                   ),
  //                 };
  //               }),
  //           }));
  //           CalendarState.update('config.list.rows', () =>
  //             GSTC.api.fromArray(rows)
  //           );
  //           setIsLoading(false);
  //           dispatch(getActiveBooking(displayCars!.map((car) => car.id)));
  //         }
  //       )
  //     );
  //   }
  // }, [displayCars, areas]);
  //
  // useEffect(() => {
  //   CalendarState.update('config.list.columns.data', () =>
  //     getColumns(displayColumns)
  //   );
  // }, [displayColumns]);
  //
  // useEffect(() => {
  //   // if (displayCars!?.length > 0 && activeBookings?.length > 0) {
  //   //   CalendarState.update('config.chart.items', () =>
  //   //     GSTC.api.fromArray(
  //   //       activeBookings?.map((booking: any) => ({
  //   //         ...booking,
  //   //         bookingId: booking.id,
  //   //         label:
  //   //           booking?.requestedStatus ||
  //   //           booking?.rentalPresentableId ||
  //   //           booking?.bookingPresentableId,
  //   //         rowId: booking.carId,
  //   //         classNames: ['booking-slot-item'],
  //   //         style: {
  //   //           background: ['confirmed', 'offered'].includes(booking.status)
  //   //             ? booking?.requestedStatus
  //   //               ? getStatusBG(booking.requestedStatus)
  //   //               : '#a2ad00'
  //   //             : '#d8f52b',
  //   //         },
  //   //         time: {
  //   //           start: GSTC.api.date(new Date(booking.from), true).valueOf(),
  //   //           end: GSTC.api.date(new Date(booking.to), true).valueOf(),
  //   //         },
  //   //       }))
  //   //     )
  //   //   );
  //   // }
  //   // return () => {
  //   //   if (activeBookings?.length > 0) dispatch(activeBookingsSuccess([]));
  //   // };
  // }, [activeBookings]);

  return (
    <div style={{ margin: 16 }}>
      {isLoading && (
        <LoadingOverlay
          styles={{
            // @ts-ignore
            wrapper: { height: window.innerHeight - 150 },
          }}
          active={true}
          spinner
        />
      )}
      <>
        <div id="gstc" />
        <RangeSwitch
          range={range}
          setRange={onChangeRange}
          onClickToday={() => scrollToNow(undefined)}
        />
      </>
      <style jsx>{styles}</style>
    </div>
  );
};

export default VehicleTimeTable;
