import css from 'styled-jsx/css';

const style = css`
  .area-info {
    margin: 0 24px 0;
  }
  .label {
    font-size: 12px;
    margin: 16px 0 0;
  }
  .value {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: #000;
    padding-bottom: 2px;
    border-bottom: 1px solid #9f9f9f;
  }

  .button-wrapper {
    margin-top: 48px;
  }
`;

export default style;
