import { ErrorResponse, VehicleCategoryData } from '../../../../@types';

export interface CategoriesState {
  entities: VehicleCategoryData[] | null;
  display: VehicleCategoryData[] | null;
  selected: VehicleCategoryData | null;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
};
