import { alphabeticalSorterFor, getRenderLink } from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import getNumOfCarsByCategoryId from '../../utils/getNumOfCarsByCategoryId';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getColumns = ({ cars, brands, dispatch }: any) => {
  const renderBrand = (brandId: string | null) => (
    <span>
      {brands?.find((brand: { id: string }) => brand.id === brandId)?.name ||
        '-'}
    </span>
  );

  const renderAcrissCode = (acrissCode: string) => <span>{acrissCode}</span>;
  const renderDescription = (desc: string) => <span>{desc}</span>;

  const renderNumOfCars = (categoryId: string) => {
    return (
      <span>{cars ? getNumOfCarsByCategoryId(cars, categoryId) : '-'}</span>
    );
  };

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: isDesktop() ? 250 : 150,
      fixed: 'left' as 'left',
      render: getRenderLink(),
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: 'Acriss Code',
      dataIndex: 'acrissCode',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      render: renderAcrissCode,
      sorter: alphabeticalSorterFor('acrissCode'),
    },
    {
      title: 'No.Vehicles',
      dataIndex: 'id',
      render: renderNumOfCars,
      width: 150,
      sorter: (categoryA: { id: string }, categoryB: { id: string }) => {
        const A = getNumOfCarsByCategoryId(cars, categoryA.id);
        const B = getNumOfCarsByCategoryId(cars, categoryB.id);
        // @ts-ignore
        return B - A;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: renderDescription,
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Brand',
      dataIndex: 'brandId',
      render: renderBrand,
      ellipsis: true,
      width: 250,
    },
  ];
};

export default getColumns;
