export const optionsForClaimStatus: Array<any> = [
  'open',
  'inProgress',
  'closed',
];

export const optionsForClaimCloseReason: Array<any> = [
  'declined',
  'incomplete',
  'wontInvoice',
  'invoiced',
];

export const optionsForClaimTripIgnition: Array<any> = ['after', 'before'];
