import { Reducer } from '../../../../@types';
import { ClaimsTypes } from '../../../../@types/enums';
import { initialState, ClaimsState } from './initialState';
// @ts-ignore
export const claimsReducer: Reducer<ClaimsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case ClaimsTypes.CLAIMS_BY_CAR_REQUEST:
      return {
        ...state,
        isLoadingCarClaim: true,
      };
    case ClaimsTypes.CLAIMS_BY_CAR_SUCCESS:
      return {
        ...state,
        isLoadingCarClaim: false,
      };
    case ClaimsTypes.CLAIMS_BY_CAR_FAILURE:
      return {
        ...state,
        isLoadingCarClaim: false,
      };
    case ClaimsTypes.CLAIMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ClaimsTypes.CLAIMS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case ClaimsTypes.CLAIMS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ClaimsTypes.SET_TOTAL_DAMAGES:
      return {
        ...state,
        totalDamages: action.payload,
      };
    case ClaimsTypes.SET_DISPLAY_CLAIMS:
      return {
        ...state,
        display: action.payload,
      };
    case ClaimsTypes.SELECT_CLAIM:
      return {
        ...state,
        selected: action.payload,
      };
    case ClaimsTypes.UPDATE_CLAIM_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updatingStatus: true,
      };
    case ClaimsTypes.UPDATE_CLAIM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        entities: state?.entities?.map((entity) => {
          if (entity.id === action.payload.id) {
            return action.payload;
          }
          return entity;
        }),
        display: state?.display?.map((car) => {
          if (car.id === action.payload.id) {
            return action.payload;
          }
          return car;
        }),
        selected: action.payload,
        updatingStatus: false,
      };
    case ClaimsTypes.UPDATE_CLAIM_FAILURE:
      return {
        ...state,
        updatingStatus: false,
        isUpdating: false,
        updatingStatusError: action.error,
      };
    case ClaimsTypes.ASSIGNABLE_CLAIM_SUCCESS:
      return {
        ...state,
        assignableClaimTrips: action.payload,
      };
    default:
      return state;
  }
};
