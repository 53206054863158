import { Reducer } from '../../../../@types';
import { EventsTypes } from '../../../../@types/enums';
import { initialState, VehicleEventsState } from './initialState';

// @ts-ignore
export const vehicleEventsReducer: Reducer<VehicleEventsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case EventsTypes.SET_VEHICLE_EVENTS_FROM:
      return {
        ...state,
        claimLastFrom:
          action.payload.target === 'CLAIM'
            ? action.payload.from
            : state.claimLastFrom,
        carLastFrom:
          action.payload.target === 'CAR'
            ? action.payload.from
            : state.carLastFrom,
      };
    case EventsTypes.VEHICLE_EVENTS_REQUEST:
      return {
        ...state,
        claimEvents:
          action.payload.target === 'CLAIM'
            ? initialState.claimEvents
            : state.claimEvents,
        tripEvents:
          action.payload.target === 'TRIP'
            ? initialState.tripEvents
            : state.tripEvents,
        careEvents:
          action.payload.target === 'CARE'
            ? initialState.careEvents
            : state.careEvents,
        carEvents:
          action.payload.target === 'CAR'
            ? initialState.carEvents
            : state.carEvents,
        claimLastFrom:
          action.payload.target === 'CLAIM'
            ? action.payload.from
            : state.claimLastFrom,
        carLastFrom:
          action.payload.target === 'CAR'
            ? action.payload.from
            : state.carLastFrom,

        to: action.payload.to,
        loading: true,
      };
    case EventsTypes.VEHICLE_EVENTS_SUCCESS:
      return {
        ...state,
        claimEvents:
          action.payload.target === 'CLAIM'
            ? action.payload.events
            : state.claimEvents,
        tripEvents:
          action.payload.target === 'TRIP'
            ? action.payload.events
            : state.tripEvents,
        careEvents:
          action.payload.target === 'CARE'
            ? action.payload.events
            : state.careEvents,
        carEvents:
          action.payload.target === 'CAR'
            ? action.payload.events
            : state.carEvents,
        loading: false,
        isAllClaimEventsLoaded: false,
        isAllCarEventsLoaded: false,
      };
    case EventsTypes.VEHICLE_EVENTS_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
      };
    case EventsTypes.LOAD_MORE_VEHICLE_EVENTS_REQUEST:
      return {
        ...state,
        claimLastFrom:
          action.payload.target === 'CLAIM'
            ? action.payload.from
            : state.claimLastFrom,
        carLastFrom:
          action.payload.target === 'CAR'
            ? action.payload.from
            : state.carLastFrom,

        to: action.payload.to,
        loading: true,
      };
    case EventsTypes.LOAD_MORE_VEHICLE_EVENTS_SUCCESS:
      return {
        ...state,
        claimEvents:
          action.payload.target === 'CLAIM'
            ? // @ts-ignore
              [...state.claimEvents, ...action.payload.events]
            : state.claimEvents,
        tripEvents:
          action.payload.target === 'TRIP'
            ? [...state.tripEvents, ...action.payload.events]
            : state.tripEvents,
        careEvents:
          action.payload.target === 'CARE'
            ? [...state.careEvents, ...action.payload.events]
            : state.careEvents,
        carEvents:
          action.payload.target === 'CAR'
            ? [...state.carEvents, ...action.payload.events]
            : state.carEvents,
        loading: false,
      };
    case EventsTypes.VEHICLE_EVENTS_EMPTY_RESPONSE:
      return {
        ...state,
        loading: false,
        isAllClaimEventsLoaded:
          action.payload.target === 'CLAIM'
            ? true
            : state.isAllClaimEventsLoaded,
        isAllCarEventsLoaded:
          action.payload.target === 'CAR' ? true : state.isAllCarEventsLoaded,
      };
    case EventsTypes.SET_VEHICLE_EVENTS_FILTERS:
      return {
        ...state,

        carEventsFilters: action.payload.carEventsFilters,
        carEvents:
          action.payload.carEventsFilters.length === 0
            ? initialState.carEvents
            : state.carEvents,
      };
    case EventsTypes.VEHICLE_EVENTS_RESET:
      return {
        ...state,
        claimEvents: initialState.claimEvents,
        tripEvents: initialState.tripEvents,
        careEvents: initialState.careEvents,
        carEvents: initialState.carEvents,
        claimLastFrom: initialState.claimLastFrom,
        carLastFrom: initialState.carLastFrom,
        to: initialState.to,
        loading: initialState.loading,
      };
    default:
      return state;
  }
};
