import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { history, parseLocale } from '../utils';
import Dashboard from '../pages/dashboard';
import LoadingIcon from '../components/shared/LoadingIcon';
import Init from '../init/init';
import Password from '../pages/password';
import Forgot from '../pages/forgot';
import Images from '../pages/images';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTexts } from '../redux/store/appData/texts/selectors';
import { selectAuth } from '../redux/store/userData/user/selectors';

const AppRoute = () => {
  const user = useSelector(selectAuth);
  const texts = useSelector(selectTexts);
  return (
    <IntlProvider
      locale={parseLocale(user?.locale)}
      messages={texts[parseLocale(user?.locale)]}
    >
      {/*// @ts-ignore*/}
      <Router history={history}>
        <Suspense fallback={<LoadingIcon />}>
          <Switch>
            <Route path="/" exact component={Init} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/password" component={Password} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/images" component={Images} />
          </Switch>
        </Suspense>
      </Router>
    </IntlProvider>
  );
};

export default AppRoute;
