import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .drawer {
    position: relative;
  }
  .drawer-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    cursor: pointer;
  }
  :global(.drawer .ant-drawer-content-wrapper) {
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.5) !important;
  }
  :global(.drawer .ant-drawer-body) {
    padding: 0;
  }
  :global(.drawer .ant-drawer-wrapper-body) {
    background: #f0f2f5;
  }
  .header {
    position: relative;
    padding: 20px 24px 20px 24px;
    max-height: 53px;
    background: ${colors.backgroundContentMain};
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    margin-bottom: 0;
  }
  .reload-button {
    position: absolute;
    top: 20px;
    right: 60px;
    cursor: pointer;
  }
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .body {
    height: calc(100vh - 53px);
    overflow-y: scroll;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #f0f2f5;
  }
  @media only screen and (min-width: 768px) {
    .drawer {
      position: relative;
    }
    :global(.drawer .ant-drawer-content-wrapper) {
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.5) !important;
    }
    :global(.drawer .ant-drawer-body) {
      padding: 0;
    }
    :global(.drawer .ant-drawer-wrapper-body) {
      background: #f0f2f5;
    }
    .header {
      position: relative;
      padding: 20px 24px 20px 24px;
      max-height: 53px;
      background: ${colors.backgroundContentMain};
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #a3a3a3;
      margin-bottom: 0;
    }
    .reload-button {
      position: absolute;
      top: 20px;
      right: 60px;
      cursor: pointer;
    }
    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .body {
      height: calc(100vh - 53px);
      overflow-y: scroll;
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #f0f2f5;
    }
  }
`;

export default style;
