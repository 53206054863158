import React from 'react';
import { history } from '../utils/history';

export function paramsToObject(entries: any) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    // @ts-ignore
    result[key] = value;
  }
  return result;
}

export function useQuery(): any {
  return React.useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const entries = urlParams.entries();
    return paramsToObject(entries);
  }, [history.location.search]);
}
