import React from 'react';
import { Tabs } from 'antd';
import styles from './ClaimTabLayout.styles';

const { TabPane } = Tabs;

type Tab = {
  title: React.ReactNode;
  content: React.ReactNode;
};

type TabLayoutProps = {
  tabs: Tab[];
  keyName: string;
  activeTab: string;
  setActiveTab: (activeKey: string) => void;
};

const ClaimTabLayout: React.FC<TabLayoutProps> = ({
  tabs,
  keyName,
  activeTab,
  setActiveTab,
}) => {
  return (
    <>
      <div className="tab-wrapper">
        <Tabs
          type="card"
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          {tabs.map((tab, id) => (
            <TabPane
              tab={tab.title}
              key={`${keyName}-tab-${id}`}
              className="tab-panel"
            >
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ClaimTabLayout;
