import { Target } from '../@types';

const targetsForVehicle: Target[] = [
  { value: 'plate', type: 'text', isAPI: false },
  { value: 'chassisNumber', type: 'text', isAPI: false },
  { value: 'status', type: 'selection', isAPI: false },
  { value: 'model', type: 'text', isAPI: false },
  { value: 'fuel', type: 'selection', isAPI: false },
  { value: 'fleetId', type: 'selection', isAPI: false },
  { value: 'fuelCardNumber', type: 'text', isAPI: false },
  { value: 'rentalCount', type: 'number', isAPI: false },
  { value: 'equippedSince', type: 'date', isAPI: false },
  { value: 'lastStatus.fuelCardIn', type: 'boolean', isAPI: false },
  { value: 'lastStatus.keyFobIn', type: 'boolean', isAPI: false },
  { value: 'fuelLevel', type: 'number', isAPI: false },
  { value: 'areaId', type: 'text', isAPI: false },
  { value: 'blockReasons', type: 'selection', isAPI: false },
  { value: 'supportedVehicleConnections', type: 'selection', isAPI: false },
  { value: 'vehicleConnection', type: 'selection', isAPI: false },
  { value: 'deviceSNR', type: 'text', isAPI: false },
];

export default targetsForVehicle;
