import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const loadingStyles = css`
  .loading-wrapper {
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const noAreasStyles = css`
  .message {
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-align: center;
  }
`;

export const assignedAreasStyles = css`
  .add-user-button {
    display: inline-flex;
    margin: 16px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .button-text {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .assigned-areas {
    margin: 16px 24px 0;
    padding-left: 0;
    list-style: none;
  }
  .assigned-area {
    display: flex;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #979797;
    justify-content: space-between;
    align-items: center;
  }
`;
