import css from 'styled-jsx/css';
import { zIndex, common } from '../../theme/theme';

const style = css.global`
  .add-circle-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .add-circle-wrapper .ant-modal-header {
    padding: 32px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .add-circle-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .add-circle-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
  }
  .add-circle-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .add-circle-contents {
    width: 400px;
  }
  .inputs-container {
    width: 100%;
    padding: 16px;
  }
  .add-circle-checkbox-container {
    width: 100%;
    padding: 8px 16px;
  }
  :global(.add-circle-checkbox-container .list-item-wrapper) {
    margin: 0 !important;
  }
  .submit-button-container {
    padding: 8px 16px 8px 16px;
  }
  .add-circle-wrapper :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .add-circle-wrapper :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
  .error {
    color: red;
    font-size: 11px;
    margin-bottom: 0;
    margin: 4px;
  }
`;

export default style;
