export enum CirclesTypes {
  CIRCLES_REQUEST = 'userDataa/Circles/CIRCLES_REQUEST',
  CIRCLES_SUCCESS = 'userData/Circles/CIRCLES_SUCCESS',
  CIRCLES_FAILURE = 'userData/Circles/CIRCLES_FAILURE',
  CIRCLE_REQUEST = 'userData/Circles/CIRCLE_REQUEST',
  CIRCLE_SUCCESS = 'userData/Circles/CIRCLE_SUCCESS',
  CIRCLE_FAILURE = 'userData/Circles/CIRCLE_FAILURE',
  UPDATE_CIRCLE_REQUEST = 'userData/Circles/UPDATE_CIRCLE_REQUEST',
  UPDATE_CIRCLE_SUCCESS = 'userData/Circles/UPDATE_CIRCLE_SUCCESS',
  UPDATE_CIRCLE_FAILURE = 'userData/Circles/UPDATE_CIRCLE_FAILURE',
  SELECT_CIRCLE = 'userData/Circles/SELECT_CIRCLE',
  SET_DISPLAY_CIRCLES = 'userData/Circles/SET_DISPLAY_CIRCLES',
  ADD_USER_REQUEST = 'userData/Circles/ADD_USER_REQUEST',
  ADD_USER_SUCCESS = 'userData/Circles/ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'userData/Circles/ADD_USER_FAILURE',
  ADD_ADMIN_REQUEST = 'userData/Circles/ADD_ADMIN_REQUEST',
  ADD_ADMIN_SUCCESS = 'userData/Circles/ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAILURE = 'userData/Circles/ADD_ADMIN_FAILURE',
  REMOVE_USER_REQUEST = 'userData/Circles/REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS = 'userData/Circles/REMOVE_USER_SUCCESS',
  REMOVE_USER_FAILURE = 'userData/Circles/REMOVE_USER_FAILURE',
  REMOVE_ADMIN_REQUEST = 'userData/Circles/REMOVE_ADMIN_REQUEST',
  REMOVE_ADMIN_SUCCESS = 'userData/Circles/REMOVE_ADMIN_SUCCESS',
  REMOVE_ADMIN_FAILURE = 'userData/Circles/REMOVE_ADMIN_FAILURE',
  ADD_FLEET_REQUEST = 'userData/Circles/ADD_FLEET_REQUEST',
  ADD_FLEET_SUCCESS = 'userData/Circles/ADD_FLEET_SUCCESS',
  ADD_FLEET_FAILURE = 'userData/Circles/ADD_FLEET_FAILURE',
  REMOVE_FLEET_REQUEST = 'userData/Circles/REMOVE_FLEET_REQUEST',
  REMOVE_FLEET_SUCCESS = 'userData/Circles/REMOVE_FLEET_SUCCESS',
  REMOVE_FLEET_FAILURE = 'userData/Circles/REMOVE_FLEET_FAILURE',
  CREATE_CIRCLE_REQUEST = 'userData/Circles/CREATE_CIRCLE_REQUEST',
  CREATE_CIRCLE_SUCCESS = 'userData/Circles/CREATE_CIRCLE_SUCCESS',
  CREATE_CIRCLE_FAILURE = 'userData/Circles/CREATE_CIRCLE_FAILURE',
}
