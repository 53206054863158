import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Button, Checkbox } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './FilterVehicleEventsModal.styles';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import {
  selectCarEventsFilters,
  fetchVehicleEventsForCar,
  setCarEventsFilters,
} from '../../redux/store/userData/vehicleEvents';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { useIntl } from 'react-intl';

const availableFilters = [
  { label: 'Claims', value: 'Claims' },
  { label: 'Rentals', value: 'Rentals' },
  { label: 'Services', value: 'Services' },
  { label: 'Base data', value: 'BaseData' },
  { label: 'Device', value: 'Device' },
];
export const FILTER_VEHICLE_EVENTS_MODAL = 'filterVehicleEventsModal';

const FilterVehicleEventsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedCar);
  const carEventsFilters = useSelector(selectCarEventsFilters);
  const visibleModal = useSelector(
    getIsVisibleModal(FILTER_VEHICLE_EVENTS_MODAL)
  );
  const [selectedFilters, setSelectedFilters] = useState(carEventsFilters);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      dispatch(closeModal(FILTER_VEHICLE_EVENTS_MODAL));
      dispatch(setCarEventsFilters(selectedFilters));
      if (selectedFilters.length !== 0) {
        setTimeout(
          () => dispatch(fetchVehicleEventsForCar(selectedCar?.id!)),
          300
        );
      }
    });
  };

  const handleCloseModal = () => {
    dispatch(closeModal(FILTER_VEHICLE_EVENTS_MODAL));
  };

  const onChange = (checkedValues: any) => {
    setSelectedFilters(checkedValues);
  };

  return (
    <>
      <DrawerModal visible={visibleModal} onClose={handleCloseModal}>
        <div className="filter-vehicle-events-modal">
          <Checkbox.Group
            options={availableFilters}
            value={selectedFilters}
            defaultValue={['BaseData']}
            onChange={onChange}
          />
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default FilterVehicleEventsModal;
