import { ErrorResponse } from '../../../../@types';

export interface UsersState {
  entities: any[] | null;
  display: any[] | null;
  selected: any | null;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
};
