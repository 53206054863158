export const getCarsByAreaId = (cars: any, areaId: any) => {
  return cars?.filter((car: { areaId: any }) => car.areaId === areaId);
};

const getNumOfCarsByAreaId = (cars: any, areaId: any) => {
  const theCars = getCarsByAreaId(cars, areaId);
  return theCars?.length;
};

export default getNumOfCarsByAreaId;
