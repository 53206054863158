import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector, batch } from 'react-redux';
import { isEmpty } from 'ramda';
import ConfiguredIntercom from '../components/ConfiguredIntercom';
import Header from '../components/shared/Header';
import Toolbar from '../components/Toolbar';
import CarTable from '../components/CarTable';
import AreaTable from '../components/AreaTable';
import ClaimTable from '../components/ClaimTable';
import CareTable from '../components/CareTable';
import TripsTable from '../components/TripsTable';
import BookingsTable from '../components/BookingsTable';
import Map from '../components/Mapbox';
import CarEditDrawer from '../components/CarEditDrawer';
import AreaEditDrawer from '../components/AreaEditDrawer';
import ClaimDrawer from '../components/ClaimDrawer';
import CareDrawer from '../components/CareDrawer';
import TripDrawer from '../components/TripDrawer';
import SideMenu from '../components/SideMenu';
import AppliedFilters from '../components/Filter/AppliedFilters';
import DamageModal from '../components/DamageModal';
import useViewMode, { ViewMode } from '../hooks/useViewMode';
import useViewType, { ViewType } from '../hooks/useViewType';
import { setQueries, removeQueries } from '../libs/setQueries';
import { zIndex, colors } from '../theme/theme';
import AddServiceModal from '../components/AddServiceModal';
import { selectCares, selectCare } from '../redux/store/userData/cares';
import { selectAreas } from '../redux/store/appData/areas';
import { selectSelectedCar, selectCars } from '../redux/store/userData/cars';
import {
  selectClaims,
  selectClaim,
  fetchAssignableClaimTrips,
} from '../redux/store/userData/claims';
import {
  selectTrip,
  selectTrips,
  fetchTripInvoiceData,
  selectBookings,
  selectBooking,
  fetchBookingById,
  fetchTripById,
} from '../redux/store/userData/trips';
import { fetchVehicleEventsForTrip } from '../redux/store/userData/vehicleEvents';
import PhoneReportModal from '../components/PhoneReportModal';
import {
  selectCarWithoutMapEffect,
  openModal,
  selectAreaWithUIEffect,
  selectViewMode,
  selectViewType,
  selectCategoryWithUIEffect,
  selectCircleWithUIEffect,
  selectPricingWithUIEffect,
  setViewMode,
  setViewType,
  selectProfileWithUIEffect,
  selectReceiptWithUIEffect,
} from '../redux/store/ui/common';
import { syncAndApplyFilter } from '../redux/store/ui/filters';
import { loginByLocalStorage, selectAuth } from '../redux/store/userData/user';
import CategoriesTable from '../components/CategoriesTable';
import AddCategoryModal from '../components/AddCategoryModal';
import CategoryDrawer from '../components/CategoryEditDrawer/CategoryEditDrawer';
import { selectCategories } from '../redux/store/userData/categories';
import CircleTable from '../components/CircleTable';
import { selectCircles } from '../redux/store/userData/circles';
import CircleEditDrawer from '../components/CircleEditDrawer';
import AddCircleModal from '../components/AddCircleModal/AddCircleModal';
import CreateAreaModal from '../components/CreateAreaModal/CreateAreaModal';
import { selectPricings } from '../redux/store/appData/pricings';
import PricingsTable from '../components/PricingsTable';
import PricingEditDrawer from '../components/PricingEditDrawer';
import { useQuery } from '../hooks/useQuery';
import { history } from '../utils';
import BookingDrawer from '../components/BookingDrawer';
import BookingCreateModal from '../components/BookingCreateModal';
import { selectProfiles } from '../redux/store/userData/profiles';
import ProfilesTable from '../components/ProfilesTable';
import ProfileEditDrawer from '../components/ProfileEditDrawer';
import AddProfileModal from '../components/AddProfileModal/AddProfileModal';
import UpdateProfileModal from '../components/UpdateProfileModal/UpdateProfileModal';
import UsersTable from '../components/UsersTable';
import UsersDrawer from '../components/UsersDrawer';
import UserContactModal from '../components/UsersDrawer/UserContactModal/UserContactModal';
import { selectUsers } from '../redux/store/userData/users';
import {
  selectAdminWithUIEffect,
  selectUserWithUIEffect,
} from '../redux/store/ui/common/thunk';
import AddLicenseModal from '../components/UsersDrawer/LicenseModal/AddLicenseModal';
import EditLicenseModal from '../components/UsersDrawer/LicenseModal/EditLicenseModal';
import TerminateCarModal from '../components/CarEditDrawer/TerminateCarModal/TerminateCarModal';
import ArchiveAreaModal from '../components/AreaEditDrawer/ArchiveAreaModal/ArchiveAreaModal';
import AdminsTable from '../components/AdminsTable';
import { selectAdmins } from '../redux/store/userData/admins';
import AdminsDrawer from '../components/AdminsDrawer';
import AddAdminModal from '../components/AdminsDrawer/AddAdminModal/AddAdminModal';
import AdminDeleteModal from '../components/AdminsDrawer/AdminDeleteModal/AdminDeleteModal';
import VahicleTimeTable from '../components/VehicleTimeTable/VehicleTimeTable';
import TripCancelModal from '../components/TripDrawer/TripCancelModal/TripCancelModal';
import BookingCancelModal from '../components/BookingDrawer/BookingCancelModal/BookingCancelModal';
import CareCancelModal from '../components/CareDrawer/CareCancelModal/CareCancelModal';
import BookingVehicleEditModal from '../components/BookingDrawer/BookingVehicleEditModal/BookingVehicleEditModal';
import TripReturnTimeEditModal from '../components/TripDrawer/TripReturnTimeEditModal/TripReturnTimeEditModal';
import BookingStartEndEditModal from '../components/BookingDrawer/BookingStartEndEditModal/BookingStartEndEditModal';
import EditAdminInfoModal from '../components/AdminsDrawer/EditAdminInfoModal/EditAdminInfoModal';
import BookingUserEditModal from '../components/BookingDrawer/BookingUserEditModal/BookingUserEditModal';
import ReceiptsTable from '../components/ReceiptsTable';
import ReceiptDrawer from '../components/ReceiptDrawer';
import { selectReceipts } from '../redux/store/userData/receipts/selectors';
import UserIdentityModal from '../components/UsersDrawer/UserIdentityModal/UserIdentityModal';
import ConfirmModal from '../components/shared/ConfirmModal/ConfirmModal';
import UserLicenseSettingsModal from '../components/UsersDrawer/UserLicenseSettingsModal/UserLicenseSettingsModal';
import UserPaymentModal from '../components/UsersDrawer/UserPaymentModal/UserPaymentModal';
import StatusModal from '../components/shared/StatusModal/StatusModal';
import UserIDSettingsModal from '../components/UsersDrawer/UserIDSettingsModal/UserIDSettingsModal';
import ReceiptsCreateModal from '../components/ReceiptDrawer/ReceiptsCreateModal/ReceiptsCreateModal';
import BookingInsuranceEditModal from '../components/BookingDrawer/BookingInsuranceEditModal/BookingInsuranceEditModal';
import BookingCircleEditModal from '../components/BookingDrawer/BookingCircleEditModal/BookingCircleEditModal';
import AddIDModal from '../components/UsersDrawer/IDModal/AddIDModal';
import EditIDModal from '../components/UsersDrawer/IDModal/EditIDModal';
import UserStatusModal from '../components/UsersDrawer/UserStatusModal/UserStatusModal';
import UsersCreateModal from '../components/UsersDrawer/UsersCreateModal/UsersCreateModal';
import UserOrgModal from '../components/UsersDrawer/UserOrgModal/UserOrgModal';
import UserOrgContactModal from '../components/UsersDrawer/UserOrgContactModal/UserOrgContactModal';
import EbusOptionsModal from '../components/UsersDrawer/UserStatusTab/EbusOptionsModal/EbusOptionsModal';
import CreateButton from '../components/shared/CreateButton/CreateButton';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [damage, setDamage] = useState(null);
  const [isDeepLinkHandled, setIsDeepLinkHandled] = useState(false);
  const { initViewMode } = useViewMode();
  const { initViewType } = useViewType();
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const user = useSelector(selectAuth);
  const carsEntities = useSelector(selectCars);
  const selectedCar = useSelector(selectSelectedCar);
  const areasEntities = useSelector(selectAreas);
  const careEntities = useSelector(selectCares);
  const tripEntities = useSelector(selectTrips);
  const bookingEntities = useSelector(selectBookings);
  const claimEntities = useSelector(selectClaims);
  const circleEntities = useSelector(selectCircles);
  const categoryEntities = useSelector(selectCategories);
  const pricingEntities = useSelector(selectPricings);
  const profileEntities = useSelector(selectProfiles);
  const usersEntities = useSelector(selectUsers);
  const adminsEntities = useSelector(selectAdmins);
  const receiptsEntities = useSelector(selectReceipts);

  const {
    vm,
    type,
    carId,
    areaId,
    claimId,
    careId,
    tripId,
    bookingId,
    damageId,
    categoryId,
    pricingId,
    profileId,
    circleId,
    userId,
    adminId,
    receiptId,
    pageNumber,
    pageSize,
    ...filters
  } = useQuery();

  const openDamageModalFromUrl = () => {
    if (vm === ViewMode.VEHICLE && damageId != null && selectedCar != null) {
      const _damage = selectedCar.damages.find(
        (d) => String(d.id) === damageId
      );

      if (_damage != null) {
        setDamage(_damage);
        dispatch(openModal('damageModal'));
      }
    }
  };

  const openDrawerByQuery = () => {
    if (
      vm === ViewMode.VEHICLE &&
      carId != null &&
      carsEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      // select entity and open drawer
      const selectedCarByQuery = carsEntities.find((car) => car.id === carId);
      if (selectedCarByQuery != null) {
        dispatch(selectCarWithoutMapEffect(selectedCarByQuery));
      }
    }

    if (
      vm === ViewMode.PROFILE &&
      profileId != null &&
      profileEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedProfileByQuery = profileEntities.find(
        (profile) => profile.id === profileId
      );
      if (selectedProfileByQuery != null) {
        dispatch(selectProfileWithUIEffect(selectedProfileByQuery));
      }
    }

    if (
      vm === ViewMode.USERS &&
      userId != null &&
      usersEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedUserByQuery = usersEntities.find(
        (user) => user.id === userId
      );
      if (selectedUserByQuery != null) {
        dispatch(selectUserWithUIEffect(selectedUserByQuery));
      }
    }

    if (
      vm === ViewMode.ADMINS &&
      adminId != null &&
      adminsEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedAdminByQuery = adminsEntities.find(
        (admin) => admin.id === adminId
      );
      if (selectedAdminByQuery != null) {
        dispatch(selectAdminWithUIEffect(selectedAdminByQuery));
      }
    }

    if (
      vm === ViewMode.AREA &&
      areaId != null &&
      areasEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedAreaByQuery = areasEntities.find(
        (area) => area.id === areaId
      );
      if (selectedAreaByQuery != null) {
        dispatch(selectAreaWithUIEffect(selectedAreaByQuery));
      }
    }

    if (
      vm === ViewMode.CATEGORIES &&
      categoryId != null &&
      categoryEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedCategoryByQuery = categoryEntities.find(
        (category) => category.id === categoryId
      );
      if (selectedCategoryByQuery != null) {
        dispatch(selectCategoryWithUIEffect(selectedCategoryByQuery));
      }
    }

    if (
      vm === ViewMode.PRICING &&
      pricingId != null &&
      pricingEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedPricingByQuery = pricingEntities.find(
        (pricing) => pricing.id === pricingId
      );
      if (selectedPricingByQuery != null) {
        dispatch(selectPricingWithUIEffect(selectedPricingByQuery));
      }
    }

    if (
      vm === ViewMode.RECEIPTS &&
      receiptId != null &&
      receiptsEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedReceiptByQuery = receiptsEntities.find(
        (receipt) => receipt.id === receiptId
      );
      if (selectedReceiptByQuery != null) {
        dispatch(selectReceiptWithUIEffect(selectedReceiptByQuery));
      }
    }

    if (
      vm === ViewMode.CIRCLE &&
      circleId != null &&
      circleEntities &&
      !isDeepLinkHandled &&
      user.userGroupsManager
    ) {
      setIsDeepLinkHandled(true);
      const selectedCircleByQuery = circleEntities.find(
        (circle) => circle.id === circleId
      );
      if (selectedCircleByQuery != null) {
        dispatch(selectCircleWithUIEffect(selectedCircleByQuery));
      }
    }

    if (
      vm === ViewMode.CLAIM &&
      claimId != null &&
      claimEntities &&
      !isDeepLinkHandled &&
      user.claimsManager
    ) {
      setIsDeepLinkHandled(true);
      const selectedClaimByQuery = claimEntities.find(
        (claim) => claim.damageId === claimId
      );
      if (selectedClaimByQuery != null) {
        batch(() => {
          dispatch(selectClaim(selectedClaimByQuery));
          if (selectedClaimByQuery?.carId) {
            dispatch(
              fetchAssignableClaimTrips(
                selectedClaimByQuery?.carId,
                selectedClaimByQuery.tripId
              )
            );
          }
          if (selectedClaimByQuery?.assignedTripId) {
            dispatch(fetchTripInvoiceData(selectedClaimByQuery.assignedTripId));
          }
        });
      }
    }

    if (
      vm === ViewMode.CARE &&
      careId != null &&
      careEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedCareByQuery = careEntities.find(
        (care) => care.id === careId
      );
      if (selectedCareByQuery != null) {
        const { id, carId, reservedAt, tripEnd } = selectedCareByQuery;
        batch(() => {
          dispatch(selectCare(selectedCareByQuery));
          dispatch(
            fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd, true)
          );
        });
      }
    }

    if (
      vm === ViewMode.SHARE &&
      tripId != null &&
      tripEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedTripByQuery = tripEntities.find(
        (trip) => trip.id === tripId
      );
      if (selectedTripByQuery != null) {
        const { id, carId, reservedAt, tripEnd } = selectedTripByQuery;
        batch(() => {
          dispatch(selectTrip(selectedTripByQuery));
          dispatch(fetchTripInvoiceData(id));
          dispatch(fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd));
        });
      } else {
        dispatch(fetchTripById(tripId, () => {}));
      }
    }

    if (
      vm === ViewMode.BOOKING &&
      bookingId != null &&
      bookingEntities &&
      !isDeepLinkHandled
    ) {
      setIsDeepLinkHandled(true);
      const selectedBookingByQuery = bookingEntities.find(
        (booking) => booking.id === bookingId
      );
      if (selectedBookingByQuery != null) {
        batch(() => {
          dispatch(selectBooking(selectedBookingByQuery));
          dispatch(fetchBookingById(bookingId));
        });
      }
    }
  };

  // initialize
  useEffect(() => {
    dispatch(loginByLocalStorage());
    const initialViewMode = initViewMode();
    const initialViewType = initViewType();
    const queries = { ...initialViewMode, ...initialViewType };

    if (initialViewMode != null || initialViewType != null) {
      setQueries(queries);
    }

    const url = new URLSearchParams(history.location.search);
    dispatch(setViewMode(url.get('vm')));
    dispatch(setViewType(url.get('type')));
    // gtag.pageview();
  }, []);

  // after loading all entities
  useEffect(() => {
    // set filter by url queries
    if (!isEmpty(filters)) {
      dispatch(
        syncAndApplyFilter({
          viewMode,
          value: { ...filters },
        })
      );
    }
    openDamageModalFromUrl();
    openDrawerByQuery();
  }, [
    carsEntities,
    areasEntities,
    careEntities,
    tripEntities,
    bookingEntities,
    claimEntities,
    circleEntities,
    pricingEntities,
    profileEntities,
    usersEntities,
    receiptsEntities,
  ]);

  useEffect(() => {
    return history.listen(() => {
      const url = new URLSearchParams(history.location.search);
      dispatch(setViewMode(url.get('vm')));
      dispatch(setViewType(url.get('type')));
    });
  }, [history]);

  const openNav = () => {
    document.getElementById('sidebar').style.width = '220px';
    document.getElementById('main').style.marginLeft = '220px';
    document.getElementById('main-header').style.left = '220px';
  };

  return (
    <Layout>
      <button className="openbtn" onClick={openNav}>
        ☰
      </button>
      <SideMenu />
      <div id="main">
        <Layout>
          <div className="wrapper">
            <div className="main-wrapper">
              <div id="main-header" className="main-header">
                <Header />
                <Toolbar />
              </div>
              <div className="main-body">
                <AppliedFilters viewMode={viewMode} viewType={viewType} />
                {viewMode === ViewMode.SHARE ? (
                  <TripsTable />
                ) : viewMode === ViewMode.BOOKING ? (
                  <BookingsTable />
                ) : viewMode === ViewMode.CARE ? (
                  <CareTable />
                ) : viewType === ViewType.MAP ? (
                  <Map />
                ) : viewType === ViewType.TIME_TABLE ? (
                  <VahicleTimeTable />
                ) : viewMode === ViewMode.AREA ? (
                  <AreaTable />
                ) : viewMode === ViewMode.CLAIM ? (
                  <ClaimTable />
                ) : viewMode === ViewMode.CATEGORIES ? (
                  <CategoriesTable />
                ) : viewMode === ViewMode.PRICING ? (
                  <PricingsTable />
                ) : viewMode === ViewMode.RECEIPTS ? (
                  <ReceiptsTable />
                ) : viewMode === ViewMode.PROFILE ? (
                  <ProfilesTable />
                ) : viewMode === ViewMode.CIRCLE ? (
                  <CircleTable />
                ) : viewMode === ViewMode.USERS ? (
                  <UsersTable />
                ) : viewMode === ViewMode.ADMINS ? (
                  <AdminsTable />
                ) : viewMode === ViewMode.VEHICLE ? (
                  <CarTable />
                ) : null}
              </div>
            </div>
          </div>
          {viewMode === ViewMode.VEHICLE && <CarEditDrawer />}
          {viewMode === ViewMode.PROFILE && <ProfileEditDrawer />}
          {(viewMode === ViewMode.AREA ||
            (viewMode === ViewMode.VEHICLE &&
              viewType === ViewType.TIME_TABLE)) && <AreaEditDrawer />}
          {viewMode === ViewMode.CLAIM && <ClaimDrawer />}
          {viewMode === ViewMode.CARE && <CareDrawer />}
          {(viewMode === ViewMode.SHARE ||
            (viewMode === ViewMode.VEHICLE &&
              viewType === ViewType.TIME_TABLE)) && <TripDrawer />}
          {(viewMode === ViewMode.BOOKING ||
            (viewMode === ViewMode.VEHICLE &&
              viewType === ViewType.TIME_TABLE)) && <BookingDrawer />}
          {viewMode === ViewMode.CATEGORIES && <CategoryDrawer />}
          {viewMode === ViewMode.PRICING && <PricingEditDrawer />}
          {viewMode === ViewMode.CIRCLE && <CircleEditDrawer />}
          {viewMode === ViewMode.USERS && <UsersDrawer />}
          {viewMode === ViewMode.ADMINS && <AdminsDrawer />}
          {viewMode === ViewMode.RECEIPTS && <ReceiptDrawer />}
          <DamageModal
            damage={damage}
            carPlate={selectedCar?.plate}
            onClose={() => {
              removeQueries({ damageId });
            }}
          />
          <AddServiceModal />
          <PhoneReportModal />
          <AddCategoryModal />
          <AddCircleModal />
          <AddProfileModal />
          <UpdateProfileModal />
          <CreateAreaModal />
          <UserContactModal />
          <UserOrgContactModal />
          <BookingCreateModal />
          <AddLicenseModal />
          <EditLicenseModal />
          <AddIDModal />
          <EditIDModal />
          <TerminateCarModal />
          <ArchiveAreaModal />
          <AddAdminModal />
          <AdminDeleteModal />
          <EditAdminInfoModal />
          <TripCancelModal />
          <BookingCancelModal />
          <CareCancelModal />
          <BookingVehicleEditModal />
          <BookingStartEndEditModal />
          <TripReturnTimeEditModal />
          <BookingUserEditModal />
          <BookingInsuranceEditModal />
          <BookingCircleEditModal />
          <UserIdentityModal />
          <UserOrgModal />
          <UserLicenseSettingsModal />
          <UserIDSettingsModal />
          <UserStatusModal />
          <UserPaymentModal />
          <ConfirmModal />
          <StatusModal />
          <ReceiptsCreateModal />
          <UsersCreateModal />
          <EbusOptionsModal />

          <ConfiguredIntercom />
          <CreateButton />
        </Layout>
      </div>
      <style jsx>
        {`
          #main {
            transition: margin-left 0.5s;
            padding: 0px;
          }
          .wrapper {
            display: flex;
          }
          .openbtn {
            font-size: 18px;
            cursor: pointer;
            background-color: #dff259;
            color: black;
            padding: 5px 10px;
            margin: 10px;
            border: none;
            border-radius: 50%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            box-shadow: rgb(0 0 0 / 10%) 0 1px 4px 0,
              rgb(0 0 0 / 19%) 0 2px 6px 0;
          }
          .list-wrapper {
            width: 400px;
            border-right: 2px solid #2b2e42;
          }
          .main-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
          }
          .main-header {
            z-index: ${zIndex.header};
            position: fixed;
            width: 100%;
            top: 0;
          }
          .main-body {
            width: 100%;
            height: 100vh;
            padding-top: ${viewMode !== ViewMode.CARE && type === ViewType.MAP
              ? 64
              : 116}px;
            background: ${colors.background};
            overflow: hidden;
          }
          :global(.grecaptcha-badge) {
            visibility: hidden;
          }
          @media only screen and (min-width: 768px) {
            #main {
              margin-left: 200px !important;
              padding: 0px;
            }
            .openbtn {
              display: none;
            }
            .main-wrapper {
              width: calc(100vw - 200px);
              height: 100%;
              position: relative;
            }
            .main-header {
              z-index: ${zIndex.header};
              position: fixed;
              width: calc(100vw - 200px);
              top: 0;
            }
          }
        `}
      </style>
    </Layout>
  );
};

export default Dashboard;
