import {
  DamageEntityData,
  ErrorResponse,
  FleetRentalData,
} from '../../../../@types';

export interface ClaimsState {
  entities: DamageEntityData[] | null;
  display: DamageEntityData[] | null;
  selected: DamageEntityData | null;
  error: ErrorResponse | null;
  totalDamages: number;
  loading: boolean;
  assignableClaimTrips: Array<FleetRentalData> | null;
  isUpdating: boolean;
  isLoadingCarClaim: boolean;
  updatingStatus: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  assignableClaimTrips: null,
  error: null,
  totalDamages: 0,
  loading: false,
  isUpdating: false,
  isLoadingCarClaim: false,
  updatingStatus: false,
};
