type Bounds = {
  north: number;
  south: number;
  east: number;
  west: number;
};

type DivSize = {
  width: number;
  height: number;
};

const GLOBE_WIDTH = 256; // a constant in Google's map projection

const getZoomFromBounds = (bounds: Bounds, divSize: DivSize): number => {
  const { north, south, east, west } = bounds;
  const { width, height } = divSize;
  const latDiff = Math.abs(north - south);
  const lngDiff = Math.abs(west - east);

  const zoomForLat = Math.round(
    Math.log((width * 360) / latDiff / GLOBE_WIDTH) / Math.LN2
  );

  const zoomForLng = Math.round(
    Math.log((height * 360) / lngDiff / GLOBE_WIDTH) / Math.LN2
  );

  return (zoomForLat + zoomForLng) / 2.5;
};

export default getZoomFromBounds;
