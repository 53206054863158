const CAR_STATUSES = {
  EQUIPMENT_READY: 'EQUIPMENT_READY',
  EQUIPPED: 'EQUIPPED',
  RELEASE_READY: 'RELEASE_READY',
  RELEASED: 'RELEASED',
  RENTED: 'RENTED',
  BLOCKED: 'BLOCKED',
  TERMINATED: 'TERMINATED',
};

export default CAR_STATUSES;
