import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css`
  .user-contact-modal {
    width: 380px;
    padding: 16px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .user-contact-modal-inputs {
    width: 100%;
    padding-bottom: 16px;
  }
  .contact-modal-wrapper,
  .reason-selector-wrapper {
    margin-bottom: 8px;
  }
  .user-contact-modal-inputs {
    width: 100%;
    padding-bottom: 16px;
  }
  .user-contact-modal-row {
    display: flex;
    padding-bottom: 10px;
  }
  .street-number-input {
    width: 150px;
    margin-left: 10px;
  }
  .postal-code-input {
    width: 250px;
    margin-right: 10px;
  }
  .phone-modal-wrapper,
  .reason-selector-wrapper {
    margin-bottom: 8px;
  }
  .user-contact-title {
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    margin-bottom: 10px;
  }
  .user-contact-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .user-contact-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .user-contact-modal :global(.add-phone-input) {
    width: 100% !important;
    padding: 5px 14px 5px 58px !important;
  }
  .user-contact-modal :global(.special-label) {
    display: none;
  }
`;

export default style;
