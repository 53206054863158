import css from 'styled-jsx/css';

const style = css`
  .num-of-results {
    font-size: 12px;
    line-height: 14px;
    color: #707070;
    font-weight: 500;
    margin: 0;
    margin-right: 16px;
  }
  @media only screen and (min-width: 768px) {
    .num-of-results {
      font-size: 14px;
      line-height: 14px;
      color: #707070;
      font-weight: 500;
      margin: 0;
      margin-right: 16px;
    }
  }
`;

export default style;
