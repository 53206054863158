import React from 'react';
import { Drawer } from 'antd';
import styles from './DrawerLayout.styles';
import { CloseOutlined } from '@ant-design/icons';
import isDesktop from '../../utils/sizeHelpers';
import FilterVehicleEventsModal from '../../components/CarEditDrawer/FilterVehicleEventsModal';
import UpdateCarStatusModal from '../../components/CarEditDrawer/UpdateCarStatusModal';
import UpdateAreaModal from '../../components/CarEditDrawer/UpdateAreaModal';
import UpdateConnectionModal from '../../components/CarEditDrawer/UpdateConnectionModal';
import UpdateCategoryModal from '../../components/CarEditDrawer/UpdateCategoryModal';
import UpdateClaimNoteModal from '../../components/ClaimDrawer/UpdateClaimNoteModal';
import { useSelector } from 'react-redux';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import { ViewMode } from '../../hooks/useViewMode';
import AddUserToCircleModal from '../../components/CircleEditDrawer/AddUserToCircleModal';
import UpdateCarPlateModal from '../../components/CarEditDrawer/UpdateCarPlateModal';
import UpdatePricingModal from '../../components/CircleEditDrawer/UpdatePricingModal';
import FinishTripModal from '../../components/TripDrawer/FinishTripModal';
import { selectViewMode } from '../../redux/store/ui/common';
import AddFleetToCircleModal from '../CircleEditDrawer/AddFleetToCircleModal';
import UpdateVehicleProfileModal from '../CarEditDrawer/UpdateVehicleProfileModal';
import AddAdminToCircleModal from '../CircleEditDrawer/CircleAdmins/AddAdminToCircleModal';

const CLOSE_ICON = '/images/drawer/close.svg';
const RELOAD_ICON = '/images/drawer/reload.svg';

const trimTitle = (title: string) => {
  const MAX_LENGTH = 27;

  return title && title?.length > MAX_LENGTH
    ? title?.substring(0, MAX_LENGTH - 3) + '...'
    : title;
};

type DrawerLayoutProps = {
  visible: boolean;
  title: any;
  body: React.ReactNode;
  footer?: React.ReactNode;
  nestedDrawers?: React.ReactNode;
  onReload: any;
  onClose: () => void;
  isCarEditDrawer?: boolean;
  isClaimDrawer?: boolean;
  isCircleEditDrawer?: boolean;
  isTripDrawer?: boolean;
  isCareDrawer?: boolean;
};

const DrawerLayout: React.FC<DrawerLayoutProps> = ({
  isTripDrawer,
  isCareDrawer,
  isClaimDrawer,
  isCarEditDrawer,
  isCircleEditDrawer,
  visible,
  title,
  body,
  footer,
  nestedDrawers,
  onReload,
  onClose,
}) => {
  const viewMode = useSelector(selectViewMode);
  const selectedCar = useSelector(selectSelectedCar);
  const handleOnClickReload = (e: any) => {
    e.preventDefault();
    onReload();
  };

  const handleOnClickClose = (e: any) => {
    e.preventDefault();
    onClose();
  };

  return (
    <>
      <Drawer
        className="drawer"
        mask={false}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        width={isDesktop() ? 435 : window?.innerWidth}
        style={
          isClaimDrawer && selectedCar && viewMode === ViewMode.CLAIM
            ? { right: '250px' }
            : {}
        }
      >
        <div className="header">
          <p className="title">{trimTitle(title)}</p>
          {onReload && (
            <div className="reload-button" onClick={handleOnClickReload}>
              <img src={RELOAD_ICON} alt="reload" />
            </div>
          )}
          <div className="close-button" onClick={handleOnClickClose}>
            <img src={CLOSE_ICON} alt="close" />
          </div>
        </div>
        <div className="body hide-scrollbar">{body}</div>
        {footer && <div className="footer">{footer}</div>}
        {nestedDrawers}
        {isCarEditDrawer ? <FilterVehicleEventsModal /> : null}
        {isCarEditDrawer ? <UpdateCarStatusModal /> : null}
        {isCarEditDrawer ? <UpdateCarPlateModal /> : null}
        {isCarEditDrawer ? <UpdateAreaModal /> : null}
        {isCarEditDrawer ? <UpdateVehicleProfileModal /> : null}
        {isCarEditDrawer ? <UpdateConnectionModal /> : null}
        {isCarEditDrawer ? <UpdateCategoryModal /> : null}
        {isCircleEditDrawer ? <AddUserToCircleModal /> : null}
        {isCircleEditDrawer ? <AddAdminToCircleModal /> : null}
        {isCircleEditDrawer ? <AddFleetToCircleModal /> : null}
        {isCircleEditDrawer ? <UpdatePricingModal /> : null}
        {isTripDrawer || isCareDrawer ? <FinishTripModal /> : null}
        <UpdateClaimNoteModal />
      </Drawer>
      <style jsx>{styles}</style>
    </>
  );
};

export default DrawerLayout;
