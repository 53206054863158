import React from 'react';
import SelectorLayout from '../../components/shared/SelectorLayout';
import { useSelector } from 'react-redux';
import { selectSelectedCar } from '../../redux/store/userData/cars';

type CircleSelectorProps = {
  style?: any;
  className?: string;
  value?: any;
  loading?: boolean;
  currentStatus?: string;
  fleetId?: string;
  onSelect?: (value: string) => void;
};

interface SelectorOptions {
  value: string | undefined;
  label: string | undefined;
}

const CircleSelector: React.FC<CircleSelectorProps> = (props) => {
  const selectedCar = useSelector(selectSelectedCar);

  const options = Array<SelectorOptions>();
  selectedCar?.availableCircles?.forEach((circle) => {
    if (circle.circleType === 'standard') {
      options.push({
        value: circle.id,
        label: circle.name,
      });
    }
  });

  return (
    <SelectorLayout
      keyPrefix="care-search"
      placeholder="Default"
      options={[...options, { value: '', label: 'Default' }]}
      {...props}
    />
  );
};

export default CircleSelector;
