import { RootState } from '../../../config';
import { FleetBookingData, FleetRentalData } from '../../../../@types';

export const selectTrips = (state: RootState): FleetRentalData[] | null =>
  state.userData.trips.entities;

export const selectDisplayTrips = (
  state: RootState
): FleetRentalData[] | null => state.userData.trips.display;

export const selectTotalTrips = (state: RootState): number =>
  state.userData.trips.totalTrips;

export const selectSelectedTrip = (state: RootState): FleetRentalData | null =>
  state.userData.trips.selected;

export const selectTripLoading = (state: RootState): boolean =>
  state.userData.trips.loading;

export const selectTripInvoiceData = (state: RootState): any =>
  state.userData.trips.selectedTripInvoiceData;

export const selectFinishTripViolations = (state: RootState): string[] =>
  state.userData.trips.finishTripViolations;

export const selectBookings = (state: RootState): FleetBookingData[] | null =>
  state.userData.trips.bookingEntities;

export const selectDisplayBookings = (
  state: RootState
): FleetBookingData[] | null => state.userData.trips.bookingDisplay;

export const selectSelectedBooking = (state: RootState): any =>
  state.userData.trips.selectedBooking;

export const selectTotalBookings = (state: RootState): number =>
  state.userData.trips.totalBookings;

export const selectBookingsLoading = (state: RootState): boolean =>
  state.userData.trips.loadingBookings;
