import css from 'styled-jsx/css';

const style = css`
  .booking-drawer-body {
    padding-bottom: 60px;
    background: #ffffff;
    height: 100%;
  }
  .booking-drawer-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 24px 24px;
    background: #f2f2f2;
  }
  .booking-code {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: auto;
  }
`;

export default style;
