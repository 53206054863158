import {
  ErrorResponse,
  FleetRentalData,
  FleetBookingData,
} from '../../../../@types';

export interface TripsState {
  entities: FleetRentalData[] | null;
  display: FleetRentalData[] | null;
  totalTrips: number;
  selected: FleetRentalData | null;
  bookingEntities: FleetBookingData[] | null;
  bookingDisplay: FleetBookingData[] | null;
  totalBookings: number;
  selectedBooking: FleetBookingData | null;
  error: ErrorResponse | null;
  loading: boolean;
  loadingBookings: boolean;
  finishingTrip: boolean;
  finishTripViolations: string[];
  selectedTripInvoiceData: any;
}

export const initialState = {
  entities: null,
  display: null,
  totalTrips: 0,
  selected: null,
  bookingEntities: null,
  bookingDisplay: null,
  totalBookings: 0,
  selectedBooking: null,
  error: null,
  loading: false,
  loadingBookings: false,
  finishingTrip: false,
  finishTripViolations: [],
  selectedTripInvoiceData: null,
};
