import React, { useMemo } from 'react';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CircleInfo.styles';
import {
  selectSelectedCircle,
  updateCircleIfNeeded,
} from '../../redux/store/userData/circles';
import { typeLabelForCircle } from '../../utils';
import ListEditItem from '../../components/shared/ListEditItem';
import ListItem from '../../components/shared/ListItem';
import { renderText } from '../shared/DrawerLayout/renderText';
import LinkToPricing from '../../components/shared/LinkToPricing';
import { renderDateTime } from '../shared/TableLayout';
import { selectPricings } from '../../redux/store/appData/pricings';
import { openModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { useIntl } from 'react-intl';
import { PermissionSwitch } from './PermissionSwitch';
import cn from 'classnames';
import { CircleInputData } from '../../@types';

const kycList = [
  {
    label: 'Address required',
    value: 'addressRequired',
  },
  {
    label: 'Payment method required',
    value: 'paymentMethodRequired',
  },
  {
    label: 'SEPA credit check required',
    value: 'creditCheckForSepaRequired',
  },
  {
    label: 'Driver licence required',
    value: 'driverLicenseRequired',
  },
  {
    label: 'Identity approve required',
    value: 'identityApprovalRequired',
  },
];

const CircleInfo: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCircle = useSelector(selectSelectedCircle);
  const pricings = useSelector(selectPricings);
  const circlePricing = useMemo(
    () =>
      pricings?.find(
        (pricing: { name: string | undefined }) =>
          pricing.name === selectedCircle?.pricing
      ),
    [selectedCircle, pricings]
  );
  const pricingEditDisabled = useMemo(
    () => !selectedCircle?.brandId || selectedCircle?.circleType === 'service',
    [selectedCircle]
  );

  const handleEditPricing = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(openModal('updateCirclePricingModal'));
  };

  const handleInvitesEnabledChange = (checked: boolean) => {
    dispatch(
      updateCircleIfNeeded(token, selectedCircle?.id!, {
        invitesEnabled: checked,
      })
    );
  };

  const handlePermissionChanged = (circleInput: CircleInputData) => {
    dispatch(updateCircleIfNeeded(token, selectedCircle?.id!, circleInput));
  };

  return (
    <>
      <div className="circle-info">
        <ListItem
          label={intl.formatMessage({
            id: 'circle.invitesEnabled',
          })}
          value={
            <div className="circle-info-switch-wrapper">
              <Switch
                checked={selectedCircle?.invitesEnabled}
                onChange={handleInvitesEnabledChange}
              />
            </div>
          }
          style={{ width: '40%' }}
        />
        <div className="circle-info-wrapper">
          <ListItem
            label="Type"
            value={typeLabelForCircle(intl, selectedCircle?.circleType!)}
            style={{ width: '100%' }}
          />
          <ListItem
            label="Code"
            value={renderText(selectedCircle?.circleCode!)}
            style={{ width: '100%' }}
          />
        </div>
        {selectedCircle?.invitesEnabled && (
          <ListItem
            label={intl.formatMessage({
              id: 'circle.invitesURL',
            })}
            value={renderText(selectedCircle?.inviteURL!)}
          />
        )}
        <ListEditItem
          label="Pricing"
          leftItem={
            <LinkToPricing
              text={selectedCircle?.pricing!}
              pricing={circlePricing}
            />
          }
          rightItem={
            !pricingEditDisabled ? (
              <img
                className="pricing-edit-button"
                src="/images/drawer/edit.svg"
                alt="edit"
                onClick={handleEditPricing}
              />
            ) : null
          }
        />
        <ListItem
          label={intl.formatMessage({
            id: 'lastModifiedAt',
          })}
          value={renderDateTime(selectedCircle?.lastModifiedAt)}
        />
        <ListItem
          label={intl.formatMessage({
            id: 'lastModifiedBy',
          })}
          value={renderText(selectedCircle?.lastModifiedBy!)}
        />
      </div>
      <div className="panel-section-title">
        {intl.formatMessage({
          id: `circle.kyc`,
        })}
      </div>
      <PermissionSwitch
        isSelected={selectedCircle?.positiveBalanceRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            positiveBalanceRequired: checked,
          });
        }}
        label={intl.formatMessage({
          id: 'circle.positiveBalanceRequired',
        })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.addressRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            addressRequired: checked,
          });
        }}
        label={intl.formatMessage({
          id: 'circle.addressRequired',
        })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.phoneVerificationRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            phoneVerificationRequired: checked,
          });
        }}
        label={intl.formatMessage({
          id: 'circle.phoneRequired',
        })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.paymentMethodRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            paymentMethodRequired: checked,
          });
        }}
        label={intl.formatMessage({
          id: 'circle.paymentRequired',
        })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.creditCheckForSepaRequired}
        disabled={!selectedCircle?.paymentMethodRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            creditCheckForSepaRequired: checked,
          });
        }}
        label={intl.formatMessage({
          id: 'circle.creditCheckRequiredForSepa',
        })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.driverLicenseRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            driverLicenseRequired: checked,
          });
        }}
        label={intl.formatMessage({ id: 'circle.licenseRequired' })}
      />
      <PermissionSwitch
        isSelected={selectedCircle?.identityApprovalRequired}
        onChange={(checked: boolean) => {
          handlePermissionChanged({
            identityApprovalRequired: checked,
          });
        }}
        label={intl.formatMessage({ id: 'circle.identityRequired' })}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default CircleInfo;
