import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { DatePicker, Modal, Button, Select, Input } from 'antd';
import styles from './BookingCreateModal.styles';
import { colors, zIndex } from '../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { createBooking } from '../../redux/store/userData/trips';
import {
  selectDisplayCars,
  selectSelectedCar,
} from '../../redux/store/userData/cars/selectors';
import { useIntl } from 'react-intl';
import TextSearch from '../Filter/TextSearch';
import dayjs from 'dayjs';
import { OutputBookingData } from '../../@types';
import { ANT_PICKERS_FORMAT } from '../../utils';

const { RangePicker } = DatePicker;
const { Option } = Select;

type AddServiceModalProps = {
  visible?: boolean;
};

const SUPPORTED_BOOKING_TYPES: OutputBookingData['bookingType'][] = [
  'usual',
  'service',
];

const BookingCreateModal: React.FC<AddServiceModalProps> = ({ visible }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedCar);
  const displayCars = useSelector(selectDisplayCars) || [];

  const [type, setType] = useState('usual') as any;
  const [carId, setCarId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [dateRange, setDateRange] = useState(null) as any;
  const _visible = useSelector(getIsVisibleModal('BookingCreateModal'));

  const isDisabled = !(dateRange?.length === 2 && carId && userEmail);

  const handleCancel = () => {
    dispatch(closeModal('BookingCreateModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    batch(() => {
      dispatch(
        createBooking(
          {
            carId,
            type,
            ...(type === 'service' && { status: 'CONFIRMED' }),
            from: dateRange[0].format('YYYY-MM-DDTHH:mmZ'),
            to: dateRange[1].format('YYYY-MM-DDTHH:mmZ'),
            ...(userEmail && { userEmail }),
          },
          () => handleCancel()
        )
      );
    });
  };

  useEffect(() => {
    if (_visible) {
      setType('usual');
      setDateRange(null);
      setUserEmail('');
      setCarId('');
    }
  }, [_visible]);

  return _visible ? (
    <>
      <div>
        <Modal
          wrapClassName="add-service-wrapper"
          title={intl.formatMessage({
            id: `fleetadmin.booking.create.title`,
          })}
          visible={visible || _visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="offline-booking-contents" tabIndex={0}>
            <p className="label">
              {intl.formatMessage({
                id: `fleetadmin.booking.create.type`,
              })}
            </p>
            <Select
              defaultValue="usual"
              value={type}
              style={{ width: '100%', marginBottom: 15 }}
              placeholder="Type"
              dropdownStyle={{ zIndex: zIndex.dropDown }}
              onChange={(value: string) => {
                setType(value);
              }}
            >
              {SUPPORTED_BOOKING_TYPES.map((type) => (
                <Option key={`type-option-${type}`} value={type}>
                  {intl.formatMessage({
                    id: `fleetadmin.booking.create.type.${type}`,
                  })}
                </Option>
              ))}
            </Select>
            <p className="label">
              {intl.formatMessage({
                id: `fleetadmin.booking.create.plate`,
              })}
            </p>
            <TextSearch
              style={{
                padding: 0,
                paddingTop: 0,
                width: '100%',
                marginBottom: 15,
              }}
              dataSource={displayCars?.map((car) => ({
                text: car.plate,
                value: car.plate,
                id: car.id,
              }))}
              defaultValue={selectedCar?.plate}
              setValue={(value, option) => {
                setCarId(option?.id);
              }}
            />
            <>
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.booking.create.user`,
                })}
              </p>
              <Input
                style={{ marginBottom: 15 }}
                placeholder={intl.formatMessage({
                  id: 'fleetadmin.booking.create.user.placeholder',
                })}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </>
            <p className="label">
              {intl.formatMessage({
                id: `fleetadmin.booking.create.duration`,
              })}
            </p>
            <RangePicker
              format={ANT_PICKERS_FORMAT}
              style={{ width: '100%' }}
              // @ts-ignore
              showTime={{
                format: 'HH:mm',
                minuteStep: 15,
              }}
              value={dateRange}
              onChange={(value) => {
                setDateRange(value);
              }}
              minDate={dayjs(new Date())}
            />
            <div className="submit-button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `fleetadmin.booking.create.button`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  ) : null;
};

export default BookingCreateModal;
