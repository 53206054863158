import React, { useState, useEffect } from 'react';
import { Button, Radio, Checkbox } from 'antd';
import DrawerModal from '../../../components/shared/DrawerModal';
import styles from './PricingUpdateModal.styles';
import { useIntl } from 'react-intl';
import { DatePicker } from 'antd/es';
import { isBefore } from 'date-fns';

const PricingUpdateModal = ({ visibleModal, handleCancel, onSubmit }) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState(null) as any;
  const [comment, setComment] = useState('');
  const [isClearComment, setClearComment] = useState(false);
  const [changeType, setChangeType] = useState(1);

  useEffect(() => {
    setClearComment(false);
  }, [visibleModal]);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit(changeType === 1, comment, startDate);
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleCancel();
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          handleCancel();
        }}
      >
        <div className="update-pricing-modal">
          <div className="status-selector-wrapper">
            <p className="label">Comment (Optional)</p>
            <textarea
              className="comment-area"
              placeholder="Type here ..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              draggable={false}
              disabled={isClearComment}
            >
              Type here ...
            </textarea>
          </div>

          <>
            <p className="period-label">
              {intl.formatMessage({ id: 'fleetadmin.car.status.range' })}
            </p>
            <Radio.Group
              onChange={(e) => setChangeType(e.target.value)}
              value={changeType}
            >
              <div className="radio-wrapper">
                <div className="row" style={{ marginBottom: 16 }}>
                  <Radio value={1}>
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.instant',
                    })}
                  </Radio>
                </div>
                <div className="row">
                  <Radio value={2} />
                  <div style={{ fontSize: 15, width: 35 }}>
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.from',
                    })}
                  </div>
                  <div style={{ marginLeft: 8, marginRight: 8, width: 180 }}>
                    <DatePicker
                      getPopupContainer={(triggerNode: HTMLElement) => {
                        return triggerNode.parentNode as HTMLElement;
                      }}
                      disabledDate={(date) =>
                        // @ts-ignore
                        isBefore(new Date(date), new Date())
                      }
                      style={{ minWidth: 'unset' }}
                      format="YYYY-MM-DD HH:mm"
                      // @ts-ignore
                      showTime={{
                        format: 'HH:mm',
                        minuteStep: 15,
                      }}
                      onChange={(value) => setStartDate(value)}
                      popupStyle={{ position: 'absolute', left: '-30%' }}
                    />
                  </div>
                </div>
              </div>
            </Radio.Group>
          </>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default PricingUpdateModal;
