import React from 'react';
import { Modal } from 'antd';
import ImageSlide from './ImageSlide';
import NoImagePlaceholder from './NoImagePlaceholder';
import styles from './AllAroundModal.styles';

type AllAroundModalProps = {
  visible?: boolean;
  allAroundCheck?: {
    images: string[];
    title: string;
    chassisNumber: string;
  };
  onClose?: () => void;
};

// @ts-ignore
const AllAroundModal: React.FC<AllAroundModalProps> = ({
  allAroundCheck,
  visible,
  onClose,
}) => {
  const handleCancel = () => {
    // @ts-ignore
    onClose();
  };

  return (
    allAroundCheck != null && (
      <>
        <div>
          <Modal
            wrapClassName="allAround-modal-wrapper"
            title={`${allAroundCheck.title} ${allAroundCheck.chassisNumber}`}
            visible={visible}
            width={1520}
            onCancel={handleCancel}
            footer={null}
          >
            {allAroundCheck.images.length === 0 ? (
              <NoImagePlaceholder />
            ) : (
              <ImageSlide images={allAroundCheck.images} />
            )}
          </Modal>
        </div>
        <style jsx global>
          {styles}
        </style>
      </>
    )
  );
};

export default AllAroundModal;
