import { RootState } from '../../../config';
import { FleetUserTransactions } from '../../../../@types';

export const selectUsers = (state: RootState): any[] | null =>
  state.userData?.users?.entities;

export const selectDisplayUsers = (state: RootState): any[] | null =>
  state.userData?.users?.display;

export const selectTotalUsers = (state: RootState): number | null =>
  state.userData?.users?.total;

export const selectSelectedUser = (state: RootState): any | null =>
  state.userData?.users?.selected;

export const selectDuplicateUser = (state: RootState): any | null =>
  state.userData?.users?.selectedDuplicate;

export const selectUserLoading = (state: RootState): boolean =>
  state.userData.users.loading;

export const selectCirtificateLoading = (state: RootState): boolean =>
  state.userData.users.certificateLoading;

export const selectUsersLoading = (state: RootState) =>
  state.userData?.users?.loading;

export const selectLoadingGDPR = (state: RootState) =>
  state.userData?.users?.loadingGDPR;

export const selectTariffs = (state: RootState) =>
  state.userData?.users?.tariffs;

export const selectUserTransactions = (
  state: RootState
): FleetUserTransactions | null =>
  state.userData?.users?.selectedUserTransactions;

export const selectUserTransactionsLoading = (state: RootState): boolean =>
  state.userData?.users?.userTransactionsLoading;
