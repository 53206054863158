import { ErrorResponse } from '../../../../@types';

export interface ReceiptsState {
  entities: any[] | null;
  display: any[] | null;
  selected: any | null;
  error: ErrorResponse | null;
  loading: boolean;
  receiptLoading: boolean;
  total: number | null;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
  receiptLoading: false,
  total: null,
};
