import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Modal, Input, Button } from 'antd';
import styles from './AddCategoryModal.styles';
import TextSearch from '../../components/Filter/TextSearch';
import { colors } from '../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { createCategory } from '../../redux/store/userData/categories';
import { selectToken } from '../../redux/store/userData/user/selectors';
import { selectFleets } from '../../redux/store/appData/fleets';
import { fetchCategoriesIfNeeded } from '../../redux/store/userData/categories/thunk';
import { useIntl } from 'react-intl';

type AddCategoryModalProps = {
  visible?: boolean;
};

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({ visible }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [name, setName] = useState('');
  const [acrissCode, setAcrissCode] = useState('');
  const [description, setDescription] = useState('');
  const [brand, setBrand] = useState('');
  const fleets = useSelector(selectFleets) as any;
  const brands = [
    // @ts-ignore
    ...new Set(fleets?.map((item) => item.brandId)),
  ].map((brand) => ({
    id: brand,
    name: fleets?.find((fleet: any) => fleet.brandId === brand).brandName,
  }));
  const _visible = useSelector(getIsVisibleModal('createCategoryModal'));
  const isDisabled = !(name && acrissCode && brand && description);

  const handleCancel = () => {
    dispatch(closeModal('createCategoryModal'));
  };

  const validateInputs = () => {
    return true;
  };

  const handleOnSubmit = (e: any) => {
    if (validateInputs()) {
      e.preventDefault();
      batch(() => {
        dispatch(
          createCategory(
            token,
            {
              name,
              acrissCode,
              description,
              brandId: brand,
            },
            () => {
              dispatch(fetchCategoriesIfNeeded());
            }
          )
        );
      });
      handleCancel();
    }
  };

  useEffect(() => {
    if (_visible) {
      setName('');
      setAcrissCode('');
      setDescription('');
      setBrand('');
    }
  }, [_visible, visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-category-wrapper"
          title={`Create a new category`}
          visible={visible || _visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-category-contents" tabIndex={0}>
            <div className="add-category-inputs-container">
              <Input
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="add-category-inputs-container">
              <Input
                value={acrissCode}
                placeholder="Acriss Code"
                onChange={(e) => setAcrissCode(e.target.value)}
              />
            </div>
            <div className="add-category-inputs-container">
              <Input
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <TextSearch
              placeholder="Brand"
              dataSource={brands.map((brand) => {
                return {
                  value: brand.id,
                  text: brand.name,
                };
              })}
              setValue={setBrand}
              defaultOpen={false}
            />
            <div className="submit-button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default AddCategoryModal;
