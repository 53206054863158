import { ErrorResponse, FleetServiceTripData } from '../../../../@types';

export interface CaresState {
  entities: FleetServiceTripData[] | null;
  display: FleetServiceTripData[] | null;
  selected: FleetServiceTripData | null;
  error: ErrorResponse | null;
  loading: boolean;
  finishingTrip: boolean;
  finishTripViolations: string[];
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
  finishingTrip: false,
  finishTripViolations: [],
};
