import { AreaData } from '../@types';

const getAreaNameById = (areas: any[] | AreaData | null, areaId: unknown) => {
  if (!Array.isArray(areas)) {
    return areaId;
  }

  if (areaId === '') {
    return 'Not assigned';
  }

  const theArea = areas.find((area) => area.id === areaId);

  if (!theArea) {
    return areaId;
  }

  return theArea.name;
};

export default getAreaNameById;
