import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, VehicleEventData } from '../../../../@types';
import { EventsTypes } from '../../../../@types/enums';
import moment from '../../../../utils/formatDate';

export const setVehicleEventsFrom = (payload: {
  from: string;
  target: EventTarget | string;
}) => createAction(EventsTypes.SET_VEHICLE_EVENTS_FROM, payload);

export const vehicleEventsRequest = (payload: {
  from: Date | moment.MomentInput | null;
  to: Date | moment.MomentInput | null;
  target: EventTarget | string;
}) => createAction(EventsTypes.VEHICLE_EVENTS_REQUEST, payload);

export const vehicleEventsSuccess = (payload: {
  events: VehicleEventData[];
  target: EventTarget | string;
}) => createAction(EventsTypes.VEHICLE_EVENTS_SUCCESS, payload);

export const vehicleEventsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(EventsTypes.VEHICLE_EVENTS_FAILURE, error);

export const addVehicleEvents = (payload: VehicleEventData[]) =>
  createAction(EventsTypes.ADD_VEHICLE_EVENTS, payload);

export const loadMoreVehicleEventsRequest = (payload: any) =>
  createAction(EventsTypes.LOAD_MORE_VEHICLE_EVENTS_REQUEST, payload);

export const loadMoreVehicleEventsSuccess = (payload: {
  events: VehicleEventData[];
  target: EventTarget | string;
}) => createAction(EventsTypes.LOAD_MORE_VEHICLE_EVENTS_SUCCESS, payload);

export const vehicleEventsEmptyResponse = (payload: {
  target: EventTarget | string;
}) => createAction(EventsTypes.VEHICLE_EVENTS_EMPTY_RESPONSE, payload);

export const resetVehicleEvents = () =>
  createAction(EventsTypes.VEHICLE_EVENTS_RESET);

export const setCarEventsFilters = (carEventsFilters: string[]) =>
  createAction(EventsTypes.SET_VEHICLE_EVENTS_FILTERS, { carEventsFilters });
