import React from 'react';
import { SVGOverlay } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { Marker } from 'react-map-gl';
import styles from './SelectedCarArea.styles';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';
import { isInsideArea } from '../../utils/isInsideArea';
import locationFromString from '../../utils/locationFromString';
import { colors } from '../../theme/theme';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import { selectDisplayAreas } from '../../redux/store/appData/areas';
import { AreaData } from '../../@types';

const AREA_LABEL_ICON = '/images/map/area-label-icon.svg';

const getCoordinates = (area: AreaData) => {
  // @ts-ignore
  return area.geoJSONData.features[0].geometry.coordinates.map(
    (coordinate: any) => {
      const coordinateArr = coordinate.split(' ');
      return [Number(coordinateArr[0]), Number(coordinateArr[1])];
    }
  );
};

const SelectedCarArea: React.FC = () => {
  const areas = useSelector(selectDisplayAreas);
  const selectedCar = useSelector(selectSelectedCar);
  const selectedArea = areas?.find(
    (areaToFind) => areaToFind.id === selectedCar?.areaId
  );
  if (!selectedArea) return null;

  const isSelectedCarInsideArea = selectedArea
    ? isInsideArea(locationFromString(selectedCar?.position!), selectedArea)
    : null;

  const areaWithGeoJson = fixGeoJSONCoordinates(selectedArea.geoJSONData);
  const bounds = getBoundsFromPoints(
    // @ts-ignore
    areaWithGeoJson.features[0].geometry.coordinates[0]
  );
  const position = {
    lat: bounds.south,
    lng: (bounds.east + bounds.west) / 2,
  };
  const redraw = ({ project }: any) => {
    const coordinates = getCoordinates(selectedArea);
    const points = coordinates.map((coordinate: any[]) => {
      return project([coordinate[0], coordinate[1]]).join(',');
    });

    return (
      <polygon
        key={`area-${selectedArea.id}`}
        id={`area-polygon-${selectedArea.id}`}
        points={points.join(' ')}
        style={{
          fill: isSelectedCarInsideArea
            ? colors.area_selected
            : colors.area_outside,
          fillOpacity: 0.2,
          stroke: isSelectedCarInsideArea
            ? colors.area_selected
            : colors.area_outside,
          strokeOpacity: 1,
          strokeWidth: 2,
          cursor: 'pointer',
        }}
      />
    );
  };

  return (
    <>
      <SVGOverlay redraw={redraw} />
      <Marker
        className="selected-area-label"
        key={`area-label-${selectedArea.id}`}
        latitude={position.lat}
        longitude={position.lng}
        offsetTop={4}
      >
        <>
          <div
            className="label-container"
            style={{
              background: isSelectedCarInsideArea
                ? colors.area_selected
                : colors.area_outside,
            }}
          >
            <img src={AREA_LABEL_ICON} alt="area icon" />
            <span className="label-text">{selectedArea.name}</span>
          </div>
          <style jsx>{styles}</style>
        </>
      </Marker>
    </>
  );
};

export default SelectedCarArea;
