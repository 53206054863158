import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import styles from './Widget.styles';
import { ViewMode } from '../../hooks/useViewMode';
import {
  toggleAreaPolygons,
  toggleCarMarkers,
  selectVisibleAreaPolygons,
  selectVisibleCarMarkers,
  selectVisibleParkingAreasPolygons,
  toggleParkingAreaPolygons,
} from '../../redux/store/ui/map';
import { selectViewMode } from '../../redux/store/ui/common/selectors';

const CarIcon = '/images/widget/widget-icon-car.svg';
const AreaIcon = '/images/widget/widget-icon-area.svg';
const ParkingIcon = '/images/widget/widget-area-parking.svg';

const Widget = () => {
  const visibleCarMarkers = useSelector(selectVisibleCarMarkers);
  const visibleAreaPolygons = useSelector(selectVisibleAreaPolygons);
  const visibleParkingAreasPolygons = useSelector(
    selectVisibleParkingAreasPolygons
  );
  const viewMode = useSelector(selectViewMode);
  const dispatch = useDispatch();

  const handleOnClickCarSwitch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(toggleCarMarkers());
  };

  const handleOnClickAreaSwitch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(toggleAreaPolygons());
  };

  const handleOnClickParkingSwitch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(toggleParkingAreaPolygons());
  };

  return (
    <>
      <div className="container">
        <div
          className={cn('switch', { visible: visibleAreaPolygons })}
          onClick={handleOnClickAreaSwitch}
        >
          <img src={AreaIcon} alt="car icon" />
        </div>
        <div
          className={cn('switch', { visible: visibleParkingAreasPolygons })}
          onClick={handleOnClickParkingSwitch}
        >
          <img src={ParkingIcon} alt="car icon" />
        </div>
        {(viewMode === ViewMode.VEHICLE || viewMode === ViewMode.CLAIM) && (
          <div
            className={cn('switch', { visible: visibleCarMarkers })}
            onClick={handleOnClickCarSwitch}
          >
            <img src={CarIcon} alt="car icon" />
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Widget;
