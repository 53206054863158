import React from 'react';
import ListEditItem from '../../../shared/ListEditItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import { renderDate } from '../../../shared/TableLayout';
import styles from '../UserInfo.styles';

interface UserIdentityInfoProps {
  selectedUser: any;
  intl: any;
  onEditOrg: () => void;
}

const UserOrgInfo: React.FC<UserIdentityInfoProps> = ({
  selectedUser,
  intl,
  onEditOrg,
}) => {
  const address = selectedUser?.address?.streetName
    ? `${selectedUser.address.streetName}, ${
        selectedUser.address.streetNumber
      }, ${selectedUser.address.postalCode}, ${selectedUser.address.city}, ${
        selectedUser.address.country || 'DE'
      }`
    : null;
  return (
    <>
      <div className="user-section-title" style={{ marginTop: 10 }}>
        {intl.formatMessage({ id: `fleetadmin.users.info.org` })}
      </div>
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.companyname` })}
        leftItem={renderText(selectedUser?.companyName, false)}
        onClickEdit={onEditOrg}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.legaltype` })}
        leftItem={renderText(selectedUser?.legalType, false)}
        onClickEdit={onEditOrg}
      />
      {selectedUser?.legalType === 'Sonstige' && (
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.users.info.otherlegaltype`,
          })}
          leftItem={renderText(selectedUser?.otherLegalType, true)}
          onClickEdit={onEditOrg}
        />
      )}
      <ListEditItem
        label={intl.formatMessage({
          id: `fleetadmin.users.info.registerNr`,
        })}
        leftItem={renderText(selectedUser?.registerNr, false)}
        onClickEdit={onEditOrg}
      />
      <ListEditItem
        label={intl.formatMessage({
          id: `fleetadmin.users.info.registerLocation`,
        })}
        leftItem={renderText(selectedUser?.registerLocation, false)}
        onClickEdit={onEditOrg}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.address` })}
        leftItem={renderText(address, false)}
        verified={selectedUser?.addressVerified}
        onClickEdit={onEditOrg}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default UserOrgInfo;
