import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingCircleEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  updateBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import { useIntl } from 'react-intl';
import { FleetBookingInputData } from '../../../@types';
import SelectorLayout from '../../shared/SelectorLayout';

const BookingCircleEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedBooking = useSelector(selectSelectedBooking) as any;
  const visibleModal = useSelector(getIsVisibleModal('BookingCircleEditModal'));
  const availableCircles = selectedBooking?.driver?.circlesData?.filter(
    (item) => item?.name
  );

  const [circle, setCircle] = useState(null);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const updateBookingInput: FleetBookingInputData = {
      ...(circle && { circleId: circle }),
    };
    dispatch(updateBooking(selectedBooking?.id, updateBookingInput));
    dispatch(closeModal('BookingCircleEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    dispatch(closeModal('BookingCircleEditModal'));
  };

  useEffect(() => {
    if (visibleModal) {
      setCircle(null);
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingCircleEditModal'));
        }}
      >
        <div className="booking-user-edit-modal">
          <div className="booking-user-edit-wrapper">
            <div className="booking-user-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.booking.update.circle.title',
                })}
              </p>
            </div>
          </div>
          <>
            <SelectorLayout
              keyPrefix="circle-select"
              value={circle}
              placeholder={intl.formatMessage({
                id: 'fleetadmin.booking.circle.label',
              })}
              options={[
                // options: admin joined circles plus circles joined by selected admin
                ...(availableCircles?.map((circle) => ({
                  value: circle?.id,
                  label: circle?.name,
                })) || []),
              ]}
              onSelect={(value) => setCircle(value)}
            />
          </>

          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.assign',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingCircleEditModal;
