import css from 'styled-jsx/css';
import { colors, common } from '../../theme/theme';

const style = css`
  .target-selector {
    width: 100%;
    ${common.panelMaxHeight(0)}
  }
  .input-wrapper {
    padding: 16px;
  }
  .target-options {
    list-style: none;
    padding: 0 0 16px;
    margin: 0;
    width: 100%;
    ${common.panelMaxHeight(64)}
    overflow-y: scroll;
  }
  .target-option {
    width: 100%;
    padding: 4px 16px 4px 28px;
    font-size: 16px;
    cursor: pointer;
  }
  .target-option:hover {
    background: ${colors.hover};
  }
`;

export default style;
