import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './PricingTable.styles';
import {
  deletePricing,
  selectSelectedPricing,
  updatePricing,
  uploadPricingFileIfNeeded,
} from '../../redux/store/appData/pricings';
import { Button, Collapse, Modal, Switch, Upload } from 'antd';
import ListEditItem from '../shared/ListEditItem';
import { selectAuth, selectToken } from '../../redux/store/userData/user';
import ListItem from '../shared/ListItem';
import { useIntl } from 'react-intl';
import { renderText } from '../shared/DrawerLayout/renderText';
import FeeItemModal from './FeeItemModal/FeeItemModal';
import PricingUpdateModal from './PricingUpdateModal/PricingUpdateModal';
import { openConfirmModal } from '../../redux/store/ui/common';
import { formatPrice } from '../../utils';

const { Panel } = Collapse;

const PricingTable: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector(selectToken);
  const selectedPricing = useSelector(selectSelectedPricing);
  const user = useSelector(selectAuth);
  const [updatedFeeItems, setUpdatedFeeItems] = useState({});
  const [selectedFee, setSelectedFee] = useState(null);
  const [openedUpdateModal, setOpenedUpdateModal] = useState(false);
  const pricingId = selectedPricing?.id?.split('-')?.[0];
  const handleBeforeUpload = (file: { name: any }) => {
    Modal.info({
      title: 'Upload file (*.pdf)',
      content: file.name,
      okText: 'Upload file',
      onOk() {
        dispatch(uploadPricingFileIfNeeded(token, pricingId, file));
      },
    });
    return false;
  };

  const onFeeItemUpdate = (feeCode, newPrice, newComment) => {
    setUpdatedFeeItems((prevState) => ({
      ...prevState,
      [feeCode]: {
        ...prevState?.[feeCode],
        changedPrice: parseFloat(newPrice),
        changePriceComment: newComment,
      },
    }));
  };

  const onUpdatePricing = (isInstant, comment, startDate) => {
    dispatch(
      updatePricing(
        pricingId,
        {
          ...(!isInstant && {
            validFrom: startDate.toISOString(),
          }),
          feeItems: Object.keys(updatedFeeItems).map((key) => ({
            code: key,
            ...updatedFeeItems[key],
          })),
          comment,
        },
        () => setUpdatedFeeItems({})
      )
    );
    setOpenedUpdateModal(false);
  };

  const handleOnDiscard = () => {
    dispatch(
      openConfirmModal({
        destructive: true,
        title: intl.formatMessage({
          id:
            selectedPricing?.status === 'SCHEDULED'
              ? `fleetadmin.pricing.delete`
              : `fleetadmin.pricing.discard`,
        }),
        onConfirm: () => {
          if (selectedPricing?.status === 'SCHEDULED') {
            dispatch(
              deletePricing(selectedPricing.id, selectedPricing.version)
            );
            setUpdatedFeeItems({});
          } else {
            setUpdatedFeeItems({});
          }
        },
      })
    );
  };

  const onFeeDisable = (fee) => {
    setUpdatedFeeItems((prevState) => ({
      ...prevState,
      [fee.code]: {
        ...prevState?.[fee.code],
        disabled: !fee.disabled,
      },
    }));
  };

  useEffect(() => {
    setUpdatedFeeItems({});
    setSelectedFee(null);
    setOpenedUpdateModal(false);
  }, [selectedPricing]);

  return (
    <div style={{ paddingTop: 24 }}>
      <p className="pricing-table-section-title">
        {intl.formatMessage({ id: 'fleetadmin.pricing.section.pdf' })}
      </p>
      <ListEditItem
        label=""
        leftItem={
          <a
            href={selectedPricing?.pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadOutlined style={{ marginRight: 4 }} />
            Herunterladen
          </a>
        }
        rightItem={
          user?.permissions?.fleetManager && (
            <Upload
              name="file"
              accept=".pdf"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
            >
              <Button
                icon={<UploadOutlined />}
                className="upload-pdf"
                type="link"
              >
                {intl.formatMessage({ id: 'fleetadmin.pricing.replace' })}
              </Button>
            </Upload>
          )
        }
      />
      <p className="pricing-table-section-title">
        {intl.formatMessage({ id: 'fleetadmin.pricing.section.fees' })}
      </p>
      {selectedPricing?.items?.length > 0 && (
        <div className="pricing-collapse-container">
          <Collapse bordered={false}>
            {selectedPricing?.feeItems?.map((fee: any, index) => (
              <Panel key={`fee-${index}`} header={`${fee.code} | ${fee.name}`}>
                <ListItem
                  style={{ marginRight: 0 }}
                  label={intl.formatMessage({
                    id: 'fleetadmin.pricing.fee.name',
                  })}
                  value={fee.name}
                />
                <div style={{ display: 'flex' }}>
                  <ListItem
                    style={{ flex: 1, marginRight: 0 }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.pricing.fee.type',
                    })}
                    value={fee.type}
                  />
                  <ListEditItem
                    style={{ flex: 1, marginRight: 0 }}
                    label={intl.formatMessage({
                      id: `fleetadmin.pricing.fee.status`,
                    })}
                    leftItem={renderText(
                      (updatedFeeItems?.[fee.code]?.disabled !== undefined
                        ? updatedFeeItems?.[fee.code]?.disabled
                        : fee.disabled) === true
                        ? 'enabled'
                        : 'disabled'
                    )}
                    rightItem={
                      fee?.canBeDisabled && (
                        <Switch
                          checked={
                            updatedFeeItems?.[fee.code]?.disabled !== undefined
                              ? updatedFeeItems?.[fee.code]?.disabled
                              : fee.disabled
                          }
                          onChange={() => onFeeDisable(fee)}
                        />
                      )
                    }
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <ListItem
                    style={{ flex: 1, marginRight: 0 }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.pricing.fee.unit',
                    })}
                    value={fee.unit}
                  />
                  <ListEditItem
                    style={{
                      flex: 1,
                      marginRight: 0,
                    }}
                    label={intl.formatMessage({
                      id: `fleetadmin.pricing.fee.amount`,
                    })}
                    leftItem={renderText(
                      updatedFeeItems?.[fee.code]?.changedPrice
                        ? `${formatPrice(
                            updatedFeeItems?.[fee.code]?.changedPrice,
                            false
                          )}`
                        : fee.price,
                      true,
                      updatedFeeItems?.[fee.code]?.changedPrice
                        ? '#0077be'
                        : 'black'
                    )}
                    rightItem={
                      fee?.unitPriceEditable && (
                        <img
                          className="edit-button"
                          src={'/images/drawer/edit.svg'}
                          alt="edit"
                          onClick={() => setSelectedFee(fee)}
                        />
                      )
                    }
                  />
                </div>
                <ListItem
                  style={{ marginRight: 0 }}
                  label={intl.formatMessage({
                    id: 'fleetadmin.pricing.fee.note',
                  })}
                  value={
                    updatedFeeItems?.[fee.code]?.changePriceComment ||
                    fee.priceComment
                  }
                  valueStyles={{
                    color: updatedFeeItems?.[fee.code]?.changePriceComment
                      ? '#0077be'
                      : 'black',
                  }}
                />
              </Panel>
            ))}
          </Collapse>
          {Object.keys(updatedFeeItems)?.length > 0 && (
            <div className="button-container">
              <Button
                className="submit-button"
                onClick={() => setOpenedUpdateModal(true)}
              >
                {intl.formatMessage({
                  id: `Schedule changes`,
                })}
              </Button>
              <Button className="discard-button" onClick={handleOnDiscard}>
                {intl.formatMessage({
                  id: `Discard changes`,
                })}
              </Button>
            </div>
          )}
        </div>
      )}

      <style jsx>{styles}</style>
      <FeeItemModal
        selectedFee={selectedFee}
        handleCancel={() => setSelectedFee(null)}
        onUpdate={onFeeItemUpdate}
      />
      <PricingUpdateModal
        visibleModal={openedUpdateModal}
        handleCancel={() => setOpenedUpdateModal(false)}
        onSubmit={onUpdatePricing}
      />
    </div>
  );
};

export default PricingTable;
