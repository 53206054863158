import area from '@turf/area';
import { polygon } from '@turf/helpers';
import getCoordinatesForTurf from './getCoordinatesForTurf';

const getAreaFromAreaEntity = (entity: {
  geoJSONData: { features: { geometry: { coordinates: string | any[] } }[] };
}) => {
  if (entity?.geoJSONData.features[0].geometry.coordinates.length === 0) {
    return 0;
  }

  const entityPolygon = polygon(getCoordinatesForTurf(entity));
  return area(entityPolygon);
};

const sortByPolygonArea = (areas: any[] | undefined) =>
  areas?.slice(0)?.sort((left, right) => {
    const leftArea = getAreaFromAreaEntity(left);
    const rightArea = getAreaFromAreaEntity(right);

    return rightArea - leftArea; // Descending order
  });

export default sortByPolygonArea;
