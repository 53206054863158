import css from 'styled-jsx/css';

const style = css`
  :global(.true-or-false-cell) {
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
`;

export default style;
