import css from 'styled-jsx/css';
import { common, zIndex } from '../../theme/theme';

const style = css`
  .wrapper {
    margin-right: 16px;
    position: relative;
  }
  .add-column-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 4px;
    margin: 0;
    white-space: nowrap;
    color: #000;
    border: 2px solid #000;
    border-radius: 8px;
    cursor: pointer;
  }
  .column-icon {
    margin-right: 0px;
  }
  .button-text {
    margin: 0;
  }
  .panel-wrapper {
    z-index: ${zIndex.columnSelector};
    width: 210px;
    position: fixed;
    width: 210px;
    top: 20%;
    left: 23%;
    ${common.secondaryShadow};
  }
  .panel-close-button {
    z-index: ${zIndex.columnSelectorCloseButton};
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) {
    .add-column-button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      width: 105px;
      padding: 0 8px;
      margin: 0;
      white-space: nowrap;
      color: #000;
      border: 2px solid #000;
      border-radius: 8px;
      cursor: pointer;
    }
    .column-icon {
      margin-right: 4px;
    }
    .panel-wrapper {
      z-index: ${zIndex.columnSelector};
      position: absolute;
      width: 270px;
      right: 0;
      left: unset;
      top: unset;
      ${common.secondaryShadow};
    }
  }
`;

export default style;
