import css from 'styled-jsx/css';

const style = css`
  .claim-drawer-body {
    padding-bottom: 60px;
    background: #ffffff;
    min-height: 100%;
  }
  .claim-drawer-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 24px 0px;
    background: #f2f2f2;
  }
  .claim-status-wrapper {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-bottom: 30px;
    background: #f2f2f2;
  }
  .claim-note-wrapper {
    width: 100%;
    padding-bottom: 5px;
    background: #f2f2f2;
  }
  .close-status-wrapper {
    display: flex;
    align-items: center;
  }
  .claim-code {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: auto;
  }
  .edit-button {
    cursor: pointer;
  }
`;

export default style;
