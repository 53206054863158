import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const loadingStyles = css`
  .loading-wrapper {
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const noFleetsStyles = css`
  .message {
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-align: center;
  }
`;

export const assignedFleetsStyles = css`
  .add-fleet-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .add-fleet-button {
    display: inline-flex;
    margin: 0 16px 8px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .button-text {
    font-size: 14px;
    margin-bottom: 0;
  }
  .assigned-fleets {
    margin-top: 12px;
  }
  .fleet-remove-button {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
