import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './TerminateCarModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  selectSelectedCar,
  updateCarIfNeeded,
} from '../../../redux/store/userData/cars';
import { selectToken } from '../../../redux/store/userData/user';
import { useIntl } from 'react-intl';

const TerminateCarModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const visibleModal = useSelector(getIsVisibleModal('TerminateCarModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateCarIfNeeded(token, selectedCar?.id!, {
        status: 'TERMINATED',
      })
    );
    dispatch(closeModal('TerminateCarModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('TerminateCarModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('TerminateCarModal'));
        }}
      >
        <div className="terminate-car-modal">
          <div className="terminate-car-wrapper">
            <div className="terminate-car-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.car.terminate.title`,
                })}
              </p>
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default TerminateCarModal;
