export const colors = {
  red: '#ff0000',
  blue: '#0077be',
  yellow: '#d8f52b',
  white: '#ffffff',
  black: '#000000',
  dark: '#2b2e42',
  gray100: '#f9f9fa', // hover item
  gray200: '#e4e7eC', // selected item
  gray300: '#d6d6d6', // numOfCar == 0
  gray400: '#7f7f7f', // numOfCar != 0
  grey: '#D8D8D8',
  border: '#979797',
  background: 'rgba(242, 242, 242, 0.6)',
  hover: 'rgba(216, 245, 35, 0.4)',
  area: '#4d5060',
  area_selected: '#084ba3',
  area_outside: 'red',
  equipment_ready: '#7f7f7f',
  equipped: '#7f7f7f',
  removed: '#9F9F9F',
  terminated: '#9F9F9F',
  release_ready: '#2b2e42',
  released: '#2f2e2e',
  blocked: '#ee4e4e',
  rented: '#d8f52b',
  device: {
    online: '#0AB735',
    offline: '#ee4e4e',
  },
  cares: {
    closed: '#0AB735',
    inProgress: '#F5A623',
    open: '#9F9F9F',
    planned: '#9F9F9F',
  },
  trips: {
    cancelled: '#9F9F9F',
    finished: '#0AB735',
    inProgress: '#F5A623',
    reserved: '#1588E0',
  },
  bookings: {
    waiting: '#DDDDDD',
    offered: '#1588E0',
    confirmed: '#F5A623',
    started: '#0AB735',
    finished: '#0AB735',
    cancelled: '#9F9F9F',
  },
  claim: {
    open: '#ee4e4e', // red
    inProgress: '#f5a623', // orange
    closed: '#0AB735', // grey
    closedSettled: '#0ab735', // green
  },
  // colors from figam
  navMenuBgDeep: '#000000',
  navMenuBgMain: '#333333',
  navMenuBgLight: '#5B5B5B',
  navMenuTypoHeadline: '#A3A3A3',
  backgroundContentMain: '#F2F2F2',
  backgroundContentLight: '#FFFFFF',
  buttonActive: '#333333',
  buttonDisabled: '#DDDDDD',
  buttonTypoDisabled: '#C7C7C7',
  getawayGreen: '#B7F051',
  getawayNeon: '#D8F523',
  getawayDarkNeon: '#A2AD00',
  getawayGradient: 'linear-gradient(225deg, #D8F523 0%, #B7F051 100%)',
  statusSuccess: '#0AB735',
  statusProgress: '#F5A623',
  statusAlert: '#EE4E4E',
  statusNeutral: '#C7C7C7',
  interactiveLink: '#0077BE',
  highlight: {
    selected: 'rgba(216, 245, 35, 0.4)',
    hovered: 'rgba(216, 245, 35, 0.1)',
  },
};

export const zIndex = {
  notesBar: 0,
  notesCircle: 1,
  carMarker: 3,
  carMarkerSelected: 4,
  appliedFilterTag: 5,
  header: 999,
  addFilter: 1000,
  columnSelector: 1000,
  addFilterCloseButton: 1001,
  columnSelectorCloseButton: 1001,
  widget: 1000,
  carDrawerCloseButton: 1001 /* ant-drawer z-index is 1000 */,
  damageModal: 1001,
  allAroundModal: 1001,
  logoutModal: 1001,
  autoCompletePopup: '1301 !important',
  drawer: 1100,
  drawerDesktop: 1300,
  dropDown: 2000,
};

export const common = {
  deepShadow: 'box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.268985);',
  secondaryShadow: 'box-shadow: 0 4px 10px 0 #0000004c;',
  button: `width: 100%;
  font-size: 16px;
  margin: 8px 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  display: block;`,
  submitButton: `color: #fff;
  background: #313131;
  display: flex;
  justify-content: center;
  border: 0;`,
  cancelButton: `color: #313131;
  display: flex;
  justify-content: center;
  border: 2px solid #313131;`,
  discardButton: `color: red !important;
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: #ededed !important;
  border: 1px solid lightgrey !important;`,
  checkbox: `background: #2f2e2e;
  border-color: #f5f5f5;`,
  checkboxDisabled: `background: #cccdd0;`,
  panelMaxHeight: (offset = 0) =>
    `max-height: calc(100vh - ${232 + offset}px);`,
};
