import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse } from '../../../../@types';
import { UserTypes } from '../../../../@types/enums/redux/store';
import { UserState } from './initialState';

export const loginRequest = () => createAction(UserTypes.LOGIN_REQUEST);

export const loginSuccess = (payload: UserState) =>
  createAction(UserTypes.LOGIN_SUCCESS, payload);

export const loginFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(UserTypes.LOGIN_FAILURE, error);
