import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CategoryInfo.styles';
import { selectFleets } from '../../redux/store/appData/fleets';
import { selectSelectedCategory } from '../../redux/store/userData/categories/selectors';

const detailList = [
  {
    label: 'Brand',
    value: 'brandId',
  },
  {
    label: 'Acriss Code',
    value: 'acrissCode',
  },
  {
    label: 'Description',
    value: 'description',
  },
];

const CategoryInfo: React.FC = () => {
  const selectedCategory = useSelector(selectSelectedCategory);
  const fleets = useSelector(selectFleets);
  const brands = [
    // @ts-ignore
    ...new Set(fleets?.map((item) => item.brandId)),
  ].map((brand) => ({
    id: brand,
    // @ts-ignore
    name: fleets?.find((fleet) => fleet.brandId === brand).brandName,
  }));

  return (
    <>
      <div className="area-info">
        {detailList.map((detail, i) => (
          <div key={`area-detail-${i}`}>
            <p className="label">{detail.label}</p>
            <p className="value">
              {!selectedCategory[detail.value] && '-'}
              {detail.label === 'Brand'
                ? brands?.find((brand) => brand.id === selectedCategory.brandId)
                    ?.name
                : selectedCategory[detail.value]}
            </p>
          </div>
        ))}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default CategoryInfo;
