import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .filter-wrapper {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    background: ${colors.background};
    box-shadow: 0px 25px 50px -45px rgba(0, 0, 0, 0.75);
  }
  .filter-wrapper.fixed {
    position: fixed;
    top: 64px;
    width: calc(100vw);
  }
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 768px) {
    .filter-wrapper {
      box-sizing: border-box;
      display: flex;
      height: 52px;
      padding: 0 24px;
      justify-content: space-between;
      align-items: center;
      background: ${colors.background};
      box-shadow: 0px 25px 50px -45px rgba(0, 0, 0, 0.75);
    }
    .filter-wrapper.fixed {
      position: fixed;
      top: 64px;
      width: calc(100vw - 200px);
    }
    .left,
    .right {
      display: flex;
      align-items: center;
    }
  }
`;

export default style;
