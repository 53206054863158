import { Target } from '../@types';

const targetsForArea: Target[] = [
  { value: 'id', type: 'text', isAPI: false },
  { value: 'name', type: 'text', isAPI: false },
  { value: 'fleetId', type: 'selection', isAPI: false },
  { value: 'kmlFilename', type: 'text', isAPI: false },
  { value: 'lastUpdatedAt', type: 'date', isAPI: false },
  { value: 'lastUpdatedBy', type: 'text', isAPI: false },
  { value: 'createdAt', type: 'date', isAPI: false },
  { value: 'numOfVehicle', type: 'number', isAPI: false },
];

export default targetsForArea;
