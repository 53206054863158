import React from 'react';
import { useSelector } from 'react-redux';
import { Marker } from 'react-map-gl';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';
import styles from './AreaLabels.styles';
import {
  selectSelectedArea,
  selectDisplayAreas,
} from '../../redux/store/appData/areas';

const AREA_LABEL_ICON = '/images/map/area-label-icon.svg';

const AreaLabels: React.FC = () => {
  const areas = useSelector(selectDisplayAreas);
  const selectedArea = useSelector(selectSelectedArea);

  return (
    <>
      {areas?.length &&
        areas?.map((area) => {
          const areaWithGeoJson = fixGeoJSONCoordinates(area.geoJSONData);
          const bounds = getBoundsFromPoints(
            // @ts-ignore
            areaWithGeoJson.features[0].geometry.coordinates[0]
          );
          const position = {
            lat: (bounds.north + bounds.south) / 2,
            lng: (bounds.east + bounds.west) / 2,
          };
          const isSelected = selectedArea && selectedArea.id === area.id;

          return (
            <Marker
              key={`area-label-${area.id}`}
              latitude={position.lat}
              longitude={position.lng}
              offsetTop={-13}
            >
              <>
                <div
                  className={`label-container ${
                    isSelected ? 'selected' : 'unselected'
                  }`}
                >
                  <img src={AREA_LABEL_ICON} alt="area icon" />
                  <span className="label-text">{area.name}</span>
                </div>
                <style jsx>{styles}</style>
              </>
            </Marker>
          );
        })}
    </>
  );
};

export default AreaLabels;
