import css from 'styled-jsx/css';
import { colors } from '../../../theme/theme';

const style = css`
  .wrapper {
    margin-top: 0px;
    padding: 0 8px;
  }
  :global(.table-layout),
  :global(.ant-table-thead > tr > th) {
    background: #f2f2f2;
  }
  :global(.table-layout .ant-table-column-title) {
    font-weight: 500 !important;
  }
  :global(.ant-table-thead > tr > th) {
    white-space: break-spaces;
  }
  :global(.table-layout .ant-pagination.ant-table-pagination) {
    float: none;
    margin: 8px;
  }
  :global(.table-layout .selected-row .ant-table-cell-fix-left-last) {
    background: initial !important;
  }
  :global(.table-layout .selected-row) {
    background: ${colors.highlight.selected} !important;
  }
  :global(.ant-skeleton) {
    background-color: white;
  }
  :global(.ant-table-column-has-sorters) {
    padding: 8px !important;
  }
  .loaderContainer {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  :global(.grecaptcha-badge) {
    visibility: hidden;
  }
  @media only screen and (min-width: 768px) {
    .wrapper {
      margin-top: 8px;
      padding: 0 16px;
    }
    :global(.table-layout .ant-pagination.ant-table-pagination) {
      float: none;
      margin: 16px;
    }
    .loaderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .empty {
      margin-bottom: 10px;
    }
    :global(.ant-table-column-has-sorters) {
      padding: 16px !important;
    }
  }
`;

export default style;
