import React from 'react';
import styles from './getRenderAction.styles';

// eslint-disable-next-line react/display-name
export const getRenderAction = (onSelect: any) => (_: any, entity: any) => {
  const handleOnClick = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(entity);
  };

  return (
    <>
      <span className="action-cell" onClick={handleOnClick}>
        Show on map
      </span>
      <style jsx>{styles}</style>
    </>
  );
};
