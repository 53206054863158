// @ts-ignore
import { ReCaptcha } from 'react-recaptcha-v3';
import AuthLayout from '../components/shared/AuthLayout';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { FormattedMessage } from 'react-intl';

const Forgot = () => (
  <AuthLayout
    meta={
      <ReCaptcha
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        action="forgot"
      />
    }
    title={<FormattedMessage id="fleetadmin.forgot.title" />}
    body={<ForgotPasswordForm />}
  />
);

export default Forgot;
