const getDateTime = (dateTimeString: any) => {
  if (!dateTimeString) {
    // @ts-ignore
    return new Date(null).getTime();
  }
  return new Date(dateTimeString).getTime();
};

export const chronologicalSorterFor =
  (target: any) => (a: { [x: string]: any }, b: { [x: string]: any }) => {
    const dateTimeA = getDateTime(a[target]);
    const dateTimeB = getDateTime(b[target]);
    return dateTimeA - dateTimeB;
  };

export default chronologicalSorterFor;
