import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { ViewMode } from '../../hooks/useViewMode';
import carColumns from './carColumns';
import profileColumns from './profileColumns';
import areaColumns from './areaColumns';
import careColumns from './careColumns';
import claimColumns from './claimColumns';
import styles from './ColumnPanel.styles';
import tripColumns from '../../components/ColumnSelector/tripColumns';
import bookingColumns from '../../components/ColumnSelector/bookingColumns';
import {
  selectDisplayColumnsForArea,
  selectDisplayColumnsForBooking,
  selectDisplayColumnsForCar,
  selectDisplayColumnsForCare,
  selectDisplayColumnsForCategory,
  selectDisplayColumnsForCircle,
  selectDisplayColumnsForClaim,
  selectDisplayColumnsForPricing,
  selectDisplayColumnsForProfile,
  selectDisplayColumnsForTimeTable,
  selectDisplayColumnsForTrip,
  selectViewMode,
  setDisplayColumns,
} from '../../redux/store/ui/common';
import categoryColumns from '../../components/ColumnSelector/categoryColumns';
import circleColumns from '../../components/ColumnSelector/circleColumns';
import pricingColumns from '../../components/ColumnSelector/pricingColumns';
import {
  selectDisplayColumnsForAdmins,
  selectDisplayColumnsForReceipts,
  selectDisplayColumnsForUsers,
  selectViewType,
} from '../../redux/store/ui/common/selectors';
import usersColumns from './usersColumns';
import { ViewType } from '../../hooks/useViewType';
import timeTableColumns from './timeTableColumns';
import { useIntl } from 'react-intl';
import adminsColumns from './adminsColumns';
import { valueToTextKey } from './columnsValueToTextKey';
import receiptsColumns from './receiptsColumns';

const ColumnPanel: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const viewMode = useSelector(selectViewMode);
  const viewType = useSelector(selectViewType);
  const vehicleValues = useSelector(selectDisplayColumnsForCar);
  const timeTableValues = useSelector(selectDisplayColumnsForTimeTable);
  const areaValues = useSelector(selectDisplayColumnsForArea);
  const careValues = useSelector(selectDisplayColumnsForCare);
  const tripValues = useSelector(selectDisplayColumnsForTrip);
  const bookingValues = useSelector(selectDisplayColumnsForBooking);
  const claimValues = useSelector(selectDisplayColumnsForClaim);
  const categoryValues = useSelector(selectDisplayColumnsForCategory);
  const pricingsValues = useSelector(selectDisplayColumnsForPricing);
  const circleValues = useSelector(selectDisplayColumnsForCircle);
  const profileValues = useSelector(selectDisplayColumnsForProfile);
  const receiptsValues = useSelector(selectDisplayColumnsForReceipts);
  const usersValues = useSelector(selectDisplayColumnsForUsers);
  const adminsValues = useSelector(selectDisplayColumnsForAdmins);

  const options: any =
    viewType === ViewType.TIME_TABLE
      ? timeTableColumns
      : viewMode === ViewMode.VEHICLE
      ? carColumns
      : viewMode === ViewMode.PROFILE
      ? profileColumns
      : viewMode === ViewMode.AREA
      ? areaColumns
      : viewMode === ViewMode.CARE
      ? careColumns
      : viewMode === ViewMode.SHARE
      ? tripColumns
      : viewMode === ViewMode.BOOKING
      ? bookingColumns
      : viewMode === ViewMode.CLAIM
      ? claimColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({
            id: `fleetadmin.claim.table.${valueToTextKey(column.value)}`,
          }),
        }))
      : viewMode === ViewMode.CATEGORIES
      ? categoryColumns
      : viewMode === ViewMode.PRICING
      ? pricingColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({
            id: `fleetadmin.pricing.${column.value.toLowerCase()}`,
          }),
        }))
      : viewMode === ViewMode.RECEIPTS
      ? receiptsColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({
            id: `fleetadmin.receipts.table.${valueToTextKey(
              column.value
            ).toLowerCase()}`,
          }),
        }))
      : viewMode === ViewMode.CIRCLE
      ? circleColumns
      : viewMode === ViewMode.USERS
      ? usersColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({
            id: `fleetadmin.user.table.${column.value.toLowerCase()}`,
          }),
        }))
      : viewMode === ViewMode.ADMINS
      ? adminsColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({
            id: `fleetadmin.admins.table.${column.value.toLowerCase()}`,
          }),
        }))
      : null;

  const value: any =
    viewType === ViewType.TIME_TABLE
      ? timeTableValues
      : viewMode === ViewMode.VEHICLE
      ? vehicleValues
      : viewMode === ViewMode.PROFILE
      ? profileValues
      : viewMode === ViewMode.AREA
      ? areaValues
      : viewMode === ViewMode.CARE
      ? careValues
      : viewMode === ViewMode.SHARE
      ? tripValues
      : viewMode === ViewMode.BOOKING
      ? bookingValues
      : viewMode === ViewMode.CLAIM
      ? claimValues
      : viewMode === ViewMode.CATEGORIES
      ? categoryValues
      : viewMode === ViewMode.PRICING
      ? pricingsValues
      : viewMode === ViewMode.RECEIPTS
      ? receiptsValues
      : viewMode === ViewMode.CIRCLE
      ? circleValues
      : viewMode === ViewMode.USERS
      ? usersValues
      : viewMode === ViewMode.ADMINS
      ? adminsValues
      : null;

  const target =
    viewType === ViewType.TIME_TABLE
      ? 'timeTable'
      : viewMode === ViewMode.VEHICLE
      ? 'car'
      : viewMode === ViewMode.PROFILE
      ? 'profile'
      : viewMode === ViewMode.AREA
      ? 'area'
      : viewMode === ViewMode.CARE
      ? 'care'
      : viewMode === ViewMode.SHARE
      ? 'trip'
      : viewMode === ViewMode.BOOKING
      ? 'booking'
      : viewMode === ViewMode.CLAIM
      ? 'claim'
      : viewMode === ViewMode.CATEGORIES
      ? 'category'
      : viewMode === ViewMode.PRICING
      ? 'pricing'
      : viewMode === ViewMode.RECEIPTS
      ? 'receipts'
      : viewMode === ViewMode.CIRCLE
      ? 'circle'
      : viewMode === ViewMode.USERS
      ? 'users'
      : viewMode === ViewMode.ADMINS
      ? 'admins'
      : null;

  const onChange = (values: any) => {
    // @ts-ignore
    dispatch(setDisplayColumns({ [target]: values }));
  };

  return (
    <>
      <div className="column-panel-wrapper">
        <Checkbox.Group
          options={options}
          defaultValue={value}
          onChange={onChange}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ColumnPanel;
