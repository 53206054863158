import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './CareCancelModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import { selectSelectedCare } from '../../../redux/store/userData/cares';
import { useIntl } from 'react-intl';
import { finishTrip } from '../../../redux/store/userData/trips';

const CareCancelModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedCare = useSelector(selectSelectedCare);
  const visibleModal = useSelector(getIsVisibleModal('CareCancelModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(finishTrip(selectedCare?.id!));
    dispatch(closeModal('CareCancelModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('CareCancelModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('CareCancelModal'));
        }}
      >
        <div className="booking-cancel-modal">
          <div className="booking-cancel-wrapper">
            <div className="booking-cancel-modal-inputs">
              <p className="label">
                Are you sure you want to cancel this trip ?
              </p>
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default CareCancelModal;
