import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AdminPermissions.styles';
import {
  selectSelectedAdmin,
  updateAdmin,
} from '../../../redux/store/userData/admins';
import SelectorLayout from '../../shared/SelectorLayout';
import {
  selectBrands,
  selectFleets,
} from '../../../redux/store/appData/fleets/selectors';
import ListEditItem from '../../shared/ListEditItem';
import { selectAuth } from '../../../redux/store/userData/user/selectors';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Switch } from 'antd';
import { selectCircles } from '../../../redux/store/userData/circles';
import { uniqueArrayOfObjects } from '../../../utils/brandHelpers';

const MERCHANT_PERMISSIONS = ['merchantManager'];

const FLEET_PERMISSIONS = [
  'fleetManager',
  'tripsManager',
  'claimsManager',
  'mechanic',
];

const USER_PERMISSIONS = ['userManager', 'userGroupsManager'];

const PAYMENT_PERMISSIONS = ['paymentManager'];

export const extractIds = (data: any) =>
  data?.length > 0 ? data?.map((data: { id: string }) => data.id) : null;

const AdminPermissions: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(selectAuth);
  const selectedAdmin = useSelector(selectSelectedAdmin);
  const selectedBrands = useSelector(selectBrands);
  const availableBrands = uniqueArrayOfObjects([
    ...user.brands,
    ...(selectedBrands?.length ? selectedBrands : []),
  ]);
  const availableFleets = useSelector(selectFleets);
  const availableCircles = useSelector(selectCircles);

  const [brands, setBrands] = useState([]);
  const [fleets, setFleets] = useState([]) as any;
  const [circles, setCircles] = useState([]) as any;

  const [merchantManager, setMerchantManager] = useState(
    Boolean(selectedAdmin?.permissions?.merchantManager)
  );
  const [fleetManager, setFleetManager] = useState(
    Boolean(selectedAdmin?.permissions?.fleetManager)
  );
  const [tripsManager, setTripsManager] = useState(
    Boolean(selectedAdmin?.permissions?.tripsManager)
  );
  const [claimsManager, setClaimsManager] = useState(
    Boolean(selectedAdmin?.permissions?.claimsManager)
  );
  const [mechanic, setMechanic] = useState(Boolean(selectedAdmin?.mechanic));
  const [userManager, setUserManager] = useState(
    Boolean(selectedAdmin?.permissions?.userManager)
  );
  const [userGroupsManager, setUserGroupsManager] = useState(
    Boolean(selectedAdmin?.permissions?.userGroupsManager)
  );
  const [paymentManager, setPaymentManager] = useState(
    Boolean(selectedAdmin?.permissions?.paymentManager)
  );
  const [addNewBrandsAutomatically, setAddNewBrandsAutomatically] = useState(
    Boolean(selectedAdmin?.addNewBrandsAutomatically)
  );
  const [addNewFleetsAutomatically, setAddNewFleetsAutomatically] = useState(
    Boolean(selectedAdmin?.addNewFleetsAutomatically)
  );
  const [addNewCirclesAutomatically, setAddNewCirclesAutomatically] = useState(
    Boolean(selectedAdmin?.addNewCirclesAutomatically)
  );

  const disabled = !(
    user?.permissions?.merchantManager && !selectedAdmin?.blocked
  );

  const onSelectBrands = (brands: any) => {
    setBrands(brands);

    // filter fleets automatically based on selected brands
    setFleets(
      availableFleets
        ?.filter((fleet) => fleets.includes(fleet.id))
        // @ts-ignore
        ?.filter((fleet) => brands?.includes(fleet?.brandId))
        ?.map((fleet: any) => fleet.id)
    );

    // filter circles automatically based on selected brands
    setCircles(
      availableCircles
        ?.filter((circle) => circles.includes(circle.id))
        // @ts-ignore
        ?.filter((circle) => brands?.includes(circle?.brandId))
        ?.map((circle: any) => circle.id)
    );
  };

  const onSelectFleets = (fleets: any) => {
    setFleets(fleets);
  };

  const onSelectCircles = (circles: any) => {
    setCircles(circles);
  };

  const onSwitchToggle = (permission: string) => {
    switch (permission) {
      case 'merchantManager':
        setMerchantManager(Boolean(!merchantManager));
        break;
      case 'fleetManager':
        setFleetManager(Boolean(!fleetManager));
        break;
      case 'tripsManager':
        setTripsManager(Boolean(!tripsManager));
        break;
      case 'claimsManager':
        setClaimsManager(Boolean(!claimsManager));
        break;
      case 'mechanic':
        setMechanic(Boolean(!mechanic));
        break;
      case 'userManager':
        setUserManager(Boolean(!userManager));
        break;
      case 'userGroupsManager':
        setUserGroupsManager(Boolean(!userGroupsManager));
        break;
      case 'paymentManager':
        setPaymentManager(Boolean(!paymentManager));
        break;
      default:
        return;
    }
  };

  const getPermissionState = (permission: string) => {
    switch (permission) {
      case 'merchantManager':
        return merchantManager;
      case 'fleetManager':
        return fleetManager;
      case 'tripsManager':
        return tripsManager;
      case 'claimsManager':
        return claimsManager;
      case 'mechanic':
        return mechanic;
      case 'userManager':
        return userManager;
      case 'userGroupsManager':
        return userGroupsManager;
      case 'paymentManager':
        return paymentManager;
      default:
        return;
    }
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (selectedAdmin) {
      dispatch(
        updateAdmin({
          brands,
          fleets,
          circles: circles,
          addNewBrandsAutomatically,
          addNewFleetsAutomatically,
          addNewCirclesAutomatically,
          permissions: {
            merchantManager,
            userManager,
            userGroupsManager,
            paymentManager,
            fleetManager,
            tripsManager,
            claimsManager,
            mechanic,
          },
        })
      );
    }
  };

  useEffect(() => {
    setMerchantManager(Boolean(selectedAdmin?.permissions?.merchantManager));
    setFleetManager(Boolean(selectedAdmin?.permissions?.fleetManager));
    setTripsManager(Boolean(selectedAdmin?.permissions?.tripsManager));
    setClaimsManager(Boolean(selectedAdmin?.permissions?.claimsManager));
    setMechanic(Boolean(selectedAdmin?.permissions?.mechanic));
    setUserManager(Boolean(selectedAdmin?.permissions?.userManager));
    setUserGroupsManager(
      Boolean(selectedAdmin?.permissions?.userGroupsManager)
    );
    setPaymentManager(Boolean(selectedAdmin?.permissions?.paymentManager));
    setBrands(extractIds(selectedAdmin?.brands) || []);
    setFleets(extractIds(selectedAdmin?.fleets) || []);
    setCircles(extractIds(selectedAdmin?.circles) || []);
    setAddNewBrandsAutomatically(
      Boolean(selectedAdmin?.addNewBrandsAutomatically)
    );
    setAddNewFleetsAutomatically(
      Boolean(selectedAdmin?.addNewFleetsAutomatically)
    );
    setAddNewCirclesAutomatically(
      Boolean(selectedAdmin?.addNewCirclesAutomatically)
    );
  }, [selectedAdmin]);

  return (
    <>
      <div className="user-info">
        <div className="admin-section-title" style={{ marginTop: 24 }}>
          {intl.formatMessage({
            id: `fleetadmin.admins.permission.merchant.title`,
          })}
        </div>
        <div className="admin-selector">
          <SelectorLayout
            keyPrefix="brand-select"
            mode="multiple"
            value={brands}
            placeholder="Brands"
            options={
              availableBrands?.map((brand) => ({
                value: brand.id,
                label: brand.name,
              })) || []
            }
            onSelect={onSelectBrands}
            disabled={disabled}
          />
          <div className="sub-actions">
            <Checkbox
              checked={addNewBrandsAutomatically}
              onChange={(e) => setAddNewBrandsAutomatically(e?.target?.checked)}
              style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
              disabled={disabled}
            >
              {intl.formatMessage({
                id: `fleetadmin.admins.permission.automatically`,
              })}
            </Checkbox>
            {disabled && (
              <div className="sub-actions">
                <div
                  className="action"
                  onClick={() =>
                    onSelectBrands(availableBrands?.map((brand) => brand.id))
                  }
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.select.all`,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        {MERCHANT_PERMISSIONS.map((permission) => (
          <ListEditItem
            key={permission}
            leftItem={
              <div className="permission-label">
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.${permission.toLowerCase()}`,
                })}
              </div>
            }
            rightItem={
              permission != undefined && (
                <Switch
                  disabled={
                    disabled || !user?.merchant?.permissions[permission]
                  }
                  checked={getPermissionState(permission)}
                  onChange={() => onSwitchToggle(permission)}
                />
              )
            }
          />
        ))}

        <div className="admin-section-title">
          {intl.formatMessage({
            id: `fleetadmin.admins.permission.fleet.title`,
          })}
        </div>
        <div className="admin-selector">
          <SelectorLayout
            keyPrefix="fleets-select"
            mode="multiple"
            value={fleets}
            placeholder="Fleets"
            options={[
              // options: admin joined circles plus circles joined by selected admin
              ...(availableFleets
                // @ts-ignore
                ?.filter((fleet) => brands?.includes(fleet?.brandId))
                ?.map((fleet) => ({
                  value: fleet.id,
                  label: fleet.name,
                })) || []),
              ...selectedAdmin.fleets
                ?.filter(
                  (fleet: any) =>
                    !availableFleets?.find(
                      (availableFleet) => availableFleet.id === fleet.id
                    )
                )
                .map((fleet: any) => ({
                  value: fleet.id,
                  label: fleet?.name,
                })),
            ]}
            onSelect={onSelectFleets}
            disabled={disabled}
          />
          <div className="sub-actions">
            <Checkbox
              checked={addNewFleetsAutomatically}
              onChange={(e) => setAddNewFleetsAutomatically(e?.target?.checked)}
              style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
              disabled={disabled}
            >
              {intl.formatMessage({
                id: `fleetadmin.admins.permission.automatically`,
              })}
            </Checkbox>
            {disabled && (
              <div className="sub-actions">
                <div
                  className="action"
                  onClick={() =>
                    onSelectFleets(
                      availableFleets
                        // @ts-ignore
                        ?.filter((fleet) => brands?.includes(fleet?.brandId))
                        ?.map((brand) => brand.id)
                    )
                  }
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.select.all`,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        {FLEET_PERMISSIONS.map((permission) => (
          <ListEditItem
            key={permission}
            leftItem={
              <div className="permission-label">
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.${permission.toLowerCase()}`,
                })}
              </div>
            }
            rightItem={
              permission != undefined && (
                <Switch
                  disabled={
                    disabled || !user?.merchant?.permissions[permission]
                  }
                  checked={getPermissionState(permission)}
                  onChange={() => onSwitchToggle(permission)}
                />
              )
            }
          />
        ))}

        <div className="admin-section-title">
          {intl.formatMessage({
            id: `fleetadmin.admins.permission.user.title`,
          })}
        </div>
        <div className="admin-selector">
          <SelectorLayout
            keyPrefix="circle-select"
            mode="multiple"
            value={circles}
            placeholder="Circles"
            options={[
              // options: admin joined circles plus circles joined by selected admin
              ...(availableCircles
                // @ts-ignore
                ?.filter((circle) => brands?.includes(circle?.brandId))
                ?.map((circle) => ({
                  value: circle.id,
                  label: circle?.name,
                })) || []),
              ...selectedAdmin.circles
                ?.filter(
                  (circle: any) =>
                    !availableCircles?.find(
                      (availableCircle) => availableCircle.id === circle.id
                    )
                )
                .map((circle: any) => ({
                  value: circle.id,
                  label: circle?.name,
                })),
            ]}
            onSelect={onSelectCircles}
            disabled={disabled}
          />
          <div className="sub-actions">
            <Checkbox
              checked={addNewCirclesAutomatically}
              onChange={(e) =>
                setAddNewCirclesAutomatically(e?.target?.checked)
              }
              style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
              disabled={disabled}
            >
              {intl.formatMessage({
                id: `fleetadmin.admins.permission.automatically`,
              })}
            </Checkbox>
            {disabled && (
              <div className="sub-actions">
                <div
                  className="action"
                  onClick={() =>
                    onSelectCircles(
                      availableCircles
                        // @ts-ignore
                        ?.filter((circle) => brands?.includes(circle?.brandId))
                        ?.map((brand) => brand.id)
                    )
                  }
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.select.all`,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        {USER_PERMISSIONS.map((permission) => (
          <ListEditItem
            key={permission}
            leftItem={
              <div className="permission-label">
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.${permission.toLowerCase()}`,
                })}
              </div>
            }
            rightItem={
              permission != undefined && (
                <Switch
                  disabled={
                    disabled || !user?.merchant?.permissions[permission]
                  }
                  checked={getPermissionState(permission)}
                  onChange={() => onSwitchToggle(permission)}
                />
              )
            }
          />
        ))}
        <div className="admin-section-title" style={{ marginBottom: 24 }}>
          {intl.formatMessage({
            id: `fleetadmin.admins.permission.payment.title`,
          })}
        </div>
        {PAYMENT_PERMISSIONS.map((permission) => (
          <ListEditItem
            key={permission}
            leftItem={
              <div className="permission-label">
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.${permission.toLowerCase()}`,
                })}
              </div>
            }
            rightItem={
              permission != undefined && (
                <Switch
                  disabled={
                    disabled || !user?.merchant?.permissions[permission]
                  }
                  checked={getPermissionState(permission)}
                  onChange={() => onSwitchToggle(permission)}
                />
              )
            }
          />
        ))}
        {!disabled && (
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.permissions.update`,
              })}
            </Button>
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AdminPermissions;
