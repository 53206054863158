import { TargetType } from '../../../../components/Filter/@types';

export type APIFilterParams = {
  carPlate?: string;
};

export interface FiltersState {
  isPanelOpen: boolean;
  target: string | null;
  targetType: TargetType | null;
  apiParams: APIFilterParams | null;
  VEHICLE: any | null;
  AREA: any;
  CLAIM: any;
  CARE: any;
  SHARE: any;
  BOOKING: any;
  CATEGORIES: any;
  PRICING: any;
  PROFILE: any;
  USERS: any;
  ADMINS: any;
  RECEIPTS: any;
  CIRCLE: any;
}

export const initialState = {
  isPanelOpen: false,
  target: null,
  targetType: null,
  apiParams: null,
  VEHICLE: {},
  AREA: {},
  CLAIM: {},
  CARE: {},
  SHARE: {},
  BOOKING: {},
  CATEGORIES: {},
  PRICING: {},
  PROFILE: {},
  USERS: {},
  ADMINS: {},
  RECEIPTS: {},
  CIRCLE: {},
};
