import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import CareStatus from '../../components/shared/CareStatus';
import CareInfo from './CareInfo';
import styles from './CareDrawerBody.styles';
import VINAndPlate from '../../components/shared/VINAndPlate';
import TripTimeline from '../../components/TripDrawer/TripTimeline';
import { selectSelectedCare } from '../../redux/store/userData/cares';

const CareDrawerBody: React.FC = () => {
  const selectedCare = useSelector(selectSelectedCare);
  const tabs = [
    {
      title: 'Timeline',
      content: (
        <>
          <VINAndPlate
            chassisNumber={selectedCare?.chassisNumber}
            plate={selectedCare?.plate}
            carId={selectedCare?.carId}
          />
          <TripTimeline />
        </>
      ),
    },
    { title: 'Info', content: <CareInfo /> },
  ];

  return (
    <div className="care-drawer-body">
      <div className="care-drawer-top">
        <p className="care-code">{selectedCare?.code}</p>
        <CareStatus
          status={selectedCare?.status}
          style={{ marginLeft: '12px' }}
        />
      </div>
      <TabLayout tabs={tabs} keyName="care-drawer" />
      <style jsx>{styles}</style>
    </div>
  );
};

export default CareDrawerBody;
