import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './AdminDeleteModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import { deleteAdmin } from '../../../redux/store/userData/admins';
import { useIntl } from 'react-intl';

const AdminDeleteModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const visibleModal = useSelector(getIsVisibleModal('AdminDeleteModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(deleteAdmin());
    dispatch(closeModal('AdminDeleteModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('AdminDeleteModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('AdminDeleteModal'));
        }}
      >
        <div className="admin-modal">
          <div className="admin-wrapper">
            <div className="admin-modal-inputs">
              <p className="admin-delete-title ">
                {intl.formatMessage({
                  id: `fleetadmin.admins.info.delete.title`,
                })}
              </p>
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default AdminDeleteModal;
