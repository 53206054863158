import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import cookie from 'js-cookie';
import { selectCars } from '../../redux/store/userData/cars';
import { selectFleets } from '../../redux/store/appData/fleets';
import { fetchCategoriesIfNeeded } from '../../redux/store/userData/categories/thunk';
import {
  selectCategories,
  selectDisplayCategories,
  selectSelectedCategory,
} from '../../redux/store/userData/categories';
import {
  selectDisplayColumnsForCategory,
  selectCategoryWithUIEffect,
} from '../../redux/store/ui/common';
import { VehicleCategoryData } from '../../@types';
import { getFilteredColumns } from '../../utils';

const CategoriesTable = () => {
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const displayCategories = useSelector(selectDisplayCategories);
  const fleets = useSelector(selectFleets);
  const categoriesEntities = useSelector(selectCategories);
  const selectedCategory: any = useSelector(selectSelectedCategory);
  const displayColumns = useSelector(selectDisplayColumnsForCategory);
  const brands = [
    // @ts-ignore
    ...new Set(fleets?.map((item) => item.brandId)),
  ].map((brand) => ({
    id: brand,
    // @ts-ignore
    name: fleets?.find((fleet) => fleet.brandId === brand).brandName,
  }));
  const selectedId = selectedCategory ? selectedCategory.id : null;
  const columns = getColumns({ brands, cars, dispatch });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: VehicleCategoryData) => {
    dispatch(selectCategoryWithUIEffect(record));
  };

  const loadCategories = useCallback(async () => {
    dispatch(fetchCategoriesIfNeeded());
  }, []);

  useEffect(() => {
    if (!categoriesEntities) {
      loadCategories();
    }
  }, []);

  return (
    <TableLayout
      rowKey="category"
      columns={filteredColumns}
      dataSource={displayCategories}
      selectedId={selectedId}
      totalNumber={displayCategories?.length}
      onClickRow={onClickRow}
    />
  );
};

export default CategoriesTable;
