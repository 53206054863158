import css from 'styled-jsx/css';

const style = css`
  .user-info {
    margin: 0;
    padding-bottom: 100px;
  }
  .edit-button {
    cursor: pointer;
  }
  .add-button {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .admin-info-label {
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .admin-delete-button {
    margin: 70px 24px 60px;
    color: red;
    cursor: pointer;
  }
  .subtitle-permission {
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding-top: 8px;
    padding-bottom: 4px;
    margin-bottom: 0;
  }
  .permission-section {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0 16px;
  }
  .permission-wrapper {
    display: inline-block;
    padding: 0;
    margin: 6px 0 6px 6px;
  }
  .permission-label {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }
  .permission-icon {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
  .admin-section-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .admin-section-account {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px;
    margin-top: 50px;
    margin-bottom: 24px;
  }
  .delete-admin {
    color: red;
    cursor: pointer;
    font-size: 14px;
  }
`;

export default style;
