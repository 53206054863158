import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './CarEditorAreaSelector.styles';
import { selectAreas } from '../../redux/store/appData/areas';
import { zIndex } from '../../theme/theme';

const AREA_ICON = '/images/car-editor/icon-pin-active.svg';
const { Option } = Select;

type AreaSelectorProps = {
  style?: any;
  className?: string;
  defaultValue?: string;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  onSelect?: (value: string) => void;
};

const AreaSelector: React.FC<AreaSelectorProps> = ({
  style,
  className,
  defaultValue,
  value,
  disabled,
  loading,
  placeholder,
  onSelect,
}) => {
  const areas = useSelector(selectAreas) as any;

  return (
    <div style={style} className={className}>
      <Select
        defaultValue={defaultValue}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder}
        onChange={onSelect}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
      >
        <Option value={''}>
          <span className="none">
            <FormattedMessage id="fleetadmin.car.areaNotAssigned" />
          </span>
        </Option>
        {[...areas]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((area) => (
            <Option key={`area-option-${area.id}`} value={area.id}>
              <img
                className={`logo ${disabled ? 'disabled' : ''}`}
                src={AREA_ICON}
                alt="area icon"
              />
              {area.name}
            </Option>
          ))}
      </Select>
      <style jsx>{styles}</style>
    </div>
  );
};

export default AreaSelector;
