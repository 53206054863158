import { request, requestAPI } from '../../../../utils/request';
import {
  carsRequest,
  carsSuccess,
  carsFailure,
  setDisplayCars,
  unlockCarSuccess,
  unlockCarFailure,
  resetModemFailure,
  resetModemSuccess,
  resetModemRequest,
  resetBluetoothFailure,
  resetBluetoothSuccess,
  resetBluetoothRequest,
  resetDataConnectionFailure,
  resetDataConnectionSuccess,
  resetDataConnectionRequest,
  carRequest,
  carSuccess,
  carFailure,
  archivedCarsSuccess,
  archivedCarsRequest,
  setCarActiveBookings,
  activeBookingsRequest,
  activeBookingsSuccess,
  activeBookingsFailure,
  bookingByIdRequest,
  bookingByIdFailure,
  timelineStatusChangesRequest,
  timelineStatusChangesSuccess,
  timelineStatusChangesFailure,
  bookingStatusChangeRequest,
  bookingStatusChangeFailure,
} from './actions';
import {
  unlockCarRequest,
  resetBoardRequest,
  resetBoardSuccess,
  resetBoardFailure,
} from './actions';
import seasonToCars from '../../../../redux/helpers/seasonToCars';
import message from '../../../../utils/message';
import {
  updateCarStatusFailure,
  updateCarStatusRequest,
  updateCarStatusSuccess,
} from './actions';
import { seasonToCar } from '../../../helpers/seasonToCars';
import getCirclesNameById from '../../../../utils/getCircleNameById';
import { notification } from 'antd';
import { addDays } from 'date-fns';
import { Dispatch, GetState } from '../../../../@types';
import { CarsState } from './initialState';
import { selectBooking, selectTrip } from '../trips/actions';
import { fetchVehicleEventsForTrip } from '../vehicleEvents';
import { batch } from 'react-redux';

const fetchCars =
  (fetchStatus: string, archived: boolean) => (dispatch: Dispatch) => {
    dispatch(archived ? archivedCarsRequest() : carsRequest());
    requestAPI('/fleets/cars', {
      fetchStatus: fetchStatus.toString(),
      archived: archived.toString(),
    })
      .then((cars) => {
        const seasonedCars = seasonToCars(cars);
        if (archived) {
          dispatch(archivedCarsSuccess(seasonedCars));
        } else {
          dispatch(carsSuccess(seasonedCars));
        }
        dispatch(setDisplayCars(seasonedCars));
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(carsFailure(err));
      });
  };

const shouldFetchCars = (state: CarsState, archived: boolean) =>
  archived
    ? !state.archivedCars && !state.loadingArchivedCars
    : !state.entities && !state.loading;

export const fetchCarsIfNeeded = (archived = false) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCars(getState().userData.cars, archived)) {
      const shouldFetchCarStatus = getState().ui.common.displayColumns.car.some(
        (column: string) => column.startsWith('lastStatus')
      );
      return dispatch(fetchCars(shouldFetchCarStatus, archived));
    } else {
      dispatch(
        setDisplayCars(
          getState()?.userData?.cars[archived ? 'archivedCars' : 'entities']
        )
      );
      return Promise.resolve();
    }
  };
};

const fetchCarById =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(carRequest());
    requestAPI(`/fleets/cars/${carId}`)
      .then((car) => {
        const seasonedCar = seasonToCar(car);
        dispatch(carSuccess(seasonedCar));
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(carFailure(err));
      });
  };

const shouldFetchCarById = (cars: CarsState) => !cars.loadingCar;

export const fetchCarByIdIfNeeded = (
  cookieToken: string,
  carId: string | null
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCarById(getState().userData.cars)) {
      return dispatch(
        fetchCarById(getState()?.userData.user?.token || cookieToken, carId)
      );
    } else {
      return Promise.resolve();
    }
  };
};

const resetBoard =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(resetBoardRequest());
    requestAPI(`/fleets/cars/${carId}/resetBoard`, {}, { method: 'PUT' })
      .then(() => {
        message.success('Board was reset successfully');
        dispatch(resetBoardSuccess());
      })
      .catch((err) => {
        dispatch(resetBoardFailure(err));
        message.error(err.message);
      });
  };

const shouldResetBoard = (cars: CarsState) => !cars.resettingBoard;

export const resetBoardIfNeeded = (token: string, carId: string | null) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldResetBoard(getState().userData.cars)) {
      return dispatch(resetBoard(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

const resetModem =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(resetModemRequest());
    requestAPI(
      `/fleets/cars/${carId}/resetModem`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        message.success('Modem was reset successfully');
        dispatch(resetModemSuccess());
      })
      .catch((err) => {
        dispatch(resetModemFailure(err));
        message.error(err.message);
      });
  };

const shouldResetModem = (cars: CarsState) => !cars.resettingModem;

export const resetModemIfNeeded = (token: string, carId: string | null) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldResetModem(getState().userData.cars)) {
      return dispatch(resetModem(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

const resetBluetooth =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(resetBluetoothRequest());
    requestAPI(
      `/fleets/cars/${carId}/resetBluetooth`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        message.success('Bluetooth was reset successfully');
        dispatch(resetBluetoothSuccess());
      })
      .catch((err) => {
        dispatch(resetBluetoothFailure(err));
        message.error(err.message);
      });
  };

const shouldResetBluetooth = (cars: CarsState) => !cars.resettingBluetooth;

export const resetBluetoothIfNeeded = (token: string, carId: string | null) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldResetBluetooth(getState().userData.cars)) {
      return dispatch(resetBluetooth(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

const resetDataConnection =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(resetDataConnectionRequest());
    requestAPI(
      `/fleets/cars/${carId}/resetDataConnection`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        message.success('DataConnection was reset successfully');
        dispatch(resetDataConnectionSuccess());
      })
      .catch((err) => {
        dispatch(resetDataConnectionFailure(err));
        message.error(err.message);
      });
  };

const shouldResetDataConnection = (cars: CarsState) =>
  !cars.resettingDataConnection;

export const resetDataConnectionIfNeeded = (
  token: string,
  carId: string | null
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldResetDataConnection(getState().userData.cars)) {
      return dispatch(resetDataConnection(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

const unlockCar =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(unlockCarRequest());
    requestAPI(
      `/fleets/cars/${carId}/unlock`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        message.success('Unlocked');
        dispatch(unlockCarSuccess(''));
      })
      .catch((err) => {
        dispatch(unlockCarFailure(err));
        message.error(err.message);
      });
  };

const lockCar =
  (token: string, carId: string | null) => (dispatch: Dispatch) => {
    dispatch(unlockCarRequest());
    requestAPI(
      `/fleets/cars/${carId}/lock`,
      {},
      {
        method: 'PUT',
      }
    )
      .then(() => {
        message.success('Locked');
        dispatch(unlockCarSuccess(''));
      })
      .catch((err) => {
        dispatch(unlockCarFailure(err));
        message.error(err.message);
      });
  };

const shouldLockCar = (state: CarsState) => !state.unlocking;

export const unlockCarIfNeeded = (token: string, carId: string | null) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldLockCar(getState().userData.cars)) {
      return dispatch(unlockCar(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

export const lockCarIfNeeded = (token: string, carId: string | null) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldLockCar(getState().userData.cars)) {
      return dispatch(lockCar(token, carId));
    } else {
      return Promise.resolve();
    }
  };
};

const updateCar =
  (token: string, carId: string | null, carData: any, successCallback: any) =>
  (dispatch: Dispatch, getState: GetState) => {
    const circles = getState().userData.circles.entities;
    dispatch(updateCarStatusRequest());
    requestAPI(
      `/fleets/cars/${carId}`,
      {},
      {
        method: 'PUT',
        body: JSON.stringify(carData),
      }
    )
      .then((data) => {
        successCallback();
        const seasonedCar = seasonToCar(data);
        dispatch(updateCarStatusSuccess(seasonedCar));

        if (carData.status === 'RELEASED') {
          const feedbackText =
            data.circles?.length >= 1
              ? data.circles
                  .map(
                    (circleId: string) =>
                      `Car is released into circle "${getCirclesNameById(
                        circles,
                        circleId
                      )}"`
                  )
                  .join('\r\n')
              : 'Car is released';

          notification.success({
            message: feedbackText,
            style: {
              whiteSpace: 'pre',
            },
          });
        } else {
          message.success('Updated');
        }
      })
      .catch((err) => {
        dispatch(updateCarStatusFailure(err));
        if (carData.status) {
          message.error(
            `Failed to set status as ${carData.status}\n${err.message}`
          );
        }
        message.error(err.message);
      });
  };

const shouldUpdateCar = (state: CarsState) => !state.updatingStatus;

export const updateCarIfNeeded = (
  token: string,
  carId: string | null,
  carData: any,
  successCallback: () => void = () => {}
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldUpdateCar(getState().userData.cars)) {
      return dispatch(updateCar(token, carId, carData, successCallback));
    } else {
      return Promise.resolve();
    }
  };
};

export const fetchCarActiveBookings =
  (carId: string | null) => (dispatch: Dispatch, getState: GetState) => {
    requestAPI(`/fleets/cars/${carId}/activeBookings`, {
      from: new Date().toUTCString(),
      to: new Date(addDays(new Date(), 90)).toUTCString(),
    })
      .then((activeBookings: any) => {
        dispatch(setCarActiveBookings(activeBookings));
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

export const getActiveBooking = (carIds: any) => (dispatch: Dispatch) => {
  dispatch(activeBookingsRequest());
  requestAPI(
    `/fleets/bookings/active/get`,
    {},
    {
      method: 'POST',
      body: JSON.stringify({ ids: carIds }),
    }
  )
    .then((activeBookings) => {
      const sortedActiveBookings = activeBookings.sort((a: any, b: any) => {
        // @ts-ignore
        return new Date(a.from) - new Date(b.from);
      });
      dispatch(activeBookingsSuccess(sortedActiveBookings));
    })
    .catch((err) => {
      message.error(err.message);
      dispatch(activeBookingsFailure(err));
    });
};

export const getBookingById = function (bookingId: any) {
  return (dispatch: Dispatch) => {
    if (bookingId) {
      dispatch(bookingByIdRequest());
      requestAPI(`/fleets/bookings/${bookingId}`)
        .then((booking) => {
          if (booking) {
            dispatch(selectBooking(booking));
          }
        })
        .catch((err) => {
          message.error(err.message);
          dispatch(bookingByIdFailure(err));
        });
    }
  };
};

export const getTripById = function (tripId: any) {
  return (dispatch: Dispatch) => {
    dispatch(bookingByIdRequest());
    requestAPI(`/fleets/trips/${tripId}`)
      .then((trip) => {
        if (trip) {
          // @ts-ignore
          const { id, reservedAt, tripEnd, carId } = trip;
          batch(() => {
            dispatch(selectTrip(trip));
            dispatch(fetchVehicleEventsForTrip(carId, id, reservedAt, tripEnd));
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(bookingByIdFailure(err));
      });
  };
};

export const getTimelineStatusChanges = function (
  from: string,
  to: string,
  carIds: any,
  successCallback: any
) {
  return (dispatch: Dispatch) => {
    dispatch(timelineStatusChangesRequest());
    requestAPI(
      `/fleets/statusChanges/get`,
      {},
      {
        method: 'POST',
        body: JSON.stringify({ ids: carIds, from, to }),
      }
    )
      .then((statuses) => {
        if (statuses) {
          const sortedStatues = statuses.sort((a: any, b: any) => {
            // @ts-ignore
            return new Date(a.timestamp) - new Date(b.timestamp);
          });
          dispatch(timelineStatusChangesSuccess(sortedStatues));
          successCallback(sortedStatues);
        }
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(timelineStatusChangesFailure(err));
      });
  };
};

export const createStatusChangeBooking = function (
  body: any,
  successCallback: any
) {
  return (dispatch: Dispatch) => {
    dispatch(bookingStatusChangeRequest());
    requestAPI(
      '/fleets/bookings',
      {},
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    )
      .then((res) => {
        if (res) {
          message.success('Updated');
          successCallback(res);
        }
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(bookingStatusChangeFailure(err));
      });
  };
};
