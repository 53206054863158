export enum ClaimsTypes {
  CLAIMS_BY_CAR_REQUEST = 'userDataa/Claims/CLAIMS_BY_CAR_REQUEST',
  CLAIMS_BY_CAR_SUCCESS = 'userData/Claims/CLAIMS_BY_CAR_SUCCESS',
  CLAIMS_BY_CAR_FAILURE = 'userData/Claims/CLAIMS_BY_CAR_FAILURE',
  CLAIMS_REQUEST = 'userData/Claims/CLAIMS_REQUEST',
  ASSIGNABLE_CLAIM_REQUEST = 'userData/Claims/ASSIGNABLE_CLAIM_REQUEST',
  CLAIMS_SUCCESS = 'userData/Claims/CLAIMS_SUCCESS',
  ASSIGNABLE_CLAIM_SUCCESS = 'userData/Claims/ASSIGNABLE_CLAIM_SUCCESS',
  CLAIMS_FAILURE = 'userData/Claims/CLAIMS_FAILURE',
  SET_DISPLAY_CLAIMS = 'userData/Claims/SET_DISPLAY_CLAIMS',
  SELECT_CLAIM = 'userData/Claims/SELECT_CLAIM',
  UPDATE_CLAIM_REQUEST = 'userData/Claims/UPDATE_CLAIM_REQUEST',
  UPDATE_CLAIM_SUCCESS = 'userData/Claims/UPDATE_CLAIM_SUCCESS',
  UPDATE_CLAIM_FAILURE = 'userData/Claims/UPDATE_CLAIM_FAILURE',
  ASSIGNABLE_CLAIM_FAILURE = 'userData/Claims/ASSIGNABLE_CLAIM_FAILURE',
  SET_TOTAL_DAMAGES = 'userData/Claims/SET_TOTAL_DAMAGES',
}
