import {
  CarStatusData,
  Damage,
  ErrorResponse,
  FleetAdminCarData,
} from '../../../../@types';

export interface CarsState {
  entities: FleetAdminCarData[] | null;
  display: FleetAdminCarData[] | null;
  archivedCars: FleetAdminCarData[] | null;
  selected: FleetAdminCarData | null;
  error: ErrorResponse | null;
  loading: boolean;
  loadingArchivedCars: boolean;
  loadingCar: boolean;
  conditions: CarStatusData | null;
  lastCarsStatus: { [key: string]: CarStatusData };
  loadingConditions: boolean;
  updatingStatus: boolean;
  updatingStatusError: any | null;
  unlocking: boolean;
  resettingBoard: boolean;
  resettingModem: boolean;
  resettingBluetooth: boolean;
  resettingDataConnection: boolean;
  carDamages: Damage[];
  carActiveBookings: any;
  drawerActiveTab: number;
  selectedSubmenu: 'archived' | null;
  activeBookings: Array<any>;
  timelineStatusChanges: Array<any>;
}

export const initialState = {
  entities: null,
  display: null,
  archivedCars: null,
  selected: null,
  error: null,
  loading: false,
  loadingArchivedCars: false,
  loadingCar: false,
  conditions: null,
  lastCarsStatus: {},
  loadingConditions: false,
  updatingStatus: false,
  updatingStatusError: null,
  unlocking: false,
  resettingBoard: false,
  resettingModem: false,
  resettingBluetooth: false,
  resettingDataConnection: false,
  carDamages: [],
  carActiveBookings: [],
  drawerActiveTab: 0,
  selectedSubmenu: null,
  activeBookings: [],
  timelineStatusChanges: [],
};
