import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
// @ts-ignore
import cookie from 'js-cookie';
// @ts-ignore
import { loginByLocalStorage } from '../redux/store/userData/user';
import { navigateTo } from '../utils';
// import * as gtag from '../libs/gtag';
import LoadingIcon from '../components/shared/LoadingIcon';
import { history } from '../utils';
import { setQueries } from '../libs/setQueries';

const Init = () => {
  const dispatch = useDispatch();
  const token = cookie.get('token');

  useEffect(() => {
    if (token || window?.localStorage?.getItem('userToken')) {
      dispatch(loginByLocalStorage());
      navigateTo(history, '/dashboard');
      setQueries({
        vm: 'VEHICLE',
        type: 'TABLE',
      });
      window.location.reload();
    } else if (window.location.pathname.includes('forgot')) {
      navigateTo(history, '/forgot');
    } else {
      navigateTo(history, '/login.html');
      window.location.reload();
      // redirectToPath('/login.html');
    }
    // gtag.pageview();
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIcon />
    </div>
  );
};

export default Init;
