export enum CarsTypes {
  CARS_REQUEST = 'userData/cars/CARS_REQUEST',
  ACTIVE_BOOKINGS_REQUEST = 'userData/cars/ACTIVE_BOOKINGS_REQUEST',
  BOOKING_BY_ID_REQUEST = 'userData/cars/BOOKING_BY_ID_REQUEST',
  ARCHIVED_CARS_REQUEST = 'userData/cars/ARCHIVED_CARS_REQUEST',
  CARS_SUCCESS = 'userData/cars/CARS_SUCCESS',
  ACTIVE_BOOKINGS_SUCCESS = 'userData/cars/ACTIVE_BOOKINGS_SUCCESS',
  ARCHIVED_CARS_SUCCESS = 'userData/cars/ARCHIVED_CARS_SUCCESS',
  CARS_FAILURE = 'userData/cars/CARS_FAILURE',
  ACTIVE_BOOKINGS_FAILURE = 'userData/cars/ACTIVE_BOOKINGS_FAILURE',
  BOOKING_BY_ID_FAILURE = 'userData/cars/BOOKING_BY_ID_FAILURE',
  CAR_REQUEST = 'userData/cars/CAR_REQUEST',
  CAR_SUCCESS = 'userData/cars/CAR_SUCCESS',
  CAR_FAILURE = 'userData/cars/CAR_FAILURE',
  SELECT_CAR = 'userData/cars/SELECT_CAR',
  SELECT_VEHICLES_SUBMENU = 'userData/cars/SELECT_VEHICLES_SUBMENU',
  SET_CAR_DAMAGES = 'userData/cars/SET_CAR_DAMAGES',
  SET_CAR_ACTIVE_BOOKINGS = 'userData/cars/SET_CAR_ACTIVE_BOOKINGS',
  SET_DISPLAY_CARS = 'userData/cars/SET_DISPLAY_CARS',
  CAR_CONDITIONS_REQUEST = 'userData/cars/CAR_CONDITIONS_REQUEST',
  CAR_CONDITIONS_SUCCESS = 'userData/cars/CAR_CONDITIONS_SUCCESS',
  UPDATE_LAST_CARS_STATUS = 'userData/cars/UPDATE_LAST_CARS_STATUS',
  CAR_CONDITIONS_FAILURE = 'userData/cars/CAR_CONDITIONS_FAILURE',
  UPDATE_CAR_STATUS_REQUEST = 'userData/cars/UPDATE_CAR_STATUS_REQUEST',
  UPDATE_CAR_STATUS_SUCCESS = 'userData/cars/UPDATE_CAR_STATUS_SUCCESS',
  UPDATE_CAR_STATUS_FAILURE = 'userData/cars/UPDATE_CAR_STATUS_FAILURE',
  UNLOCK_CAR_REQUEST = 'userData/cars/UNLOCK_CAR_REQUEST',
  UNLOCK_CAR_SUCCESS = 'userData/cars/UNLOCK_CAR_SUCCESS',
  UNLOCK_CAR_FAILURE = 'userData/cars/UNLOCK_CAR_FAILURE',
  RESET_BOARD_REQUEST = 'userData/cars/RESET_BOARD_REQUEST',
  RESET_BOARD_SUCCESS = 'userData/cars/RESET_BOARD_SUCCESS',
  RESET_BOARD_FAILURE = 'userData/cars/RESET_BOARD_FAILURE',
  RESET_MODEM_REQUEST = 'userData/cars/RESET_MODEM_REQUEST',
  RESET_MODEM_SUCCESS = 'userData/cars/RESET_MODEM_SUCCESS',
  RESET_MODEM_FAILURE = 'userData/cars/RESET_MODEM_FAILURE',
  RESET_BLUETOOTH_REQUEST = 'userData/cars/RESET_BLUETOOTH_REQUEST',
  RESET_BLUETOOTH_SUCCESS = 'userData/cars/RESET_BLUETOOTH_SUCCESS',
  RESET_BLUETOOTH_FAILURE = 'userData/cars/RESET_BLUETOOTH_FAILURE',
  RESET_DATA_CONNECTION_REQUEST = 'userData/cars/RESET_DATA_CONNECTION_REQUEST',
  RESET_DATA_CONNECTION_SUCCESS = 'userData/cars/RESET_DATA_CONNECTION_SUCCESS',
  RESET_DATA_CONNECTION_FAILURE = 'userData/cars/RESET_DATA_CONNECTION_FAILURE',
  SET_CAR_DRAWER_ACTIVE_TAB = 'userData/cars/SET_CAR_DRAWER_ACTIVE_TAB',
  TIMELINE_STATUS_CHANGES_REQUEST = 'userData/cars/TIMELINE_STATUS_CHANGES_REQUEST',
  TIMELINE_STATUS_CHANGES_SUCCESS = 'userData/cars/TIMELINE_STATUS_CHANGES_SUCCESS',
  TIMELINE_STATUS_CHANGES_FAILURE = 'userData/cars/TIMELINE_STATUS_CHANGES_FAILURE',
  BOOKING_STATUS_CHANGE_REQUEST = 'userData/cars/BOOKING_STATUS_CHANGE_REQUEST',
  BOOKING_STATUS_CHANGE_SUCCESS = 'userData/cars/BOOKING_STATUS_CHANGE_SUCCESS',
  BOOKING_STATUS_CHANGE_FAILURE = 'userData/cars/BOOKING_STATUS_CHANGE_FAILURE',
}
