import React from 'react';
import { useIntl } from 'react-intl';
import styles from './ViolationList.styles';

interface Props {
  violations: Array<string>;
}

const ViolationList: React.FC<Props> = ({ violations }) => {
  const intl = useIntl();
  if (violations.length === 0) {
    return <span>No Violations reported</span>;
  }

  return (
    <>
      <ul>
        {violations.map((violation) => (
          <li key={violation}>
            {`- ${intl.formatMessage({
              id: `fleetadmin.violationType.${violation}`,
            })}`}
          </li>
        ))}
      </ul>
      <style jsx>{styles}</style>
    </>
  );
};

export default ViolationList;
