import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Input, Button, Checkbox } from 'antd';
import styles from './AddAdminModal.styles';
import { colors } from '../../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import {
  createAdmin,
  selectSelectedAdmin,
} from '../../../redux/store/userData/admins';
import SelectorLayout from '../../shared/SelectorLayout';
import { selectBrands } from '../../../redux/store/appData/fleets';
import { selectFleets } from '../../../redux/store/appData/fleets/selectors';
import { useIntl } from 'react-intl';
import { selectCircles } from '../../../redux/store/userData/circles';
import { LANGUAGES } from '../EditAdminInfoModal/EditAdminInfoModal';
import { selectAuth } from '../../../redux/store/userData/user';
import { uniqueArrayOfObjects } from '../../../utils/brandHelpers';

const AddAdminModal: React.FC = () => {
  const user = useSelector(selectAuth);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [locale, setLocale] = useState('');

  const [fleetManager, setFleetManager] = useState(false);
  const [tripsManager, setTripsManager] = useState(false);
  const [claimsManager, setClaimsManager] = useState(false);
  const [mechanic, setMechanic] = useState(false);

  const [merchantManager, setMerchantManager] = useState(false);
  const [userManager, setUserManager] = useState(false);
  const [userGroupsManager, setUserGroupsManager] = useState(false);
  const [paymentManager, setPaymentManager] = useState(false);

  const [brands, setBrands] = useState([]) as any;
  const [fleets, setFleets] = useState([]) as any;
  const [circles, setCircles] = useState([]) as any;

  const [addNewBrandsAutomatically, setAddNewBrandsAutomatically] =
    useState(false);
  const [addNewFleetsAutomatically, setAddNewFleetsAutomatically] =
    useState(false);
  const [addNewCirclesAutomatically, setAddNewCirclesAutomatically] =
    useState(false);

  const selectedAdmin = useSelector(selectSelectedAdmin);
  const selectedBrands = useSelector(selectBrands);
  const selectedFleets = useSelector(selectFleets);
  const selectedCircles = useSelector(selectCircles);
  const availableBrands = uniqueArrayOfObjects([
    ...user.brands,
    ...(selectedBrands?.length ? selectedBrands : []),
  ]);

  const brandsOptions = useMemo(
    () =>
      availableBrands?.map((brand) => ({
        value: brand.id,
        label: brand.name,
      })) || [],
    [availableBrands]
  );

  const fleetOptions =
    selectedFleets
      // @ts-ignore
      ?.filter((fleet) => brands?.includes(fleet?.brandId))
      ?.map((fleet) => ({
        value: fleet.id,
        label: fleet.name,
      })) || [];

  const circleOptions =
    selectedCircles
      // @ts-ignore
      ?.filter((circle) => brands?.includes(circle?.brandId))
      ?.map((circle: any) => ({
        value: circle.id,
        label: circle.name,
      })) || [];

  const visible = useSelector(getIsVisibleModal('createAdminModal'));

  const anyPermissionAssigned =
    fleetManager ||
    tripsManager ||
    claimsManager ||
    mechanic ||
    merchantManager ||
    userManager ||
    userGroupsManager ||
    paymentManager;

  const isDisabled = !(firstName && lastName && email && anyPermissionAssigned);

  const onSelectBrands = (brands: any) => {
    setBrands(brands);

    // filter fleets automatically based on selected brands
    setFleets(
      selectedFleets
        ?.filter((fleet) => fleets.includes(fleet.id))
        // @ts-ignore
        ?.filter((fleet) => brands?.includes(fleet?.brandId))
        ?.map((fleet: any) => fleet.id)
    );

    // filter circles automatically based on selected brands
    setCircles(
      selectedCircles
        ?.filter((circle) => circles.includes(circle.id))
        // @ts-ignore
        ?.filter((circle) => brands?.includes(circle?.brandId))
        ?.map((circle: any) => circle.id)
    );
  };

  const onSelectFleets = (value: any) => {
    setFleets(value);
  };

  const onSelectCircles = (value: any) => {
    setCircles(value);
  };

  const onSelectLocale = (value: any) => {
    setLocale(value);
  };

  const handleCancel = () => {
    dispatch(closeModal('createAdminModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      createAdmin({
        firstName,
        lastName,
        email,
        permissions: {
          fleetManager,
          tripsManager,
          claimsManager,
          merchantManager,
          mechanic,
          userManager,
          userGroupsManager,
          paymentManager,
        },
        locale: locale.toLowerCase(),
        brands,
        fleets,
        circles,
        addNewBrandsAutomatically,
        addNewFleetsAutomatically,
        addNewCirclesAutomatically,
      })
    );

    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setFleetManager(false);
      setTripsManager(false);
      setClaimsManager(false);
      setMechanic(false);
      setMerchantManager(false);
      setUserManager(false);
      setUserGroupsManager(false);
      setPaymentManager(false);
      setLocale('');
      setAddNewBrandsAutomatically(false);
      setAddNewFleetsAutomatically(false);
      setAddNewCirclesAutomatically(false);
      setBrands([]);
      setFleets([]);
      setCircles([]);
    }
  }, [visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-admin-wrapper"
          title={
            selectedAdmin
              ? 'Update admin'
              : intl.formatMessage({
                  id: `fleetadmin.admins.admin.new`,
                })
          }
          visible={visible}
          width={520}
          style={{ top: '10px' }}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-admin-contents" tabIndex={0}>
            <div className="add-admin-title">
              {intl.formatMessage({
                id: `fleetadmin.admins.info.user`,
              })}
            </div>
            <div className="input-container">
              <div className="add-admin-input-row-container">
                <Input
                  value={firstName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.admins.info.firstName`,
                  })}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{ marginRight: 12 }}
                />
                <Input
                  value={lastName}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.admins.info.lastName`,
                  })}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <Input
                value={email}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.admins.info.email`,
                })}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: 16 }}
              />
              <SelectorLayout
                keyPrefix="brand-select"
                value={locale}
                onSelect={onSelectLocale}
                options={LANGUAGES}
                style={{ marginTop: 16 }}
              />
            </div>
            <div className="add-admin-title">
              {intl.formatMessage({
                id: `fleetadmin.admins.tab.permissions`,
              })}
            </div>
            <div className="input-container" style={{ paddingTop: 0 }}>
              <div className="add-admin-subtitle">
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.merchant.title`,
                })}
              </div>
              <div className="input-container" style={{ padding: '0 0 8px 0' }}>
                <SelectorLayout
                  keyPrefix="brand-select"
                  mode="multiple"
                  value={brands}
                  placeholder="Select Brands"
                  onSelect={onSelectBrands}
                  options={brandsOptions}
                />
                <div className="sub-actions">
                  <Checkbox
                    checked={addNewBrandsAutomatically}
                    onChange={(e) =>
                      setAddNewBrandsAutomatically(e?.target?.checked)
                    }
                    style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
                  >
                    {intl.formatMessage({
                      id: `fleetadmin.admins.permission.automatically`,
                    })}
                  </Checkbox>
                  <div className="sub-actions">
                    <div
                      className="action"
                      onClick={() =>
                        onSelectBrands(selectedBrands?.map((brand) => brand.id))
                      }
                    >
                      {intl.formatMessage({
                        id: `fleetadmin.admins.permission.select.all`,
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <Checkbox
                checked={merchantManager}
                onChange={(e) => setMerchantManager(e?.target?.checked)}
                style={{ padding: '4px 0', margin: 0 }}
              >
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.merchantmanager`,
                })}
              </Checkbox>
              <div className="add-admin-subtitle" style={{ marginTop: '20px' }}>
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.fleet.title`,
                })}
              </div>
              <div className="input-container" style={{ padding: '0 0 8px 0' }}>
                <SelectorLayout
                  keyPrefix="brand-select"
                  mode="multiple"
                  value={fleets}
                  placeholder="Select Fleets"
                  onSelect={onSelectFleets}
                  options={fleetOptions}
                />
                <div className="sub-actions">
                  <Checkbox
                    disabled={
                      !user?.merchant?.permissions.fleetManager ?? false
                    }
                    checked={addNewFleetsAutomatically}
                    onChange={(e) =>
                      setAddNewFleetsAutomatically(e?.target?.checked)
                    }
                    style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
                  >
                    {intl.formatMessage({
                      id: `fleetadmin.admins.permission.automatically`,
                    })}
                  </Checkbox>
                  <div className="sub-actions">
                    <div
                      className="action"
                      onClick={() =>
                        onSelectFleets(
                          selectedFleets
                            // @ts-ignore
                            ?.filter((fleet) =>
                              brands?.includes(fleet?.brandId)
                            )
                            ?.map((brand) => brand.id)
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: `fleetadmin.admins.permission.select.all`,
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-admin-input-container">
                <Checkbox
                  disabled={!user?.merchant?.permissions.fleetManager ?? false}
                  checked={fleetManager}
                  onChange={(e) => setFleetManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.fleetmanager`,
                  })}
                </Checkbox>

                <Checkbox
                  disabled={!user?.merchant?.permissions.tripsManager ?? false}
                  checked={tripsManager}
                  onChange={(e) => setTripsManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.tripsmanager`,
                  })}
                </Checkbox>

                <Checkbox
                  disabled={!user?.merchant?.permissions.claimsManager ?? false}
                  checked={claimsManager}
                  onChange={(e) => setClaimsManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.claimsmanager`,
                  })}
                </Checkbox>
                <Checkbox
                  disabled={!user?.merchant?.permissions?.mechanic ?? false}
                  checked={mechanic}
                  onChange={(e) => setMechanic(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.mechanic`,
                  })}
                </Checkbox>
              </div>
              <div className="add-admin-subtitle" style={{ marginTop: '20px' }}>
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.user.title`,
                })}
              </div>
              <div className="input-container" style={{ padding: '0 0 8px 0' }}>
                <SelectorLayout
                  keyPrefix="brand-select"
                  mode="multiple"
                  value={circles}
                  placeholder="Select Circles"
                  onSelect={onSelectCircles}
                  options={circleOptions}
                />
                <div className="sub-actions">
                  <Checkbox
                    disabled={
                      !user?.merchant?.permissions.userGroupsManager ?? false
                    }
                    checked={addNewCirclesAutomatically}
                    onChange={(e) =>
                      setAddNewCirclesAutomatically(e?.target?.checked)
                    }
                    style={{ padding: '4px 0', margin: 0, color: '#1890ff' }}
                  >
                    {intl.formatMessage({
                      id: `fleetadmin.admins.permission.automatically`,
                    })}
                  </Checkbox>
                  <div className="sub-actions">
                    <div
                      className="action"
                      onClick={() =>
                        onSelectCircles(
                          selectedCircles
                            // @ts-ignore
                            ?.filter((circle) =>
                              brands?.includes(circle?.brandId)
                            )
                            ?.map((brand) => brand.id)
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: `fleetadmin.admins.permission.select.all`,
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-admin-input-container">
                <Checkbox
                  disabled={!user?.merchant?.permissions.userManager ?? false}
                  checked={userManager}
                  onChange={(e) => setUserManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.usermanager`,
                  })}
                </Checkbox>

                <Checkbox
                  disabled={
                    !user?.merchant?.permissions.userGroupsManager ?? false
                  }
                  checked={userGroupsManager}
                  onChange={(e) => setUserGroupsManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.usergroupsmanager`,
                  })}
                </Checkbox>
              </div>

              <div className="add-admin-subtitle" style={{ marginTop: '20px' }}>
                {intl.formatMessage({
                  id: `fleetadmin.admins.permission.payment.title`,
                })}
              </div>
              <div className="add-admin-input-container">
                <Checkbox
                  disabled={
                    !user?.merchant?.permissions.paymentManager ?? false
                  }
                  checked={paymentManager}
                  onChange={(e) => setPaymentManager(e?.target?.checked)}
                  style={{ padding: '4px 0', margin: 0 }}
                >
                  {intl.formatMessage({
                    id: `fleetadmin.admins.permission.paymentmanager`,
                  })}
                </Checkbox>
              </div>
            </div>
            <div className="button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default AddAdminModal;
