import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select, DatePicker } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './AddLicenseModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  selectSelectedUser,
  addUserDL,
} from '../../../redux/store/userData/users';
import { useIntl } from 'react-intl';
import { zIndex } from '../../../theme/theme';
import { ANT_PICKERS_FORMAT } from '../../../utils';

const { Option } = Select;

const supportedLicenseCountries = [
  { code: 'CH', name: 'Switzerland' },
  { code: 'MC', name: 'Monaco' },
  { code: 'NO', name: 'Norway' },
  { code: 'BE', name: 'Belgium' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'AT', name: 'Austria' },
  { code: 'FI', name: 'Finland' },
];

const AddLicenseModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const visibleModal = useSelector(getIsVisibleModal('AddLicenseModal'));

  const [number, setNumber] = useState('');
  const [classBValidFrom, setClassBValidFrom] = useState('') as any;
  const [classBValidUntil, setClassBValidUntil] = useState('') as any;
  const [issueDate, setIssueDate] = useState('') as any;
  const [issuingAuthority, setIssuingAuthority] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [issueCountry, setIssueCountry] = useState('DE');

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      addUserDL(selectedUser.id, {
        number,
        issueDate,
        issuingAuthority,
        birthPlace,
        issueCountry,
        classBValidFrom,
        ...(classBValidUntil && {
          classBValidUntil,
        }),
      })
    );
    dispatch(closeModal('AddLicenseModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('AddLicenseModal'));
  };

  useEffect(() => {
    if (visibleModal) {
      setNumber('');
      setBirthPlace('');
      setClassBValidFrom('');
      setIssueDate('');
      setClassBValidUntil('');
      setIssuingAuthority('');
      setIssueCountry('DE');
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('UserContactModal'));
        }}
      >
        <div className="update-phone-modal">
          <div className="phone-modal-wrapper">
            <div className="update-phone-modal-inputs">
              <p className="add-phone-title">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.license.manualForm.title`,
                })}
              </p>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.licenseNumber`,
                  })}
                </p>
                <Input
                  value={number}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.licenseNumber.placeholder`,
                  })}
                  onChange={(e: any) => {
                    setNumber(e.target.value);
                  }}
                />
              </div>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.birthPlace`,
                  })}
                </p>
                <Input
                  value={birthPlace}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.birthPlace.placeholder`,
                  })}
                  onChange={(e: any) => {
                    setBirthPlace(e.target.value);
                  }}
                />
              </div>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.license.issuingAuthority.placeholder`,
                  })}
                </p>
                <Input
                  value={issuingAuthority}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.license.issuingAuthority.placeholder`,
                  })}
                  onChange={(e: any) => {
                    setIssuingAuthority(e.target.value);
                  }}
                />
              </div>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.issueDate`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.issueDate.placeholder`,
                  })}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  onChange={(_, dateString) => setIssueDate(dateString)}
                />
              </div>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.classB.From`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.classB.From.placeholder`,
                  })}
                  onChange={(_, dateString) => setClassBValidFrom(dateString)}
                />
              </div>
              <div className="license-input">
                <p className="label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.classB.until`,
                  })}
                </p>
                <DatePicker
                  format={ANT_PICKERS_FORMAT}
                  getPopupContainer={(triggerNode: HTMLElement) => {
                    return triggerNode.parentNode as HTMLElement;
                  }}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({
                    id: `fleetadmin.users.info.license.manualForm.classB.until.placeholder`,
                  })}
                  onChange={(_, dateString) => setClassBValidUntil(dateString)}
                />
              </div>
              <p className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.license.manualForm.issueCountry`,
                })}
              </p>
              <Select
                dropdownStyle={{ zIndex: zIndex.dropDown }}
                value={issueCountry}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.id.manualForm.issueCountry`,
                })}
                onChange={(code: string) => setIssueCountry(code)}
              >
                {supportedLicenseCountries.map((country) => (
                  <Option
                    key={`area-option-${country.code}`}
                    value={country.code}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Button
            disabled={!(number && issueCountry && issueDate && classBValidFrom)}
            className="submit-button"
            onClick={handleOnSubmit}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default AddLicenseModal;
