import React from 'react';
import cn from 'classnames';
import styles from './RangeSwitch.styles';
import { CalendarRange } from '../VehicleTimeTable';

// @ts-ignore
const RangeSwitch = ({ range, setRange, onClickToday }) => {
  const handleOnClickHours = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setRange(CalendarRange.Hours);
  };

  const handleOnClickDays = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setRange(CalendarRange.Days);
  };

  const handleOnClickMonths = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setRange(CalendarRange.Week);
  };

  return (
    <>
      <div className="range-switcher">
        <span className={cn('item', { today: true })} onClick={onClickToday}>
          Today
        </span>
        <span
          className={cn('item', {
            active: range === CalendarRange.Hours,
          })}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          onClick={handleOnClickHours}
        >
          Hours
        </span>
        <span
          className={cn('item', { active: range === CalendarRange.Days })}
          style={{ borderRadius: 0 }}
          onClick={handleOnClickDays}
        >
          Days
        </span>
        <span
          className={cn('item', {
            active: range === CalendarRange.Week,
          })}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={handleOnClickMonths}
        >
          Weeks
        </span>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default RangeSwitch;
