import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, selectViewMode } from '../../../redux/store/ui/common';
import { ViewMode } from '../../../hooks/useViewMode';
import styles from './CreateButton.styles';
import { selectReceipt } from '../../../redux/store/userData/receipts';
import { selectUser } from '../../../redux/store/userData/users';
import { selectAdmin } from '../../../redux/store/userData/admins';
import { useSelectedDrawer } from '../../../hooks/useSelectedViewMode';

const CreateButton: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const selectedDrawer = useSelectedDrawer(viewMode);

  const show = [
    ViewMode.CATEGORIES,
    ViewMode.CIRCLE,
    ViewMode.AREA,
    ViewMode.PROFILE,
    ViewMode.ADMINS,
    ViewMode.RECEIPTS,
    ViewMode.BOOKING,
  ].includes(viewMode);

  const handleClick = () => {
    switch (viewMode) {
      case ViewMode.CATEGORIES: {
        dispatch(openModal('createCategoryModal'));
        break;
      }
      case ViewMode.CIRCLE: {
        dispatch(openModal('createCircleModal'));
        break;
      }
      case ViewMode.PROFILE: {
        dispatch(openModal('createProfileModal'));
        break;
      }
      case ViewMode.ADMINS: {
        dispatch(selectAdmin(null));
        dispatch(openModal('createAdminModal'));
        break;
      }
      case ViewMode.RECEIPTS: {
        dispatch(selectReceipt(null));
        dispatch(openModal('ReceiptsCreateModal'));
        break;
      }
      case ViewMode.USERS: {
        dispatch(selectUser(null));
        dispatch(openModal('UsersCreateModal'));
        break;
      }
      case ViewMode.BOOKING: {
        dispatch(openModal('BookingCreateModal'));
        break;
      }
      case ViewMode.AREA: {
        dispatch(openModal('createAreaModal'));
        break;
      }
      default:
        break;
    }
  };

  return show ||
    (viewMode === ViewMode.USERS &&
      !window.location.href.includes('admin.getaway.pro')) ? (
    <>
      <div className="wrapper" title="Create" onClick={handleClick}>
        <img
          style={selectedDrawer ? { right: '450px' } : {}}
          className="floating-plus"
          src={'/images/table/floatingPlus.svg'}
          alt="Create"
        />
      </div>
      <style jsx>{styles}</style>
    </>
  ) : null;
};

export default CreateButton;
