import css from 'styled-jsx/css';

const style = css`
  .vehicle-info {
    margin: 16px 0 0;
  }
  .edit-button {
    cursor: pointer;
  }
`;

export default style;
