import React from 'react';
import { IntlShape } from 'react-intl';
import {
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDate,
} from '../shared/TableLayout';
import { FleetData } from '../../@types';
import { Tag } from 'antd';
import abbreviateText from '../../utils/abbreviateText';

type getColumnsArgs = {
  intl: IntlShape;
  availableFleets: Array<FleetData> | null;
};

const getColumns = ({ intl, availableFleets }: getColumnsArgs) => {
  const renderEmail = (email: string) => (
    <span style={{ color: '#0077be', cursor: 'pointer' }}>
      {abbreviateText(email, 25)}
    </span>
  );

  const renderAccess = (fullAccess: string) => (
    <span>
      {fullAccess
        ? intl.formatMessage({
            id: 'fleetadmin.admins.table.full',
          })
        : intl.formatMessage({
            id: 'fleetadmin.admins.table.limited',
          })}
    </span>
  );

  const renderStatus = (blocked: string) => (
    <span>
      {blocked
        ? intl.formatMessage({
            id: 'fleetadmin.admins.info.deleted',
          })
        : intl.formatMessage({
            id: 'fleetadmin.admins.info.active',
          })}
    </span>
  );

  const renderFleets = (fleets: Array<any>) => (
    <>
      {fleets?.map((fleet) => (
        <Tag color="grey" key={fleet?.id}>
          {fleet?.name || '-'}
        </Tag>
      ))}
    </>
  );

  return [
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.email',
      }),
      dataIndex: 'email',
      ellipsis: true,
      width: 250,
      fixed: 'left' as 'left',
      render: renderEmail,
      sorter: alphabeticalSorterFor('email'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.firstname',
      }),
      dataIndex: 'firstName',
      ellipsis: true,
      width: 150,
      sorter: alphabeticalSorterFor('firstName'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.lastname',
      }),
      dataIndex: 'lastName',
      ellipsis: true,
      width: 150,
      sorter: alphabeticalSorterFor('lastName'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.blocked',
      }),
      dataIndex: 'blocked',
      ellipsis: true,
      width: 150,
      render: renderStatus,
      sorter: alphabeticalSorterFor('blocked'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.created',
      }),
      dataIndex: 'created',
      ellipsis: true,
      width: 150,
      render: renderDate,
      sorter: chronologicalSorterFor('created'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.access',
      }),
      dataIndex: 'fullAccess',
      ellipsis: true,
      width: 150,
      render: renderAccess,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.admins.table.fleets',
      }),
      dataIndex: 'fleets',
      ellipsis: true,
      width: 150,
      render: renderFleets,
    },
  ];
};

export default getColumns;
