const SERVICE_TYPES = [
  { text: 'Wash outside', value: 'washOutside' },
  { text: 'Wash Inside', value: 'washInside' },
  { text: 'Safety Check', value: 'safetyCheck' },
  { text: 'Clean Outside Dry', value: 'cleanOutsideDry' },
  { text: 'Refuel', value: 'refuel' },
  { text: 'Relocate', value: 'relocate' },
  { text: 'Battery Charge', value: 'batteryCharge' },
  { text: 'Battery Change Simple', value: 'batteryChangeSimple' },
  { text: 'battery Change Opening', value: 'batteryChangeOpening' },
  { text: 'Basic Car Photos', value: 'basicCarPhotos' },
  { text: 'Basic Damage Records', value: 'basicDamageRecords' },
  { text: 'Exchange Fuel Card', value: 'exchangeFuelCard' },
  { text: 'Basic Branding', value: 'basicBranding' },
  { text: 'Other Basic Service', value: 'otherBasicService' },
  { text: 'Tire Pressure', value: 'tirePressure' },
  { text: 'Windshield Fluid Refill', value: 'windshieldFluidRefill' },
  { text: 'Combo Pro', value: 'comboPro' },
  { text: 'Combo Refuel Wash Inside', value: 'comboRefuelWashInside' },
  { text: 'Combo Refuel Wash Outside', value: 'comboRefuelWashOutside' },
  { text: 'Combo Refuel Wash Total', value: 'comboRefuelWashTotal' },
];

export default SERVICE_TYPES;
