import css from 'styled-jsx/css';
import { colors, common } from '../../theme/theme';

export const timelineStyles = css`
  .timeline-wrapper {
    margin: 0 24px 24px 8px;
  }
  .reservedAtBody{
    display: flex;
    margin-bottom: 0;
  }
  .reservedAtLocation {
    color: ${colors.blue};
    cursor: pointer;
    margin-bottom: 0;
   }
   .arrow-right {
    width: 16px;
    height: 16px;
    margin-left: 4px;
   }
   .damage-record-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
   }
   .damage-record-label {
    color: ${colors.blue};
    font-size: 14px;
   }
   .timeline-wrapper :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
    margin-top: 0px;
    margin-right: 24px;
  }
  .timeline-wrapper :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
    margin-top: 0px;
    margin-right: 24px;
  }
  .label {
    font-size: 12px;
    margin: 16px 0 0;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .close {
    margin-top: 12px;
    color: red;
    text-align: center;
    cursor: pointer;
  }
  .reported-trip{
    display: flex;
    align-items: center;
  }
  .estimated-value {
    color: ${colors.blue};
    font-size: 14px;
  }
  .edit-button {
    cursor: pointer;
  }
  .estimated-category {
    display: flex;
    flex-direction: column;
  }
  .estimated-category-label {
    font-size: 12px;
    margin: 8px 0 12px;
  }
  :global(.ant-checkbox-wrapper) {
    margin-left: 0px !important;
    margin-bottom: 2px;
  }
  .dot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  margin-left: 8px;
  align-self: center;
  }
  .cancel-text {
  margin-top: 20px;
  color: red;
  cursor: pointer;
  }
  }
`;
