import { useSelector } from 'react-redux';
import { setQueries } from '../libs/setQueries';
import { useQuery } from './useQuery';
import { selectAuth } from '../redux/store/userData/user/selectors';
import { UserData } from '../redux/store/userData/user/initialState';

export enum ViewMode {
  VEHICLE = 'VEHICLE',
  PROFILE = 'PROFILE',
  AREA = 'AREA',
  CLAIM = 'CLAIM',
  CARE = 'CARE',
  SHARE = 'SHARE',
  BOOKING = 'BOOKING',
  CATEGORIES = 'CATEGORIES',
  PRICING = 'PRICING',
  CIRCLE = 'CIRCLE',
  USERS = 'USERS',
  ADMINS = 'ADMINS',
  RECEIPTS = 'RECEIPTS',
}

const useViewMode = (): {
  viewMode: ViewMode;
  setViewMode: (vm: ViewMode) => void;
  initViewMode: () => { vm: ViewMode } | null;
} => {
  const user = useSelector(selectAuth);
  const storedUser = (
    user?.email ? user : JSON.parse(window?.localStorage?.getItem('userData'))
  ) as UserData;
  const query = useQuery();

  if (Array.isArray(query.vm)) {
    throw new Error('vm must be string');
  }

  // @ts-ignore
  const viewMode = ViewMode[query.vm];

  const setViewMode = (viewMode: ViewMode) => {
    setQueries({ vm: viewMode });
  };

  const initViewMode = () => {
    const availableViews = [
      ...(storedUser?.permissions?.fleetManager ? [ViewMode.VEHICLE] : []),
      ...(storedUser?.permissions?.fleetManager ? [ViewMode.PROFILE] : []),
      ...(storedUser?.permissions?.fleetManager ? [ViewMode.AREA] : []),
      ...(storedUser?.permissions?.claimsManager ? [ViewMode.CLAIM] : []),
      ...(storedUser?.permissions?.tripsManager ? [ViewMode.CARE] : []),
      ...(storedUser?.permissions?.tripsManager ? [ViewMode.SHARE] : []),
      ...(storedUser?.permissions?.tripsManager ? [ViewMode.BOOKING] : []),
      ...(storedUser?.permissions?.fleetManager ? [ViewMode.CATEGORIES] : []),
      ...(storedUser?.permissions?.fleetManager ? [ViewMode.PRICING] : []),
      ...(storedUser?.permissions?.userGroupsManager ? [ViewMode.CIRCLE] : []),
      ...(storedUser?.permissions?.userManager ? [ViewMode.USERS] : []),
      ...(storedUser?.permissions?.merchantManager ? [ViewMode.ADMINS] : []),
      ...(storedUser?.permissions?.paymentManager ? [ViewMode.RECEIPTS] : []),
    ];
    if (!query?.vm && availableViews?.length > 0) {
      return { vm: availableViews[0] };
    }
    return null;
  };

  return {
    viewMode,
    setViewMode,
    initViewMode,
  };
};

export default useViewMode;
