import { RootState } from '../../../config';
import { CarsState } from './initialState';
import { CarStatusData, Damage, FleetAdminCarData } from '../../../../@types';

export const selectCars = (state: RootState): FleetAdminCarData[] | null =>
  state.userData.cars.entities;

export const selectArchivedCars = (
  state: RootState
): FleetAdminCarData[] | null => state.userData.cars.archivedCars;

export const selectDisplayCars = (
  state: RootState
): FleetAdminCarData[] | null => state.userData.cars.display;

export const selectCarsLoading = (state: RootState): boolean =>
  state.userData.cars.loading;

export const selectTimeTableCars = (
  state: RootState
): FleetAdminCarData[] | null =>
  // @ts-ignore
  state.userData.cars.display;

export const selectSelectedCar = (state: RootState): FleetAdminCarData | null =>
  state.userData.cars.selected;

export const selectSelectedVehiclesSubmenu = (
  state: RootState
): CarsState['selectedSubmenu'] => state.userData.cars.selectedSubmenu;

export const carDamages = (state: RootState): Damage[] =>
  state.userData.cars.carDamages;

export const selectCarActiveBookings = (state: RootState): Damage[] =>
  state.userData.cars.carActiveBookings;

export const selectCarConditions = (state: RootState): CarStatusData | null =>
  state.userData.cars.conditions;

export const selectLastCarsStatus = (
  state: RootState
): { [key: string]: CarStatusData } => state.userData.cars.lastCarsStatus;

// TODO: move this state to under ui
export const selectLoadingConditions = (state: RootState) =>
  state.userData.cars.loadingConditions;

// TODO: move this state to under ui
export const selectUpdatingCarStatus = (state: RootState): boolean =>
  state.userData.cars.updatingStatus;

// TODO: move this state to under ui
export const selectUnlockingCar = (state: RootState): boolean =>
  state.userData.cars.unlocking;

export const selectResettingBoard = (state: RootState): boolean =>
  state.userData.cars.resettingBoard;

export const selectResettingModem = (state: RootState): boolean =>
  state.userData.cars.resettingModem;

export const selectResettingDataConnection = (state: RootState): boolean =>
  state.userData.cars.resettingDataConnection;

export const selectResettingBluetooth = (state: RootState): boolean =>
  state.userData.cars.resettingBluetooth;

export const selectCarDrawerActiveTab = (state: RootState): number =>
  state.userData.cars.drawerActiveTab;

export const selectActiveBookings = (state: RootState): any =>
  state.userData.cars.activeBookings;
