import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  getRenderLink,
} from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import React from 'react';
import { FleetAdminBrandData } from '../../redux/store/appData/fleets';
import { VehicleProfileInfoData } from '../../@types';

export const getBrandNameById = (
  brands: any,
  brandId: string
): string | null => {
  if (brands == null) {
    return null;
  }

  return (
    brands?.find((brand: { id: string }) => brand.id === brandId)?.name || null
  );
};

const getColumns = (brands: FleetAdminBrandData[]) => {
  const renderBrand = (brandId: string) => (
    <span>{getBrandNameById(brands, brandId)}</span>
  );

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      fixed: 'left' as 'left',
      render: getRenderLink(),
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: 'Brand',
      dataIndex: 'brandId',
      ellipsis: true,
      width: 200,
      render: renderBrand,
      sorter: (
        profileA: VehicleProfileInfoData,
        profileB: VehicleProfileInfoData
      ) => {
        const brandNameA = getBrandNameById(brands, profileA?.brandId!);
        const brandNameB = getBrandNameById(brands, profileB?.brandId!);
        return alphabeticalSorter(brandNameA, brandNameB);
      },
    },
    {
      title: 'Vehicle Brand',
      dataIndex: 'brandName',
      ellipsis: true,
      width: 150,
      sorter: alphabeticalSorterFor('brandName'),
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'model',
      ellipsis: true,
      width: 200,
      sorter: alphabeticalSorterFor('model'),
    },
  ];
};

export default getColumns;
