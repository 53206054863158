import css from 'styled-jsx/css';
import { zIndex, common } from '../../theme/theme';

const style = css.global`
  .add-service-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .add-service-wrapper .ant-modal-header {
    padding: 15px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .add-service-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .add-service-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
  }
  .add-service-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .add-service-contents {
    width: 400px;
  }
  .date-input {
    width: 175px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 18px;
  }
  .time-input {
    width: 175px;
    margin-top: 20px;
  }
  .location-container {
    width: 100%;
    padding: 16px;
  }
  .services-container {
    width: 100%;
    padding: 16px;
  }
  .input-latitude {
    width: 175px;
    margin-right: 18px;
  }
  .input-longitude {
    width: 175px;
  }
  .submit-button-container {
    padding: 8px 16px 8px 16px;
  }
  .add-service-wrapper :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .add-service-wrapper :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
  .error {
    color: red;
    font-size: 11px;
    margin-bottom: 0;
    margin: 4px;
  }
`;

export default style;
