import { history } from '../utils/history';

function paramsToObject(entries: any) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    // @ts-ignore
    result[key] = value;
  }
  return result;
}

export const setQueries = (queries: any, overwrite = false) => {
  let params;
  if (overwrite) {
    params = { ...queries };
  } else {
    const urlParams = new URLSearchParams(history.location.search);
    const entries = urlParams.entries();
    const existingParams = paramsToObject(entries);
    params = { ...existingParams, ...queries };
  }
  history.push({
    pathname: history.location.pathname,
    search: new URLSearchParams(params).toString(),
  });
};

type Queries = {
  [key: string]: string | string[];
};

export const appendQueries = (queries: any): void => {};

export const removeQueries = (queries: Queries): void => {
  if (queries) {
    const query = new URLSearchParams(window.location.search);
    const entries = query.entries();
    const existingParams = paramsToObject(entries);
    // @ts-ignore
    delete existingParams[Object.keys(queries)[0]];
    setQueries(existingParams, true);
  }
};

export const setQueryPagination = (pageSize: number, pageNumber: number) => {
  const urlParams = new URLSearchParams(history.location.search);
  urlParams.set('pageSize', String(pageSize));
  urlParams.set('pageNumber', String(pageNumber));
  history.push({
    pathname: history.location.pathname,
    search: urlParams.toString(),
  });
};
