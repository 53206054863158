import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, FleetAdminCircleData } from '../../../../@types';
import { CirclesTypes } from '../../../../@types/enums/redux/store/CirclesTypes';

export const circlesRequest = () => createAction(CirclesTypes.CIRCLES_REQUEST);

export const circlesSuccess = (payload: FleetAdminCircleData[]) =>
  createAction(CirclesTypes.CIRCLES_SUCCESS, payload);

export const circlesFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.CIRCLES_FAILURE, error);

export const circleRequest = () => createAction(CirclesTypes.CIRCLE_REQUEST);

export const circleSuccess = (payload: FleetAdminCircleData) =>
  createAction(CirclesTypes.CIRCLE_SUCCESS, payload);

export const circleFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.CIRCLE_FAILURE, error);

export const updateCircleRequest = () =>
  createAction(CirclesTypes.UPDATE_CIRCLE_REQUEST);

export const updateCircleSuccess = (payload: FleetAdminCircleData) =>
  createAction(CirclesTypes.UPDATE_CIRCLE_SUCCESS, payload);

export const updateCircleFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.UPDATE_CIRCLE_FAILURE, error);

export const selectCircle = (payload: FleetAdminCircleData | null) =>
  createAction(CirclesTypes.SELECT_CIRCLE, payload);

export const setDisplayCircles = (payload: FleetAdminCircleData[]) =>
  createAction(CirclesTypes.SET_DISPLAY_CIRCLES, payload);

export const addUserRequest = () => createAction(CirclesTypes.ADD_USER_REQUEST);

export const addUserSuccess = () => createAction(CirclesTypes.ADD_USER_SUCCESS);

export const addUserFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.ADD_USER_FAILURE, error);

export const addAdminRequest = () =>
  createAction(CirclesTypes.ADD_ADMIN_REQUEST);

export const addAdminSuccess = () =>
  createAction(CirclesTypes.ADD_ADMIN_SUCCESS);

export const addAdminFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.ADD_ADMIN_FAILURE, error);

export const removeUserRequest = () =>
  createAction(CirclesTypes.REMOVE_USER_REQUEST);

export const removeUserSuccess = () =>
  createAction(CirclesTypes.REMOVE_USER_SUCCESS);

export const removeUserFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.REMOVE_USER_FAILURE, error);

export const removeAdminRequest = () =>
  createAction(CirclesTypes.REMOVE_ADMIN_REQUEST);

export const removeAdminSuccess = () =>
  createAction(CirclesTypes.REMOVE_ADMIN_SUCCESS);

export const removeAdminFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.REMOVE_ADMIN_FAILURE, error);

export const addFleetRequest = () =>
  createAction(CirclesTypes.ADD_FLEET_REQUEST);

export const addFleetSuccess = (payload: FleetAdminCircleData) =>
  createAction(CirclesTypes.ADD_FLEET_SUCCESS, payload);

export const addFleetFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.ADD_FLEET_FAILURE, error);

export const removeFleetRequest = () =>
  createAction(CirclesTypes.REMOVE_FLEET_REQUEST);

export const removeFleetSuccess = (payload: FleetAdminCircleData) =>
  createAction(CirclesTypes.REMOVE_FLEET_SUCCESS, payload);

export const removeFleetFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.REMOVE_FLEET_FAILURE, error);

export const createCircleRequest = () =>
  createAction(CirclesTypes.CREATE_CIRCLE_REQUEST);

export const createCircleSuccess = (payload: FleetAdminCircleData) =>
  createAction(CirclesTypes.CREATE_CIRCLE_SUCCESS, payload);

export const createCircleFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(CirclesTypes.CREATE_CIRCLE_FAILURE, error);
