import React from 'react';
import { Collapse, Switch } from 'antd';
import ListEditItem from '../../../shared/ListEditItem';
import ListItem from '../../../shared/ListItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import styles from '../UserInfo.styles';

const { Panel } = Collapse;

interface UserTariffInfoProps {
  selectedUser: any;
  intl: any;
  tariffs: any;
  onTariffUpdate: () => void;
  updateUserUpsellings: (code: string | null) => void;
  onClickTariffFile: () => void;
}

const UserTariffInfo: React.FC<UserTariffInfoProps> = ({
  selectedUser,
  intl,
  tariffs,
  onTariffUpdate,
  updateUserUpsellings,
  onClickTariffFile,
}) => {
  const userTariffAvailableUpsellings = tariffs?.find(
    (tariff: any) => tariff.id === selectedUser?.tariff?.id
  );
  const parsedExtraData = selectedUser?.extraData
    ? JSON.parse(selectedUser.extraData)
    : {};

  return (
    <>
      <div className="user-section-title">
        {intl.formatMessage({ id: `fleetadmin.users.info.tariff` })}
      </div>
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.tariff.label` })}
        leftItem={renderText(selectedUser?.tariff?.name, true)}
        onClickEdit={onTariffUpdate}
      />
      <div className="collapse-container">
        <Collapse bordered={false}>
          <Panel
            header={intl.formatMessage({ id: `fleetadmin.users.tariff.extra` })}
            key="user-id"
          >
            {userTariffAvailableUpsellings?.upsellings?.map(
              (upselling: any) => (
                <ListEditItem
                  key={upselling.code}
                  leftItem={
                    <div className="permission-label">
                      {intl.formatMessage({
                        id: `fleetadmin.users.info.upsellings.${upselling.code}`,
                      })}
                    </div>
                  }
                  rightItem={
                    <Switch
                      checked={selectedUser?.tariff?.upsellings
                        .map((upselling: any) => upselling.code)
                        .includes(upselling.code)}
                      onChange={() =>
                        updateUserUpsellings(
                          selectedUser?.tariff?.upsellings
                            .map((upselling: any) => upselling.code)
                            .includes(upselling.code)
                            ? null
                            : upselling.code
                        )
                      }
                    />
                  }
                />
              )
            )}
            {parsedExtraData &&
              Object.keys(parsedExtraData)
                ?.filter((data) => parsedExtraData?.[data])
                ?.map(
                  (key, index) =>
                    parsedExtraData[key] && (
                      <ListItem
                        key={key}
                        label={intl.formatMessage({
                          id: `Info ${index + 1}`,
                        })}
                        value={renderText(
                          `${intl.formatMessage({
                            id: `fleetadmin.${key.replace(/\s/g, '')}`,
                          })}: ${parsedExtraData[key]}`,
                          true
                        )}
                      />
                    )
                )}
            {['TLA'].includes(selectedUser?.brandCode) && (
              <>
                <ListItem
                  label={intl.formatMessage({
                    id: `fleetadmin.tariff.subscriptionNr`,
                  })}
                  value={renderText(
                    selectedUser?.ebusProductOptions?.[0]?.split(':')?.[1] ||
                      null,
                    true
                  )}
                />
                <div className="tariff-file" onClick={onClickTariffFile}>
                  {intl.formatMessage({ id: `fleetadmin.tariff.file` })}
                </div>
              </>
            )}
          </Panel>
        </Collapse>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserTariffInfo;
