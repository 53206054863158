import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import CarEditorAreaSelector from '../../components/CarEditDrawer/CarEditorAreaSelector';
import styles from './UpdateAreaModal.styles';
import { useIntl } from 'react-intl';
import { isInsideArea } from '../../utils/isInsideArea';
import locationFromString from '../../utils/locationFromString';
import {
  updateCarIfNeeded,
  selectSelectedCar,
} from '../../redux/store/userData/cars';
import { selectAreas } from '../../redux/store/appData/areas';
import {
  getIsVisibleModal,
  closeModal,
  openModal,
} from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';

const UpdateCarStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const [nextArea, setNextArea] = useState(selectedCar?.areaId);
  const visibleModal = useSelector(getIsVisibleModal('updateAreaModal'));
  const visibleConfirmAreaModal = useSelector(
    getIsVisibleModal('confirmAreaModal')
  );
  const currentArea = selectedCar?.areaId;
  const [comment, setComment] = useState('');

  const handleUpdateArea = (
    value: React.SetStateAction<string | undefined>
  ) => {
    setNextArea(value);
  };

  const areas = useSelector(selectAreas);
  const area = areas?.find((areaToFind) => areaToFind.id === nextArea) || null;
  const insideArea = isInsideArea(
    locationFromString(selectedCar?.position!),
    area
  );
  useEffect(() => {
    setComment('');
  }, [visibleModal]);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (insideArea) {
      batch(() => {
        dispatch(
          updateCarIfNeeded(
            token,
            selectedCar?.id!,
            comment
              ? {
                  areaId: nextArea,
                  noteText: comment,
                }
              : {
                  areaId: nextArea,
                }
          )
        );
      });
      dispatch(closeModal('updateAreaModal'));
    } else {
      dispatch(openModal('confirmAreaModal'));
    }
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateAreaModal'));
  };

  const handleOnCancelConfirmArea = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('confirmAreaModal'));
  };

  const handleOnConfirmAreaCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      dispatch(
        updateCarIfNeeded(
          token,
          selectedCar?.id!,
          comment
            ? {
                areaId: nextArea,
                noteText: comment,
              }
            : {
                areaId: nextArea,
              }
        )
      );
    });
    dispatch(closeModal('confirmAreaModal'));
    dispatch(closeModal('updateAreaModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateAreaModal'));
        }}
      >
        <div className="update-car-area-modal">
          <div className="area-selector-wrapper">
            <p className="label">Area</p>
            <CarEditorAreaSelector
              className="car-editor-area-selector"
              defaultValue={nextArea}
              value={
                nextArea !== ''
                  ? nextArea
                  : intl.formatMessage({
                      id: 'fleetadmin.car.areaNotAssigned',
                    })
              }
              onSelect={handleUpdateArea}
              loading={false}
            />
          </div>
          <div className="status-selector-wrapper">
            <p className="label">Comment (Optional)</p>
            <textarea
              className="comment-area"
              placeholder="Type here ..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              draggable={false}
            >
              Type here ...
            </textarea>
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={currentArea === nextArea}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
        <DrawerModal
          visible={visibleConfirmAreaModal}
          onClose={() => {
            dispatch(closeModal('confirmAreaModal'));
          }}
        >
          <div className="update-car-area-modal">
            <p className="label">
              The car is outside the selected area, are you sure ?
            </p>
            <Button
              className="submit-button"
              onClick={handleOnConfirmAreaCancel}
            >
              {intl.formatMessage({
                id: `submit`,
              })}
            </Button>
            <Button
              className="cancel-button"
              onClick={handleOnCancelConfirmArea}
            >
              {intl.formatMessage({
                id: `cancel`,
              })}
            </Button>
          </div>
        </DrawerModal>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateCarStatusModal;
