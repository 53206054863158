import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .wrapper {
    border: 2px solid ${colors.black};
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 4px 8px;
    margin-bottom: 16px;
  }
`;

export default style;
