import { useEffect } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import AuthLayout from '../components/shared/AuthLayout';
import InvalidToken from '../components/InvalidToken';
import SetPasswordForm from '../components/SetPasswordForm';
// import * as gtag from '../libs/gtag';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '../hooks/useQuery';

const isInvalidToken = (token) => !token;

const Password = () => {
  const { token } = useQuery();

  useEffect(() => {
    // gtag.pageview();
  }, []);

  return (
    <AuthLayout
      meta={
        <ReCaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          action="password"
        />
      }
      title={<FormattedMessage id="fleetadmin.password.title" />}
      body={isInvalidToken(token) ? <InvalidToken /> : <SetPasswordForm />}
    />
  );
};

export default Password;
