import React from 'react';
import cn from 'classnames';
import formatDateWithDay from '../../utils/formatDateWithDay';
import styles from './TimeTag.styles';

type TimeTagProps = {
  date: String;
  hideFirstBar?: Boolean;
  isInsideTrip?: Boolean;
};

const TimeTag: React.FC<TimeTagProps> = ({
  date,
  hideFirstBar,
  isInsideTrip,
}) => {
  return (
    <>
      <div className={cn('wrapper', { isInsideTrip })}>
        <div className={cn('first', 'bar', { hidden: hideFirstBar })} />
        <span className="time-tag">{formatDateWithDay(date)}</span>
        <div className="second bar" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default TimeTag;
