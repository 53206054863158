import { Reducer } from '../../../../@types';
import { MapTypes } from '../../../../@types/enums';
import { initialState, MapState } from './initialState';

export const mapReducer: Reducer<MapState> = (state = initialState, action) => {
  switch (action.type as any) {
    case MapTypes.SET_MAP_CENTER:
      return {
        ...state,
        center: {
          ...action.payload,
        },
      };
    case MapTypes.SET_MAP_CENTER_AND_ZOOM:
      return {
        ...state,
        center: {
          ...action.payload.center,
        },

        zoom: action.payload.zoom,
      };
    case MapTypes.TOGGLE_CAR_MARKERS:
      return {
        ...state,
        visibleCarMarkers: !state.visibleCarMarkers,
      };
    case MapTypes.TOGGLE_AREA_POLYGONS:
      return {
        ...state,
        visibleAreaPolygons: !state.visibleAreaPolygons,
      };
    case MapTypes.TOGGLE_PARKING_AREA_POLYGONS:
      return {
        ...state,
        visibleParkingAreasPolygons: !state.visibleParkingAreasPolygons,
      };
    default:
      return state;
  }
};
