import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewType } from '../../hooks/useViewType';
import ColumnPanel from './ColumnPanel';
import styles from './ColumnsSelector.styles';
import isDesktop from '../../utils/sizeHelpers';
import { selectViewType } from '../../redux/store/ui/common/selectors';
import { useIntl } from 'react-intl';

const COLUMN_ICON = '/images/filter/column.svg';
const CLOSE_ICON = '/images/filter/close.svg';

type Props = {};

// @ts-ignore
const ColumnsSelector: React.FC<Props> = () => {
  const intl = useIntl();
  const node = useRef(null);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const viewType = useSelector(selectViewType);

  const handleOnClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setPanelOpen(!isPanelOpen);
  };

  const handleOnClickClose = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setPanelOpen(false);
  };

  const handleOnClickOutSide = (e: { target: any }) => {
    const isClickedInSide =
      // @ts-ignore
      node.current != null && node?.current?.contains(e.target);
    if (!isClickedInSide) {
      setPanelOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutSide);
    };
  }, []);
  return (
    (viewType === ViewType.TABLE || viewType === ViewType.TIME_TABLE) && (
      <>
        <div className="wrapper" ref={node}>
          <div className="add-column-button" onClick={handleOnClick}>
            <img className="column-icon" src={COLUMN_ICON} alt="column icon" />
            <p className="button-text">{isDesktop() ? 'Columns' : ''}</p>
          </div>
          {isPanelOpen && (
            <div className="panel-wrapper">
              <div className="panel-close-button" onClick={handleOnClickClose}>
                <img src={CLOSE_ICON} />
              </div>
              <ColumnPanel />
            </div>
          )}
        </div>
        <style jsx>{styles}</style>
      </>
    )
  );
};

export default ColumnsSelector;
