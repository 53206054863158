import css from 'styled-jsx/css';

const style = css`
  .logo {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
  }
  .logo.disabled {
    opacity: 0.25;
  }
  .none {
    margin-left: 20px;
  }
`;

export default style;
