const tripColumns = [
  { label: 'Trip ID', value: 'code', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Plate No.', value: 'plate', disabled: false },
  { label: 'Contract ID', value: 'merchantRef', disabled: false },
  { label: 'Fleet', value: 'fleetName', disabled: false },
  { label: 'User', value: 'userReferralCode', disabled: false },
  { label: 'Started at', value: 'tripStart', disabled: false },
  { label: 'Finished at', value: 'tripEnd', disabled: false },
  { label: 'Duration', value: 'duration', disabled: false },
  { label: 'Mileage', value: 'distance', disabled: false },
  {
    label: 'Violation: Missing key',
    value: 'violationMissionKey',
    disabled: false,
  },
  {
    label: 'Violation: Missing fuel card',
    value: 'violationMissingFuelCard',
    disabled: false,
  },
  {
    label: 'Violation: Return area',
    value: 'violationReturnArea',
    disabled: false,
  },
  { label: 'Violation: Low fuel', value: 'violationLowFuel', disabled: false },
];

export const defaultSelectedTripColumns = tripColumns.map(
  (tripColumn) => tripColumn.value
);

export default tripColumns;
