import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LoadingIcon from '../../components/shared/LoadingIcon';
import {
  selectSelectedCar,
  resetBoardIfNeeded,
  resetModemIfNeeded,
  resetBluetoothIfNeeded,
  resetDataConnectionIfNeeded,
  selectResettingBoard,
  selectResettingModem,
  selectResettingDataConnection,
  selectResettingBluetooth,
} from '../../redux/store/userData/cars';
import { selectAuth } from '../../redux/store/userData/user';
import {
  deviceItemStyles,
  itemsWrapperStyles,
  loadingStyles,
} from './DeviceFunctions.styles';

type Props = {
  label: string;
  icon: any;
  onClick: (arg: any) => void;
};

const DeviceItem = ({ label, icon, onClick }: Props) => {
  return (
    <>
      <div className="device-item" onClick={onClick}>
        <img
          className="device-item-image"
          src={`/images/car-editor/${icon}`}
          alt={label}
        />
        <p className="device-item-label">
          <FormattedMessage id={`fleetadmin.carEditor.${label}`} />
        </p>
      </div>
      <style jsx>{deviceItemStyles}</style>
    </>
  );
};

const DeviceFunctions = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(selectAuth);
  const selectedCar = useSelector(selectSelectedCar);
  const resettingBoard = useSelector(selectResettingBoard);
  const resettingModem = useSelector(selectResettingModem);
  const resettingDataConnection = useSelector(selectResettingDataConnection);
  const resettingBluetooth = useSelector(selectResettingBluetooth);

  if (
    resettingBoard ||
    resettingModem ||
    resettingDataConnection ||
    resettingBluetooth
  ) {
    return (
      <>
        <div className="loading-wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  const handleClickResetBoard = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(resetBoardIfNeeded(token, selectedCar?.id!));
  };

  const handleClickResetModem = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(resetModemIfNeeded(token, selectedCar?.id!));
  };

  const handleClickResetBluetooth = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(resetBluetoothIfNeeded(token, selectedCar?.id!));
  };

  const handleClickResetDataConnection = (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();
    dispatch(resetDataConnectionIfNeeded(token, selectedCar?.id!));
  };

  const itemsProps = [
    {
      label: 'resetModem',
      icon: 'modem.svg',
      onClick: handleClickResetModem,
    },
    {
      label: 'resetGPRS',
      icon: 'language.svg',
      onClick: handleClickResetDataConnection,
    },
    {
      label: 'resetBoard',
      icon: 'hard-drive.svg',
      onClick: handleClickResetBoard,
    },
    {
      label: 'resetBluetooth',
      icon: 'bluetooth.svg',
      onClick: handleClickResetBluetooth,
    },
  ];

  return (
    <>
      <div className="items-wrapper">
        {itemsProps.map((props, i) => (
          <DeviceItem key={i} {...props} />
        ))}
      </div>
      <style jsx>{itemsWrapperStyles}</style>
    </>
  );
};

export default DeviceFunctions;
