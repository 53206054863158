import React from 'react';
import { SVGOverlay } from 'react-map-gl';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '../../theme/theme';
import sortByPolygonArea from '../../utils/sortByPolygonArea';
import {
  selectHideOnMapArea,
  selectSelectedArea,
  selectDisplayAreas,
} from '../../redux/store/appData/areas';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import { selectAreaWithUIEffect } from '../../redux/store/ui/common';

const getCoordinates = (area: {
  geoJSONData: { features: { geometry: { coordinates: string[] } }[] };
}) => {
  return area.geoJSONData.features[0].geometry.coordinates.map(
    (coordinate: string) => {
      const coordinateArr = coordinate.split(' ');
      return [Number(coordinateArr[0]), Number(coordinateArr[1])];
    }
  );
};

const Areas: React.FC = () => {
  const dispatch = useDispatch();
  const areas = useSelector(selectDisplayAreas);
  const selectedCar = useSelector(selectSelectedCar);
  const selectedArea = useSelector(selectSelectedArea);
  const hideOnMap: any = useSelector(selectHideOnMapArea);
  const filteredAreas = areas?.filter(
    (area) => !hideOnMap.includes(area?.id!) && area.id !== selectedCar?.areaId
  );
  const sortedAreas = sortByPolygonArea(filteredAreas);

  const redraw = ({ project }: any) => {
    return sortedAreas?.map((area) => {
      const selected =
        selectedArea && selectedArea.id && area.id === selectedArea.id;
      const color = selected ? colors.area_selected : colors.area;
      const coordinates = getCoordinates(area);
      const points = coordinates.map((coordinate: any[]) => {
        return project([coordinate[0], coordinate[1]]).join(',');
      });

      const handleOnClick = () => {
        dispatch(selectAreaWithUIEffect(area));
      };

      return (
        <polygon
          key={`area-${area.id}`}
          id={`area-polygon-${area.id}`}
          points={points.join(' ')}
          style={{
            fill: color,
            fillOpacity: 0.2,
            stroke: color,
            strokeOpacity: 1,
            strokeWidth: 2,
            cursor: 'pointer',
          }}
          onClick={handleOnClick}
        />
      );
    });
  };

  return <SVGOverlay redraw={redraw} />;
};

export default Areas;
