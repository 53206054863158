import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './TripReturnTimeEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  selectSelectedTrip,
  updateTrip,
} from '../../../redux/store/userData/trips';
import { isBefore } from 'date-fns';
import { DatePicker, TimePicker } from 'antd/es';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { ANT_PICKERS_FORMAT } from '../../../utils';

const TripReturnTimeEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip) as any;
  const [backBy, setBackBy] = useState(dayjs(selectedTrip?.backBy)) as any;
  const [backByTime, setBackByTime] = useState(
    dayjs(selectedTrip?.backBy)
  ) as any;
  const visibleModal = useSelector(
    getIsVisibleModal('TripReturnTimeEditModal')
  );

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const selectedTime = new Date(backByTime);
    const selectedDate = new Date(backBy);
    dispatch(
      updateTrip(selectedTrip?.id, {
        backBy: new Date(
          selectedDate.setHours(
            selectedTime.getHours(),
            selectedTime.getMinutes()
          )
        ).toISOString(),
      })
    );
    dispatch(closeModal('TripReturnTimeEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('TripReturnTimeEditModal'));
  };

  useEffect(() => {
    if (visibleModal) {
      setBackBy(dayjs(selectedTrip?.backBy));
      setBackByTime(dayjs(selectedTrip?.backBy));
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('TripReturnTimeEditModal'));
        }}
      >
        <div className="trip-return-edit-modal">
          <div className="trip-return-edit-wrapper">
            <div className="trip-return-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.trip.update.backby.title',
                })}
              </p>
            </div>
          </div>
          {intl.formatMessage({
            id: 'fleetadmin.trip.update.backby.label',
          })}
          <DatePicker
            format={ANT_PICKERS_FORMAT}
            getPopupContainer={(triggerNode: HTMLElement) => {
              return triggerNode.parentNode as HTMLElement;
            }}
            defaultValue={dayjs(selectedTrip?.backBy)}
            disabledDate={(date) =>
              // @ts-ignore
              isBefore(new Date(date), new Date())
            }
            style={{ width: '130px', marginLeft: 12, marginBottom: 16 }}
            onChange={(_, dateString) => setBackBy(dateString)}
          />
          <TimePicker
            // @ts-ignore
            getPopupContainer={(triggerNode: HTMLElement) => {
              return triggerNode.parentNode as HTMLElement;
            }}
            format="HH:mm"
            style={{ width: '100px', marginLeft: 12 }}
            defaultValue={dayjs(selectedTrip?.backBy)}
            onChange={(value) => setBackByTime(value)}
            minuteStep={15}
          />
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.update',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.cancel',
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default TripReturnTimeEditModal;
