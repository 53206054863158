import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './TripCancelModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  finishTrip,
  selectSelectedTrip,
} from '../../../redux/store/userData/trips';
import { useIntl } from 'react-intl';

const TripCancelModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip) as any;
  const visibleModal = useSelector(getIsVisibleModal('TripCancelModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(finishTrip(selectedTrip?.id));
    dispatch(closeModal('TripCancelModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('TripCancelModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('TripCancelModal'));
        }}
      >
        <div className="update-phone-modal">
          <div className="phone-modal-wrapper">
            <div className="update-phone-modal-inputs">
              <p className="label">
                Are you sure you want to cancel this trip ?
              </p>
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default TripCancelModal;
