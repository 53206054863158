import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .booking-table-container {
  }
  .booking-table-colsWrapper {
    display: flex;
    flex-direction: row;
  }

  .booking-table-timeColWrapper {
    width: 70px;
    background-color: rgba(158, 158, 158, 0.1);
  }

  .booking-table-timeBoxHeader {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(158, 158, 158, 0.3);
    border-left-width: 0;
    border-top-width: 0;
  }

  .booking-table-timeTextHeader {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(162, 173, 0);
    cursor: pointer;
  }

  .booking-table-timeTextHeaderOBI {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(162, 173, 0);
  }

  .booking-table-timeBox {
    width: 100%;
    height: 31px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .booking-table-timeText {
    position: absolute;
    display: flex;
    top: -12px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .booking-table-timeText-pre {
    margin: 0;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .booking-table-timeTextDashWrapper {
    transform: rotate(-90deg);
    align-items: center;
    justify-content: center;
  }

  .booking-table-timeTextDash {
    position: absolute;
    right: -0.8px;
    top: 0;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(158, 158, 158, 0.4);
  }
  // daysScroll {}
  .booking-table-swiper {
    // flex: 1;
  }

  .booking-table-swiperPage {
    height: 100%;
    display: flex !important;
    flex-direction: row;
  }

  .booking-table-dayColWrapper {
    width: 102px;
    flex: 1;
    align-items: center;
  }

  .booking-table-dayBoxHeader {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(158, 158, 158, 0.3);
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    background-color: rgba(158, 158, 158, 0.1);
  }

  .booking-table-dayTextHeaderMonth {
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.7);
  }

  .booking-table-dayTextHeaderHighlighted {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 0 2px;
    overflow: hidden;
  }

  .booking-table-dayTextHeader {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .booking-table-slotBox {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(158, 158, 158, 0.3);
    border-bottom-width: 0;
    border-left-width: 0;
  }

  .booking-table-fromActiveSlot {
    position: absolute;
    top: 0;
    width: 95%;
    height: 27px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgb(162, 173, 0);
    opacity: 0.5;
    margin-top: 3px;
    cursor: pointer;
  }

  .booking-table-toActiveSlot {
    position: absolute;
    top: 0;
    width: 95%;
    height: 27px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgb(162, 173, 0);
    opacity: 0.5;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .booking-table-betweenActiveSlot {
    position: absolute;
    top: 0;
    width: 95%;
    height: 100%;
    padding: 15px 0;
    background-color: rgb(162, 173, 0);
    opacity: 0.5;
    cursor: pointer;
  }

  .booking-table-greySlot {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    background-color: #9e9e9e;
    opacity: 0.25;
  }
  .booking-table-arrows {
    display: flex;
  }
  .booking-table-arrow-right {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 4px;
  }
  .booking-table-arrow-left {
    width: 16px;
    height: 16px;
    margin: 4px 8px 4px 4px;
    cursor: pointer;
    transform: scaleX(-1);
  }
  .add-booking-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .add-booking-button {
    display: flex;
    margin: 0 16px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: ${colors.black};
    border: 2px solid ${colors.black};
    border-radius: 8px;
    cursor: pointer;
  }
  .button-text {
    font-size: 12px;
    margin-bottom: 0;
  }
  :global(.slick-list) {
    width: 100% !important;
  }
`;

export default style;
