import React from 'react';
import ListEditItem from '../../../shared/ListEditItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import styles from '../UserInfo.styles';

interface UserContactInfoProps {
  selectedUser: any;
  intl: any;
  onEditContact: () => void;
}

const UserContactInfo: React.FC<UserContactInfoProps> = ({
  selectedUser,
  intl,
  onEditContact,
}) => {
  return (
    <>
      <div className="user-section-title">
        {intl.formatMessage({ id: `fleetadmin.users.info.org.contact` })}
      </div>
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.firstname` })}
        leftItem={renderText(selectedUser.firstName, false)}
        verified={selectedUser?.firstNameVerified}
        onClickEdit={onEditContact}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.lastname` })}
        leftItem={renderText(selectedUser.lastName, false)}
        verified={selectedUser?.lastNameVerified}
        onClickEdit={onEditContact}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.phone` })}
        leftItem={renderText(selectedUser.phoneNumber, false)}
        verified={selectedUser?.verified?.phoneNumber}
        onClickEdit={onEditContact}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.email` })}
        leftItem={renderText(selectedUser.email, false)}
        verified={selectedUser?.emailVerified}
        onClickEdit={onEditContact}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default UserContactInfo;
