import React from 'react';
import styles from './ProfileImages.styles';
import ProfileGallery from './ProfileGallery';

const ProfileInfo: React.FC = () => {
  return (
    <>
      <div className="profile-images-wrapper">
        <ProfileGallery />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ProfileInfo;
