import React from 'react';
import {
  alphabeticalSorterFor,
  chronologicalSorterFor,
  getRenderLink,
  renderDateTime,
} from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import { getStatusColor } from '../../utils';
import Tag from 'antd/lib/tag';
import { renderText } from '../shared/DrawerLayout/renderText';

const getColumns = (intl) => {
  // const renderUrl = (pdfUrl: string | URL | undefined) => (
  //   <a onClick={() => window.open(pdfUrl)}>Link</a>
  // );

  const renderStatus = (status) => (
    <Tag color={getStatusColor(status)}>{status}</Tag>
  );

  const renderFleets = (fleets) => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {fleets?.length > 0 ? (
        fleets.map((fleet) => (
          <div
            key={fleet.id}
            className="sqaure-tag"
            style={{ marginBottom: 2 }}
          >
            <Tag>{fleet.name}</Tag>
          </div>
        ))
      ) : (
        <span>-</span>
      )}
    </div>
  );

  const renderCircles = (circles) => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {circles?.length > 0 ? (
        circles.map((circle) => (
          <div
            key={circle.id}
            className="sqaure-tag"
            style={{ marginBottom: 2 }}
          >
            <Tag>{circle.name}</Tag>
          </div>
        ))
      ) : (
        <span>-</span>
      )}
    </div>
  );

  return [
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.name' }),
      dataIndex: 'name',
      ellipsis: true,
      width: isDesktop() ? 170 : 150,
      fixed: 'left' as 'left',
      render: renderText,
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.status' }),
      dataIndex: 'status',
      width: 150,
      render: renderStatus,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.version' }),
      dataIndex: 'version',
      width: 100,
      sorter: alphabeticalSorterFor('version'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.lastmodifiedat' }),
      dataIndex: 'lastModifiedAt',
      width: isDesktop() ? 170 : 150,
      render: renderDateTime,
      sorter: alphabeticalSorterFor('lastModifiedAt'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.validfrom' }),
      dataIndex: 'validFrom',
      width: isDesktop() ? 170 : 150,
      render: renderDateTime,
      sorter: chronologicalSorterFor('validFrom'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.fleets' }),
      dataIndex: 'fleets',
      width: isDesktop() ? 200 : 150,
      render: renderFleets,
      sorter: chronologicalSorterFor('fleets'),
    },
    {
      title: intl.formatMessage({ id: 'fleetadmin.pricing.circles' }),
      dataIndex: 'circles',
      width: isDesktop() ? 300 : 150,
      render: renderCircles,
      sorter: chronologicalSorterFor('circles'),
    },
    // {
    //   title: 'PDF url',
    //   dataIndex: 'pdfUrl',
    //   render: renderUrl,
    //   ellipsis: true,
    //   width: 100,
    // },
  ];
};

export default getColumns;
