import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './UpdateVehicleProfileModal.styles';
import {
  updateCarIfNeeded,
  selectSelectedCar,
} from '../../redux/store/userData/cars';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import {
  fetchProfiles,
  selectProfiles,
} from '../../redux/store/userData/profiles';
import CarEditorProfileSelector from './CarEditorProfileSelector';
import { useIntl } from 'react-intl';

export const updateVehicleProfileModal = 'updateVehicleProfileModal';

const UpdateProfileModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const profiles = useSelector(selectProfiles);
  const [profileId, setProfileId] = useState(selectedCar?.profileId);
  const visibleModal = useSelector(
    getIsVisibleModal(updateVehicleProfileModal)
  );
  const currentProfile = selectedCar?.profileId;

  const handleUpdateProfile = (
    value: React.SetStateAction<string | undefined>
  ) => {
    setProfileId(value);
  };

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      dispatch(
        updateCarIfNeeded(token, selectedCar?.id!, {
          profileId: profileId,
        })
      );
    });
    dispatch(closeModal(updateVehicleProfileModal));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal(updateVehicleProfileModal));
  };

  const loadProfiles = useCallback(async () => {
    dispatch(fetchProfiles());
  }, []);

  useEffect(() => {
    if (visibleModal && !profiles) {
      loadProfiles();
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal(updateVehicleProfileModal));
        }}
      >
        <div className="update-car-profile-modal">
          <div className="profile-selector-wrapper">
            <p className="label">Profile</p>
            <CarEditorProfileSelector
              className="car-editor-profile-selector"
              defaultValue={profileId}
              value={profileId || 'Not assigned'}
              onSelect={handleUpdateProfile}
              loading={false}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={currentProfile === profileId}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateProfileModal;
