import React from 'react';
import { Badge, Popover } from 'antd';
import {
  alphabeticalSorter,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  renderDate,
} from '../shared/TableLayout';
import RenderBoolean from '../shared/RenderBoolean';
import { renderText } from '../shared/DrawerLayout/renderText';
import { IntlShape } from 'react-intl';
import { formatPrice, getStatusColor } from '../../utils';
import Tag from 'antd/lib/tag';
import StatusBadge from '../shared/StatusBadge';
import { GAdminUserDataOutput } from '../../redux/store/userData/users';
import { BrandData } from '../../@types';

type getColumnsArgs = {
  intl: IntlShape;
  selectedBrand: BrandData;
};

const getColumns = ({ intl, selectedBrand }: getColumnsArgs) => {
  const renderEmail = (email: string, record: any) => (
    <span>
      <StatusBadge verified={record.emailVerified} />
      {email.length > 18 ? `${email.substring(0, 18)}...` : email}
    </span>
  );

  const renderUserType = (userType: string) => (
    <span>
      {intl.formatMessage({
        id: `fleetadmin.user.${userType}`,
      })}
    </span>
  );

  const renderPhone = (phone: string, record: any) => (
    <span>
      {phone ? (
        <>
          <StatusBadge verified={record?.verified?.phoneNumber} />
          {phone}
        </>
      ) : (
        '-'
      )}
    </span>
  );
  const renderUserCode = (code: string) => (
    <span style={{ color: '#0077be', cursor: 'pointer' }}>{code}</span>
  );
  const renderPayment = (paymentMethods: any[]) => (
    <RenderBoolean
      value={
        !!paymentMethods?.find(
          (payment: { status: string }) => payment.status === 'verified'
        )
      }
    />
  );

  const renderStatus = (status: string, record) =>
    status ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tag color={getStatusColor(status)}>
          {intl.formatMessage({
            id: `fleetadmin.status.${status}`,
          })}
        </Tag>
        {status === 'blocked' && (
          <Popover
            content={
              <div className="popover">
                {intl.formatMessage({
                  id: `fleetadmin.user.blocked.${
                    record?.blockReasonDetail || record?.blockReason || 'other'
                  }`,
                })}
              </div>
            }
            placement="top"
          >
            <img
              style={
                !record?.blockReason || record?.blockReason === 'other'
                  ? { width: '14px', height: '14px' }
                  : { width: '16px', height: '16px' }
              }
              src={`/images/user-blocked-reasons/${
                record?.blockReasonDetail === 'creditCheck'
                  ? 'creditCheck'
                  : record?.blockReason || 'other'
              }.svg`}
              alt="block reason"
            />
          </Popover>
        )}
      </div>
    ) : (
      '-'
    );

  const renderCreditStatus = (_, record) => {
    const status = record?.creditworthinessData?.creditWorthinessStatus;

    return status ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tag color={getStatusColor(status)}>
          {intl.formatMessage({
            id: `fleetadmin.status.${status}`,
          })}
        </Tag>
        {status === 'blocked' && (
          <Popover
            content={
              <div className="popover">
                {intl.formatMessage({
                  id: `fleetadmin.user.blocked.${
                    record?.blockReasonDetail || record?.blockReason || 'other'
                  }`,
                })}
              </div>
            }
            placement="top"
          >
            <img
              style={
                !record?.blockReason || record?.blockReason === 'other'
                  ? { width: '14px', height: '14px' }
                  : { width: '16px', height: '16px' }
              }
              src={`/images/user-blocked-reasons/${
                record?.blockReasonDetail === 'creditCheck'
                  ? 'creditCheck'
                  : record?.blockReason || 'other'
              }.svg`}
              alt="block reason"
            />
          </Popover>
        )}
      </div>
    ) : (
      '-'
    );
  };

  const renderMerchants = (merchantNo: any[], record: any) => {
    return (
      <div>
        {record?.merchantSystemData?.map(
          (merchant) =>
            merchant?.merchantReference &&
            merchant?.merchantSystem && (
              <Tag
                key={merchant?.merchantReference}
                style={{
                  borderColor: '#d3d3d3',
                  backgroundColor: 'rgba(242, 242, 242, 0.6)',
                  margin: 2,
                }}
                className="merchant-tag"
              >
                {`${merchant?.merchantReference}_${merchant?.merchantSystem}`}
              </Tag>
            )
        )}
      </div>
    );
  };

  const renderIDStatus = (status: any[], record: any) =>
    record?.identityDocuments?.[0]?.status ? (
      <div>
        <Tag color={getStatusColor(record?.identityDocuments?.[0]?.status)}>
          {intl.formatMessage({
            id: `fleetadmin.status.${record?.identityDocuments?.[0]?.status}`,
          })}
        </Tag>
        {record.remainingIDSubmissionAttempts === 0 && (
          <Badge
            style={{
              backgroundColor: '#f5222d',
              bottom: '5px',
              position: 'absolute',
              padding: 0,
            }}
            count={0}
            showZero
            title={intl.formatMessage({
              id: `fleetadmin.users.info.id.submissions`,
            })}
          />
        )}
      </div>
    ) : (
      '-'
    );

  const checkIfNotEmpty = (str: string) => str && str.length > 0;

  const renderPhoneVerified = (phone: any, record: any) => {
    if (record?.phoneNumber?.length > 0) {
      if (record.verified.phoneNumber) {
        return (
          <div>
            <Tag color={getStatusColor('verified')}>
              {intl.formatMessage({
                id: `fleetadmin.status.phone.verified`,
              })}
            </Tag>
          </div>
        );
      } else {
        return (
          <div>
            <Tag color={getStatusColor('listed')}>
              {intl.formatMessage({
                id: `fleetadmin.status.phone.listed`,
              })}
            </Tag>
          </div>
        );
      }
    }
    return (
      <div>
        <Tag color={getStatusColor('open')}>
          {intl.formatMessage({
            id: `fleetadmin.status.phone.open`,
          })}
        </Tag>
      </div>
    );
  };

  const renderAddressStatus = (addressVerified: any, record: any) => {
    if (
      record.address != null &&
      checkIfNotEmpty(record.address.city) &&
      checkIfNotEmpty(record.address.streetName)
    ) {
      if (record.addressVerified) {
        return (
          <div>
            <Tag color={getStatusColor('verified')}>
              {intl.formatMessage({
                id: `fleetadmin.status.address.verified`,
              })}
            </Tag>
          </div>
        );
      } else {
        return (
          <div>
            <Tag color={getStatusColor('listed')}>
              {intl.formatMessage({
                id: `fleetadmin.status.address.listed`,
              })}
            </Tag>
          </div>
        );
      }
    }
    return (
      <div>
        <Tag color={getStatusColor('open')}>
          {intl.formatMessage({
            id: `fleetadmin.status.address.open`,
          })}
        </Tag>
      </div>
    );
  };

  const renderDLStatus = (status: any[], record: any) =>
    record?.licenses?.[0]?.licenseStatus ? (
      <div>
        <Tag color={getStatusColor(record?.licenses?.[0]?.licenseStatus)}>
          {intl.formatMessage({
            id: `fleetadmin.status.${record?.licenses?.[0]?.licenseStatus}`,
          })}
        </Tag>
        {record.remainingLicenseSubmissionAttempts === 0 && (
          <Badge
            style={{
              backgroundColor: '#f5222d',
              bottom: '5px',
              position: 'absolute',
              padding: 0,
            }}
            count={0}
            showZero
            title={intl.formatMessage({
              id: `fleetadmin.users.info.dl.submissions`,
            })}
          />
        )}
      </div>
    ) : (
      '-'
    );

  const getPhoneStatus = (phone: any, record: any) => {
    if (phone && phone.length > 0) {
      if (record.verified.phoneNumber) {
        return 'verified';
      } else {
        return 'listed';
      }
    }
    return 'open';
  };

  const sorterForPhoneStatus = (target: string) => (a: any, b: any) => {
    const targetA = getPhoneStatus(a.phoneNumber, a);
    const targetB = getPhoneStatus(b.phoneNumber, b);
    return alphabeticalSorter(targetA, targetB);
  };

  const getAddressStatus = (record: any) => {
    if (
      record.address &&
      record.address.city &&
      record.address.city.length > 0 &&
      record.address.streetName &&
      record.address.streetName.length > 0
    ) {
      return record.addressVerified ? 'verified' : 'listed';
    }
    return 'open';
  };

  const addressStatusSorter = (target: string) => (a: any, b: any) => {
    const statusA = getAddressStatus(a);
    const statusB = getAddressStatus(b);

    return statusA.localeCompare(statusB);
  };

  return [
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.referralcode',
      }),
      dataIndex: 'referralCode',
      fixed: 'left' as 'left',
      ellipsis: true,
      width: 150,
      render: renderUserCode,
      sorter: alphabeticalSorterFor('referralCode'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.accountstatus',
      }),
      dataIndex: 'accountStatus',
      width: 150,
      render: renderStatus,
      sorter: alphabeticalSorterFor('accountStatus'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.users.info.title',
      }),
      dataIndex: 'title',
      width: 120,
      render: renderText,
      sorter: alphabeticalSorterFor('title'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.email',
      }),
      dataIndex: 'email',
      width: 250,
      render: renderEmail,
      sorter: alphabeticalSorterFor('email'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.usertype',
      }),
      dataIndex: 'userType',
      width: 150,
      render: renderUserType,
      sorter: alphabeticalSorterFor('userType'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.balance',
      }),
      width: 150,
      dataIndex: 'balance',
      render: (value) => formatPrice(value, false, true),
      sorter: alphabeticalSorterFor('balance'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.phonenumber',
      }),
      dataIndex: 'phoneNumber',
      width: 200,
      render: renderPhone,
      sorter: alphabeticalSorterFor('phoneNumber'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.id',
      }),
      dataIndex: 'id',
      width: 250,
      render: renderText,
      sorter: alphabeticalSorterFor('id'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.merchantusereference',
      }),
      dataIndex: 'merchantUserReference',
      width: 250,
      render: renderMerchants,
      sorter: alphabeticalSorterFor('merchantUserReference'),
    },
    ...(['TLA', 'BND', 'FRM', 'CTF', 'SPS'].includes(selectedBrand?.code)
      ? [
          {
            title: intl.formatMessage({
              id: 'fleetadmin.users.info.tariff',
            }),
            dataIndex: ['tariff', 'name'],
            width: 150,
            render: renderText,
          },
        ]
      : []),
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.drivinglicenseno',
      }),
      dataIndex: ['license', 'number'],
      width: 150,
      render: renderText,
      sorter: alphabeticalSorterFor('drivingLicenseno'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.firstname',
      }),
      dataIndex: 'firstName',
      width: 150,
      render: renderText,
      sorter: alphabeticalSorterFor('firstName'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.lastname',
      }),
      dataIndex: 'lastName',
      width: 150,
      render: renderText,
      sorter: alphabeticalSorterFor('lastName'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.created',
      }),
      dataIndex: 'created',
      width: 150,
      render: renderDate,
      sorter: chronologicalSorterFor('created'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.credit',
      }),
      dataIndex: 'creditworthinessData.creditWorthinessStatus',
      width: 150,
      render: renderCreditStatus,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.brandname',
      }),
      dataIndex: 'brandName',
      width: 150,
      render: renderText,
      sorter: alphabeticalSorterFor('brandName'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.identity',
      }),
      dataIndex: 'identityDocuments.[0].status',
      width: 150,
      render: renderIDStatus,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.license',
      }),
      dataIndex: 'licenses.[0].licenseStatus',
      width: 150,
      render: renderDLStatus,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.verified.phonenumber',
      }),
      dataIndex: 'verified.phoneNumber',
      width: 150,
      render: renderPhoneVerified,
      sorter: sorterForPhoneStatus('phoneNumber'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.verified.address',
      }),
      dataIndex: 'verified.address',
      width: 150,
      render: renderAddressStatus,
      sorter: addressStatusSorter('verified.address'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.paymentmethods',
      }),
      dataIndex: 'paymentMethods',
      width: 150,
      render: renderPayment,
    },
  ];
};

export default getColumns;
