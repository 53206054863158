import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Switch } from 'antd';
import styles from './UserLicenseSettingsModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import ListEditItem from '../../shared/ListEditItem';

const UserLicenseSettingsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const [
    remainingLicenseSubmissionAttempts,
    setRemainingLicenseSubmissionAttempts,
  ] = useState(selectedUser?.remainingLicenseSubmissionAttempts);
  const [youngDriverApproved, setYoungDriverApproved] = useState(
    selectedUser?.youngDriverApproved || false
  );

  const visible = useSelector(getIsVisibleModal('LicenseSettingsModal'));

  const handleCancel = () => {
    dispatch(closeModal('LicenseSettingsModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (selectedUser) {
      dispatch(
        updateUser(selectedUser.id, {
          remainingLicenseSubmissionAttempts,
          youngDriverApproved,
        })
      );
    }
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setRemainingLicenseSubmissionAttempts(
        selectedUser?.remainingLicenseSubmissionAttempts || ''
      );
      setYoungDriverApproved(selectedUser?.youngDriverApproved || '');
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-license-settings-contents">
          <div className="user-license-settings-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.drivinglicense`,
            })}
          </div>
          <div className="user-license-settings-input-container">
            <Input
              value={remainingLicenseSubmissionAttempts}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.info.dl.submissions`,
              })}
              onChange={(e) =>
                setRemainingLicenseSubmissionAttempts(e.target.value)
              }
              style={{ marginRight: 12 }}
            />
            <ListEditItem
              leftItem={
                <div className="permission-label">
                  {intl.formatMessage({
                    id: `fleetadmin.users.info.dl.young`,
                  })}
                </div>
              }
              rightItem={
                <Switch
                  checked={youngDriverApproved}
                  onChange={() => {
                    setYoungDriverApproved(!youngDriverApproved);
                  }}
                />
              }
            />
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserLicenseSettingsModal;
