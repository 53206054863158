import { ErrorResponse, PricingTableData } from '../../../../@types';

export interface PricingsState {
  entities: PricingTableData[] | null;
  display: PricingTableData[] | null;
  selected: PricingTableData | null;
  error: ErrorResponse | null;
  loading: boolean;
  brandPricings: { [key: string]: PricingTableData[] };
  loadingBrandPricings: boolean;
  uploadingFile: boolean;
}

export const initialState = {
  entities: null,
  display: null,
  selected: null,
  error: null,
  loading: false,
  brandPricings: {},
  loadingBrandPricings: false,
  uploadingFile: false,
};
