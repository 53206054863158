import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserWallet.styles';
import { useIntl } from 'react-intl';
import {
  fetchUserTransactions,
  selectUserTransactions,
  selectUserTransactionsLoading,
} from '../../../redux/store/userData/users';
import Transaction from './components/Transaction';
import { formatPrice } from '../../../utils';
import formatDateTime from '../../../utils/formatDateTime';
import LoadingIcon from '../../shared/LoadingIcon';
import { Select } from 'antd';

const UseWallet: React.FC<{
  selectedUser: any;
}> = ({ selectedUser }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userTransactions = useSelector(selectUserTransactions);
  const userTransactionsLoading = useSelector(selectUserTransactionsLoading);
  const transactions = userTransactions?.transactions;
  const [walletType, setWalletType] = useState('all');

  const handleChangeType = (value) => {
    setWalletType(value);
    if (!userTransactionsLoading)
      if (value === 'all') {
        dispatch(
          fetchUserTransactions(
            'account',
            selectedUser.id,
            0,
            20,
            false,
            () => {
              dispatch(
                fetchUserTransactions('deposit', selectedUser.id, 0, 20, true)
              );
            }
          )
        );
      } else {
        dispatch(fetchUserTransactions(value, selectedUser.id, 0));
      }
  };

  const loadMoreTransactions = () => {
    if (!userTransactionsLoading)
      if (walletType === 'all') {
        dispatch(
          fetchUserTransactions(
            'account',
            selectedUser.id,
            userTransactions.offset + 20,
            20,
            false,
            () => {
              dispatch(
                fetchUserTransactions(
                  'deposit',
                  selectedUser.id,
                  userTransactions.offset + 20,
                  20,
                  true
                )
              );
            }
          )
        );
      } else {
        dispatch(
          fetchUserTransactions(
            walletType,
            selectedUser.id,
            userTransactions.offset + 20
          )
        );
      }
  };

  useEffect(() => {
    setWalletType('all');
  }, [selectedUser]);

  return (
    <>
      <div className="wallet-section-title" style={{ marginTop: 10 }}>
        {intl.formatMessage({ id: `fleetadmin.users.wallet.wallets` })}
      </div>

      <div className="wallet-balance-wrapper">
        <div
          className="wallet-balance-value"
          style={userTransactions?.userBalance < 0 ? { color: 'darkred' } : {}}
        >
          {userTransactionsLoading && !userTransactions ? (
            <LoadingIcon />
          ) : (
            formatPrice(userTransactions?.userBalance || 0, false)
          )}
        </div>
        <div className="wallet-balance-title">
          {intl.formatMessage({ id: `fleetadmin.wallet.balance` })}
        </div>
      </div>

      <div className="wallet-balance-wrapper">
        <div
          className="wallet-balance-value"
          style={
            userTransactions?.accountDeposit < 0 ? { color: 'darkred' } : {}
          }
        >
          {userTransactionsLoading && !userTransactions ? (
            <LoadingIcon />
          ) : (
            formatPrice(userTransactions?.accountDeposit || 0, false)
          )}
        </div>
        <div className="wallet-balance-title">
          {intl.formatMessage({ id: `fleetadmin.users.wallet.deposit` })}
        </div>
      </div>

      <div className="wallet-section-transactions">
        <div className="wallet-section-title" style={{ margin: 0 }}>
          {intl.formatMessage({ id: `fleetadmin.wallet.transaction` })}
        </div>
        <Select
          value={walletType}
          style={{ width: 150 }}
          onChange={handleChangeType}
          options={[
            {
              value: 'all',
              label: intl.formatMessage({
                id: `fleetadmin.users.wallet.all`,
              }),
            },
            {
              value: 'account',
              label: intl.formatMessage({
                id: `fleetadmin.users.wallet.account`,
              }),
            },
            {
              value: 'deposit',
              label: intl.formatMessage({
                id: `fleetadmin.users.wallet.deposit`,
              }),
            },
          ]}
        />
      </div>

      {!userTransactionsLoading && transactions?.length === 0 && (
        <div className="empty-wrapper">
          <img
            className="empty"
            src="/images/table/empty.svg"
            width="50"
            height="50"
          />
          {intl.formatMessage({ id: `fleetadmin.users.info.empty` })}
        </div>
      )}

      {userTransactionsLoading && !userTransactions ? (
        <div className="empty-wrapper">
          <LoadingIcon />
        </div>
      ) : (
        <div style={{ padding: '0 24px 24px 24px' }}>
          {transactions?.length > 0 &&
            transactions?.map((transaction, index) => {
              const currentMonth = formatDateTime(
                transaction.date,
                'YYYY - MM'
              );
              const previousMonth =
                index > 0
                  ? formatDateTime(transactions[index - 1].date, 'YYYY - MM')
                  : null;

              return (
                <div key={index}>
                  {index !== 0 && currentMonth !== previousMonth && (
                    <div className="wallet-day-tag">{currentMonth}</div>
                  )}
                  <Transaction
                    intl={intl}
                    index={index}
                    amount={transaction.amount}
                    date={formatDateTime(transaction.date, 'DD.MM HH:MM')}
                    headerTitle={intl.formatMessage({
                      id: `fleetadmin.wallet.${transaction.transactionType}`,
                    })}
                    headerValue=""
                    transaction={transaction}
                  />
                </div>
              );
            })}

          {transactions?.length < userTransactions?.totalCount && (
            <div className="load-more" onClick={loadMoreTransactions}>
              Load more... {userTransactionsLoading && <LoadingIcon />}
            </div>
          )}
        </div>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default UseWallet;
