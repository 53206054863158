const getBrandCodeById = (brands: any[] | null, brandId: any) => {
  if (!Array.isArray(brands)) {
    return brandId;
  }

  const theBrand = brands.find((brand) => brand.id === brandId);
  return theBrand ? theBrand.code : brandId;
};

export default getBrandCodeById;
