import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const loadingStyles = css`
  .loading-wrapper {
    width: 100%;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const deviceItemStyles = css`
  .device-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 23%;
    height: 100%;
    border: 1px solid #f1f1f1;
    padding: 10px 5px 5px;
    cursor: pointer;
  }
  .device-item-label {
    font-family: Avenir Next;
    font-size: 12px;
    margin: 7px 0 0 0;
    font-weight: 600;
    color: ${colors.blue};
  }
  .device-item-image {
    height: 24px;
  }
`;

export const itemsWrapperStyles = css`
  .items-wrapper {
    display: flex;
    height: 85px;
    justify-content: space-between;
    align-items: center;
    background: white;
    text-align: center;
    margin: -18px 24px 24px;
  }
`;
