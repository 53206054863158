import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import { Table } from 'antd';
import {
  selectDisplayColumnsForPricing,
  selectPricingWithUIEffect,
} from '../../redux/store/ui/common';
import {
  fetchPricingsIfNeeded,
  selectDisplayPricings,
  selectPricings,
  selectSelectedPricing,
} from '../../redux/store/appData/pricings';
import { PricingTableData } from '../../@types';
import getFeeItemColumns from './getFeeItemColumns';
import { useIntl } from 'react-intl';
import { getFilteredColumns } from '../../utils';

const PricingsTable = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const displayPricings: any[] = useSelector(selectDisplayPricings);
  const pricingsEntities = useSelector(selectPricings);
  const selectedPricing: any = useSelector(selectSelectedPricing);
  const displayColumns = useSelector(selectDisplayColumnsForPricing);
  const selectedId = selectedPricing ? selectedPricing.id : null;
  const columns = getColumns(intl);
  const feeItemColumns = getFeeItemColumns();
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: PricingTableData) => {
    dispatch(selectPricingWithUIEffect(record));
  };

  const loadPricings = useCallback(async () => {
    dispatch(fetchPricingsIfNeeded());
  }, []);

  useEffect(() => {
    if (!pricingsEntities) {
      loadPricings();
    }
  }, []);

  return (
    <TableLayout
      rowKey="pricing"
      columns={filteredColumns}
      dataSource={displayPricings}
      selectedId={selectedId}
      totalNumber={displayPricings?.length}
      onClickRow={onClickRow}
      expandedRowRender={(record) => (
        <Table
          columns={feeItemColumns}
          dataSource={[...record.feeItems].sort((a, b) => {
            let numberA = parseInt(a.code?.substring(1), 10);
            let numberB = parseInt(b.code?.substring(1), 10);
            return numberA - numberB;
          })}
          pagination={false}
        />
      )}
    />
  );
};

export default PricingsTable;
