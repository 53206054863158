import css from 'styled-jsx/css';
import { zIndex } from '../theme/theme';

const style = css`
  .logoutModal {
    z-index: ${zIndex.logoutModal};
  }
  .side-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
  }
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .closebtn {
    font-size: 35px;
    color: white;
  }
  .side-menu-header-logo-wrapper {
    height: unset;
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }
  .side-menu-header-logo {
    margin-left: 15px;
    width: 50%;
    height: 100%;
  }
  .footer {
    margin-top: auto;
    background: #666666;
    padding-bottom: 16px;
  }
  .menu-items {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .menu-item {
    font-size: 14px;
    padding: 8px 16px;
    color: #ffffff;
    cursor: pointer;
  }
  .menu-item.menu-item-title {
    color: darkgrey;
    cursor: default;
  }
  .menu-item.sub-menu {
    padding: 4px 16px 4px 32px;
  }
  .menu-item.sub-menu-2 {
    padding: 4px 16px 4px 48px;
  }
  .menu-item.sub-menu-item-title {
    color: darkgrey;
    cursor: default;
    padding: 4px 16px 4px 32px;
  }
  .menu-item.active {
    color: #2f2e2e;
    background: #bff056;
  }
  .menu-item.coming-soon {
    color: #676767;
    cursor: not-allowed;
    display: flex;
    justify-content: space-between;
  }
  .section-name {
    padding-top: 16px;
    color: rgba(255, 255, 255, 0.48);
    font-weight: bold;
    cursor: default;
  }
  .username {
    padding: 16px;
    padding-bottom: 0;
    color: white;
    margin: 0;
    cursor: pointer;
  }
  .version {
    color: #9e9e9e;
    font-size: 13px;
    text-align: right;
    margin-bottom: 4px;
    margin-right: 4px;
  }
  @media only screen and (min-width: 768px) {
    .side-menu-header-logo-wrapper {
      height: 64px;
      padding: 12px 16px;
    }
    .side-menu-header-logo {
      height: 100%;
      width: auto;
    }
    .closebtn {
      display: none;
    }
    .side-menu {
      padding-top: 0px;
      position: fixed;
      top: 0;
      left: 0;
      width: 200px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      background: #333333;
    }
    .footer {
      margin-top: auto;
      background: #666666;
      padding-bottom: 16px;
    }
    .menu-items {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .menu-item {
      font-size: 14px;
      padding: 8px 16px;
      color: #ffffff;
      cursor: pointer;
    }
    .menu-item.active {
      color: #2f2e2e;
      background: #bff056;
    }
    .menu-item.coming-soon {
      color: #676767;
      cursor: not-allowed;
      display: flex;
      justify-content: space-between;
    }
    .section-name {
      padding-top: 16px;
      color: rgba(255, 255, 255, 0.48);
      font-weight: bold;
      cursor: default;
    }
    .username {
      padding: 16px;
      padding-bottom: 0;
      color: white;
      margin: 0;
      cursor: pointer;
    }
    .beta {
      margin-bottom: 0;
      margin-left: 10px;
      background-color: rgb(226, 87, 71);
      border-radius: 2px;
      padding: 2px 4px;
      font-size: 10px;
      line-height: 11px;
      color: white;
    }
  }
`;

export default style;
