import { RootState } from '../../../config';
import { FleetServiceTripData } from '../../../../@types';

export const selectCares = (state: RootState): FleetServiceTripData[] | null =>
  state.userData.cares.entities;

export const selectDisplayCares = (
  state: RootState
): FleetServiceTripData[] | null => state.userData.cares.display;

export const selectSelectedCare = (
  state: RootState
): FleetServiceTripData | null => state.userData.cares.selected;

export const selectFinishServiceTripViolations = (state: RootState): string[] =>
  state.userData.cares.finishTripViolations;

export const selectCaresLoading = (state: RootState): boolean =>
  state.userData.cares.loading;
