import css from 'styled-jsx/css';
import { colors } from '../../../theme/theme';

const style = css`
  .wallet-section-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px 16px 24px;
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wallet-balance-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0 24px 16px 24px;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 8px 16px;
  }
  .wallet-balance-value {
    font-weight: 700;
  }
  .wallet-balance-title {
    color: gray;
  }

  .wallet-day-tag {
    font-weight: bold;
    border: 1px solid gray;
    width: 60px;
    font-size: 10px;
    border-radius: 2px;
    text-align: center;
    margin-left: 70px;
  }
  .load-more {
    display: inline-flex;
    align-items: center;
    height: 26px;
    margin: 20px 0;
    padding: 3px 10px;
    color: ${colors.black};
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    border: solid 2px ${colors.black};
    cursor: pointer;
  }
  .empty-wrapper {
    margin-top: 50px;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  .wallet-section-transactions {
    display: flex;
    justify-content: space-between;
    margin-right: 24px;
    align-items: flex-start;
    margin-top: 36px;
  }
`;

export default style;
