export const alphabeticalSorter = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const getUpperCase = (value: string) =>
  typeof value === 'string' ? value.toUpperCase() : value;

export const alphabeticalSorterFor = (target: string) => (a: any, b: any) => {
  const targetA = getUpperCase(a[target]) || '';
  const targetB = getUpperCase(b[target]) || '';
  return alphabeticalSorter(targetA, targetB);
};
