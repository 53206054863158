import { FleetAdminCarData } from '../@types';

export const getVehicleById = (
  vehicles: FleetAdminCarData[] | null,
  id: string | undefined
): FleetAdminCarData | undefined => {
  if (id) {
    return vehicles?.find((vehicle) => vehicle.id === id);
  }
  return undefined;
};
