import { FleetAdminCarData, Reducer } from '../../../../@types';
import { CarsTypes } from '../../../../@types/enums';
import { initialState, CarsState } from './initialState';
// @ts-ignore
export const carsReducer: Reducer<CarsState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case CarsTypes.CARS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarsTypes.ACTIVE_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarsTypes.TIMELINE_STATUS_CHANGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarsTypes.ARCHIVED_CARS_REQUEST:
      return {
        ...state,
        loadingArchivedCars: true,
      };
    case CarsTypes.CARS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case CarsTypes.ARCHIVED_CARS_SUCCESS:
      return {
        ...state,
        archivedCars: action.payload,
        loadingArchivedCars: false,
      };
    case CarsTypes.CARS_FAILURE:
      return {
        ...state,

        error: action.error,
        loading: false,
        loadingArchivedCars: false,
      };
    case CarsTypes.ACTIVE_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CarsTypes.TIMELINE_STATUS_CHANGES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CarsTypes.CAR_REQUEST:
      return {
        ...state,
        loadingCar: true,
      };
    case CarsTypes.CAR_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities!.map((entity) =>
            entity.id === action.payload.id ? action.payload : entity
          ),
        ],
        selected: action.payload,
        loadingCar: false,
      };
    case CarsTypes.ACTIVE_BOOKINGS_SUCCESS:
      return {
        ...state,
        activeBookings: action.payload,
        loading: false,
      };
    case CarsTypes.TIMELINE_STATUS_CHANGES_SUCCESS:
      return {
        ...state,
        timelineStatusChanges: action.payload,
        loading: false,
      };
    case CarsTypes.CAR_FAILURE:
      return {
        ...state,

        error: action.error,
        loadingCar: false,
      };
    case CarsTypes.SELECT_CAR:
      return {
        ...state,
        selected: action.payload,
      };
    case CarsTypes.SET_CAR_ACTIVE_BOOKINGS:
      return {
        ...state,
        carActiveBookings: action.payload,
      };
    case CarsTypes.SELECT_VEHICLES_SUBMENU:
      return {
        ...state,
        selectedSubmenu: action.payload,
      };
    case CarsTypes.SET_CAR_DAMAGES:
      return {
        ...state,
        carDamages: [
          ...action.payload.map((item: any, index: number) => ({
            ...item,
            index: index + 1,
          })),
        ],
      };
    case CarsTypes.SET_DISPLAY_CARS:
      return {
        ...state,
        display: action?.payload
          ? [
              ...action?.payload?.filter(
                (car: FleetAdminCarData) => car?.status !== 'TERMINATED'
              ),
              ...action?.payload?.filter(
                (car: FleetAdminCarData) => car?.status === 'TERMINATED'
              ),
            ]
          : [],
      };
    case CarsTypes.CAR_CONDITIONS_REQUEST:
      return {
        ...state,
        conditions: null,
        loadingConditions: true,
      };
    case CarsTypes.CAR_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: action.payload,
        loadingConditions: false,
      };
    case CarsTypes.UPDATE_LAST_CARS_STATUS:
      return {
        ...state,
        lastCarsStatus: {
          ...state.lastCarsStatus,

          [action.payload.carId]: {
            ...state.lastCarsStatus[action.payload.carId],
            ...action.payload,
          },
        },
      };
    case CarsTypes.CAR_CONDITIONS_FAILURE:
      return {
        ...state,
        loadingConditions: false,
      };
    case CarsTypes.UPDATE_CAR_STATUS_REQUEST:
      return {
        ...state,
        updatingStatus: true,
      };
    case CarsTypes.UPDATE_CAR_STATUS_SUCCESS:
      return {
        ...state,
        entities: state.entities!.map((entity) => {
          if (entity.id === action.payload.id) {
            return action.payload;
          }
          return entity;
        }),
        display: state.display!.map((car) => {
          if (car.id === action.payload.id) {
            return action.payload;
          }
          return car;
        }),
        selected: action.payload,
        updatingStatus: false,
      };
    case CarsTypes.UPDATE_CAR_STATUS_FAILURE:
      return {
        ...state,
        updatingStatus: false,

        updatingStatusError: action.error,
      };
    case CarsTypes.UNLOCK_CAR_REQUEST:
      return {
        ...state,
        unlocking: true,
      };
    case CarsTypes.UNLOCK_CAR_SUCCESS:
      return {
        ...state,
        unlocking: false,
      };
    case CarsTypes.UNLOCK_CAR_FAILURE:
      return {
        ...state,
        unlocking: false,
      };
    case CarsTypes.RESET_BOARD_REQUEST:
      return {
        ...state,
        resettingBoard: true,
      };
    case CarsTypes.RESET_BOARD_SUCCESS:
      return {
        ...state,
        resettingBoard: false,
      };
    case CarsTypes.RESET_BOARD_FAILURE:
      return {
        ...state,
        resettingBoard: false,
      };
    case CarsTypes.RESET_MODEM_REQUEST:
      return {
        ...state,
        resettingModem: true,
      };
    case CarsTypes.RESET_MODEM_SUCCESS:
      return {
        ...state,
        resettingModem: false,
      };
    case CarsTypes.RESET_MODEM_FAILURE:
      return {
        ...state,
        resettingModem: false,
      };
    case CarsTypes.RESET_BLUETOOTH_REQUEST:
      return {
        ...state,
        resettingBluetooth: true,
      };
    case CarsTypes.RESET_BLUETOOTH_SUCCESS:
      return {
        ...state,
        resettingBluetooth: false,
      };
    case CarsTypes.RESET_BLUETOOTH_FAILURE:
      return {
        ...state,
        resettingBluetooth: false,
      };
    case CarsTypes.RESET_DATA_CONNECTION_REQUEST:
      return {
        ...state,
        resettingDataConnection: true,
      };
    case CarsTypes.RESET_DATA_CONNECTION_SUCCESS:
      return {
        ...state,
        resettingDataConnection: false,
      };
    case CarsTypes.RESET_DATA_CONNECTION_FAILURE:
      return {
        ...state,
        resettingDataConnection: false,
      };
    case CarsTypes.SET_CAR_DRAWER_ACTIVE_TAB:
      return {
        ...state,
        drawerActiveTab: action.payload,
      };
    default:
      return state;
  }
};
