import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import styles from './UserPaymentModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { selectAuth } from '../../../redux/store/userData/user';
import { PaymentTypeSwitch } from '../UserInfoTab/components/PaymentTypeSwitch';

const UserPaymentModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const [isSepaBlocked, setIsSepaBlocked] = useState(false);
  const [isCardBlocked, setIsCardBlocked] = useState(false);
  const [isPayInBlocked, setIsPayInBlocked] = useState(false);
  const [isPayPalBlocked, setIsPayPalBlocked] = useState(false);
  const user = useSelector(selectAuth);
  const selectedBrand = user.brands.find(
    (it) => it.id === selectedUser?.brandId
  );

  const visible = useSelector(getIsVisibleModal('UserPaymentModal'));

  const handleCancel = () => {
    dispatch(closeModal('UserPaymentModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (selectedUser) {
      const blockedPaymentTypes = [
        isPayPalBlocked && 'paypal',
        isCardBlocked && 'card',
        isPayInBlocked && 'payIn',
        isSepaBlocked && 'sepa',
      ].filter(Boolean);
      dispatch(updateUser(selectedUser.id, { blockedPaymentTypes }));
    }
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setIsCardBlocked(selectedUser?.blockedPaymentTypes?.includes('card'));
      setIsSepaBlocked(selectedUser?.blockedPaymentTypes?.includes('sepa'));
      setIsPayInBlocked(selectedUser?.blockedPaymentTypes?.includes('payIn'));
      setIsPayPalBlocked(selectedUser?.blockedPaymentTypes?.includes('paypal'));
    }
  }, [visible]);

  const isPaymentMethodSelected = (paymentType: string) => {
    switch (paymentType) {
      case 'card':
        return !isCardBlocked;
      case 'sepa':
        return !isSepaBlocked;
      case 'paypal':
        return !isPayPalBlocked;
      case 'payIn':
        return !isPayInBlocked;
    }
    return false;
  };

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-payment-contents">
          <div className="user-payment-title">
            {intl.formatMessage({
              id: `fleetadmin.users.info.payment.settings`,
            })}
          </div>
          <div className="user-payment-input-container">
            {selectedBrand?.allowedPaymentTypes?.map((paymentType) => {
              return (
                <PaymentTypeSwitch
                  key={paymentType}
                  paymentType={paymentType}
                  isSelected={isPaymentMethodSelected(paymentType)}
                  onChange={() => {
                    switch (paymentType) {
                      case 'payIn':
                        setIsPayInBlocked(!isPayInBlocked);
                        break;
                      case 'card':
                        setIsCardBlocked(!isCardBlocked);
                        break;
                      case 'paypal':
                        setIsPayPalBlocked(!isPayPalBlocked);
                        break;
                      case 'sepa':
                        setIsSepaBlocked(!isSepaBlocked);
                        break;
                    }
                  }}
                />
              );
            })}
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserPaymentModal;
