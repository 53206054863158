import React from 'react';
import Selection from './Selection';

const options = [
  { label: 'True', value: 'true', disabled: false },
  { label: 'False', value: 'false', disabled: false },
  { label: 'Unknown', value: 'unknown', disabled: false },
];

type BooleanSelectionProps = {
  defaultValue?: any[];
  setValue: (arg: any) => void;
};

const BooleanSelection: React.FC<BooleanSelectionProps> = ({
  setValue,
  defaultValue,
}) => {
  return (
    <Selection
      options={options}
      withInput={false}
      setValue={setValue}
      defaultValue={defaultValue}
    />
  );
};

export default BooleanSelection;
