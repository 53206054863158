import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Modal, Input, Button } from 'antd';
import styles from './CreateAreaModal.styles';
import { colors } from '../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user/selectors';
import { selectFleets } from '../../redux/store/appData/fleets';
import { createAreaIfNeeded } from '../../redux/store/appData/areas';
import SelectorLayout from '../../components/shared/SelectorLayout';
import { InputAreaData } from '../../@types';
import { uniqueArrayOfObjects } from '../../utils/brandHelpers';
import { useIntl } from 'react-intl';

const CreateAreaModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const fleets = useSelector(selectFleets);
  const brands = useMemo(
    () =>
      fleets
        ? uniqueArrayOfObjects(
            fleets.map((fleet) => ({
              id: fleet.brandId,
              name: fleet.brandName,
            }))
          )
        : [],
    [fleets]
  );
  const brandFleets: any = useMemo(() => {
    const fleetsPerBrand = {};
    brands
      .map((brand) => brand.id)
      .forEach((brandId) => {
        // @ts-ignore
        fleetsPerBrand[brandId] = fleets.filter(
          (fleet) => fleet.brandId === brandId
        );
      });
    return fleetsPerBrand;
  }, [brands]);

  const brandOptions =
    brands.length > 0
      ? brands.map((brand) => ({
          label: brand.name,
          value: brand.id,
        }))
      : [];

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedFleet, setSelectedFleet] = useState(null) as any;

  useEffect(() => {}, [selectedBrand]);

  const fleetOptions =
    // @ts-ignore
    brandFleets && brandFleets[selectedBrand]
      ? brandFleets[selectedBrand!].map((fleet: { name: any; id: any }) => ({
          label: fleet.name,
          value: fleet.id,
        }))
      : [];

  const visible = useSelector(getIsVisibleModal('createAreaModal'));
  const isDisabled = !(name && description);

  const handleCancel = () => {
    dispatch(closeModal('createAreaModal'));
  };

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      const inputAreaData: InputAreaData = {
        name,
        description,
        brandId: selectedBrand!,
        fleetId: selectedFleet!,
      };
      dispatch(createAreaIfNeeded(token, inputAreaData));
    });
    handleCancel();
  };

  const onBrandChanged = (value: any) => {
    setSelectedBrand(value);
    setSelectedFleet(null);
  };

  useEffect(() => {
    if (visible) {
      setName('');
      setDescription('');
      const brand = brands ? brands[0].id : null;
      setSelectedBrand(brand);
      setSelectedFleet(
        brand && brandFleets[brand].length > 0
          ? brandFleets[brand][0]?.id
          : null
      );
    }
  }, [visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-area-wrapper"
          title={`Create new area`}
          visible={visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-area-contents" tabIndex={0}>
            <div className="inputs-container">
              <Input
                value={name}
                placeholder="Area name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="inputs-container">
              <Input
                value={description}
                placeholder="Area description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="inputs-container">
              <SelectorLayout
                keyPrefix="brand-select"
                value={selectedBrand}
                placeholder="Select Brand"
                onSelect={onBrandChanged}
                options={brandOptions}
              />
            </div>
            <div className="inputs-container">
              <SelectorLayout
                keyPrefix="fleet-select"
                value={selectedFleet}
                placeholder="Select Fleet"
                onSelect={setSelectedFleet}
                options={fleetOptions}
              />
            </div>
            <div className="checkbox-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default CreateAreaModal;
