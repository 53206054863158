import css from 'styled-jsx/css';

const style = css`
  .number-search {
    padding: 16px;
  }
  .number-search :global(.input) {
    width: 100%;
  }
  .number-search :global(.radio) {
    display: block;
    margin-bottom: 10px;
  }
`;

export default style;
