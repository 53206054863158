import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';

const InvalidToken = () => (
  <>
    <p>
      <FormattedMessage id="fleetadmin.password.invalidToken" />
    </p>
    <p>
      <Link to="/forgot">
        <a>
          <FormattedMessage id="fleetadmin.password.linkToForgot" />
        </a>
      </Link>
    </p>
  </>
);

export default InvalidToken;
