import css from 'styled-jsx/css';

const style = css`
  .pricing-info {
    margin: 0;
  }
  :global(.upload-pdf.ant-btn) {
    padding-right: 0;
  }
  .pricing-info-last-update-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .pricing-info-last-update-at {
    font-size: 14px;
    color: grey;
  }
  .pricing-label {
    padding: 10px 24px 5px 24px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  :global(.ant-select-disabled .ant-select-selection-item) {
    background: #fafafa !important;
    border: 1px solid #d9d9d9 !important;
    color: rgb(0, 0, 0, 0.6) !important;
  }
  :global(
      .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
        .ant-select-selector
    ) {
    background: white !important;
  }
`;

export default style;
