import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  a {
    color: ${colors.blue};
  }
`;

export default style;
