import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectDisplayColumnsForReceipts,
  selectReceiptWithUIEffect,
} from '../../redux/store/ui/common';
import {
  generateReceiptsCSV,
  selectDisplayReceipts,
  selectSelectedReceipt,
  selectTotalReceipts,
} from '../../redux/store/userData/receipts';
import { useIntl } from 'react-intl';
import { fetchReceipts } from '../../redux/store/userData/receipts/thunk';
import { Modal, Button, Checkbox } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { extractFileName } from '../../utils/fileHelpers';
import { common, zIndex } from '../../theme/theme';
import { selectAuth } from '../../redux/store/userData/user';
import moment from 'moment/moment';
import { getFilteredColumns } from '../../utils';
import withPaginatedQueryFetch from '../../hoc/withPaginatedQueryFetch';

const ReceiptsTable = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayReceipts: any[] = useSelector(selectDisplayReceipts);
  const admin = useSelector(selectAuth);
  const receiptsNo = useSelector(selectTotalReceipts);
  const selectedReceipts: any = useSelector(selectSelectedReceipt);
  const displayColumns = useSelector(selectDisplayColumnsForReceipts);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [includeReceiptsCopy, setIncludeReceiptsCopy] = useState(false);

  const selectedId = selectedReceipts ? selectedReceipts.id : null;
  const columns = getColumns({ intl });
  const filteredColumns = getFilteredColumns(columns, displayColumns);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickRow = (record: any) => {
    dispatch(selectReceiptWithUIEffect(record));
  };

  const addCsvToZip = async (zip, ids) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        generateReceiptsCSV({ ids }, async (csvResponse) => {
          if (csvResponse.ok) {
            const csvBlob = await csvResponse.blob();
            if (csvBlob.size > 0) {
              const filename = extractFileName(csvResponse);
              if (filename && filename !== 'undefined') {
                zip.file(filename, csvBlob);
                resolve(); // Resolve after adding to ZIP
              }
            }
          } else {
            reject('CSV response not OK.');
          }
        })
      );
    });
  };

  const onDownloadReceiptsAsZip = async () => {
    try {
      setLoading(true);
      const zip = new JSZip();
      const ids = selectedRows.map((row) => row.id);

      for (const row of selectedRows) {
        const response = await fetch(row.receiptUrl);
        const blob = await response.blob();
        const filename = extractFileName(response);
        if (filename) zip.file(filename, blob);

        if (includeReceiptsCopy) {
          const copyResponse = await fetch(`${row.receiptUrl}?copy=true`);
          const copyBlob = await copyResponse.blob();
          const copyFilename = extractFileName(copyResponse);
          if (copyFilename) zip.file(copyFilename, copyBlob);
        }
      }

      // Generate and add CSV to the zip
      await addCsvToZip(zip, ids);

      // Generate zip file and trigger download
      const content = await zip.generateAsync({ type: 'blob' });
      setLoading(false);
      saveAs(
        content,
        `${moment().format('YYYY_MM_DD')}_${admin?.merchant?.code}_receipts.zip`
      );
      setSelectedRows([]);
      setDownloadModalVisible(false);
    } catch (error) {
      console.error('Failed to download receipts zip:', error);
    }
  };

  return (
    <>
      {selectedRows?.length > 0 && (
        <div
          style={{
            marginLeft: 32,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {/*// @ts-ignore*/}
          <Button
            type="primary"
            size="small"
            style={{
              marginRight: 8,
            }}
            onClick={() => setDownloadModalVisible(true)}
          >
            {!loading && <DownloadOutlined style={{ color: 'white' }} />}
          </Button>
          {`Selected ${selectedRows?.length} items`}
        </div>
      )}
      <TableLayout
        rowKey="receipt"
        columns={filteredColumns}
        dataSource={displayReceipts}
        selectedId={selectedId}
        totalNumber={receiptsNo}
        onClickRow={onClickRow}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        current={props.pageNumber}
        onPageChanged={props.onPageChanged}
      />
      <Modal
        wrapClassName="download-receipt-wrapper"
        title={`${selectedRows?.length} ${intl.formatMessage({
          id: 'fleetadmin.receipts.download.title',
        })}`}
        visible={downloadModalVisible}
        width={400}
        style={{ top: 'calc(30%)' }}
        onCancel={() => setDownloadModalVisible(false)}
        footer={null}
      >
        <Checkbox
          checked={includeReceiptsCopy}
          onChange={(e) => setIncludeReceiptsCopy(e?.target?.checked)}
          style={{ padding: '4px 0', margin: 0 }}
        >
          {intl.formatMessage({
            id: `fleetadmin.receipts.download.checkbox.copy`,
          })}
        </Checkbox>
        <Button
          className="submit-button"
          loading={loading}
          onClick={onDownloadReceiptsAsZip}
        >
          {intl.formatMessage({
            id: `download`,
          })}
        </Button>
      </Modal>
      <style jsx global>
        {`
          .download-receipt-wrapper {
            z-index: ${zIndex.allAroundModal};
          }
          .download-receipt-wrapper :global(.submit-button) {
            ${common.button}
            ${common.submitButton}
            margin-top: 16px;
          }
        `}
      </style>
    </>
  );
};

export default withPaginatedQueryFetch(ReceiptsTable, fetchReceipts);
