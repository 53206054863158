import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .timeline-wrapper {
    margin: 0 24px 24px 8px;
  }
  .timeline-filter-button {
    align-items: center;
    justify-content: center;
    border: solid 2px ${colors.black};
    background-color: ${colors.white};
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-flex;
    margin: 0;
    margin-right: 16px;
    padding: 3px 10px;
    color: ${colors.black};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
`;

export default style;
