import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .update-car-plate-modal {
    width: 320px;
    padding: 16px;
  }
  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .update-car-plate-wrapper {
    margin-bottom: 8px;
  }
  .update-car-plate-modal :global(textarea.text-area) {
    resize: none;
    border: solid 1px #676767;
    border-radius: 4px;
    font-size: 16px;
  }
  .update-car-plate-modal :global(.submit-button) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .update-car-plate-modal :global(.submit-button:hover) {
    margin-top: 24px;
    ${common.button}
    ${common.submitButton}
  }
  .update-car-plate-modal :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .update-car-plate-modal :global(.cancel-button:hover) {
    ${common.button}
    ${common.cancelButton}
  }
`;

export default style;
