import React from 'react';
import cn from 'classnames';
import styles from '../TimeLine/TimelineItem.styles';

const NowItem: React.FC = () => {
  return (
    <>
      <div className={'timeline-item-wrapper'}>
        <div className="left">
          <div
            className={cn('first', 'bar', {
              hidden: true,
            })}
          />
          <div className="icon-wrapper">
            <img src={`/images/timeline/loading.svg`} alt={`loading icon`} />
          </div>
          <div
            className={cn('second', ' bar', {
              hidden: true,
            })}
          />
        </div>
        <div className="right">
          <p className="title">Now</p>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default NowItem;
