import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const bookingInfoStyles = css`
   {
    .add-booking-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .blue-action {
      text-align: start;
      padding-left: 24px;
      padding-top: 8px;
      padding-bottom: 12px;
      font-size: 11px;
      cursor: pointer;
      color: #1890ff;
      font-weight: 400;
    }
    .add-booking-button {
      display: flex;
      margin: 0 16px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      color: ${colors.black};
      border: 2px solid ${colors.black};
      border-radius: 8px;
      cursor: pointer;
    }
    .edit-button {
      cursor: pointer;
    }
    .button-text {
      font-size: 12px;
      margin-bottom: 0;
    }
    .booking-info-cancel-button {
      margin: 24px 24px 60px;
      color: red;
      cursor: pointer;
    }
    .list-item-wrapper {
      margin: 0 24px 16px;
      border-bottom: 1px solid #979797;
      padding-bottom: 2px;
    }
    .label-status {
      font-size: 12px;
      margin: 16px 0 0;
      color: rgba(0, 0, 0, 0.65) !important;
    }
    .booking-info-block-reasons {
      display: flex;
    }
  }
`;
