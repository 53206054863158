import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './UpdateConnectionModal.styles';
import {
  selectSelectedCar,
  updateCarIfNeeded,
} from '../../redux/store/userData/cars';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';
import CarEditorConnectionSelector from '../../components/CarEditDrawer/CarEditorConnectionSelector';
import { selectToken } from '../../redux/store/userData/user';
import { fetchCarConditionsIfNeeded } from '../../redux/helpers';
import { useIntl } from 'react-intl';

const UpdateConnectionModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const visibleModal = useSelector(getIsVisibleModal('updateConnectionModal'));

  const currentConnection = selectedCar?.vehicleConnection;
  const [selectedConnection, setSelectedConnection] = useState(
    currentConnection === 'cloudboxx' ? 'geoTab' : 'cloudboxx'
  );

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      updateCarIfNeeded(
        token,
        selectedCar?.id!,
        {
          vehicleConnection: selectedConnection,
        },
        () => {
          dispatch(
            fetchCarConditionsIfNeeded(token, selectedCar?.id!, false, true)
          );
        }
      )
    );
    dispatch(closeModal('updateConnectionModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateConnectionModal'));
  };

  const handleOnChange = (value: React.SetStateAction<string>) => {
    setSelectedConnection(value);
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateConnectionModal'));
        }}
      >
        <div className="update-car-connection-modal">
          <div className="connection-selector-wrapper">
            <p className="label">Vehicle Connection</p>
            <CarEditorConnectionSelector
              connections={selectedCar?.supportedVehicleConnections || []}
              className="car-editor-area-selector"
              defaultValue={selectedConnection}
              value={selectedConnection}
              onSelect={handleOnChange}
              loading={false}
            />
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={currentConnection === selectedConnection}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateConnectionModal;
