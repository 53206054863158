import { requestAPI } from '../../../../utils/request';
import { textsRequest, textsSuccess, textsFailure } from './actions';
import message from '../../../../utils/message';
import { Dispatch, GetState } from '../../../../@types';
import { TextsState } from './initialState';
import { parseLocale } from '../../../../utils';

const fetchTexts = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch(textsRequest());
  const user = getState().userData.user;
  requestAPI('/texts/admin')
    .then((data) => {
      dispatch(
        textsSuccess({ texts: data.texts, locale: parseLocale(user?.locale) })
      );
    })
    .catch((err) => {
      dispatch(textsFailure(err));
      message.error(err.message);
    });
};

const shouldFetchTexts = (state: TextsState) => !state.loading;

export const fetchTextsIfNeeded = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchTexts(getState().appData.texts)) {
      return dispatch(fetchTexts());
    } else {
      return Promise.resolve();
    }
  };
};
