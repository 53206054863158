import { getDeepProperty } from '../../../utils/getDeepProperty';

const getDataSourceValues = (entities, target) => {
  return (
    entities
      ?.flatMap((entity) => {
        const value = getDeepProperty(entity, target);
        return value !== undefined ? value : null;
      })
      ?.filter((x) => x !== null)
      ?.filter((x, i, self) => self.indexOf(x) === i) || []
  );
};

export default getDataSourceValues;
