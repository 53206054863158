import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css`
  .user-info {
    margin: 0;
    padding-bottom: 100px;
  }
  .edit-button {
    cursor: pointer;
  }
  .add-button {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .user-section-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 24px;
    margin-top: 36px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .collapse-container {
    margin-right: 24px;
    margin-left: 24px;
  }
  :global(.ant-collapse-content > .ant-collapse-content-box) {
    padding: 0 !important;
  }
  .collapse-unpair {
    text-align: end;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 24px;
    font-size: 11px;
    cursor: pointer;
    color: red;
    font-weight: 400;
  }
  .collapse-blue-action {
    text-align: end;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 12px;
    font-size: 11px;
    cursor: pointer;
    color: #1890ff;
    font-weight: 400;
  }
  .collapse-red-action {
    text-align: end;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 12px;
    font-size: 11px;
    cursor: pointer;
    color: red;
    font-weight: 400;
  }
  .dl-image-container {
    padding-top: 8px;
    padding-left: 24px;
    padding-bottom: 24px;
  }
  .permission-label {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }
  .payment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .payment-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .payment-icon-paypal {
    width: 34px;
    height: 22px;
    margin-right: 15px;
  }
  .payment-icon-payIn {
    width: 30px;
    height: 17px;
    margin-right: 15px;
  }
  .dl-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .empty-wrapper {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  .empty {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .button-container {
    padding: 50px 16px 30px 16px;
    position: sticky;
    bottom: 0;
    background-image: url('/images/drawer/cta-background.svg');
  }
  .user-info :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .user-info :global(.ant-collapse-header) {
    padding-left: 24px !important;
    padding-right: 0 !important;
  }
  .user-info :global(.ant-tag) {
    margin-right: 0px !important;
  }
  .user-info :global(.ant-collapse-arrow) {
    left: 5px !important;
  }
  .tariff-file {
    text-align: end;
    color: #1677ff;
    padding: 4px 24px 10px 24px;
    font-size: 12px;
    cursor: pointer;
  }
`;

export default style;
