// Function to get the deep nested property from an object
export const getDeepProperty = (obj, path) => {
  const parts = path
    .split('.')
    .map((part) =>
      part.includes('[') ? part.split(/[.[\]]/).filter(Boolean) : part
    );
  let current = obj;
  for (let i = 0; i < parts.length; i++) {
    if (current === null || typeof current !== 'object') {
      return null;
    }
    if (Array.isArray(parts[i])) {
      let tempCurrent = current;
      parts[i].forEach((part) => {
        tempCurrent = tempCurrent[part];
      });
      current = tempCurrent;
    } else {
      current = current[parts[i]];
    }
  }
  return current;
};
