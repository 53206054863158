import React from 'react';
import { Select } from 'antd';
import { PricingTableData } from '../../@types';
import { zIndex } from '../../theme/theme';

const { Option } = Select;

type PricingSelectorProps = {
  pricings: Array<PricingTableData>;
  style?: any;
  className?: string;
  defaultValue?: string;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  onSelect?: (value: string) => void;
};

const PricingSelector: React.FC<PricingSelectorProps> = ({
  pricings,
  style,
  className,
  defaultValue,
  value,
  disabled,
  loading,
  placeholder,
  onSelect,
}) => {
  return (
    <div style={style} className={className}>
      <Select
        defaultValue={defaultValue}
        value={value}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
        style={{ width: '100%' }}
        disabled={disabled}
        loading={loading}
        placeholder={placeholder}
        onChange={onSelect}
      >
        {pricings &&
          [...pricings].map((pricing) => (
            <Option key={`pricing-option-${pricing.id}`} value={pricing.id}>
              {pricing.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default PricingSelector;
