import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  p {
    margin-bottom: 0;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 14px;
    width: 130px;
    text-align: center;
    border-radius: 12px;
    color: #fff;
  }
  p.waiting {
    background: ${colors.bookings.waiting};
  }
  p.offered {
    background: ${colors.bookings.offered};
  }
  p.confirmed {
    background: ${colors.bookings.confirmed};
  }
  p.started {
    background: ${colors.bookings.started};
  }
  p.finished {
    background: ${colors.bookings.finished};
  }
  p.cancelled {
    background: ${colors.bookings.cancelled};
  }
`;

export default style;
