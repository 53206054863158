import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tabs } from 'antd';
import styles from './UsersCreateModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import BulkCreateUsers from './BulkCreateUsers';

const { TabPane } = Tabs;

type PhoneReportModalProps = {
  visible?: boolean;
};

const UsersCreateModal: React.FC<PhoneReportModalProps> = ({ visible }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const _visible = useSelector(getIsVisibleModal('UsersCreateModal'));

  const handleCancel = () => {
    dispatch(closeModal('UsersCreateModal'));
  };

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-users-wrapper"
          title={intl.formatMessage({
            id: 'fleetadmin.users.create.title',
          })}
          visible={visible || _visible}
          width={520}
          style={{ top: 'calc(25%)' }}
          onCancel={handleCancel}
          footer={null}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Bulk" key="1">
              <BulkCreateUsers />
            </TabPane>
            <TabPane tab="Individual" key="2" disabled />
          </Tabs>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default UsersCreateModal;
