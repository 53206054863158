import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import UsersDrawerBody from './UsersDrawerBody';
import { closeUsersDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectDuplicateUser } from '../../redux/store/userData/users';

const UserDuplicateDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const selectedDuplicateUser = useSelector(selectDuplicateUser);
  const visible = selectedDuplicateUser != null;

  const handleOnClose = () => {
    dispatch(closeUsersDrawerAndSetQueries(true));
  };

  return (
    selectedDuplicateUser && (
      <DrawerLayout
        visible={visible}
        title={selectedDuplicateUser?.id!!}
        body={<UsersDrawerBody selectedUser={selectedDuplicateUser} />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default UserDuplicateDrawer;
