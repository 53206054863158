import { FleetAdminCarData } from '../../../@types';

export const optionsForVehicleStatus: Array<FleetAdminCarData['status']> = [
  'EQUIPMENT_READY',
  'EQUIPPED',
  'RELEASE_READY',
  'RELEASED',
  'BLOCKED',
  'RENTED',
  'TERMINATED',
];

export const optionsForVehicleGear: Array<FleetAdminCarData['gear']> = [
  'automatic',
  'manual',
];

export const optionsForVehicleFuelType: Array<FleetAdminCarData['fuel']> = [
  'diesel',
  'unleaded98',
  'unleaded95',
  'electric',
  'LPG',
  'other',
  'hybrid_diesel',
  'hybrid_unleaded98',
  'hybrid_unleaded95',
];

export const optionsForVehicleBlockReasons: FleetAdminCarData['blockReasons'] =
  [
    'needsFuel',
    'needsKeyFob',
    'needsCleaning',
    'needsAccessKitCheck',
    'needsFuelCard',
    'needsRepair',
    'needsBattery',
    'needsRelocation',
    'needsOther',
  ];

export const optionsForSupportedVehicleConnections: FleetAdminCarData['supportedVehicleConnections'] =
  ['cloudboxx', 'geoTab'];

export const optionsForVehicleConnection: FleetAdminCarData['supportedVehicleConnections'] =
  ['cloudboxx', 'geoTab'];
