export const valueToTextKey = (value: string) => {
  switch (value) {
    case 'damageId':
      return 'damage';
    case 'closeReason':
      return 'closereason';
    case 'tripIgnition':
      return 'ignition';
    case 'addedAt':
      return 'addedat';
    case 'fleetId':
      return 'fleet';
    case 'userReferralCode':
      return 'user';
    case 'tripPresentableId':
      return 'tripid';
    case 'thirdParty':
      return 'thirdparty';
    case 'operation':
      return 'action';
    case 'brandName':
      return 'brandname';
    default:
      return value;
  }
};
