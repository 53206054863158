import css from 'styled-jsx/css';

const style = css`
  .area-info {
    margin: 0;
  }
  .button-wrapper {
    margin: 48px 24px;
  }
  .archive-area-button {
    margin-top: 15px;
    color: red;
    cursor: pointer;
  }
`;

export default style;
