import React from 'react';
import { Button, Empty, Skeleton, Popover } from 'antd';
import styles from './CircleUsersTable.styles';
import isDesktop from '../../utils/sizeHelpers';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    fontFamily: 'AvenirNext,sans-serif',
  },
  body: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    fontFamily: 'AvenirNext,sans-serif',
  },
}))(TableCell);

const descendingComparator = (
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string | number,
  order: string
) => {
  // equal items sort equally
  if (a[orderBy] === b[orderBy]) {
    return 0;
  }
  // nulls sort after anything else
  else if (!a[orderBy]) {
    return 1;
  } else if (!b[orderBy]) {
    return -1;
  }
  // otherwise, if we're ascending, lowest sorts first
  else if (order === 'asc') {
    return a[orderBy] < b[orderBy] ? -1 : 1;
  }
  // if descending, highest sorts first
  else {
    return a[orderBy] < b[orderBy] ? 1 : -1;
  }
};

const getComparator = (order: string, orderBy: string) => {
  return (a: { [x: string]: number }, b: { [x: string]: number }) =>
    descendingComparator(a, b, orderBy, order);
};

const stableSort = (
  array: any[],
  comparator: {
    (a: { [x: string]: number }, b: { [x: string]: number }): 0 | 1 | -1;
    (arg0: any, arg1: any): any;
  }
) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

type Props = {
  dataSource: any[];
  onRemoveUserClicked: (arg0: string) => void;
  isAdminTable?: boolean;
};

const headCells = [
  {
    id: 'email',
    label: 'email',
    numeric: false,
    style: { padding: '12px 4px', borderTopLeftRadius: 8 },
  },
  { id: 'code', label: 'code', numeric: false, style: { padding: '12px 4px' } },
  {
    id: 'name',
    label: 'name',
    numeric: false,
    style: { padding: '12px 4px' },
  },
  {
    id: 'action',
    label: 'action',
    numeric: false,
    style: { padding: '12px 4px', borderTopRightRadius: 8 },
  },
];

const CircleUsersTable: React.FC<Props> = ({
  dataSource,
  onRemoveUserClicked,
  isAdminTable = false,
}) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('index');

  const intl = useIntl();

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: React.SetStateAction<string>) => (event: any) => {
      handleRequestSort(event, property);
    };

  return (
    <>
      {dataSource?.length > 0 ? (
        <div className="wrapper">
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="collapsible table"
            >
              <TableHead>
                <TableRow>
                  {headCells
                    .filter((head) =>
                      isAdminTable ? head.id !== 'code' : head
                    )
                    .map((headCell) => (
                      <StyledTableCell
                        key={headCell.id}
                        align="left"
                        padding="none"
                        style={headCell.style}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          // @ts-ignore
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.label)}
                        >
                          {headCell.id !== 'action' &&
                            intl.formatMessage({
                              id: `fleetadmin.circle.${headCell.id}.header`,
                            })}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(dataSource, getComparator(order, orderBy)).map(
                  (row) => (
                    <React.Fragment key={`row-${row.id}`}>
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                          padding="none"
                          style={{ padding: '12px 4px' }}
                        >
                          {row?.email?.length > 18
                            ? `${row?.email.substring(0, 18)}...`
                            : row?.email}
                        </StyledTableCell>
                        {!isAdminTable && (
                          <StyledTableCell
                            align="left"
                            padding="none"
                            style={{ padding: '12px 4px' }}
                          >
                            {row?.referralCode || '-'}
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          align="left"
                          padding="none"
                          style={{ padding: '12px 4px' }}
                        >
                          {`${row?.firstName} ${row?.lastName}`.length > 15
                            ? `${`${row?.firstName} ${row?.lastName}`.substring(
                                0,
                                15
                              )}...`
                            : `${row?.firstName} ${row?.lastName}`}
                        </StyledTableCell>
                        <StyledTableCell align="left" padding="none">
                          <Popover
                            content={
                              <div className="popover">
                                <p>Are you sure?</p>
                                <Button
                                  onClick={() => onRemoveUserClicked(row.id)}
                                >
                                  Yes
                                </Button>
                              </div>
                            }
                            trigger="click"
                            placement="topRight"
                          >
                            <img
                              className="joined-user-leave"
                              src="/images/circleDrawer/delete.svg"
                              alt="delete"
                            />
                          </Popover>
                        </StyledTableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : dataSource?.length === 0 ? (
        <Empty style={{ marginTop: '50px' }} description={'No records'} />
      ) : (
        <div className="loaderContainer">
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          {isDesktop() && (
            <>
              <Skeleton active paragraph />
              <Skeleton active paragraph />
            </>
          )}
        </div>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default CircleUsersTable;
