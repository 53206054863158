import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import styles from './LoadMoreCarEvents.styles';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import {
  selectLoadingVehicleEvents,
  selectIsAllCarEventsLoaded,
  fetchVehicleEventsForCar,
} from '../../redux/store/userData/vehicleEvents';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const LoadingIcon = (props: any) => <Spin indicator={antIcon} {...props} />;

type LoadMoreProps = {};

const LoadMoreCarEvents: React.FC<LoadMoreProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingVehicleEvents);
  const selectedCar = useSelector(selectSelectedCar);
  const isAllEventsLoaded = useSelector(selectIsAllCarEventsLoaded);

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(fetchVehicleEventsForCar(selectedCar?.id, true));
  };

  if (isAllEventsLoaded) {
    return null;
  }

  return (
    <>
      <div className="wrapper">
        <div className="bar" />
        {isLoading ? (
          <div className="loading-icon-wrapper">
            <LoadingIcon />
          </div>
        ) : (
          <div className="load-more" onClick={onClick}>
            Load more...
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default LoadMoreCarEvents;
