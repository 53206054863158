import React from 'react';
import { Collapse } from 'antd';
import ListItem from '../../../shared/ListItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import styles from '../UserInfo.styles';

const { Panel } = Collapse;

interface UserDeviceInfoProps {
  selectedUser: any;
  intl: any;
  onClickUnpair: (uuid: string) => void;
}

const UserDeviceInfo: React.FC<UserDeviceInfoProps> = ({
  selectedUser,
  intl,
  onClickUnpair,
}) => (
  <>
    <div className="user-section-title">
      {intl.formatMessage({ id: `fleetadmin.users.info.devices` })}
    </div>
    {selectedUser?.pairedDevices?.length === 0 ? (
      <div className="empty-wrapper">
        <img
          className="empty"
          src="/images/table/empty.svg"
          width="50"
          height="50"
        />
        {intl.formatMessage({ id: `fleetadmin.users.info.empty` })}
      </div>
    ) : (
      <div className="collapse-container">
        <Collapse bordered={false}>
          {selectedUser?.pairedDevices?.map((device: any, index: number) => (
            <Panel header={`UUID: ${device.uuid}`} key={index}>
              {device?.model && device?.platform && (
                <ListItem
                  label={intl.formatMessage({
                    id: `fleetadmin.users.info.device.model`,
                  })}
                  value={renderText(
                    `${device.model}, ${device.platform}`,
                    false
                  )}
                />
              )}
              {device?.pairedAt && (
                <ListItem
                  label={intl.formatMessage({
                    id: `fleetadmin.users.info.device.paired`,
                  })}
                  value={renderText(device?.pairedAt, false)}
                />
              )}
              <div
                className="collapse-unpair"
                onClick={() => onClickUnpair(device.uuid)}
              >
                {intl.formatMessage({
                  id: `fleetadmin.users.info.device.unpair`,
                })}
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    )}
    <style jsx>{styles}</style>
  </>
);

export default UserDeviceInfo;
