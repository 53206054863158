import css from 'styled-jsx/css';
import { zIndex, common } from '../../theme/theme';

const style = css.global`
  .update-profile-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .update-profile-wrapper .ant-modal-header {
    padding: 32px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .update-profile-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .update-profile-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
  }
  .update-profile-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .profile-inputs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
  }
  .section-label {
    font-size: 16px;
    font-weight: bold;
    margin: 9px 10px 0px 13px;
    padding-left: 4px;
  }
  .input-container1 {
    width: 100%;
    padding: 8px 16px;
  }
  .input-container2 {
    width: 48%;
  }
  .input-container3 {
    width: 31%;
  }
  .input-container4 {
    width: 23%;
  }
  .text-input-label {
    font-size: 12px;
    margin-bottom: 2px;
    padding-left: 4px;
  }
  .button-container {
    padding: 8px 16px 8px 16px;
  }
  .update-profile-wrapper :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .error {
    color: red;
    font-size: 11px;
    margin-bottom: 0;
    margin: 4px;
  }
  .switch-label {
    font-size: 12px;
    margin: 0 0 3px 2px;
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

export default style;
