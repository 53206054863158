import React from 'react';
import booleanToText from '../../utils/booleanToText';
import ListItem from '../../components/shared/ListItem';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import { DamageEntityData } from '../../@types';
import { ImageStyles } from '../TimeLine/ImageAdded.styles';
import { claimInfoStyles } from './ClaimInfo.styles';
import moment from 'moment';
import ClaimStatusItem from '../TimeLine/ClaimStatusItem';
import ClaimStatusLabel from '../../components/shared/ClaimStatusLabel';
import { useIntl } from 'react-intl';
import ImageAdded from '../TimeLine/ImageAdded';
import { colors } from '../../theme/theme';
import { batch, useDispatch } from 'react-redux';
import { selectClaim } from '../../redux/store/userData/claims/actions';

const IMAGE_PATH = '/images/timeline';

type ClaimInfoProps = {
  claim: DamageEntityData;
  index?: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClaimInfo: React.FC<ClaimInfoProps> = ({ claim, index }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const roadworthiness = booleanToText(claim?.roadworthiness!);
  const thirdPartyInvolved = booleanToText(claim?.thirdParty!);

  const isNoImages = !claim.damageImages || claim.damageImages.length === 0;

  const onClickDamageId = () => {
    batch(() => {
      dispatch(selectClaim(claim));
    });
  };

  return (
    <div>
      <div>
        <div style={{ marginTop: -16, paddingBottom: '20px' }}>
          <ClaimStatusItem
            title={intl.formatMessage({
              id: 'fleetadmin.claim.section.reported',
            })}
            date={moment(claim.addedAt).format('YYYY-MM-DD HH:mm')}
            icon={`${IMAGE_PATH}/redDot.svg`}
            body={
              <>
                <pre className="reservedAtBody">
                  {`by ${claim.userReferralCode}|`}
                  <LinkToGoogleMap position={claim.geoLocation} />
                </pre>
                <ListItem
                  style={{ margin: 0, marginBottom: '10px', marginTop: '24px' }}
                  label={intl.formatMessage({
                    id: 'fleetadmin.claim.id.label',
                  })}
                  value={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        onClick={onClickDamageId}
                        style={{ color: colors.blue, cursor: 'pointer' }}
                      >
                        {claim.damageId}
                      </div>
                      <ClaimStatusLabel status={claim?.status || 'open'} />
                    </div>
                  }
                />
                <div className="row">
                  <ListItem
                    style={{
                      flex: 1,
                      margin: 0,
                      marginBottom: '10px',
                      marginRight: '12px',
                    }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.where.label',
                    })}
                    value={
                      claim?.damageWhere
                        ? intl.formatMessage({
                            id: `fleetadmin.damage.where.${claim.damageWhere}`,
                          })
                        : '-'
                    }
                  />
                  <ListItem
                    style={{ flex: 1, margin: 0, marginBottom: '10px' }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.location.label',
                    })}
                    value={
                      claim?.damageLocation
                        ? intl.formatMessage({
                            id: `fleetadmin.damage.location.${claim.damageLocation}`,
                          })
                        : '-'
                    }
                  />
                </div>
                <div className="row">
                  <ListItem
                    style={{
                      flex: 1,
                      margin: 0,
                      marginBottom: '10px',
                      marginRight: '12px',
                    }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.side.label',
                    })}
                    value={
                      claim?.damageSide
                        ? intl.formatMessage({
                            id: `fleetadmin.damage.side.${claim.damageSide}`,
                          })
                        : '-'
                    }
                  />
                  <ListItem
                    style={{ flex: 1, margin: 0, marginBottom: '10px' }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.component.label',
                    })}
                    value={
                      claim?.damageComponent
                        ? intl.formatMessage({
                            id: `fleetadmin.damage.component.${claim.damageComponent}`,
                          })
                        : '-'
                    }
                  />
                </div>
                <div className="row">
                  <ListItem
                    style={{
                      flex: 1,
                      margin: 0,
                      marginBottom: '10px',
                      marginRight: '12px',
                    }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.roadworthiness.label',
                    })}
                    value={roadworthiness}
                  />
                  <ListItem
                    style={{ flex: 1, margin: 0, marginBottom: '10px' }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.3rdparty.label',
                    })}
                    value={thirdPartyInvolved}
                  />
                </div>
                <ListItem
                  style={{ margin: 0, marginBottom: '10px' }}
                  label={intl.formatMessage({
                    id: 'fleetadmin.claim.description',
                  })}
                  value={claim.damageDescription}
                />
                <div className="row">
                  <ListItem
                    style={{
                      flex: 1,
                      margin: 0,
                      marginBottom: '10px',
                      marginRight: '12px',
                    }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.vehicle.label',
                    })}
                    value={claim.plate}
                  />
                  <ListItem
                    style={{ flex: 1, margin: 0, marginBottom: '10px' }}
                    label={intl.formatMessage({
                      id: 'fleetadmin.claim.mileage.label',
                    })}
                    value={claim.mileage != null && `${claim.mileage} km`}
                  />
                </div>
                <div className="images-wrapper">
                  {isNoImages ? (
                    <p className="no-img-message">
                      {intl.formatMessage({
                        id: 'fleetadmin.claim.missing.images',
                      })}
                    </p>
                  ) : (
                    <>
                      <ImageAdded
                        vehicleEvent={{ ...claim, images: claim.damageImages }}
                      />
                    </>
                  )}
                </div>
              </>
            }
            iconSize={16}
            hiddenTopBar={true}
            editable={false}
            onPressEdit={() => {}}
          />
        </div>
        <style jsx>{claimInfoStyles}</style>
        <style jsx>{ImageStyles}</style>
      </div>
    </div>
  );
};

export default ClaimInfo;
