import { VehicleProfileInfoData } from '../../../../@types';
import { RootState } from '../../../config';

export const selectProfiles = (
  state: RootState
): VehicleProfileInfoData[] | null => state.userData?.profiles?.entities;

export const selectDisplayProfiles = (
  state: RootState
): VehicleProfileInfoData[] | null => state.userData?.profiles?.display;

export const selectSelectedProfile = (
  state: RootState
): VehicleProfileInfoData | null => state.userData?.profiles?.selected;

export const selectLoadingProfile = (state: RootState) =>
  state.userData?.profiles?.loadingProfile;

export const selectProfilesLoading = (state: RootState): boolean =>
  state.userData.profiles.loading;
