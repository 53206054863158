import {
  carConditionsRequest,
  carConditionsSuccess,
  carConditionsFailure,
  updateLastCarsStatus,
  CarsState,
} from '../store/userData/cars/index';
import message from '../../utils/message';
import { Dispatch, GetState } from '../../@types';

const defaultCarCondition = {
  fuelLevel: null,
  keyFobIn: false,
  fuelCardIn: false,
  batteryVoltage: null,
};

const fetchCarConditions =
  (token: string, carId: string, withMessage: boolean, refresh: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(carConditionsRequest());
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/fleets/cars/${carId}/status`
    );
    url.searchParams.append('refresh', refresh.toString());
    fetch(url.toString(), {
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(async (res) => {
        const body = await res.json();
        return { body, ok: res.ok };
      })
      .then(({ body, ok }) => {
        if (!ok) {
          throw new Error(JSON.stringify(body));
        }
        const carCondition = { ...defaultCarCondition, ...body };
        dispatch(carConditionsSuccess(carCondition));
        if (refresh) {
          dispatch(updateLastCarsStatus(carCondition));
        }
        if (withMessage) {
          message.success('Updated');
        }
      })
      .catch((err) => {
        dispatch(carConditionsFailure(err));
        if (withMessage) {
          message.error(err.message);
        }
      });
  };

const shouldFetchCarConditions = (state: CarsState) =>
  !state?.loadingConditions && state?.selected?.status !== 'EQUIPMENT_READY';

export const fetchCarConditionsIfNeeded = (
  token: string,
  carId: string,
  withMessage: boolean,
  refresh = false
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCarConditions(getState().userData.cars)) {
      return dispatch(fetchCarConditions(token, carId, withMessage, refresh));
    } else {
      return Promise.resolve();
    }
  };
};
