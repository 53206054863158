import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from 'ramda';
import moment from 'moment';
import isEmptyArray from '../../utils/isEmptyArray';
import LoadingIcon from '../../components/shared/LoadingIcon';
import TimeTag from '../TimeLine/TimeTag';
import LoadMoreClaimEvents from './LoadMoreClaimEvents';
import { loadingStyles, timelineStyles } from './ClaimTimeline.styles';
import ClaimAllAroundGallery from '../../components/ClaimAllAroundGallery';
import ClaimTimelineItem from '../TimeLine/ClaimTimelineItem';
import AllAroundModal from '../../components/AllAroundModal';
import { useIntl } from 'react-intl';
import { selectCars, selectSelectedCar } from '../../redux/store/userData/cars';
import { selectSelectedClaim } from '../../redux/store/userData/claims';
import {
  selectIsAllClaimEventsLoaded,
  selectClaimEvents,
} from '../../redux/store/userData/vehicleEvents';
import { VehicleEventData } from '../../@types';

const ClaimTimeline: React.FC = () => {
  const intl = useIntl();
  const vehicleEvents = useSelector(selectClaimEvents) as any;
  const selectedClaim = useSelector(selectSelectedClaim);
  const selectedCar = useSelector(selectSelectedCar);
  const isAllEventsLoaded = useSelector(selectIsAllClaimEventsLoaded);
  const cars = useSelector(selectCars);
  const VIN = cars?.find(
    (car) => car.id === selectedClaim?.carId || selectedCar?.id
  )?.chassisNumber;
  const [isVisibleCarCheckGallery, setIsVisibleCarCheckGallery] =
    React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAllAroundIndex, setSelectedAllAroundIndex] = React.useState(0);
  const [serviceImages, setServiceImages] = useState([]);
  const [isVisibleServiceModal, setIsVisibleServiceModal] = useState(false);
  let tripId: any;
  let isInsideTrip = false;
  const serviceImageTitle = intl.formatMessage({
    id: 'fleetadmin.trip.serviceImage.modal.title',
  });
  if (isEmptyArray(vehicleEvents)) {
    return (
      <>
        <div className="wrapper">
          <LoadingIcon />
        </div>
        <style jsx>{loadingStyles}</style>
      </>
    );
  }

  let groupedVehicleEvents = {
    [moment(selectedClaim?.tripStartedAt || selectedClaim?.addedAt).format(
      'YYYY-MM-DD'
    )]: [],
  } as any;

  if (vehicleEvents != null && !isEmptyArray(vehicleEvents)) {
    groupedVehicleEvents = groupBy((ve: any) =>
      moment(ve?.addedAt).format('YYYY-MM-DD')
    )(vehicleEvents);
  }

  const galleryData =
    vehicleEvents !== null
      ? vehicleEvents
          .filter(
            (event: { type: string; imageType: string | string[] }) =>
              event.type === 'imageAdded' &&
              !event.imageType.includes('service')
          )
          .map(
            (event: {
              imageType: any;
              presentableTripId: any;
              addedAt: any;
              images: any;
            }) => {
              return {
                type: event.imageType,
                tripId: event.presentableTripId,
                addedAt: event.addedAt,
                images: event.images,
              };
            }
          )
      : [];

  const openCarCheckGallery = (addedAt: any) => {
    const indexWithinGallery = galleryData.findIndex(
      (data: { addedAt: any }) => data.addedAt === addedAt
    );
    setSelectedAllAroundIndex(indexWithinGallery);
    setIsVisibleCarCheckGallery(true);
  };

  const closeCarCheckGallery = () => {
    setIsVisibleCarCheckGallery(false);
  };

  const openServiceModal = (ve: any) => {
    setServiceImages(ve.images);
    setIsVisibleServiceModal(true);
  };

  const closeServiceModal = () => {
    setIsVisibleServiceModal(false);
  };

  return (
    <>
      <div className="timeline-wrapper" style={{ paddingTop: '30px' }}>
        {Object.entries(groupedVehicleEvents).map(
          ([key, vehicleEvents]: any, i) => {
            return (
              <div key={`timeline-${key}`} className="timeline-group">
                <TimeTag
                  date={key}
                  hideFirstBar={i === 0}
                  isInsideTrip={isInsideTrip}
                />
                {vehicleEvents?.map((ve: VehicleEventData, _i: number) => {
                  let isNewTrip =
                    ve.tripId !== undefined && tripId !== ve.tripId;
                  if (isNewTrip) {
                    tripId = ve.tripId;
                  }
                  isInsideTrip = !['tripStarted', 'shockDetected'].includes(
                    ve?.type!
                  );

                  let hideSecondBar =
                    isAllEventsLoaded &&
                    vehicleEvents.length - 1 === _i &&
                    Object.keys(groupedVehicleEvents).length - 1 === i;

                  return (
                    <ClaimTimelineItem
                      key={`vehicleEvents-${_i}`}
                      isNewTrip={isNewTrip}
                      vehicleEvent={ve}
                      hideSecondBar={hideSecondBar}
                      isInsideTrip={ve.type === 'tripStarted' || isInsideTrip}
                      onClickBody={() =>
                        ve?.imageType?.includes('service')
                          ? openServiceModal(ve)
                          : openCarCheckGallery(ve.addedAt)
                      }
                    />
                  );
                })}
              </div>
            );
          }
        )}
        <LoadMoreClaimEvents />
      </div>
      {galleryData && galleryData.length > 0 && (
        <ClaimAllAroundGallery
          vin={VIN}
          galleryData={galleryData}
          visible={isVisibleCarCheckGallery}
          onClose={closeCarCheckGallery}
        />
      )}
      {serviceImages && serviceImages.length > 0 && (
        <AllAroundModal
          allAroundCheck={{
            images: serviceImages,
            title: serviceImageTitle,
            chassisNumber: ' ',
          }}
          visible={isVisibleServiceModal}
          onClose={closeServiceModal}
        />
      )}
      <style jsx>{timelineStyles}</style>
    </>
  );
};

export default ClaimTimeline;
