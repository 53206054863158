import { IntlShape } from 'react-intl';

export const typeLabelForCircle = (intl: IntlShape, type: string) => {
  return intl.formatMessage({ id: 'circle.type.' + type });
};

export const labelForCircle = (type: string) => {
  switch (type) {
    case 'area':
      return 'areaLevel';
    case 'service':
      return 'maintenance';
    default:
      return 'fleetLevel';
  }
};
