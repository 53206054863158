import React from 'react';

export const renderText = (
  data: string | null,
  dashForEmpty = true,
  color = 'black'
) =>
  dashForEmpty ? (
    <span style={{ color }}>{data || '-'}</span>
  ) : data ? (
    <span>{data}</span>
  ) : null;

export const renderNumber = (data: number | null) => <span>{data || '-'}</span>;
