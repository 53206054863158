import { requestAPI } from '../../../../utils/request';
import {
  vehicleEventsRequest,
  vehicleEventsSuccess,
  vehicleEventsFailure,
  setVehicleEventsFrom,
  loadMoreVehicleEventsRequest,
  loadMoreVehicleEventsSuccess,
  vehicleEventsEmptyResponse,
} from './actions';
import isEmptyArray from '../../../../utils/isEmptyArray';
import message from '../../../../utils/message';
import { TRIP_DURATION_MARGIN } from '../../../../components/TripsTable/TripsTable';
import getNDaysBefore from '../../../../utils/datetime/getNDaysBefore';
import { Dispatch, GetState } from '../../../../@types';
import { VehicleEventsState } from './initialState';
import moment from '../../../../utils/formatDate';

export type FILTER_OPTION =
  | 'Claims'
  | 'Rentals'
  | 'Services'
  | 'BaseData'
  | 'Device';
export type EVENT_TAG =
  | 'baseData'
  | 'service'
  | 'availability'
  | 'device'
  | 'trip'
  | 'damageClaim'
  | 'claim'
  | 'access'
  | 'infringementClaim';

const getDateTime = (dateTimeString: any) => {
  if (!dateTimeString) {
    return 0;
  }
  return new Date(dateTimeString).getTime();
};

const getURIQuery = (query: Object): String =>
  Object.entries(query)
    .map(([key, value]) => {
      if (value == null) {
        return null;
      }

      if (Array.isArray(value)) {
        return value
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&');
      }

      // @ts-ignore
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter((value) => value !== null)
    .join('&');

const filterOptionToTags = (option: any): EVENT_TAG[] => {
  switch (option) {
    case 'BaseData':
      return ['baseData', 'availability'];
    case 'Claims':
      return ['damageClaim', 'claim', 'infringementClaim'];
    case 'Device':
      return ['device'];
    case 'Rentals':
      return ['access', 'trip'];
    case 'Services':
      return ['access', 'service'];
    default:
      return [];
  }
};

export const fetchVehicleEvents =
  (
    token: string,
    carId: string | undefined,
    from: Date | moment.MomentInput | null,
    to: Date | moment.MomentInput | null,
    tags: string[],
    target: EventTarget | any,
    isLoadMore: boolean,
    tripId: any = null
  ) =>
  (dispatch: Dispatch) => {
    if (isLoadMore) {
      dispatch(
        loadMoreVehicleEventsRequest({
          from,
          to,
          target,
        })
      );
    } else {
      dispatch(
        vehicleEventsRequest({
          from,
          to,
          target,
        })
      );
    }
    const query = {
      from,
      to,
      tags,
    };
    requestAPI(`/vehicleEvents/${carId}?${getURIQuery(query)}`)
      .then((vehicleEvents) => {
        if (isEmptyArray(vehicleEvents)) {
          // @ts-ignore
          dispatch(vehicleEventsEmptyResponse({ target }));
        } else {
          let sortedVehicleEvents =
            vehicleEvents.length === 1
              ? vehicleEvents
              : [...vehicleEvents]
                  .sort(
                    (ve1, ve2) =>
                      getDateTime(ve2.addedAt) - getDateTime(ve1.addedAt)
                  )
                  ?.map((event: any) => ({
                    ...event,
                    principalEmail: event?.principalEmail?.includes(
                      'vanadgroup.com'
                    )
                      ? 'system'
                      : event?.principalEmail || '',
                  }));
          if (tripId) {
            sortedVehicleEvents = sortedVehicleEvents.filter(
              (event: { tripId: any }) => event.tripId === tripId
            );
          }

          const theOldestEvent =
            sortedVehicleEvents[sortedVehicleEvents.length - 1];
          const isTheOldestEventOlderThanRequestFrom =
            getDateTime(theOldestEvent?.addedAt) < getDateTime(from);

          if (isTheOldestEventOlderThanRequestFrom) {
            dispatch(
              setVehicleEventsFrom({
                from: theOldestEvent?.addedAt,
                target,
              })
            );
          }
          if (isLoadMore) {
            dispatch(
              loadMoreVehicleEventsSuccess({
                events: sortedVehicleEvents,
                target: target,
              })
            );
          } else {
            dispatch(
              vehicleEventsSuccess({
                events: sortedVehicleEvents,
                target: target,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(vehicleEventsFailure(err));
        message.error(err.message);
      });
  };

const shouldFetchVehicleEvents = (state: VehicleEventsState) => !state.loading;

export const fetchVehicleEventsIfNeeded = (
  carId: string | undefined,
  from: Date | null,
  to: Date | null,
  tags: string[],
  target: EventTarget | string,
  isLoadMore: boolean,
  tripId = null
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchVehicleEvents(getState().userData.vehicleEvents)) {
      return dispatch(
        fetchVehicleEvents(
          getState().userData.user.token,
          carId,
          from,
          to,
          tags,
          target,
          isLoadMore,
          tripId
        )
      );
    } else {
      return Promise.resolve();
    }
  };
};

export const fetchVehicleEventsForTrip =
  (
    carId: string,
    tripId: string,
    from: any,
    to: any,
    service: boolean = false
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const fromDate = from
      ? new Date(new Date(from).getTime() - TRIP_DURATION_MARGIN).toISOString()
      : (null as any);
    const toDate = to
      ? new Date(new Date(to).getTime() + TRIP_DURATION_MARGIN).toISOString()
      : (new Date(Date.now() + TRIP_DURATION_MARGIN).toISOString() as any);
    const tags = ['availability', 'device', 'damageClaim', 'claim'];
    if (service) {
      tags.push('service');
    }
    return dispatch(
      fetchVehicleEvents(
        getState().userData.user.token,
        carId,
        fromDate,
        toDate,
        tags,
        // @ts-ignore
        service ? 'CARE' : 'TRIP',
        false,
        tripId
      )
    );
  };

export const fetchVehicleEventsForCar =
  (carId?: string, isLoadMore: boolean = false) =>
  (dispatch: Dispatch, getState: GetState) => {
    const prevFrom = getState().userData.vehicleEvents.carLastFrom;
    const filterOptions = getState().userData.vehicleEvents.carEventsFilters;
    const fromDate = getNDaysBefore(
      isLoadMore ? prevFrom : Date.now(),
      10
    ).toISOString() as any;

    const toDate = isLoadMore
      ? new Date(prevFrom).toISOString()
      : (new Date().toISOString() as any);

    const tags = filterOptions
      .map((option: string) => filterOptionToTags(option))
      .reduce((accumulator: string | any[], currentValue: any[]) => [
        ...accumulator,
        ...currentValue.filter((item) => !accumulator.includes(item)),
      ]);

    return dispatch(
      fetchVehicleEvents(
        getState().userData.user.token,
        carId,
        fromDate,
        toDate,
        tags,
        // @ts-ignore
        'CAR',
        isLoadMore
      )
    );
  };
