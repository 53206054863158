import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

export const timelineStyles = css`
  .timeline-item-wrapper {
    display: flex;
    width: 100%;
  }
  .bar {
    width: 16px;
  }
   .first.bar {
    height: 16px;
    border-right: 1px solid ${colors.border};
  }
  .second.bar {
    height: calc(100% - 24px);
    border-right: 1px solid ${colors.border};
  }
  .first.bar.hidden,
  .second.bar.hidden {
    border-right: none;
  }
  .icon {
    margin-top: 8px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 100%;
  }
  .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  .title {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0;
    font-family: AvenirNext;
    font-size: 16px;
    color: #313131;
  }
  .date-time {
    color: #9f9f9f;
    margin-left: 8px;
    font-family: AvenirNext;
    font-size: 14px;
    line-height: 18px;
  }
  .body {
    margin-bottom: 16px;
  }
  }
`;
