import React from 'react';
import { useSelector } from 'react-redux';
import styles from './AdminsDrawerBody.styles';
import TabLayout from '../../components/shared/TabLayout';
import AdminInfo from './AdminInfoTab/AdminInfo';
import { selectSelectedAdmin } from '../../redux/store/userData/admins';
import { useIntl } from 'react-intl';
import AdminPermissions from './AdminPermissionsTab/AdminPermissions';
import AdminStatus from './AdminStatus/AdminStatus';

const AdminsDrawerBody = () => {
  const intl = useIntl();
  const selectedAdmin = useSelector(selectSelectedAdmin);

  const tabs = [
    {
      title: intl.formatMessage({
        id: `fleetadmin.admins.tab.info`,
      }),
      content: <AdminInfo />,
    },
    {
      title: intl.formatMessage({
        id: `fleetadmin.admins.tab.permissions`,
      }),
      content: <AdminPermissions />,
    },
  ];

  return (
    <>
      <div className="users-drawer-body">
        <div className="users-name-wrapper">
          <p className="name">{`${selectedAdmin?.firstName} ${selectedAdmin?.lastName}`}</p>
          <AdminStatus status={selectedAdmin?.blocked ? 'deleted' : 'active'} />
        </div>
        <TabLayout tabs={tabs} keyName="users-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AdminsDrawerBody;
