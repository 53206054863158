import css from 'styled-jsx/css';

const style = css`
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: white;
    color: #313131;
    font-family: Avenir Next;
    font-style: normal;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    z-index: 1;
    bottom: -25%;
    right: 150%;
    margin-left: -75px;
    border: 1px solid #979797;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 25%;
    right: -11%;
    margin-top: 8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent #979797;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

export default style;
