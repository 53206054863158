import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css`
  :global(.mapboxgl-marker) {
    z-index: ${zIndex.carMarker};
  }
  :global(.mapboxgl-marker.selected) {
    z-index: ${zIndex.carMarkerSelected};
  }
  .car-marker-wrapper {
    display: flex;
  }
  .car-marker-icon {
    cursor: pointer;
  }
  .car-block-reason-wrapper {
    position: absolute;
    left: 28px;
    width: max-content;
  }
  .car-block-reason-icon {
    width: 24px;
    height: 24px;
    position: relative;
  }
  .car-marker-outsideArea {
    position: absolute;
    left: 28px;
    height: 24px;
    width: 24px;
  }
`;

export default style;
