import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './BookingUserEditModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  updateBooking,
  selectSelectedBooking,
} from '../../../redux/store/userData/trips';
import { useIntl } from 'react-intl';
import { FleetBookingInputData } from '../../../@types';

const BookingUserEditModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedBooking = useSelector(selectSelectedBooking) as any;
  const visibleModal = useSelector(getIsVisibleModal('BookingUserEditModal'));

  const [email, setEmail] = useState<string>('');

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const updateBookingInput: FleetBookingInputData = {
      userEmail: email,
    };
    dispatch(updateBooking(selectedBooking?.id, updateBookingInput));
    dispatch(closeModal('BookingUserEditModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    dispatch(closeModal('BookingUserEditModal'));
  };

  const onUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (visibleModal) {
      setEmail('');
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('BookingUserEditModal'));
        }}
      >
        <div className="booking-user-edit-modal">
          <div className="booking-user-edit-wrapper">
            <div className="booking-user-edit-modal-inputs">
              <p className="label">
                {intl.formatMessage({
                  id: 'fleetadmin.booking.update.user.title',
                })}
              </p>
            </div>
          </div>
          <Input
            placeholder={intl.formatMessage({
              id: 'fleetadmin.booking.user.placeholder',
            })}
            style={{ marginBottom: 15 }}
            value={email}
            onChange={onUserEmailChange}
          />

          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'fleetadmin.booking.button.assign',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default BookingUserEditModal;
