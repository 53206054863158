import { store } from '../redux/config';
import { parseLocale } from './locale';

export const formatPrice = (
  price: number,
  allowCt = true,
  codeDisplay = false
): string => {
  if (price < 100 && allowCt) {
    return `${Math.ceil(price)} Ct`;
  }

  const currencyFormatter = new Intl.NumberFormat(
    parseLocale(store.getState()?.userData?.user?.locale),
    {
      style: 'currency',
      currency: 'EUR',
      ...(codeDisplay ? { currencyDisplay: 'code' } : {}),
      maximumFractionDigits: 2,
    }
  );

  return currencyFormatter.format(price / 100);
};
