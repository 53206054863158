import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import ListItem from '../../components/shared/ListItem';
import formatDate from '../../utils/formatDate';
import styles from './VehicleInfo.styles';
import getFleetNameById from '../../utils/getFleetNameById';
import Gallery from '../../components/CarEditDrawer/Gallery';
import { selectSelectedCar } from '../../redux/store/userData/cars';
import { selectFleets } from '../../redux/store/appData/fleets';
import ListEditItem from '../../components/shared/ListEditItem';
import { openModal } from '../../redux/store/ui/common';
import { selectAuth } from '../../redux/store/userData/user/selectors';

const VehicleInfo: React.FC = () => {
  const intl = useIntl();
  const user = useSelector(selectAuth);
  const dispatch = useDispatch();
  const fleets = useSelector(selectFleets);
  const selectedCar = useSelector(selectSelectedCar);
  const blockedReasons = selectedCar?.blockReasons
    ? selectedCar?.blockReasons
        .map((reason) =>
          intl.formatMessage({ id: `fleetadmin.blockReasons.${reason}` })
        )
        .join(', ')
    : [];

  const handleClickEditPlateButton = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(openModal('updateCarPlateModal'));
  };

  return (
    <>
      <div className="vehicle-info">
        <ListEditItem
          label={<FormattedMessage id="fleetadmin.carEditor.plate" />}
          leftItem={<div>{selectedCar?.plate}</div>}
          rightItem={
            user?.permissions?.fleetManager && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleClickEditPlateButton}
              />
            )
          }
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.vin" />}
          value={selectedCar?.chassisNumber}
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.model" />}
          value={
            (
              <span>
                {selectedCar?.brand} {selectedCar?.model}
              </span>
            ) || '-'
          }
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.fleet" />}
          value={getFleetNameById(fleets, selectedCar?.fleetId)}
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.gear" />}
          value={selectedCar?.gear || ''}
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.enginePower" />}
          value={
            <>
              {selectedCar?.enginePower || ''}{' '}
              <FormattedMessage id="fleetadmin.carEditor.enginePowerUnit" />
            </>
          }
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.pollutionBadge" />}
          value={selectedCar?.pollutionBadge || ''}
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.fuel" />}
          value={selectedCar?.fuel}
        />
        <ListItem
          label={
            <FormattedMessage id="fleetadmin.carEditor.initialRegistration" />
          }
          value={
            selectedCar?.initialRegistration
              ? formatDate(selectedCar.initialRegistration)
              : ''
          }
        />
        <ListItem
          label={<FormattedMessage id="fleetadmin.carEditor.fuelCardNumber" />}
          value={selectedCar?.fuelCardNumber || '-'}
        />
        <ListItem
          label="Fuel card pin"
          value={
            selectedCar?.fuelCardPin
              ? [...selectedCar.fuelCardPin].join(' . ').trim()
              : '-'
          }
        />
        {selectedCar?.status === 'BLOCKED' && (
          <ListItem
            label={<FormattedMessage id="fleetadmin.carEditor.blockReasons" />}
            value={blockedReasons}
          />
        )}
        <Gallery />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default VehicleInfo;
