const claimColumns = [
  { label: 'Claim ID', value: 'damageId', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Close reason', value: 'closeReason', disabled: false },
  { label: 'Trip ignition', value: 'tripIgnition', disabled: false },
  { label: 'Created at', value: 'addedAt', disabled: false },
  { label: 'Brand', value: 'brand', disabled: false },
  { label: 'Fleet', value: 'fleetId', disabled: false },
  { label: 'User', value: 'userReferralCode', disabled: false },
  { label: 'Plate', value: 'plate', disabled: false },
  { label: 'Trip ID', value: 'tripPresentableId', disabled: false },
  { label: '3rd party', value: 'thirdParty', disabled: false },
  { label: 'Roadworthiness', value: 'roadworthiness', disabled: false },
  { label: 'Action', value: 'operation', disabled: false },
];

export const defaultSelectedClaimColumns = claimColumns.map(
  (claimColumn) => claimColumn.value
);

export default claimColumns;
