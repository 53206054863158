import { createAction, createErrorAction } from '../../../helpers';
import {
  ErrorResponse,
  FleetBookingData,
  FleetRentalData,
} from '../../../../@types';
import { TripsTypes } from '../../../../@types/enums';

export const moreTripsRequest = () =>
  createAction(TripsTypes.MORE_TRIPS_REQUEST);

export const tripsRequest = () => createAction(TripsTypes.TRIPS_REQUEST);

export const tripsSuccess = (payload: FleetRentalData[] | null) =>
  createAction(TripsTypes.TRIPS_SUCCESS, payload);

export const tripsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.TRIPS_FAILURE, error);

export const tripRequest = () => createAction(TripsTypes.TRIP_REQUEST);

export const createBookingRequest = () =>
  createAction(TripsTypes.CREATE_BOOKING_REQUEST);

export const cancelBookingRequest = () =>
  createAction(TripsTypes.CANCEL_BOOKINGS_REQUEST);

export const updateBookingRequest = () =>
  createAction(TripsTypes.UPDATE_BOOKINGS_REQUEST);

export const updateTripRequest = () =>
  createAction(TripsTypes.UPDATE_TRIP_REQUEST);

export const startBookingRequest = () =>
  createAction(TripsTypes.START_BOOKING_REQUEST);

export const tripSuccess = (payload: FleetRentalData) =>
  createAction(TripsTypes.TRIP_SUCCESS, payload);

export const createBookingSuccess = () =>
  createAction(TripsTypes.CREATE_OFFLINE_BOOKING_SUCCESS);

export const cancelBookingSuccess = () =>
  createAction(TripsTypes.CANCEL_BOOKINGS_SUCCESS);

export const updateBookingSuccess = function (payload: any) {
  return createAction(TripsTypes.UPDATE_BOOKINGS_SUCCESS, payload);
};

export const updateTripSuccess = function (payload: any) {
  return createAction(TripsTypes.UPDATE_TRIP_SUCCESS, payload);
};

export const cancelBookingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.CANCEL_BOOKINGS_FAILURE, error);

export const updateBookingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.UPDATE_BOOKINGS_FAILURE, error);

export const updateTripFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.UPDATE_TRIP_FAILURE, error);

export const startOfflineBookingSuccess = () =>
  createAction(TripsTypes.START_OFFLINE_BOOKING_SUCCESS);

export const tripFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.TRIP_FAILURE, error);

export const createBookingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.CREATE_OFFLINE_BOOKING_FAILURE, error);

export const startOfflineBookingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.START_OFFLINE_BOOKING__FAILURE, error);

export const tripInvoiceRequest = () =>
  createAction(TripsTypes.TRIP_INVOICE_REQUEST);

export const tripInvoiceSuccess = (payload: FleetRentalData) =>
  createAction(TripsTypes.TRIP_INVOICE_SUCCESS, payload);

export const tripInvoiceFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.TRIP_INVOICE_FAILURE, error);

export const setDisplayTrips = (payload: FleetRentalData[] | null) =>
  createAction(TripsTypes.SET_DISPLAY_TRIPS, payload);

export const setTotalTrips = (payload: number) =>
  createAction(TripsTypes.SET_TOTAL_TRIPS, payload);

export const selectTrip = (payload: FleetRentalData | null) =>
  createAction(TripsTypes.SELECT_TRIP, payload);

export const finishTripRequest = () =>
  createAction(TripsTypes.FINISH_TRIP_REQUEST);

export const finishTripSuccess = (payload: FleetRentalData) =>
  createAction(TripsTypes.FINISH_TRIP_SUCCESS, payload);

export const finishTripFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.FINISH_TRIP_FAILURE, error);

export const startTripRequest = () =>
  createAction(TripsTypes.START_TRIP_REQUEST);

export const startTripSuccess = (payload: FleetRentalData) =>
  createAction(TripsTypes.START_TRIP_SUCCESS, payload);

export const startTripFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.START_TRIP_FAILURE, error);

export const finishTripViolations = (payload: string[]) =>
  createAction(TripsTypes.FINISH_TRIP_VIOLATIONS, payload);

export const bookingsRequest = () => createAction(TripsTypes.BOOKINGS_REQUEST);

export const bookingsSuccess = (payload: FleetBookingData[]) =>
  createAction(TripsTypes.BOOKINGS_SUCCESS, payload);

export const bookingsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.BOOKING_FAILURE, error);

export const bookingRequest = () => createAction(TripsTypes.BOOKING_REQUEST);

export const bookingSuccess = (payload: FleetBookingData[]) =>
  createAction(TripsTypes.BOOKING_SUCCESS, payload);

export const bookingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TripsTypes.BOOKING_FAILURE, error);

export const setBookingsDisplay = (payload: FleetBookingData[]) =>
  createAction(TripsTypes.SET_BOOKINGS_DISPLAY, payload);

export const setTotalBookings = (payload: number) =>
  createAction(TripsTypes.SET_TOTAL_BOOKINGS, payload);

export const selectBooking = (payload: FleetBookingData | null) =>
  createAction(TripsTypes.SELECT_BOOKING, payload);
