import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '../../components/shared/ListItem';
import getVehicleByVIN from '../../utils/getVehicleByVIN';
import LinkToArea from '../../components/shared/LinkToArea';
import LinkToVehicle from '../../components/shared/LinkToVehicle';
import { tripInfoStyles } from './TripInfo.styles';
import { formatDistance, formatDuration, getAreaById } from '../../utils';
import ViolationList from '../../components/TripDrawer/ViolationList';
import InvoiceItem from '../../components/TripDrawer/InvoiceItem';
import { selectAreas } from '../../redux/store/appData/areas';
import { selectCars } from '../../redux/store/userData/cars';
import {
  selectSelectedTrip,
  selectTripInvoiceData,
  startTrip,
  updateTrip,
} from '../../redux/store/userData/trips';
import LinkToCircle from '../shared/LinkToCircle';
import { selectCircles } from '../../redux/store/userData/circles';
import getCircleById from '../../utils/getCircleById';
import { selectAuth } from '../../redux/store/userData/user/selectors';
import {
  openConfirmModal,
  openModal,
} from '../../redux/store/ui/common/actions';
import formatDateTime from '../../utils/formatDateTime';
import ListEditItem from '../shared/ListEditItem';
import { selectTripEvents } from '../../redux/store/userData/vehicleEvents/selectors';
import { VehicleEventData } from '../../@types';
import { useIntl } from 'react-intl';
import LinkToUser from '../shared/LinkToUser';

const TripInfo = () => {
  const intl = useIntl();
  const cars = useSelector(selectCars);
  const areas = useSelector(selectAreas);
  const circles = useSelector(selectCircles);
  const {
    id,
    merchantRef,
    status,
    chassisNumber,
    tripStart,
    userReferralCode,
    userMerchantRef,
    userId,
    areaId,
    circleId,
    circleCode,
    duration,
    distance,
    endViolations,
    backBy,
    tripEnd,
    userBlocked,
  } = useSelector(selectSelectedTrip) as any;
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const selectTripInvoice = useSelector(selectTripInvoiceData);
  const selectedTripEvents = useSelector(selectTripEvents);
  const vehicle = getVehicleByVIN(cars, chassisNumber);
  const area = getAreaById(areas, areaId);
  const circle = getCircleById(circles!, circleId);
  const isTripDone = status === 'DONE' && !!tripStart;
  const isTripInProgress = ['OPEN', 'IN_PROGRESS'].includes(status);
  const isTripReserved = ['RESERVED'].includes(status);
  const initialUnlockDate = selectedTripEvents?.find(
    (ve: VehicleEventData) => ve.type === 'tripStarted'
  )?.addedAt;

  const onFinishTripClicked = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(openModal('TripCancelModal'));
  };

  const onBlockUserClicked = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      openConfirmModal({
        destructive: true,
        title: intl.formatMessage({
          id: `fleetadmin.trip.block.user.msg`,
        }),
        onConfirm: () => {
          dispatch(updateTrip(id, { userBlocked: true }));
        },
      })
    );
  };

  const onUnblockUserClicked = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      openConfirmModal({
        title: intl.formatMessage({
          id: `fleetadmin.trip.unblock.user.msg`,
        }),
        onConfirm: () => {
          dispatch(updateTrip(id, { userBlocked: false }));
        },
      })
    );
  };
  const onStartTripClicked = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(startTrip(id));
  };

  const onEditReturnTime = () => {
    dispatch(openModal('TripReturnTimeEditModal'));
  };

  const returnTime = tripEnd ? tripEnd : backBy;
  return (
    <>
      <div className="trip-info">
        <ListItem label={'Contract ID'} value={merchantRef} />
        <ListItem
          label={'VIN'}
          value={
            vehicle ? (
              <LinkToVehicle vehicle={vehicle} text={chassisNumber} />
            ) : (
              <></>
            )
          }
        />
        <ListItem
          label={'User code'}
          value={
            <LinkToUser
              userId={userId}
              userCode={userReferralCode}
              label={`${userReferralCode} (${userMerchantRef})`}
            />
          }
        />
        {(circle?.name || circleCode) && (
          <ListItem
            label={'User Circle'}
            value={
              <LinkToCircle circle={circle} text={circle?.name || circleCode} />
            }
          />
        )}
        <ListItem
          label={'Return Area'}
          value={area ? <LinkToArea area={area} text={area?.name} /> : '-'}
        />
        {initialUnlockDate && (
          <ListItem
            label={intl.formatMessage({
              id: 'fleetadmin.trip.info.tripstarted.label',
            })}
            value={<span>{formatDateTime(initialUnlockDate)}</span>}
          />
        )}
        {returnTime && (
          <ListEditItem
            label={intl.formatMessage({
              id: 'fleetadmin.trip.info.backby.label',
            })}
            leftItem={<span>{formatDateTime(returnTime)}</span>}
            rightItem={
              user?.permissions?.fleetManager &&
              isTripInProgress && (
                <img
                  className="edit-button"
                  src={'/images/drawer/edit.svg'}
                  alt="edit"
                  onClick={onEditReturnTime}
                />
              )
            }
          />
        )}
        <ListItem
          label={'Insurance'}
          value={
            !['CANCELLED', 'EXPIRED'].includes(status)
              ? selectTripInvoice?.insurance
              : '-'
          }
        />
        <ListItem
          label={'Duration'}
          value={isTripDone ? formatDuration(duration) : '-'}
        />
        <ListItem
          label={'Mileage'}
          value={isTripDone ? formatDistance(distance) : '-'}
        />
        <ListItem
          label={'End Violations'}
          value={
            isTripDone ? <ViolationList violations={endViolations} /> : '-'
          }
        />
        <ListItem
          label={'Invoice'}
          value={
            isTripDone && selectTripInvoice?.invoiceName ? (
              <InvoiceItem
                invoiceName={selectTripInvoice?.invoiceName}
                invoiceUri={selectTripInvoice?.invoiceUri}
              />
            ) : (
              '-'
            )
          }
        />
        {user?.permissions?.tripsManager &&
          isTripInProgress &&
          (userBlocked ? (
            <div
              className="trip-info-unblock-button"
              onClick={onUnblockUserClicked}
            >
              {intl.formatMessage({
                id: 'fleetadmin.trip.unblock.user',
              })}
            </div>
          ) : (
            <div
              className="trip-info-block-button"
              onClick={onBlockUserClicked}
            >
              {intl.formatMessage({
                id: 'fleetadmin.trip.block.user',
              })}
            </div>
          ))}
        {user?.permissions?.tripsManager && isTripInProgress && (
          <div
            className="trip-info-finish-button"
            onClick={onFinishTripClicked}
          >
            Finish trip
          </div>
        )}
        {user?.permissions?.tripsManager && isTripReserved && (
          <div className="trip-info-finish-button" onClick={onStartTripClicked}>
            Start trip
          </div>
        )}
      </div>
      <style jsx>{tripInfoStyles}</style>
    </>
  );
};

export default TripInfo;
