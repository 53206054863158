import { ViewMode } from '../../../hooks/useViewMode';
import targetsForVehicle from '../assets/targetsForVehicle';
import targetsForArea from '../assets/targetsForArea';
import targetsForClaim from '../assets/targetsForClaim';
import targetsForCare from '../assets/targetsForCare';
import targetsForShare from '../assets/targetsForShare';
import targetsForBooking from '../assets/targetsForBooking';
import targetsForCategory from '../../../components/Filter/assets/targetsForCategory';
import targetsForCircle from '../../../components/Filter/assets/targetsForCircle';
import targetsForPricing from '../../../components/Filter/assets/targetsForPricing';
import targetsForProfile from '../assets/targetsForProfile';
import targetsForUsers from '../assets/targetsForUsers';
import targetsForAdmins from '../assets/targetsForAdmins';
import targetsForReceipts from '../assets/targetsForReceipts';

const getTargets = (viewMode: ViewMode): any => {
  if (viewMode === ViewMode.VEHICLE) {
    return targetsForVehicle;
  }
  if (viewMode === ViewMode.PROFILE) {
    return targetsForProfile;
  }
  if (viewMode === ViewMode.AREA) {
    return targetsForArea;
  }
  if (viewMode === ViewMode.CLAIM) {
    return targetsForClaim;
  }
  if (viewMode === ViewMode.CARE) {
    return targetsForCare;
  }
  if (viewMode === ViewMode.SHARE) {
    return targetsForShare;
  }
  if (viewMode === ViewMode.BOOKING) {
    return targetsForBooking;
  }
  if (viewMode === ViewMode.CATEGORIES) {
    return targetsForCategory;
  }
  if (viewMode === ViewMode.PRICING) {
    return targetsForPricing;
  }
  if (viewMode === ViewMode.CIRCLE) {
    return targetsForCircle;
  }
  if (viewMode === ViewMode.USERS) {
    return targetsForUsers;
  }
  if (viewMode === ViewMode.ADMINS) {
    return targetsForAdmins;
  }
  if (viewMode === ViewMode.RECEIPTS) {
    return targetsForReceipts;
  }
};

export default getTargets;
