import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Modal, Input, Button } from 'antd';
import styles from './AddProfileModal.styles';
import { colors } from '../../theme/theme';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectBrands } from '../../redux/store/appData/fleets';
import { createProfile } from '../../redux/store/userData/profiles';
import SelectorLayout from '../shared/SelectorLayout';
import { useIntl } from 'react-intl';

const AddProfileModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [model, setModel] = useState('');
  const [brandId, setBrandId] = useState('');
  const brands = useSelector(selectBrands);
  const brandsOptions = useMemo(
    () =>
      brands?.map((brand) => ({
        value: brand.id,
        label: brand.name,
      })) || [],
    [brands]
  );

  const visible = useSelector(getIsVisibleModal('createProfileModal'));
  const isDisabled = !(name && brandName && model && brandId);

  const onSelectBrand = (value: any) => {
    setBrandId(value);
  };

  const handleCancel = () => {
    dispatch(closeModal('createProfileModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    batch(() => {
      const profileInputData = {
        name,
        brandId,
        brandName,
        model,
      };
      dispatch(createProfile(profileInputData));
    });
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setName('');
      setBrandName('');
      setModel('');
      setBrandId('');
    }
  }, [visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="add-profile-wrapper"
          title={`Create new`}
          visible={visible}
          width={520}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="add-profile-contents" tabIndex={0}>
            <div className="add-profile-subtitle">Base Data</div>
            <div className="add-profile-input-container">
              <Input
                value={name}
                placeholder="Profile name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="add-profile-input-container">
              <Input
                value={brandName}
                placeholder="Vehicle Brand"
                onChange={(e) => setBrandName(e.target.value)}
              />
            </div>
            <div className="add-profile-input-container">
              <Input
                value={model}
                placeholder="Vehicle Model"
                onChange={(e) => setModel(e.target.value)}
              />
            </div>
            <div className="add-profile-input-container">
              <SelectorLayout
                keyPrefix="brand-select"
                value={brandId}
                placeholder="Select Brand"
                onSelect={onSelectBrand}
                options={brandsOptions}
              />
            </div>
            <div className="button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default AddProfileModal;
