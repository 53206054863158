const profileColumns = [
  { label: 'Name', value: 'name', disabled: true },
  { label: 'Model', value: 'model', disabled: false },
  { label: 'Brand', value: 'brandId', disabled: false },
  { label: 'Brand Name', value: 'brandName', disabled: false },
];

export const defaultSelectedProfileColumns = profileColumns.map(
  (profileColumn) => profileColumn.value
);

export default profileColumns;
