import React from 'react';
import StatusInfo from './StatusInfo';
import TabLayout from '../../components/shared/TabLayout';
import VehicleInfo from './VehicleInfo';
import styles from './CarEditDrawerBody.styles';
import { batch, useDispatch, useSelector } from 'react-redux';
import CarTimeline from './CarTimeline';
import {
  selectCarDrawerActiveTab,
  selectSelectedCar,
  setCarDrawerActiveTab,
} from '../../redux/store/userData/cars';
import { fetchVehicleEventsForCar } from '../../redux/store/userData/vehicleEvents';
import Badge from '../../components/shared/Badge';
import Record from '../../components/CarEditDrawer/Record';
import { carDamages } from '../../redux/store/userData/cars';
import { setDisplayColumns } from '../../redux/store/ui/common';
import { defaultSelectedRecordColumns } from '../ColumnSelector/recordColumns';
import BookingTable from '../../components/CarEditDrawer/BookingTable';

const CarEditDrawerBody: React.FC = () => {
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedCar);
  const selectedActiveTab = useSelector(selectCarDrawerActiveTab);
  const selectedDamages = useSelector(carDamages);
  const carRecords =
    selectedDamages?.length > 0
      ? selectedDamages?.filter(
          // @ts-ignore
          (claim) => claim?.carId === selectedCar?.id
        )
      : ([] as any);
  const tabs = [
    { title: 'Status', content: <StatusInfo /> },
    { title: 'Timeline', content: <CarTimeline /> },
    { title: 'Availability', content: <BookingTable /> },
    { title: 'Info', content: <VehicleInfo /> },
    {
      title: (
        <span>
          Record
          {
            <Badge
              num={
                carRecords?.filter(
                  (claim: { status: string; closeReason: string }) =>
                    !(
                      claim?.status === 'closed' &&
                      claim?.closeReason === 'declined'
                    )
                ).length
              }
            />
          }
        </span>
      ),
      content: (
        <>
          <Record carRecords={carRecords} />
        </>
      ),
    },
  ];

  return (
    <div className="car-edit-drawer-body">
      <div className="head">
        <p className="plate">{selectedCar?.plate ? selectedCar.plate : '-'}</p>
      </div>
      <TabLayout
        tabs={tabs}
        keyName="car-edit-drawer"
        activeTab={selectedActiveTab}
        onChangeTab={(activeKey) => {
          dispatch(
            setCarDrawerActiveTab(
              parseInt(activeKey.split('-').pop() || '0', 10)
            )
          );
          if (activeKey === 'car-edit-drawer-tab-1') {
            batch(() => {
              dispatch(fetchVehicleEventsForCar(selectedCar?.id));
            });
          }
          if (activeKey === 'car-edit-drawer-tab-3') {
            batch(() => {
              dispatch(
                setDisplayColumns({ record: defaultSelectedRecordColumns })
              );
            });
          }
        }}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default CarEditDrawerBody;
