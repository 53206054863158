import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .column-panel-wrapper {
    box-sizing: border-box;
    padding: 40px 16px 16px;
    background: #f1f1f1;
  }
  .column-panel-wrapper :global(.ant-checkbox-group) {
    display: flex !important;
    flex-direction: column !important;
  }
  .column-panel-wrapper :global(.ant-checkbox-group-item) {
    font-size: 16px;
    margin-bottom: 8px;
  }
  // .column-panel-wrapper :global(.ant-checkbox-checked .ant-checkbox-inner) {
  //   ${common.checkbox}
  // }
  // .column-panel-wrapper
  //   :global(
  //     .ant-checkbox-group-item:hover .ant-checkbox-checked .ant-checkbox-inner
  //   ) {
  //   ${common.checkbox}
  // }
  // .column-panel-wrapper
  //   :global(.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner) {
  //   ${common.checkboxDisabled}
  // }
`;

export default style;
