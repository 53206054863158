const circleColumns = [
  { label: 'Name', value: 'name', disabled: true },
  { label: 'Type', value: 'circleType', disabled: false },
  { label: 'Code', value: 'circleCode', disabled: false },
  { label: 'Created at', value: 'created', disabled: false },
  { label: 'Last modified at', value: 'lastModifiedAt', disabled: false },
  { label: 'Last modified by', value: 'lastModifiedBy', disabled: false },
];

export const defaultSelectedCircleColumns = circleColumns.map(
  (circleColumn) => circleColumn.value
);

export default circleColumns;
