import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Input, Select } from 'antd';
import DrawerModal from '../../shared/DrawerModal';
import styles from './UserOrgContactModal.styles';
import {
  closeModal,
  getIsVisibleModal,
} from '../../../redux/store/ui/common/index';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { selectAuth } from '../../../redux/store/userData/user';

interface UserInput {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  emailVerified?: boolean;
}

const UserOrgContactModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const visibleModal = useSelector(getIsVisibleModal('UserOrgContactModal'));

  const user = useSelector(selectAuth);

  const selectedBrand = user.brands.find(
    (it) => it.id === selectedUser?.brandId
  );
  const supportedPhoneCountries =
    selectedBrand?.allowedPhoneNumberCountries?.map((country) =>
      country.toLowerCase()
    );

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneApproved, setPhoneApproved] = useState(
    selectedUser?.verified?.phoneNumber || false
  );
  const [firstName, setFirstName] = useState(selectedUser?.firstName || '');
  const [lastName, setLastName] = useState(selectedUser?.lastName || '');
  const [email, setEmail] = useState('');

  const [emailApproved, setEmailApproved] = useState(
    selectedUser?.emailVerified || false
  );

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let userInput: UserInput = {};

    if (firstName !== selectedUser?.firstName) {
      userInput.firstName = firstName;
    }
    if (lastName !== selectedUser.lastName) {
      userInput.lastName = lastName;
    }

    if (phoneNumber.value != selectedUser.phoneNumber) {
      userInput.phoneNumber = phoneNumber.value;
    }

    if (email != selectedUser?.email) {
      userInput.email = email;
    }

    if (emailApproved && !selectedUser.emailVerified) {
      userInput.emailVerified = emailApproved;
    }

    if (phoneApproved && selectedUser?.verified.phoneNumber == false) {
      // @ts-ignore
      userInput.phoneNumberVerified = phoneApproved;
    }

    if (Object.keys(userInput).length > 0) {
      dispatch(updateUser(selectedUser.id, userInput));
      dispatch(closeModal('UserOrgContactModal'));
    }
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('UserOrgContactModal'));
  };

  const onPhoneNumberChanged = (value: string, data: any) => {
    let newVal = value.startsWith(data?.dialCode)
      ? value.slice(data?.dialCode?.length)
      : value;
    const zeroRegex = new RegExp(`^[0]*`, 'gi');
    newVal = newVal.replace(zeroRegex, '');
    const dialCodeRegex = new RegExp(`^${data?.dialCode}`, 'gi');
    newVal = newVal.replace(dialCodeRegex, '');
    newVal = `+${data?.dialCode}${newVal}`;

    setPhoneNumber({
      dialCode: data?.dialCode,
      value: newVal,
      error: '',
    });
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onClickEmailApproved = (e: any) => {
    setEmailApproved(e.target.checked);
  };

  const onClickPhoneApproved = (e: any) => {
    setPhoneApproved(e.target.checked);
  };

  useEffect(() => {
    if (visibleModal) {
      setFirstName(selectedUser?.firstName || '');
      setLastName(selectedUser?.lastName || '');
      setPhoneNumber({
        dialCode: '',
        value: selectedUser?.phoneNumber,
        error: '',
      });
      setEmail(selectedUser?.email);
    }
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('UserOrgContactModal'));
        }}
      >
        <div className="user-org-contact-modal">
          <div className="contact-modal-wrapper">
            <div className="user-org-contact-modal-inputs">
              <p className="user-org-contact-title">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.contact`,
                })}
              </p>

              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.firstname`,
                })}
              </div>
              <Input
                value={firstName}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.firstname`,
                })}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ marginRight: 12 }}
                disabled={selectedUser?.firstNameVerified}
              />

              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.lastname`,
                })}
              </div>
              <Input
                value={lastName}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.lastname`,
                })}
                style={{ marginBottom: 12 }}
                onChange={(e) => setLastName(e.target.value)}
                disabled={selectedUser?.lastNameVerified}
              />

              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.phone`,
                })}
              </div>
              <PhoneInput
                country={selectedBrand?.defaultCountryCode || 'de'}
                onlyCountries={supportedPhoneCountries}
                value={phoneNumber?.value}
                onChange={onPhoneNumberChanged}
                inputClass="add-phone-input"
                inputProps={{
                  required: true,
                  autoComplete: 'tel',
                }}
              />
              <Checkbox
                disabled={selectedUser?.verified.phoneNumber}
                style={{ marginBottom: 8, marginTop: 8 }}
                value={phoneApproved}
                onChange={onClickPhoneApproved}
              >
                {intl.formatMessage({
                  id: 'fleetadmin.users.phone.verify',
                })}
              </Checkbox>
            </div>
            <div className="user-org-contact-modal-inputs">
              <div className="label">
                {intl.formatMessage({
                  id: `fleetadmin.users.info.email`,
                })}
              </div>
              <Input
                value={email}
                placeholder={intl.formatMessage({
                  id: `fleetadmin.users.info.email`,
                })}
                onChange={onChangeEmail}
                disabled={selectedUser?.emailVerified}
              />
            </div>
            <Checkbox
              disabled={selectedUser?.emailVerified}
              style={{ marginBottom: 8, marginTop: 8 }}
              value={emailApproved}
              onChange={onClickEmailApproved}
            >
              {intl.formatMessage({
                id: 'fleetadmin.users.info.emailVerified',
              })}
            </Checkbox>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `fleetadmin.admins.info.update`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `fleetadmin.admins.info.cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserOrgContactModal;
