import React from 'react';
import { IntlShape } from 'react-intl';
import isDesktop from '../../utils/sizeHelpers';
import {
  alphabeticalSorterFor,
  chronologicalSorterFor,
  getRenderLink,
  renderDate,
} from '../shared/TableLayout';
import { renderNumber, renderText } from '../shared/DrawerLayout/renderText';
import { colors } from '../../theme/theme';
import { formatPrice } from '../../utils';
import Tag from 'antd/lib/tag';

type getColumnsArgs = {
  intl: IntlShape;
};

const getColumns = ({ intl }: getColumnsArgs) => {
  const renderType = (type: string) =>
    renderText(
      intl.formatMessage({
        id: `fleetadmin.receipts.type.${type}`,
      })
    );

  const renderAction = (receiptUrl: string) => (
    <span
      style={{ color: `${colors.blue}`, cursor: 'pointer' }}
      onClick={() => window.open(receiptUrl)}
    >
      {intl.formatMessage({
        id: `fleetadmin.receipts.download`,
      })}
    </span>
  );

  const renderMerchants = (merchantNo: any[], record: any) => {
    return (
      <div>
        {record?.merchantSystemData?.map(
          (merchant) =>
            merchant?.merchantReference &&
            merchant?.merchantSystem && (
              <Tag
                key={merchant?.merchantReference}
                style={{
                  borderColor: '#d3d3d3',
                  backgroundColor: 'rgba(242, 242, 242, 0.6)',
                  margin: 2,
                }}
                className="merchant-tag"
              >
                {`${merchant?.merchantReference}_${merchant?.merchantSystem}`}
              </Tag>
            )
        )}
      </div>
    );
  };

  return [
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.receiptno',
      }),
      dataIndex: 'receiptNo',
      ellipsis: true,
      width: isDesktop() ? 200 : 150,
      fixed: 'left' as 'left',
      render: getRenderLink(),
      sorter: alphabeticalSorterFor('receiptNo'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.receipttype',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'receiptType',
      render: renderType,
      sorter: alphabeticalSorterFor('receiptType'),
    },
    {
      title: 'Action',
      dataIndex: 'receiptUrl',
      width: 170,
      render: renderAction,
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.receiptmerchantreference',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'receiptMerchantReference',
      render: renderText,
      sorter: alphabeticalSorterFor('receiptMerchantReference'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.receiptdate',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'receiptDate',
      render: renderDate,
      sorter: chronologicalSorterFor('receiptDate'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.usercode',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'userCode',
      render: renderText,
      sorter: alphabeticalSorterFor('userCode'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.user.table.merchantusereference',
      }),
      dataIndex: 'merchantUserReference',
      width: 250,
      render: renderMerchants,
      sorter: alphabeticalSorterFor('merchantUserReference'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.tripcode',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'tripCode',
      render: renderText,
      sorter: alphabeticalSorterFor('tripCode'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.tripreference',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'tripReference',
      render: renderText,
      sorter: alphabeticalSorterFor('tripReference'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.vatrate',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'vatRate',
      render: renderNumber,
      sorter: alphabeticalSorterFor('vatRate'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.vatamount',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'vatAmount',
      render: formatPrice,
      sorter: alphabeticalSorterFor('vatAmount'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.grossamount',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'grossAmount',
      render: formatPrice,
      sorter: alphabeticalSorterFor('grossAmount'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.currency',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'currency',
      render: renderText,
      sorter: alphabeticalSorterFor('currency'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.collection',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'collection',
      render: renderText,
      sorter: alphabeticalSorterFor('collection'),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.receipts.table.createdby',
      }),
      width: 150,
      ellipsis: true,
      dataIndex: 'createdBy',
      render: renderText,
      sorter: chronologicalSorterFor('createdBy'),
    },
  ];
};

export default getColumns;
