import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import styles from './ReceiptsCreateModal.styles';
import { getIsVisibleModal, closeModal } from '../../../redux/store/ui/common';
import { useIntl } from 'react-intl';
import { selectToken } from '../../../redux/store/userData/user/selectors';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

type PhoneReportModalProps = {
  visible?: boolean;
};

const ReceiptsCreateModal: React.FC<PhoneReportModalProps> = ({ visible }) => {
  const intl = useIntl();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const _visible = useSelector(getIsVisibleModal('ReceiptsCreateModal'));
  const [erroredRows, setErroredRows] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => {
    dispatch(closeModal('ReceiptsCreateModal'));
  };

  const uploadProps = {
    name: 'file',
    accept: '.csv',
    fileList,
    multiple: false,
    customRequest: async (options) => {
      const { file, onSuccess, onError } = options;
      let parsedCsvRows = null;
      await Papa.parse(file, {
        complete: (results) => (parsedCsvRows = results),
      });
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/fleets/receipts`,
          {
            method: 'POST',
            headers: {
              Authorization: token,
            },
            body: formData,
          }
        );
        const data = await response.json();
        if (response.ok) {
          if (data.addedTransactions > 0) {
            message.success(
              `${data.addedTransactions} receipts uploaded successfully.`
            );
            onSuccess(data, response);
          }
          setFileList([]);
          if (data?.errors?.entry?.length > 0 && parsedCsvRows) {
            // Start the erroredRows array with the column names
            const erroredRows = [parsedCsvRows.data[0].concat(['error'])];
            // Create a new array of errored rows based on the error entries in the response
            data.errors.entry.forEach((errorEntry) => {
              const errorIndex = parseInt(errorEntry.key.split(':')[0]);
              // Adjust for the column names at index 0
              const errorRow = parsedCsvRows.data[errorIndex + 1];
              if (errorRow) {
                // Add the error message to the row and push it
                erroredRows.push(errorRow.concat([errorEntry.value]));
              }
            });
            setErroredRows(erroredRows);
          } else {
            setTimeout(() => handleCancel(), 1000);
          }
        } else {
          message.error(`${file.name} file upload failed.`);
          onError();
        }
      } catch (error) {
        message.error(`${file.name} file upload failed.`);
        onError(error);
      }
    },
    onChange(info) {
      setFileList(info.fileList.filter((file) => !!file.status));
    },
  };

  const onDownloadPress = (event: any, done: () => void) => {
    done();
  };

  useEffect(() => {
    setFileList([]);
    setErroredRows(null);
  }, [_visible]);
  return (
    <>
      <div>
        <Modal
          wrapClassName="add-receipt-wrapper"
          title={intl.formatMessage({
            id: 'fleetadmin.receipts.create.title',
          })}
          visible={visible || _visible}
          width={520}
          style={{ top: 'calc(30%)' }}
          onCancel={handleCancel}
          footer={null}
        >
          {/*@ts-ignore*/}
          <div>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {intl.formatMessage({
                  id: 'fleetadmin.receipts.upload.title',
                })}
              </p>
              <p className="ant-upload-hint">
                {intl.formatMessage({
                  id: 'fleetadmin.receipts.upload.description',
                })}
              </p>
            </Dragger>
          </div>
          {erroredRows?.length > 0 && (
            <CSVLink
              filename="failed-receipts.csv"
              // @ts-ignore
              data={erroredRows}
              asyncOnClick
              onClick={onDownloadPress}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'red',
                marginTop: 12,
              }}
            >
              <DownloadOutlined style={{ color: 'red', marginRight: 5 }} />
              {`${erroredRows?.length - 1} ${intl.formatMessage({
                id: 'fleetadmin.receipts.failed.msg',
              })}`}
            </CSVLink>
          )}
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default ReceiptsCreateModal;
