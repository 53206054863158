import css from 'styled-jsx/css';
import { zIndex, common } from '../../../theme/theme';

const style = css.global`
  .add-admin-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .add-admin-wrapper .ant-modal-header {
    padding: 32px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .add-admin-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .add-admin-wrapper .ant-modal-body {
    display: flex;
    padding: 15px;
    padding-top: 0;
  }
  .add-admin-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  .add-admin-contents {
    // width: 400px;
  }
  .add-admin-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding: 0 17px;
    margin-top: 5px;
  }
  .add-admin-subtitle {
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .input-container {
    width: 100%;
    padding: 16px;
  }
  .add-admin-input-row-container {
    display: flex;
    justify-content: space-between;
  }
  .add-admin-input-container {
    display: flex;
    flex-direction: column;
  }
  .admin-switch-wrapper {
    margin-bottom: 3px;
  }
  .button-container {
    padding: 8px 16px 8px 16px;
  }
  .add-admin-wrapper :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .add-admin-wrapper :global(.submit-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
  .error {
    color: red;
    font-size: 11px;
    margin-bottom: 0;
    margin: 4px;
  }
  .sub-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .separator-slash {
    margin: 0 3px 2px 3px;
  }
  .action {
    color: #1890ff;
    cursor: pointer;
  }
`;

export default style;
