import css from 'styled-jsx/css';

const style = css`
  .profile-gallery-wrapper {
    min-height: 100%;
    padding: 10px 24px;
  }
  .profile-image-wrapper {
    position: relative;
    margin-bottom: 8px;
  }
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .trashTouchable {
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 4px;
    padding: 4px;
    background-color: #dee3e9;
    cursor: pointer;
  }
  .trash {
    width: 24px;
    height: 24px;
  }
  :global(.upload-photo.ant-btn) {
    margin-top: 5px;
    padding: 0;
  }
`;

export default style;
