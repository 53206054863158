import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .car-edit-drawer-body {
    background: white;
    min-height: 100%;
  }
  .head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 26px 15px 26px;
    background: ${colors.backgroundContentMain};
  }
  .plate {
    color: #000;
    font-size: 28px;
    font-weight: 500;
    margin-right: auto;
    margin-bottom: 0;
    display: inline-block;
  }
`;

export default style;
