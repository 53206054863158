import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ClaimStatusLabel.styles';

type ClaimStatusLabelProps = {
  status: string;
  style?: any;
};

const ClaimStatusLabel: React.FC<ClaimStatusLabelProps> = ({
  status,
  style,
}) => {
  return (
    <p style={style} className={status}>
      <FormattedMessage id={`fleetadmin.claimStatus.${status}`} />
      <style jsx>{styles}</style>
    </p>
  );
};

export default ClaimStatusLabel;
