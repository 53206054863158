const carColumns = [
  { label: 'Vehicle VIN', value: 'chassisNumber', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Location', value: 'position', disabled: false },
  { label: 'AccessKit type', value: 'vehicleConnection', disabled: false },
  { label: 'LastStatusChange', value: 'statusChangedAt', disabled: false },
  { label: 'Equipped', value: 'equippedSince', disabled: false },
  {
    label: 'First ReleasedReady',
    value: 'firstReleaseReadyAt',
    disabled: false,
  },
  { label: 'Plate', value: 'plate', disabled: false },
  { label: 'Model', value: 'model', disabled: false },
  { label: 'Fleet', value: 'fleetId', disabled: false },
  { label: 'Area', value: 'areaId', disabled: false },
  {
    label: 'Battery voltage',
    value: 'lastStatus.batteryVoltage',
    disabled: false,
  },
  { label: 'Cloudbox status', value: 'lastStatus.online', disabled: false },
  { label: 'Fuel status', value: 'lastStatus.fuelLevel', disabled: false },
  { label: 'Key', value: 'lastStatus.keyFobIn', disabled: false },
  { label: 'FuelCard', value: 'lastStatus.fuelCardIn', disabled: false },
  { label: 'Action', value: 'operation', disabled: false },
];

export const defaultSelectedCarColumns = carColumns
  .map((carColumn) => carColumn.value)
  .filter((carColumn) => !carColumn.startsWith('lastStatus'));

export default carColumns;
