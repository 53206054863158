import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styles from './LinkToUser.styles';
import {
  fetchDuplicateById,
  fetchUserById,
  fetchUserDuplicates,
  fetchUserTransactions,
  selectUserLoading,
} from '../../redux/store/userData/users';
import { selectAuth } from '../../redux/store/userData/user';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingIcon from './LoadingIcon';

interface Props {
  userId: string;
  userCode: string;
  label?: string;
  isDuplicate?: boolean;
}

const LinkToUser: React.FC<Props> = ({
  userId,
  userCode,
  label,
  isDuplicate = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const isUserManager = user?.permissions?.userManager;
  const isUserLoading = useSelector(selectUserLoading);
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  const onClickHandler = (e: { preventDefault: () => void }) => {
    if (isUserManager && !isUserLoading && openedDrawersCount < 2) {
      if (isDuplicate) {
        dispatch(fetchDuplicateById(userId!));
      } else {
        dispatch(
          fetchUserById(userId!, () => {
            batch(() => {
              dispatch(fetchUserDuplicates(userId!));
              dispatch(
                fetchUserTransactions('account', userId, 0, 20, false, () => {
                  dispatch(
                    fetchUserTransactions('deposit', userId, 0, 20, true)
                  );
                })
              );
            });
          })
        );
      }
    }
  };

  return (
    <>
      {openedDrawersCount < 2 ? (
        <span
          className={isUserManager ? 'link' : 'value-wrapper'}
          onClick={onClickHandler}
        >
          {label || userCode || ''}
          {isUserLoading && <LoadingIcon />}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=USERS&type=TABLE&pageSize=50&pageNumber=1&userId=${userId}`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link">{label || userCode || ''}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToUser;
