const pricingColumns = [
  { label: 'Name', value: 'name', disabled: true },
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Version', value: 'version', disabled: false },
  { label: 'Last modified at', value: 'lastModifiedAt', disabled: false },
  { label: 'Valid from', value: 'validFrom', disabled: false },
  { label: 'Vehicle validation', value: 'fleets', disabled: false },
  { label: 'User validation', value: 'circles', disabled: false },
  // { label: 'PDF url', value: 'pdfUrl', disabled: false },
];

export const defaultSelectedPricingColumns = pricingColumns.map(
  (pricingColumn) => pricingColumn.value
);

export default pricingColumns;
