import * as React from 'react';
import {
  getRenderLink,
  renderDateTime,
  alphabeticalSorterFor,
  chronologicalSorterFor,
  alphabeticalSorter,
} from '../shared/TableLayout';
import isDesktop from '../../utils/sizeHelpers';
import BookingStatus from '../shared/BookingStatus';
import { AreaData, FleetAdminCarData } from '../../@types';
import getAreaNameById from '../../utils/getAreaNameById';
import { getVehicleById } from '../../utils/getVehicleById';

const getColumns = (
  intl: any,
  cars: FleetAdminCarData[] | null,
  areas: AreaData[] | null
): any[] => {
  const renderStatus = (status: any) => <BookingStatus status={status} />;
  const renderArea = (areaId: string) => (
    <span>{getAreaNameById(areas, areaId)}</span>
  );
  const renderCarPlate = (carId: string) => (
    <span>{getVehicleById(cars, carId)?.plate || '-'}</span>
  );

  const renderType = (type: string) => (
    <span>
      {intl.formatMessage({ id: `fleetadmin.booking.type.${type}` }) || '-'}
    </span>
  );

  return [
    {
      title: 'Booking ID',
      dataIndex: 'presentableId',
      render: getRenderLink(),
      ellipsis: true,
      width: isDesktop() ? 130 : 100,
      fixed: 'left' as 'left',
      sorter: alphabeticalSorterFor('presentableId'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      width: 170,
      sorter: alphabeticalSorterFor('status'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: renderType,
      width: 170,
      sorter: alphabeticalSorterFor('type'),
    },
    {
      title: 'Created at',
      dataIndex: 'created',
      ellipsis: true,
      width: 170,
      render: renderDateTime,
      sorter: chronologicalSorterFor('created'),
    },
    {
      title: 'Car Plate',
      dataIndex: 'confirmedCarId',
      width: 150,
      render: renderCarPlate,
      sorter: (carA: FleetAdminCarData, carB: FleetAdminCarData) => {
        const carPlateA = getVehicleById(cars, carA.id)?.plate;
        const carPlateB = getVehicleById(cars, carB.id)?.plate;
        return alphabeticalSorter(carPlateA, carPlateB);
      },
    },
    {
      title: 'From',
      dataIndex: 'from',
      ellipsis: true,
      width: 170,
      render: renderDateTime,
      sorter: chronologicalSorterFor('from'),
    },
    {
      title: 'To',
      dataIndex: 'to',
      ellipsis: true,
      width: 170,
      render: renderDateTime,
      sorter: chronologicalSorterFor('to'),
    },
    {
      title: 'Area',
      dataIndex: 'areaId',
      width: 170,
      render: renderArea,
      sorter: (areaA: AreaData, areaB: AreaData) => {
        const areaNameA = getAreaNameById(areas, areaA.id);
        const areaNameB = getAreaNameById(areas, areaB.id);
        return alphabeticalSorter(areaNameA, areaNameB);
      },
    },
  ];
};

export default getColumns;
