import React from 'react';
import styles from './LinkToPricing.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectPricing } from '../../redux/store/appData/pricings';
import { selectTrip } from '../../redux/store/userData/trips';
import { selectArea } from '../../redux/store/appData/areas';
import { selectSelectedDrawersCount } from '../../redux/store/ui/common';
import { ExportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  pricing?: any;
  params?: any;
}

const LinkToPricing: React.FC<Props> = ({ text, pricing }) => {
  const dispatch = useDispatch();
  const openedDrawersCount = useSelector(selectSelectedDrawersCount);

  // Not assigned case
  if (!pricing) {
    return <span>{text || '-'}</span>;
  }

  const onClickHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (pricing != null && openedDrawersCount < 2) {
      dispatch(selectPricing(pricing));
      dispatch(selectTrip(null));
      dispatch(selectArea(null));
    }
  };

  return (
    <>
      {openedDrawersCount < 2 ? (
        <span className="link" onClick={onClickHandler}>
          {text}
        </span>
      ) : (
        <Link
          replace={false}
          to={{
            pathname: '/dashboard',
            search: `?vm=PRICING&type=TABLE&pageSize=50&pageNumber=1&pricingId=${pricing.id}`,
          }}
          target="_blank"
        >
          <a className="external-link-wrapper" target="_blank" rel="noreferrer">
            <span className="link">{text}</span>
            <ExportOutlined
              color="#0077be"
              style={{ fontSize: '18px', marginLeft: '10px' }}
            />
          </a>
        </Link>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToPricing;
