import React from 'react';
import { useSelector } from 'react-redux';
import TabLayout from '../../components/shared/TabLayout';
import styles from './PricingEditDrawerBody.styles';
import PricingInfo from './PricingInfo';
import { selectSelectedPricing } from '../../redux/store/appData/pricings';
import PricingTable from '../../components/PricingEditDrawer/PricingTable';

const PricingEditDrawerBody = () => {
  const selectedPricing = useSelector(selectSelectedPricing);

  const tabs = [
    { title: 'Status', content: <PricingInfo /> },
    { title: 'Einstellungen', content: <PricingTable /> },
  ];

  return (
    <>
      <div className="pricing-edit-drawer-body">
        <div className="pricing-name-wrapper">
          <p className="name" title={selectedPricing?.name}>
            {selectedPricing?.name?.length > 20
              ? `${selectedPricing?.name.slice(0, 20)}...`
              : selectedPricing?.name}
          </p>
        </div>
        <TabLayout tabs={tabs} keyName="pricing-edit-drawer" />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default PricingEditDrawerBody;
