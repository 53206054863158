import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import {
  selectViewMode,
  closeCircleDrawerAndSetQueries,
} from '../../redux/store/ui/common';
import { selectSelectedCircle } from '../../redux/store/userData/circles';
import CircleEditDrawerBody from '../../components/CircleEditDrawer/CircleEditDrawerBody';
import { ViewMode } from '../../hooks/useViewMode';
import PricingEditDrawer from '../../components/PricingEditDrawer';

const CircleEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const selectedCircle = useSelector(selectSelectedCircle);
  const visible = selectedCircle != null;

  const handleOnClose = () => {
    dispatch(closeCircleDrawerAndSetQueries());
  };

  return (
    selectedCircle && (
      <DrawerLayout
        isCircleEditDrawer
        visible={visible}
        title={selectedCircle.id}
        nestedDrawers={
          <>{viewMode !== ViewMode.PRICING && <PricingEditDrawer />}</>
        }
        body={<CircleEditDrawerBody />}
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default CircleEditDrawer;
