import React from 'react';
import { SVGOverlay } from 'react-map-gl';
import { colors } from '../../theme/theme';
import { PARKING_AREAS } from './parkingAreasData';

const ParkingAreas: React.FC = () => {
  const redraw = ({ project }: any) => {
    return PARKING_AREAS?.features.map((area) => {
      const coordinates = area.geometry.coordinates[0];

      const points = coordinates.map((coordinate: any[]) => {
        return project([coordinate[0], coordinate[1]]).join(',');
      });

      return (
        <polygon
          key="parking-areas"
          id="parking-areas"
          points={points.join(' ')}
          style={{
            fill: colors.blue,
            fillOpacity: 0.2,
            stroke: colors.blue,
            strokeOpacity: 1,
            strokeWidth: 2,
          }}
        />
      );
    });
  };

  return <SVGOverlay redraw={redraw} />;
};

export default ParkingAreas;
