import React from 'react';
import styles from './LinkToGoogleMap.styles';

interface Props {
  position: any;
}

const LinkToGoogleMap: React.FC<Props> = ({ position }) => {
  if (!position) {
    return <span>-</span>;
  }

  return (
    <>
      <a
        href={`https://maps.google.com/?q=${position}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {position}
      </a>
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToGoogleMap;
