import css from 'styled-jsx/css';

const style = css`
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .edit-button {
    cursor: pointer;
  }
  .disabled-edit-button {
    cursor: not-allowed;
  }
  .add-button {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export default style;
