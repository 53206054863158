import css from 'styled-jsx/css';

const style = css`
  .wrapper {
    margin: 0 24px;
    display: flex;
  }
  .label {
    font-size: 12px;
    margin: 16px 0 0;
  }
  .value {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: #000;
    padding-bottom: 2px;
    border-bottom: 1px solid #979797;
  }
  .vin {
    width: 200px;
    margin-right: 16px;
  }
  .plate {
    width: 136px;
  }
`;

export default style;
