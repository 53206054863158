import moment from 'moment';

const formatDate = (dateTime: moment.MomentInput) => {
  if (dateTime === '') {
    return '';
  }
  return moment(dateTime).format('DD MMM. YYYY');
};

export default formatDate;

export const ANT_PICKERS_FORMAT = 'YYYY-MM-DD';
