import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  noVehicleStyles,
  assignedVehiclesStyles,
} from './AssignedVehicles.styles';
import { selectCategoryAssignedVehicles } from '../../redux/store/userData/categories';
import { selectCar } from '../../redux/store/userData/cars/actions';

const NoVehicle = () => {
  return (
    <>
      <div>
        <p className="message">
          There are no vehicles assigned to this category
        </p>
      </div>
      <style jsx>{noVehicleStyles}</style>
    </>
  );
};

const AssignedVehicles = () => {
  const dispatch = useDispatch();
  const assignedVehicles = useSelector(selectCategoryAssignedVehicles);

  return (
    <>
      <ul
        className="assigned-vehicles"
        style={!assignedVehicles?.length ? { borderBottom: 0 } : {}}
      >
        {assignedVehicles?.length === 0 && <NoVehicle />}
        {assignedVehicles?.length! > 0 &&
          assignedVehicles?.map((vehicle, id) => {
            const handleOnClick = (e: { preventDefault: () => void }) => {
              e.preventDefault();
              dispatch(selectCar(vehicle));
            };
            return (
              <li
                className="assigned-vehicle"
                key={`assigned-vehicle-${id}`}
                onClick={handleOnClick}
              >
                {vehicle.plate}
              </li>
            );
          })}
      </ul>
      <style jsx>{assignedVehiclesStyles}</style>
    </>
  );
};

export default AssignedVehicles;
