import css from 'styled-jsx/css';

const style = css`
  .list-item-wrapper {
    margin: 0 24px 16px;
    border-bottom: 1px solid #979797;
    padding-bottom: 2px;
  }
  .label {
    font-size: 12px;
    margin: 16px 0 0;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .label:first-child {
    margin: 0;
  }
  .value-wrapper {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    color: rgb(0, 0, 0, 0.8);
  }
`;

export default style;
