import css from 'styled-jsx/css';
import { common } from '../../theme/theme';

const style = css`
  .value-selector {
    padding-bottom: 49px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    padding: 0 16px;
    width: 100%;
    border-top: 1px solid #c9c9c9;
  }
  .value-selector :global(.add-filter-button) {
    ${common.button}
    ${common.submitButton}
  }
  .value-selector :global(.add-filter-button:hover) {
    ${common.button}
    ${common.submitButton}
  }
`;

export default style;
