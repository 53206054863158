import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../shared/DrawerModal';
import styles from './FinishTripModal.styles';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';
import {
  selectSelectedTrip,
  finishTrip,
  finishTripViolations,
  selectFinishTripViolations,
} from '../../redux/store/userData/trips';
import { selectSelectedCare } from '../../redux/store/userData/cares';
import { useIntl } from 'react-intl';

const FinishTripModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedCare = useSelector(selectSelectedCare);
  const finishViolations = useSelector(selectFinishTripViolations);
  const visibleModal = useSelector(getIsVisibleModal('finishTripModal'));

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(finishTrip(selectedTrip?.id || selectedCare?.id, true));
    close();
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    close();
  };

  const close = () => {
    dispatch(closeModal('finishTripModal'));
    // clear finish trip violations
    dispatch(finishTripViolations([]));
  };

  return (
    <>
      <DrawerModal visible={visibleModal} onClose={close}>
        <div className="finish-trip-modal">
          <div className="finish-trip-modal-body-wrapper">
            <p className="finish-trip-label">Finish trip check</p>
            <div className="finish-trip-description">
              <p>
                {intl.formatMessage({
                  id: 'trip.finish.force.dialog.message',
                })}
              </p>
              {finishViolations.map((violation) => (
                <p key={violation}>{` - ${intl.formatMessage({
                  id: 'trip.finish.force.violation' + violation,
                })}`}</p>
              ))}
            </div>
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: 'trip.finish.force.dialog.ok',
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: 'trip.finish.force.dialog.cancel',
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default FinishTripModal;
