import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './AddFleetToCircleModal.styles';
import {
  addFleetToCircle,
  selectSelectedCircle,
} from '../../redux/store/userData/circles';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import FleetSelector from '../shared/FleetSelector';
import { selectFleets } from '../../redux/store/appData/fleets';
import { useIntl } from 'react-intl';

const AddFleetToCircleModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const fleets = useSelector(selectFleets);
  const selectedCircle = useSelector(selectSelectedCircle);
  const availableFleets = useMemo(() => {
    const assignedFleets = selectedCircle?.fleets?.map((fleet) => fleet.id);
    if (assignedFleets) {
      return fleets?.filter((fleet) => !assignedFleets?.includes(fleet.id));
    }
    return fleets || [];
  }, [fleets, selectedCircle?.fleets]);

  const [selectedFleet, setSelectedFleet] = useState<string>('');
  const visibleModal = useSelector(getIsVisibleModal('addFleetToCircleModal'));

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    batch(() => {
      dispatch(addFleetToCircle(selectedCircle?.id!, selectedFleet));
      dispatch(closeModal('addFleetToCircleModal'));
    });
  };

  const handleOnCancel = (e: any) => {
    e.preventDefault();
    dispatch(closeModal('addFleetToCircleModal'));
  };

  const onSelectFleet = (fleetId: string) => {
    setSelectedFleet(fleetId);
  };

  useEffect(() => {
    setSelectedFleet('');
  }, [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('addFleetToCircleModal'));
        }}
      >
        <div className="add-fleet-modal">
          <div className="add-fleet-wrapper">
            <p className="label">Select Fleet</p>
            <FleetSelector
              fleets={availableFleets!}
              onSelect={onSelectFleet}
              value={selectedFleet}
            />
          </div>
          <Button className="submit-button" onClick={handleOnSubmit}>
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default AddFleetToCircleModal;
