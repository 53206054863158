import isDesktop from '../../utils/sizeHelpers';
import {
  alphabeticalSorterFor,
  chronologicalSorterFor,
} from '../shared/TableLayout';

const getFeeItemColumns = () => {
  return [
    {
      title: 'ID (Gebühr)',
      dataIndex: 'code',
      width: 150,
      fixed: 'left' as 'left',
      // render: renderText,
      sorter: alphabeticalSorterFor('code'),
    },
    {
      title: 'Name (de)',
      dataIndex: 'name',
      width: 150,
      // render: renderText,
      sorter: alphabeticalSorterFor('name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      // render: renderText,
      sorter: alphabeticalSorterFor('type'),
    },
    {
      title: 'Betrag (brutto, EUR)',
      dataIndex: 'price',
      width: isDesktop() ? 200 : 150,
      // render: renderText,
      sorter: alphabeticalSorterFor('price'),
    },
    {
      title: 'Einheit',
      dataIndex: 'unit',
      width: isDesktop() ? 200 : 150,
      // render: renderText,
      sorter: chronologicalSorterFor('unit'),
    },
  ];
};

export default getFeeItemColumns;
