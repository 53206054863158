import css from 'styled-jsx/css';
import { colors } from '../../theme/theme';

const style = css`
  .link {
    color: ${colors.blue};
    cursor: pointer;
  }
`;

export default style;
