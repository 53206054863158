import React, { ReactNode } from 'react';
import styles from './ListEditItem.styles';
import ListItem from '../../components/shared/ListItem';
import { useIntl } from 'react-intl';

interface Props {
  label?: ReactNode;
  leftItem: ReactNode;
  rightItem?: ReactNode;
  style?: any;
  verified?: boolean;
  onClickEdit?: () => void;
  onClickAdd?: () => void;
}

const ListEditItem: React.FC<Props> = ({
  label,
  leftItem,
  rightItem,
  style = {},
  verified,
  onClickEdit,
  onClickAdd,
}) => {
  const intl = useIntl();
  const disableEditing = verified != undefined && leftItem && verified === true;
  return (
    <>
      <ListItem
        label={label}
        value={
          <div className="wrapper">
            <span>
              {verified != undefined && leftItem && (
                <img
                  title={intl.formatMessage({
                    id: `fleetadmin.users.info.${
                      verified ? 'verified' : 'unverified'
                    }`,
                  })}
                  alt={verified ? 'verified' : 'unverified'}
                  src={`/images/drawer/${
                    verified ? 'verified_green' : 'verified_grey'
                  }.svg`}
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 4,
                    marginBottom: 2,
                  }}
                />
              )}
              {leftItem && leftItem}
            </span>
            {rightItem && rightItem}
            {onClickEdit != undefined && (
              <img
                className={
                  disableEditing ? 'disabled-edit-button' : 'edit-button'
                }
                src={`/images/drawer/${
                  disableEditing ? 'disabledEdit' : 'edit'
                }.svg`}
                alt="edit"
                onClick={disableEditing ? () => {} : onClickEdit}
              />
            )}
            {onClickAdd != undefined && (
              <img
                className="add-button"
                src="/images/drawer/add.svg"
                alt="edit"
                onClick={onClickAdd}
              />
            )}
          </div>
        }
        style={style}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default ListEditItem;
