import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css.global`
  .damage-modal-wrapper {
    z-index: ${zIndex.allAroundModal};
  }
  .damage-modal-wrapper .ant-modal-header {
    padding: 15px;
    border-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  :global(.ant-modal) {
    top: 0;
    height: 95vh;
  }
  :global(.ant-modal-content) {
    top: 0;
    height: 95vh;
  }
  .damage-modal-wrapper .ant-modal-title {
    font-size: 24px;
  }
  .damage-modal-wrapper .ant-modal-body {
    padding: 15px;
    padding-top: 0;
  }
  .damage-modal-wrapper .ant-modal-close {
    width: 50px;
    height: 50px;
    color: black;
  }
  @media only screen and (min-width: 768px) {
    damage-modal-wrapper {
      z-index: ${zIndex.allAroundModal};
    }
    .damage-modal-wrapper .ant-modal-header {
      padding: 15px;
      border-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    :global(.ant-modal) {
      top: 20px;
      height: 95vh;
    }
    :global(.ant-modal-content) {
      top: 0;
      height: 95vh;
    }
    .damage-modal-wrapper .ant-modal-title {
      font-size: 24px;
    }
    .damage-modal-wrapper .ant-modal-body {
      padding: 15px;
      padding-top: 0;
    }
    .damage-modal-wrapper .ant-modal-close {
      width: 50px;
      height: 50px;
      color: black;
    }
  }
`;

export default style;
