import css from 'styled-jsx/css';

const style = css`
  .circle-info {
    margin: 0;
  }
  .circle-info-wrapper {
    display: flex;
  }
  .circle-info-switch-wrapper {
    margin-bottom: 5px;
  }
  .button-wrapper {
    margin-top: 48px;
  }
  .pricing-edit-button {
    cursor: pointer;
  }
  .kyc-edit-button {
    align-self: flex-start;
  }
  .kyc-section {
    display: flex;
    flex-direction: column;
  }
  .kyc-wrapper {
    display: inline-block;
    padding: 0;
    margin: 6px 0 6px 6px;
  }
  .kyc-label {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .kyc-icon {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
`;

export default style;
