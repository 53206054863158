export const optionsForTripStatus: Array<string> = [
  'planned',
  'reserved',
  'opened',
  'driving',
  'parking',
  'waitingForCheckin',
  'finished',
  'cancelled',
  'expired',
];
