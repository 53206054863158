import css from 'styled-jsx/css';
import { common } from '../../../theme/theme';

const style = css.global`
  .user-license-settings-contents {
    width: 380px;
    padding: 16px;
  }
  .user-license-settings-title {
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    margin-bottom: 10px;
  }
  .user-license-settings-subtitle {
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .user-license-settings-input-container {
    width: 100%;
  }
  .user-license-settings-input-row-container {
    display: flex;
    justify-content: space-between;
  }
  .user-license-settings-input-container {
    display: flex;
    flex-direction: column;
  }
  .user-switch-wrapper {
    margin-bottom: 3px;
  }
  .user-license-settings-date {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .permission-label {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }
  .button-container {
    padding: 8px 0;
  }
  .user-license-settings-contents .list-item-wrapper {
    margin-top: 12px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .user-license-settings-contents :global(.submit-button) {
    ${common.button}
    ${common.submitButton}
  }
  .user-license-settings-contents :global(.cancel-button) {
    ${common.button}
    ${common.cancelButton}
  }
  .error {
    color: red;
    font-size: 11px;
    margin-bottom: 0;
    margin: 4px;
  }
`;

export default style;
