import css from 'styled-jsx/css';

const style = css`
  .profile-info {
    margin: 0;
    padding-bottom: 100px;
  }
`;

export default style;
