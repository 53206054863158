import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse } from '../../../../@types';
import { TextsTypes } from '../../../../@types/enums';

export const textsRequest = () => createAction(TextsTypes.TEXTS_REQUEST);

export const textsSuccess = (payload: any) =>
  createAction(TextsTypes.TEXTS_SUCCESS, payload);

export const textsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(TextsTypes.TEXTS_FAILURE, error);
