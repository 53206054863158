import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  areasReducer,
  initialState as areasInitialState,
} from '../store/appData/areas/index';
import {
  authReducer,
  initialState as authInitialState,
} from '../store/userData/user/index';
import {
  caresReducer,
  initialState as caresInitialState,
} from '../store/userData/cares/index';
import {
  tripsReducer,
  initialState as tripsInitialState,
} from '../store/userData/trips/index';
import {
  carsReducer,
  initialState as carsInitialState,
} from '../store/userData/cars/index';
import {
  circlesReducer,
  initialState as circlesInitialState,
} from '../store/userData/circles/index';
import {
  profilesReducer,
  initialState as profilesInitialState,
} from '../store/userData/profiles/index';
import {
  claimsReducer,
  initialState as claimsInitialState,
} from '../store/userData/claims/index';
import {
  filterReducer,
  initialState as filterInitialState,
} from '../store/ui/filters/index';
import {
  fleetsReducer,
  initialState as fleetsInitialState,
} from '../store/appData/fleets/index';
import {
  pricingsReducer,
  initialState as pricingsInitialState,
} from '../store/appData/pricings/index';
import {
  mapReducer,
  initialState as mapInitialState,
} from '../store/ui/map/index';
import {
  uiReducer,
  initialState as uiInitialState,
} from '../store/ui/common/index';
import {
  vehicleEventsReducer,
  initialState as vehicleEventsInitialState,
} from '../store/userData/vehicleEvents/index';
import {
  categoriesReducer,
  initialState as categoriesInitialState,
} from '../store/userData/categories/index';
import {
  usersReducer,
  initialState as usersInitialState,
} from '../store/userData/users/index';
import {
  adminsReducer,
  initialState as adminsInitialState,
} from '../store/userData/admins/index';
import {
  textsReducer,
  initialState as textsInitialState,
} from '../store/appData/texts/index';
import {
  receiptsReducer,
  initialState as receiptsInitialState,
} from '../store/userData/receipts/index';

const commonReducerConfig = {
  key: 'common',
  storage: storage,
  whitelist: ['displayColumns'],
  stateReconciler: autoMergeLevel2,
} as any;

const authReducerConfig = {
  key: 'user',
  storage: storage,
  stateReconciler: autoMergeLevel2,
} as any;

const rootReducer = combineReducers({
  userData: combineReducers({
    user: persistReducer(authReducerConfig, authReducer),
    cars: carsReducer,
    cares: caresReducer,
    claims: claimsReducer,
    trips: tripsReducer,
    vehicleEvents: vehicleEventsReducer,
    categories: categoriesReducer,
    circles: circlesReducer,
    profiles: profilesReducer,
    users: usersReducer,
    admins: adminsReducer,
    receipts: receiptsReducer,
  }),
  ui: combineReducers({
    common: persistReducer(commonReducerConfig, uiReducer),
    filter: filterReducer,
    map: mapReducer,
  }),
  appData: combineReducers({
    areas: areasReducer,
    fleets: fleetsReducer,
    pricings: pricingsReducer,
    texts: textsReducer,
  }),
});

export const initialState = {
  userData: {
    user: authInitialState,
    cars: carsInitialState,
    cares: caresInitialState,
    claims: claimsInitialState,
    trips: tripsInitialState,
    vehicleEvents: vehicleEventsInitialState,
    categories: categoriesInitialState,
    circles: circlesInitialState,
    profiles: profilesInitialState,
    users: usersInitialState,
    admins: adminsInitialState,
    receipts: receiptsInitialState,
  },
  ui: {
    common: uiInitialState,
    filter: filterInitialState,
    map: mapInitialState,
  },
  appData: {
    areas: areasInitialState,
    fleets: fleetsInitialState,
    pricings: pricingsInitialState,
    texts: textsInitialState,
  },
};

export type RootState = typeof initialState;

export default rootReducer;
