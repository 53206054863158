import React from 'react';
import { useSelector } from 'react-redux';
import LinkToVehicle from '../../components/shared/LinkToVehicle';
import getVehicleByVIN from '../../utils/getVehicleByVIN';
import styles from './VINAndPlate.styles';
import { selectCars } from '../../redux/store/userData/cars';

const VINAndPlate = ({ chassisNumber, plate, carId = '' }: any) => {
  const cars = useSelector(selectCars);
  const vehicle = getVehicleByVIN(cars, chassisNumber);

  return (
    <>
      <div className="wrapper">
        {chassisNumber && (
          <div className="vin">
            <p className="label">VIN</p>
            <p className="value">
              <LinkToVehicle
                vehicle={vehicle || { id: carId }}
                text={chassisNumber}
              />
            </p>
          </div>
        )}
        <div className="plate">
          <p className="label">Plate</p>
          <p className="value">
            <LinkToVehicle vehicle={vehicle || { id: carId }} text={plate} />
          </p>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default VINAndPlate;
