const getBoundsFromPoints = (points: any[]) => {
  return points.reduce(
    (bounds, { lat, lng }) => ({
      north: bounds.north < lat ? lat : bounds.north,
      south: bounds.south > lat ? lat : bounds.south,
      east: bounds.east < lng ? lng : bounds.east,
      west: bounds.west > lng ? lng : bounds.west,
    }),
    {
      north: -90,
      south: 90,
      east: -180,
      west: 180,
    }
  );
};

export default getBoundsFromPoints;
