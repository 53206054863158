// @ts-nocheck
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from './ImageSlide.styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Img } from 'react-image';
import LoadingIcon from '../shared/LoadingIcon';

export const customPaginationIndicator = (
  onClickHandler,
  isSelected,
  index
) => {
  return (
    <div className="indicator-wrapper">
      <button
        className={isSelected ? 'active-indicator' : 'inactive-indicator'}
        onClick={(e) => {
          onClickHandler(e);
          document
            .getElementById(`image-${(e.target as HTMLButtonElement).value}`)
            .click();
        }}
        onKeyDown={onClickHandler}
        value={index}
        key={`indicator-button-${index}`}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

const ImageSlide = ({ images }) => {
  return (
    <div className="border">
      <Carousel
        showArrows
        infiniteLoop
        useKeyboardArrows
        showThumbs={false}
        selectedItem={0}
        showStatus={false}
        renderIndicator={customPaginationIndicator}
      >
        {images.map((image, id) => (
          <div key={`allAround-image-slide-${id}`}>
            <div className="image-wrapper" id={`image-${id}`}>
              <Img
                key={image}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  pointerEvents: 'auto',
                }}
                src={image}
                loading="lazy"
                loader={<LoadingIcon />}
                alt="All-Around-Image"
              />
            </div>
          </div>
        ))}
      </Carousel>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ImageSlide;
