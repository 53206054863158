const defaultServiceEntity = {
  id: '',
  status: '',
  code: '',
  plate: '',
  fleetId: '',
  areaId: '',
  tripStart: '',
  tripEnd: '',
  userName: '',
  brandId: '',
  brandCode: '',
  chassisNumber: '',
  images: [],
  serviceTypes: [],
};

export const seasonToService = (service: { serviceTypes: any }): any => ({
  ...defaultServiceEntity,
  ...service,
  serviceTypes: service.serviceTypes,
});

const seasonToServices = (services: any[]) => services.map(seasonToService);

export default seasonToServices;
