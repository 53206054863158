export enum ReceiptsTypes {
  RECEIPTS_REQUEST = 'appData/pricing/RECEIPTS_REQUEST',
  RECEIPTS_SUCCESS = 'appData/pricing/RECEIPTS_SUCCESS',
  RECEIPTS_FAILURE = 'appData/pricing/RECEIPTS_FAILURE',
  RECEIPT_REQUEST = 'appData/pricing/RECEIPT_REQUEST',
  RECEIPT_SUCCESS = 'appData/pricing/RECEIPT_SUCCESS',
  RECEIPT_FAILURE = 'appData/pricing/RECEIPT_FAILURE',
  SET_DISPLAY_RECEIPT = 'appData/pricing/SET_DISPLAY_RECEIPT',
  SELECT_RECEIPT = 'appData/pricing/SELECT_RECEIPT',
  RECEIPTS_CSV_REQUEST = 'appData/pricing/RECEIPTS_CSV_REQUEST',
  RECEIPTS_CSV_SUCCESS = 'appData/pricing/RECEIPTS_CSV_SUCCESS',
  RECEIPTS_CSV_FAILURE = 'appData/pricing/RECEIPTS_CSV_FAILURE',
  SET_TOTAL_RECEIPTS = 'appData/pricing/SET_TOTAL_RECEIPTS',
}
