import { Target } from '../@types';

const targetsForUsers: Target[] = [
  { value: 'brandName', type: 'text', isAPI: false },
  {
    value: 'accountStatus',
    type: 'selection',
    isAPI: true,
    queryName: 'status',
    queryType: 'array',
  },
  { value: 'email', type: 'text', isAPI: true },
  { value: 'referralCode', type: 'text', isAPI: true },
  { value: 'id', type: 'text', isAPI: true },
  {
    value: 'merchantUserReference',
    type: 'text',
    isAPI: true,
    queryName: 'merchantReference',
  },
  { value: 'phoneNumber', type: 'text', isAPI: true },
  {
    value: 'license.number',
    type: 'text',
    isAPI: true,
    queryName: 'licenseNumber',
  },
  { value: 'firstName', type: 'text', isAPI: true },
  { value: 'lastName', type: 'text', isAPI: true },
  {
    value: 'creditworthinessData.creditWorthinessStatus',
    type: 'selection',
    isAPI: true,
    queryName: 'cwStatus',
  },
  {
    value: 'identityDocuments.[0].status',
    type: 'selection',
    isAPI: true,
    queryName: 'idStatus',
  },
  {
    value: 'licenses.[0].licenseStatus',
    type: 'selection',
    isAPI: true,
    queryName: 'dlStatus',
  },
  {
    value: 'created',
    type: 'date',
    isAPI: true,
    queryNameFrom: 'signedUpFrom',
    queryNameTo: 'signedUpTo',
  },
  {
    value: 'remainingIDSubmissionAttempts',
    type: 'int',
    isAPI: true,
  },
  {
    value: 'remainingLicenseSubmissionAttempts',
    type: 'int',
    isAPI: true,
  },
  {
    value: 'tariff.name',
    type: 'text',
    isAPI: true,
    queryName: 'tariffs',
  },
  {
    value: 'userType',
    type: 'text',
    isAPI: true,
  },
];

export default targetsForUsers;
