import css from 'styled-jsx/css';
import { zIndex } from '../../theme/theme';

const style = css`
  :global(.auto-complete-popup) {
    z-index: ${zIndex.autoCompletePopup};
  }
  .text-search {
    padding: 16px;
  }
  .text-search :global(.text-input) {
    width: 100%;
  }
`;

export default style;
