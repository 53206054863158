import { GeoJSONData } from '../@types';
import locationFromString from './locationFromString';

interface GeoJSONDataWithCoordinates extends Omit<GeoJSONData, 'features'> {
  features: Array<{
    geometry: {
      coordinates: Array<Array<{ lng: number; lat: number }>>;
      type?: string;
    };
  }>;
}

const fixGeoJSONCoordinates = (
  geoJSON: any
): GeoJSONDataWithCoordinates | GeoJSONData => {
  const { features } = geoJSON;

  if (features && features.length) {
    const fixedFeatures = features.map((feature: { geometry: any }) => {
      const { geometry } = feature;
      const { coordinates } = geometry;
      const fixedCoordinates = coordinates.map((coordinate: any) => {
        const point = locationFromString(String(coordinate), ' ') as any;
        // I swapped lat, lng
        return { lng: point.lat, lat: point.lng };
      });
      return { geometry: { ...geometry, coordinates: [fixedCoordinates] } };
    });
    return { ...geoJSON, features: fixedFeatures };
  }
  return geoJSON;
};

export default fixGeoJSONCoordinates;
