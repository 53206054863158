export enum TripsTypes {
  MORE_TRIPS_REQUEST = 'userData/Trips/MORE_TRIPS_REQUEST',
  TRIPS_REQUEST = 'userData/Trips/TRIPS_REQUEST',
  TRIPS_SUCCESS = 'userData/Trips/TRIPS_SUCCESS',
  TRIPS_FAILURE = 'userData/Trips/TRIPS_FAILURE',
  TRIP_REQUEST = 'userData/Trips/TRIP_REQUEST',
  CREATE_BOOKING_REQUEST = 'userData/Trips/CREATE_BOOKING_REQUEST',
  START_BOOKING_REQUEST = 'userData/Trips/START_BOOKING_REQUEST',
  TRIP_SUCCESS = 'userData/Trips/TRIP_SUCCESS',
  CREATE_OFFLINE_BOOKING_SUCCESS = 'userData/Trips/CREATE_OFFLINE_BOOKING_SUCCESS',
  START_OFFLINE_BOOKING_SUCCESS = 'userData/Trips/START_OFFLINE_BOOKING_SUCCESS',
  TRIP_FAILURE = 'userData/Trips/TRIP_FAILURE',
  CREATE_OFFLINE_BOOKING_FAILURE = 'userData/Trips/CREATE_OFFLINE_BOOKING_FAILURE',
  START_OFFLINE_BOOKING__FAILURE = 'userData/Trips/START_OFFLINE_BOOKING__FAILURE',
  TRIP_INVOICE_REQUEST = 'userData/Trips/TRIP_INVOICE_REQUEST',
  TRIP_INVOICE_SUCCESS = 'userData/Trips/TRIP_INVOICE_SUCCESS',
  TRIP_INVOICE_FAILURE = 'userData/Trips/TRIP_INVOICE_FAILURE',
  SET_DISPLAY_TRIPS = 'userData/Trips/SET_DISPLAY_TRIPS',
  SET_TOTAL_TRIPS = 'userData/Trips/SET_TOTAL_TRIPS',
  SELECT_TRIP = 'userData/Trips/SELECT_TRIP',
  FINISH_TRIP_REQUEST = 'userData/Trips/FINISH_TRIP_REQUEST',
  FINISH_TRIP_SUCCESS = 'userData/Trips/FINISH_TRIP_SUCCESS',
  FINISH_TRIP_FAILURE = 'userData/Trips/FINISH_TRIP_FAILURE',
  START_TRIP_REQUEST = 'userData/Trips/START_TRIP_REQUEST',
  START_TRIP_SUCCESS = 'userData/Trips/START_TRIP_SUCCESS',
  START_TRIP_FAILURE = 'userData/Trips/START_TRIP_FAILURE',
  FINISH_TRIP_VIOLATIONS = 'userData/Trips/FINISH_TRIP_VIOLATIONS',
  BOOKINGS_REQUEST = 'userData/Trips/BOOKINGS_REQUEST',
  BOOKINGS_SUCCESS = 'userData/Trips/BOOKINGS_SUCCESS',
  BOOKINGS_FAILURE = 'userData/Trips/BOOKINGS_FAILURE',
  CANCEL_BOOKINGS_REQUEST = 'userData/Trips/CANCEL_BOOKINGS_REQUEST',
  CANCEL_BOOKINGS_SUCCESS = 'userData/Trips/CANCEL_BOOKINGS_SUCCESS',
  CANCEL_BOOKINGS_FAILURE = 'userData/Trips/CANCEL_BOOKINGS_FAILURE',
  UPDATE_BOOKINGS_REQUEST = 'userData/Trips/UPDATE_BOOKINGS_REQUEST',
  UPDATE_BOOKINGS_SUCCESS = 'userData/Trips/UPDATE_BOOKINGS_SUCCESS',
  UPDATE_BOOKINGS_FAILURE = 'userData/Trips/UPDATE_BOOKINGS_FAILURE',
  UPDATE_TRIP_REQUEST = 'userData/Trips/UPDATE_TRIP_REQUEST',
  UPDATE_TRIP_SUCCESS = 'userData/Trips/UPDATE_TRIP_SUCCESS',
  UPDATE_TRIP_FAILURE = 'userData/Trips/UPDATE_TRIP_FAILURE',
  SET_BOOKINGS_DISPLAY = 'userData/Trips/SET_BOOKINGS_DISPLAY',
  SET_TOTAL_BOOKINGS = 'userData/Trips/SET_TOTAL_BOOKINGS',
  SELECT_BOOKING = 'userData/Trips/SELECT_BOOKING',
  BOOKING_REQUEST = 'userData/Trips/BOOKING_REQUEST',
  BOOKING_SUCCESS = 'userData/Trips/BOOKING_SUCCESS',
  BOOKING_FAILURE = 'userData/Trips/BOOKING_FAILURE',
}
