import { Target } from '../@types';

const targetsForShare: Target[] = [
  { value: 'code', type: 'text', isAPI: true, queryName: 'tripCode' },
  { value: 'status', type: 'selection', isAPI: true, queryType: 'array' },
  { value: 'plate', type: 'text', isAPI: true, queryName: 'carPlate' },
  {
    value: 'merchantRef',
    type: 'text',
    isAPI: true,
    queryName: 'merchantReference',
  },
  {
    value: 'fleetId',
    type: 'selection',
    isAPI: true,
    queryName: 'fleet',
    queryType: 'array',
  },
  { value: 'tripStart', type: 'date', isAPI: false },
  { value: 'tripEnd', type: 'date', isAPI: false },
  {
    value: 'userReferralCode',
    type: 'text',
    isAPI: false,
  },
];

export default targetsForShare;
