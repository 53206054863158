import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Modal, Button } from 'antd';
import styles from './UpdateProfileModal.styles';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import {
  selectSelectedProfile,
  updateProfile,
} from '../../redux/store/userData/profiles';
import { colors } from '../../theme/theme';
import SelectorLayout, { arrayToOptions } from '../shared/SelectorLayout';
import { selectBrands } from '../../redux/store/appData/fleets';
import TextInput from '../shared/TextInput';
import { useIntl } from 'react-intl';

const VehicleTypeOptions = ['transporter', 'passenger'];
const PollutionBadgeOptions = ['green', 'yellow', 'red', 'blue', 'none'];
const GearOptions = ['manual', 'automatic'];
const FuelOptions = [
  'diesel',
  'unleaded98',
  'unleaded95',
  'electric',
  'LPG',
  'CNG',
  'hybrid_diesel',
  'hybrid_unleaded98',
  'hybrid_unleaded95',
  'other',
];

const UpdateProfileModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedProfile = useSelector(selectSelectedProfile);
  const visible = useSelector(getIsVisibleModal('updateProfileModal'));

  const [name, setName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [model, setModel] = useState('');
  const [vehicleType, setVehicleType] = useState<any>();
  const [pollutionBadge, setPollutionBadge] = useState<any>();
  const [gear, setGear] = useState<any>();
  const [fuel, setFuel] = useState<any>();
  const [fuelConsumption, setFuelConsumption] = useState<any>();
  const [fuelTankVolume, setFuelTankVolume] = useState<any>();
  const [batteryCapacityKwh, setBatteryCapacityKwh] = useState<any>();
  const [enginePower, setEnginePower] = useState<any>();
  const [seats, setSeats] = useState<any>();
  const [modelYear, setModelYear] = useState<any>();
  const [loadCapacity, setLoadCapacity] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [height, setHeight] = useState<any>();
  const [length, setLength] = useState<any>();
  const [brandId, setBrandId] = useState('');
  const brands = useSelector(selectBrands);
  const brandsOptions = useMemo(
    () =>
      brands?.map((brand) => ({
        value: brand.id,
        label: brand.name,
      })) || [],
    [brands]
  );

  const isDisabled = !name;

  const handleCancel = () => {
    dispatch(closeModal('updateProfileModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    batch(() => {
      const profileInputData = {
        name,
        brandId,
        brandName,
        model,
        vehicleType,
        pollutionBadge,
        gear,
        fuel,
        ...(fuel === 'electric'
          ? { batteryCapacityKwh }
          : { fuelConsumption, fuelTankVolume }),
        enginePower,
        seats,
        modelYear,
        loadCapacity,
        width,
        height,
        length,
      };
      dispatch(updateProfile(selectedProfile?.id!, profileInputData));
    });
    handleCancel();
  };

  const onSelectVehicleType = (value: any) => {
    setVehicleType(value);
  };

  const onSelectPollutionBadge = (value: any) => {
    setPollutionBadge(value);
  };

  const onSelectGear = (value: any) => {
    setGear(value);
  };

  const onSelectFuel = (value: any) => {
    setFuel(value);
  };

  const onSelectBrand = (value: any) => {
    setBrandId(value);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeVehicleBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);
  };

  const onChangeModel = (e: ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  };

  const onChangeFuelConsumption = (e: ChangeEvent<HTMLInputElement>) => {
    setFuelConsumption(e.target.value);
  };

  const onChangeFuelTankVolume = (e: ChangeEvent<HTMLInputElement>) => {
    setFuelTankVolume(e.target.value);
  };

  const onChangeBatteryCapacityKwh = (e: ChangeEvent<HTMLInputElement>) => {
    setBatteryCapacityKwh(e.target.value);
  };

  const onChangeEnginePower = (e: ChangeEvent<HTMLInputElement>) => {
    setEnginePower(e.target.value);
  };

  const onChangeSeats = (e: ChangeEvent<HTMLInputElement>) => {
    setSeats(e.target.value);
  };

  const onChangeModelYear = (e: ChangeEvent<HTMLInputElement>) => {
    setModelYear(e.target.value);
  };

  const onChangeLoadCapacity = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadCapacity(e.target.value);
  };

  const onChangeWidth = (e: ChangeEvent<HTMLInputElement>) => {
    setWidth(e.target.value);
  };

  const onChangeHeight = (e: ChangeEvent<HTMLInputElement>) => {
    setHeight(e.target.value);
  };

  const onChangeLength = (e: ChangeEvent<HTMLInputElement>) => {
    setLength(e.target.value);
  };

  useEffect(() => {
    if (visible) {
      setName(selectedProfile?.name!);
      setBrandId(selectedProfile?.brandId!);
      setBrandName(selectedProfile?.brandName!);
      setModel(selectedProfile?.model!);
      setVehicleType(selectedProfile?.vehicleType);
      setPollutionBadge(selectedProfile?.pollutionBadge);
      setGear(selectedProfile?.gear);
      setFuel(selectedProfile?.fuel);
      setBatteryCapacityKwh(selectedProfile?.batteryCapacityKwh);
      setFuelConsumption(selectedProfile?.fuelConsumption);
      setFuelTankVolume(selectedProfile?.fuelTankVolume);
      setEnginePower(selectedProfile?.enginePower);
      setSeats(selectedProfile?.seats);
      setModelYear(selectedProfile?.modelYear);
      setLoadCapacity(selectedProfile?.loadCapacity);
      setWidth(selectedProfile?.width);
      setHeight(selectedProfile?.height);
      setLength(selectedProfile?.length);
    }
  }, [visible]);

  return (
    <>
      <div>
        <Modal
          wrapClassName="update-profile-wrapper"
          title={`Update vehicle profile`}
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="update-profile-contents">
            <div className="profile-inputs-container">
              <div className="input-container2">
                <TextInput
                  value={name}
                  placeholder="Profile Name"
                  onChange={onChangeName}
                />
              </div>
              <div className="input-container2">
                <p className="text-input-label">Brand</p>
                <SelectorLayout
                  keyPrefix="brand-select"
                  value={brandId}
                  placeholder="Select Brand"
                  onSelect={onSelectBrand}
                  options={brandsOptions}
                />
              </div>
            </div>
            <div className="profile-inputs-container">
              <div className="input-container2">
                <TextInput
                  value={brandName}
                  placeholder="Vehicle Brand"
                  onChange={onChangeVehicleBrand}
                />
              </div>
              <div className="input-container2">
                <TextInput
                  value={model}
                  placeholder="Vehicle Model"
                  onChange={onChangeModel}
                />
              </div>
            </div>
            <div className="profile-inputs-container">
              <div className="input-container2">
                <p className="text-input-label">Vehicle Type</p>
                <SelectorLayout
                  keyPrefix="vehicle-type-select"
                  value={vehicleType}
                  placeholder="Select Vehicle Type"
                  onSelect={onSelectVehicleType}
                  options={arrayToOptions(VehicleTypeOptions)}
                />
              </div>
              <div className="input-container2">
                <p className="text-input-label">Gear</p>
                <SelectorLayout
                  keyPrefix="gear-select"
                  value={gear}
                  placeholder="Select Gear"
                  onSelect={onSelectGear}
                  options={arrayToOptions(GearOptions)}
                />
              </div>
            </div>
            <div className="profile-inputs-container">
              <div className="input-container3">
                <TextInput
                  value={enginePower}
                  placeholder="Engine Power (HPS)"
                  onChange={onChangeEnginePower}
                />
              </div>
              <div className="input-container3">
                <TextInput
                  value={seats}
                  placeholder="Seats"
                  onChange={onChangeSeats}
                />
              </div>
              <div className="input-container3">
                <TextInput
                  value={modelYear}
                  placeholder="Model Year"
                  onChange={onChangeModelYear}
                />
              </div>
            </div>
            <p className="section-label">Fuel Info:</p>
            <div className="profile-inputs-container">
              <div className="input-container2">
                <p className="text-input-label">Fuel</p>
                <SelectorLayout
                  keyPrefix="fuel-select"
                  value={fuel}
                  placeholder="Select Fuel"
                  onSelect={onSelectFuel}
                  options={arrayToOptions(FuelOptions)}
                />
              </div>
              <div className="input-container2">
                <p className="text-input-label">Pollution Badge</p>
                <SelectorLayout
                  keyPrefix="pollution-badge-select"
                  value={pollutionBadge}
                  placeholder="Select Pollution Badge"
                  onSelect={onSelectPollutionBadge}
                  options={arrayToOptions(PollutionBadgeOptions)}
                />
              </div>
            </div>
            {fuel !== 'electric' && (
              <div className="profile-inputs-container">
                <div className="input-container2">
                  <TextInput
                    value={fuelConsumption}
                    placeholder="Fuel Consumption (l/100km)"
                    onChange={onChangeFuelConsumption}
                  />
                </div>
                <div className="input-container2">
                  <TextInput
                    value={fuelTankVolume}
                    placeholder="Fuel Tank Volume (l)"
                    onChange={onChangeFuelTankVolume}
                  />
                </div>
              </div>
            )}
            {fuel === 'electric' && (
              <div className="profile-inputs-container">
                <div className="input-container2">
                  <TextInput
                    value={batteryCapacityKwh}
                    placeholder="Battery Capacity (Kwh)"
                    onChange={onChangeBatteryCapacityKwh}
                  />
                </div>
              </div>
            )}
            {vehicleType === 'transporter' && (
              <>
                <p className="section-label">Loading Capacity:</p>
                <div className="profile-inputs-container">
                  <div className="input-container3">
                    <TextInput
                      value={loadCapacity}
                      placeholder="Load Capacity (kg)"
                      onChange={onChangeLoadCapacity}
                    />
                  </div>
                  <div className="input-container3">
                    <TextInput
                      value={width}
                      placeholder="Width (mm)"
                      onChange={onChangeWidth}
                    />
                  </div>
                  <div className="input-container3">
                    <TextInput
                      value={height}
                      placeholder="Height (mm)"
                      onChange={onChangeHeight}
                    />
                  </div>
                </div>
                <div className="profile-inputs-container">
                  <div className="input-container3">
                    <TextInput
                      value={length}
                      placeholder="Length (mm)"
                      onChange={onChangeLength}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="button-container">
              <Button
                className="submit-button"
                style={isDisabled ? { background: colors.buttonDisabled } : {}}
                onClick={handleOnSubmit}
                disabled={isDisabled}
              >
                {intl.formatMessage({
                  id: `submit`,
                })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx global>
        {styles}
      </style>
    </>
  );
};

export default UpdateProfileModal;
