import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AdminInfo.styles';
import { renderText } from '../../shared/DrawerLayout/renderText';
import { selectSelectedAdmin } from '../../../redux/store/userData/admins';
import { openModal } from '../../../redux/store/ui/common/actions';
import ListEditItem from '../../shared/ListEditItem';
import ListItem from '../../shared/ListItem';
import { useIntl } from 'react-intl';
import { selectAuth } from '../../../redux/store/userData/user/selectors';
import { renderDate } from '../../shared/TableLayout';
import { parseLocale } from '../../../utils';

const AdminInfo: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedAdmin = useSelector(selectSelectedAdmin);
  const user = useSelector(selectAuth);
  const editable =
    user?.permissions?.merchantManager && !selectedAdmin?.blocked;

  const handleInfoEditButton = () => {
    dispatch(openModal('EditAdminInfoModal'));
  };

  const onDeleteAdmin = () => {
    dispatch(openModal('AdminDeleteModal'));
  };

  return (
    <>
      <div className="user-info">
        <div className="admin-section-title">
          {intl.formatMessage({
            id: `fleetadmin.admins.info.user`,
          })}
        </div>
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.firstName`,
          })}
          leftItem={renderText(selectedAdmin.firstName)}
          rightItem={
            editable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleInfoEditButton}
              />
            )
          }
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.lastName`,
          })}
          leftItem={renderText(selectedAdmin.lastName)}
          rightItem={
            editable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleInfoEditButton}
              />
            )
          }
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.email`,
          })}
          leftItem={renderText(selectedAdmin.email)}
          rightItem={
            editable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleInfoEditButton}
              />
            )
          }
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.language`,
          })}
          leftItem={renderText(
            selectedAdmin?.locale
              ? parseLocale(selectedAdmin?.locale).toUpperCase()
              : 'DE'
          )}
          rightItem={
            editable && (
              <img
                className="edit-button"
                src={'/images/drawer/edit.svg'}
                alt="edit"
                onClick={handleInfoEditButton}
              />
            )
          }
        />
        <div className="admin-section-account">
          {intl.formatMessage({
            id: `fleetadmin.admins.info.account`,
          })}
        </div>
        <ListItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.merchant`,
          })}
          value={renderText(selectedAdmin.merchant?.name)}
        />
        <ListItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.created`,
          })}
          value={renderDate(selectedAdmin.created)}
        />
        <ListItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.createdby`,
          })}
          value={renderText(selectedAdmin.createdBy)}
        />
        <ListEditItem
          label={intl.formatMessage({
            id: `fleetadmin.admins.info.status`,
          })}
          leftItem={renderText(
            selectedAdmin?.blocked
              ? intl.formatMessage({
                  id: `fleetadmin.admins.info.deleted`,
                })
              : intl.formatMessage({
                  id: `fleetadmin.admins.info.active`,
                })
          )}
          rightItem={
            editable && (
              <div className="delete-admin" onClick={onDeleteAdmin}>
                {intl.formatMessage({
                  id: `fleetadmin.admins.info.archive`,
                })}
              </div>
            )
          }
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AdminInfo;
