import { ErrorResponse, FleetData } from '../../../../@types';

export type FleetAdminBrandData = {
  id: string;
  name: string;
};

export interface FleetsState {
  entities: FleetData[] | null;
  brands: FleetAdminBrandData[] | null;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState = {
  entities: null,
  brands: null,
  error: null,
  loading: false,
};
