import { createAction, createErrorAction } from '../../../helpers';
import { ErrorResponse, PricingTableData } from '../../../../@types';
import { PricingTypes } from '../../../../@types/enums';

export const pricingsRequest = () => createAction(PricingTypes.PRICING_REQUEST);

export const pricingsSuccess = (payload: PricingTableData[]) =>
  createAction(PricingTypes.PRICING_SUCCESS, payload);

export const pricingsFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PricingTypes.PRICING_FAILURE, error);

export const brandPricingsRequest = () =>
  createAction(PricingTypes.BRAND_PRICING_REQUEST);

export const brandPricingSuccess = (payload: any) =>
  createAction(PricingTypes.BRAND_PRICING_SUCCESS, payload);

export const brandPricingFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PricingTypes.BRAND_PRICING_FAILURE, error);

export const setDisplayPricings = (payload: PricingTableData[]) =>
  createAction(PricingTypes.SET_DISPLAY_PRICING, payload);

export const selectPricing = (payload: PricingTableData | null) =>
  createAction(PricingTypes.SELECT_PRICING, payload);

export const uploadPricingFileRequest = () =>
  createAction(PricingTypes.UPLOAD_PRICING_FILE_REQUEST);

export const uploadPricingFileSuccess = (payload: PricingTableData) =>
  createAction(PricingTypes.UPLOAD_PRICING_FILE_SUCCESS, payload);

export const uploadPricingFileFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PricingTypes.UPLOAD_PRICING_FILE_FAILURE, error);

export const pricingUpdateRequest = () =>
  createAction(PricingTypes.PRICING_UPDATE_REQUEST);

export const pricingUpdateSuccess = (payload: PricingTableData) =>
  createAction(PricingTypes.PRICING_UPDATE_SUCCESS, payload);

export const pricingUpdateFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PricingTypes.PRICING_UPDATE_FAILURE, error);

export const pricingDeleteRequest = () =>
  createAction(PricingTypes.PRICING_DELETE_REQUEST);

export const pricingDeleteSuccess = (payload) =>
  createAction(PricingTypes.PRICING_DELETE_SUCCESS, payload);

export const pricingDeleteFailure = <E extends ErrorResponse>(error: E) =>
  createErrorAction(PricingTypes.PRICING_DELETE_FAILURE, error);
