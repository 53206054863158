import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DrawerLayout from '../../components/shared/DrawerLayout';
import CategoryEditDrawerBody from './CategoryEditDrawerBody';
import { closecategoryDrawerAndSetQueries } from '../../redux/store/ui/common';
import { selectSelectedCategory } from '../../redux/store/userData/categories/selectors';
import { ViewMode } from '../../hooks/useViewMode';
import CarEditDrawer from '../../components/CarEditDrawer';
import { selectViewMode } from '../../redux/store/ui/common/selectors';

const CategoryEditDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectViewMode);
  const selectedCategory = useSelector(selectSelectedCategory);
  const visible = selectedCategory != null;

  const handleOnClose = () => {
    dispatch(closecategoryDrawerAndSetQueries());
  };

  return (
    selectedCategory && (
      <DrawerLayout
        visible={visible}
        title={selectedCategory.id}
        body={<CategoryEditDrawerBody />}
        nestedDrawers={
          <>{viewMode !== ViewMode.VEHICLE && <CarEditDrawer />}</>
        }
        onReload={undefined}
        onClose={handleOnClose}
      />
    )
  );
};

export default CategoryEditDrawer;
