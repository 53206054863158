import React from 'react';
import { Collapse, Switch, Tag } from 'antd';
import ListEditItem from '../../../shared/ListEditItem';
import ListItem from '../../../shared/ListItem';
import ImageAdded from '../../../TimeLine/ImageAdded';
import { getStatusColor } from '../../../../utils';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import { renderDate } from '../../../shared/TableLayout';
import styles from '../UserInfo.styles';

const { Panel } = Collapse;

interface UserDLInfoProps {
  selectedUser: any;
  intl: any;
  onEditLicenseSettings: () => void;
  openAddLicenseModal: () => void;
  onDLStatusUpdate: () => void;
}

const UserDLInfo: React.FC<UserDLInfoProps> = ({
  selectedUser,
  intl,
  onEditLicenseSettings,
  openAddLicenseModal,
  onDLStatusUpdate,
}) => (
  <>
    <div className="user-section-title">
      {intl.formatMessage({ id: `fleetadmin.users.info.drivinglicense` })}
      <img
        className="add-button"
        src="/images/drawer/add.svg"
        alt="edit"
        onClick={openAddLicenseModal}
      />
    </div>
    <ListEditItem
      label={intl.formatMessage({ id: `fleetadmin.users.info.dl.submissions` })}
      leftItem={<span>{selectedUser?.remainingLicenseSubmissionAttempts}</span>}
      onClickEdit={onEditLicenseSettings}
    />
    <ListEditItem
      leftItem={
        <div className="permission-label">
          {intl.formatMessage({ id: `fleetadmin.users.info.dl.young` })}
        </div>
      }
      rightItem={
        <Switch checked={selectedUser?.youngDriverApproved} disabled />
      }
    />
    <div className="collapse-container">
      <Collapse bordered={false}>
        {selectedUser?.licenses?.map((license: any, index: number) => (
          <Panel
            header={
              <div className="dl-header">
                <span>
                  {`${intl.formatMessage({
                    id: `fleetadmin.users.info.dl.submitted`,
                  })} ${license.submissionDate}`}
                </span>
                {license?.licenseStatus && (
                  <Tag color={getStatusColor(license.licenseStatus)}>
                    {intl.formatMessage({
                      id: `fleetadmin.status.${license.licenseStatus}`,
                    })}
                  </Tag>
                )}
              </div>
            }
            key={index}
          >
            <ListEditItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.status`,
              })}
              leftItem={renderText(license.licenseStatus, false)}
              onClickEdit={onDLStatusUpdate}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.number`,
              })}
              value={renderText(license.number, false)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.classBFrom`,
              })}
              value={renderDate(license?.classB?.from, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.classBTo`,
              })}
              value={renderDate(license?.classB?.to, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.issueFrom`,
              })}
              value={renderDate(license?.interval?.from, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.issuingAuthority`,
              })}
              value={renderText(license?.issuingAuthority, false)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.issueCountry`,
              })}
              value={renderText(license.issueCountry, false)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.lastchangedat`,
              })}
              value={renderDate(license?.lastChangedAt, true)}
            />
            <ListItem
              label={intl.formatMessage({
                id: `fleetadmin.users.info.dl.lastchangedby`,
              })}
              value={renderText(license?.lastChangedBy, true)}
            />
            {license?.frontImageUrl && (
              <div className="dl-image-container">
                <ImageAdded
                  vehicleEvent={{
                    images: [
                      license?.frontImageUrl,
                      license?.backImageUrl,
                      license?.selfieUrl,
                    ],
                  }}
                />
              </div>
            )}
          </Panel>
        ))}
      </Collapse>
    </div>
    <style jsx>{styles}</style>
  </>
);

export default UserDLInfo;
