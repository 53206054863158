import React from 'react';
import styles from './LinkToArea.styles';
import { useDispatch } from 'react-redux';
import { VehicleProfileInfoData } from '../../@types';
import { selectProfile } from '../../redux/store/userData/profiles';

interface Props {
  text?: string;
  profile?: VehicleProfileInfoData | null;
  params?: any;
}

const LinkToProfile: React.FC<Props> = ({ text, profile }) => {
  const dispatch = useDispatch();

  if (!profile) {
    return <span>{text}</span>;
  }

  const onClickHandler = (e: any) => {
    e.preventDefault();
    if (profile != null) {
      dispatch(selectProfile(profile));
    }
  };
  return (
    <>
      <span className="link" onClick={onClickHandler}>
        {text || '-'}
      </span>
      <style jsx>{styles}</style>
    </>
  );
};

export default LinkToProfile;
